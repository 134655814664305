// eslint-disable-next-line import/extensions
import { APPLY_RATES_TO, SHIPMENT_STATUS, STOP_STATUS } from '@/utils/constants';

export const RatesEngineMixin = {
    methods: {
        $_getTotalAmount(ratesList) {
            let total = 0;
            ratesList.forEach((line) => {
                total += Math.round((line.quantity * line.unitPriceExTax + Number.EPSILON) * 100) / 100;
            });
            return total;
        },
        $_getRateAmount(rate) {
            const amount = rate.quantity * rate.unitPriceExTax;
            return Math.round((amount + Number.EPSILON) * 100) / 100;
        },
        $_getshipmentDataModel(
            shipmentDetails,
            user,
            loadValues = null,
            chargeList = null,
            costList = null,
            isCharging = false,
            isCosting = false,
            revertCharges = false,
            revertCosts = false,
            rateGroupChange = false,
            addressChange = false
        ) {
            const startTime = this.earliestStartTime(shipmentDetails?.shipmentStatusHistory);
            const finishTime = this.latestFinishTime(shipmentDetails?.shipmentStatusHistory);

            const pickup = {
                name: shipmentDetails.pickupStop.name,
                address: shipmentDetails.pickupStop.address,
                location: {
                    latitude: shipmentDetails.pickupStop.location.latitude,
                    longitude: shipmentDetails.pickupStop.location.longitude
                },
                tripDate: shipmentDetails.pickupStop.tripDate,
                earliestStartTime: startTime ?? null
            };

            const drop = {
                name: shipmentDetails.dropStop.name,
                address: shipmentDetails.dropStop.address,
                location: {
                    latitude: shipmentDetails.dropStop.location.latitude,
                    longitude: shipmentDetails.dropStop.location.longitude
                },
                latestFinishTime: finishTime ?? null
            };

            let pickupLocation = null;
            let dropLocation = null;
            let hasCustomerInvoice = false;
            let hasTeamMemberInvoice = false;
            let isChargingRule = isCharging;
            let isCostingRule = isCosting;

            if (
                shipmentDetails.pickupStop.location === null ||
                shipmentDetails.pickupStop.location?.latitude === 0 ||
                shipmentDetails.pickupStop.location?.longitude === 0
            ) {
                const status = ['Picked Up'];
                pickupLocation = this.getLocation(shipmentDetails?.shipmentStatusHistory, status);
            }

            if (
                shipmentDetails.dropStop.location === null ||
                shipmentDetails.dropStop.location?.latitude === 0 ||
                shipmentDetails.dropStop.location?.longitude === 0
            ) {
                const status = ['Complete'];
                dropLocation = this.getLocation(shipmentDetails?.shipmentStatusHistory, status);
            }

            if (pickupLocation) {
                pickup.location = pickupLocation;
            }

            if (dropLocation) {
                drop.location = dropLocation;
            }

            if (this.shipmentDetails.rateGroupId == null) {
                if (this.shipmentDetails.customerRateGroupId) {
                    // eslint-disable-next-line no-bitwise
                    const shouldCharge =
                        this.shipmentDetails.customerApplyRatesTo & this.getKeyByLabelInApplyRatesTo('Shipment');

                    if (shouldCharge && isCharging) {
                        isChargingRule = true;
                    } else {
                        isChargingRule = false;
                    }
                }

                if (this.shipmentDetails.assignedTo?.rateGroupId) {
                    // eslint-disable-next-line no-bitwise
                    const shouldCost =
                        this.shipmentDetails.assignedTo.applyRatesTo & this.getKeyByLabelInApplyRatesTo('Shipment');

                    if (shouldCost && isCosting) {
                        isCostingRule = true;
                    } else {
                        isCostingRule = false;
                    }
                }
            }

            if (shipmentDetails.customerInvoiceId !== null && shipmentDetails.customerInvoiceId !== undefined) {
                isChargingRule = false;
                hasCustomerInvoice = true;
            }

            if (shipmentDetails.teamMemberInvoiceId !== null && shipmentDetails.teamMemberInvoiceId !== undefined) {
                isCostingRule = false;
                hasTeamMemberInvoice = true;
            }

            // need to check if costs are a commission rate, and if so we need to update them based on the Base Charge.
            const isCommissionBasedCosts = this.costLinesAreCommissionBased(
                this.shipmentDetails.costLines,
                this.shipmentDetails.teamMemberInvoiceId
            );

            const shipmentWaitingTime = this.getTotalShipmentWaitingTime(shipmentDetails.shipmentStatusHistory);

            const data = {
                pickup: JSON.stringify(pickup),
                drop: JSON.stringify(drop),
                tripDate: shipmentDetails.pickupStop.tripDate,
                rateGroupId: shipmentDetails.rateGroupId,
                load: loadValues,
                teamId: user.teamId,
                publicUserId: user.publicUserId,
                speedUnits: user.speedUnits,
                chargeList: JSON.stringify(chargeList),
                costList: JSON.stringify(costList),
                revertToDefaultCharges: revertCharges,
                revertToDefaultCosts: revertCosts,
                shipmentId: shipmentDetails.shipmentId,
                isChargingRule,
                isCostingRule,
                getDistance: true,
                customerId: shipmentDetails.customerId === null ? null : shipmentDetails.customerId,
                addressChanged: addressChange,
                rateGroupChanged: rateGroupChange,
                assignedTeamMemberPublicId: shipmentDetails.assignToPublicUserId
                    ? shipmentDetails.assignToPublicUserId
                    : shipmentDetails.assignedTo?.publicUserId,
                distance: shipmentDetails.distance ?? 0,
                costsAreCommission: isCommissionBasedCosts,
                hasCustomerInvoice,
                hasTeamMemberInvoice,
                customerRateGroupId: shipmentDetails.customerRateGroupId,
                teamMemberRateGroupId: shipmentDetails.assignedTo?.rateGroupId,
                isChargesApproved: shipmentDetails.isChargesApproved,
                isCostsApproved: shipmentDetails.isCostsApproved,
                waitingTime: shipmentWaitingTime
            };

            return data;
        },
        $_getStopData(
            stopDetails,
            user,
            loadValues = null,
            chargeList = null,
            costList = null,
            isCharging = false,
            isCosting = false,
            revertCharges = false,
            revertCosts = false,
            rateGroupChange = false,
            addressChange = false
        ) {
            let stopLoc = null;
            let hasCustomerInvoice = false;
            let hasTeamMemberInvoice = false;
            let isChargingRule = isCharging;
            let isCostingRule = isCosting;

            if (
                this.stopDetails.location === null ||
                this.stopDetails.location?.latitude === 0 ||
                this.stopDetails.location?.longitude === 0
            ) {
                const status = ['Complete', 'Failed'];
                stopLoc = this.getLocation(this.stopDetails.stopStatusHistory, status);
            }

            const drop = {
                name: stopDetails.name,
                address: stopDetails.address,
                location: {
                    latitude: stopDetails.location.latitude,
                    longitude: stopDetails.location.longitude
                }
            };

            if (stopLoc) {
                drop.location = stopLoc;
            }

            const pickup = {
                location: {
                    latitude: stopDetails.trip?.startLocation?.latitude,
                    longitude: stopDetails.trip?.startLocation?.longitude
                }
            };

            // need to check if costs are a commission rate, and if so we need to update them based on the Base Charge.
            const isCommissionBasedCosts = this.costLinesAreCommissionBased(
                this.stopDetails.costLines,
                this.stopDetails.teamMemberInvoiceId
            );

            if (this.stopDetails.rateGroupId == null) {
                if (this.stopDetails.customerRateGroupId) {
                    // eslint-disable-next-line no-bitwise
                    const shouldCharge =
                        this.stopDetails.customerApplyRatesTo & this.getKeyByLabelInApplyRatesTo('Stop');

                    if (shouldCharge && isCharging) {
                        isChargingRule = true;
                    } else {
                        isChargingRule = false;
                    }
                }

                if (this.stopDetails.assignedTo?.rateGroupId) {
                    // eslint-disable-next-line no-bitwise
                    const shouldcost =
                        this.stopDetails.assignedTo.applyRatesTo & this.getKeyByLabelInApplyRatesTo('Stop');

                    if (shouldcost && isCosting) {
                        isCostingRule = true;
                    } else {
                        isCostingRule = false;
                    }
                }
            }

            if (stopDetails.customerInvoiceId !== null && stopDetails.customerInvoiceId !== undefined) {
                isChargingRule = false;
                hasCustomerInvoice = true;
            }

            if (stopDetails.teamMemberInvoiceId !== null && stopDetails.teamMemberInvoiceId !== undefined) {
                isCostingRule = false;
                hasTeamMemberInvoice = true;
            }

            const stopWaitingTime = this.getTotalStopWaitingTimes(stopDetails.stopStatusHistory);

            const data = {
                pickup: JSON.stringify(pickup),
                drop: JSON.stringify(drop),
                tripDate: stopDetails.tripDate,
                rateGroupId: stopDetails.rateGroupId,
                load: loadValues,
                teamId: user.teamId,
                publicUserId: user.publicUserId,
                speedUnits: user.speedUnits,
                chargeList: JSON.stringify(chargeList),
                costList: JSON.stringify(costList),
                revertToDefaultCharges: revertCharges,
                revertToDefaultCosts: revertCosts,
                stopId: stopDetails.stopId,
                isChargingRule,
                isCostingRule,
                customerId: stopDetails.customerId === null ? null : stopDetails.customerId,
                assignedTeamMemberPublicId: stopDetails.assignToPublicUserId
                    ? stopDetails.assignToPublicUserId
                    : stopDetails.assignedTo?.publicUserId,
                rateGroupChanged: rateGroupChange ?? false,
                addressChanged: addressChange ?? false,
                stopAttemptCounter: stopDetails.stopAttemptCounter,
                stopStatus: stopDetails.status,
                previousAttemptStopId: stopDetails.previousAttemptStopId,
                costsAreCommission: isCommissionBasedCosts,
                hasCustomerInvoice,
                hasTeamMemberInvoice,
                customerRateGroupId: stopDetails.customerRateGroupId,
                teamMemberRateGroupId: stopDetails.assignedTo?.rateGroupId,
                isChargesApproved: stopDetails.isChargesApproved,
                isCostsApproved: stopDetails.isCostsApproved,
                waitingTime: stopWaitingTime
            };

            return data;
        },
        $_getRateRuleData(
            chargeType,
            publicUserId,
            rateDetail,
            customerId,
            listLength,
            rateGroupId,
            driverRuleCreation,
            driverUserId
        ) {
            const obj = {
                type: chargeType.type,
                publicUserId,
                rule: JSON.stringify(rateDetail),
                customerId,
                priority: listLength + 1,
                rateGroupId: rateGroupId === 0 ? null : rateGroupId,
                isCostingRule: driverRuleCreation === true,
                isChargingRule: driverRuleCreation !== true,
                driverUserId
            };

            return obj;
        },
        earliestStartTime(shipmentStatusHistory) {
            if (!shipmentStatusHistory || shipmentStatusHistory?.length === 0) {
                return null;
            }

            const startStatus = ['Pending', 'Enroute to Pickup', 'Arrived at Pickup', 'Picked Up'];
            let earliestTimes = shipmentStatusHistory.filter((status) => startStatus.includes(status.newStatus));

            if (earliestTimes.length === 0) {
                return null;
            }

            if (earliestTimes.find((x) => x.newStatus === 'Picked Up')) {
                earliestTimes = earliestTimes.filter((x) => x.newStatus === 'Picked Up');
            }

            earliestTimes.sort((a, b) => a.actionDate - b.actionDate);
            let startTime = earliestTimes[0].actionDate;

            earliestTimes.forEach((status) => {
                if (status.actionDate >= startTime) {
                    startTime = status.actionDate;
                }
            });

            return startTime.toString();
        },
        latestFinishTime(shipmentStatusHistory) {
            if (!shipmentStatusHistory || shipmentStatusHistory?.length === 0) {
                return null;
            }

            const finishStatus = ['Pending', 'Enroute to Drop', 'Arrived at Drop', 'Complete'];
            let latestFinishTimes = shipmentStatusHistory.filter((status) => finishStatus.includes(status.newStatus));

            if (latestFinishTimes.length === 0) {
                return null;
            }

            if (latestFinishTimes.find((x) => x.newStatus === 'Complete')) {
                latestFinishTimes = latestFinishTimes.filter((x) => x.newStatus === 'Complete');
            }

            latestFinishTimes.sort((a, b) => a.actionDate - b.actionDate);
            let finishTime = latestFinishTimes[0].actionDate;

            latestFinishTimes.forEach((status) => {
                if (status.actionDate >= finishTime) {
                    finishTime = status.actionDate;
                }
            });

            return finishTime.toString();
        },
        getTotalStopWaitingTimes(stopStatusHistory) {
            try {
                if (!stopStatusHistory || stopStatusHistory.length === 0) {
                    return null;
                }

                const arrivedAt = stopStatusHistory
                    .filter((item) => item.newStatus === STOP_STATUS.Arrived)
                    .sort((a, b) => a.actionDate - b.actionDate)
                    .pop();

                const completedAt = stopStatusHistory
                    .filter((item) => item.newStatus === STOP_STATUS.Complete)
                    .sort((a, b) => a.actionDate - b.actionDate)
                    .pop();

                if (!arrivedAt || !completedAt || completedAt.actionDate <= arrivedAt.actionDate) {
                    return null;
                }

                const waitingTime = new Date(completedAt.actionDate) - new Date(arrivedAt.actionDate);
                const waitingTimeMinutes = Math.ceil(waitingTime / (1000 * 60)); // Convert milliseconds to minutes

                return `00:${String(waitingTimeMinutes).padStart(2, '0')}:00`;
            } catch {
                return null;
            }
        },
        getTotalShipmentWaitingTime(shipmentStatusHistory) {
            if (!shipmentStatusHistory || shipmentStatusHistory.length === 0) {
                return null;
            }
            const waitingTimePickup = this.shipmentStopPickupWaitingTime(shipmentStatusHistory);
            const waitingTimeDrop = this.shipmentDropWaitingTime(shipmentStatusHistory);

            const totalMinutes = waitingTimeDrop + waitingTimePickup;
            return `00:${String(totalMinutes).padStart(2, '0')}:00`;
        },
        shipmentStopPickupWaitingTime(shipmentStatusHistory) {
            try {
                const arrivedAt = shipmentStatusHistory
                    .filter((x) => x.newStatus === SHIPMENT_STATUS.ArrivedAtPickup)
                    .sort((a, b) => a.actionDate - b.actionDate)
                    .pop();

                const completeArriveUp = shipmentStatusHistory
                    .filter((x) => x.newStatus === SHIPMENT_STATUS.PickedUp)
                    .sort((a, b) => a.actionDate - b.actionDate)
                    .pop();

                if (!arrivedAt || !completeArriveUp || completeArriveUp.actionDate <= arrivedAt.actionDate) {
                    return 0;
                }

                const waitingTimeMs = new Date(completeArriveUp.actionDate) - new Date(arrivedAt.actionDate);
                return Math.ceil(waitingTimeMs / (1000 * 60)); // Convert milliseconds to minutes
            } catch {
                return 0;
            }
        },
        shipmentDropWaitingTime(shipmentStatusHistory) {
            try {
                const arrivedAt = shipmentStatusHistory
                    .filter((x) => x.newStatus === SHIPMENT_STATUS.ArrivedAtDrop)
                    .sort((a, b) => a.actionDate - b.actionDate)
                    .pop();

                const completeDrop = shipmentStatusHistory
                    .filter((x) => x.newStatus === SHIPMENT_STATUS.Complete)
                    .sort((a, b) => a.actionDate - b.actionDate)
                    .pop();

                if (!arrivedAt || !completeDrop || completeDrop.actionDate <= arrivedAt.actionDate) {
                    return 0;
                }

                const waitingTimeMs = new Date(completeDrop.actionDate) - new Date(arrivedAt.actionDate);
                return Math.ceil(waitingTimeMs / (1000 * 60)); // Convert milliseconds to minutes
            } catch {
                return 0;
            }
        },
        $_getTripDataModel(
            tripDetails,
            user,
            loadValues = null,
            chargeList = null,
            costList = null,
            isCharging = false,
            isCosting = false,
            revertCharges = false,
            revertCosts = false,
            rateGroupChange = false,
            addressChange = false
        ) {
            const startAddress = {
                location: {
                    latitude: tripDetails.locationDetails.startLocation.latitude,
                    longitude: tripDetails.locationDetails.startLocation.longitude
                },
                tripDate: tripDetails.tripDate,
                earliestStartTime: tripDetails.actualStartTime
            };

            const endAddress = {
                location: {
                    latitude: tripDetails.locationDetails.endLocation?.latitude,
                    longitude: tripDetails.locationDetails.endLocation?.longitude
                },
                latestFinishTime: tripDetails.actualEndTime
            };

            const data = {
                pickup: JSON.stringify(startAddress),
                drop: JSON.stringify(endAddress),
                tripDate: tripDetails.tripDate,
                rateGroupId: tripDetails.rateGroupId,
                load: loadValues,
                teamId: user.teamId,
                publicUserId: user.publicUserId,
                speedUnits: user.speedUnits,
                chargeList: JSON.stringify(chargeList),
                costList: JSON.stringify(costList),
                revertToDefaultCharges: revertCharges,
                revertToDefaultCosts: revertCosts,
                tripId: tripDetails.tripId,
                isChargingRule: isCharging,
                isCostingRule: isCosting,
                getDistance: false,
                customerId: tripDetails.customerId === null ? null : tripDetails.customerId,
                addressChanged: addressChange,
                rateGroupChanged: rateGroupChange,
                assignedTeamMemberPublicId: tripDetails.assignToPublicUserId
                    ? tripDetails.assignToPublicUserId
                    : tripDetails.assignedTo?.publicUserId,
                distance: tripDetails.travelDistanceMetres,
                tripStartTime: this.tripDetails.actualStartTime,
                startTime: this.tripDetails.startTime,
                userDefaultTripStartTime: this.tripDetails.userDefaultTripStartTime,
                tripEndTime: this.tripDetails.actualEndTime,
                endTime: this.tripDetails.endTime,
                userDefaultTripEndTime: this.tripDetails.userDefaultTripEndTime,
                tripEstimatedEndTime: this.tripDetails.locationDetails.endEta,
                userTimeZone: this.tripDetails.userTimeZone,
                isChargesApproved: this.tripDetails.isChargesApproved,
                isCostsApproved: this.tripDetails.isCostsApproved
            };

            return data;
        },
        costLinesAreCommissionBased(costs, teamMemberInvoiceId) {
            const baseCharge = costs.find((c) => {
                return c.isBaseCharge === true;
            });

            if (baseCharge == null) 
                return false;

            if (baseCharge.type !== 'commission') {
                return false;
            }

            if (teamMemberInvoiceId) {
                return false;
            }

            return true;
        },
        getLocation(historyEvents, status) {
            if (!historyEvents || historyEvents?.length === 0) 
                return null;

            const statuses = status;
            const latestFinishTimes = historyEvents.filter((status) => statuses.includes(status.newStatus));

            if (latestFinishTimes.length === 0) {
                return null;
            }

            latestFinishTimes.sort((a, b) => a.actionDate - b.actionDate);

            return latestFinishTimes.pop().location;
        },
        getKeyByLabelInApplyRatesTo(label) {
            const applyRatesToKey = APPLY_RATES_TO.filter(function compareLabels(type) {
                if (type.label.toLowerCase() === label.toLowerCase()) 
                    return type.key;
                return null;
            });
            if (applyRatesToKey.length > 0) 
                return applyRatesToKey[0].key;
            return 0;
        }
    }
};
