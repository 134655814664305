<template>
    <div class="md-layout-item condition-container">
        <div>
            <label class="condition-label">{{ conditionBlockLabel }}</label>
            <form-group
                v-show="!isFallbackCondition"
                name="name"
                attribute="name"
                class="condition-name"
                :class="{
                    error: validator.name.$error
                }"
            >
                <md-input v-model="condition.name" placeholder="Condition Name" :disabled="isFallbackCondition" />
            </form-group>
        </div>

        <ConditionBuilderItem
            v-for="(c, ndx) in validator.subConditions.$each.$iter"
            :sub-condition="c.$model"
            :show-operator="ndx > 0"
            :key="c.$model.tempKey"
            :order="c.$model.order"
            :validator="c"
            @subConditionRemoved="subConditionRemovedHandler"
            @subConditionChanged="subConditionChangedHandler"
        />
        <div v-if="!isFallbackCondition" class="sub-condition-button-wrapper">
            <md-button @click.prevent="addSubCondition">Add Sub-condition</md-button>
        </div>

        <div>
            <label class="operator-wrapper">Assign to</label>
            <div class="lg-condition-wrapper">
                <form-group
                    name="operator"
                    label="Carrier"
                    :class="{
                        error: validator.assignTo.$error
                    }"
                >
                    <md-select v-model="condition.assignTo">
                        <md-option v-for="carrier in activeCarriers" :value="carrier.key" :key="`c-${carrier.key}`">
                            {{ carrier.text }}
                        </md-option>
                    </md-select>
                </form-group>
            </div>
            <div class="lg-condition-wrapper">
                <form-group
                    name="preference"
                    label="Preference"
                    :class="{
                        error: validator.assignToOption.$error
                    }"
                >
                    <md-select v-model="condition.assignToOption">
                        <md-option v-for="act in assignToOptions" :value="act" :key="`o-${act}`">
                            {{ act }}
                        </md-option>
                    </md-select>
                </form-group>
            </div>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import { CUSTOM_SUB_CONDITION_DEFAULTS } from '@/utils/defaults';
import ConditionBuilderItem from './ConditionBuilderItem';

export default {
    name: 'ConditionBuilder',
    components: { ConditionBuilderItem },
    props: {
        condition: { type: Object, default: () => {} },
        validator: { type: Object, required: true }
    },
    data() {
        return {
            action: null,
            assignToOptions: ['Cheapest', 'Fastest']
        };
    },
    computed: {
        ...mapGetters({
            allTeamCarriers: 'team/teamCarriers'
        }),
        activeCarriers() {
            let activeCarriers = [];
            if (this.allTeamCarriers && this.allTeamCarriers.length) {
                activeCarriers = this.allTeamCarriers
                    .filter((x) => x.status === 'Active')
                    .map((x) => {
                        return {
                            key: x.carrierPublicTeamId,
                            text: x.carrierTeam.company
                        };
                    });
            }
            activeCarriers.unshift({ key: 'Any', text: 'Any' });
            return activeCarriers;
        },
        subConditions() {
            if (this.condition) 
                return this.condition.subConditions || [];
            return [];
        },
        isFallbackCondition() {
            return this.condition && this.condition.isFallbackCondition;
        },
        conditionBlockLabel() {
            if (this.isFallbackCondition) {
                return this.condition.name;
            }
            return `Condition Block #${this.condition.order}`;
        }
    },
    methods: {
        addSubCondition() {
            this.condition.subConditions.push({
                ...CUSTOM_SUB_CONDITION_DEFAULTS(),
                order: this.condition.subConditions.length + 1,
                tempKey: _.random(-1, -10000)
            });
        },
        subConditionRemovedHandler(order) {
            const current = cloneDeep(this.condition.subConditions);
            const orderIndex = current.findIndex((x) => x.order === order);
            current.splice(orderIndex, 1);
            if (current.length) {
                current[0].mainOperator = null;

                // update subcondition order
                for (let i = 0; i < current.length; i++) {
                    current[i].order = i + 1;
                }

                this.$set(this.condition, 'subConditions', [...current]);
            } else {
                this.$set(this.condition, 'subConditions', []);
                this.$emit('conditionRemoved', this.condition.order);
            }
        },
        subConditionChangedHandler(args) {
            const subConditionIndex = this.condition.subConditions.findIndex((x) => x.order === args.order);
            let updatedSubCondition = this.condition.subConditions[subConditionIndex];
            updatedSubCondition = { ...updatedSubCondition, ...args };
            this.$set(this.condition.subConditions, subConditionIndex, updatedSubCondition);
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .form-wrapper {
    width: 100%;
}
.operator-wrapper {
    margin: 5px;
    width: 90px;
    display: inline-block;
}

.lg-condition-wrapper {
    margin: 5px;
    width: 170px;
    padding: 2px;
    display: inline-block;
}

.condition-container {
    border: solid 1px green;
    padding: 15px;
    margin-top: 10px;
}

.condition-label {
    font-weight: bold;
    color: grey;
    display: inline-block;
}

.error {
    border: solid 1px red;
}

.condition-name {
    display: inline-block;
    width: 350px;
    margin-left: 25px;
}
</style>
