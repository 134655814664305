var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item" }, [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c(
            "div",
            { staticClass: "custom-toolbar-start" },
            [
              _c("customer-filter-options", {
                staticClass: "filter-steps--choices filter-status",
                attrs: { "filter-customer-id": _vm.customerIdFilterValue },
                on: { onCustomerSelected: _vm.handleCustomerChanged },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "custom-toolbar-end" },
            [
              _vm.$root.isDesktop
                ? _c("search-component", {
                    attrs: { "search-filter": _vm.currentSearchTextValue },
                    on: { onSearch: _vm.handleFilterOrSearch },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-green" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon card-icon-text" },
                    [
                      _c("md-icon", [_vm._v("star")]),
                      _vm._v(
                        "\n                        Frequent Addresses\n                    "
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("md-card-content", [
                _c("div", { staticClass: "cf-container" }, [
                  _c(
                    "div",
                    { staticClass: "cf-action-container" },
                    [
                      !_vm.isCustomer
                        ? _c(
                            "md-button",
                            {
                              staticClass: "md-primary md-just-icon md-round",
                              attrs: { title: "Add Frequent Address" },
                              on: { click: _vm.emitAdd },
                            },
                            [_c("md-icon", [_vm._v("add")])],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.frequentAddressesList.length != 0
                    ? _c(
                        "div",
                        [
                          _c("frequent-addresses-list", {
                            attrs: {
                              "list-of-frequent-addresses":
                                _vm.frequentAddressesList,
                              "customer-id-filter": _vm.customerIdFilterValue,
                              "search-filter": _vm.currentSearchTextValue,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.frequentAddressesList.length == 0
                    ? _c("div", [
                        _c("p", { staticClass: "no-result-message" }, [
                          _vm._v("No Frequent Addresses configured"),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }