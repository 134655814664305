var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.filters && _vm.filters.length > 0
        ? _c(
            "form-group",
            { attrs: { name: "filter.status", label: "Filter" } },
            [
              _c(
                "md-select",
                {
                  attrs: { id: "filter-status-select" },
                  model: {
                    value: _vm.nonWebhookStatusFilter,
                    callback: function ($$v) {
                      _vm.nonWebhookStatusFilter = $$v
                    },
                    expression: "nonWebhookStatusFilter",
                  },
                },
                _vm._l(_vm.filters, function (item) {
                  return _c(
                    "md-option",
                    {
                      key: item,
                      attrs: {
                        value: item,
                        "data-test-id": "create-email-action-filter-" + item,
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item) +
                          "\n                "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "form-group",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isIncludeProofOfDeliveryInEmailApplicable,
              expression: "isIncludeProofOfDeliveryInEmailApplicable",
            },
          ],
          staticClass: "checkbox-container",
          attrs: { name: "settings.includeProofOfDeliveryInEmail" },
        },
        [
          _c(
            "md-checkbox",
            {
              attrs: { id: "includeProofOfDeliveryInEmail" },
              model: {
                value:
                  _vm.customActionModel.settings.includeProofOfDeliveryInEmail,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.customActionModel.settings,
                    "includeProofOfDeliveryInEmail",
                    $$v
                  )
                },
                expression:
                  "customActionModel.settings.includeProofOfDeliveryInEmail",
              },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.includeProofOfDeliveryInEmailText) +
                  "\n            "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "form-group",
        {
          attrs: {
            name: "settings.emailRecipientTypes",
            label: "Recipient(s)",
          },
        },
        [
          _c(
            "md-select",
            {
              attrs: { id: "emailRecipientTypes", multiple: "" },
              model: {
                value: _vm.customActionModel.settings.emailRecipientTypes,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.customActionModel.settings,
                    "emailRecipientTypes",
                    $$v
                  )
                },
                expression: "customActionModel.settings.emailRecipientTypes",
              },
            },
            _vm._l(_vm.emailRecipientOptions, function (item) {
              return _c(
                "md-option",
                {
                  key: item.value,
                  attrs: {
                    value: item.value,
                    "data-test-id":
                      "create-email-action-recipient-types-" + item.value,
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.text) +
                      "\n                "
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm.customActionModel.settings.emailRecipientTypes &&
      _vm.customActionModel.settings.emailRecipientTypes.includes("Specified")
        ? _c(
            "form-group",
            {
              attrs: {
                name: "settings.recipientEmailAddresses",
                label: "Email address(es)",
              },
            },
            [
              _c("md-input", {
                attrs: { id: "recipient-addresses-input" },
                model: {
                  value: _vm.customActionModel.settings.recipientEmailAddresses,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.customActionModel.settings,
                      "recipientEmailAddresses",
                      $$v
                    )
                  },
                  expression:
                    "customActionModel.settings.recipientEmailAddresses",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "form-group",
        { attrs: { name: "settings.timeDelay.type", label: "Time Delay" } },
        [
          _c("TimeDelayType", {
            attrs: { value: _vm.customActionModel.settings.timeDelay.type },
            on: { changed: _vm.handleTimeDelayTypeChanged },
          }),
        ],
        1
      ),
      _vm.customActionModel.settings.timeDelay.type === "Delayed"
        ? _c(
            "form-group",
            {
              attrs: { name: "settings.timeDelay.delay", label: "Time Value" },
            },
            [
              _c("TimeSpanInput", {
                attrs: {
                  value: _vm.customActionModel.settings.timeDelay.delay,
                },
                on: { changed: _vm.handleTimeDelayChanged },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.customActionModel.settings.timeDelay.type === "Specified"
        ? _c(
            "form-group",
            {
              attrs: { name: "settings.timeDelay.specificTime", label: "Time" },
            },
            [
              _c("TimePicker", {
                attrs: {
                  time: _vm.customActionModel.settings.timeDelay.specificTime,
                  "all-time-options": false,
                  "clear-none": true,
                },
                on: { selectedTime: _vm.getStartTime },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.conditionVariables.length
        ? _c("CustomConditionBuilderList", {
            attrs: {
              "custom-action-model": _vm.customActionModel,
              validator: _vm.validator,
              "condition-variables": _vm.conditionVariables,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }