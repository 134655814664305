<template>
    <div>
        <form-group v-if="filters && filters.length > 0" name="filter.status" label="Filter">
            <md-select id="filter-status-select" v-model="nonWebhookStatusFilter">
                <md-option
                    v-for="item in filters"
                    :key="item"
                    :value="item"
                    :data-test-id="`create-email-action-filter-${item}`"
                >
                    {{ item }}
                </md-option>
            </md-select>
        </form-group>
        <form-group
            name="settings.includeProofOfDeliveryInEmail"
            class="checkbox-container"
            v-show="isIncludeProofOfDeliveryInEmailApplicable"
        >
            <md-checkbox
                id="includeProofOfDeliveryInEmail"
                v-model="customActionModel.settings.includeProofOfDeliveryInEmail"
            >
                {{ includeProofOfDeliveryInEmailText }}
            </md-checkbox>
        </form-group>
        <form-group name="settings.emailRecipientTypes" label="Recipient(s)">
            <md-select id="emailRecipientTypes" multiple v-model="customActionModel.settings.emailRecipientTypes">
                <md-option
                    v-for="item in emailRecipientOptions"
                    :key="item.value"
                    :value="item.value"
                    :data-test-id="`create-email-action-recipient-types-${item.value}`"
                >
                    {{ item.text }}
                </md-option>
            </md-select>
        </form-group>
        <form-group
            v-if="
                customActionModel.settings.emailRecipientTypes &&
                    customActionModel.settings.emailRecipientTypes.includes('Specified')
            "
            name="settings.recipientEmailAddresses"
            label="Email address(es)"
        >
            <md-input id="recipient-addresses-input" v-model="customActionModel.settings.recipientEmailAddresses" />
        </form-group>
        <!-- // hide it because we are yet to offer customised template
                <form-group name="Use system template" class="no-underline">
                    <md-checkbox
                        v-model="customActionModel.settings.useSystemTemplate"
                        :disabled="initialUseSystemTemplate"
                    >
                        Use default template
                    </md-checkbox>
                </form-group>
                <form-group
                    v-if="!customActionModel.settings.useSystemTemplate"
                    name="settings.emailTemplateId"
                    label="Custom Template Id"
                    class="keep-spacing"
                >
                    <md-input
                        id="custom-template-input"
                        v-model="customActionModel.settings.emailTemplateId"
                        @input="$v.customActionModel.settings.emailTemplateId.$touch()"
                        readonly
                    />
                </form-group>
-->
        <form-group name="settings.timeDelay.type" label="Time Delay">
            <TimeDelayType :value="customActionModel.settings.timeDelay.type" @changed="handleTimeDelayTypeChanged" />
        </form-group>
        <form-group
            v-if="customActionModel.settings.timeDelay.type === 'Delayed'"
            name="settings.timeDelay.delay"
            label="Time Value"
        >
            <TimeSpanInput :value="customActionModel.settings.timeDelay.delay" @changed="handleTimeDelayChanged" />
        </form-group>
        <form-group
            v-if="customActionModel.settings.timeDelay.type === 'Specified'"
            name="settings.timeDelay.specificTime"
            label="Time"
        >
            <TimePicker
                :time="customActionModel.settings.timeDelay.specificTime"
                :all-time-options="false"
                :clear-none="true"
                @selectedTime="getStartTime"
            />
        </form-group>
        <CustomConditionBuilderList
            v-if="conditionVariables.length"
            :custom-action-model="customActionModel"
            :validator="validator"
            :condition-variables="conditionVariables"
        />
    </div>
</template>

<script>
import {
    TEAM_CUSTOM_ACTION_EVENT_TRIGGERS,
    TEAM_CUSTOM_ACTION_EMAIL_RECIPIENT_OPTIONS,
    TEAM_CUSTOM_ACTION_TYPE_OPTIONS
} from '@/utils/CustomActionConstants';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { TimePicker } from '@/components';
import TimeSpanInput from '@/components/TimeSpanInput';
import TimeDelayType from '../components/TimeDelayType';
import CustomConditionBuilderList from '../components/CustomConditionBuilderList';

export default {
    name: 'ManageEmailAction',
    mixins: [GeneralMixin],
    props: {
        customActionModel: { type: Object, default: () => null },
        validator: { type: Object, required: true },
        isUpdate: { type: Boolean, default: false },
        conditionVariables: { type: Array, default: () => [] },
        resolve: { type: Function, default: () => {} }
    },
    components: { TimePicker, TimeSpanInput, TimeDelayType, CustomConditionBuilderList },
    data() {
        return {
            action: 'Email',
            eventTriggers: TEAM_CUSTOM_ACTION_EVENT_TRIGGERS,
            selectedEventTrigger: null,
            actionTypeOptions: TEAM_CUSTOM_ACTION_TYPE_OPTIONS,
            nonWebhookStatusFilter: null,
            initialUseSystemTemplate: false,
            timeDelayValue: null
        };
    },
    computed: {
        ...mapGetters('custom-actions', ['existingReplyToEmails']),
        filteredEventTriggers() {
            return this.eventTriggers
                .filter((x) => x.supportedActions.includes(this.action))
                .sort((a, b) => (a.displayText > b.displayText ? 1 : -1));
        },
        currentEventTrigger() {
            if (!this.customActionModel.eventTrigger) return null;

            return this.filteredEventTriggers.find((x) => x.eventTrigger === this.customActionModel.eventTrigger);
        },
        filters() {
            if (
                this.currentEventTrigger &&
                this.currentEventTrigger.emailConfig &&
                this.currentEventTrigger.emailConfig.filters &&
                this.currentEventTrigger.emailConfig.filters.statuses
            ) {
                return [...this.currentEventTrigger.emailConfig.filters.statuses];
            }

            return [];
        },
        isStatusMultiselect() {
            return this.customActionModel.action === 'Webhook';
        },
        emailRecipientOptions() {
            if (!this.currentEventTrigger) return [];

            let limitRecipients = [];
            if (this.currentEventTrigger.emailConfig?.limitRecipients) {
                [limitRecipients] = [this.currentEventTrigger.emailConfig.limitRecipients];
            }

            let recipientOptions = TEAM_CUSTOM_ACTION_EMAIL_RECIPIENT_OPTIONS;
            if (limitRecipients && limitRecipients.length) {
                recipientOptions = TEAM_CUSTOM_ACTION_EMAIL_RECIPIENT_OPTIONS.filter((x) =>
                    limitRecipients.includes(x.value)
                );
            }

            return recipientOptions;
        },
        isIncludeProofOfDeliveryInEmailApplicable() {
            // POD is only applicable for Complete and Failed stops
            return (
                this.customActionModel.eventTrigger === 'STOP-STATUS-CHANGED' &&
                (this.nonWebhookStatusFilter === 'Complete' || this.nonWebhookStatusFilter === 'Failed')
            );
        },
        includeProofOfDeliveryInEmailText() {
            let text = 'Include POD PDF attachment in email';

            if (this.nonWebhookStatusFilter === 'Failed') {
                text = 'Include failed PDF attachment in email';
            }

            return text;
        }
    },
    watch: {
        'customActionModel.eventTrigger': function(newVal, oldVal) {
            if (oldVal) {
                this.nonWebhookStatusFilter = null;
                this.customActionModel.settings.emailRecipientTypes = [];
            }

            this.updateIncludeProofOfDeliveryInEmailValue();
        },
        nonWebhookStatusFilter(newVal, oldVal) {
            this.customActionModel.filter.status = newVal ? [newVal] : [];

            // The email recipients for some triggers vary from filter to filter,
            // so I am resetting the recipients whenever the filter changes
            // to prevent saving invalid recipients.
            const shouldResetRecipientTypes =
                oldVal && oldVal !== newVal && this.customActionModel.eventTrigger === 'STOP-STATUS-CHANGED';
            if (shouldResetRecipientTypes) {
                this.customActionModel.settings.emailRecipientTypes = [];
            }

            this.updateIncludeProofOfDeliveryInEmailValue();
        },
        'customActionModel.settings.emailRecipientTypes': function(newVal) {
            if (!newVal || !newVal.includes('Specified')) {
                this.customActionModel.settings.recipientEmailAddresses = null;
            }
        },
        'customActionModel.settings.timeDelay.type': function(newVal, oldVal) {
            if (!newVal || newVal === 'Immediate') {
                this.customActionModel.settings.timeDelay.delay = null;
                this.customActionModel.settings.timeDelay.specificTime = null;
            }

            if (newVal) {
                if (newVal === 'Delayed') {
                    this.customActionModel.settings.timeDelay.specificTime = null;
                }

                if (newVal === 'Specified') {
                    this.customActionModel.settings.timeDelay.delay = null;
                }
            }
        }
    },
    methods: {
        getStartTime(selectedTime) {
            if (selectedTime) {
                const { appointmentTime } = selectedTime;
                this.customActionModel.settings.timeDelay.specificTime = appointmentTime;
            } else {
                this.customActionModel.settings.timeDelay.specificTime = null;
            }
        },
        async handleTimeDelayChanged(args) {
            this.customActionModel.settings.timeDelay.delay = args.formatted;
            this.timeDelayValue = args;
        },
        async handleTimeDelayTypeChanged(delayType) {
            this.customActionModel.settings.timeDelay.type = delayType;
        },
        updateIncludeProofOfDeliveryInEmailValue() {
            // Default for including POD is checked, but only apply the default if creating a new custom action
            if (this.isUpdate) {
                return;
            }

            this.customActionModel.settings.includeProofOfDeliveryInEmail = this.isIncludeProofOfDeliveryInEmailApplicable;
        }
    },
    async mounted() {
        // if there's a custom template id from backend, provide an option to use it or use the system template
        // but if there's no template id, force user to use the system template
        this.initialUseSystemTemplate =
            !this.customActionModel.settings.emailTemplateId || !this.customActionModel.settings.emailTemplateId.length;

        /* eslint-disable */
        if (this.isUpdate) {
            const { status } = this.customActionModel.filter;
            if (status.length) {
                this.nonWebhookStatusFilter = status[0];
            }
        }
        /* eslint-enable */
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-checkbox-label {
    opacity: 1 !important;
    color: black !important;
}

::v-deep div.md-field.no-underline:after {
    height: 0px;
}

::v-deep .md-checkbox {
    margin-top: 0px;
}

.md-field.checkbox-container {
    padding-bottom: 0;
    margin: 0;
}

.md-field.checkbox-container:after {
    height: 0;
}
</style>
