<template>
    <div class="team-region-member-controls-container">
        <div v-if="showTeamRegion && teamRegions && teamRegions.length > 0">
            <form-group name="teamRegion" label="Team Region">
                <team-region-options
                    :selected="selectedValues.teamRegionId"
                    @selectedOption="handleTeamRegionChanged"
                    :mode="mode"
                />
            </form-group>
        </div>
        <div v-if="!isSingleUser && showTeamMembers">
            <form-group name="member" label="Team Member" class="team-member">
                <!-- <md-input v-model="selectedValues.publicUserId" type="hidden" /> -->
                <vue-select
                    data-testid="member-carrier-id"
                    :reduce="(item) => item.memberOrCarrierId"
                    label="fullName"
                    :options="filteredTeamMemberOptions"
                    v-model="selectedValues.memberOrCarrierId"
                    :searchable="$root.isDesktop"
                    :selectable="(option) => option.selectable"
                >
                    <template v-slot:option="option">
                        <span v-if="option.selectable">{{ option.fullName }}</span>
                        <hr v-else />
                    </template>
                </vue-select>
            </form-group>
        </div>
    </div>
</template>

<script>
import TeamRegionOptions from '@/components/TeamRegionOptions';
import { filterMemberOrCarrierssByTeamRegionId } from '@/helpers/index';
import { mapGetters } from 'vuex';

export default {
    name: 'TeamRegionMemberControls',
    props: {
        // eslint-disable-next-line require-prop-types
        teamRegionId: {
            type: Number,
            default: null
        },
        publicUserId: {
            type: [String, Number],
            default: null
        },
        carrierTeamId: {
            type: Number,
            default: null
        },
        mode: {
            type: String,
            default: 'create'
        },
        showTeamRegion: {
            type: Boolean,
            default: true
        },
        strictTeamRegionFilter: {
            type: Boolean,
            default: true
        },
        showLabelIfMemberNotInTeamRegion: {
            type: Boolean,
            default: false
        },
        showCarriers: {
            type: Boolean,
            default: false
        },
        showTeamMembers: {
            type: Boolean,
            default: true
        },
        showChildTeamRegion: {
            type: Boolean,
            default: false
        }
    },
    components: { TeamRegionOptions },
    data() {
        return {
            selectedValues: { teamRegionId: 0, memberOrCarrierId: 0 },
            defaultTeamMemberOption: {
                publicUserId: 0,
                fullName: 'Unassigned',
                email: null,
                teamRegionId: null,
                teamId: null,
                type: 'member',
                selectable: true,
                memberOrCarrierId: 0
            },
            memberOrCarrierName: null,
            teamRegionChanged: false,
            filterTeamRegionIds: []
        };
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            allTeamMembers: 'team/teamMembers',
            allTeamCarriers: 'team/teamCarriers'
        }),
        filteredTeamMemberOptions() {
            return filterMemberOrCarrierssByTeamRegionId({
                allTeamRegions: this.teamRegions,
                allRawMembers: this.allTeamMembers,
                defaults: this.defaultTeamMemberOption,
                teamRegionId: this.selectedValues.teamRegionId,
                strictTeamRegionFilter: this.strictTeamRegionFilter,
                allRawTeamCarriers: this.showCarriers ? this.allTeamCarriers : [],
                showChildTeamRegion: this.showChildTeamRegion,
                listOfTeamRegion: this.filterTeamRegionIds
            });
        },
        selectedMemberOrCarrier() {
            if (this.selectedValues && this.selectedValues.memberOrCarrierId) {
                const found = this.filteredTeamMemberOptions.find(
                    (x) => x.memberOrCarrierId === this.selectedValues.memberOrCarrierId
                );
                return { ...found };
            }
            return null;
        }
    },
    watch: {
        teamRegionId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.handleTeamRegionChanged(newVal);
            }
        },
        publicUserId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.updateSelectedMemberOrCarrier();
            }
        },
        carrierTeamId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.updateSelectedMemberOrCarrier();
            }
        },
        'selectedValues.memberOrCarrierId': function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.emitChanges({ source: 'watch-selectedMemberOrCarrierId' });
            }
        },
        selectedMemberOrCarrier(newVal, oldVal) {
            this.memberOrCarrierName = newVal?.fullName;
        }
    },
    methods: {
        handleTeamRegionChanged(teamRegionId) {
            const teamRegionIdToUse = !teamRegionId ? 0 : teamRegionId;
            this.selectedValues = { teamRegionId: teamRegionIdToUse, memberOrCarrierId: 0 };
            this.teamRegionChanged = true;
            this.updateSelectedMemberOrCarrier();
            this.teamRegionChanged = false;
            this.emitChanges({ source: 'handleTeamRegionChanged' });
        },
        updateSelectedMemberOrCarrier() {
            this.getParentTeamRegion();
            // Note: when, ex. trip (and all its stops) is assigned to a carrier team
            // but since Stop cannot be re-assigned to a carrier team individually, the carriers are excluded in the 'Assign Stop to Member' dropdown
            // we still need to display the assigned Carrier but should not be selectable
            let displayText = null;

            const { publicUserId } = this;
            const { carrierTeamId } = this;
            let found = null;
            if (publicUserId && publicUserId !== 0) {
                found = this.filteredTeamMemberOptions.find((x) => x.publicUserId === publicUserId);

                if (!found) {
                    const member = this.allTeamMembers.find((x) => x.publicUserId === publicUserId);
                    if (member) 
                        displayText = member.fullName;
                }
            } else if (carrierTeamId && carrierTeamId !== 0) {
                found = this.filteredTeamMemberOptions.find((x) => x.carrierTeamId === carrierTeamId);

                if (!found) {
                    const carrier = this.allTeamCarriers.find((x) => x.carrierTeamId === carrierTeamId);
                    if (carrier) 
                        displayText = carrier.carrierTeam.company;
                }
            }

            if (found) {
                this.selectedValues = { ...this.selectedValues, memberOrCarrierId: found.memberOrCarrierId };
                return;
            }

            if (this.teamRegionChanged) 
                this.selectedValues = { ...this.selectedValues, memberOrCarrierId: 0 };
            else 
                this.selectedValues = { ...this.selectedValues, memberOrCarrierId: displayText || 0 };
        },
        emitChanges(moreValues) {
            let eventArgs = {
                teamRegionId: !this.selectedValues.teamRegionId ? null : this.selectedValues.teamRegionId,
                publicUserId:
                    this.selectedMemberOrCarrier && this.selectedMemberOrCarrier.type === 'member'
                        ? this.selectedMemberOrCarrier.publicUserId
                        : null,
                carrierTeamId:
                    this.selectedMemberOrCarrier && this.selectedMemberOrCarrier.type === 'carrier'
                        ? this.selectedMemberOrCarrier.carrierTeamId
                        : null
            };

            if (!eventArgs.publicUserId && !eventArgs.carrierTeamId) {
                // means not found from valid selections, or it's unassigned
                if (this.selectedValues.memberOrCarrierId && !Number.isInteger(this.selectedValues.memberOrCarrierId)) {
                    // we'll still emit the original value
                    eventArgs = {
                        ...eventArgs,
                        publicUserId: this.publicUserId || null,
                        carrierTeamId: this.carrierTeamId || null
                    };
                }
            }
            let teamRegion = null;
            let teamMember = null;
            if (eventArgs.teamRegionId) {
                teamRegion = this.teamRegions.find((x) => x.teamRegionId === eventArgs.teamRegionId);
            }
            if (eventArgs.publicUserId) {
                teamMember = this.allTeamMembers.find((x) => x.publicUserId === eventArgs.publicUserId);
            }
            this.$emit('onChanged', { teamRegion, teamMember, ...eventArgs, ...moreValues });
        },
        getParentTeamRegion() {
            this.filterTeamRegionIds = [];
            let currentLevel = null;
            let regionId = null;

            if (this.selectedValues.teamRegionId == null || this.selectedValues.teamRegionId === -1) 
                return;

            this.filterTeamRegionIds.push(this.selectedValues.teamRegionId);
            const currentTeamRegion = this.teamRegions.find((x) => x.teamRegionId === this.selectedValues.teamRegionId);
            currentLevel = currentTeamRegion.level;

            if (currentTeamRegion.parentTeamRegionId != null) {
                regionId = currentTeamRegion.parentTeamRegionId;
                this.filterTeamRegionIds.push(currentTeamRegion.parentTeamRegionId);
            }

            if (currentLevel > 1) {
                for (let i = currentLevel - 1; i > 1; i--) {
                    // eslint-disable-next-line no-loop-func
                    const region = this.teamRegions.find((x) => x.teamRegionId === regionId);
                    if ((region !== null && region.parentTeamRegionId != null) || region.parentTeamRegionId !== 0) {
                        regionId = region.parentTeamRegionId;
                        this.filterTeamRegionIds.push(region.parentTeamRegionId);
                    }
                }
            }
        }
    },
    mounted() {
        this.selectedValues.teamRegionId = this.teamRegionId;
        this.updateSelectedMemberOrCarrier();
    }
};
</script>

<style scoped>
::v-deep .md-field.md-theme-default label {
    top: -0.8rem !important;
    font-size: 0.6875rem !important;
}
</style>
