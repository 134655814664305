var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout-item condition-container" },
    [
      _c(
        "div",
        [
          _c("label", { staticClass: "condition-label" }, [
            _vm._v(_vm._s(_vm.conditionBlockLabel)),
          ]),
          _c(
            "form-group",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFallbackCondition,
                  expression: "!isFallbackCondition",
                },
              ],
              staticClass: "condition-name",
              class: {
                error: _vm.validator.name.$error,
              },
              attrs: { name: "name", attribute: "name" },
            },
            [
              _c("md-input", {
                attrs: {
                  placeholder: "Condition Name",
                  disabled: _vm.isFallbackCondition,
                },
                model: {
                  value: _vm.condition.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.condition, "name", $$v)
                  },
                  expression: "condition.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.validator.subConditions.$each.$iter, function (c, ndx) {
        return _c("CustomConditionBuilderItem", {
          key: c.$model.tempKey,
          attrs: {
            "sub-condition": c.$model,
            "show-operator": ndx > 0,
            order: c.$model.order,
            validator: c,
            conditionVariables: _vm.conditionVariables,
          },
          on: {
            subConditionRemoved: _vm.subConditionRemovedHandler,
            subConditionChanged: _vm.subConditionChangedHandler,
          },
        })
      }),
      !_vm.isFallbackCondition
        ? _c(
            "div",
            { staticClass: "sub-condition-button-wrapper" },
            [
              _c(
                "md-button",
                {
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addSubCondition($event)
                    },
                  },
                },
                [_vm._v("Add Sub-condition")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }