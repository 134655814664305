var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "md-button",
        {
          staticClass:
            "md-primary md-just-icon md-round pull-right header-button",
          attrs: { title: "Add Shipment", disabled: _vm.isCustomer },
          on: { click: _vm.createShipment },
        },
        [_c("md-icon", [_vm._v("add")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }