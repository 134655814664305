var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-table",
    { staticClass: "custom-paginated-table" },
    [
      _c(
        "md-table-row",
        [
          _c("md-table-head", [_vm._v("Address Code")]),
          _c("md-table-head", [_vm._v("Address")]),
          _c("md-table-head", [_vm._v("Contact Name")]),
          _c("md-table-head", [_vm._v("Email")]),
          _c("md-table-head", [_vm._v("Phone")]),
          _c("md-table-head", [_vm._v("Customer")]),
          _c("md-table-head"),
        ],
        1
      ),
      _vm._l(_vm.filteredlistOfFrequentAddresses, function (frequentAddress) {
        return _c(
          "md-table-row",
          { key: frequentAddress.Id },
          [
            _c("md-table-cell", [
              _vm._v(
                "\n            " +
                  _vm._s(frequentAddress.addressCode) +
                  "\n        "
              ),
            ]),
            _c(
              "md-table-cell",
              { staticClass: "frequent-address-col" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "Frequent Address Details",
                        params: {
                          frequentAddressId: frequentAddress.frequentAddressId,
                          editMode: false,
                        },
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(frequentAddress.address) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("md-table-cell", [
              _vm._v(
                "\n            " +
                  _vm._s(frequentAddress.contactName) +
                  "\n        "
              ),
            ]),
            _c("md-table-cell", [
              _vm._v(
                "\n            " + _vm._s(frequentAddress.email) + "\n        "
              ),
            ]),
            _c("md-table-cell", { staticClass: "phone-number-cell" }, [
              _vm._v(
                "\n            " + _vm._s(frequentAddress.phone) + "\n        "
              ),
            ]),
            _c("md-table-cell", [
              frequentAddress.customerId > 0
                ? _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(frequentAddress.customer.name) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "md-table-cell",
              [
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-round md-just-icon md-warning action-btn-icon",
                    attrs: { title: "Edit Frequent" },
                    on: {
                      click: function ($event) {
                        return _vm.editFrequentAddress(
                          frequentAddress.frequentAddressId
                        )
                      },
                    },
                  },
                  [_c("md-icon", [_vm._v("edit")])],
                  1
                ),
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-round md-just-icon md-danger action-btn-icon",
                    attrs: { title: "Delete Frequent Address" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteFrequentAddress(
                          frequentAddress.frequentAddressId
                        )
                      },
                    },
                  },
                  [_c("md-icon", [_vm._v("delete")])],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }