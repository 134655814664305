var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "charges-editor-page" },
    [
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-content",
                  [
                    _c(
                      "collapse",
                      {
                        staticClass: "collapse-panel",
                        attrs: {
                          collapse: ["Filters"],
                          icon: "keyboard_arrow_down",
                          "color-collapse": "success",
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "md-collapse-pane-1" },
                          [
                            _c("process-charge-overview-toolbar", {
                              attrs: {
                                "team-region-id": _vm.user.teamRegionId,
                                "is-cost-approval-feature-enabled":
                                  _vm.isCostApprovalFeatureEnabled,
                                "is-charge-approval-feature-enabled":
                                  _vm.isChargeApprovalFeatureEnabled,
                                "service-packages": _vm.servicePackages,
                              },
                              on: {
                                onFilterProcessCharges: _vm.handleFilterChanged,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c(
              "md-card",
              { staticClass: "custom-card" },
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-warning" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("price_check")])],
                      1
                    ),
                  ]
                ),
                _c(
                  "md-card-content",
                  { staticClass: "body-list" },
                  [
                    _vm.selectedJobs.length > 1
                      ? _c(
                          "div",
                          {
                            staticClass: "bulk-section sticky-selection",
                            class: [{ fixed: _vm.isFixedSelector }],
                          },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.selectedJobs.length) +
                                  " job" +
                                  _vm._s(
                                    _vm.selectedJobs.length > 1 ? "s" : ""
                                  ) +
                                  " selected."
                              ),
                            ]),
                            _c(
                              "div",
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-round md-just-icon md-success",
                                    attrs: { title: "Recalculate jobs" },
                                    on: { click: _vm.bulkRecalculate },
                                  },
                                  [
                                    _c("md-icon", [
                                      _vm._v("currency_exchange"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-round md-just-icon md-success",
                                    attrs: { title: "Approve jobs" },
                                    on: { click: _vm.bulkApprove },
                                  },
                                  [_c("md-icon", [_vm._v("thumb_up")])],
                                  1
                                ),
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-round md-just-icon md-danger",
                                    attrs: { title: "Unapprove jobs" },
                                    on: { click: _vm.bulkUnapprove },
                                  },
                                  [_c("md-icon", [_vm._v("thumb_down")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("ProcessChargesTable", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "table-header",
                            fn: function () {
                              return [
                                _c("md-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSelectAll(_vm.isSelectAll)
                                    },
                                  },
                                  model: {
                                    value: _vm.isSelectAll,
                                    callback: function ($$v) {
                                      _vm.isSelectAll = $$v
                                    },
                                    expression: "isSelectAll",
                                  },
                                }),
                                _c("table-header-dropdown", {
                                  staticClass: "reference-column",
                                  attrs: {
                                    "column-icon": "keyboard_arrow_down",
                                    "default-text":
                                      _vm.tableColumnOptions.columns[
                                        _vm.columnKeys.referenceSource
                                      ].name,
                                    "dropdown-options":
                                      _vm.tableColumnKeyOptions.referenceSource,
                                    "selected-option":
                                      _vm.tableColumnOptions.columns[
                                        _vm.columnKeys.referenceSource
                                      ],
                                  },
                                  on: {
                                    selectedOption: function ($event) {
                                      return _vm.$_handleColumnSwitch(
                                        $event,
                                        _vm.columnKeys.referenceSource,
                                        "stops.defaults.stop-table-options"
                                      )
                                    },
                                  },
                                }),
                                _c("h5", [_vm._v("Trip Date")]),
                                _c("h5", { staticClass: "hide-column" }, [
                                  _vm._v("Customer"),
                                ]),
                                _c("h5", { staticClass: "hide-column" }, [
                                  _vm._v("Service Pkg"),
                                ]),
                                _c("h5", { staticClass: "time-header" }, [
                                  _vm._v("Time"),
                                ]),
                                _c("h5", { staticClass: "hide-column" }, [
                                  _vm._v("Attempt #"),
                                ]),
                                _c("h5", { staticClass: "hide-column" }, [
                                  _vm._v("Run #"),
                                ]),
                                _c("h5", [_vm._v("Status")]),
                                _c("h5", [_vm._v("Delivered By")]),
                                _c("table-header-dropdown", {
                                  staticClass: "finance-column",
                                  attrs: {
                                    "default-text": "Total Charge",
                                    "dropdown-options":
                                      _vm.totalChargeDropdownList,
                                    "selected-option":
                                      _vm.selectedTotalChargeDropdowns,
                                  },
                                  on: {
                                    selectedOption:
                                      _vm.handleSelectTotalChargeFilter,
                                  },
                                }),
                                _c("table-header-dropdown", {
                                  staticClass: "finance-column",
                                  attrs: {
                                    "default-text": "Total Cost",
                                    "dropdown-options":
                                      _vm.totalCostDropdownList,
                                    "selected-option":
                                      _vm.selectedTotalCostDropdowns,
                                  },
                                  on: {
                                    selectedOption:
                                      _vm.handleSelectTotalCostFilter,
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "finance-column hide-column" },
                                  [_c("h5", [_vm._v("Margin")])]
                                ),
                                _c("h5", [_vm._v("Actions")]),
                              ]
                            },
                            proxy: true,
                          },
                          _vm.jobChargeList !== null &&
                          _vm.jobChargeList !== undefined &&
                          _vm.jobChargeList.length > 0 &&
                          !_vm.isLoading
                            ? {
                                key: "table-content",
                                fn: function () {
                                  return _vm._l(
                                    _vm.jobChargeList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        { key: index },
                                        [
                                          _c("ActiveRow", {
                                            attrs: {
                                              "root-job": item,
                                              "selected-jobs": _vm.selectedJobs,
                                              "has-expanded-row":
                                                item.hasExpandedRow,
                                              "reference-header-value":
                                                _vm.tableColumnOptions.columns[
                                                  _vm.columnKeys.referenceSource
                                                ],
                                              "is-cost-approval-feature-enabled":
                                                _vm.isCostApprovalFeatureEnabled,
                                              "is-charge-approval-feature-enabled":
                                                _vm.isChargeApprovalFeatureEnabled,
                                              "service-packages":
                                                _vm.servicePackages,
                                            },
                                            on: {
                                              "row-click": function ($event) {
                                                return _vm.handleRowClick(index)
                                              },
                                              "update-rates": function (
                                                $event
                                              ) {
                                                return _vm.updateRates(item)
                                              },
                                              "approve-jobs": function (
                                                $event
                                              ) {
                                                return _vm.approveJobs(item)
                                              },
                                              "unapprove-jobs": function (
                                                $event
                                              ) {
                                                return _vm.unapproveJobs(item)
                                              },
                                              "update-service-package":
                                                function ($event) {
                                                  return _vm.updateServicePackage(
                                                    item
                                                  )
                                                },
                                              "update-time":
                                                _vm.handleTimeUpdated,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                item.childrenJobs.length > 0
                                                  ? {
                                                      key: "nested-table",
                                                      fn: function () {
                                                        return [
                                                          _c("NestedTable", {
                                                            attrs: {
                                                              "root-job": item,
                                                              "selected-jobs":
                                                                _vm.selectedJobs,
                                                              "reference-header-value":
                                                                _vm
                                                                  .tableColumnOptions
                                                                  .columns[
                                                                  _vm.columnKeys
                                                                    .referenceSource
                                                                ],
                                                            },
                                                            on: {
                                                              "update-rates":
                                                                _vm.updateRates,
                                                              "select-items":
                                                                _vm.handleNestedItemsSelected,
                                                              "deselect-items":
                                                                _vm.handleNestedItemsDeselected,
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    }
                                                  : null,
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                },
                                proxy: true,
                              }
                            : {
                                key: "table-content",
                                fn: function () {
                                  return [
                                    _vm.isLoading
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-loader loading-position",
                                          },
                                          [
                                            _c("fade-loader", {
                                              attrs: {
                                                loading: _vm.isLoading,
                                                color: "#333333",
                                              },
                                            }),
                                            _c("span", [_vm._v("LOADING")]),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "p",
                                          { staticClass: "no-result-message" },
                                          [
                                            _vm._v(
                                              "\n                                No results matching your search/filter could be found.\n                            "
                                            ),
                                          ]
                                        ),
                                  ]
                                },
                                proxy: true,
                              },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "md-card-actions",
        {
          staticClass: "page-footer",
          attrs: { "md-alignment": "space-between" },
        },
        [
          _c("div", [
            _vm.total === _vm.pagination.perPage
              ? _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "\n                Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of many\n            "
                  ),
                ])
              : _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of " +
                      _vm._s(_vm.totalPages)
                  ),
                ]),
            _c("p", [_vm._v("Total Jobs: " + _vm._s(_vm.pagination.total))]),
          ]),
          _c("pagination", {
            staticClass: "pagination-no-border pagination-success",
            attrs: { "per-page": _vm.pagination.perPage, total: _vm.total },
            on: {
              "change-page": function ($event) {
                return _vm.handleChangePage($event, _vm.pagination.perPage)
              },
            },
            model: {
              value: _vm.pagination.currentPage,
              callback: function ($$v) {
                _vm.$set(_vm.pagination, "currentPage", $$v)
              },
              expression: "pagination.currentPage",
            },
          }),
        ],
        1
      ),
      _vm.showProgressBox
        ? _c("div", { staticClass: "progress-container" }, [
            _c(
              "div",
              { staticClass: "button-close" },
              [
                _c(
                  "md-button",
                  {
                    staticClass: "md-default md-just-icon md-round",
                    on: { click: _vm.handleCloseProgressBox },
                  },
                  [_c("md-icon", [_vm._v("close")])],
                  1
                ),
              ],
              1
            ),
            !_vm.isProgressComplete
              ? _c("div", { staticClass: "progress-text-section" }, [
                  _vm._m(0),
                  _vm.totalToRecalculate == 0
                    ? _c("div", [_vm._v("Preparing...")])
                    : _c("div", [
                        _vm._v(
                          _vm._s(_vm.currentProcessed) +
                            " of " +
                            _vm._s(_vm.totalToRecalculate) +
                            " have been recalculated."
                        ),
                      ]),
                ])
              : _c("div", { staticClass: "progress-text-section" }, [
                  _c(
                    "div",
                    [
                      _c("md-icon", { staticClass: "green-check" }, [
                        _vm._v("check_circle"),
                      ]),
                    ],
                    1
                  ),
                  _c("div", [
                    _vm._v(
                      "\n                Recalculated Jobs.\n            "
                    ),
                  ]),
                ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("div", { staticClass: "btn-loader route-loader" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }