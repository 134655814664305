var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "detail-container" }, [
      _c(
        "div",
        { staticClass: "close-button" },
        [
          _c(
            "md-button",
            {
              staticClass: "md-default md-just-icon md-round pull-right",
              on: { click: _vm.closeModal },
            },
            [_c("md-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      !_vm.loading
        ? _c("div", [
            _c("div", { staticClass: "detail-section text-center" }, [
              _vm.stopDetails.stopRef != null
                ? _c(
                    "span",
                    { staticClass: "title blue-ref" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Stop Details",
                              params: { stopId: _vm.stopDetails.stopId },
                            },
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.stopDetails.stopRef) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _c(
                    "span",
                    { staticClass: "title" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Stop Details",
                              params: { stopId: _vm.stopDetails.stopId },
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.stopDetails.id) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
              _c(
                "div",
                { staticClass: "detail-group text-center action-detail-group" },
                [
                  _vm.stopDetails.assignedTo.publicUserId
                    ? _c("img", {
                        staticClass: "profile-image",
                        attrs: {
                          src:
                            _vm.stopDetails.assignedTo.photoUrl !== null
                              ? _vm.stopDetails.assignedTo.photoUrl
                              : _vm.$root.defaultPhotoUrl,
                          alt: "avatar",
                        },
                        on: { error: _vm.$_setDefaultBrokenImage },
                      })
                    : _vm._e(),
                  _vm.stopDetails.carrierTeam != null
                    ? _c("img", {
                        staticClass: "profile-image",
                        attrs: {
                          src:
                            _vm.stopDetails.carrierTeam.logoUrl !== null
                              ? _vm.stopDetails.carrierTeam.logoUrl
                              : _vm.$root.defaultLogoUrl,
                          alt: "avatar",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "router-link",
                    {
                      staticClass: "md-button md-success md-just-icon md-round",
                      attrs: {
                        title: "View stop details",
                        to: {
                          name: "Stop Details",
                          params: { stopId: _vm.stopDetails.stopId },
                        },
                        target: "_blank",
                      },
                    },
                    [
                      _c("div", { staticClass: "md-ripple" }, [
                        _c(
                          "div",
                          { staticClass: "md-button-content" },
                          [_c("md-icon", [_vm._v("visibility")])],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm.isCarrierAssigned
                    ? [
                        _vm.carrierTrackingUrl
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "md-button md-primary md-just-icon md-round",
                                attrs: {
                                  href: _vm.carrierTrackingUrl,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("div", { staticClass: "md-ripple" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-button-content" },
                                    [_c("md-icon", [_vm._v("place")])],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : [
                        _vm.stopDetails.trackingLinkUrl
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "md-button md-primary md-just-icon md-round",
                                attrs: {
                                  title: "Tracking Link",
                                  href: _vm.setTrackingLinkUrl(
                                    _vm.stopDetails.trackingLinkUrl
                                  ),
                                  target: "_blank",
                                },
                              },
                              [
                                _c("div", { staticClass: "md-ripple" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-button-content" },
                                    [_c("md-icon", [_vm._v("place")])],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _vm.stopDetails.trackingLinkUrl == null &&
                            !_vm.isReadOnlyUser
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "md-button md-default md-just-icon md-round",
                                attrs: {
                                  title: "Create tracking link",
                                  target: "_blank",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.createTrackingLink(
                                      _vm.stopDetails
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "md-ripple" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-button-content" },
                                    [
                                      _c("md-icon", [
                                        _vm._v("add_location_alt"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                  _c("update-stop-button", {
                    attrs: {
                      stop: _vm.stopDetails.stopId,
                      "team-members": _vm.teamMembers,
                      "is-failed":
                        _vm.stopDetails.status.toLowerCase() === "failed",
                      "carrier-team-id": _vm.stopDetails.carrierTeamId,
                    },
                    on: { stopUpdated: _vm.updateStop },
                  }),
                  !_vm.isSingleUser &&
                  !_vm.isSingleTeamMember &&
                  !_vm.isReadOnlyUser
                    ? _c("reassign-stop-button", {
                        attrs: {
                          type: "icon",
                          "class-name": "small-icon",
                          item: _vm.stopDetails,
                          "team-members": _vm.teamMembers,
                        },
                        on: { assignedUser: _vm.assignStop },
                      })
                    : _vm._e(),
                  !_vm.stopDetails.shipmentId && _vm.canCloneJobs
                    ? _c("clone-job", {
                        attrs: {
                          "stop-id": _vm.stopDetails.stopId,
                          "stop-details": _vm.stopDetails,
                          "use-icon": true,
                        },
                      })
                    : _vm._e(),
                  _c("delete-stop-button", {
                    attrs: {
                      "stop-id": _vm.stopDetails.stopId,
                      "team-members": _vm.teamMembers,
                      "is-disabled": _vm.stopDetails.type != null,
                      "has-attached-items": _vm.stopDetails.items.length > 0,
                    },
                    on: { stopDeleted: _vm.deleteStop },
                  }),
                  _vm.stopDetails.items.length > 0
                    ? _c("generate-label-button", {
                        attrs: { id: _vm.stopDetails.stopId },
                      })
                    : _vm._e(),
                  !_vm.stopDetails.carrierTeamId
                    ? _c("stop-pdf-download-button", {
                        attrs: {
                          status: _vm.stopDetails.status.toLowerCase(),
                          "stop-type": _vm.stopDetails.type,
                          "stop-id": _vm.stopDetails.stopId,
                          "stop-ref": _vm.stopDetails.stopRef,
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "line-separator" }),
            _c("div", { staticClass: "detail-section" }, [
              _c("span", { staticClass: "title" }, [_vm._v("Stop Details")]),
              _vm.stopDetails.shipmentId > 0
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Shipment Ref:"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "Shipment Details",
                                params: {
                                  shipmentId: _vm.stopDetails.shipmentId,
                                },
                              },
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.stopDetails.stopRef.slice(0, -2)) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.stopDetails.carrierTeamId == null
                ? _c(
                    "div",
                    { staticClass: "detail-group" },
                    [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Assigned To:"),
                      ]),
                      _vm.isAssignedTo()
                        ? _c("reassign-stop-button", {
                            attrs: {
                              type: "text",
                              item: _vm.stopDetails,
                              "team-members": _vm.teamMembers,
                            },
                            on: { assignedUser: _vm.assignStop },
                          })
                        : _c("reassign-stop-button", {
                            attrs: {
                              type: "button",
                              "class-name": "custom-btn",
                              item: _vm.stopDetails,
                              "team-members": _vm.teamMembers,
                            },
                            on: { assignedUser: _vm.assignStop },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.stopDetails.rating
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("Rating:")]),
                    _c("div", { staticClass: "value" }, [
                      _c(
                        "div",
                        { staticClass: "rating" },
                        _vm._l(5, function (index) {
                          return _c(
                            "md-icon",
                            {
                              key: index,
                              style: {
                                color:
                                  index <= _vm.stopDetails.rating
                                    ? "orange"
                                    : "rgba(0,0,0,0.54",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                star_rate\n                            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.stopDetails.type != null
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("Type:")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.stopDetails.type) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Status:")]),
                _c(
                  "span",
                  { staticClass: "value" },
                  [
                    _c("stop-status-button", {
                      attrs: { stop: _vm.stopDetails },
                      on: { statusUpdated: _vm.changeStopStatus },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("When:")]),
                _c("div", { staticClass: "value" }, [
                  _vm.stopDetails.tripDate == null &&
                  _vm.stopDetails.appointmentTime == null &&
                  _vm.stopDetails.timeWindowStart == null &&
                  _vm.stopDetails.timeWindowEnd == null
                    ? _c("span", [_c("i", [_vm._v("Unscheduled")])])
                    : _vm._e(),
                  _vm.stopDetails.tripDate != null
                    ? _c(
                        "span",
                        [
                          _vm.stopDetails.tripId && !_vm.isCarrierAssigned
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Trip Details",
                                      params: {
                                        tripId: _vm.stopDetails.tripId,
                                      },
                                    },
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          _vm.stopDetails.tripDate,
                                          _vm.DATE_TYPES.standardDate
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.stopDetails.tripDate,
                                        _vm.DATE_TYPES.standardDate
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.stopDetails.timeWindowStart != null &&
                  _vm.stopDetails.timeWindowEnd != null
                    ? _c("span", [
                        _vm._v(
                          "\n                            (" +
                            _vm._s(
                              _vm._f("timeFormat")(
                                _vm.stopDetails.timeWindowStart
                              )
                            ) +
                            " -\n                            " +
                            _vm._s(
                              _vm._f("timeFormat")(
                                _vm.stopDetails.timeWindowEnd
                              )
                            ) +
                            ")\n                        "
                        ),
                      ])
                    : _vm.stopDetails.appointmentTime != null
                    ? _c("span", [
                        _vm._v(
                          "\n                            (" +
                            _vm._s(
                              _vm._f("timeFormat")(
                                _vm.stopDetails.appointmentTime
                              )
                            ) +
                            ")\n                        "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm.stopDetails.originalEta != null &&
              _vm.stopDetails.status != "Arrived" &&
              _vm.stopDetails.status != "Departed" &&
              _vm.stopDetails.status != "Complete"
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("ETA:")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("dateTimeFormat")(
                              _vm.stopDetails.originalEta,
                              _vm.DATE_TYPES.standardDate
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Address:")]),
                _c("div", { staticClass: "value" }, [
                  _vm.stopDetails.name &&
                  !_vm.$_validateCoordinate(_vm.stopDetails.address)
                    ? _c("div", { staticClass: "address-name" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.stopDetails.name) +
                            "\n                        "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      staticClass: "address",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.viewAddress(_vm.stopDetails)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$_displayAddressName(_vm.stopDetails)) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Duration:")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.stopDetails.durationMinutes) + " minutes"),
                ]),
              ]),
              _vm.stopDetails.arrivalDate
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("Arrival:")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("dateTimeFormat")(
                              _vm.stopDetails.arrivalDate,
                              _vm.DATE_TYPES.standardDate
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.stopDetails.departureDate
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Departure:"),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("dateTimeFormat")(
                              _vm.stopDetails.departureDate,
                              _vm.DATE_TYPES.standardDate
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.teamRegionName
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Team Region:"),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.teamRegionName) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.stopDetails.runNumber || _vm.stopDetails.runName
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("Run:")]),
                    _vm.stopDetails.runName
                      ? _c("span", { staticClass: "value" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.stopDetails.runName) +
                              "\n                    "
                          ),
                        ])
                      : _c("span", { staticClass: "value" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.stopDetails.runNumber) +
                              "\n                    "
                          ),
                        ]),
                  ])
                : _vm._e(),
              _vm.stopDetails.teamMemberInvoiceId
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Team Member Invoice:"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "Team Member Invoice Details",
                                params: {
                                  invoiceId:
                                    _vm.stopDetails.teamMemberInvoiceId,
                                },
                              },
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.stopDetails.teamMemberInvoiceNumber
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            class:
                              "custom-badge invoice-badge payment-status-" +
                              _vm.stopDetails.teamMemberInvoiceStatus
                                .toLowerCase()
                                .replace(/\s+/g, ""),
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "inputs.paymentStatuses." +
                                      _vm.stopDetails.teamMemberInvoiceStatus
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.stopDetails.customerInvoiceId
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Customer Invoice:"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "Customer Invoice Details",
                                params: {
                                  invoiceId: _vm.stopDetails.customerInvoiceId,
                                },
                              },
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.stopDetails.customerInvoiceNumber) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            class:
                              "custom-badge invoice-badge payment-status-" +
                              _vm.stopDetails.customerInvoiceStatus
                                .toLowerCase()
                                .replace(/\s+/g, ""),
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "inputs.paymentStatuses." +
                                      _vm.stopDetails.customerInvoiceStatus
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm.stopDetails.carrierTeamId != null
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.stopDetails.carrierTeamId != null
              ? _c(
                  "div",
                  { staticClass: "detail-section contact-detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Carrier Details"),
                    ]),
                    _c("div", { staticClass: "detail-group" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Carrier:"),
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.stopDetails.carrierTeam.company) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "detail-group" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Reference #:"),
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.stopDetails.shipment.assignedCarrierBooking
                                .supportReference
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "detail-group" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Service Type:"),
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _c(
                          "span",
                          {
                            staticClass: "custom-badge",
                            class:
                              "status-" +
                              _vm.$_getServiceLevelBadgeColor(
                                _vm.stopDetails.shipment.assignedCarrierBooking
                                  .timeWindowEnd
                              ),
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    _vm.stopDetails.shipment
                                      .assignedCarrierBooking.serviceLevel
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "detail-group" }, [
                      _c("label", { staticClass: "label" }, [_vm._v("Price:")]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.stopDetails.shipment.assignedCarrierBooking
                                  .feeAfterTax,
                                _vm.stopDetails.shipment.assignedCarrierBooking
                                  .currency
                              )
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _vm.stopDetails.type == "Pick Up"
                      ? _c("div", { staticClass: "detail-group" }, [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Pickup ETA:"),
                          ]),
                          _vm.stopDetails.shipment.assignedCarrierBooking
                            .pickupEta
                            ? _c("span", { staticClass: "value" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("dateTimeFormat")(
                                        _vm.stopDetails.shipment
                                          .assignedCarrierBooking.pickupEta,
                                        _vm.DATE_TYPES.standardDate
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ])
                            : _c("span", { staticClass: "not-available" }, [
                                _vm._v(
                                  "\n                        Not available\n                    "
                                ),
                              ]),
                        ])
                      : _vm._e(),
                    _vm.stopDetails.type == "Drop"
                      ? _c("div", { staticClass: "detail-group" }, [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Drop ETA:"),
                          ]),
                          _vm.stopDetails.shipment.assignedCarrierBooking
                            .dropOffEta
                            ? _c("span", { staticClass: "value" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("dateTimeFormat")(
                                        _vm.stopDetails.shipment
                                          .assignedCarrierBooking.dropOffEta,
                                        _vm.DATE_TYPES.standardDate
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ])
                            : _c("span", { staticClass: "not-available" }, [
                                _vm._v(
                                  "\n                        Not available\n                    "
                                ),
                              ]),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "line-separator" }),
            _c(
              "div",
              { staticClass: "detail-section contact-detail-section" },
              [
                _c("span", { staticClass: "title" }, [
                  _vm._v("Contact Details"),
                ]),
                _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Name:")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.stopDetails.contact.name) +
                        "\n                    "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Phone:")]),
                  _c(
                    "a",
                    {
                      staticClass: "value link",
                      attrs: { href: "tel:" + _vm.stopDetails.contact.name },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.stopDetails.contact.phone) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Email:")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.stopDetails.contact.email) +
                        "\n                    "
                    ),
                  ]),
                ]),
              ]
            ),
            _vm.stopDetails.brandId != null
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.stopDetails.brandId != null
              ? _c("div", { staticClass: "detail-section" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("Brand")]),
                  _vm.stopDetails.brandLogoUrl
                    ? _c("img", {
                        staticClass: "brand-logo",
                        attrs: {
                          src:
                            _vm.stopDetails.brandLogoUrl !== null
                              ? _vm.stopDetails.brandLogoUrl
                              : _vm.$root.defaultPhotoUrl,
                          title: _vm.stopDetails.brandName,
                          alt: _vm.stopDetails.brandName,
                        },
                        on: { error: _vm.$_setDefaultBrokenImage },
                      })
                    : _vm._e(),
                  !_vm.stopDetails.brandLogoUrl
                    ? _c("span", [_vm._v(_vm._s(_vm.stopDetails.brandName))])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.stopDetails.skills.length > 0
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.stopDetails.skills.length > 0
              ? _c(
                  "div",
                  { staticClass: "detail-section contact-detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Required Skills"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "detail-group md-chips md-primary md-theme-default md-has-placeholder md-theme-default",
                      },
                      _vm._l(_vm.stopDetails.skills, function (skill, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "md-chip md-theme-default",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(skill) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
            _vm.stopCustomFieldList.length
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.stopCustomFieldList.length
              ? _c(
                  "div",
                  { staticClass: "detail-section contact-detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Custom fields"),
                    ]),
                    _vm._l(
                      _vm.stopCustomFieldList,
                      function (customField, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "detail-group" },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v(_vm._s(customField.label) + ":"),
                            ]),
                            _vm.customFieldValues !== null &&
                            _vm.customFieldValues[customField.name] !== null
                              ? _c("div", { staticClass: "value" }, [
                                  customField.type.toLowerCase() === "date"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm._f("dateTimeFormat")(
                                                _vm.customFieldValues[
                                                  customField.name
                                                ],
                                                _vm.DATE_TYPES.standardDate
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.customFieldValues[
                                              customField.name
                                            ]
                                          )
                                        ),
                                      ]),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm.stopDetails.shipmentId && _vm.shipmentCustomFieldList.length
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.stopDetails.shipmentId && _vm.shipmentCustomFieldList.length
              ? _c(
                  "div",
                  { staticClass: "detail-section contact-detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Shipment Custom fields"),
                    ]),
                    _vm._l(
                      _vm.shipmentCustomFieldList,
                      function (customField, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "detail-group" },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v(_vm._s(customField.label) + ":"),
                            ]),
                            _vm.shipmentCustomFieldValues !== null &&
                            _vm.shipmentCustomFieldValues[customField.name] !==
                              null
                              ? _c("div", { staticClass: "value" }, [
                                  customField.type.toLowerCase() === "date"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm._f("dateTimeFormat")(
                                                _vm.shipmentCustomFieldValues[
                                                  customField.name
                                                ],
                                                _vm.DATE_TYPES.standardDate
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.shipmentCustomFieldValues[
                                              customField.name
                                            ]
                                          )
                                        ),
                                      ]),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm.loadCapacityList.length
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.loadCapacityList.length
              ? _c(
                  "div",
                  { staticClass: "detail-section contact-detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [_vm._v("Load")]),
                    _vm._l(
                      _vm.loadCapacityList,
                      function (loadCapacity, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "detail-group" },
                          [
                            loadCapacity.type !== "dimensions"
                              ? _c("div", [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(_vm._s(loadCapacity.label) + ":"),
                                  ]),
                                  _vm.loadValues !== null &&
                                  _vm.loadValues[loadCapacity.type] !== null
                                    ? _c("div", { staticClass: "value" }, [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.loadValues[loadCapacity.type]
                                            ) +
                                            " " +
                                            _vm._s(loadCapacity.symbol) +
                                            "\n                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            loadCapacity.type === "dimensions"
                              ? _c("div", [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(_vm._s(loadCapacity.label)),
                                  ]),
                                  _vm.loadValues !== null &&
                                  _vm.loadValues[loadCapacity.type] !== null
                                    ? _c("div", { staticClass: "value" }, [
                                        _vm._v(
                                          "\n                            L: " +
                                            _vm._s(_vm.loadValues["length"]) +
                                            " " +
                                            _vm._s(loadCapacity.symbol) +
                                            " W: " +
                                            _vm._s(_vm.loadValues["width"]) +
                                            "\n                            " +
                                            _vm._s(loadCapacity.symbol) +
                                            " H: " +
                                            _vm._s(_vm.loadValues["height"]) +
                                            " " +
                                            _vm._s(loadCapacity.symbol) +
                                            "\n                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm.stopDetails.driverInstructions
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.stopDetails.driverInstructions
              ? _c(
                  "div",
                  { staticClass: "detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Driver's Instructions"),
                    ]),
                    _c("DriverInstructions", {
                      attrs: {
                        "driver-instructions":
                          _vm.stopDetails.driverInstructions,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.stopDetails.items.length > 0
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.stopDetails.items.length > 0
              ? _c(
                  "div",
                  { staticClass: "detail-section contact-detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [_vm._v("Items")]),
                    _vm._l(_vm.stopDetails.items, function (line, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          { staticClass: "detail-group item-reference" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  title: "View item details",
                                  to: {
                                    name: "Inventory Details",
                                    params: { itemId: line.itemId },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class:
                                      "custom-badge inventory-status-" +
                                      line.status
                                        .toLowerCase()
                                        .replace(/\s+/g, ""),
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t(
                                            "inputs.itemStatuses." + line.status
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "label status-label" },
                                  [_vm._v(_vm._s(line.description))]
                                ),
                              ]
                            ),
                            _c(
                              "md-tooltip",
                              {
                                staticClass: "line-tooltip",
                                attrs: { "md-direction": "left" },
                              },
                              [
                                line.currentLocationName
                                  ? _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(line.currentLocationName) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                                line.barcode
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(line.barcode) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.stopDetails.lines.length > 0
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.stopDetails.lines.length > 0
              ? _c(
                  "div",
                  { staticClass: "detail-section contact-detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [_vm._v("Services")]),
                    _vm._l(_vm.stopDetails.lines, function (line, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          { staticClass: "detail-group item-reference" },
                          [
                            _c("span", { staticClass: "label info-line" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(line.description) +
                                  "\n                            "
                              ),
                              _c("span", { staticClass: "quantity" }, [
                                _vm._v(
                                  "\n                                x " +
                                    _vm._s(line.quantity) +
                                    "\n                                "
                                ),
                                line.quantityType
                                  ? _c(
                                      "span",
                                      { staticClass: "quantity-type" },
                                      [_vm._v(_vm._s(line.quantityType))]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.stopDetails.notes
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.stopDetails.notes
              ? _c("div", { staticClass: "detail-section" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("Notes")]),
                  _c("div", { staticClass: "detail-group notes-content" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.stopDetails.notes) +
                        "\n                "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.stopDetails.batchImportId ||
            (_vm.stopDetails.importErrors &&
              _vm.stopDetails.importErrors.length > 0)
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.stopDetails.batchImportId ||
            (_vm.stopDetails.importErrors &&
              _vm.stopDetails.importErrors.length > 0)
              ? _c("div", { staticClass: "detail-section" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Import Details"),
                  ]),
                  _vm.stopDetails.batchImportId
                    ? _c("div", { staticClass: "detail-group" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Import Id:"),
                        ]),
                        _c("span", { staticClass: "value" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.stopDetails.batchImportId) +
                              "\n                    "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.stopDetails.importErrors &&
                  _vm.stopDetails.importErrors.length > 0
                    ? _c("div", { staticClass: "detail-group" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Import Errors:"),
                        ]),
                        _c("span", { staticClass: "value" }, [
                          _c(
                            "ul",
                            _vm._l(
                              _vm.stopDetails.importErrors,
                              function (importError, importErrorIndex) {
                                return _c("li", { key: importErrorIndex }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(importError) +
                                      "\n                            "
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _c("div", [
            _c(
              "div",
              { staticClass: "stopbar-loader" },
              [
                _c("fade-loader", {
                  attrs: { loading: _vm.loading, color: "#333333" },
                }),
                _c("span", [_vm._v("LOADING")]),
              ],
              1
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }