<template>
    <div class="filter-inline" v-if="brandList != null && brandList.length > 0">
        <form-group name="brand" label="Brand">
            <md-input v-model="brandValue" type="hidden" />
            <vue-select
                data-testid="brand"
                :reduce="(x) => x.brandId"
                label="name"
                :options="brandList"
                v-model="brandValue"
                :searchable="$root.isDesktop"
            ></vue-select>
        </form-group>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests } from '@/helpers';

export default {
    name: 'BrandFilter',
    props: {
        value: {
            type: Object,
            default: null
        },
        listOfBrands: {
            type: Array,
            default: null
        }
    },
    mixins: [GeneralMixin],
    async mounted() {
        if (this.listOfBrands == null) {
            await this.getBrands();
        } else {
            this.brandList = this.listOfBrands;
        }

        if (this.value && this.value.brandId) {
            // eslint-disable-next-line radix
            this.brandValue = this.value.brandId;
        } else {
            this.brandValue = null;
        }
    },
    methods: {
        async getBrands() {
            this.$_handleLoaderState(true);
            const api = '/api/brands';
            const response = await handleRequests(api);
            if (response.data !== null && response.data.length > 0) {
                this.brandList = response.data;
            }
            this.$_handleLoaderState(false);
        }
    },
    watch: {
        brandValue(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.value.brandId = newValue;
                this.$emit('input', this.value);
            }
        },
        'value.brandId': function(newValue) {
            this.brandValue = newValue;
        }
    },
    data() {
        return {
            brandList: [],
            brandValue: null
        };
    }
};
</script>
