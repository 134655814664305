// Values are the same as CustomActionEmailRecipientTypes in the backend
export const TEAM_CUSTOM_ACTION_EMAIL_RECIPIENT_OPTIONS = [
    {
        value: 'StopContact',
        text: 'Stop Contact'
    },
    {
        value: 'ShipmentContact',
        text: 'Shipment Contact'
    },
    {
        value: 'Customer',
        text: 'Customer - Email'
    },
    {
        value: 'CustomerContact',
        text: 'Customer - Contact Email'
    },
    {
        value: 'TeamMember',
        text: 'Assigned Team Member'
    },
    {
        value: 'TeamManager',
        text: 'Team Manager'
    },
    {
        value: 'Specified',
        text: 'Specific Email Addresses'
    }
];

export const TEAM_CUSTOM_ACTION_TYPE_OPTIONS = [
    {
        value: 'Email',
        text: 'Send Email'
    },
    {
        value: 'Sms',
        text: 'Send Sms'
    },
    {
        value: 'Webhook',
        text: 'Trigger Webhook'
    },
    {
        value: 'GetQuotesFromCarriers',
        text: 'Get quotes from carriers',
        group: 'Others'
    },
    {
        value: 'PlaceCarrierBooking',
        text: 'Place Booking with Carrier',
        group: 'Others'
    },
    {
        value: 'OfferToTeamMembers',
        text: 'Offer to Team Members',
        group: 'Others'
    }
];

// must be in-sync with backend
export const TEAM_CUSTOM_ACTION_EVENT_TRIGGERS = [
    {
        order: 11,
        eventTrigger: 'STOP-CREATED',
        displayText: 'Stop - Created',
        supportedActions: ['Email', 'OfferToTeamMembers'],
        emailConfig: {},
        smsConfig: {}
    },
    {
        order: 12,
        eventTrigger: 'STOP-ASSIGNED',
        displayText: 'Stop - Assigned',
        supportedActions: ['Email', 'Webhook', 'Sms'],
        emailConfig: {},
        smsConfig: {
            hasStopType: true
        }
    },
    {
        order: 13,
        eventTrigger: 'STOP-STATUS-CHANGED',
        displayText: 'Stop - Status Changed',
        supportedActions: ['Email', 'Webhook', 'Sms'],
        emailConfig: {
            filters: {
                statuses: ['Enroute', 'Complete', 'Failed']
            }
        },
        smsConfig: {
            filters: {
                statuses: ['Pending', 'Enroute', 'Arrived', 'Cancelled', 'Delayed', 'Complete', 'On Hold', 'Failed']
            },
            hasStopType: true
        }
    },
    {
        order: 14,
        eventTrigger: 'STOP-TRIP-STATUS-CHANGED',
        displayText: "Stop's Trip- Status Changed",
        supportedActions: ['Email', 'Sms'],
        emailConfig: {
            filters: {
                statuses: ['Started']
            }
        },
        smsConfig: {
            filters: {
                statuses: ['Started', 'Complete']
            },
            limitRecipients: ['StopContact', 'Customer', 'CustomerContact', 'Specified'],
            hasStopType: true
        }
    },
    {
        order: 15,
        eventTrigger: 'STOP-EVENT-MANUAL-TRIGGERED',
        displayText: 'Manual Triggered (Stop)',
        supportedActions: ['Sms'],
        emailConfig: {},
        smsConfig: {
            hasStopType: true,
            hideStopTypeFilter: false,
            limitRecipients: ['StopContact']
        }
    },
    {
        order: 16,
        eventTrigger: 'ORDER-EVENT-MANUAL-TRIGGERED',
        displayText: 'Manual Triggered (Order)',
        supportedActions: ['Sms'],
        emailConfig: {},
        smsConfig: {
            hasStopType: false,
            hideStopTypeFilter: false,
            limitRecipients: ['ShippingContact']
        }
    },
    {
        order: 17,
        eventTrigger: 'STOP-IMPORT-COMPLETED',
        displayText: 'Stop - Import Completed',
        supportedActions: ['Webhook'],
        emailConfig: {},
        smsConfig: {}
    },
    {
        order: 17,
        eventTrigger: 'STOP-ITEMS-STATUS-CHANGED',
        displayText: 'Stop - Items Status Changed',
        supportedActions: ['Email'],
        emailConfig: {
            filters: {
                statuses: ['NotDelivered']
            },
            limitRecipients: ['Customer', 'CustomerContact', 'TeamManager', 'Specified']
        },
        smsConfig: {}
    },
    {
        order: 21,
        eventTrigger: 'SHIPMENT-CREATED',
        displayText: 'Shipment - Created',
        supportedActions: ['Email', 'GetQuotesFromCarriers', 'OfferToTeamMembers'],
        emailConfig: {},
        smsConfig: {}
    },
    {
        order: 22,
        eventTrigger: 'SHIPMENT-ASSIGNED',
        displayText: 'Shipment - Assigned',
        supportedActions: ['Webhook'],
        emailConfig: {},
        smsConfig: {}
    },
    {
        order: 23,
        eventTrigger: 'SHIPMENT-TRIP-STATUS-CHANGED',
        displayText: "Shipment's Trip- Status Changed",
        supportedActions: ['Sms'],
        emailConfig: {
            filters: {
                statuses: ['Started']
            }
        },
        smsConfig: {
            filters: {
                statuses: ['Started', 'Complete']
            },
            limitRecipients: ['StopContact', 'Customer', 'CustomerContact', 'Specified'],
            hasStopType: false
        }
    },
    {
        order: 24,
        eventTrigger: 'SHIPMENT-IMPORT-COMPLETED',
        displayText: 'Shipment - Import Completed',
        supportedActions: ['Webhook'],
        emailConfig: {},
        smsConfig: {}
    },

    {
        order: 31,
        eventTrigger: 'TRIP-CREATED',
        displayText: 'Trip - Created',
        supportedActions: ['Webhook'],
        emailConfig: {},
        smsConfig: {}
    },
    {
        order: 32,
        eventTrigger: 'TRIP-ASSIGNED',
        displayText: 'Trip - Assigned',
        supportedActions: ['Webhook'],
        emailConfig: {},
        smsConfig: {}
    },
    {
        order: 33,
        eventTrigger: 'TRIP-STATUS-CHANGED',
        displayText: 'Trip - Status Changed',
        supportedActions: ['Email', 'Webhook'],
        emailConfig: {
            filters: {
                statuses: ['Started', 'Complete']
            },
            limitRecipients: ['TeamMember', 'TeamManager', 'Specified']
        },
        smsConfig: {
            filters: {
                statuses: ['Started', 'Complete']
            }
        }
    },
    {
        order: 41,
        eventTrigger: 'CUSTOMER-BOOKING',
        displayText: 'Customer Booking',
        supportedActions: ['Email'],
        emailConfig: {},
        smsConfig: {}
    },
    {
        order: 51,
        eventTrigger: 'NOTE-CREATED',
        displayText: 'Note - Created',
        supportedActions: ['Webhook'],
        emailConfig: {},
        smsConfig: {}
    },

    {
        order: 61,
        eventTrigger: 'STOP-LINK-CREATED',
        displayText: 'Stop Link Created',
        supportedActions: ['Sms'],
        emailConfig: {},
        smsConfig: {
            hasStopType: true
        }
    },
    {
        order: 62,
        eventTrigger: 'SHIPMENT-LINK-CREATED',
        displayText: 'Shipment Link Created',
        supportedActions: ['Sms'],
        emailConfig: {},
        smsConfig: {
            hideStopTypeFilter: true
        }
    },

    {
        order: 63,
        eventTrigger: 'GENERAL-LINK-CREATED',
        displayText: 'General Link Created',
        supportedActions: ['Sms'],
        emailConfig: {},
        smsConfig: {
            hideStopTypeFilter: true,
            limitRecipients: ['Specified']
        }
    },
    {
        order: 71,
        eventTrigger: 'REVIEW-FEEDBACK',
        displayText: 'Review Feedback',
        supportedActions: ['Email'],
        emailConfig: {
            filters: {
                statuses: ['All', 'Low']
            },
            limitRecipients: ['Specified']
        },
        smsConfig: {}
    },

    {
        order: 81,
        eventTrigger: 'SHIPMENT-QUOTES-GATHERED',
        displayText: 'Shipment Quotes Gathered',
        supportedActions: ['PlaceCarrierBooking'],
        emailConfig: {},
        smsConfig: {}
    },
    {
        order: 91,
        eventTrigger: 'JOB-OFFER-FAILED',
        displayText: 'Job Offer To Team Members Failed',
        supportedActions: ['Email'],
        emailConfig: {
            limitRecipients: ['TeamManager', 'Specified']
        },
        smsConfig: {}
    },
    {
        order: 91,
        eventTrigger: 'GEOFENCE-MOVEMENT',
        displayText: 'Geofence Movement',
        supportedActions: ['Email', 'Webhook'],
        emailConfig: {
            filters: {
                statuses: ['All', 'Enter', 'Leave']
            },
            limitRecipients: ['TeamMember', 'TeamManager', 'Specified']
        },
        smsConfig: {}
    },
    {
        order: 101,
        eventTrigger: 'TRACKER-OFFLINE-TIME-EXCEEDED',
        displayText: 'Tracker Offline time exceeded',
        supportedActions: ['Email'],
        emailConfig: {
            // Commented out in case we need advanced filtering.
            // filters: {
            //    statuses: ['24 hours', '48 hours']
            // },
            limitRecipients: ['TeamManager', 'Specified']
        },
        smsConfig: {}
    }
];

export const TEAM_CUSTOM_ACTION_SMS_RECIPIENT_OPTIONS = [
    {
        value: 'StopContact',
        text: 'Stop Contact'
    },
    {
        value: 'Customer',
        text: 'Customer'
    },
    {
        value: 'TeamMember',
        text: 'Assigned Team Member'
    },
    {
        value: 'TeamManager',
        text: 'Team Manager'
    },
    {
        value: 'Specified',
        text: 'Specific Sms Numbers'
    },
    {
        value: 'ShippingContact',
        text: 'Shipping Contact'
    }
];

export const TEAM_CUSTOM_ACTION_STOP_MERGE_FIELDS = [
    { key: 'address', name: 'Address' },
    { key: 'stopRef', name: 'Stop Reference' },
    { key: 'sourceReference', name: 'Source Reference' },
    { key: 'type', name: 'Stop Type' },
    { key: 'stopDetailsUrl', name: 'Stop Details Url' },
    { key: 'driverFirstName', name: 'Driver First Name' },
    { key: 'driverLastName', name: 'Driver Last Name' },
    { key: 'driverEmail', name: 'Driver Email' },
    { key: 'driverPhone', name: 'Driver Phone' },
    { key: 'contactName', name: 'Contact Name' },
    { key: 'contactEmail', name: 'Contact Email' },
    { key: 'contactPhone', name: 'Contact Phone' },
    { key: 'customerName', name: 'Customer Name' },
    { key: 'customerFirstName', name: 'Customer First Name' },
    { key: 'customerLastName', name: 'Customer Last Name' },
    { key: 'customerEmail', name: 'Customer Email' },
    { key: 'customerPhone', name: 'Customer Phone' },
    { key: 'tripDate', name: 'Trip Date' },
    { key: 'appointmentTime', name: 'Appointment Time' },
    { key: 'timeWindowStart', name: 'Time Window Start' },
    { key: 'timeWindowEnd', name: 'Time Window End' },
    { key: 'orderNumber', name: 'Order Number' },
    { key: 'trackingLink', name: 'Tracking Url' },
    { key: 'podLink', name: 'Proof of Delivery Url' },
    { key: 'rateDriverLink', name: 'Rate Driver Url' }
];

export const TEAM_CUSTOM_ACTION_STOP_TYPE_OPTIONS = [
    {
        value: 'No Stop Type',
        text: 'No Stop Type'
    },
    {
        value: 'Pick Up',
        text: 'Pick Up'
    },
    {
        value: 'Drop',
        text: 'Drop'
    }
];

export const TEAM_CUSTOM_ACTION_TIME_DELAY_OPTIONS = [
    {
        value: 'Immediate',
        text: 'Immediate (default)'
    },
    {
        value: 'Delayed',
        text: 'Time Span'
    },
    {
        value: 'Specified',
        text: 'Specific Time'
    }
];

export const TEAM_CUSTOM_ACTION_ALERT_TIME_FRAME_OPTIONS = [
    {
        value: 'Anytime',
        text: 'Anytime (default)'
    },
    {
        value: 'Specified',
        text: 'Specific Time'
    }
];

const containsOperator = {
    key: 'Contains',
    text: 'Contains'
};

const equalOperator = {
    key: '==',
    text: 'Equals'
};

const lessThanOperator = {
    key: '<',
    text: 'Less Than'
};

const lessThanEqualOperator = {
    key: '<=',
    text: 'Less Than Equal'
};

const greaterThanOperator = {
    key: '>',
    text: 'Greater Than'
};

const greaterThanEqualOperator = {
    key: '>=',
    text: 'Greater Than Equal'
};

const betweenOperator = {
    key: 'Between',
    text: 'Between'
};

const stringDataType = {
    type: 'Text',
    operators: [containsOperator, equalOperator]
};

const intDataType = {
    type: 'Number',
    operators: [
        equalOperator,
        lessThanOperator,
        lessThanEqualOperator,
        greaterThanOperator,
        greaterThanEqualOperator,
        betweenOperator
    ]
};

const dateDataType = {
    type: 'Date',
    operators: [
        equalOperator,
        lessThanOperator,
        lessThanEqualOperator,
        greaterThanOperator,
        greaterThanEqualOperator,
        betweenOperator
    ]
};

const booleanDataType = {
    type: 'CheckBox',
    operators: [equalOperator]
};

export {
    containsOperator,
    equalOperator,
    lessThanOperator,
    lessThanEqualOperator,
    greaterThanOperator,
    greaterThanEqualOperator,
    betweenOperator,
    stringDataType,
    intDataType,
    dateDataType,
    booleanDataType
};
