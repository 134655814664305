var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _vm.hasTeam
      ? _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-blue" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("settings")])],
                  1
                ),
              ]
            ),
            _vm.hasTeam
              ? _c("md-card-content", [
                  _c(
                    "div",
                    { staticClass: "md-layout" },
                    _vm._l(_vm.teamSettingOptions, function (option, index) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "feature",
                              rawName: "v-feature",
                              value: _vm.getRouteFeatureRestrictions(option),
                              expression: "getRouteFeatureRestrictions(option)",
                            },
                          ],
                          key: index,
                          staticClass:
                            "md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 md-size-25 text-center",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "setting-menu",
                                  attrs: {
                                    to: {
                                      name: option.name,
                                    },
                                  },
                                },
                                [
                                  option.isCustomIcon
                                    ? _c("md-icon", { class: option.class }, [
                                        _c("img", {
                                          attrs: {
                                            src: "/img/icons/" + option.icon,
                                          },
                                        }),
                                      ])
                                    : _c("md-icon", { class: option.class }, [
                                        _vm._v(_vm._s(option.icon)),
                                      ]),
                                  _c("p", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "menus.setting." + option.class
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "custom-toolbar" }, [
      _c("div", { staticClass: "custom-toolbar-start" }, [
        _c("h3", { staticClass: "title" }, [_vm._v("Team Settings")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }