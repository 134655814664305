import { CircleMode, DragCircleMode, DirectMode, SimpleSelectMode } from 'mapbox-gl-draw-circle';

import FreehandMode from 'mapbox-gl-draw-freehand-mode';
import { STOP_STATUS } from '@/utils/constants';

const mapBoxPolyline = require('@mapbox/polyline');

export const MapBoxMixin = {
    methods: {
        $_mapBox_createMap(id, lat, lng, mapOptions = {}, dataLoadCallback) {
            mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_API_KEY;

            const mapbox = new mapboxgl.Map({
                container: id, // container ID
                style: 'mapbox://styles/mapbox/streets-v12', // style URL
                center: [lng, lat], // starting position [lng, lat]
                zoom: 9 // starting zoom
            });

            mapbox.on('load', (e) => {
                // Starting dummy source that will used as a trigger to add more layers for each route with different colors.
                mapbox.addSource('route', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'Point',
                            coordinates: []
                        }
                    }
                });

                // Check if the source type has changed and trigger a call back function
                mapbox.on('data', (e) => {
                    if (e && e.sourceId === 'route' && e.source.data.type === 'FeatureCollection') {
                        dataLoadCallback(e);
                    }
                });

                // Add default data source for the stop points that are clustered
                mapbox.addSource('stopPoints', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    },
                    cluster: true,
                    clusterMaxZoom: 14, // Max zoom to cluster points on
                    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
                });

                mapbox.addLayer({
                    id: 'selected-stops-clusters',
                    type: 'circle',
                    source: 'stopPoints',
                    filter: ['has', 'point_count'],
                    paint: {
                        // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
                        // with three steps to implement three types of circles:
                        //   * Blue, 20px circles when point count is less than 100
                        //   * Yellow, 30px circles when point count is between 100 and 750
                        //   * Pink, 40px circles when point count is greater than or equal to 750
                        'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
                        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
                    }
                });

                mapbox.addLayer({
                    id: 'selected-stops-cluster-count',
                    type: 'symbol',
                    source: 'stopPoints',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': ['get', 'point_count_abbreviated'],
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 12
                    }
                });

                mapbox.addLayer({
                    id: 'selected-stops-unclustered-point',
                    type: 'circle',
                    source: 'stopPoints',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'circle-color': '#51bbd6',
                        'circle-radius': 5,
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#fff'
                    }
                });

                mapbox.addSource('service-area-polygons', {
                    type: 'geojson',
                    data: {
                        type: 'Feature'
                    }
                });

                mapbox.addLayer({
                    id: 'service-area-polygons',
                    type: 'fill',
                    source: 'service-area-polygons', // reference the data source
                    layout: {},
                    paint: {
                        'fill-color': '#FF0000', // color fill
                        'fill-opacity': 0.5
                    }
                });

                mapbox.addLayer({
                    id: 'outline',
                    type: 'line',
                    source: 'service-area-polygons',
                    layout: {},
                    paint: {
                        'line-color': '#FF0000',
                        'line-width': 3
                    }
                });

                // inspect a cluster on click
                mapbox.on('click', 'selected-stops-clusters', (e) => {
                    const features = mapbox.queryRenderedFeatures(e.point, {
                        layers: ['selected-stops-clusters']
                    });
                    const clusterId = features[0].properties.cluster_id;
                    mapbox.getSource('stopPoints').getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) {
                            return;
                        }

                        mapbox.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom
                        });
                    });
                });

                mapbox.on('click', 'selected-stops-unclustered-point', (e) => {
                    mapbox.fire('marker-click', e.features[0].properties);
                });

                mapbox.on('mouseenter', 'selected-stops-clusters', () => {
                    mapbox.getCanvas().style.cursor = 'pointer';
                });
                mapbox.on('mouseleave', 'selected-stops-clusters', () => {
                    mapbox.getCanvas().style.cursor = '';
                });

                // Add default data source for the Unselected stop points that are clustered
                mapbox.addSource('unSelectedStopPoints', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    },
                    cluster: true,
                    clusterMaxZoom: 14, // Max zoom to cluster points on
                    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
                });

                mapbox.addLayer({
                    id: 'un-selected-stops-clusters',
                    type: 'circle',
                    source: 'unSelectedStopPoints',
                    filter: ['has', 'point_count'],
                    paint: {
                        // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
                        // with three steps to implement three types of circles:
                        //   * Blue, 20px circles when point count is less than 100
                        //   * Yellow, 30px circles when point count is between 100 and 750
                        //   * Pink, 40px circles when point count is greater than or equal to 750
                        'circle-color': ['step', ['get', 'point_count'], '#808080', 100, '#808080', 750, '#808080'],
                        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
                    }
                });

                mapbox.addLayer({
                    id: 'un-selected-stops-cluster-count',
                    type: 'symbol',
                    source: 'unSelectedStopPoints',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': ['get', 'point_count_abbreviated'],
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 12
                    }
                });

                mapbox.addLayer({
                    id: 'un-selected-stops-unclustered-point',
                    type: 'circle',
                    source: 'unSelectedStopPoints',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'circle-color': '#808080',
                        'circle-radius': 5,
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#fff'
                    }
                });

                // inspect a cluster on click
                mapbox.on('click', 'un-selected-stops-clusters', (e) => {
                    const features = mapbox.queryRenderedFeatures(e.point, {
                        layers: ['un-selected-stops-clusters']
                    });
                    const clusterId = features[0].properties.cluster_id;
                    mapbox.getSource('unSelectedStopPoints').getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) {
                            return;
                        }

                        mapbox.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom
                        });
                    });
                });

                // When a click event occurs on a feature in
                // the unclustered-point layer, open a popup at
                // the location of the feature, with
                // description HTML from its properties.
                mapbox.on('click', 'un-selected-stops-unclustered-point', (e) => {
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    const { address } = e.features[0].properties;

                    // Ensure that if the map is zoomed out such that
                    // multiple copies of the feature are visible, the
                    // popup appears over the copy being pointed to.
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }

                    new mapboxgl.Popup()
                        .setLngLat(coordinates)
                        .setHTML(`Address: ${address}`)
                        .addTo(mapbox);
                });

                mapbox.on('mouseenter', 'un-selected-stops-clusters', () => {
                    mapbox.getCanvas().style.cursor = 'pointer';
                });
                mapbox.on('mouseleave', 'un-selected-stops-clusters', () => {
                    mapbox.getCanvas().style.cursor = '';
                });

                mapbox.on('mouseenter', 'selected-stops-unclustered-point', () => {
                    mapbox.getCanvas().style.cursor = 'pointer';
                });
                mapbox.on('mouseleave', 'selected-stops-unclustered-point', () => {
                    mapbox.getCanvas().style.cursor = '';
                });
            });

            return mapbox;
        },
        $_mapBox_setMarkers(mapbox, latitude, longitude, type, pin, labelText, status = 'Pending') {
            let el = null;
            let marker;
            if (type === 'stop') {
                el = document.createElement('div');
                el.className = 'marker-stop';
                if (status === STOP_STATUS.Complete) {
                    el.innerHTML = `<span style='background:${
                        pin.color
                    };opacity: 0.6;color:green;'><b>${labelText}</b></span>`;
                } else if (status === STOP_STATUS.Failed) {
                    el.innerHTML = `<span style='background:${
                        pin.color
                    };opacity: 0.6;color:red;'><b>${labelText}</b></span>`;
                } else {
                    el.innerHTML = `<span style='background:${pin.color};'><b>${labelText}</b></span>`;
                }

                marker = new mapboxgl.Marker(el).setLngLat([longitude, latitude]);
            } else if (type === 'statusHistory' || type === 'geofence') {
                marker = new mapboxgl.Marker({
                    scale: 1.5
                }).setLngLat([longitude, latitude]);
            } else if (type === 'start') {
                marker = new mapboxgl.Marker({
                    scale: 1,
                    color: 'red'
                }).setLngLat([longitude, latitude]);
            } else if (type === 'end') {
                el = document.createElement('div');
                el.innerHTML = `<span style='background-image:url(${
                    pin.optimiseStop
                });display:block;height:37px;width:26px;margin-top:-38px'></span>`;
                marker = new mapboxgl.Marker(el).setLngLat([longitude, latitude]);
            } else if (type === 'driver') {
                el = document.createElement('div');
                el.className = 'marker-driver';
                el.innerHTML = `<span style='display: inline-block;width: 55px;opacity: 0.8;'>
                    <img style='margin:-15px;' src='${pin.driver}' />
                </span>`;
                marker = new mapboxgl.Marker(el).setLngLat([longitude, latitude]);
            } else if (type === 'driverBreak') {
                el = document.createElement('div');
                el.innerHTML = `<span style='background-image:url("/img/map/optimise-stop/driverBreak.png");display:block;height:27px;width:30px;margin-top:-38px'></span>`;
                marker = new mapboxgl.Marker(el).setLngLat([longitude, latitude]);
            } else {
                marker = new mapboxgl.Marker({
                    scale: 0.3,
                    color: '#ffa500'
                }).setLngLat([longitude, latitude]);
            }

            return marker;
        },
        $_map_getMarkerOptions(type, markers, labelText) {
            switch (type) {
                case 'statusHistory':
                case 'heartBeat':
                    return Object.assign(
                        {},
                        {
                            scaledSize: new google.maps.Size(22, 22),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(11, 11),
                            url: markers[type]
                        }
                    );
                case 'geofence':
                    return Object.assign(
                        {},
                        {
                            path: 'M 5 0 10 10 5 20 0 10 Z',
                            strokeColor: 'black',
                            fillColor: '#ffa500',
                            fillOpacity: 1,
                            strokeOpacity: 0.8,
                            anchor: new google.maps.Point(5, 11)
                        }
                    );
                case 'stop':
                    return Object.assign(
                        {},
                        {
                            element: `<html><head></head><body><h3>${labelText}</h3></body></html>`,
                            fillColor: markers.color,
                            fillOpacity: 0.8,
                            strokeColor: 'black',
                            strokeOpacity: 0.8,
                            strokeWeight: 1,
                            anchor: new google.maps.Point(0, 0),
                            labelOrigin: new google.maps.Point(0, -30),
                            scale: 0.75
                        }
                    );
                case 'optimiseStop':
                    return Object.assign(
                        {},
                        {
                            scaledSize: new google.maps.Size(24, 36),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(12, 18),
                            url: markers[type]
                        }
                    );
                case 'driver':
                    return Object.assign(
                        {},
                        {
                            scaledSize: new google.maps.Size(48, 50),
                            origin: new google.maps.Point(0, 0),
                            // anchor should be the base of the marker + length of the pin
                            anchor: new google.maps.Point(20, 40),
                            url: markers[type]
                        }
                    );
                case 'driverBreak':
                    return Object.assign(
                        {},
                        {
                            scaledSize: new google.maps.Size(48, 50),
                            origin: new google.maps.Point(0, 0),
                            // anchor should be the base of the marker + length of the pin
                            anchor: new google.maps.Point(-100, -100),
                            url: markers[type]
                        }
                    );
                case 'stops':
                case 'unassignedstop':
                    return Object.assign(
                        {},
                        {
                            path: google.maps.SymbolPath.CIRCLE,
                            strokeColor: 'black',
                            fillColor: '#ffa500',
                            strokeOpacity: 1,
                            fillOpacity: 1,
                            strokeWeight: 1,
                            scale: 4
                        }
                    );
                case 'timeSeries':
                default:
                    return Object.assign(
                        {},
                        {
                            path: google.maps.SymbolPath.CIRCLE,
                            anchor: new google.maps.Point(0, 0),
                            scale: 6,
                            fillColor: markers.fillColor,
                            strokeColor: markers.strokeColor,
                            fillOpacity: 1,
                            strokeWeight: 1
                        }
                    );
            }
        },
        $_mapBox_removeElement(element) {
            element.remove();
        },
        $_mapBox_addMapElement(element, mapbox) {
            element.addTo(mapbox);
        },
        $_mapBox_clearRoute(vehicleId, mapbox) {
            const layer = mapbox.getLayer(vehicleId);
            if (layer) {
                mapbox.setLayoutProperty(vehicleId, 'visibility', 'none');
            }
        },
        $_mapBox_hideRoute(vehicleId, mapbox) {
            const layer = mapbox.getLayer(vehicleId);
            if (layer) {
                mapbox.setPaintProperty(vehicleId, 'line-color', '#aaa');
            }
        },
        $_mapBox_showRoute(vehicleId, mapbox, color) {
            const layer = mapbox.getLayer(vehicleId);
            if (layer) {
                mapbox.setPaintProperty(vehicleId, 'line-color', color);
                mapbox.moveLayer(vehicleId);
            }
        },
        $_mapBox_handleListener(map, listener, callback) {
            map.on(listener, callback);
        },
        $_mapBox_createRouteData(path = []) {
            const coord = path.map((x) => [x[1], x[0]]);
            const data = {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: coord
                }
            };
            return data;
        },
        $_mapBox_triggerData(mapBox) {
            const data = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: []
                        }
                    }
                ]
            };
            mapBox.getSource('route').setData(data);
        },
        $_mapBox_setData(mapBox, dataName, data) {
            mapBox.getSource(dataName).setData(data);
        },
        $_mapBox_callMapFunction(obj, method, params) {
            switch (method) {
                case 'panTo':
                    obj.easeTo({ center: [params[0], params[1]], zoom: 9 });
                    break;
                case 'fitBounds':
                    obj.fitBounds(params, { padding: 200 });
                    break;
                case 'extend':
                    obj.extend(params);
                    break;
                case 'setZoom':
                    // TODO: implement this
                    break;
                default:
            }
        },
        $_mapbox_createBounds(mapbox) {
            return mapbox.getBounds();
        },
        $_mapbox_createPath(routeStr) {
            return mapBoxPolyline.decode(routeStr);
        },
        $_mapBox_createRouteLine(featureData, mapbox, vehicleId, options) {
            const data = {
                type: 'FeatureCollection',
                features: featureData
            };
            if (mapbox.getLayer(vehicleId)) {
                mapbox.getSource(vehicleId).setData(data);
                mapbox.setLayoutProperty(vehicleId, 'visibility', 'visible');
            } else {
                mapbox.addLayer({
                    id: vehicleId,
                    type: 'line',
                    source: {
                        type: 'geojson',
                        data
                    },
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    paint: {
                        'line-color': options.strokeColor,
                        'line-width': options.strokeWeight
                    }
                });

                this.map.on('mouseenter', vehicleId, (e) => {
                    this.map.getCanvas().style.cursor = 'pointer';
                });

                this.map.on('mouseleave', vehicleId, (e) => {
                    this.map.getCanvas().style.cursor = '';
                });
            }
            return vehicleId;
        },
        $_map_drawingManager() {
            const draw = new MapboxDraw({
                displayControlsDefault: false,
                userProperties: true,
                modes: {
                    ...MapboxDraw.modes,
                    draw_polygon: FreehandMode,
                    draw_circle: CircleMode,
                    drag_circle: DragCircleMode,
                    direct_select: DirectMode,
                    simple_select: SimpleSelectMode
                }
            });
            return draw;
        },
        $_mapbox_computeDistance(start, end) {
            const linestring = {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: [[start.longitude, start.latitude], [end.longitude, end.latitude]]
                }
            };
            return turf.length(linestring) * 1000;
        },
        $_mapbox_containsLocation(marker, polygon) {
            const poly = turf.polygon(polygon.features[0].geometry.coordinates);
            const markerPoint = turf.point([marker.location.longitude, marker.location.latitude]);
            const ins = turf.booleanPointInPolygon(markerPoint, poly);
            return ins;
        },
        $_mapBox_remove_geojson_marker(stop, geoJson) {
            if (stop && geoJson && geoJson.features && geoJson.features.length > 0) {
                const index = geoJson.features.findIndex((x) => x.properties.stopId === stop.stopId);
                if (index > -1) {
                    geoJson.features.splice(index, 1);
                }
            }
        },
        $_mapBox_add_geojson_marker(stop, geoJson) {
            if (stop && geoJson && geoJson.features) {
                const index = geoJson.features.findIndex((x) => x.properties.stopId === stop.stopId);
                if (index === -1) {
                    const feat = {
                        type: 'Feature',
                        properties: stop,
                        geometry: {
                            type: 'Point',
                            coordinates: [stop.location.longitude, stop.location.latitude, 0.0]
                        }
                    };
                    geoJson.features.push(feat);
                }
            }
        },
        $_mapbox_calculateMarkerCoordinates(marker, mapbox, options = {}) {
            // for getting the pixel location of the marker
            const INFO_WINDOW_HEIGHT = options.height;
            const INFO_WINDOW_WIDTH = options.width || 132.5;
            const markerLngLat = marker.getLngLat();
            const worldCoordinate = mapbox.project([markerLngLat.lng, markerLngLat.lat]);
            const coord = {
                left: `${worldCoordinate.x - INFO_WINDOW_WIDTH}px`,
                top: `${worldCoordinate.y - INFO_WINDOW_HEIGHT}px`
            };

            return coord;
        },
        $_mapbox_setMarkerClusterColor(mapbox, colorOptions) {},
        $_mapBox_getMaxBounds(latLngParams) {
            let minLat = Infinity;
            let maxLat = -Infinity;
            let minLng = Infinity;
            let maxLng = -Infinity;

            latLngParams.forEach(([lat, lng]) => {
                if (lat < minLat) 
                    minLat = lat;
                if (lat > maxLat) 
                    maxLat = lat;
                if (lng < minLng) 
                    minLng = lng;
                if (lng > maxLng) 
                    maxLng = lng;
            });

            const lat = [];
            lat.push([minLat, minLng]);
            lat.push([maxLat, maxLng]);

            return lat;
        }
    }
};
