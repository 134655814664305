var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-green" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("coffee")])],
                    1
                  ),
                  _c("div", { staticClass: "custom-toolbar" }, [
                    _c("div", { staticClass: "custom-toolbar-start" }, [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Break Management"),
                      ]),
                    ]),
                    _c("div", { staticClass: "custom-toolbar-end" }, [
                      _c(
                        "div",
                        { staticClass: "header-button-container" },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-round md-just-icon md-primary",
                              attrs: { title: "Add Break Schedule" },
                              on: {
                                click: function ($event) {
                                  return _vm.setAddBreakState(true)
                                },
                              },
                            },
                            [_c("md-icon", [_vm._v("add")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("md-card-content", { staticClass: "body-list" }, [
                _c("div", [
                  _vm.breaksList.length != 0 || _vm.addBreakState
                    ? _c(
                        "div",
                        [
                          _c(
                            "md-table",
                            {
                              staticClass:
                                "context-menu-support custom-paginated-table",
                            },
                            [
                              _c(
                                "draggable",
                                {
                                  staticClass: "custom-draggable",
                                  attrs: { draggable: ".drag-item" },
                                  on: { change: _vm.breakScheduleOrderChanged },
                                  model: {
                                    value: _vm.breaksList,
                                    callback: function ($$v) {
                                      _vm.breaksList = $$v
                                    },
                                    expression: "breaksList",
                                  },
                                },
                                [
                                  _c(
                                    "md-table-row",
                                    [
                                      _c("md-table-head", {
                                        staticClass: "order-column",
                                      }),
                                      _c("md-table-head", [
                                        _vm._v("Break Schedule"),
                                      ]),
                                      _c("md-table-head", [_vm._v("Action")]),
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.breaksList, function (item) {
                                    return _c("team-break-row-component", {
                                      key: item.breakScheduleId,
                                      attrs: {
                                        "team-break-details": item,
                                        "break-count": _vm.breaksList.length,
                                      },
                                      on: {
                                        editBreakScheduleClick:
                                          _vm.editBreakSchedule,
                                        deleteBreakScheduleClick:
                                          _vm.deleteBreakSchedule,
                                      },
                                    })
                                  }),
                                  _vm.addBreakState
                                    ? _c(
                                        "md-table-row",
                                        [
                                          _c(
                                            "md-table-cell",
                                            { staticClass: "order-align" },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.breaksList.length + 1
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                          _c("md-table-cell", [
                                            _vm._v(
                                              "\n                                            Before completing\n                                            "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.newEntry
                                                      .drivingTimeMinutes,
                                                  expression:
                                                    "newEntry.drivingTimeMinutes",
                                                },
                                              ],
                                              staticClass: "minutes-textbox",
                                              attrs: {
                                                type: "text",
                                                onkeypress:
                                                  "return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.newEntry
                                                    .drivingTimeMinutes,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.newEntry,
                                                    "drivingTimeMinutes",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(
                                              "\n                                            minutes of work take a\n                                            "
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.newEntry
                                                      .breakTimeMinutes,
                                                  expression:
                                                    "newEntry.breakTimeMinutes",
                                                },
                                              ],
                                              staticClass: "minutes-textbox",
                                              attrs: {
                                                type: "text",
                                                onkeypress:
                                                  "return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.newEntry.breakTimeMinutes,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.newEntry,
                                                    "breakTimeMinutes",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(
                                              "\n                                            minute break.\n                                        "
                                            ),
                                          ]),
                                          _c(
                                            "md-table-cell",
                                            [
                                              _c(
                                                "md-button",
                                                {
                                                  staticClass:
                                                    "md-round md-just-icon md-primary break-btn-icon",
                                                  attrs: {
                                                    title: "Add Break Schedule",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.createBreakSchedule,
                                                  },
                                                },
                                                [
                                                  _c("md-icon", [
                                                    _vm._v("check"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "md-button",
                                                {
                                                  staticClass:
                                                    "md-round md-just-icon md-danger break-btn-icon",
                                                  attrs: { title: "Cancel" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setAddBreakState(
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("md-icon", [
                                                    _vm._v("cancel"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.breaksList.length == 0 && !_vm.addBreakState
                    ? _c("div", [
                        _c("p", { staticClass: "no-result-message" }, [
                          _vm._v(
                            "No breaks configured; breaks disabled for team"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }