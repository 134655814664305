<template>
    <div class="details-page" v-if="inventoryDetails">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <h3 class="title">Item details ( {{ inventoryDetails.barcode }} )</h3>
                    </div>
                    <div class="custom-toolbar-end" v-if="!$root.isMobileOnly">
                        <div class="header-button-container">
                            <inventory-status-button
                                :item-status="inventoryDetails.status"
                                :item-id="inventoryDetails.itemId"
                                :inventory-details="inventoryDetails"
                                @statusUpdated="statusUpdated()"
                                class="status-btn"
                            />

                            <update-item-button
                                :item="inventoryDetails"
                                @itemUpdated="statusUpdated"
                                class-name="header-button"
                                :use-icon="false"
                                :is-delivered="inventoryDetails.status === 'Delivered'"
                                :show-all-fields="true"
                                :stop-status="
                                    inventoryDetails.lines.length > 0 ? inventoryDetails.lines.at(-1).stopStatus : null
                                "
                                :shipment-status="
                                    inventoryDetails.lines.length > 0
                                        ? inventoryDetails.lines.at(-1).shipmentStatus
                                        : null
                                "
                                class="status-btn"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout md-alignment-top-left content-layout-panel">
            <div
                class="md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-66 md-xlarge-size-50"
            >
                <div class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-blue">
                            <div class="card-icon">
                                <md-icon>description</md-icon>
                            </div>
                            <h4 class="title">Inventory Information</h4>
                        </md-card-header>

                        <md-card-content>
                            <div class="left-details">
                                <div class="details-box">
                                    <label>Type</label>
                                    <span>{{ inventoryDetails.type }}</span>
                                </div>
                                <div class="details-box">
                                    <label>Description</label>
                                    <span>{{ inventoryDetails.description }}</span>
                                </div>
                                <div class="details-box" v-if="inventoryDetails.sku">
                                    <label>SKU</label>
                                    <span>{{ inventoryDetails.sku }}</span>
                                </div>
                                <div class="details-box" v-if="inventoryDetails.productReference">
                                    <label>Product Reference</label>
                                    <span>{{ inventoryDetails.productReference }}</span>
                                </div>
                                <div class="details-box" v-if="inventoryDetails.installationTimeInMinutes">
                                    <label>Installation Time (minutes)</label>
                                    <span>{{ inventoryDetails.installationTimeInMinutes }}</span>
                                </div>
                                <div class="details-box">
                                    <label class="label">Order Number</label>
                                    <span class="value" v-if="inventoryDetails.order">
                                        <router-link :to="{
                                                name: 'Orders',
                                                params: { orderId: inventoryDetails.order.orderId }
                                            }"
                                                     target="_blank">
                                            {{ inventoryDetails.order.orderNumber }}
                                        </router-link>
                                    </span>
                                </div>
                                <div v-if="loadCapacityList && inventoryDetails.load" class="item-capacity-details">
                                    <div class="details-box"
                                         v-for="(loadCapacity, index) in loadCapacityList"
                                         :key="index">
                                        <div v-if="loadCapacity.type !== 'dimensions'">
                                            <label>{{ loadCapacity.label }}</label>
                                            <span v-if="loadCapacity !== null && loadValues[loadCapacity.type] !== null">
                                                {{ loadValues[loadCapacity.type] }} {{ loadCapacity.symbol }}
                                            </span>
                                        </div>
                                        <div v-if="loadCapacity.type === 'dimensions'">
                                            <label>{{ loadCapacity.label }}</label>
                                            <span v-if="loadCapacity !== null && loadValues[loadCapacity.type] !== null">
                                                L: {{ loadValues['length'] }} {{ loadCapacity.symbol }} W:
                                                {{ loadValues['width'] }} {{ loadCapacity.symbol }} H:
                                                {{ loadValues['height'] }} {{ loadCapacity.symbol }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="right-details">
                                <div class="details-box">
                                    <label>Warehouse</label>
                                    <span>{{ getWarehouseName(inventoryDetails.warehouseId) }}</span>
                                </div>
                                <div class="details-box">
                                    <label>Current Location</label>
                                    <span>{{ inventoryDetails.currentLocationName }}</span>
                                </div>
                                <div class="details-box">
                                    <label>Possesed by</label>
                                    <span>{{ getFullNameFromTeamMemberId(inventoryDetails.userId) }}</span>
                                </div>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>

                <div
                    class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100"
                    v-if="inventoryDetails.lines"
                >
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-green">
                            <div class="card-icon">
                                <md-icon>control_camera</md-icon>
                            </div>
                            <h4 class="title">Stop / Shipment Details</h4>
                        </md-card-header>

                        <md-card-content>
                            <md-table
                                class="context-menu-support custom-paginated-table"
                                v-if="inventoryDetails.lines.length > 0"
                            >
                                <md-table-row>
                                    <md-table-head>Status</md-table-head>
                                    <md-table-head>Type</md-table-head>
                                    <md-table-head>Reference</md-table-head>
                                    <md-table-head>Stop Name</md-table-head>
                                    <md-table-head>Trip Date</md-table-head>
                                    <md-table-head>Team member</md-table-head>
                                </md-table-row>
                                <md-table-row v-for="item in inventoryDetails.lines" :key="item.stopLineId">
                                    <md-table-cell>{{ $t('inputs.itemStatuses.' + item.status) }}</md-table-cell>
                                    <md-table-cell>{{ item.type }}</md-table-cell>
                                    <md-table-cell>
                                        <router-link
                                            v-if="!item.shipmentId"
                                            :to="{ name: 'Stop Details', params: { stopId: item.stopId } }"
                                            target="_blank"
                                        >
                                            {{ item.stopRef }}
                                        </router-link>
                                        <router-link
                                            v-else
                                            :to="{
                                                name: 'Shipment Details',
                                                params: { shipmentId: item.shipmentId }
                                            }"
                                            target="_blank"
                                        >
                                            {{ item.shipmentRef }}
                                        </router-link>
                                    </md-table-cell>
                                    <md-table-cell>{{ item.name }}</md-table-cell>
                                    <md-table-cell>
                                        <template v-if="item.tripDate">
                                            {{ item.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                        </template>
                                    </md-table-cell>

                                    <md-table-cell>
                                        <template v-if="item.publicUserId">
                                            {{ getFullNameFromTeamMemberId(item.publicUserId) }}
                                        </template>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </md-card-content>
                    </md-card>
                </div>
                <div
                    class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100"
                    v-if="inventoryDetails.itemLines && inventoryDetails.itemLines.length > 0"
                >
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-green">
                            <div class="card-icon">
                                <md-icon>description</md-icon>
                            </div>
                            <h4 class="title">Item Lines</h4>
                        </md-card-header>

                        <md-card-content>
                            <md-table
                                class="context-menu-support custom-paginated-table"
                                v-if="inventoryDetails.itemLines.length > 0"
                            >
                                <md-table-row>
                                    <md-table-head>Product Reference</md-table-head>
                                    <md-table-head>Description</md-table-head>
                                    <md-table-head>Barcode</md-table-head>
                                    <md-table-head>Name</md-table-head>
                                    <md-table-head>Size</md-table-head>
                                    <md-table-head>Color</md-table-head>
                                    <md-table-head>Price</md-table-head>
                                    <md-table-head>Quantity</md-table-head>
                                </md-table-row>
                                <md-table-row v-for="itemLine in inventoryDetails.itemLines" :key="itemLine.itemLineId">
                                    <md-table-cell>{{ itemLine.productReference }}</md-table-cell>
                                    <md-table-cell>{{ itemLine.description }}</md-table-cell>
                                    <md-table-cell>{{ itemLine.productVariantBarcode }}</md-table-cell>
                                    <md-table-cell>{{ itemLine.productName }}</md-table-cell>
                                    <md-table-cell>{{ itemLine.productVariantSize }}</md-table-cell>
                                    <md-table-cell>{{ itemLine.productVariantColour }}</md-table-cell>
                                    <md-table-cell>{{ itemLine.productPrice }}</md-table-cell>
                                    <md-table-cell>{{ itemLine.quantity }}</md-table-cell>
                                </md-table-row>
                            </md-table>
                        </md-card-content>
                    </md-card>
                </div>

                <div
                    class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100"
                    v-if="inventoryDetails.order"
                >
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-blue">
                            <div class="card-icon">
                                <md-icon>checklist</md-icon>
                            </div>
                            <h4 class="title">Product Details</h4>
                        </md-card-header>

                        <md-card-content>
                            <md-table class="context-menu-support custom-paginated-table">
                                <md-table-row>
                                    <md-table-head>Barcode</md-table-head>
                                    <md-table-head>Name</md-table-head>
                                    <md-table-head>Size</md-table-head>
                                    <md-table-head>Color</md-table-head>
                                    <md-table-head>Price</md-table-head>
                                    <md-table-head>Quantity</md-table-head>
                                </md-table-row>
                                <md-table-row v-for="item in inventoryDetails.order.orderLines" :key="item.orderLineId">
                                    <md-table-cell>{{ item.productVariantBarcode }}</md-table-cell>
                                    <md-table-cell>{{ item.productName }}</md-table-cell>
                                    <md-table-cell>{{ item.productVariantSize }}</md-table-cell>
                                    <md-table-cell>{{ item.productVariantColour }}</md-table-cell>
                                    <md-table-cell>{{ item.productPrice }}</md-table-cell>
                                    <md-table-cell>{{ item.quantity }}</md-table-cell>
                                </md-table-row>
                            </md-table>
                        </md-card-content>
                    </md-card>
                </div>
            </div>

            <!--v-if="$root.isDesktop"-->

            <div
                class="md-layout-item md-small-size-100 md-layout md-medium-size-100 md-large-size-33 md-xlarge-size-50"
            >
                <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-warning">
                            <div class="card-icon">
                                <md-icon>history</md-icon>
                            </div>
                            <h4 class="title">Status History</h4>
                        </md-card-header>

                        <md-card-content>
                            <time-line plain type="simple" v-if="inventoryDetails.itemStatusHistory.length > 0">
                                <time-line-item
                                    inverted
                                    :badge-type="`inventory-status-${item.newStatus.toLowerCase().replace(/\s+/g, '')}`"
                                    badge-icon="place"
                                    v-for="item in inventoryDetails.itemStatusHistory"
                                    :key="item.itemStatusHistoryId"
                                >
                                    <span
                                        slot="header"
                                        class="badge"
                                        :class="`inventory-status-${item.newStatus.toLowerCase().replace(/\s+/g, '')}`"
                                    >
                                        {{ $t('inputs.itemStatuses.' + item.newStatus) }}
                                    </span>

                                    <span slot="headerTime" class="time">
                                        {{ item.actionDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                                    </span>

                                    <h6 slot="footer" class="note">
                                        {{ item.notes }}
                                    </h6>
                                </time-line-item>
                            </time-line>
                            <p v-else class="grayedout-text">No Status History Available</p>
                        </md-card-content>
                    </md-card>
                </div>
                <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-warning">
                            <div class="card-icon">
                                <md-icon>control_camera</md-icon>
                            </div>
                            <h4 class="title">Movement History</h4>
                        </md-card-header>

                        <md-card-content>
                            <time-line plain type="simple" v-if="inventoryDetails.itemMovementHistory.length > 0">
                                <time-line-item
                                    inverted
                                    :badge-type="
                                        `inventory-movement-${item.newLocationName.toLowerCase().replace(/\s+/g, '')}`
                                    "
                                    badge-icon="place"
                                    v-for="item in inventoryDetails.itemMovementHistory"
                                    :key="item.itemMovementHistoryId"
                                >
                                    <span
                                        slot="header"
                                        class="badge"
                                        :class="
                                            `inventory-movement-${item.newLocationName
                                                .toLowerCase()
                                                .replace(/\s+/g, '')}`
                                        "
                                    >
                                        {{ $t('inputs.itemLocations.' + item.newLocationName) }}
                                    </span>

                                    <span slot="headerTime" class="time">
                                        {{ item.actionDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                                    </span>

                                    <h6 slot="footer" class="note">
                                        {{ item.notes }}
                                    </h6>
                                </time-line-item>
                            </time-line>
                            <p v-else class="grayedout-text">No Movement History Available</p>
                        </md-card-content>
                    </md-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { TimeLine, TimeLineItem } from '@/components';
import { mapGetters } from 'vuex';
import { InventoryStatusButton, UpdateItemButton } from './buttons';

export default {
    name: 'InventoryDetails',
    components: { TimeLine, TimeLineItem, InventoryStatusButton, UpdateItemButton },
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam',
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    data() {
        return {
            warehouses: null,
            teamMembers: null,
            inventoryDetails: null,
            loadCapacityList: [],
            loadValues: []
        };
    },
    async mounted() {
        this.$_handleLoaderState(true);
        try {
            this.loadCapacityList = [];
            if (this.user.vehicleCapacityUnitsConfiguration && this.user.vehicleCapacityUnitsConfiguration.length > 0) {
                this.loadCapacityList = this.user.vehicleCapacityUnitsConfiguration.filter(x => x.type !== 'quantity');
            }
            await Promise.all([this.getWarehouses(), this.getTeamMembers()]);
            await this.getInventoryDetails(this.$route.params.itemId);
        } finally {
            this.$_handleLoaderState(false);
        }
    },
    methods: {
        async statusUpdated() {
            await this.getInventoryDetails(this.inventoryDetails.itemId);
            // this.$emit('statusUpdated', { status: this.inventoryDetails.status, itemId: this.inventoryDetails.itemId });
        },
        async getWarehouses(pageNumber = 1, itemsPerPage = 200) {
            let response = {
                data: {
                    warehouses: [],
                    totalWarehouses: null
                }
            };
            try {
                const endpoint = `/api/warehouses`;
                response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        itemsPerPage
                    }
                });
            } catch (error) {
                const message = 'Error in getting the warehouses list';
                showErrorMessage(this, message, error);
            }
            this.warehouses = response.data.warehouses;
        },
        async getTeamMembers(tripDate = null) {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS', {
                date: tripDate
            });
        },
        async getInventoryDetails(itemId) {
            try {
                const api = `/api/items/${itemId}`;
                const { data } = await handleRequests(api);

                this.inventoryDetails = data;

                document.title = `Locate2u - Item ${this.inventoryDetails.barcode}`;

                this.inventoryDetails.itemStatusHistory.sort((a, b) => {
                    return moment(a.actionDate).diff(moment(b.actionDate));
                });

                if (this.loadCapacityList != null && this.loadCapacityList.length) {
                    this.loadValues = this.inventoryDetails.load;
                }

                // Sort them ascending.
                if (this.inventoryDetails.lines && this.inventoryDetails.lines.length > 0)
                    this.inventoryDetails.lines.sort((a, b) => {
                        if (a.tripDate === null) 
                            return 1;
                        if (b.tripDate === null) 
                            return -1;
                        return moment(a.tripDate).diff(moment(b.tripDate));
                    });
            } catch (error) {
                const message = 'Error in getting the inventory details';
                showErrorMessage(this, message, error);
            }
        },
        getWarehouseName(id) {
            const fnd = this.warehouses.find((element) => element.warehouseId === id);
            if (fnd === undefined) 
                return '';
            return fnd.name;
        },
        getFullNameFromTeamMemberId(id) {
            const fnd = this.teamMembers.find((element) => element.publicUserId === id);
            if (fnd === undefined) 
                return '';
            return fnd.fullName;
        }
    }
};
</script>

<style lang="scss" scoped>
.details-page {
    padding-top: 40px;

    ::v-deep .badge {
        display: inline-block;
        border-radius: 12px;
        padding: 5px 12px;
        text-transform: uppercase;
        font-size: 10px;
        color: #fff;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;

        position: initial;
        width: initial;
        height: initial;
        top: initial;
        right: initial;
        margin-top: initial;
        margin-right: initial;
    }

    .note {
    }

    .left-details {
        width: 50%;
        float: left;
    }

    ::v-deep .md-field.md-disabled:after {
        border-bottom: none;
    }

    ::v-deep .md-field:after {
        height: 0;
    }

    ::v-deep .md-field:before {
        background-color: initial !important;
    }

    .custom-label {
        font-size: 0.6875rem;
        color: #aaa;
    }

    ::v-deep .timeline.timeline-simple {
        margin-top: 0;
    }

    ::v-deep .md-card-timeline {
        margin-top: 0;
        margin-bottom: 0;
    }

    ::v-deep .timeline .timeline-panel {
        min-height: 55px;
    }

    ::v-deep .timeline > li > .timeline-panel {
        padding: 5px 20px;
        background-color: #fafafa;
    }

    ::v-deep .timeline-panel > h6 > h6 {
        text-transform: none;
        margin-top: 0;
    }

    ::v-deep .timeline-panel > h6 {
        margin-top: 0px;
    }

    ::v-deep .timeline-heading {
        margin-bottom: 5px;
    }

    ::v-deep .timeline > li {
        margin-bottom: 5px;
    }

    ::v-deep .timeline > li > .timeline-panel:after,
    ::v-deep .timeline > li > .timeline-panel:before {
        border-left-color: #fafafa;
        border-right-color: #fafafa;
    }

    ::v-deep .timeline.timeline-simple > li > .timeline-panel {
        width: 83%;
    }

    ::v-deep .timeline-heading .time {
        margin-left: 10px;
        color: #333333;
        font-weight: 400;
        font-size: 0.75rem;
    }

    .item-capacity-details {
        > div {
            display: inline-block;
            width: 200px;
        }
        a {
            cursor: pointer;
        }
        .run-number {
            width: 100px;
        }
    }
}

.profile-image {
    height: 63px;
    width: 63px;
    padding: 0px !important;
    -webkit-box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 0, 0, 0.2) !important;
    img {
        border-radius: 3px;
    }
}

::v-deep .md-card-header-icon {
    z-index: 9;
}

.map-box ::v-deep .md-card-content {
    padding-top: 0;
    padding-bottom: 20px;
    margin-top: -22px;
}

.custom-chip {
    margin-top: 0px;
}

.header-button-container {
    > button {
        margin-right: 0;
    }
    .status-btn {
        float: left;
        display: inline-block;
        text-transform: uppercase;
        font-size: 12px;
        > ::v-deep span {
            margin: 6px 8px;
            height: 36px;
            line-height: 36px;
            border-radius: 2px;
            margin-right: 0px;
        }

        > ::v-deep .dropdown-menu {
            margin-left: 9px;
            margin-top: 0;
        }
    }
}

.custom-toolbar {
    .title {
        margin-bottom: 0;
    }
}

.content-layout-panel {
    > div {
        padding: 0;
    }
}

@media (min-width: 768px) {
    .details-page {
        padding-top: 0;
    }
}
</style>
