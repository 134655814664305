<template>
    <form-group name="customer" :label="labelName" class="filter-customer">
        <md-input v-model="selectedCustomerId" type="hidden" />
        <vue-select
            data-testid="customer"
            :reduce="(customer) => customer.customerId"
            label="labelText"
            :options="customerList"
            v-model="selectedCustomerId"
            :searchable="$root.isDesktop"
            :selectable="(option) => option.selectable"
        >
            <template v-slot:option="option">
                <span v-if="option.selectable">{{ option.labelText }}</span>
                <hr v-else />
            </template>
        </vue-select>
    </form-group>
</template>
<script>
import { ref, onMounted, watch, computed } from '@vue/composition-api';
import { handleRequests } from '@/helpers';

export default {
    name: 'CustomerOptions',
    props: {
        selected: {
            default: null,
            type: Number
        },
        teamRegionId: {
            type: Number,
            default: null
        },
        labelName: {
            type: String,
            default: 'Customer'
        }
    },
    setup(props, context) {
        const selectedCustomerId = ref(null);
        /**
         *
         * @type {Ref<import("@/jsDocTypes/Customer").CustomerListItemViewModel[]>}
         */
        const customers = ref([]);

        async function fetchCustomers() {
            const endpoint = `/api/customers`;
            const { data } = await handleRequests(endpoint, {
                params: {
                    teamRegionId: props.teamRegionId,
                    disablePaging: true
                }
            });
            return data.customers;
        }

        onMounted(async () => {
            customers.value = await fetchCustomers();
            selectedCustomerId.value = props.selected;
        });

        watch(
            () => selectedCustomerId.value,
            (newVal) => {
                const model = customers.value.find((customer) => customer.customerId === newVal);
                context.emit('selectedOption', newVal, model);
            }
        );

        const customerList = computed(() => {
            return customers.value.map((customer) => {
                let labelText = '';
                if (customer.company) {
                    labelText = customer.company;
                } else if (customer.name) {
                    labelText = customer.name;
                } else {
                    labelText = `${customer.firstName} ${customer.lastName}`;
                }
                return {
                    customerId: customer.customerId,
                    labelText,
                    selectable: true
                };
            });
        });

        return {
            selectedCustomerId,
            customerList
        };
    }
};
</script>

<style scoped lang="scss">
::v-deep .filter-customer .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    display: block;
}
</style>
