<template>
    <div>
        <md-button
            title="Add Shipment"
            class="md-primary md-just-icon md-round pull-right header-button"
            @click="createShipment"
            :disabled="isCustomer"
        >
            <md-icon>add</md-icon>
        </md-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { SHIPMENT_DETAILS_DEFAULTS } from '@/utils/defaults';
import CreateShipmentModal from './CreateShipmentModal';

export default {
    name: 'CreateShipment',
    mixins: [GeneralMixin],
    props: {
        teamMembers: {
            type: Array,
            default: () => []
        },
        selectedMemberId: {
            type: [String, Number],
            default: () => 0
        }
    },
    data() {
        return {
            shipmentDetails: Object.assign({}, SHIPMENT_DETAILS_DEFAULTS())
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isCustomerAdmin: 'user/isCustomerAdmin',
            isCustomer: 'user/isCustomer'
        })
    },
    methods: {
        createShipment() {
            this.resetShipmentState();

            if (this.selectedMemberId !== null) {
                this.shipmentDetails.assignToPublicUserId = this.selectedMemberId;
            }
            if (this.isCustomerAdmin) {
                this.shipmentDetails.customerId = this.user.customerId || null;
            }

            // User user or team default stop duration, if null use value from SHIPMENT_DETAILS_DEFAULTS
            this.shipmentDetails.pickupStop.durationMinutes =
                this.user.defaultStopDurationMinutes ?? this.shipmentDetails.pickupStop.durationMinutes;
            this.shipmentDetails.dropStop.durationMinutes =
                this.user.defaultStopDurationMinutes ?? this.shipmentDetails.dropStop.durationMinutes;

            const shipmentDetails = { ...this.shipmentDetails, teamRegionId: this.user.teamRegionId };
            // console.log('[CreateShipmnet] (createShipment) shipmentDetails', shipmentDetails);
            this.$modal
                .show(CreateShipmentModal, {
                    members: this.teamMembers,
                    shipmentDetails
                })
                .then((response) => {
                    if (response.toLowerCase() === 'ok') 
                        this.emitShipmentCreated();
                    this.$modal.hide();
                });
        },
        emitShipmentCreated() {
            this.resetShipmentState();
            this.$emit('shipmentCreated');
        },
        resetShipmentState() {
            Object.assign(this.shipmentDetails, SHIPMENT_DETAILS_DEFAULTS());
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    margin-right: 16px;
}
</style>
