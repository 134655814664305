var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-layout" }, [
    _c("div", { staticClass: "page-toolbar" }, [
      _c("div", { staticClass: "toolbar-left" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchText,
              expression: "searchText",
            },
          ],
          staticClass: "search-textbox",
          attrs: {
            type: "text",
            placeholder: "Just start typing to search all fields...",
            autofocus: "",
          },
          domProps: { value: _vm.searchText },
          on: {
            keyup: _vm.searchTextChanged,
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchText = $event.target.value
            },
          },
        }),
      ]),
      _c(
        "div",
        { staticClass: "toolbar-right" },
        [
          _c("filter-manager", {
            attrs: {
              "filter-modified": _vm.filterModified,
              "filter-object": _vm.gridSearchState,
            },
            on: {
              "update:filterModified": function ($event) {
                _vm.filterModified = $event
              },
              "update:filter-modified": function ($event) {
                _vm.filterModified = $event
              },
              selectedFilterChanged: _vm.selectedFilterChanged,
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c("ag-grid-vue", {
          staticClass: "grid-display",
          class: _vm.gridThemeClass,
          attrs: {
            "row-model-type": "serverSide",
            "column-defs": _vm.columnDefs,
            "grid-options": _vm.gridOptions,
            "default-col-def": _vm.defaultColDef,
            pagination: true,
            "pagination-page-size": _vm.gridPageSize,
            "pagination-page-size-selector": [10, 15, 20, 50, 100],
            "suppress-excel-export": false,
            "auto-group-column-def": _vm.gridAutoGroupColumnDef,
            "row-selection": _vm.rowSelection,
            "side-bar": _vm.gridSideBar,
            "pivot-panel-show": _vm.gridPivotPanelShow,
            "group-selects-children": true,
            "overlay-loading-template": _vm.gridLoadingOverlayTemplate,
            "pagination-number-formatter": _vm.paginationNumberFormatter,
            "tooltip-interaction": true,
          },
          on: {
            "grid-ready": _vm.onGridReady,
            "filter-changed": _vm.onGridFilterChanged,
            "sort-changed": _vm.onGridSortChanged,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }