<template>
    <div class="filter-steps--container">
        <team-region-member-filter-options
            v-if="canViewTeamRegion || isCustomerAdmin"
            :team-region-id="teamRegionId"
            @changed="handleTeamRegionMemberChanged"
            :strict-team-region-filter="false"
            :clearable="false"
            :show-carriers="true"
            :show-team-member="showTeamMembers"
        ></team-region-member-filter-options>
        <md-field v-if="hasCustomerFilterAccess">
            <customer-filter-options
                class="filter-steps--choices filter-status"
                @onCustomerSelected="handleCustomerChanged"
            />
        </md-field>
        <md-field v-if="showStatus">
            <vue-select
                data-testid="shipment-status"
                v-if="statuses"
                :options="statuses"
                placeholder="Status"
                v-model="statusFilterValue"
            ></vue-select>
        </md-field>
        <md-field v-if="showQuotingStatus">
            <QuotingStatusFilter @changed="handleQuotingStatusChanged" />
        </md-field>
        <md-field>
            <md-datepicker
                data-testid="start-date"
                class="filter-steps--choices filter-step-date start-date"
                v-model="fromDate"
                md-immediately
                :md-debounce="100"
            ></md-datepicker>
        </md-field>
        <md-field>
            <md-datepicker
                data-testid="end-date"
                class="filter-steps--choices filter-step-date end-date"
                v-model="toDate"
                md-immediately
                :md-debounce="100"
            ></md-datepicker>
        </md-field>
        <md-checkbox type="checkbox" v-model="showBlankShipmentDateFirst" @change="handleShowBlankShipmentDateFirst">
            Show shipments with no date first
        </md-checkbox>
    </div>
</template>
<script>
import moment from 'moment';
import { STATUS_SHIPMENT_CONSTANTS, DATE_TYPES } from '@/utils/constants';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { TeamRegionMemberFilterOptions, QuotingStatusFilter, CustomerFilterOptions } from '@/components';

export default {
    name: 'ShipmentFilterComponent',
    props: {
        teamMembers: {
            type: Function,
            default: () => []
        },
        teamRegionId: {
            type: Number,
            default: null
        },
        showStatus: {
            type: Boolean,
            default: true
        },
        showQuotingStatus: {
            type: Boolean,
            default: false
        },
        showTeamMembers: {
            type: Boolean,
            default: true
        },
        dateRange: {
            type: Object,
            default: () => {
                return {
                    fromDate: '',
                    toDate: ''
                };
            }
        },
        filterValue: {
            type: Object,
            default: null
        }
    },
    components: { TeamRegionMemberFilterOptions, QuotingStatusFilter, CustomerFilterOptions },
    data() {
        return {
            statuses: ['Active', ...STATUS_SHIPMENT_CONSTANTS, 'All'],
            statusFilterValue: 'Active',
            teamMemberFilterValue: '',
            carrierTeamFilterValue: null,
            fromDate: '',
            toDate: '',
            selectedTeamRegionId: null,
            selectedQuotingStatus: null,
            customerIdFilterValue: null,
            showBlankShipmentDateFirst: false
        };
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions',
            user: 'user/user',
            canEditOwnTeamRegion: 'user/canEditOwnTeamRegion',
            canViewTeamRegion: 'user/canViewTeamRegion',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isReadOnlyUser: 'user/isReadOnlyUser',
            hasCustomerFilterAccess: 'user/hasInventoryListAccess',
            isCustomerAdmin: 'user/isCustomerAdmin'
        })
    },
    mounted() {
        if (this.filterValue.fromDate) {
            this.fromDate = this.filterValue.fromDate;
        }

        if (this.filterValue.toDate) {
            this.toDate = this.filterValue.toDate;
        }
    },
    watch: {
        statusFilterValue(newValue, oldValue) {
            this.handleFilter();
        },
        dateRange: {
            handler(newVal) {
                if (newVal.fromDate) {
                    this.fromDate = moment(newVal.fromDate).format(DATE_TYPES.internationalDate);
                }
                if (newVal.toDate) {
                    this.toDate = moment(newVal.toDate).format(DATE_TYPES.internationalDate);
                }
            },
            deep: true
        },
        fromDate(newVal, oldVal) {
            if (
                moment(newVal).format(DATE_TYPES.internationalDate) ===
                moment(oldVal).format(DATE_TYPES.internationalDate)
            ) {
                return;
            }

            if (
                this.fromDate &&
                this.toDate &&
                moment(newVal).format(DATE_TYPES.internationalDate) >
                    moment(this.toDate).format(DATE_TYPES.internationalDate)
            ) {
                this.$notify({
                    message: 'From date cannot be later than the to date.',
                    type: 'danger'
                });
                return;
            }

            this.handleFilter();
        },
        toDate(newVal, oldVal) {
            if (
                moment(newVal).format(DATE_TYPES.internationalDate) ===
                moment(oldVal).format(DATE_TYPES.internationalDate)
            ) {
                return;
            }

            if (
                this.fromDate &&
                this.toDate &&
                moment(newVal).format(DATE_TYPES.internationalDate) <
                    moment(this.fromDate).format(DATE_TYPES.internationalDate)
            ) {
                this.$notify({
                    message: 'To date cannot be earlier than the from date.',
                    type: 'danger'
                });
                return;
            }

            this.handleFilter();
        }
    },
    methods: {
        // eslint-disable-next-line func-names
        handleFilter: _.debounce(function() {
            this.handleFilteredValue();
        }, 700),
        clearFilters() {
            this.statusFilterValue = '';
            this.teamMemberFilterValue = '';
            this.carrierTeamFilterValue = null;
            this.fromDate = '';
            this.toDate = '';
            this.selectedTeamRegionId = null;
            this.customerIdFilterValue = null;
            this.showBlankShipmentDateFirst = null;
        },
        handleFilteredValue() {
            // use nextTick to ensure that date values have already been changed.
            // this is because the @selected method of md-datepicker is asynchronous.
            this.$nextTick(() => {
                const { statusFilterValue, teamMemberFilterValue } = this;

                let fromDate = '';
                if (this.fromDate) {
                    fromDate = moment(this.fromDate).format(DATE_TYPES.internationalDate);
                }

                let toDate = '';
                if (this.toDate) {
                    toDate = moment(this.toDate).format(DATE_TYPES.internationalDate);
                }

                const eventArgs = {
                    status: statusFilterValue,
                    teamMemberPublicUserId: teamMemberFilterValue,
                    carrierTeamId: this.carrierTeamFilterValue,
                    fromDate,
                    toDate,
                    teamRegionId: this.selectedTeamRegionId,
                    quotingStatus: this.selectedQuotingStatus,
                    customerId: this.customerIdFilterValue,
                    showBlankShipmentDateFirst: this.showBlankShipmentDateFirst
                };
                this.$emit('onFilterShipments', eventArgs);
            });
        },
        handleTeamRegionMemberChanged(val) {
            this.teamMemberFilterValue = val.type === 'Member' ? val.publicUserId : null;
            this.carrierTeamFilterValue = val.type === 'Carrier' ? val.carrierTeamId : null;
            this.selectedTeamRegionId = val.teamRegionId;
            this.handleFilter();
        },
        handleQuotingStatusChanged(val) {
            this.selectedQuotingStatus = val;
            this.handleFilter();
        },
        handleCustomerChanged(value) {
            this.customerIdFilterValue = value;
            this.handleFilter();
        },
        handleShowBlankShipmentDateFirst(value) {
            this.showBlankShipmentDateFirst = value;
            this.handleFilter();
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-steps--container {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }
    .filter-step-date {
        width: 250px;
        margin-right: 0;
        min-height: initial;
    }

    .filter-status {
        min-width: 150px;
        margin-right: 0;
        min-height: initial;
        position: absolute;
    }

    .filter-button--container {
        display: inline-block;
    }
    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }

    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        bottom: 30px;
        color: #aaaaaa;
        font-weight: 400;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }

    .start-date:before,
    .start-date:after {
        content: 'Start Date';
    }
    .end-date:before,
    .end-date:after {
        content: 'End Date';
    }

    ::v-deep .md-has-value:before,
    ::v-deep .md-has-value:after {
        bottom: 0;
        height: 0;
        content: '';
    }

    ::v-deep .md-datepicker .md-input {
        position: absolute;
        z-index: 9;
    }

    ::v-deep .md-datepicker .md-input-action {
        z-index: 99;
        right: 52px;
    }
}
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}
::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs__search {
    cursor: pointer;
}

::v-deep .vs__selected-options {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-wrap: nowrap;
}
</style>
