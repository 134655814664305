var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loadShapesOnly
    ? _c("div", { staticClass: "area-fence map-panel" }, [
        _c(
          "div",
          { staticClass: "floating-service-area" },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-black" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("my_location")])],
                      1
                    ),
                    _vm.selectedTab === "areaList"
                      ? _c(
                          "md-button",
                          {
                            staticClass:
                              "md-primary md-just-icon md-round md-card-header-action-button",
                            attrs: {
                              title: "Turn On/Off preview on the map screen",
                            },
                            on: {
                              click: _vm.handleServiceAreaEntireMapPreview,
                            },
                          },
                          [
                            _c("md-icon", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.toggleMainPreviewList
                                      ? "visibility_off"
                                      : "visibility"
                                  ) +
                                  "\n                    "
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("md-card-content", [
                  _vm.selectedTab === "areaList"
                    ? _c(
                        "div",
                        [
                          _c(
                            "md-table",
                            { staticClass: "area-list-table" },
                            [
                              _c(
                                "md-table-row",
                                [
                                  _c("md-table-head", [_vm._v("Name")]),
                                  _c("md-table-head", [_vm._v("Actions")]),
                                ],
                                1
                              ),
                              _vm._l(_vm.areaList, function (serviceArea) {
                                return _c(
                                  "md-table-row",
                                  {
                                    key: serviceArea[_vm.idName],
                                    class:
                                      serviceArea[_vm.idName] == [_vm.idName]
                                        ? "highlight-item"
                                        : "",
                                  },
                                  [
                                    _c(
                                      "md-table-cell",
                                      {
                                        class:
                                          (_vm.selectedArea ==
                                          serviceArea[_vm.idName]
                                            ? "selected-area-highlight "
                                            : "") + " service-area-table-name",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.zoomToFence(
                                                  serviceArea[_vm.idName]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            serviceArea.suburbLocalities
                                              .length == 0
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v("polyline"),
                                                    ]),
                                                    _c("md-tooltip", [
                                                      _vm._v(
                                                        "This " +
                                                          _vm._s(_vm.title) +
                                                          " was drawn using the polyline"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "span",
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v("playlist_add"),
                                                    ]),
                                                    _c("md-tooltip", [
                                                      _vm._v(
                                                        "\n                                            This " +
                                                          _vm._s(_vm.title) +
                                                          " was created from the list of suburbs\n                                        "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(serviceArea.name) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      {
                                        staticClass:
                                          "service-area-table-actions",
                                      },
                                      [
                                        _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-warning md-just-icon md-round action-button",
                                            attrs: {
                                              title: "Edit service area",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleEditServiceArea(
                                                  serviceArea[_vm.idName]
                                                )
                                              },
                                            },
                                          },
                                          [_c("md-icon", [_vm._v("edit")])],
                                          1
                                        ),
                                        _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-primary md-just-icon md-round action-button",
                                            attrs: {
                                              title:
                                                "Toggle service area visibility",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleServiceAreaPreview(
                                                  serviceArea[_vm.idName]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("md-icon", [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    serviceArea.areaVisible
                                                      ? "visibility"
                                                      : "visibility_off"
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-danger md-just-icon md-round action-button",
                                            attrs: {
                                              title: "Delete service area",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDeleteServiceArea(
                                                  serviceArea[_vm.idName]
                                                )
                                              },
                                            },
                                          },
                                          [_c("md-icon", [_vm._v("delete")])],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                          _c(
                            "md-button",
                            {
                              staticClass:
                                "md-primary md-block service-area-add-button",
                              on: { click: _vm.handleAddNewServiceArea },
                            },
                            [
                              _vm._v(
                                "\n                        ADD NEW SERVICE AREA\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm.selectedTab === "create" ||
                      _vm.selectedTab === "search"
                    ? _c("div", { staticClass: "create-service-area" }, [
                        _c("div", { staticClass: "md-layout" }, [
                          _vm.selectedTab === "search" && _vm.listLevel === 1
                            ? _c(
                                "div",
                                { staticClass: "md-layout-item md-size-100" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "select-state-title" },
                                    [
                                      _vm._v(
                                        "\n                                Select a state\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: [
                                {
                                  "md-layout-item": true,
                                  "md-size-100": _vm.selectedTab === "create",
                                  "side-panel": _vm.selectedTab === "search",
                                },
                              ],
                            },
                            [
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  {
                                    class: [
                                      {
                                        "md-size-50":
                                          _vm.selectedTab === "create",
                                        "md-layout-item side-panel-button-container": true,
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        class: [
                                          {
                                            "selected-side-panel-button":
                                              _vm.selectedTab === "create",
                                            "side-panel-left-button":
                                              _vm.selectedTab === "create",
                                          },
                                        ],
                                        attrs: {
                                          disabled: _vm.hasSelectedDrawMode,
                                        },
                                        on: { click: _vm.handleDrawOnMap },
                                      },
                                      [
                                        _c(
                                          "div",
                                          [_c("md-icon", [_vm._v("draw")])],
                                          1
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            "\n                                            DRAW ON MAP\n                                        "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    class: [
                                      {
                                        "md-size-50":
                                          _vm.selectedTab === "create",
                                        "md-layout-item side-panel-button-container": true,
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        class: [
                                          {
                                            "selected-side-panel-button":
                                              _vm.selectedTab === "search",
                                            "side-panel-right-button":
                                              _vm.selectedTab === "create",
                                          },
                                        ],
                                        attrs: {
                                          disabled: _vm.hasSelectedDrawMode,
                                        },
                                        on: { click: _vm.handleShowStates },
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("md-icon", [
                                              _vm._v("checklist_rtl"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            "\n                                            SELECT FROM LIST\n                                        "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm.selectedTab == "create" && _vm.newArea
                            ? _c(
                                "div",
                                { staticClass: "md-layout-item md-size-100" },
                                [
                                  _vm.newArea
                                    ? _c(
                                        "div",
                                        { staticClass: "area-list-wrapper" },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "form-wrapper",
                                                {
                                                  staticClass: "form-wrapper",
                                                  attrs: { validator: _vm.$v },
                                                },
                                                [
                                                  _c(
                                                    "form-group",
                                                    {
                                                      attrs: {
                                                        name: "areaName",
                                                        label: "Name",
                                                      },
                                                    },
                                                    [
                                                      _c("md-input", {
                                                        directives: [
                                                          {
                                                            name: "focus",
                                                            rawName: "v-focus",
                                                          },
                                                        ],
                                                        attrs: {
                                                          "aria-placeholder":
                                                            "Name",
                                                        },
                                                        model: {
                                                          value: _vm.areaName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.areaName = $$v
                                                          },
                                                          expression:
                                                            "areaName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "form-button" },
                                                [
                                                  _c(
                                                    "md-button",
                                                    {
                                                      staticClass:
                                                        "md-primary dialog-button md-theme-default",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleAddNewArea()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            Save\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "md-button",
                                                    {
                                                      staticClass:
                                                        "md-default dialog-button md-theme-default",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.cancelAddEditMode()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            Cancel\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm.selectedTab == "search"
                            ? _c(
                                "div",
                                {
                                  class: [
                                    {
                                      "md-layout-item": true,
                                      "md-size-100":
                                        _vm.selectedTab === "create",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class:
                                        _vm.listLevel == 1
                                          ? "list-container-state"
                                          : "list-container",
                                    },
                                    [
                                      _vm.listLevel == 1
                                        ? _c("div", [
                                            _vm.listOfStates.length
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    _vm.listOfStates,
                                                    function (state) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: state.stateId,
                                                          class: [
                                                            {
                                                              "state-item": true,
                                                              "selected-state-item":
                                                                state.stateId ===
                                                                _vm.currentStateId,
                                                            },
                                                          ],
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleClickState(
                                                                state.stateId
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            " +
                                                              _vm._s(
                                                                state.province
                                                              ) +
                                                              "\n                                        "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "no-result-container",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        We currently don't support your country please contact support or email us\n                                        at\n                                        "
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "mailto:support@locate2u.com",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "support@locate2u.com"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                          ])
                                        : _vm._e(),
                                      _vm.listLevel == 2
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "back-btn",
                                                on: {
                                                  click: _vm.handleShowStates,
                                                },
                                              },
                                              [
                                                _c("md-icon", [
                                                  _vm._v("arrow_back"),
                                                ]),
                                                _vm._v(
                                                  "\n                                        Back to list of states\n                                    "
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "search-suburb-title",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Search suburb/s:\n                                    "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.suburbNames,
                                                      expression: "suburbNames",
                                                    },
                                                  ],
                                                  staticClass: "c-textarea",
                                                  attrs: {
                                                    placeholder:
                                                      "Search a list of suburbs or post codes separated by comma or an individual suburb or post code",
                                                  },
                                                  domProps: {
                                                    value: _vm.suburbNames,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.suburbNames =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "md-button",
                                                  {
                                                    staticClass:
                                                      "validate-btn md-primary",
                                                    on: {
                                                      click:
                                                        _vm.handleMultipleSuburbSearch,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            Validate\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "search-section" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "preview-title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            Result/s: (" +
                                                        _vm._s(
                                                          _vm
                                                            .listOfSuburbSearchResult
                                                            .length
                                                        ) +
                                                        ")\n                                            "
                                                    ),
                                                    _vm.listOfSuburbSearchResult
                                                      .length > 0
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "preview-btn",
                                                            on: {
                                                              click:
                                                                _vm.toggleSearchResultPreview,
                                                            },
                                                          },
                                                          [
                                                            _c("md-icon", [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    _vm.toggleSearchResultList
                                                                      ? "visibility"
                                                                      : "visibility_off"
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]),
                                                            _c("md-tooltip", [
                                                              _vm._v(
                                                                "Turn On/Off preview on the map screen"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.listOfSuburbSearchResult
                                                      .length > 0
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "preview-btn",
                                                            on: {
                                                              click:
                                                                _vm.addSearchResultToPreviewList,
                                                            },
                                                          },
                                                          [
                                                            _c("md-icon", [
                                                              _vm._v("add"),
                                                            ]),
                                                            _c("md-tooltip", [
                                                              _vm._v(
                                                                "Add search result to preview list"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "search-container",
                                                  },
                                                  [
                                                    _vm.isLoading
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "loader",
                                                          },
                                                          [
                                                            _c("fade-loader", {
                                                              attrs: {
                                                                loading:
                                                                  _vm.isLoading,
                                                                color:
                                                                  "#808080",
                                                              },
                                                            }),
                                                            _c("span", [
                                                              _vm._v(
                                                                "Loading..."
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _c("div", [
                                                          _vm
                                                            .listOfSuburbSearchResult
                                                            .length
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "item-list",
                                                                },
                                                                _vm._l(
                                                                  _vm.listOfSuburbSearchResult,
                                                                  function (
                                                                    suburb
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: suburb.suburbLocalityId,
                                                                        staticClass:
                                                                          "state-item",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.addToPreviewList(
                                                                                        suburb
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "md-icon",
                                                                                  {
                                                                                    staticClass:
                                                                                      "blue-icon",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "add"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "md-tooltip",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Add to list"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.handleSuburbPreview(
                                                                                        suburb,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "md-icon",
                                                                                  {
                                                                                    staticClass:
                                                                                      "info-icon",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "visibility"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "md-tooltip",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Show map preview"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                suburb.namePostCode
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            : _vm
                                                                .listOfSuburbSearchResult
                                                                .length == 0 &&
                                                              _vm.currentSearchTextValue
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "no-result-container",
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "No results matching your search could be found."
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "no-result-container",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                    Search suburb/s or post codes to seee results.\n                                                "
                                                                  ),
                                                                ]
                                                              ),
                                                        ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm.listLevel == 2
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "list-container-search",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "selected-preview" },
                                            [
                                              _c(
                                                "form-wrapper",
                                                {
                                                  staticClass: "form-wrapper",
                                                  attrs: { validator: _vm.$v },
                                                },
                                                [
                                                  _c(
                                                    "form-group",
                                                    {
                                                      attrs: {
                                                        name: "areaName",
                                                        label: "Name",
                                                      },
                                                    },
                                                    [
                                                      _c("md-input", {
                                                        directives: [
                                                          {
                                                            name: "focus",
                                                            rawName: "v-focus",
                                                          },
                                                        ],
                                                        attrs: {
                                                          "aria-placeholder":
                                                            "Name",
                                                        },
                                                        model: {
                                                          value: _vm.areaName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.areaName = $$v
                                                          },
                                                          expression:
                                                            "areaName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div"),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "preview-title",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        Your selection/s: (" +
                                                      _vm._s(
                                                        _vm.previewSuburbList
                                                          .length
                                                      ) +
                                                      ")\n                                        "
                                                  ),
                                                  _vm.previewSuburbList.length >
                                                  0
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "preview-btn",
                                                          on: {
                                                            click:
                                                              _vm.turnOffAllSelectedPreview,
                                                          },
                                                        },
                                                        [
                                                          _c("md-icon", [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm.togglePreviewList
                                                                    ? "visibility"
                                                                    : "visibility_off"
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]),
                                                          _c("md-tooltip", [
                                                            _vm._v(
                                                              "Turn On/Off preview on the map screen"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm.previewSuburbList.length > 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "preview-box",
                                                    },
                                                    _vm._l(
                                                      _vm.previewSuburbList,
                                                      function (previewSuburb) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: previewSuburb.suburbLocalityId,
                                                            staticClass:
                                                              "preview-item",
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeFromList(
                                                                          previewSuburb
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "md-icon",
                                                                    {
                                                                      staticClass:
                                                                        "red-icon",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "close"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "md-tooltip",
                                                                    [
                                                                      _vm._v(
                                                                        "Remove item from list"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleSuburbPreview(
                                                                          previewSuburb
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "md-icon",
                                                                    {
                                                                      staticClass:
                                                                        "info-icon",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                        visibility\n                                                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "md-tooltip",
                                                                    [
                                                                      _vm._v(
                                                                        "Show preview in the map screen"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]),
                                                            _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  previewSuburb.namePostCode
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "preview-box",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "no-result-container",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            Select suburb/s from results\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                          _vm.listLevel == 2
                                            ? _c(
                                                "div",
                                                { staticClass: "list-btn" },
                                                [
                                                  _c(
                                                    "md-button",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.cancelAddEditMode()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        Cancel\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "md-button",
                                                    {
                                                      staticClass: "md-primary",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleAddNewSuburbArea()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        Save\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n                    SELECT LIST\n                "
                        ),
                      ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }