<template>
    <div class="filter-toolbar">
        <span class="filter-title">Current Filter:</span>
        <div class="filter-selection" :class="currentFilter ? 'present' : 'empty'">
            <div class="col1 layout-column" @click="showFilterSelection()">
                <md-icon class="icon edit">tune</md-icon>
            </div>
            <div class="col2 layout-column selected-filter-name">
                <span :class="currentFilter ? 'has-filter' : 'no-filter'">
                    {{ currentFilter ? currentFilter.name : 'No filter' }}
                </span>
            </div>
            <div class="col3 layout-column" @click="clearCurrentFilter()">
                <md-icon v-if="currentFilter" class="icon delete">cancel</md-icon>
            </div>
        </div>
        <div class="filter-options">
            <a
                v-if="currentFilter && currentFilter.isSaving === false && filterModified"
                class="active"
                @click="saveCurrentFilter()"
            >
                Save
            </a>
            <span v-else-if="currentFilter && currentFilter.isSaving === true" class="saving">Saving...</span>
            <span v-else class="inactive">Save</span>
            |
            <a :class="filterModified ? 'active' : 'inactive'" @click="revertToFilterConfig()">
                Revert
            </a>
            |
            <a :class="hasGridFilter ? 'active' : 'inactive'" @click="saveNewFilter()">
                Save As New
            </a>

            <div v-if="newFilterDetailsVisible" class="save-new-filter-details" v-click-outside="exitModals">
                <div class="col1 layout-column">
                    <input
                        type="text"
                        class="filter-name-textbox"
                        placeholder="Enter a name for your filter"
                        v-model="newFilterName"
                    />
                </div>
                <div class="col2 layout-column" @click="newFilterSaved()">
                    <md-icon class="saveicon">save</md-icon>
                </div>
            </div>
        </div>
        <div v-if="filterSelectionVisible" class="filter-selection-popup" v-click-outside="exitModals">
            <div class="exit-icon" @click="exitModals"><md-icon>close</md-icon></div>
            Your Saved Filters

            <table class="filter-selection-table">
                <thead>
                    <tr>
                        <th>Default</th>
                        <th>Name</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="filter in userSavedJobFilters"
                        :key="filter.id"
                        :class="{ 'saving-row': filter.isSaving }"
                    >
                        <td
                            @click="setDefaultFilter(filter)"
                            class="icon-column"
                            @mouseover="defaultColumnMouseOver(filter)"
                            @mouseleave="defaultColumnMouseLeave(filter)"
                        >
                            <md-icon
                                class="icon default"
                                v-if="filter.shouldApplyByDefault"
                                aria-label="Default filter will load automatically when you visit this page"
                            >
                                star
                            </md-icon>
                            <md-icon
                                class="icon default"
                                v-else-if="filter.showDefaultIcon"
                                aria-label="Select new default filter to load"
                            >
                                star_border
                            </md-icon>
                        </td>
                        <td @mouseover="selectColumnMouseOver(filter)" @mouseleave="selectColumnMouseLeave(filter)">
                            <div v-if="!filter.isEditing" @click="changeSelectedFilter(filter)">
                                <span class="filter-name">{{ filter.name }}</span>
                                <md-icon
                                    v-if="Object.is(currentFilter, filter)"
                                    class="icon selected"
                                    title="Selected filter"
                                    aria-label="Currently selected filter"
                                >
                                    check_circle
                                </md-icon>
                                <md-icon
                                    v-else-if="filter.showSelectIcon"
                                    class="icon selected"
                                    title="Select this filter"
                                    aria-label="Select this filter"
                                >
                                    check
                                </md-icon>
                            </div>
                            <div v-else>
                                <input
                                    type="text"
                                    v-model="filter.name"
                                    @blur="saveFilterName(filter)"
                                    @keyup.enter="saveFilterName(filter)"
                                />
                            </div>
                        </td>
                        <td @click="editFilterName(filter)" class="icon-column">
                            <md-icon class="icon edit" title="Rename filter" aria-label="Change name of filter">
                                edit
                            </md-icon>
                        </td>
                        <td @click="deleteFilter(filter)" class="icon-column">
                            <md-icon
                                class="icon delete"
                                title="Delete this filter"
                                aria-label="Delete the filter permanently"
                            >
                                delete
                            </md-icon>
                        </td>
                        <td v-if="filter.isSaving" class="saving-overlay" colspan="4">Saving...</td>
                    </tr>
                </tbody>
            </table>

            <div class="details-text">
                Your saved filters belong to you. You may optionally select a default filter which will load
                automatically when you visit this page.
            </div>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests } from '@/helpers';
import Vue from 'vue';

export default {
    name: 'FilterManager',
    mixins: [GeneralMixin],
    props: {
        filterModified: {
            type: Boolean,
            default: () => false
        },
        filterObject: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            userSavedJobFilters: [],
            currentFilter: null,
            newFilterName: '',
            newFilterDetailsVisible: false,
            filterSelectionVisible: false
        };
    },
    computed: {
        hasGridFilter() {
            return this.hasGridFilterInternal();
        }
    },
    async mounted() {
        if (this.$route.query.id) {
            const userSavedJobFilterId = this.$route.query.id;
            await this.loadUserSavedJobFilters(userSavedJobFilterId);
            if (this.currentFilter === null) {
                this.updateQueryParam(null);
            }
        } else {
            await this.loadUserSavedJobFilters();
        }
    },
    methods: {
        updateQueryParam(userSavedJobFilterId) {
            if (userSavedJobFilterId) {
                this.$router.replace({
                    query: {
                        id: userSavedJobFilterId
                    }
                });
            } else {
                this.$router.replace({
                    query: {}
                });
            }
        },
        defaultColumnMouseOver(filter) {
            if (filter.isSaving === true) {
                return;
            }

            filter.showDefaultIcon = true;
        },
        defaultColumnMouseLeave(filter) {
            filter.showDefaultIcon = false;
        },
        selectColumnMouseOver(filter) {
            if (filter.isSaving === true) {
                return;
            }

            filter.showSelectIcon = true;
        },
        selectColumnMouseLeave(filter) {
            filter.showSelectIcon = false;
        },
        deleteFilter(filter) {
            if (filter.isSaving === true) {
                return;
            }

            filter.isSaving = true;

            const api = `/api/jobs/filters/${filter.userSavedJobFilterId}`;
            const payload = {
                method: 'delete'
            };
            handleRequests(api, payload).then((response) => {
                if (response.status === 200) {
                    this.userSavedJobFilters = this.userSavedJobFilters.filter((x) => x !== filter);
                    if (this.currentFilter === filter) {
                        this.currentFilter = null;
                        this.updateFilterModified(false);
                        this.notifySelectedFilterChange();
                        this.updateQueryParam(null);
                    }
                }

                filter.isSaving = false;
            });
        },
        editFilterName(filter) {
            if (filter.isSaving === true) {
                return;
            }

            filter.isEditing = true;
        },
        saveFilterName(filter) {
            if (filter.isSaving) {
                return;
            }

            filter.isSaving = true;

            const api = `/api/jobs/filters/${filter.userSavedJobFilterId}`;
            const payload = {
                method: 'put',
                data: filter
            };
            handleRequests(api, payload).then((response) => {
                filter.isEditing = false;
                filter.isSaving = false;
                filter.showSelectIcon = false;
            });
        },
        setDefaultFilter(filter) {
            if (filter.isSaving === true) {
                return;
            }

            filter.isSaving = true;

            this.userSavedJobFilters.forEach((x) => {
                if (x !== filter) {
                    x.shouldApplyByDefault = false;
                }
            });

            filter.shouldApplyByDefault = !filter.shouldApplyByDefault;

            // Get filter.shouldApplyByDefault as text
            const defaultText = filter.shouldApplyByDefault ? 'true' : 'false';

            const api = `/api/jobs/filters/${filter.userSavedJobFilterId}/default/${defaultText}`;
            const payload = {
                method: 'put'
            };
            handleRequests(api, payload).then(() => {
                filter.isSaving = false;
            });
        },
        updateFilterModified(value) {
            this.$emit('update:filterModified', value);
        },
        async saveCurrentFilter() {
            if (this.currentFilter && this.filterObject && this.filterModified) {
                this.currentFilter.isSaving = true;
                this.currentFilter.jobFilterJson = JSON.stringify(this.filterObject);

                const api = `/api/jobs/filters/${this.currentFilter.userSavedJobFilterId}`;
                const payload = {
                    method: 'put',
                    data: this.currentFilter
                };
                const response = await handleRequests(api, payload);

                if (response.status === 200) {
                    this.updateFilterModified(false);
                    this.notifySelectedFilterChange();
                }

                this.currentFilter.isSaving = false;
            }
        },
        revertToFilterConfig() {
            if (this.filterModified) {
                if (this.currentFilter) {
                    this.updateFilterModified(false);
                    this.notifySelectedFilterChange();
                } else {
                    this.updateFilterModified(false);
                    this.notifySelectedFilterChange();
                }
            }
        },
        saveNewFilter() {
            if (this.hasGridFilterInternal()) {
                this.newFilterName = '';
                this.newFilterDetailsVisible = true;
            }
        },
        newFilterSaved() {
            if (this.hasGridFilterInternal() && this.newFilterName && this.newFilterName.trim() !== '') {
                const newFilter = {
                    name: this.newFilterName,
                    jobFilterJson: JSON.stringify(this.filterObject),
                    shouldApplyByDefault: false
                };

                const api = `/api/jobs/filters`;
                const payload = {
                    method: 'post',
                    data: newFilter
                };
                handleRequests(api, payload).then((response) => {
                    if (response.data) {
                        newFilter.userSavedJobFilterId = response.data;
                        this.currentFilter = newFilter;
                        this.userSavedJobFilters.push(newFilter);
                        this.newFilterDetailsVisible = false;
                        this.updateQueryParam(this.currentFilter.userSavedJobFilterId);
                    }
                });
            }

            this.newFilterDetailsVisible = false;
        },
        hasGridFilterInternal() {
            if (this.filterObject && this.filterObject != null) {
                if (this.filterObject.filterModel && Object.keys(this.filterObject.filterModel).length > 0) {
                    return true;
                }
                if (this.filterObject.sortModel && this.filterObject.sortModel.length > 0) {
                    return true;
                }
                if (this.filterObject.SearchAllFields && this.filterObject.SearchAllFields.trim() !== '') {
                    return true;
                }
                if (this.filterObject.columnState && this.filterObject.columnState.length > 0) {
                    return true;
                }
            }

            return false;
        },
        exitModals() {
            if (this.newFilterDetailsVisible === true) {
                this.newFilterName = '';
                this.newFilterDetailsVisible = false;
            }
            if (this.filterSelectionVisible === true) {
                this.filterSelectionVisible = false;
            }
        },
        notifySelectedFilterChange() {
            if (this.currentFilter && this.currentFilter.jobFilterJson) {
                const filterObject = JSON.parse(this.currentFilter.jobFilterJson);
                this.$emit('selectedFilterChanged', filterObject);
            } else {
                this.$emit('selectedFilterChanged', null);
            }
        },
        async loadUserSavedJobFilters(userSavedJobFilterId) {
            const api = `/api/jobs/filters`;
            const payload = {
                method: 'get'
            };
            const response = await handleRequests(api, payload);

            if (response.data) {
                this.userSavedJobFilters = response.data;

                // Add on some properties that are observable so that we can interact with them
                this.userSavedJobFilters.forEach((x) => {
                    Vue.set(x, 'showDefaultIcon', false);
                    Vue.set(x, 'showSelectIcon', false);
                    Vue.set(x, 'isEditing', false);
                    Vue.set(x, 'isSaving', false);
                });

                // ensure userSavedJobFilterId is a number
                const id = parseInt(userSavedJobFilterId, 10);

                if (id && id > 0) {
                    const filter = this.userSavedJobFilters.find((x) => x.userSavedJobFilterId === id);
                    if (filter) {
                        this.currentFilter = filter;
                        this.notifySelectedFilterChange();
                    }
                }
                if (this.currentFilter === null) {
                    const defaultFilter = this.userSavedJobFilters.find((x) => x.shouldApplyByDefault);

                    if (defaultFilter) {
                        this.currentFilter = defaultFilter;
                        this.notifySelectedFilterChange();
                        this.updateQueryParam(this.currentFilter.userSavedJobFilterId);
                    }
                }
            }
        },
        showFilterSelection() {
            this.filterSelectionVisible = !this.filterSelectionVisible;
        },
        clearCurrentFilter() {
            this.currentFilter = null;
            this.updateFilterModified(false);
            this.notifySelectedFilterChange();
            this.updateQueryParam(null);
            this.filterSelectionVisible = false;
        },
        changeSelectedFilter(filter) {
            if (filter) {
                if (filter.isSaving === true) {
                    return;
                }

                if (this.currentFilter !== filter) {
                    this.currentFilter = filter;
                    this.updateFilterModified(false);
                    this.notifySelectedFilterChange();
                    this.updateQueryParam(this.currentFilter.userSavedJobFilterId);
                }
                this.filterSelectionVisible = false;
            } else {
                this.currentFilter = null;
                this.updateFilterModified(false);
                this.notifySelectedFilterChange();
                this.updateQueryParam(null);
                this.filterSelectionVisible = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-toolbar {
    display: grid;
    grid-template-rows: auto 1fr auto;
    row-gap: 0px;
    justify-content: stretch;
}

.filter-title {
    grid-row: 1;
    justify-items: left;
    font-size: 12px;
}

.filter-selection {
    grid-row: 2;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #999;
    background-color: #fff;
    padding-left: 10px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 5px;
    align-items: center;
}

.selected-filter-name {
    line-height: 25px;
}

.filter-selection-popup {
    position: absolute;
    z-index: 99;
    top: 0%;
    right: 0%;
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px #999;
    padding: 10px;
    width: 500px;
    min-height: 100px;
}

.filter-selection-popup .details-text {
    font-size: 12px;
    color: #555;
    margin-top: 10px;
}

.filter-selection-table {
    width: 100%;
    border-collapse: collapse;
}

.filter-selection-table th {
    padding: 3px;
    text-align: left;
    border-bottom: 1px solid #bbb;
    font-size: 10px;
    font-weight: bold;
}

.filter-selection-table tr:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.filter-selection-table td {
    padding: 3px;
}

.filter-selection-table .filter-name {
    font-size: 12px;
    cursor: pointer;
    color: #444 !important;
}

.filter-selection-table .filter-name:hover {
    text-decoration: underline;
    color: #005bff !important;
}

.filter-selection-table .icon-column {
    width: 30px;
    text-align: center;
    cursor: pointer;
}

.saving-row {
    opacity: 0.5;
    pointer-events: none; /* Prevent user interactions */
    position: relative; /* Ensures the overlay positions correctly */
}
.saving-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    color: #333;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    pointer-events: none; /* Make it non-interactive */
}

.icon {
    cursor: pointer;
    font-size: 20px;
    color: #999;
}

.icon.default:hover {
    color: #aa8800;
}

.icon.edit:hover {
    color: #005bff;
}

.icon.delete:hover {
    color: #ff0000;
}

.icon.selected {
    color: #00aa5b;
    font-size: 16px;
    padding-left: 16px;
}

.icon.bookmark {
    color: #888;
    font-size: 14px;
}

.icon.Bookmark:hover {
    color: #005bff !important;
}

.filter-selection-popup .exit-icon {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
}
.filter-selection-popup .exit-icon:hover {
    color: #aa5b00 !important;
}

.filter-options {
    grid-row: 3;
    justify-items: center;
    font-size: 12px;
    color: #999;
    text-decoration: none;
    anchor-name: --filter-options-anchor;
    position: relative;
}

.filter-options a.inactive {
    cursor: default;
    color: #999;
    text-decoration: none;
}
.filter-options a.inactive:hover {
    cursor: default;
    color: #999 !important;
    text-decoration: none;
}

.filter-options a.active {
    cursor: pointer;
    color: #005bff !important;
    text-decoration: none;
}
.filter-options a.active:hover {
    cursor: pointer;
    color: #005bff !important;
    text-decoration: underline !important;
}

.filter-options .saving {
    color: #222;
    font-size: 12px;
}

.save-new-filter-details {
    display: grid;
    position-anchor: --filter-options-anchor;
    grid-template-columns: 1fr auto;
    column-gap: 5px;
    justify-items: center;
    align-items: center;
    border: 1px solid #999;
    border-radius: 4px;
    background-color: #fff;
    max-width: 300px;
    width: 300px;
    position: absolute;
    z-index: 99;
    padding: 5px;
    top: 100%;
    right: 0%;
    box-shadow: 0px 0px 5px 0px #999;
}

.filter-name-textbox {
    width: 100%;
    border: 1px solid #999;
    border-radius: 8px;
    font-size: 16px;
    padding: 6px 10px 6px 10px;
    background-color: #f8f8f8;
    align-items: center;
    justify-self: center;
}

.save-new-filter-details .save-icon {
    cursor: pointer;
    grid-column: 2;
    font-size: 28px;
}

.save-new-filter-details .save-icon:hover {
    color: #00aa5b !important;
}

.layout-column {
    padding: 0px;
    margin: 0px;
    width: 100%;
}

.col1 {
    grid-column: 1;
}

.col2 {
    grid-column: 2;
    height: 100%;
    position: relative;
}

.col3 {
    grid-column: 3;
}
</style>
