<template>
    <div>
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-warning">
                <div class="card-icon">
                    <md-icon>map</md-icon>
                </div>
                <div class="map-router-link" v-if="isTrip">
                    <router-link
                        :to="{
                            name: 'Maps Overview Active User Panel',
                            params: {
                                date: selectedDate,
                                userId: details.assignedTo.publicUserId
                            },
                            query: { from: 'trip' }
                        }"
                        target="_blank"
                    >
                        View Route Playback
                    </router-link>
                </div>
            </md-card-header>

            <md-card-content>
                <div id="map_canvas" style="width: 100%; height: 480px;" />
            </md-card-content>
            <div class="mapbox-footer">
                Use CTRL + Shift + Scroll to zoom the map
            </div>
        </md-card>
    </div>
</template>
<script>
import { MapBoxMixin } from '@/mixins';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { DATE_TYPES } from '@/utils/constants';
import { MarkerPins } from '@/utils/MarkerPins';
import { handleRequests, getStopStatusColour } from '@/helpers';

export default {
    name: 'MapBoxOverlay',
    mixins: [MapBoxMixin],
    props: {
        details: {
            type: Object,
            default: () => {}
        },
        type: {
            type: String,
            default: ''
        },
        clickedHistory: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    async mounted() {
        await this.getDriverDetails();
        this.initializeMap();
    },
    data() {
        return {
            map: null,
            drawingManager: null,
            drawings: [],
            drawingPolygons: [],
            currentDrawingShape: null,
            selectedDate: null,
            isTrip: false,
            driverRouteDetails: null,
            coordinates: [],
            routeData: [],
            markers: [],
            geojson: {
                type: 'FeatureCollection',
                features: []
            },
            animatedMarkerLayer: '',
            animatedMarkerSource: '',
            animatedMarkerImage: ''
        };
    },
    watch: {
        'clickedHistory.status': function(val) {
            // This function renders the pulsing animation for stop status markers
            const marker = this.geojson.features.find(
                (m) =>
                    m.properties.status.toLowerCase() === val.toLowerCase() &&
                    m.properties.actionDate === this.clickedHistory.actionDate
            );

            if (marker === null || marker === undefined) 
                return;

            if (
                this.animatedMarkerLayer !== '' &&
                this.animatedMarkerSource !== '' &&
                this.animatedMarkerImage !== ''
            ) {
                this.map.removeImage(this.animatedMarkerImage);
                this.map.removeLayer(this.animatedMarkerLayer);
                this.map.removeSource(this.animatedMarkerSource);
            }

            const rgb = this.hexToRgb(marker.properties.color);
            const longitude = marker.geometry.coordinates[0];
            const latitude = marker.geometry.coordinates[1];
            const size = 80;
            const { map } = this;
            map.setCenter([longitude, latitude]);
            const pulsingDot = {
                width: size,
                height: size,
                data: new Uint8Array(size * size * 4),

                // When the layer is added to the map,
                // get the rendering context for the map canvas.
                onAdd() {
                    const canvas = document.createElement('canvas');
                    canvas.width = this.width;
                    canvas.height = this.height;
                    this.context = canvas.getContext('2d');
                },

                render() {
                    const duration = 1000;
                    const t = (performance.now() % duration) / duration;

                    const radius = (size / 2) * 0.3;
                    const outerRadius = (size / 2) * 0.7 * t + radius;
                    const { context } = this;

                    context.clearRect(0, 0, this.width, this.height);
                    context.beginPath();
                    context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
                    context.fillStyle = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${1 - t})`;
                    context.opacity = 0.5;
                    context.fill();

                    context.beginPath();
                    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
                    context.fillStyle = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`;
                    context.lineWidth = 2 + 4 * (1 - t);
                    context.fill();
                    context.stroke();

                    // Update this image's data with data from the canvas.
                    this.data = context.getImageData(0, 0, this.width, this.height).data;

                    map.triggerRepaint();
                    return true;
                }
            };

            this.map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
            this.map.addSource('dot-point', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [longitude, latitude] // icon position [lng, lat]
                            }
                        }
                    ]
                }
            });
            this.map.addLayer({
                id: 'layer-with-pulsing-dot',
                type: 'symbol',
                source: 'dot-point',
                layout: {
                    'icon-image': 'pulsing-dot'
                }
            });
            this.animatedMarkerImage = 'pulsing-dot';
            this.animatedMarkerLayer = 'layer-with-pulsing-dot';
            this.animatedMarkerSource = 'dot-point';
        }
    },
    methods: {
        async getDriverDetails() {
            const {
                tripDate,
                assignedTo: { publicUserId },
                routeDetails
            } = this.details;
            this.driverRouteDetails = routeDetails;

            if (publicUserId === null) 
                return;

            if (routeDetails === null || routeDetails === undefined) {
                const date = this.$options.filters.dateFormat(tripDate, DATE_TYPES.internationalDate);
                const driverDetailsEndpoint = `/api/trips/${date}?userId=${publicUserId}`;
                const {
                    data: {
                        data: { routeDetails }
                    }
                } = await handleRequests(driverDetailsEndpoint);
                this.driverRouteDetails = routeDetails;
            }
        },
        initializeMap() {
            // get starting location of user. if there is none, default to Australia
            const { latitude, longitude } = this.user.startLocation
                ? this.user.startLocation
                : getRegionCoordinates('AU');

            this.map = this.$_mapBox_createMap('map_canvas', latitude, longitude, null, null);
            this.drawingManager = this.$_map_drawingManager();
            this.map.addControl(this.drawingManager);
            /* this.map.scrollZoom.disable(); */
            this.map.on('wheel', (event) => {
                if (event.originalEvent.ctrlKey) {
                    return;
                }

                if (event.originalEvent.metaKey) {
                    return;
                }

                if (event.originalEvent.altKey) {
                    return;
                }

                event.preventDefault();
            });
            this.map.on('load', () => {
                if (this.type.toLowerCase() === 'stop') {
                    this.initializeStop();
                }

                if (this.type.toLowerCase() === 'shipment') {
                    this.initializeShipment();
                }

                if (this.type.toLowerCase() === 'trip') {
                    this.initializeTrip();
                }
            });
        },
        initializeStop() {
            const { latitude, longitude } = this.details.location;
            new mapboxgl.Marker({ color: '#b40219' })
                .setLngLat([longitude, latitude])
                .setPopup(new mapboxgl.Popup().setHTML(this.getAddressHtmlPopup(this.details.address)))
                .addTo(this.map);

            this.coordinates.push([longitude, latitude]);
            if (this.driverRouteDetails !== null && this.driverRouteDetails !== undefined) {
                this.setDriverMarker();
                this.setRouteData();
            }

            this.setStopHistoryMarkers();

            if (this.coordinates.length > 1) {
                this.setMapBounds();
            } else {
                this.map.jumpTo({
                    center: [longitude, latitude]
                });
                this.map.setZoom(15);
            }
        },
        initializeShipment() {
            new mapboxgl.Marker({ color: '#426E22' })
                .setLngLat([this.details.dropStop.location.longitude, this.details.dropStop.location.latitude])
                .setPopup(new mapboxgl.Popup().setHTML(this.getAddressHtmlPopup(this.details.dropStop.address)))
                .addTo(this.map);

            new mapboxgl.Marker({ color: '#b40219' })
                .setLngLat([this.details.pickupStop.location.longitude, this.details.pickupStop.location.latitude])
                .setPopup(new mapboxgl.Popup().setHTML(this.getAddressHtmlPopup(this.details.pickupStop.address)))
                .addTo(this.map);

            if (!this.checkIfPropertyNullUndefined(this.driverRouteDetails)) {
                this.setDriverMarker(false);
                this.setRouteData();
            }

            this.map.fitBounds(
                [
                    [this.details.dropStop.location.longitude, this.details.dropStop.location.latitude],
                    [this.details.pickupStop.location.longitude, this.details.pickupStop.location.latitude]
                ],
                {
                    padding: 100
                }
            );
        },
        initializeTrip() {
            const { tripDate } = this.details;
            this.selectedDate = moment(tripDate).format(DATE_TYPES.internationalDate);
            this.isTrip = true;
            let stopOrder = 1;

            this.details.stopList.forEach((stopItem) => {
                if (!this.checkIfPropertyNullUndefined(stopItem.isStartStop)) {
                    new mapboxgl.Marker({ color: '#b40219' })
                        .setLngLat([stopItem.location.longitude, stopItem.location.latitude])
                        .setPopup(new mapboxgl.Popup().setHTML(this.getAddressHtmlPopup(stopItem.address)))
                        .addTo(this.map);
                    this.coordinates.push([stopItem.location.longitude, stopItem.location.latitude]);
                } else if (!this.checkIfPropertyNullUndefined(stopItem.isEndStop)) {
                    if (!this.checkIfPropertyNullUndefined(stopItem.location)) {
                        new mapboxgl.Marker({ color: '#426E22' })
                            .setLngLat([stopItem.location.longitude, stopItem.location.latitude])
                            .setPopup(new mapboxgl.Popup().setHTML(this.getAddressHtmlPopup(stopItem.address)))
                            .addTo(this.map);
                        this.coordinates.push([stopItem.location.longitude, stopItem.location.latitude]);
                    }
                } else if (!this.checkIfPropertyNullUndefined(stopItem.location)) {
                    const el = document.createElement('div');
                    el.className = 'marker';
                    el.style.width = '50px';
                    el.style.height = '50px';
                    el.style.backgroundSize = 'cover';
                    el.style.borderRadius = '50%';
                    el.style.backgroundImage = "url('/img/map/mapbox/stop-marker.svg')";
                    el.textContent = stopOrder;
                    el.style.textAlign = 'center';
                    el.style.paddingTop = '15px';
                    el.style.color = '#fff';
                    el.style.fontWeight = 'bold';
                    el.style.fontSize = '15px';
                    new mapboxgl.Marker(el)
                        .setLngLat([stopItem.location.longitude, stopItem.location.latitude])
                        .setPopup(new mapboxgl.Popup().setHTML(this.getAddressHtmlPopup(stopItem.address)))
                        .addTo(this.map);
                    this.coordinates.push([stopItem.location.longitude, stopItem.location.latitude]);
                    stopOrder += 1;
                }
            });

            if (this.details.routeDetails && this.details.routeDetails.coordinates) {
                // Sets the driver marker
                const {
                    coordinates: { latitude, longitude }
                } = this.details.routeDetails;
                const el = document.createElement('div');
                el.className = 'marker';
                el.style.width = '50px';
                el.style.height = '50px';
                el.style.backgroundSize = 'cover';
                el.style.borderRadius = '50%';
                el.style.backgroundImage = `url('${MarkerPins[0].driver}')`;
                new mapboxgl.Marker(el).setLngLat([longitude, latitude]).addTo(this.map);
                this.coordinates.push([longitude, latitude]);
            }
            this.setStartCompleteMarkerManually('started');
            this.setStartCompleteMarkerManually('complete');
            this.setRouteData();
            this.setMapBounds();
        },
        setDriverMarker(setBounds = true) {
            if (this.driverRouteDetails.coordinates && this.driverRouteDetails.route) {
                const {
                    coordinates: { latitude, longitude }
                } = this.driverRouteDetails;
                const el = document.createElement('div');
                el.className = 'marker';
                el.style.width = '50px';
                el.style.height = '50px';
                el.style.backgroundSize = 'cover';
                el.style.borderRadius = '50%';
                el.style.backgroundImage = `url('${MarkerPins[0].driver}')`;
                new mapboxgl.Marker(el).setLngLat([longitude, latitude]).addTo(this.map);
                this.coordinates.push([longitude, latitude]);
            }
            if (setBounds) 
                this.setMapBounds();
        },
        setStopHistoryMarkers() {
            this.details.stopStatusHistory.forEach((stopStatusHistory) => {
                if (stopStatusHistory.location) {
                    const color = getStopStatusColour(stopStatusHistory.newStatus).fillColor;
                    const feature = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [stopStatusHistory.location.longitude, stopStatusHistory.location.latitude]
                        },
                        properties: {
                            status: stopStatusHistory.newStatus,
                            actionDate: stopStatusHistory.actionDate,
                            color
                        }
                    };
                    const el = document.createElement('div');
                    el.style.width = `15px`;
                    el.style.height = `15px`;
                    el.style.display = 'block';
                    el.style.border = 'none';
                    el.style.borderRadius = '50%';
                    el.style.padding = 0;
                    el.style.backgroundColor = color;

                    new mapboxgl.Marker(el)
                        .setLngLat([stopStatusHistory.location.longitude, stopStatusHistory.location.latitude])
                        .setPopup(new mapboxgl.Popup().setHTML(this.getStopStatusChangePopupHtml(stopStatusHistory)))
                        .addTo(this.map);

                    this.coordinates.push([stopStatusHistory.location.longitude, stopStatusHistory.location.latitude]);
                    this.geojson.features.push(feature);
                }
            });
        },
        setRouteData() {
            let decodedPath = '';
            if (!this.checkIfPropertyNullUndefined(this.details.routeDetails)) {
                if (!this.checkIfPropertyNullUndefined(this.details.routeDetails.route))
                    decodedPath = this.$_mapbox_createPath(this.details.routeDetails.route);
            } else if (!this.checkIfPropertyNullUndefined(this.driverRouteDetails)) {
                if (!this.checkIfPropertyNullUndefined(this.driverRouteDetails.route))
                    decodedPath = this.$_mapbox_createPath(this.driverRouteDetails.route);
            }
            if (decodedPath) {
                const routeLine = this.$_mapBox_createRouteData(decodedPath);

                this.routeData.push(routeLine);
                const options = {
                    strokeColor: '#A44BC5',
                    strokeWeight: 3,
                    strokeOpacity: 1,
                    zIndex: 3
                };

                this.$_mapBox_createRouteLine(this.routeData, this.map, this.details.assignedTo.publicUserId, options);
            }
        },
        setMapBounds() {
            const bounds = this.coordinates.reduce(function _(bounds, coord) {
                return bounds.extend(coord);
            }, new mapboxgl.LngLatBounds(this.coordinates[0], this.coordinates[0]));

            this.map.fitBounds(bounds, {
                padding: 100
            });
        },
        setStartCompleteMarkerManually(status) {
            const stopItem = this.details.statusHistory.find((x) => x.newStatus.toLowerCase() === status);
            if (!this.checkIfPropertyNullUndefined(stopItem) && !this.checkIfPropertyNullUndefined(stopItem.location)) {
                let htmlString = `ACTUAL ${status.toUpperCase()} LOCATION CHANGED BY</br>`;
                htmlString += `<b>${stopItem.changedByUserFullName}</br>`;
                new mapboxgl.Marker({ color: getStopStatusColour(stopItem.newStatus).fillColor })
                    .setLngLat([stopItem.location.longitude, stopItem.location.latitude])
                    .setPopup(new mapboxgl.Popup().setHTML(htmlString))
                    .addTo(this.map);
                this.coordinates.push([stopItem.location.longitude, stopItem.location.latitude]);
            }
        },
        getAddressHtmlPopup(address) {
            return `<b>Address:</b> ${address}`;
        },
        getStopStatusChangePopupHtml(stopStatusHistory) {
            let htmlString = '';
            htmlString += `<b>Changed by:</b> </br> ${stopStatusHistory.changedByUserFullName}</br>`;
            htmlString += `<b>Location change:</b> </br> ${this.$options.filters.dateTimeFormat(
                stopStatusHistory.actionDate
            )}</br>`;
            htmlString +=
                `<b><span style="color: ${getStopStatusColour(stopStatusHistory.oldStatus).fillColor}">${
                    stopStatusHistory.oldStatus
                } </span></b> <i class="md-icon md-icon-font arrow-icon md-theme-default" style="color: black; margin-bottom:5px;">arrow_right_alt</i>` +
                ` <b><span style="color:${getStopStatusColour(stopStatusHistory.newStatus).fillColor} ">${
                    stopStatusHistory.newStatus
                }</span></b>`;
            return htmlString;
        },
        hexToRgb(hex) {
            // Need to convert hex to rgb to apply alpha opacity
            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result
                ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                }
                : null;
        },
        checkIfPropertyNullUndefined(property) {
            if (property === null || property === undefined) {
                return true;
            }
            return false;
        }
    }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';

.map-router-link {
    display: inline-block;
    position: absolute;
    right: 23px;
    top: 15px;
    a {
        font-weight: 600;
    }
}

.md-card .md-card-header a {
    color: #2b93ff !important;
}

::v-deep .md-card .md-card-header .card-icon i {
    color: #fff;
}

.mapbox-footer {
    padding-left: 20px;
    padding-bottom: 10px;
}
</style>
