var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-steps--container" },
    [
      _c("CustomerFilterOptionsNew", {
        staticClass: "filter-steps--choices filter-status",
        attrs: { "filter-customer-id": _vm.customerIdFilterValue },
        on: { onCustomerSelected: _vm.handleCustomerFilterChange },
      }),
      _c("ServiceAreaFilterOptions", {
        staticClass: "filter-steps--choices filter-status",
        attrs: { "filter-service-area-id": _vm.serviceAreaFilterValue },
        on: { onSelectedServiceArea: _vm.handleServiceAreaFilterChange },
      }),
      _c("OrderDateFilterOptions", {
        on: { onDateFilterChanged: _vm.handleDateFilterChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }