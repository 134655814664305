<template>
    <div class="profile-container">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Frequent Addresses'
                        }"
                    >
                        <h3 class="title">Frequent Addresses</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ frequentAddress.contactName }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>library_books</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div class="cf-container">
                            <div class="cf-action-container">
                                <md-button
                                    v-if="!editMode && user.role.toLowerCase() !== 'customer'"
                                    title="Edit Frequent Address"
                                    class="md-primary md-just-icon md-round"
                                    @click.stop="toggleEdit()"
                                >
                                    <md-icon>edit</md-icon>
                                </md-button>
                            </div>
                            <div class="frequentAddress-information field-spacing-container">
                                <form-wrapper :validator="$v.frequentAddress" class="form-wrapper">
                                    <div class="information-box">
                                        <div class="md-layout">
                                            <div class="md-layout-item">
                                                <form-group name="addressCode" label="Address Code">
                                                    <md-input
                                                        v-model="frequentAddress.addressCode"
                                                        :disabled="!editMode"
                                                    />
                                                </form-group>
                                            </div>
                                            <div class="md-layout-item">
                                                <form-group v-if="!editMode" name="customer" label="Customer">
                                                    <md-input
                                                        v-model="savedCustomer.name"
                                                        :disabled="!editMode || readOnlyCustomer"
                                                    />
                                                </form-group>
                                                <customer-autocomplete
                                                    v-if="editMode"
                                                    :label="'Customer'"
                                                    id="autocustomer"
                                                    placeholder=""
                                                    :should-focus="true"
                                                    v-model="customer"
                                                    @change="handleCustomerFieldChange"
                                                    :update-customer="true"
                                                />
                                            </div>
                                        </div>

                                        <div class="md-layout">
                                            <div class="md-layout-item">
                                                <form-group
                                                    name="contactName"
                                                    label="Contact Name"
                                                    :messages="{ required: 'Contact Name is required.' }"
                                                >
                                                    <md-input
                                                        v-model="frequentAddress.contactName"
                                                        :disabled="!editMode"
                                                    />
                                                </form-group>
                                            </div>
                                            <div class="md-layout-item">
                                                <form-group
                                                    name="phone"
                                                    label="Contact Number"
                                                    :messages="{ required: 'Contact Number is required.' }"
                                                >
                                                    <md-input v-model="frequentAddress.phone" :disabled="!editMode" />
                                                </form-group>
                                            </div>
                                            <div class="md-layout-item">
                                                <form-group
                                                    name="email"
                                                    label="Email"
                                                    :messages="{ required: 'Email is required.' }"
                                                >
                                                    <md-input v-model="frequentAddress.email" :disabled="!editMode" />
                                                </form-group>
                                            </div>
                                        </div>

                                        <div class="md-layout">
                                            <div class="md-layout-item">
                                                <google-autocomplete
                                                    label="Destination"
                                                    ref="address"
                                                    id="Address"
                                                    classname="form-control autocomplete-input"
                                                    placeholder=""
                                                    :should-focus="false"
                                                    v-model="autocompleteAddress"
                                                    :disable-gps-coordinates="true"
                                                    @change="handleAddressChanged"
                                                    :disabled="!editMode"
                                                ></google-autocomplete>
                                            </div>
                                        </div>

                                        <div class="md-layout">
                                            <div class="md-layout-item">
                                                <form-group name="notes" label="Notes">
                                                    <md-input v-model="frequentAddress.notes" :disabled="!editMode" />
                                                </form-group>
                                            </div>
                                        </div>
                                        <div class="md-layout" v-if="customFieldList !== null">
                                            <div class="md-layout-item">
                                                <form-group name="time" label="" class="time-picker">
                                                    <time-picker
                                                        @selectedTime="getTime"
                                                        :disabled="!editMode"
                                                        :time="time"
                                                        :time-window-only="true"
                                                        :enable-auto-scroll="true"
                                                        :additional-time-options="additionalTimeOption"
                                                    />
                                                </form-group>
                                            </div>
                                            <div class="md-layout-item">
                                                <form-group name="durationMinutes" label="Duration (mins)">
                                                    <md-input
                                                        type="number"
                                                        :disabled="!editMode"
                                                        v-model="frequentAddress.durationMinutes"
                                                    />
                                                </form-group>
                                            </div>
                                        </div>
                                        <div class="md-layout" v-if="customFieldList !== null">
                                            <div class="md-layout-item">
                                                <label class="fa-header">Stop Custom Fields</label>
                                            </div>
                                        </div>
                                        <div
                                            class="md-layout"
                                            :key="index"
                                            v-for="(customFields, index) in customFieldList"
                                            v-if="customFieldList !== null"
                                        >
                                            <div
                                                class="md-layout-item"
                                                :key="index2"
                                                v-for="(customField, index2) in customFields"
                                            >
                                                <custom-field-inputs
                                                    :custom-field-definition="customField"
                                                    :initial-value="customFieldValues[customField.name]"
                                                    :disabled="!editMode"
                                                    :show-label="true"
                                                    @changed="handleCustomFieldChanged"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-actions">
                                        <md-button
                                            v-if="editMode"
                                            class="md-secondary dialog-button pull-right"
                                            @click="cancelUpdate"
                                        >
                                            Cancel
                                        </md-button>
                                        <md-button
                                            v-if="editMode"
                                            class="md-primary dialog-button pull-right"
                                            @click="actionButtonFrequentAddress"
                                        >
                                            {{ isUpdate ? 'Update' : 'Add' }}
                                        </md-button>
                                    </div>
                                </form-wrapper>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { DATE_TYPES } from '@/utils/constants';
import { CustomerAutocomplete, CustomFieldInputs, TimePicker, GoogleAutocomplete } from '@/components';

export default {
    name: 'FrequentAddressDetails',
    mixins: [GeneralMixin],
    components: { CustomerAutocomplete, CustomFieldInputs, TimePicker, GoogleAutocomplete },
    data() {
        return {
            frequentAddress: {
                customerId: null,
                address: null,
                contactName: null,
                email: null,
                phone: null,
                notes: null,
                timeWindowStart: null,
                timeWindowEnd: null,
                durationInMinutes: 0,
                customFields: null
            },
            customerIdFilterValue: null,
            editMode: false,
            isUpdate: true,
            customer: {
                customerId: 0,
                contact: {}
            },
            autocompleteAddress: {
                location: null,
                address: ''
            },
            savedCustomer: {},
            customFieldList: null,
            customFieldValues: {},
            time: null,
            additionalTimeOption: [],
            readOnlyCustomer: false
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isCustomerAdmin: 'user/isCustomerAdmin'
        })
    },
    async mounted() {
        this.$_handleLoaderState(true);

        if (this.$route.params !== null && this.$route.params.editMode !== undefined) {
            this.editMode = this.$route.params.editMode;
        }

        if (this.$route.params.frequentAddressId === 0) {
            this.editMode = true;
            this.isUpdate = false;
            this.initializeCustomFields();
        } else {
            this.retrieveFrequentAddressDetails();
        }

        this.$_handleLoaderState(false);
    },
    methods: {
        handleCustomerFieldChange() {
            this.frequentAddress.customerId = this.customer.customerId;
        },
        async retrieveFrequentAddressDetails() {
            this.$_handleLoaderState(true);
            const api = `/api/frequent-addresses/${this.$route.params.frequentAddressId}`;
            const payload = {
                method: 'get'
            };
            handleRequests(api, payload).then(
                (response) => {
                    this.frequentAddress = response.data;
                    this.customer.customerId = this.frequentAddress.customerId;
                    if (this.frequentAddress.customFields !== null)
                        this.customFieldValues = JSON.parse(this.frequentAddress.customFields);
                    if (this.frequentAddress.customerId != null && this.frequentAddress.customerId > 0) {
                        this.getCustomerDetails(this.frequentAddress.customerId);
                    }

                    if (this.frequentAddress.timeWindowStart != null && this.frequentAddress.timeWindowEnd != null) {
                        const timeWindowStart = moment(this.frequentAddress.timeWindowStart)
                            .utc()
                            .format(DATE_TYPES.militaryTime);
                        const timeWindowEnd = moment(this.frequentAddress.timeWindowEnd)
                            .utc()
                            .format(DATE_TYPES.militaryTime);
                        const timeWindowStartStandard = moment(this.frequentAddress.timeWindowStart)
                            .utc()
                            .format(DATE_TYPES.standardTime);
                        const timeWindowEndStandard = moment(this.frequentAddress.timeWindowEnd)
                            .utc()
                            .format(DATE_TYPES.standardTime);
                        this.additionalTimeOption.push({
                            name: `${timeWindowStartStandard} - ${timeWindowEndStandard}`,
                            timeWindowStart,
                            timeWindowEnd
                        });
                    }

                    this.autocompleteAddress.address = this.frequentAddress.address;

                    if (this.user !== null || this.user !== undefined) {
                        this.initializeCustomFields();
                    }
                    this.checkIfCustomerAdmin();
                    this.$_handleLoaderState(false);
                },
                (error) => {
                    const message = 'Error in retrieving frequent address details';
                    showErrorMessage(this, message, error);
                    this.$_handleLoaderState(false);
                }
            );
        },
        async addFrequentAddress() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            if (this.customFieldList.length > 0) {
                this.frequentAddress.customFields = JSON.stringify(this.customFieldValues);
            }
            this.$_handleLoaderState(true);
            this.frequentAddress.customerId = this.customer.customerId;
            const api = `/api/frequent-addresses`;
            const payload = {
                method: 'post',
                data: this.frequentAddress
            };
            handleRequests(api, payload).then(
                (response) => {
                    const message = 'Successfully added';
                    this.$notify({
                        message,
                        type: 'success'
                    });
                    this.$router.push({
                        path: `/frequent-addresses`
                    });
                    this.$_handleLoaderState(false);
                },
                (error) => {
                    const message = 'Error in adding frequent address';
                    showErrorMessage(this, message, error);
                    this.$_handleLoaderState(false);
                }
            );
        },
        async updateFrequentAddress() {
            this.$_handleLoaderState(true);
            this.frequentAddress.customerId = this.customer.customerId;
            if (this.customFieldList.length > 0) {
                this.frequentAddress.customFields = JSON.stringify(this.customFieldValues);
            }
            const api = `/api/frequent-addresses/${this.$route.params.frequentAddressId}`;
            const payload = {
                method: 'put',
                data: this.frequentAddress
            };
            handleRequests(api, payload).then(
                (response) => {
                    const message = 'Successfully updated';
                    this.$notify({
                        message,
                        type: 'success'
                    });
                    this.$router.push({
                        path: `/frequent-addresses`
                    });
                    this.$_handleLoaderState(false);
                },
                (error) => {
                    const message = 'Error in adding frequent address';
                    showErrorMessage(this, message, error);
                    this.$_handleLoaderState(false);
                }
            );
        },
        toggleEdit() {
            this.editMode = !this.editMode;
        },
        actionButtonFrequentAddress() {
            if (this.isTimeValid) {
                if (this.$route.params.frequentAddressId > 0) {
                    this.updateFrequentAddress();
                } else {
                    this.addFrequentAddress();
                }
            }
        },
        initializeCustomFields() {
            if (this.user.stopCustomFieldDefinitions !== null || this.user.stopCustomFieldDefinitions !== undefined) {
                const subsets = 2;

                this.customFieldList = this.user.stopCustomFieldDefinitions.reduce((resultArray, item, index) => {
                    const chunkIndex = Math.floor(index / subsets);

                    if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = []; // start a new subset
                    }

                    resultArray[chunkIndex].push(item);

                    return resultArray;
                }, []);
            }
        },
        async getCustomerDetails(customerId) {
            try {
                const api = `/api/customers/${customerId}`;
                const { data } = await handleRequests(api);
                this.savedCustomer = data;
            } catch (error) {
                const message = 'Error in getting the customer details';
                showErrorMessage(this, message, error);
            }
        },
        getTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { timeWindowStart, timeWindowEnd } = selectedTime;
                this.frequentAddress.timeWindowStart = timeWindowStart;
                this.frequentAddress.timeWindowEnd = timeWindowEnd;
            } else {
                this.frequentAddress.timeWindowStart = null;
                this.frequentAddress.timeWindowEnd = null;
            }
            this.isTimeValid = isTimeValid;
        },
        handleCustomFieldChanged({ name, value }) {
            this.customFieldValues[name] = value;
        },
        handleAddressChanged(val) {
            this.frequentAddress.location = this.autocompleteAddress.location;
            this.frequentAddress.address = val;
            this.frequentAddress.addressComponents = this.autocompleteAddress.addressComponents;
        },
        cancelUpdate() {
            this.$router.push({
                path: `/frequent-addresses`
            });
        },
        checkIfCustomerAdmin() {
            if (this.isCustomerAdmin) {
                this.readOnlyCustomer = true;
                this.customer.customerId = this.user.customerId;
            }
        }
    },
    watch: {
        editMode() {
            this.checkIfCustomerAdmin();
        }
    },
    validations: {
        frequentAddress: {
            address: { required },
            contactName: { required }
        }
    }
};
</script>

<style lang="scss" scoped>
.md-field.md-disabled .md-input {
    cursor: auto;
}

.md-disabled::after {
    border-bottom: none !important;
}

.default-tracking-info {
    i {
        font-size: 17px !important;
        margin-top: 5px;
        color: #2b93ff !important;
    }
}

.empty {
    i {
        margin-left: 35px;
        margin-top: 8px;
    }
}

.fa-header {
    font-weight: bold;
}

.time-picker {
    margin-top: -5px;
    margin-bottom: 0px;
}
</style>
