<template>
    <div class="charges-editor-page">
        <div class="md-layout">
            <div class="md-layout-item md-size-100">
                <md-card>
                    <md-card-content>
                        <collapse
                            class="collapse-panel"
                            :collapse="['Filters']"
                            icon="keyboard_arrow_down"
                            color-collapse="success"
                        >
                            <template slot="md-collapse-pane-1">
                                <process-charge-overview-toolbar
                                    @onFilterProcessCharges="handleFilterChanged"
                                    :team-region-id="user.teamRegionId"
                                    :is-cost-approval-feature-enabled="isCostApprovalFeatureEnabled"
                                    :is-charge-approval-feature-enabled="isChargeApprovalFeatureEnabled"
                                    :service-packages="servicePackages"
                                />
                            </template>
                        </collapse>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card class="custom-card">
                    <md-card-header class="md-card-header-icon md-card-header-warning">
                        <div class="card-icon">
                            <md-icon>price_check</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div
                            :class="[{ fixed: isFixedSelector }]"
                            class="bulk-section sticky-selection"
                            v-if="selectedJobs.length > 1"
                        >
                            <div>{{ selectedJobs.length }} job{{ selectedJobs.length > 1 ? 's' : '' }} selected.</div>
                            <div>
                                <md-button
                                    class="md-round md-just-icon md-success"
                                    title="Recalculate jobs"
                                    @click="bulkRecalculate"
                                >
                                    <md-icon>currency_exchange</md-icon>
                                </md-button>
                                <md-button
                                    class="md-round md-just-icon md-success"
                                    title="Approve jobs"
                                    @click="bulkApprove"
                                >
                                    <md-icon>thumb_up</md-icon>
                                </md-button>
                                <md-button
                                    class="md-round md-just-icon md-danger"
                                    title="Unapprove jobs"
                                    @click="bulkUnapprove"
                                >
                                    <md-icon>thumb_down</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <ProcessChargesTable>
                            <template v-slot:table-header>
                                <md-checkbox v-model="isSelectAll" @change="onSelectAll(isSelectAll)"></md-checkbox>
                                <table-header-dropdown
                                    class="reference-column"
                                    column-icon="keyboard_arrow_down"
                                    :default-text="tableColumnOptions.columns[columnKeys.referenceSource].name"
                                    :dropdown-options="tableColumnKeyOptions.referenceSource"
                                    :selected-option="tableColumnOptions.columns[columnKeys.referenceSource]"
                                    @selectedOption="
                                        $_handleColumnSwitch(
                                            $event,
                                            columnKeys.referenceSource,
                                            'stops.defaults.stop-table-options'
                                        )
                                    "
                                />
                                <h5>Trip Date</h5>
                                <h5 class="hide-column">Customer</h5>
                                <h5 class="hide-column">Service Pkg</h5>
                                <h5 class="time-header">Time</h5>
                                <h5 class="hide-column">Attempt #</h5>
                                <h5 class="hide-column">Run #</h5>
                                <h5>Status</h5>
                                <h5>Delivered By</h5>
                                <table-header-dropdown
                                    class="finance-column"
                                    default-text="Total Charge"
                                    :dropdown-options="totalChargeDropdownList"
                                    :selected-option="selectedTotalChargeDropdowns"
                                    @selectedOption="handleSelectTotalChargeFilter"
                                />
                                <table-header-dropdown
                                    class="finance-column"
                                    default-text="Total Cost"
                                    :dropdown-options="totalCostDropdownList"
                                    :selected-option="selectedTotalCostDropdowns"
                                    @selectedOption="handleSelectTotalCostFilter"
                                />
                                <div class="finance-column hide-column">
                                    <h5>Margin</h5>
                                </div>
                                <h5>Actions</h5>
                            </template>
                            <template
                                v-slot:table-content
                                v-if="
                                    jobChargeList !== null &&
                                        jobChargeList !== undefined &&
                                        jobChargeList.length > 0 &&
                                        !isLoading
                                "
                            >
                                <div v-for="(item, index) in jobChargeList" :key="index">
                                    <ActiveRow
                                        :root-job="item"
                                        :selected-jobs="selectedJobs"
                                        :has-expanded-row="item.hasExpandedRow"
                                        :reference-header-value="tableColumnOptions.columns[columnKeys.referenceSource]"
                                        :is-cost-approval-feature-enabled="isCostApprovalFeatureEnabled"
                                        :is-charge-approval-feature-enabled="isChargeApprovalFeatureEnabled"
                                        :service-packages="servicePackages"
                                        @row-click="handleRowClick(index)"
                                        @update-rates="updateRates(item)"
                                        @approve-jobs="approveJobs(item)"
                                        @unapprove-jobs="unapproveJobs(item)"
                                        @update-service-package="updateServicePackage(item)"
                                        @update-time="handleTimeUpdated"
                                    >
                                        <template v-slot:nested-table v-if="item.childrenJobs.length > 0">
                                            <NestedTable
                                                :root-job="item"
                                                :selected-jobs="selectedJobs"
                                                :reference-header-value="
                                                    tableColumnOptions.columns[columnKeys.referenceSource]
                                                "
                                                @update-rates="updateRates"
                                                @select-items="handleNestedItemsSelected"
                                                @deselect-items="handleNestedItemsDeselected"
                                            />
                                        </template>
                                    </ActiveRow>
                                </div>
                            </template>
                            <template v-else v-slot:table-content>
                                <div v-if="isLoading" class="content-loader loading-position">
                                    <fade-loader :loading="isLoading" color="#333333" />
                                    <span>LOADING</span>
                                </div>
                                <p v-else class="no-result-message">
                                    No results matching your search/filter could be found.
                                </p>
                            </template>
                        </ProcessChargesTable>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <md-card-actions class="page-footer" md-alignment="space-between">
            <div>
                <p v-if="total === pagination.perPage" class="card-category">
                    Page {{ pagination.currentPage }} of many
                </p>
                <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>

                <p>Total Jobs: {{ pagination.total }}</p>
            </div>
            <pagination
                v-model="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                @change-page="handleChangePage($event, pagination.perPage)"
            />
        </md-card-actions>

        <div class="progress-container" v-if="showProgressBox">
            <div class="button-close">
                <md-button class="md-default md-just-icon md-round" @click="handleCloseProgressBox">
                    <md-icon>close</md-icon>
                </md-button>
            </div>
            <div class="progress-text-section" v-if="!isProgressComplete">
                <div>
                    <div class="btn-loader route-loader"></div>
                </div>
                <div v-if="totalToRecalculate == 0">Preparing...</div>
                <div v-else>{{ currentProcessed }} of {{ totalToRecalculate }} have been recalculated.</div>
            </div>
            <div v-else class="progress-text-section">
                <div>
                    <md-icon class="green-check">check_circle</md-icon>
                </div>
                <div>
                    Recalculated Jobs.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PAGINATION_DEFAULTS, STOP_TABLE_HEADER_DISPLAY_OPTION } from '@/utils/defaults';
import { Pagination, TableHeaderDropdown, Collapse } from '@/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { FeatureManager } from '@/directives';
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';
import { PROCESS_TOTALS_CONSTANTS, DATE_TYPES, REFERENCE_SOURCE_KEY_OPTIONS, FEATURE_NAMES } from '@/utils/constants';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import moment from 'moment';
import { NestedTable, ProcessChargesTable, ActiveRow, ProcessChargeOverviewToolbar } from './components';

const signalR = require('@aspnet/signalr');

export default {
    name: 'ProcessCharges',
    components: {
        NestedTable,
        ActiveRow,
        ProcessChargesTable,
        Pagination,
        FadeLoader,
        ProcessChargeOverviewToolbar,
        TableHeaderDropdown,
        Collapse
    },
    mixins: [GeneralMixin, FeatureManager],
    data() {
        return {
            isLoading: false,
            isSelectAll: false,
            jobChargeList: [],
            selectedJobs: [],
            pagination: PAGINATION_DEFAULTS,
            fromDateFilterValue: '',
            toDateFilterValue: '',
            expandedRow: null,
            newRow: null,
            loadCapacityList: [],
            selectedRow: null,
            isRecalculating: false,
            shouldCloseDropdown: false,
            teamMembers: [],
            filters: {
                teamRegionId: null,
                customerId: null,
                totalCostFilter: 0,
                totalChargeFilter: 0,
                userId: null,
                status: 'All',
                fromDate: null,
                toDate: null,
                search: null,
                selectedTeamMember: null,
                attempt: null,
                approvalStatus: 'Unapproved',
                runNumber: null
            },
            totalCostDropdownList: PROCESS_TOTALS_CONSTANTS,
            selectedTotalCostDropdowns: PROCESS_TOTALS_CONSTANTS[0],
            totalChargeDropdownList: PROCESS_TOTALS_CONSTANTS,
            selectedTotalChargeDropdowns: PROCESS_TOTALS_CONSTANTS[0],
            showProgressBox: false,
            totalToRecalculate: 0,
            currentProcessed: 0,
            isProgressComplete: false,
            isFixedSelector: false,
            isCostApprovalFeatureEnabled: false,
            isChargeApprovalFeatureEnabled: false,
            headerDropdown: REFERENCE_SOURCE_KEY_OPTIONS,
            ...STOP_TABLE_HEADER_DISPLAY_OPTION(),
            servicePackages: []
        };
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    async created() {
        this.$_applyColumnSwitchDefaults('stops.defaults.stop-table-options', {
            [this.columnKeys.referenceSource]: REFERENCE_SOURCE_KEY_OPTIONS
        });
    },
    async mounted() {
        this.servicePackages = [...(await this.getServicePackages())];

        this.getTeamMembers();

        this.loadCapacityList = this.user.vehicleCapacityUnitsConfiguration || [];

        if (localStorage.getItem('processCharge.search-filter-options') !== null) {
            const localStorageOptions = JSON.parse(localStorage.getItem('processCharge.search-filter-options'));
            if (localStorageOptions) {
                this.filters = localStorageOptions;
            }
        }

        await this.loadJobs();
        this.setupSignalR();

        document.querySelector('.main-panel').addEventListener('scroll', this.handleScroll, true);

        this.isCostApprovalFeatureEnabled = this.$_hasFeature(FEATURE_NAMES.JobsCostApproval);
        this.isChargeApprovalFeatureEnabled = this.$_hasFeature(FEATURE_NAMES.JobsChargeApproval);
    },
    beforeDestroy() {
        document.querySelector('.main-panel').removeEventListener('scroll', this.handleScroll);

        if (this.connection) {
            this.connection.invoke('RemoveFromTeamChannel');
        }
    },
    methods: {
        /**
         * Check if the job matches the identifiers.
         * Running time: O(n) where n is the number of identifiers
         * @param job
         * @param identifiers
         * @returns {*|boolean}
         */
        checkIfJobMatches(job, identifiers) {
            const stopIds = identifiers.filter((i) => i.identifierType === 'stopId').map((i) => i.identifier);
            const shipmentIds = identifiers.filter((i) => i.identifierType === 'shipmentId').map((i) => i.identifier);
            const tripIds = identifiers.filter((i) => i.identifierType === 'tripId').map((i) => i.identifier);
            if (job.stopId) {
                return stopIds.includes(job.stopId);
            }
            if (job.shipmentId) {
                return shipmentIds.includes(job.shipmentId);
            }
            if (job.tripId) {
                return tripIds.includes(job.tripId);
            }
            return false;
        },
        /**
         *
         * @param {{ identifierType: "stopId" | "shipmentId" | "tripId", identifier: number }[]} items
         */
        handleNestedItemsDeselected(items) {
            const jobsFlattened = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const job of this.jobChargeList) {
                jobsFlattened.push(job);
                jobsFlattened.push(...job.childrenJobs);
            }
            const jobsToDeselect = jobsFlattened.filter((job) => this.checkIfJobMatches(job, items));
            this.selectedJobs = this.selectedJobs.filter((job) => !jobsToDeselect.includes(job));
        },
        /**
         *
         * @param {{ identifierType: "stopId" | "shipmentId" | "tripId", identifier: number }[]} items
         */
        handleNestedItemsSelected(items) {
            // since there are childrenJobs, we need to flatten the list
            const jobsFlattened = [];

            // eslint-disable-next-line no-restricted-syntax
            for (const job of this.jobChargeList) {
                jobsFlattened.push(job);
                jobsFlattened.push(...job.childrenJobs);
            }
            // if an item in the list is already selected, we should remove it
            const newlySelectedJobs = jobsFlattened.filter((job) => this.checkIfJobMatches(job, items));
            // 1. get the intersection of selectedJobs and this.selectedJobs
            // 2. remove the intersection from this.selectedJobs and selectedJobs
            // 3. add the remaining selectedJobs to this.selectedJobs
            const intersection = this.selectedJobs.filter((job) => newlySelectedJobs.includes(job));
            const remainingSelectedJobs = newlySelectedJobs.filter((job) => !intersection.includes(job));
            this.selectedJobs = [...this.selectedJobs, ...remainingSelectedJobs];
        },
        handleTimeUpdated(payload) {
            const {
                tripId,
                newTimes: { actualStartTime, actualEndTime },
                shipmentId
            } = payload;

            const trip = this.jobChargeList.find((t) => t.tripId === tripId);
            const shipment = this.jobChargeList.find((s) => s.shipmentId === shipmentId);

            if (trip) {
                trip.actualStartTime = actualStartTime;
                trip.actualEndTime = actualEndTime;
            }

            if (shipment) {
                shipment.actualStartTime = actualStartTime;
                shipment.actualEndTime = actualEndTime;
            }
        },
        getLoadValue(itemLoad, type) {
            if (itemLoad === undefined) 
                return 0;

            const load = JSON.parse(itemLoad);
            return load[type] ?? 0;
        },
        async fetchAllCompletedJobs(pageNumber = 1, itemsPerPage = 50) {
            this.isLoading = true;
            const endpoint = `/api/process-charges/list`;
            const response = await handleRequests(endpoint, {
                params: {
                    pageNumber,
                    itemsPerPage,
                    ...this.filters
                }
            });
            this.isLoading = false;
            return response;
        },
        async loadJobs(pageNumber = 1, perPage = 50) {
            await this.handleFetchAllJobs(pageNumber, perPage);
        },
        async handleFetchAllJobs(pageNumber = 1, perPage = 50) {
            if (this.isLoading) {
                return;
            }
            const {
                data: { jobs, totalJobs }
            } = await this.fetchAllCompletedJobs(pageNumber, perPage);

            this.selectedRow = null;
            this.expandedRow = null;

            this.assignJobcharges(jobs, totalJobs);
            // reset selected jobs
            this.selectedJobs = [];
        },
        assignJobcharges(jobs, totalJobs) {
            this.jobChargeList = jobs;
            this.pagination.total = totalJobs;
        },
        onSelectAll(value) {
            if (this.expandedRow) {
                this.removeOpenedRow();
            }

            this.selectedJobs = [];

            if (value) {
                this.jobChargeList.forEach((job) => {
                    this.selectedJobs.push(job);
                });
            } else {
                this.selectedJobs = [];
                this.shouldCloseDropdown = true;
            }
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            if (this.isLoading) {
                return;
            }

            this.$_handleLoaderState(true);
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;

            const {
                data: { jobs, totalJobs }
            } = await this.fetchAllCompletedJobs(currentPage, perPage);

            this.assignJobcharges(jobs, totalJobs);

            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            this.$_handleLoaderState(false);
        },
        getMaxPage(page) {
            this.maxPage = page;
        },
        removeOpenedRow() {
            if (this.selectedRow && this.expandedRow) {
                this.jobChargeList[this.jobChargeList.indexOf(this.selectedRow)].hasExpandedRow = false;
            }

            this.selectedJobs = [];
            this.shouldCloseDropdown = true;
            this.expandedRow = null;
        },
        getTime(item) {
            const timesArr = [];
            if (item.tripId || item.shipmentId) {
                const times = {
                    startTime: null,
                    endTime: null,
                    actualStartTime: null,
                    actualEndTime: null
                };
                if (item.startTime) {
                    times.startTime = moment(item.startTime).format(DATE_TYPES.standardTime);
                }
                if (item.actualStartTime) {
                    times.actualStartTime = moment(item.actualStartTime).format(DATE_TYPES.standardTime);
                }
                if (item.endTime) {
                    times.endTime = moment(item.endTime).format(DATE_TYPES.standardTime);
                }
                if (item.actualEndTime) {
                    times.actualEndTime = moment(item.actualEndTime).format(DATE_TYPES.standardTime);
                }
                let startTimeString = '';
                if (times.startTime && times.actualStartTime) {
                    startTimeString = `${times.startTime} (${times.actualStartTime})`;
                } else {
                    startTimeString = times.startTime || times.actualStartTime;
                }

                let endTimeString = '';
                if (times.endTime && times.actualEndTime) {
                    endTimeString = `${times.endTime} (${times.actualEndTime})`;
                } else {
                    endTimeString = times.endTime || times.actualEndTime;
                }

                timesArr.push(`${startTimeString} -> ${endTimeString}`);
            } else {
                // for stops, only the endTime is present
                // do the same thing for endTime and actualEndTime
                const times = {
                    endTime: null,
                    actualEndTime: null
                };
                if (item.endTime) {
                    times.endTime = moment(item.endTime).format(DATE_TYPES.standardTime);
                }
                if (item.actualEndTime) {
                    times.actualEndTime = moment(item.actualEndTime).format(DATE_TYPES.standardTime);
                }
                let endTimeString = '';
                if (times.endTime && times.actualEndTime) {
                    endTimeString = `${times.endTime} (${times.actualEndTime})`;
                } else {
                    endTimeString = times.endTime || times.actualEndTime;
                }
                timesArr.push(endTimeString);
            }
            return timesArr.join('');
        },
        handleRowClick(index) {
            const updatedIndex = index;

            // only one row can be expanded at a time
            if (this.selectedRow && this.expandedRow) {
                const selectedIndex = this.jobChargeList.indexOf(this.selectedRow);
                this.jobChargeList[this.jobChargeList.indexOf(this.selectedRow)].hasExpandedRow = false;

                this.selectedJobs.splice(this.selectedRow);

                if (selectedIndex === index) {
                    this.expandedRow = null;
                    return;
                }
            }

            this.jobChargeList[updatedIndex].hasExpandedRow = true;
            const row = this.jobChargeList[updatedIndex];

            const objCopy = Object.assign({}, row);
            objCopy.isExpanded = true;
            objCopy.hasExpandedRow = false;

            this.selectedRow = this.jobChargeList[updatedIndex];
            this.expandedRow = objCopy;

            this.selectedJobs.push(this.selectedRow);
        },
        async bulkRecalculate() {
            if (!this.isRecalculating) {
                await this.updateRates();
            }
        },
        async updateRates(item) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Recalculate',
                    body: item
                        ? `Are you sure you want to recalculate ${item.ref}?`
                        : `Are you sure you want to recalculated all job/s?`,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isRecalculating = true;
                        this.showProgressBox = true;
                        this.totalToRecalculate = 0;
                        this.currentProcessed = 0;
                        this.isProgressComplete = false;

                        const stopIds = [];
                        const shipmentIds = [];
                        const tripIds = [];
                        let createBatch = false;

                        if (item) {
                            if (item.stopId) {
                                if (item.rateGroupId) {
                                    stopIds.push(item.stopId);
                                    item.isRecalculating = true;
                                    item.hasExpandedRow = false;
                                }
                            }

                            if (item.shipmentId) {
                                if (item.rateGroupId) {
                                    shipmentIds.push(item.shipmentId);
                                    item.isRecalculating = true;
                                    item.hasExpandedRow = false;
                                }
                            }
                            if (item.tripId) {
                                if (item.rateGroupId) {
                                    tripIds.push(item.tripId);
                                    item.isRecalculating = true;
                                    item.hasExpandedRow = false;
                                }
                            }
                        } else {
                            this.selectedJobs.forEach((item) => {
                                if (item.stopId) {
                                    if (item.rateGroupId) {
                                        stopIds.push(item.stopId);
                                        item.isRecalculating = true;
                                        item.hasExpandedRow = false;
                                        createBatch = true;
                                    }
                                }

                                if (item.shipmentId) {
                                    if (item.rateGroupId) {
                                        shipmentIds.push(item.shipmentId);
                                        item.isRecalculating = true;
                                        item.hasExpandedRow = false;
                                        createBatch = true;
                                    }
                                }
                                if (item.tripId) {
                                    if (item.rateGroupId) {
                                        tripIds.push(item.tripId);
                                        item.isRecalculating = true;
                                        item.hasExpandedRow = false;
                                        createBatch = true;
                                    }
                                }
                            });
                        }

                        this.removeOpenedRow();

                        this.isSelectAll = false;
                        const { teamId } = this.user;

                        const toUpdate = {
                            stopIds,
                            shipmentIds,
                            tripIds,
                            teamId
                        };

                        const payload = {
                            method: 'post',
                            data: toUpdate
                        };

                        if (stopIds.length <= 0 && shipmentIds.length <= 0 && tripIds.length <= 0) {
                            this.isRecalculating = false;
                            this.isProgressComplete = true;
                            return;
                        }

                        const api = createBatch ? `/api/rates/recalculate-rates-Batch` : `/api/rates/recalculate-rates`;
                        try {
                            await handleRequests(api, payload);
                        } catch (e) {
                            const message = `Cannot recalculate, please contact support if issue persists`;
                            showErrorMessage(this, message, e);
                        }
                    }

                    this.isRecalculating = false;
                });
        },
        async bulkApprove() {
            await this.approveJobs();
        },
        async approveJobs(item) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Approve',
                    body: item
                        ? `Are you sure you want to approve ${item.ref}?`
                        : `Are you sure you want to approve all job/s?`,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response !== 'Confirm') {
                        return;
                    }

                    const approvalPayload = this.getApprovalPayload(item);
                    if (
                        approvalPayload.stopIds.length <= 0 &&
                        approvalPayload.shipmentIds.length <= 0 &&
                        approvalPayload.tripIds.length <= 0
                    ) {
                        return;
                    }

                    const payload = {
                        method: 'post',
                        data: approvalPayload
                    };

                    this.$_handleLoaderState(true, 'APPROVING...');

                    const api = `/api/process-charges/approve-jobs`;
                    try {
                        await handleRequests(api, payload);

                        this.$notifySuccess(item ? `Job ${item.ref} approved.` : 'Jobs approved.');
                        this.$_handleLoaderState(false);

                        this.selectedJobs = [];
                    } catch (e) {
                        const message = `Cannot approve, please contact support if issue persists`;
                        showErrorMessage(this, message, e);
                        this.$_handleLoaderState(false);
                    }

                    this.removeOpenedRow();
                    this.isSelectAll = false;
                });
        },
        async bulkUnapprove() {
            await this.unapproveJobs();
        },
        async unapproveJobs(item) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Unapprove',
                    body: item
                        ? `Are you sure you want to unapprove ${item.ref}?`
                        : `Are you sure you want to unapprove all job/s?`,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response !== 'Confirm') {
                        return;
                    }

                    const approvalPayload = this.getApprovalPayload(item);
                    if (
                        approvalPayload.stopIds.length <= 0 &&
                        approvalPayload.shipmentIds.length <= 0 &&
                        approvalPayload.tripIds.length <= 0
                    ) {
                        return;
                    }

                    const payload = {
                        method: 'post',
                        data: approvalPayload
                    };

                    this.$_handleLoaderState(true, 'UNAPPROVING...');

                    const api = `/api/process-charges/unapprove-jobs`;
                    try {
                        await handleRequests(api, payload);

                        this.$notifySuccess(item ? `Job ${item.ref} unapproved.` : 'Jobs unapproved.');
                        this.$_handleLoaderState(false);

                        this.selectedJobs = [];
                    } catch (e) {
                        const message = `Cannot unapprove, please contact support if issue persists`;
                        showErrorMessage(this, message, e);
                        this.$_handleLoaderState(false);
                    }

                    this.removeOpenedRow();

                    this.isSelectAll = false;
                });
        },
        getApprovalPayload(item) {
            const stopIds = [];
            const shipmentIds = [];
            const tripIds = [];

            if (item) {
                item.hasExpandedRow = false;

                if (item.stopId) {
                    stopIds.push(item.stopId);
                }
                if (item.shipmentId) {
                    shipmentIds.push(item.shipmentId);
                }
                if (item.tripId) {
                    tripIds.push(item.tripId);
                }
            } else {
                this.selectedJobs.forEach((item) => {
                    item.hasExpandedRow = false;

                    if (item.stopId) {
                        stopIds.push(item.stopId);
                    }
                    if (item.shipmentId) {
                        shipmentIds.push(item.shipmentId);
                    }
                    if (item.tripId) {
                        tripIds.push(item.tripId);
                    }
                });
            }

            return {
                stopIds,
                shipmentIds,
                tripIds
            };
        },
        showWarning(item) {
            // show warning if location is (0,0) or null
            // skip if item is a trip
            if (item.tripId) {
                return false;
            }
            if (
                !item.location ||
                !item.location.latitude ||
                !item.location.longitude ||
                item.location.longitude === '0' ||
                item.location.latitude === '0'
            ) {
                return true;
            }

            return false;
        },
        async getTeamMembers() {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS', {
                teamRegionId: this.filters.teamRegionId
            });
        },
        handleSelectTotalCostFilter(item) {
            this.selectedTotalCostDropdowns = item;
            const updateFilters = { ...this.filters, totalCostFilter: item.key };
            this.filters = Object.assign(this.filters, updateFilters);
            this.loadJobs(1);
        },
        handleSelectTotalChargeFilter(item) {
            this.selectedTotalChargeDropdowns = item;
            const updateFilters = { ...this.filters, totalChargeFilter: item.key };
            this.filters = Object.assign(this.filters, updateFilters);
            this.loadJobs(1);
        },
        async handleFilterChanged(data) {
            this.filters = Object.assign(this.filters, data);
            this.filters.totalCostFilter = this.selectedTotalCostDropdowns.key;
            this.filters.totalChargeFilter = this.selectedTotalChargeDropdowns.key;

            this.loadJobs(1);
        },
        async setupSignalR() {
            const userCrendentials = await window.auth.getUser();

            this.connection = new signalR.HubConnectionBuilder()
                .withUrl('/api/RatesEngineUpdateHub', {
                    accessTokenFactory: () => {
                        return userCrendentials.access_token;
                    }
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            try {
                await this.connection.start().then((result) => {
                    this.connection.invoke('JoinToTeamChannel');
                });

                this.connection.onclose(async () => {
                    await this.setupSignalR();
                });

                this.connection.on('ProcessRecalculationCompleted', async (totalSuccess, failedRecords) => {
                    this.handleRecalculationCompleted(totalSuccess, failedRecords);
                });

                this.connection.on('ProcessRecalculationShipmentComplete', async (shipmentId) => {
                    this.handleShipmentRecalculated(shipmentId);
                });

                this.connection.on('ProcessRecalculationTripComplete', async (tripId) => {
                    this.handleTripRecalculation(tripId);
                });

                this.connection.on('ProcessRecalculationStopComplete', async (stopId) => {
                    this.handleStopRecalculated(stopId);
                });

                this.connection.on('ProcessRecalculationTotal', async (totalsToBeCalculated) => {
                    this.handleTotalToBeRecalculated(totalsToBeCalculated);
                });

                this.connection.on('ProcessRecalculationFailed', this.handleRecalculationFailed);

                this.connection.on('ShipmentRatesUpdated', async (shipmentId, updateCost, updateCharges) => {
                    this.handleShipmentRatesUpdated(shipmentId, updateCost, updateCharges);
                });

                this.connection.on('StopRatesUpdated', async (stopId, updateCost, updateCharges) => {
                    this.handleStopRatesUpdated(stopId, updateCost, updateCharges);
                });

                this.connection.on('StopApprovalStatusUpdated', async (stopId, approved, unapproved) => {
                    this.onStopApprovalStatusUpdated(stopId, approved, unapproved);
                });

                this.connection.on('ShipmentApprovalStatusUpdated', async (shipmentId, approved, unapproved) => {
                    this.onShipmentApprovalStatusUpdated(shipmentId, approved, unapproved);
                });

                this.connection.on('TripApprovalStatusUpdated', async (tripId, approved, unapproved) => {
                    this.onTripApprovalStatusUpdated(tripId, approved, unapproved);
                });
            } catch (err) {
                setTimeout(this.setupSignalR, 5000);
            }
        },
        handleRecalculationCompleted(totalSuccess, failedRecords) {
            if (Number(failedRecords) <= 0) {
                this.isProgressComplete = true;
            } else {
                this.isProgressComplete = true;
                this.showProgressBox = false;

                this.$notify({
                    message: `Completed: ${totalSuccess} Failed  ${failedRecords}.<br /><br /> Partially recalculated, please try again. If error persists please contact support.`,
                    type: 'warning',
                    duration: 10000
                });
            }
        },
        async handleShipmentRatesUpdated(shipmentId, updateCosts, updateCharges) {
            const shipment = this.jobChargeList.find((s) => Number(shipmentId) === s.shipmentId);

            if (shipment) {
                await this.fetchShipment(shipmentId, updateCosts, updateCharges);
            }
        },
        async handleStopRatesUpdated(stopId, updateCosts, updateCharges) {
            const stop = this.jobChargeList.find((s) => Number(stopId) === s.stopId);

            if (stop) {
                await this.fetchStop(stopId, updateCosts, updateCharges);
            }
        },
        handleShipmentRecalculated(shipmentId, updateCosts, updateCharges) {
            const shipment = this.jobChargeList.find((s) => Number(shipmentId) === s.shipmentId);

            if (shipment) {
                this.fetchShipment(shipmentId, updateCosts, updateCharges);
            }

            this.currentProcessed = this.currentProcessed + 1;
        },
        handleStopRecalculated(stopId, updateCosts, updateCharges) {
            // flatten the list first
            const jobs = [];

            // eslint-disable-next-line no-restricted-syntax
            for (const job of this.jobChargeList) {
                jobs.push(job);
                if (job.childrenJobs.length > 0) {
                    jobs.push(...job.childrenJobs);
                }
            }
            const stop = jobs.find((s) => Number(stopId) === s.stopId);

            if (stop) {
                this.fetchStop(stopId, updateCosts, updateCharges);
            }

            this.currentProcessed = this.currentProcessed + 1;
        },
        handleTripRecalculation(tripId) {
            const trip = this.jobChargeList.find((s) => Number(tripId) === s.tripId);

            if (trip) {
                this.fetchTrip(tripId);
            }

            this.currentProcessed = this.currentProcessed + 1;
        },
        handleTotalToBeRecalculated(total) {
            this.totalToRecalculate = Number(total);
        },
        handleCloseProgressBox() {
            this.showProgressBox = false;
        },
        async fetchStop(stopId, updateCosts, updateCharges) {
            const endpoint = `/api/process-charges/stop/${stopId}`;
            const response = await handleRequests(endpoint);
            const stop = response.data;

            this.removeOpenedRow();

            // update the stop's isRecalculating if it's still true
            // use DFS to find the stop
            for (let i = 0; i < this.jobChargeList.length; i++) {
                if (this.jobChargeList[i].stopId === stopId) {
                    this.jobChargeList.splice(i, 1, stop);
                    break;
                }
                if (this.jobChargeList[i].childrenJobs.length > 0) {
                    for (let j = 0; j < this.jobChargeList[i].childrenJobs.length; j++) {
                        if (this.jobChargeList[i].childrenJobs[j].stopId === stopId) {
                            const { childrenJobs } = this.jobChargeList[i];
                            childrenJobs.splice(j, 1, stop);
                            this.jobChargeList.splice(i, 1, { ...this.jobChargeList[i], childrenJobs });
                            break;
                        }
                    }
                }
            }
            this.toggleHighlight(stop, updateCosts, updateCharges);
        },
        async fetchShipment(shipmentId, updateCosts, updateCharges) {
            const endpoint = `/api/process-charges/shipment/${shipmentId}`;
            const response = await handleRequests(endpoint);
            const shipment = response.data;

            this.removeOpenedRow();
            // update the shipment's isRecalculating if it's still true
            const shipmentIndex = this.jobChargeList.findIndex((s) => shipment.shipmentId === s.shipmentId);
            if (shipmentIndex > -1) {
                this.jobChargeList[shipmentIndex].isRecalculating = false;
            }

            this.jobChargeList.splice(shipmentIndex, 1, shipment);
            this.toggleHighlight(shipment, updateCosts, updateCharges);
        },
        async fetchTrip(tripId) {
            const endpoint = `/api/process-charges/trip/${tripId}`;
            const response = await handleRequests(endpoint);
            const trip = response.data;

            let selectedRowIsUpdatedRow = false;
            if (this.selectedRow && this.selectedRow.tripId === trip.tripId) 
                selectedRowIsUpdatedRow = true;
            else 
                this.removeOpenedRow();

            const tripIndex = this.jobChargeList.findIndex((t) => trip.tripId === t.tripId);
            if (tripIndex > -1) {
                this.jobChargeList[tripIndex].isRecalculating = false;
            }

            trip.hasExpandedRow = selectedRowIsUpdatedRow;
            this.selectedRow = trip;
            this.expandedRow = trip;

            this.jobChargeList.splice(tripIndex, 1, trip);
            this.toggleHighlight(trip, true, true);
        },
        handleRecalculationFailed() {
            this.showProgressBox = false;
            showErrorMessage(this, 'Failed to recalculate jobs, if error persists please contact support.', null);
        },
        handleScroll() {
            const target = document.querySelector('.md-card-header');
            const scrollPos = target.getBoundingClientRect().top;

            if (scrollPos <= 80) {
                this.isFixedSelector = true;
                return;
            }

            this.isFixedSelector = false;
        },
        toggleHighlight(job, updateCosts, updateCharges) {
            if (updateCosts) {
                job.hasUpdatedCosts = Boolean(updateCosts);
                setTimeout(() => {
                    job.hasUpdatedCosts = false;
                }, 1000);
            }

            if (updateCharges) {
                job.hasUpdatedCharges = Boolean(updateCharges);
                setTimeout(() => {
                    job.hasUpdatedCharges = false;
                }, 1000);
            }
        },
        async onStopApprovalStatusUpdated(stopId, approved, unapproved) {
            const stopIndex = this.jobChargeList.findIndex((j) => j.stopId === Number(stopId));

            if (this.filters.approvalStatus === 'Unapproved' && approved) {
                this.jobChargeList.splice(stopIndex, 1);
            } else if (this.filters.approvalStatus === 'Approved' && unapproved) {
                this.jobChargeList.splice(stopIndex, 1);
            } else {
                await this.fetchStop(Number(stopId));
            }
        },
        async onShipmentApprovalStatusUpdated(shipmentId, approved, unapproved) {
            const shipmentIndex = this.jobChargeList.findIndex((j) => j.shipmentId === Number(shipmentId));

            if (this.filters.approvalStatus === 'Unapproved' && approved) {
                this.jobChargeList.splice(shipmentIndex, 1);
            } else if (this.filters.approvalStatus === 'Approved' && unapproved) {
                this.jobChargeList.splice(shipmentIndex, 1);
            } else {
                await this.fetchShipment(Number(shipmentId));
            }
        },
        async onTripApprovalStatusUpdated(tripId, approved, unapproved) {
            const tripIndex = this.jobChargeList.findIndex((j) => j.tripId === Number(tripId));

            if (this.filters.approvalStatus === 'Unapproved' && approved) {
                this.jobChargeList.splice(tripIndex, 1);
            } else if (this.filters.approvalStatus === 'Approved' && unapproved) {
                this.jobChargeList.splice(tripIndex, 1);
            } else {
                await this.fetchTrip(Number(tripId));
            }
        },
        async getServicePackages() {
            try {
                const endpoint = `/api/rate-groups/rateRules`;
                const response = await handleRequests(endpoint);

                if (response !== null && response.data !== null) {
                    return response.data.map((rg) => {
                        return {
                            rateGroupId: rg.rateGroupId,
                            rateGroupName: rg.rateGroupName,
                            rateGroupType: rg.ruleType
                        };
                    });
                }

                return null;
            } catch (e) {
                const message = `Unable to fetch service packages.`;
                showErrorMessage(this, message, e);
            }

            return null;
        },
        async updateServicePackage(item) {
            const data = this.setIsRecalculating(item, true);

            const payload = {
                method: 'patch',
                data
            };

            const endpoint = `/api/process-charges/update-service`;
            try {
                await handleRequests(endpoint, payload);
            } catch (e) {
                const message = `Cannot update service packages.`;
                showErrorMessage(this, message, e);
            }
        },
        setIsRecalculating(item, value) {
            const stopIds = [];
            const shipmentIds = [];
            const tripIds = [];
            item.isRecalculating = value;

            if (item.stopId) {
                stopIds.push(item.stopId);
            }

            if (item.shipmentId) {
                shipmentIds.push(item.shipmentId);
            }

            if (item.tripId) {
                tripIds.push(item.tripId);
            }

            const toUpdate = {
                rateGroupId: item.rateGroupId ?? null,
                stopIds,
                shipmentIds,
                tripIds
            };

            this.isRecalculating = true;
            this.showProgressBox = true;
            this.totalToRecalculate = 0;
            this.currentProcessed = 0;
            this.isProgressComplete = false;

            return toUpdate;
        }
    }
};
</script>
<style lang="scss" scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

.hide-container {
    display: none;
}

@media (min-width: 1400px) {
    .hide-container {
        display: table-cell;
        line-height: 30px;

        ::v-deep .md-table-cell-container {
            max-width: 300px;
        }
    }
}

.custom-button-container .custom-a-blue a {
    color: rgba(0, 0, 0, 0.87);
}

.custom-card {
    z-index: initial;
}

.custom-tab-list {
    position: initial;
}

.status-filter {
    width: 250px !important;
    padding-right: 15px;
}

.more_button {
    position: absolute;
}

.selected-row {
    background-color: aliceblue !important;
}

.margin-icon {
    color: red !important;
}

.warming-icon {
    color: orange !important;
}

.warning-font {
    color: red !important;
}

.profile-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
    object-fit: contain;
    background-color: #eee;
}

.tooltip-width {
    max-width: 500px;
}

.address-text-tooltip {
    text-align: left;
}

.address-separator {
    float: left;
    margin-right: 10px;
    font-weight: bold;
}

.address-text {
    white-space: nowrap;
    width: 46%;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}

.stop-address-text {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}

.body-list {
    position: relative;
}

.bulk-section {
    position: absolute;
    top: -35px;
    width: 545px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #cfeefa;
    color: #2b93ff;
    font-weight: 400;
    padding: 5px 10px 5px 20px;

    > div {
        width: 60%;
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }

    > div:last-child {
        width: 40%;
        text-align: right;

        button {
            height: 30px;
            width: 30px;
            min-width: 30px;
        }

        .dropdown {
            width: 120px;
            background-color: #ff9800 !important;
            display: inline-block;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
            height: 30px;
            line-height: 30px;
            margin-top: 6px;
            cursor: pointer;
        }
    }
}

.progress-container {
    min-width: 200px;
    max-width: 250px;
    background-color: #fff;
    position: fixed;
    right: 12px;
    bottom: 8px;
    z-index: 99;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    padding: 0 10px 10px 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    .progress-text-section {
        display: table;
        padding-top: 12px;

        > div {
            display: table-cell;
            vertical-align: middle;
        }

        > div:last-child {
            padding-left: 10px;
        }
    }

    ::v-deep .md-button {
        height: 20px;
        min-width: 20px;
        width: 20px;
        position: absolute;
        top: -12px;
        right: -12px;

        i {
            font-size: 15px !important;
        }
    }
}

.green-check {
    color: #4caf50 !important;
}

.btn-loader {
    border: 4px solid #eeeeee;
    border-radius: 50%;
    border-top: 4px solid #2b93ff;
    width: 24px;
    height: 24px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    top: 85%;
    left: 48%;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.route-loader {
    top: 20% !important;
    left: 0 !important;
}

.route-loader-text {
    left: 37px;
    position: absolute;
    top: 13px;
}

.sync-loader {
    display: inline-block;
    background-color: #2b93ff;
    border-radius: 50%;
    width: 27px;
    height: 27px;

    svg {
        margin-top: 3px;
        margin-left: 2px;
    }
}

.has-invoice,
.is-approved {
    display: inline-block !important;
    border-radius: 50% !important;
    width: 25px;
    height: 25px;
}

.center-data {
    display: flex !important;
    align-items: center !important;
}

.sticky-selection {
    z-index: 1000;
    transition: top 0.1s ease;
}

.sticky-selection.fixed {
    position: sticky !important;
    top: 70px !important;
}

.cost-updated {
    display: flex !important;
    align-items: center !important;
    color: #4caf50 !important;
    font-weight: bold;
}

.finance-column {
    justify-self: end;
}

.reference-column {
    padding-left: 0px;
    width: 170px;
}

h5 {
    margin: 0;
}

.loading-position {
    left: 50%;
    top: 35%;
}

.time-header {
    min-width: 100px;
}

.collapse-panel {
    ::v-deep i.md-icon.md-icon-font.md-theme-default {
        float: left !important;
        position: initial !important;
        margin-top: -2px;
    }

    ::v-deep i.md-icon.md-icon-font.md-icon-image.md-date-icon.md-theme-default {
        margin-top: 6px !important;
    }
}
</style>
