var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-time-picker-container" }, [
    _c(
      "div",
      {
        staticClass: "time-picker-container",
        class: !_vm.isTimeValid ? "error-box" : "",
        attrs: { "data-testid": "time" },
      },
      [
        _c(
          "div",
          [
            _c("md-input", {
              attrs: { name: "time", type: "hidden" },
              model: {
                value: _vm.selectedTime,
                callback: function ($$v) {
                  _vm.selectedTime = $$v
                },
                expression: "selectedTime",
              },
            }),
            !_vm.timeWindowOnly
              ? _c("vue-select", {
                  attrs: {
                    "append-to-body": "",
                    "calculate-position": _vm.withPopper,
                    taggable: "",
                    disabled: !_vm.timeOptions.length || _vm.disabled,
                    options: _vm.timeOptions,
                    searchable: _vm.$root.isDesktop,
                  },
                  on: { input: _vm.handleChange },
                  model: {
                    value: _vm.selectedTime,
                    callback: function ($$v) {
                      _vm.selectedTime = $$v
                    },
                    expression: "selectedTime",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        !_vm.isTimeValid
          ? _c("div", { staticClass: "error-note" }, [
              _c("span", [_vm._v("Invalid time format")]),
            ])
          : _vm._e(),
      ]
    ),
    _vm.showTimeWindowOption || _vm.timeWindowOnly
      ? _c(
          "div",
          {
            staticClass: "time-picker-container",
            class: !_vm.isStartTimeValid ? "error-box" : "",
            attrs: { "data-testid": "start-time" },
          },
          [
            _c(
              "div",
              { class: { "hide-cross": !_vm.timeWindowOnly } },
              [
                _c("span", { staticClass: "custom-label" }, [
                  _vm._v("Start Time"),
                ]),
                _c("md-input", {
                  attrs: { type: "hidden" },
                  model: {
                    value: _vm.selectedStartTime,
                    callback: function ($$v) {
                      _vm.selectedStartTime = $$v
                    },
                    expression: "selectedStartTime",
                  },
                }),
                _c("vue-select", {
                  attrs: {
                    "append-to-body": "",
                    "calculate-position": _vm.withPopper,
                    taggable: "",
                    disabled: !_vm.startEndTimeOptions.length || _vm.disabled,
                    options: _vm.startEndTimeOptions,
                    searchable: _vm.$root.isDesktop,
                  },
                  model: {
                    value: _vm.selectedStartTime,
                    callback: function ($$v) {
                      _vm.selectedStartTime = $$v
                    },
                    expression: "selectedStartTime",
                  },
                }),
              ],
              1
            ),
            !_vm.isStartTimeValid
              ? _c("div", { staticClass: "error-note" }, [
                  _c("span", [_vm._v(_vm._s(_vm.invalidText))]),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.showTimeWindowOption || _vm.timeWindowOnly
      ? _c(
          "div",
          {
            staticClass: "time-picker-container",
            class: !_vm.isEndTimeValid ? "error-box" : "",
            attrs: { "data-testid": "end-time" },
          },
          [
            _c(
              "div",
              { ref: "endTime", staticClass: "hide-cross" },
              [
                _c("span", { staticClass: "custom-label" }, [
                  _vm._v("End Time"),
                ]),
                _c("md-input", {
                  attrs: { type: "hidden" },
                  model: {
                    value: _vm.selectedEndTime,
                    callback: function ($$v) {
                      _vm.selectedEndTime = $$v
                    },
                    expression: "selectedEndTime",
                  },
                }),
                _c("vue-select", {
                  attrs: {
                    "append-to-body": "",
                    "calculate-position": _vm.withPopper,
                    taggable: "",
                    disabled: !_vm.startEndTimeOptions.length || _vm.disabled,
                    options: _vm.startEndTimeOptions,
                    searchable: _vm.$root.isDesktop,
                  },
                  model: {
                    value: _vm.selectedEndTime,
                    callback: function ($$v) {
                      _vm.selectedEndTime = $$v
                    },
                    expression: "selectedEndTime",
                  },
                }),
              ],
              1
            ),
            !_vm.isEndTimeValid
              ? _c("div", { staticClass: "error-note" }, [
                  _c("span", [_vm._v(_vm._s(_vm.invalidText))]),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }