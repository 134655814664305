var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "team-region-member-controls-container" }, [
    _vm.showTeamRegion && _vm.teamRegions && _vm.teamRegions.length > 0
      ? _c(
          "div",
          [
            _c(
              "form-group",
              { attrs: { name: "teamRegion", label: "Team Region" } },
              [
                _c("team-region-options", {
                  attrs: {
                    selected: _vm.selectedValues.teamRegionId,
                    mode: _vm.mode,
                  },
                  on: { selectedOption: _vm.handleTeamRegionChanged },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    !_vm.isSingleUser && _vm.showTeamMembers
      ? _c(
          "div",
          [
            _c(
              "form-group",
              {
                staticClass: "team-member",
                attrs: { name: "member", label: "Team Member" },
              },
              [
                _c("vue-select", {
                  attrs: {
                    "data-testid": "member-carrier-id",
                    reduce: function (item) {
                      return item.memberOrCarrierId
                    },
                    label: "fullName",
                    options: _vm.filteredTeamMemberOptions,
                    searchable: _vm.$root.isDesktop,
                    selectable: function (option) {
                      return option.selectable
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "option",
                        fn: function (option) {
                          return [
                            option.selectable
                              ? _c("span", [_vm._v(_vm._s(option.fullName))])
                              : _c("hr"),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2197038194
                  ),
                  model: {
                    value: _vm.selectedValues.memberOrCarrierId,
                    callback: function ($$v) {
                      _vm.$set(_vm.selectedValues, "memberOrCarrierId", $$v)
                    },
                    expression: "selectedValues.memberOrCarrierId",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }