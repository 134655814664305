<template>
    <div v-if="canCloneJobs" class="clone-button">
        <md-button
            :class="useIcon ? 'md-just-icon md-round md-success' : 'md-success'"
            title="Duplicate"
            @click.stop="cloneJob()"
        >
            <md-icon v-if="useIcon">file_copy</md-icon>
            <span v-else>Duplicate</span>
        </md-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import marked from 'marked';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins';
// eslint-disable-next-line import/no-cycle
import { CloneJobEditModal } from '@/components';

export default {
    name: 'CloneJob',
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            canCloneJobs: 'user/canCloneJobs'
        })
    },
    props: {
        stopId: {
            type: Number,
            default: null
        },
        shipmentId: {
            type: Number,
            default: null
        },
        stopDetails: {
            type: Object,
            default: () => {}
        },
        shipmentDetails: {
            type: Object,
            default: () => {}
        },
        useIcon: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        async cloneJob() {
            if (this.stopId === null && this.shipmentId === null) 
                return;

            try {
                this.$modal
                    .show(CloneJobEditModal, { stopDetails: this.stopDetails, shipmentDetails: this.shipmentDetails })
                    .then(async (response) => {
                        if (response && response.result.toLowerCase() === 'confirm') {
                            this.$_handleLoaderState(true, '...CREATING');

                            const payload = {
                                method: 'post',
                                data: response.data
                            };

                            const api =
                                this.stopId !== null
                                    ? `/api/stops/clone/${this.stopId}`
                                    : `/api/shipments/clone/${this.shipmentId}`;
                            const result = await handleRequests(api, payload);

                            this.$_handleLoaderState(false);
                            const jobType = this.stopId !== null ? `stop` : `shipments`;
                            const endPoint = this.stopId !== null ? '/stops/details' : '/shipments/details';
                            const notifyMessage = marked(
                                `You can find the duplicated ${jobType} by clicking the following link: <a onmouseout="this.style.color='white'" style="color:white !important; text-decoration: underline;" href="${endPoint}/${
                                    result.data.id
                                }">${result.data.ref}</a>`
                            );

                            this.$notify({
                                message: notifyMessage,
                                type: 'success',
                                duration: 10000
                            });
                        }
                    });
            } catch (error) {
                const message = 'Error duplicating job.';
                showErrorMessage(this, message, error);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.clone-button {
    display: inline;
}
</style>
