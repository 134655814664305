<template>
    <md-table-row :key="breakDetails.breakScheduleId" class="drag-item">
        <md-table-cell class="order-align">
            {{ breakDetails.order }}
        </md-table-cell>
        <md-table-cell v-if="!editBreakState">
            Before completing
            <span class="span-break-minutes">{{ breakDetails.drivingTimeMinutes }}</span>
            minutes of work take a
            <span class="span-break-minutes">{{ breakDetails.breakTimeMinutes }}</span>
            minute break.
        </md-table-cell>
        <md-table-cell v-if="editBreakState">
            Before completing
            <input
                type="text"
                class="search-textbox"
                v-model="breakDetails.drivingTimeMinutes"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
            />
            minutes of work take a
            <input
                type="text"
                class="search-textbox"
                v-model="breakDetails.breakTimeMinutes"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
            />
            minute break.
        </md-table-cell>
        <md-table-cell>
            <md-button
                v-if="editBreakState"
                class="md-round md-just-icon md-success break-btn-icon"
                title="Save Edit"
                @click="emitEdit"
            >
                <md-icon>save</md-icon>
            </md-button>
            <md-button
                v-if="editBreakState"
                class="md-round md-just-icon md-danger break-btn-icon"
                title="Cancel Edit"
                @click="toggleEdit"
            >
                <md-icon>cancel</md-icon>
            </md-button>
            <md-button
                v-if="!editBreakState"
                class="md-round md-just-icon md-warning break-btn-icon"
                title="Edit Break Schedule"
                @click="toggleEdit"
            >
                <md-icon>edit</md-icon>
            </md-button>
            <md-button
                v-if="!editBreakState"
                class="md-round md-just-icon md-danger break-btn-icon"
                title="Delete Break Schedule"
                @click="emitDelete"
            >
                <md-icon>delete</md-icon>
            </md-button>
        </md-table-cell>
    </md-table-row>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';

export default {
    name: 'TeamBreakRowComponent',
    mixins: [GeneralMixin],
    props: {
        teamBreakDetails: {
            type: Object,
            default: () => {}
        },
        breakCount: {
            type: Number,
            default: 0
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    data() {
        return {
            editBreakState: false,
            tempBreakDetails: null,
            breakDetails: {}
        };
    },
    async mounted() {
        this.breakDetails = this.teamBreakDetails;
    },
    methods: {
        emitEdit() {
            if (this.editBreakState) {
                if (this.validateBreakSchedule()) {
                    this.$emit('editBreakScheduleClick', this.breakDetails);
                    this.editBreakState = false;
                } else {
                    this.$notify({
                        message: 'Minutes should not be empty or zero.',
                        type: 'danger'
                    });
                }
            } else {
                this.editBreakState = true;
            }
        },
        toggleEdit() {
            if (this.editBreakState) {
                this.breakDetails = Object.assign({}, this.tempBreakDetails);
                this.tempBreakDetails = null;
            } else {
                this.tempBreakDetails = Object.assign({}, this.breakDetails);
            }

            this.editBreakState = !this.editBreakState;
        },
        emitDelete() {
            this.$emit('deleteBreakScheduleClick', this.breakDetails.breakScheduleId);
        },
        changeOrder(shouldIncrease) {
            this.$emit('changeOrderBreakScheduleClick', this.breakDetails.breakScheduleId, shouldIncrease);
        },
        validateBreakSchedule() {
            if (this.breakDetails === null || this.breakDetails === undefined) {
                return false;
            }

            return (
                this.isPropertyValid(this.breakDetails.drivingTimeMinutes) &&
                this.isPropertyValid(this.breakDetails.breakTimeMinutes)
            );
        },
        isPropertyValid(property) {
            if (property === null || property === undefined || property === '' || property <= 0) 
                return false;
            return true;
        }
    }
};
</script>
<style lang="scss" scoped>
.order-align {
    text-align: center;
}

.span-break-minutes {
    width: 30px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
}

.search-textbox {
    width: 30px;
}

.break-btn-icon {
    margin-left: 3px;
    margin-right: 3px;
}
</style>
