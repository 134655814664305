<template>
    <md-select :disabled="!enabled" v-model="selectedOption" @md-selected="getSelectedOption" data-testid="team-region">
        <md-option
            v-for="item in optionList"
            :key="item.teamRegionId"
            :value="item.teamRegionId"
            :class="isSubRegion ? `option-level-${item.level}` : ''"
        >
            {{ item.name }}
        </md-option>
    </md-select>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TeamRegionOptions',
    props: {
        selected: {
            type: Number,
            default: null
        },
        mode: { type: String, default: 'create' },
        enabled: { type: Boolean, default: true },
        showAuto: { type: Boolean, default: true },
        showAllTeamRegion: {
            type: Boolean,
            default: false
        },
        includeSubTeamRegions: { type: Boolean, default: true }
    },
    data() {
        return {
            optionList: [],
            selectedOption: 0,
            teamRegionDefaults: [
                {
                    // all members to be shown
                    name: 'Auto',
                    teamRegionId: 0 // special handling, md-option does not accept NULL
                },
                {
                    name: 'Not Set',
                    teamRegionId: -1 // special handling, show only members WithOUT team Region
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions',
            user: 'user/user'
        }),
        filterTeamRegionOptions() {
            if (this.teamRegions) {
                if (this.includeSubTeamRegions) {
                    return this.teamRegions;
                }

                return this.teamRegions.filter((x) => !x.level || x.level <= 1); // parent-most team regions only
            }

            return [];
        },
        isSubRegion() {
            const subRegion = this.filterTeamRegionOptions.find((x) => x.level >= 2);
            return !!subRegion;
        }
    },
    mounted() {
        this.refreshOptions();
        if (this.mode === 'create') {
            this.selectedOption = this.selected ?? this.user.teamRegionId ?? 0; // default: current user's OR auto
        } else {
            this.selectedOption = this.selected;
        }
    },
    watch: {
        selected: {
            immediate: true,
            handler(newValue) {
                this.selectedOption = !newValue ? 0 : newValue;
            }
        },
        'user.teamRegionId': function(newValue) {
            this.refreshOptions();
            this.selectedOption = this.selected !== null ? this.selected : -1;
        }
    },
    methods: {
        /**
         *
         * @param {number} teamRegionId
         */
        getSelectedOption(teamRegionId) {
            const teamRegion = this.filterTeamRegionOptions.find((x) => x.teamRegionId === teamRegionId);
            this.$emit('selectedOption', teamRegionId === 0 ? null : teamRegionId, teamRegion);
        },
        refreshOptions() {
            this.optionList = [...this.filterTeamRegionOptions];
            if (this.optionList) {
                if (this.showAuto) {
                    this.optionList = [...this.teamRegionDefaults, ...this.optionList];
                } else {
                    this.optionList = [this.teamRegionDefaults[1], ...this.optionList];
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.option-level-1 {
    ::v-deep .md-list-item-text {
        font-weight: 500;
    }
}

.option-level-2 {
    margin-left: 20px !important;
}

.option-level-3 {
    margin-left: 40px !important;
}
</style>
