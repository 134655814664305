<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <OrderFilterMenu @handleFilterChange="handleFilterChange" />
                    </div>
                    <div class="custom-toolbar-end">
                        <search-component
                            v-if="$root.isDesktop"
                            :search-filter="currentSearchTextValue"
                            :handle-search-on-type="true"
                            @onSearch="handleFilterOrSearch"
                        />
                    </div>
                </div>
                <md-card class="custom-margin-bottom">
                    <md-card-header class="md-card-header-icon md-card-header-warning">
                        <div class="card-icon">
                            <md-icon>receipt_long</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div
                            :class="['table-bulk-action-container', topScrollPosition > 150 ? 'sticky' : '']"
                            v-if="selectedOrders.length"
                        >
                            <div>
                                {{ selectedOrders.length }} order{{ selectedOrders.length > 1 ? 's' : '' }} selected.
                            </div>
                            <div>
                                <md-button
                                    class="md-warning bulk-action-btn"
                                    title="Send SMS"
                                    @click="sendSchedulingLink"
                                >
                                    <md-icon>schedule_send</md-icon>
                                    Send SMS
                                </md-button>
                                <!-- <md-button
                                    class="md-primary bulk-action-btn"
                                    title="Create Pickup/Delivery"
                                    @click="sendSchedulingLink"
                                >
                                    <md-icon>departure_board</md-icon>
                                    Create Pickup/Delivery
                                </md-button> -->
                            </div>
                        </div>
                        <div v-if="!isListLoading">
                            <md-table class="context-menu-support custom-paginated-table">
                                <md-table-row>
                                    <md-table-head>
                                        <md-checkbox
                                            class="table-checkbox table-head-checkbox"
                                            v-model="isSelectAll"
                                            @change="onSelectAll(isSelectAll)"
                                        ></md-checkbox>
                                    </md-table-head>
                                    <TableHeaderDropdown
                                        column-icon="keyboard_arrow_down"
                                        :default-text="selectedColumn.name"
                                        :dropdown-options="columnDropdownOptions"
                                        :selected-option="selectedColumn"
                                        @selectedOption="handleSelectedColumn"
                                    />
                                    <md-table-head>Order Date</md-table-head>
                                    <md-table-head>Items</md-table-head>
                                    <md-table-head>Shipping Address</md-table-head>
                                    <md-table-head>Customer Name</md-table-head>
                                    <md-table-head>Shipping Contact</md-table-head>
                                    <md-table-head>Fulfillment Status</md-table-head>
                                    <md-table-head v-if="$root.isDesktop && !isReadOnlyUser">Actions</md-table-head>
                                </md-table-row>
                                <md-table-row v-for="order in orderList" :key="order.orderId">
                                    <md-table-cell>
                                        <md-checkbox
                                            class="table-checkbox"
                                            :value="order"
                                            v-model="selectedOrders"
                                            @change="singleCheckbox(order)"
                                        ></md-checkbox>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <router-link
                                            v-if="selectedColumn.key == 'order-number'"
                                            class="ref-link"
                                            :to="{ name: 'Order Details', params: { orderId: order.orderId } }"
                                            target="_blank"
                                        >
                                            {{ order.orderNumber }}
                                        </router-link>
                                        <router-link
                                            v-else
                                            class="ref-link"
                                            :to="{ name: 'Order Details', params: { orderId: order.orderId } }"
                                            target="_blank"
                                        >
                                            {{
                                                order.sourceReference != null
                                                    ? order.sourceReference
                                                    : order.orderNumber
                                            }}
                                        </router-link>
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ order.orderDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ order.itemCount }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ order.shippingAddress != null ? order.shippingAddress.city : '' }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ order.customerId != null ? order.customer.name : '' }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ order.contact.name }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        <span class="custom-badge">{{ order.status }}</span>
                                    </md-table-cell>

                                    <md-table-cell v-if="$root.isDesktop && !isReadOnlyUser" class="action-buttons">
                                        <!-- <md-button
                                            v-if="!isReadOnlyUser"
                                            title="Create a delivery schedule"
                                            :class="[
                                                'md-info md-just-icon md-round',
                                                isDisabled ? 'button-disabled' : ''
                                            ]"
                                        >
                                            <md-icon>departure_board</md-icon>
                                        </md-button> -->
                                        <md-button
                                            v-if="!isReadOnlyUser"
                                            title="Update order"
                                            :class="[
                                                'md-warning md-just-icon md-round',
                                                isDisabled ? 'button-disabled' : ''
                                            ]"
                                            @click="updateOrder(order)"
                                        >
                                            <md-icon>edit</md-icon>
                                        </md-button>
                                        <!-- <md-button
                                            v-if="!isReadOnlyUser"
                                            title="Delete order"
                                            :class="[
                                                'md-danger md-just-icon md-round',
                                                isDisabled ? 'button-disabled' : ''
                                            ]"
                                        >
                                            <md-icon>delete</md-icon>
                                        </md-button> -->
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <div v-if="orderList.length == 0">
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
                <md-card-actions class="page-footer" md-alignment="space-between">
                    <div>
                        <p v-if="total === pagination.perPage" class="card-category">
                            Page {{ pagination.currentPage }} of many
                        </p>
                        <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                    </div>
                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>
        </div>
    </div>
</template>

<script>
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { Pagination, TableHeaderDropdown, SearchComponent } from '@/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import _ from 'lodash';
import { DATE_TYPES } from '@/utils/constants';
import moment from 'moment';
import OrderFilterMenu from './components/OrderFilterMenu';
import SendBulkSms from './components/SendBulkSms';
import UpdateOrderModal from './components/UpdateOrderModal';

export default {
    name: 'OrdersOverview',
    mixins: [GeneralMixin],
    components: {
        FadeLoader,
        Pagination,
        TableHeaderDropdown,
        SearchComponent,
        OrderFilterMenu
    },
    data() {
        return {
            orderList: [],
            isListLoading: false,
            pagination: PAGINATION_DEFAULTS,
            columnDropdownOptions: [
                { key: 'order-number', name: 'Order #' },
                { key: 'source-reference', name: 'Source Ref' }
            ],
            selectedColumn: { key: 'order-number', name: 'Order #' },
            isDisabled: false,
            currentSearchTextValue: '',
            customerIdFilterValue: null,
            fromDate: moment().format(DATE_TYPES.internationalDate),
            toDate: moment().format(DATE_TYPES.internationalDate),
            dateFilterType: 'orderDate',
            isSelectAll: false,
            selectedOrders: [],
            topScrollPosition: 0
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser'
        }),
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        }
    },
    async mounted() {
        await this.fetchOrdersList();
        this.createHandleScroll();
    },
    methods: {
        async fetchOrdersList(pageNumber = 1, itemsPerPage = 50) {
            try {
                this.isListLoading = true;
                const endpoint = `/api/orders/list`;
                const response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        itemsPerPage,
                        searchText: this.currentSearchTextValue,
                        dateFilterType: this.dateFilterType,
                        fromDate: this.fromDate,
                        toDate: this.toDate,
                        serviceAreaId: this.serviceAreaId,
                        customerId: this.customerId
                    }
                });

                this.orderList = response.data.orders;
                this.pagination.total = response.data.totalOrders;
                this.pagination.perPage = itemsPerPage;
                this.isListLoading = false;
            } catch (error) {
                const message = 'Error in fetching order list';
                showErrorMessage(this, message, error);
                this.isListLoading = false;
            }
        },
        handleSelectedColumn(item) {
            this.selectedColumn = item;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            this.fetchOrdersList(currentPage, perPage);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
        },
        // eslint-disable-next-line func-names
        handleFilterOrSearch: _.debounce(function(val) {
            this.currentSearchTextValue = val.searchText;
            this.fetchOrdersList();
        }, 1000),
        handleFilterChange(filter) {
            this.customerId = filter.customerId;
            this.serviceAreaId = filter.serviceAreaId;
            this.dateFilterType = filter.filterType;
            this.fromDate = filter.fromDate;
            this.toDate = filter.toDate;
            this.fetchOrdersList();
        },
        onSelectAll(value) {
            if (value) {
                this.orderList.forEach((order) => {
                    const isSelected = this.selectedOrders.some(
                        (selectedOrder) => selectedOrder.orderId === order.orderId
                    );

                    if (isSelected) {
                        return;
                    }
                    this.selectedOrders.push(order);
                });
            } else {
                this.selectedOrders = [];
            }
        },
        singleCheckbox(order) {
            const isRemoved = !this.selectedOrders.some((selectedOrder) => selectedOrder.orderId === order.orderId);
            if (isRemoved) {
                this.isSelectAll = false;
            }
        },
        createHandleScroll() {
            document.querySelector('.main-panel').addEventListener('scroll', this.handleScroll, true);
        },
        handleScroll(e) {
            this.topScrollPosition = e.target.scrollTop;
        },
        sendSchedulingLink() {
            this.$modal
                .show(SendBulkSms, {
                    title: 'Send Bulk SMS',
                    selectedOrders: this.selectedOrders
                })
                .then((response) => {
                    if (response === 'ok') 
                        this.$modal.hide();
                });
        },
        updateOrder(order) {
            this.$modal
                .show(UpdateOrderModal, {
                    orderId: order.orderId
                })
                .then((response) => {
                    if (response.code.toLowerCase() === 'ok') {
                        this.$modal.hide();
                        order.contact = response.data.contact;
                    }
                });
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        document.querySelector('.main-panel').removeEventListener('scroll', this.handleScroll);
    }
};
</script>

<style lang="scss" scoped>
.loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

.ref-link {
    color: rgba(0, 0, 0, 0.87) !important;
}

.ref-link:hover {
    color: #2b93ff !important;
}

::v-deep .dropdown-menu {
    margin-left: 0 !important;
}

.custom-margin-bottom {
    margin-bottom: 0;
}

.custom-badge {
    background-color: #c2907f;
    max-width: initial !important;
}

.custom-paginated-table {
    overflow-y: auto;
}

.custom-toolbar {
    .custom-toolbar-start {
        flex: 3;
    }
}

.table-bulk-action-container {
    .bulk-action-btn {
        width: 180px;
    }
}
</style>
