<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Add Note</h4>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.addNote" class="form-wrapper">
                <tabs
                    :tab-name="['Notes', 'Photos', 'Documents', 'Preview']"
                    color-button="warning"
                    class="custom-tab-list"
                >
                    <template slot="tab-pane-1">
                        <div>
                            <form-group name="role" class="custom-textarea">
                                <md-textarea v-focus v-model="form.note" @input="update" :md-debounce="100" />
                            </form-group>
                        </div>
                    </template>
                    <template slot="tab-pane-2">
                        <div>
                            <div id="notes-upload-multiple-image">
                                <vue-upload-multiple-image
                                    @upload-success="uploadImageSuccess"
                                    @before-remove="beforeRemove"
                                    @edit-image="editImage"
                                    :data-images="images"
                                    id-upload="myIdUpload"
                                    edit-upload="myIdEdit"
                                    drag-text="Drag Images (Multiple)"
                                    browse-text="(or) Select"
                                    primary-text="Default"
                                    mark-is-primary="Set as default"
                                    popup-text="This image will be displayed as the default"
                                    drop-text="Drop your files here ..."
                                    :show-primary="false"
                                    :max-image="10"
                                ></vue-upload-multiple-image>
                            </div>
                        </div>
                    </template>
                    <template slot="tab-pane-3">
                        <div>
                            <div>
                                <document-file-upload
                                    :documents="documents"
                                    @upload-success="uploadDocumentSuccess"
                                    @upload-remove="uploadDocumentRemove"
                                />
                            </div>
                        </div>
                    </template>
                    <template slot="tab-pane-4">
                        <div>
                            <div class="add-note-preview" v-html="compiledMarkdown"></div>
                            <div class="photo-preview">
                                <div v-for="(image, index) in imageList" :key="index">
                                    <md-avatar>
                                        <img
                                            :src="image.photoUrl"
                                            class="profile-image"
                                            @error="$_setDefaultBrokenImage"
                                            alt=""
                                        />
                                        <md-tooltip md-direction="top" class="tooltip-image-container">
                                            <div class="tooltip-image">
                                                <img :src="image.photoUrl" @error="$_setDefaultBrokenImage" alt="" />
                                            </div>
                                        </md-tooltip>
                                    </md-avatar>
                                </div>
                            </div>
                        </div>
                    </template>
                </tabs>
                <div class="note-type-container">
                    <div class="type-field">
                        <label class="custom-label">Type</label>
                        <vue-select
                            :reduce="(x) => x.name"
                            label="name"
                            :options="noteTypes"
                            placeholder="Status"
                            v-model="form.type"
                            :searchable="$root.isDesktop"
                            :clearable="false"
                        ></vue-select>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <div class="stats">
                <i class="md-icon md-icon-font md-theme-default">emoji_objects</i>
                <span class="tip">
                    You can also use the
                    <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Here-Cheatsheet" target="_blank">
                        Markdown syntax
                    </a>
                    to format your notes.
                </span>
            </div>
            <md-button class="dialog-button md-primary" @click="saveNote">
                Save
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import { BlockBlobClient } from '@azure/storage-blob';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { required } from 'vuelidate/lib/validators';
import marked from 'marked';
import { Tabs, DocumentFileUpload } from '@/components';
import { NOTE_TYPE_CONSTANTS, STOP_NOTE_TYPE_CONSTANTS } from '@/utils/constants';

export default {
    name: 'NotesModal',
    components: { VueUploadMultipleImage, Tabs, DocumentFileUpload },
    mixins: [GeneralMixin],
    props: {
        shipmentId: {
            type: Number,
            default: null
        },
        stopId: {
            type: Number,
            default: null
        },
        tripId: {
            type: Number,
            default: null
        },
        orderId: {
            type: Number,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            form: {
                note: '',
                type: 'Stop Note'
            },
            selectedPhoto: null,
            photoPreview: null,
            images: [],
            imageList: [],
            imageUrls: [],
            documents: [],
            documentList: [],
            documentUrls: [],
            noteTypes: [...NOTE_TYPE_CONSTANTS]
        };
    },
    computed: {
        compiledMarkdown() {
            return marked(this.form.note, { sanitize: true });
        }
    },
    mounted() {
        if (this.shipmentId != null) {
            this.form.type = 'Shipment Note';
        } else if (this.tripId != null) {
            this.form.type = 'Trip Started';
            this.noteTypes = this.noteTypes.concat([...STOP_NOTE_TYPE_CONSTANTS]);
        } else if (this.orderId != null) {
            this.form.type = 'Order Note';
        } else {
            this.form.type = this.stopId !== null ? 'Stop Note' : 'Other';
            this.noteTypes = this.noteTypes.concat([...STOP_NOTE_TYPE_CONSTANTS]);
        }
    },
    methods: {
        async saveNote() {
            const resp = await this.addNote();
            if (resp.data && (resp.data.photoUploadUrls || resp.data.documentUploadUrls)) {
                const uploadPromiseList = [];
                const uploadDocumentPromiseList = [];
                this.$_handleLoaderState(true, 'SAVING...');

                if (resp.data.photoUploadUrls) {
                    for (let i = 0; i < resp.data.photoUploadUrls.length; i++) {
                        uploadPromiseList.push(
                            this.onUploadImage(resp.data.photoUploadUrls[i], this.imageList[i].file)
                        );
                    }
                    Promise.all(uploadPromiseList).then(() => {
                        this.$_handleLoaderState(false);
                    });
                }

                if (resp.data.documentUploadUrls) {
                    for (let i = 0; i < resp.data.documentUploadUrls.length; i++) {
                        uploadDocumentPromiseList.push(
                            this.onUploadDocument(resp.data.documentUploadUrls[i], this.documentList[i].file)
                        );
                    }
                    Promise.all(uploadDocumentPromiseList).then(() => {
                        this.$_handleLoaderState(false);
                    });
                }
            }
        },
        // eslint-disable-next-line consistent-return
        async addNote() {
            // if (!this.$v.addNote.$invalid) {
            this.$_handleLoaderState(true);
            let api = `/api/stops/${this.stopId}/notes`;
            if (this.shipmentId != null) {
                api = `/api/shipments/${this.shipmentId}/notes`;
            } else if (this.tripId != null) {
                api = `/api/trips/${this.tripId}/notes`;
            } else if (this.orderId != null) {
                api = `/api/orders/${this.orderId}/notes`;
            }

            const photoFiles = this.imageList.map((item) => {
                return item.file.name;
            });

            const documentFiles = this.documentList.map((item) => {
                return item.file.name;
            });

            const payload = {
                method: 'post',
                data: {
                    note: this.form.note,
                    type: this.form.type,
                    photoFileNames: photoFiles,
                    DocumentFileNames: documentFiles
                }
            };
            try {
                const response = await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully added note.',
                    type: 'success'
                });
                this.resolve(this.form.note);
                this.$_handleLoaderState(false);
                return response;
            } catch (e) {
                const message = 'Could not add a new note.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
                this.resolve(false);
            }
            // }
        },
        update(value) {
            this.form.note = value;
        },
        async onUploadImage(notePhotoUrl, photofile) {
            const blockBlobClient = new BlockBlobClient(notePhotoUrl);

            try {
                await blockBlobClient.upload(photofile, photofile.size, {
                    blobHTTPHeaders: {
                        blobContentType: 'image/jpg'
                    }
                });

                this.selectedPhoto = null;
                this.photoPreview = null;
            } catch (error) {
                this.$notify({
                    message: `Error in uploading photo (${error})`,
                    type: 'danger'
                });
            }
        },
        async onUploadDocument(noteDocumentUrl, documentfile) {
            const blockBlobClient = new BlockBlobClient(noteDocumentUrl);

            try {
                await blockBlobClient.upload(documentfile, documentfile.size, {
                    blobHTTPHeaders: {
                        blobContentType: documentfile.type
                    }
                });

                this.selectedPhoto = null;
                this.photoPreview = null;
            } catch (error) {
                this.$notify({
                    message: `Error in uploading document (${error})`,
                    type: 'danger'
                });
            }
        },
        async uploadDocumentSuccess(filelist) {
            const items = [];
            filelist.forEach((item) => {
                items.push({ photoUrl: URL.createObjectURL(item), file: item, type: item.type });
            });
            this.documents = filelist;
            this.documentList = items;
        },
        uploadDocumentRemove(index, filelist) {
            this.documentList.splice(index, 1);
            this.documents = filelist;
        },
        async uploadImageSuccess(formData, index, fileList) {
            const items = [];
            formData.forEach((item) => {
                items.push({ photoUrl: URL.createObjectURL(item), file: item });
            });
            this.images = fileList;
            this.imageList = this.imageList.concat(items);
        },
        beforeRemove(index, done, fileList) {
            this.imageList.splice(index, 1);
            this.images = fileList;
            done();
        },
        editImage(formData, index, fileList) {
            let file;

            formData.forEach((item) => {
                file = item;
            });
            this.imageList[index].photoUrl = URL.createObjectURL(file);
            this.imageList[index].file = file;
            this.images = fileList;
        }
    },
    validations: {
        addNote: {
            note: {
                required
            }
        }
    }
};
</script>
<style lang="scss" scoped>
#notes-upload-multiple-image {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.add-note-preview {
    text-align: left;
    padding: 20px 16px;
    max-height: 220px;
    overflow: auto;
    border: 1px solid #eee;
    min-height: 220px;
    margin-top: 13px;
    white-space: pre;
}
.note-image {
    height: 100px;
    width: initial;
    margin-right: 10px;
}
.upload-photo-btn {
    margin-bottom: 0px;
}
.notes-image-container {
    margin-top: 10px;
}
.action-btn {
    margin-right: 10px;
}
.action-btns {
    position: absolute;
    right: 20px;
}

.modal-container {
    max-width: 720px;
    // min-height: 500px;
    .modal-body {
        min-height: 400px;
        padding-bottom: 0;
        .form-wrapper {
            position: relative;
            .note-type-container {
                position: absolute;
                right: 0;
                top: -52px;
                z-index: 9;

                .type-field {
                    width: 200px;
                    .custom-label {
                        display: block;
                        margin-bottom: -8px;
                    }
                }
                @media (min-width: 768px) {
                    top: 8px;
                }
            }
        }
    }
    .stats {
        flex-grow: 8;
        .md-icon {
            color: #ffd900;
        }
    }
    .tip {
        font-size: 12px;
    }
}

.custom-tab-list {
    margin-top: -20px;
    margin-bottom: 0 !important;
    ::v-deep .md-card-header {
        padding: 0;
    }

    ::v-deep .md-card-content {
        padding: 0;
        .md-list {
            margin: 0;
            .md-list-item:first-child {
                margin-left: 0;
            }
        }
    }

    .custom-textarea:before,
    .custom-textarea:after {
        border-bottom: none;
    }

    .custom-textarea {
        border-bottom: none;
        textarea {
            border: 1px solid #eee;
            resize: none !important;
            min-height: 288px !important;
        }
    }
}

#notes-upload-multiple-image {
    > div {
        width: 100%;
    }
    ::v-deep .image-container {
        width: 100%;
        max-width: initial;
        box-shadow: none;
        margin-top: 3px;
        height: 220px;
        .preview-image {
            height: 180px;
        }
        .image-bottom {
            border-top: 1px solid #eee;
            justify-content: center;
        }
        .image-center {
            width: 100%;
        }
        .show-image {
            width: 100%;
            .show-img {
                max-width: 100%;
                max-height: 170px;
                width: auto;
                margin: 0 auto;
            }
        }
    }

    ::v-deep .image-list-container {
        max-width: initial;
        .image-list-item {
            height: 50px;
            width: 50px;
        }
    }

    ::v-deep .modal-container {
        .vue-lightbox-header {
            button {
                margin-right: 0;
                svg {
                    fill: #aaa;
                }
            }
        }
        .vue-lightbox-modal-thumbnail {
            width: 100%;
            border: 1px solid #aaa;
            padding: 5px;
        }
        .vue-lightbox-arrow {
            background-color: rgba(0, 0, 0, 0.2);
            svg {
                fill: #fff;
            }
        }
    }
}

.photo-preview {
    margin-top: 10px;
    > div {
        height: 45px;
        width: 45px;
        border-radius: 4px;
        margin-right: 5px;
        display: inline-block;
        border: 1px solid #d6d6d6;
        vertical-align: middle;
    }
}

.tooltip-image-container {
    z-index: 9999;
}

.tooltip-image {
    width: 250px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    img {
        vertical-align: middle;
    }
}
</style>
