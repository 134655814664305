var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.brandList != null && _vm.brandList.length > 0
    ? _c(
        "div",
        { staticClass: "filter-inline" },
        [
          _c(
            "form-group",
            { attrs: { name: "brand", label: "Brand" } },
            [
              _c("md-input", {
                attrs: { type: "hidden" },
                model: {
                  value: _vm.brandValue,
                  callback: function ($$v) {
                    _vm.brandValue = $$v
                  },
                  expression: "brandValue",
                },
              }),
              _c("vue-select", {
                attrs: {
                  "data-testid": "brand",
                  reduce: function (x) {
                    return x.brandId
                  },
                  label: "name",
                  options: _vm.brandList,
                  searchable: _vm.$root.isDesktop,
                },
                model: {
                  value: _vm.brandValue,
                  callback: function ($$v) {
                    _vm.brandValue = $$v
                  },
                  expression: "brandValue",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }