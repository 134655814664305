var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isActive
    ? _c("div", [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleAssignStop({
                  stopId: _vm.item.stopId,
                  assignedTo: _vm.item.driverPublicUserId,
                  assignedCarrierTeamId: _vm.item.carrierTeamId,
                  stopRef: _vm.item.stopRef,
                  teamRegionId: _vm.item.teamRegionId,
                })
              },
            },
          },
          [
            _vm.item && _vm.item.driverPublicUserId
              ? _c("a", { attrs: { title: "reassign stop" } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.item.driverName) +
                      "\n        "
                  ),
                ])
              : _vm.item && _vm.item.carrierTeamId
              ? _c("a", { attrs: { title: "reassign stop" } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.item.carrierTeam.company) +
                      "\n        "
                  ),
                ])
              : _c("a", { attrs: { title: "reassign stop" } }, [
                  _vm._v("Unassigned"),
                ]),
          ]
        ),
      ])
    : _c("div", [
        _vm.item && _vm.item.driverPublicUserId
          ? _c("span", [_vm._v(_vm._s(_vm.item.driverName))])
          : _vm.item && _vm.item.carrierTeamId
          ? _c("span", [_vm._v(_vm._s(_vm.item.carrierTeam.company))])
          : _c("span", [_vm._v("Unassigned")]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }