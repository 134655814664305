<template>
    <!-- In the future we will replace the old CustomerFilterOptions to use this as the old one contains the structure of the whole filter menu bar
    and it is hard to do a lot of adjustment as it should contain only the customer filter dropdown.
 -->
    <md-field>
        <vue-select
            data-testid="customer-filter"
            v-if="customerList.length > 0"
            :options="customerList"
            :reduce="(cl) => cl.customerId"
            label="name"
            placeholder="Customer"
            v-model="customerFilterValue"
            :searchable="$root.isDesktop"
        ></vue-select>
    </md-field>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';

export default {
    name: 'CustomerFilterOptionsNew',
    props: {
        filterCustomerId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            customerList: [],
            customerId: this.filterCustomerId,
            name: null,
            customerFilterValue: null
        };
    },
    computed: {
        ...mapGetters({
            hasCustomerFilterAccess: 'user/hasInventoryListAccess'
        })
    },
    watch: {
        customerFilterValue(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.customerId = newValue;
                this.handleFilterValue();
            }
        },
        filterCustomerId() {
            this.customerFilterValue = this.filterCustomerId;
        }
    },
    methods: {
        handleFilterValue() {
            this.$emit('onCustomerSelected', this.customerId);

            if (this.customerList.length > 0) {
                const customer = this.customerList.find((customer) => customer.customerId === this.customerId);

                this.$emit('onCustomerSelectedName', customer?.name);
            }
        },
        async getCustomerList() {
            let response = {
                data: []
            };

            try {
                this.isLoading = true;
                const endpoint = '/api/customers/allCustomers';

                response = await handleRequests(endpoint);
            } catch (error) {
                const message = 'Error in getting the customer list';
                showErrorMessage(this, message, error);
            }

            this.isLoading = false;

            return response.data.customers.map((cl) => {
                return {
                    customerId: cl.customerId,
                    name: cl.name
                };
            });
        }
    },
    async mounted() {
        this.customerList = [...(await this.getCustomerList())];

        if (this.filterCustomerId) {
            this.customerFilterValue = this.filterCustomerId;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}

::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}

::v-deep .vs__search {
    cursor: pointer;
}

.status-filter {
    width: 180px !important;
}

.state-filter {
    margin-right: 20px;
}

::v-deep .filter-customer .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    display: block;
}
</style>
