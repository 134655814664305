<template>
    <div class="modal-container" :class="$root.isMobileOnly ? 'modal-dialog-full-width' : ''">
        <div class="modal-header">
            <h4 class="modal-title" v-if="isFilterSelection">{{ filterTitle }}</h4>
            <h4 class="modal-title" v-else-if="!isBulk">Assign Team Member Stop {{ stopRef }}</h4>
            <h4 class="modal-title" v-else>Assign Stops</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body" :class="$root.isMobileOnly ? 'modal-content-full-width' : ''">
            <form-wrapper :validator="$v.createStop" class="form-wrapper" v-if="!$root.isMobileOnly">
                <team-region-member-controls
                    :team-region-id="teamRegionId"
                    :public-user-id="form.assignedUser"
                    :carrier-team-id="form.assignedCarrierTeamId"
                    @onChanged="handleTeamRegionMemberChanged"
                    mode="update"
                    :show-team-region="false"
                    :strict-team-region-filter="false"
                    :show-carriers="false"
                />
            </form-wrapper>
            <div v-else>
                <div
                    v-for="member in teamMemberOptions"
                    :key="member.publicUserId"
                    class="member-item"
                    @click.stop="assignStopMobile(member.publicUserId)"
                >
                    <span>{{ member.fullName }}</span>
                    <span class="check-mark" v-if="form.assignedUser === member.publicUserId">
                        <md-icon>check_circle</md-icon>
                    </span>
                </div>
            </div>
        </div>
        <div class="modal-footer" v-if="!$root.isMobileOnly">
            <md-button class="md-primary dialog-button" @click="assignStop">Assign</md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { required } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { TeamRegionMemberControls } from '@/components';

export default {
    name: 'AssignStopModal',
    mixins: [GeneralMixin],
    props: {
        teamMembers: {
            type: Array,
            default: () => []
        },
        stopId: {
            type: [Number, Array],
            default: null
        },
        assignedTo: {
            type: String,
            default: ''
        },
        assignedCarrierTeamId: {
            type: [String, Number],
            default: ''
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        stopRef: {
            type: String,
            default: null
        },
        teamRegionId: {
            type: Number,
            default: null
        },
        isBulk: {
            type: Boolean,
            default: false
        },
        isFilterSelection: {
            type: Boolean,
            default: false
        },
        filterTitle: {
            type: String,
            default: 'Filter Stops for'
        },
        anyStopsInPast: {
            type: Boolean,
            default: false
        }
    },
    components: { TeamRegionMemberControls },
    validations: {
        createStop: {
            assignedUser: {
                required
            }
        }
    },
    data() {
        return {
            form: {
                assignedUser: null,
                assignedCarrierTeamId: null
            },
            teamMemberOptions: []
        };
    },
    computed: {
        ...mapGetters({
            allTeamCarriers: 'team/teamCarriers'
        })
    },
    mounted() {
        this.updateMemberOptions();

        let assignedUser = null;
        if (this.assignedTo) {
            assignedUser = this.teamMembers.find((member) => member.publicUserId === this.assignedTo);
        }

        this.form = {
            assignedUser: assignedUser ? assignedUser.publicUserId : null,
            assignedCarrierTeamId: this.assignedCarrierTeamId || null
        };
    },
    methods: {
        assignStopMobile(publicUserId) {
            this.form.assignedUser = publicUserId;
            this.assignStop();
        },
        async assignStop() {
            if (this.isFilterSelection) {
                this.resolve(this.form.assignedUser);
                return;
            }

            this.$messageBox
                .showIf(this.anyStopsInPast, null, {
                    body: `One or more stops you're trying to assign are for a past date. Do you want to assign these stops, keeping the date in the <b>past</b>, or change the date to <b>today</b>?`,
                    buttons: ['Past', 'Today', 'Cancel']
                })
                .then(async (response) => {
                    if (response?.toLowerCase() === 'cancel') 
                        return;

                    this.$_handleLoaderState(true, 'UPDATING...');

                    let api = `/api/stops/${this.stopId}/assign-user`;
                    let data = {
                        assignToPublicUserId: !this.form.assignedUser ? null : this.form.assignedUser,
                        carrierTeamId: !this.form.assignedCarrierTeamId ? null : this.form.assignedCarrierTeamId,
                        assignToPastTrip: response === null ? null : response.toLowerCase() === 'past'
                    };

                    if (this.isBulk) {
                        api = '/api/stops/bulk/assign-user';
                        data = {
                            stops: this.stopId,
                            request: {
                                assignToPublicUserId: !this.form.assignedUser ? null : this.form.assignedUser,
                                carrierTeamId: !this.form.assignedCarrierTeamId
                                    ? null
                                    : this.form.assignedCarrierTeamId,
                                assignToPastTrip: response === null ? null : response.toLowerCase() === 'past'
                            }
                        };
                    }

                    const payload = {
                        method: 'post',
                        data
                    };

                    try {
                        const data = await handleRequests(api, payload);

                        let msg = 'Stop was un-assigned.';
                        let name = null;
                        if (this.isBulk) {
                            if (this.form.assignedUser != null) {
                                const { fullName } = this.teamMembers.find(
                                    (member) => member.publicUserId === this.form.assignedUser
                                );

                                name = fullName;
                            } else if (this.form.assignedCarrierTeamId != null) {
                                name = this.allTeamCarriers.find(
                                    (member) => member.carrierTeamId === this.form.assignedCarrierTeamId
                                ).carrierTeam.company;
                            }
                            this.resolve({ errors: data.data, name });
                            return;
                            // eslint-disable-next-line no-else-return
                        } else {
                            if (this.form.assignedUser != null) {
                                const { fullName } = this.teamMembers.find(
                                    (member) => member.publicUserId === this.form.assignedUser
                                );

                                name = fullName;
                            } else if (this.form.assignedCarrierTeamId != null) {
                                name = this.allTeamCarriers.find(
                                    (member) => member.carrierTeamId === this.form.assignedCarrierTeamId
                                ).carrierTeam.company;
                            }

                            if (name != null) {
                                msg = `Stop was assigned to ${name}`;
                            }
                        }
                        this.$notify({
                            message: msg,
                            type: 'success'
                        });
                        this.$v.$reset();
                        this.resolve(data.data);
                    } catch (e) {
                        const message = 'Cannot assign stop to user.';
                        showErrorMessage(this, message, e);
                        this.resolve(false);
                    }
                });
        },
        updateMemberOptions() {
            let tempMembers = this.teamMembers.map((member) => ({
                publicUserId: member.publicUserId,
                fullName: `${member.firstName || ''} ${member.lastName || ''}`,
                email: member.email,
                teamRegionId: member.teamRegionId
            }));

            tempMembers = tempMembers.filter((x) => {
                return x.teamRegionId === this.teamRegionId;
            });

            const defaultOption = {
                publicUserId: null,
                fullName: 'Unassigned',
                email: null
            };
            this.teamMemberOptions = [defaultOption].concat(tempMembers);
        },
        handleTeamRegionMemberChanged(val) {
            this.form.assignedUser = val && val.publicUserId ? val.publicUserId : null;
            this.form.assignedCarrierTeamId = val && val.carrierTeamId ? val.carrierTeamId : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 500px;
}
</style>
