var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("div", { staticClass: "modal-container" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("h4", { staticClass: "modal-title" }, [_vm._v("Update Stop")]),
            _c(
              "md-button",
              {
                staticClass:
                  "md-simple md-just-icon md-round modal-default-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$modal.hide($event)
                  },
                },
              },
              [_c("md-icon", [_vm._v("clear")])],
              1
            ),
          ],
          1
        ),
        !_vm.stopDetails.shipmentId
          ? _c(
              "div",
              [
                _c(
                  "md-button",
                  {
                    staticClass: "md-success chargeButton-toggle",
                    on: {
                      click: function ($event) {
                        return _vm.openCharges()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            Charges (" +
                        _vm._s(_vm.chargeList.length) +
                        ")\n        "
                    ),
                  ]
                ),
                _c("charge-list-modal", {
                  attrs: {
                    "rates-list": _vm.chargeList,
                    "rates-is-loading": _vm.ratesIsLoading,
                    "rate-list-data": _vm.rateListData,
                    currency: _vm.stopDetails.currency,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("br"),
        _c(
          "div",
          { staticClass: "modal-body stop-form-modal" },
          [
            _c(
              "form-wrapper",
              {
                staticClass: "form-wrapper",
                attrs: { validator: _vm.$v.stopDetails },
              },
              [
                _c(
                  "div",
                  { staticClass: "stop-form-container" },
                  [
                    !_vm.stopDetails.shipmentId
                      ? _c("charging-type-options", {
                          attrs: {
                            "selected-charge-type-id":
                              _vm.stopDetails.rateGroupId,
                          },
                          on: { selectedOption: _vm.handleRateTypeChanged },
                        })
                      : _vm._e(),
                    !_vm.stopDetails.shipmentId
                      ? _c("team-region-member-controls", {
                          attrs: {
                            "team-region-id": _vm.stopDetails.teamRegionId,
                            "public-user-id":
                              _vm.stopDetails.assignedTo.publicUserId,
                            "carrier-team-id": _vm.stopDetails.carrierTeamId,
                            "show-label-if-member-not-in-team-region": true,
                            "show-carriers": false,
                            "strict-team-region-filter": false,
                            mode: "update",
                          },
                          on: { onChanged: _vm.handleTeamRegionMemberChanged },
                        })
                      : _vm._e(),
                    !_vm.stopDetails.shipmentId
                      ? _c(
                          "div",
                          [
                            _c(
                              "form-group",
                              { attrs: { name: "type", label: "Type" } },
                              [
                                _c("md-input", {
                                  attrs: { type: "hidden" },
                                  model: {
                                    value: _vm.stopDetails.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.stopDetails, "type", $$v)
                                    },
                                    expression: "stopDetails.type",
                                  },
                                }),
                                _c("vue-select", {
                                  attrs: {
                                    "data-testid": "stop-type",
                                    options: _vm.stopTypes,
                                    reduce: function (x) {
                                      return x.type
                                    },
                                    label: "key",
                                  },
                                  model: {
                                    value: _vm.stopDetails.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.stopDetails, "type", $$v)
                                    },
                                    expression: "stopDetails.type",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c(
                          "form-group",
                          {
                            attrs: {
                              name: "sourceReference",
                              label: "Source Reference",
                            },
                          },
                          [
                            _c("md-input", {
                              attrs: {
                                readonly: _vm.stopDetails.shipmentId !== null,
                              },
                              model: {
                                value: _vm.stopDetails.sourceReference,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.stopDetails,
                                    "sourceReference",
                                    $$v
                                  )
                                },
                                expression: "stopDetails.sourceReference",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("brand-filter", {
                          model: {
                            value: _vm.stopDetails,
                            callback: function ($$v) {
                              _vm.stopDetails = $$v
                            },
                            expression: "stopDetails",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "contact-details" },
                      [
                        _c("span", { staticClass: "cd-title" }, [
                          _vm._v("Customer Details"),
                        ]),
                        _c("customer-autocomplete", {
                          attrs: {
                            label:
                              _vm.stopDetails.customerId == null
                                ? "Name"
                                : "Customer",
                            id: "autocustomer",
                            placeholder: "",
                            "should-focus": false,
                            "update-mode": true,
                            "auto-fill-address": true,
                          },
                          on: {
                            change: _vm.handleCustomerFieldChange,
                            changeCustomerRateGroup:
                              _vm.handleCustomerChangeRateGroup,
                          },
                          model: {
                            value: _vm.stopDetails,
                            callback: function ($$v) {
                              _vm.stopDetails = $$v
                            },
                            expression: "stopDetails",
                          },
                        }),
                        _c(
                          "div",
                          [
                            _c("google-autocomplete", {
                              ref: "address",
                              attrs: {
                                label: "Destination",
                                id: "autoaddress",
                                classname: "form-control autocomplete-input",
                                placeholder: "",
                                "should-focus": true,
                                "disable-gps-coordinates": false,
                              },
                              on: {
                                handleCoordinates: _vm.handleCoordinates,
                                change: _vm.handleStopDetailsChanged,
                              },
                              model: {
                                value: _vm.stopDetails,
                                callback: function ($$v) {
                                  _vm.stopDetails = $$v
                                },
                                expression: "stopDetails",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.stopDetails.customerId != null
                          ? _c(
                              "form-group",
                              {
                                attrs: { name: "name", label: "Contact Name" },
                              },
                              [
                                _c("md-input", {
                                  model: {
                                    value: _vm.stopDetails.contact.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.stopDetails.contact,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression: "stopDetails.contact.name",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "form-group",
                          { attrs: { name: "phone", label: "Phone" } },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.stopDetails.contact.phone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.stopDetails.contact,
                                    "phone",
                                    $$v
                                  )
                                },
                                expression: "stopDetails.contact.phone",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "form-group",
                          { attrs: { name: "email", label: "Email" } },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.stopDetails.contact.email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.stopDetails.contact,
                                    "email",
                                    $$v
                                  )
                                },
                                expression: "stopDetails.contact.email",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.loadFieldList !== null
                          ? _c(
                              "div",
                              _vm._l(_vm.loadFieldList, function (load, index) {
                                return _c("load-inputs", {
                                  key: index,
                                  attrs: {
                                    "load-definition": load,
                                    "initial-value": _vm.loadValues,
                                  },
                                  on: { changed: _vm.handleLoadChanged },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        !_vm.stopDetails.type && _vm.skillOptions.length
                          ? _c(
                              "form-group",
                              { attrs: { name: "skills", label: "Skills" } },
                              [
                                _c("multiselect", {
                                  attrs: {
                                    "data-testid": "skills-option",
                                    options: _vm.skillOptions,
                                    multiple: true,
                                    "close-on-select": true,
                                    placeholder: "Pick skills",
                                  },
                                  model: {
                                    value: _vm.stopDetails.skills,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.stopDetails, "skills", $$v)
                                    },
                                    expression: "stopDetails.skills",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "form-group",
                          { attrs: { name: "notes", label: "Notes" } },
                          [
                            _c("md-textarea", {
                              model: {
                                value: _vm.stopDetails.notes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.stopDetails, "notes", $$v)
                                },
                                expression: "stopDetails.notes",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.stopCustomFieldList !== null
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.stopCustomFieldList,
                                function (customField, index) {
                                  return _c("custom-field-inputs", {
                                    key: index,
                                    attrs: {
                                      "custom-field-definition": customField,
                                      "initial-value":
                                        _vm.customFieldValues[customField.name],
                                    },
                                    on: {
                                      changed: _vm.handleCustomFieldChanged,
                                    },
                                  })
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          [
                            _c(
                              "form-group",
                              {
                                attrs: {
                                  name: "runNumber",
                                  label: "Run (Optional)",
                                },
                              },
                              [
                                _c("md-input", {
                                  attrs: { type: "number", min: "1" },
                                  on: {
                                    input: function ($value) {
                                      return (_vm.stopDetails.runNumber =
                                        parseFloat($value) || null)
                                    },
                                  },
                                  model: {
                                    value: _vm.stopDetails.runNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.stopDetails,
                                        "runNumber",
                                        $$v
                                      )
                                    },
                                    expression: "stopDetails.runNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "tripdate" },
                      [
                        _c(
                          "md-checkbox",
                          {
                            attrs: {
                              "data-testid": "trip-date-checkbox",
                              disabled: _vm.tripDateRequired,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleIsSetTripDateChange($event)
                              },
                            },
                            model: {
                              value: _vm.isSetTripDate,
                              callback: function ($$v) {
                                _vm.isSetTripDate = $$v
                              },
                              expression: "isSetTripDate",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Set trip date for this stop?\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.isSetTripDate
                      ? _c(
                          "div",
                          {
                            ref: "bottomElement",
                            staticClass: "stop-datepicker",
                            class: !_vm.stopDetails.assignedTo.publicUserId
                              ? ""
                              : "hide-clear-button",
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "md-datepicker",
                                  {
                                    ref: "mdDatePicker",
                                    attrs: {
                                      "md-immediately": "",
                                      "md-disabled-dates": _vm.disabledDates,
                                      "md-debounce": 10,
                                    },
                                    model: {
                                      value: _vm.tripDate,
                                      callback: function ($$v) {
                                        _vm.tripDate = $$v
                                      },
                                      expression: "tripDate",
                                    },
                                  },
                                  [_c("label", [_vm._v("Trip Date")])]
                                ),
                                _vm.isSetTripDate &&
                                _vm.tripDateRequired &&
                                _vm.tripDate == null
                                  ? _c("span", { staticClass: "error" }, [
                                      _vm._v(
                                        "\n                            Trip date is required.\n                        "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "form-group",
                                  {
                                    staticClass: "time-picker",
                                    attrs: { name: "time", label: "Time" },
                                  },
                                  [
                                    _c("time-picker", {
                                      attrs: {
                                        time: _vm.time,
                                        "additional-time-options":
                                          _vm.additionalTimeOption,
                                        "enable-auto-scroll": true,
                                      },
                                      on: { selectedTime: _vm.getTime },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "durationMinutes",
                                      label: "Duration (mins)",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.stopDetails.durationMinutes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.stopDetails,
                                            "durationMinutes",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "stopDetails.durationMinutes",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                attrs: { disabled: _vm.ratesIsLoading },
                on: { click: _vm.updateStop },
              },
              [_vm._v("\n            Update\n        ")]
            ),
            _c(
              "md-button",
              {
                staticClass: "dialog-button md-default",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$modal.hide($event)
                  },
                },
              },
              [_vm._v("Cancel")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }