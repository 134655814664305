<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <customer-filter-options
                            class="filter-steps--choices filter-status"
                            @onCustomerSelected="handleCustomerChanged"
                            :filter-customer-id="customerIdFilterValue"
                        />
                    </div>
                    <div class="custom-toolbar-end">
                        <search-component
                            v-if="$root.isDesktop"
                            :search-filter="currentSearchTextValue"
                            @onSearch="handleFilterOrSearch"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon card-icon-text">
                            <md-icon>star</md-icon>
                            Frequent Addresses
                        </div>
                    </md-card-header>
                    <md-card-content>
                        <div class="cf-container">
                            <div class="cf-action-container">
                                <md-button
                                    v-if="!isCustomer"
                                    title="Add Frequent Address"
                                    class="md-primary md-just-icon md-round"
                                    @click="emitAdd"
                                >
                                    <md-icon>add</md-icon>
                                </md-button>
                            </div>
                            <div v-if="frequentAddressesList.length != 0">
                                <frequent-addresses-list
                                    :list-of-frequent-addresses="frequentAddressesList"
                                    :customer-id-filter="customerIdFilterValue"
                                    :search-filter="currentSearchTextValue"
                                ></frequent-addresses-list>
                            </div>
                            <div v-if="frequentAddressesList.length == 0">
                                <p class="no-result-message">No Frequent Addresses configured</p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { CustomerFilterOptions, SearchComponent } from '@/components';
import { mapGetters } from 'vuex';
import FrequentAddressesList from './components/FrequentAddressesList';

export default {
    name: 'FrequentAddressesOverview',
    components: { FrequentAddressesList, CustomerFilterOptions, SearchComponent },
    mixins: [GeneralMixin],
    data() {
        return {
            frequentAddressesList: [],
            customerIdFilterValue: null,
            currentSearchTextValue: null
        };
    },
    async mounted() {
        this.$_handleLoaderState(true);
        await this.fetchFrequentAddressesList();
        this.$_handleLoaderState(false);
    },
    computed: {
        ...mapGetters({
            isCustomer: 'user/isCustomer',
            isCustomerAdmin: 'user/isCustomerAdmin',
            user: 'user/user'
        })
    },
    methods: {
        async fetchFrequentAddressesList() {
            let customerIdPayload = null;
            if (this.isCustomer || this.isCustomerAdmin) {
                customerIdPayload = this.user.customerId;
            }
            const api = `/api/frequent-addresses/list`;
            const payload = {
                method: 'get',
                params: { customerId: customerIdPayload }
            };
            await handleRequests(api, payload).then(
                (response) => {
                    this.frequentAddressesList = response.data.frequentAddresses;
                },
                (error) => {
                    const message = 'Error in adding frequent address';
                    showErrorMessage(this, message, error);
                    this.$_handleLoaderState(false);
                }
            );
        },
        handleCustomerChanged(value) {
            this.customerIdFilterValue = value;
        },
        async handleFilterOrSearch(value) {
            this.currentSearchTextValue = value.searchText;
        },
        emitAdd() {
            this.$router.push({
                name: 'Frequent Address Details',
                params: {
                    frequentAddressId: 0,
                    editMode: true
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.body-list {
    padding-top: 0px;
    position: relative;
}

.custom-toolbar {
    margin-top: 0px;
}

.content {
    margin-top: -15px;

    ::v-deep .md-card.md-theme-default {
        margin-bottom: 0;
    }
}

.card-icon-text {
    color: white;
}

::v-deep .filter-customer .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    display: block;
}

.filter-status {
    min-width: 250px;
    margin-right: 0;
    min-height: initial;
}
</style>
