var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canCloneJobs
    ? _c(
        "div",
        { staticClass: "clone-button" },
        [
          _c(
            "md-button",
            {
              class: _vm.useIcon
                ? "md-just-icon md-round md-success"
                : "md-success",
              attrs: { title: "Duplicate" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.cloneJob()
                },
              },
            },
            [
              _vm.useIcon
                ? _c("md-icon", [_vm._v("file_copy")])
                : _c("span", [_vm._v("Duplicate")]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }