<template>
    <div class="modal-container" :class="$root.isMobileOnly ? 'modal-dialog-full-width' : ''">
        <div class="modal-header">
            <h4 class="modal-title" v-if="isFilterSelection">{{ filterTitle }}</h4>
            <h4 class="modal-title" v-else>Assign Shipment</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body" :class="$root.isMobileOnly ? 'modal-content-full-width' : ''">
            <form-wrapper :validator="$v.createShipment" class="form-wrapper" v-if="!$root.isMobileOnly">
                <team-region-member-controls
                    :team-region-id="teamRegionId"
                    :public-user-id="form.assignedUser"
                    :carrier-team-id="form.assignedCarrierTeamId"
                    @onChanged="handleTeamRegionMemberChanged"
                    mode="update"
                    :show-team-region="false"
                    :strict-team-region-filter="false"
                    :show-carriers="false"
                />
            </form-wrapper>
            <div v-else>
                <div
                    v-for="member in teamMemberOptions"
                    :key="member.publicUserId"
                    class="member-item"
                    @click.stop="assignShipmentMobile(member.publicUserId)"
                >
                    <span>{{ member.fullName }}</span>
                    <span class="check-mark" v-if="form.assignedUser === member.publicUserId">
                        <md-icon>check_circle</md-icon>
                    </span>
                </div>
            </div>
        </div>
        <div class="modal-footer" v-if="!$root.isMobileOnly">
            <md-button class="md-primary dialog-button" @click="assignShipment">Assign</md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { required } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { TeamRegionMemberControls } from '@/components';

export default {
    name: 'AssignShipmentModal',
    mixins: [GeneralMixin],
    props: {
        teamMembers: {
            type: Array,
            default: () => []
        },
        shipmentId: {
            type: [Number, Array],
            default: null
        },
        assignedTo: {
            type: String,
            default: ''
        },
        assignedCarrierTeamId: {
            type: Number,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        shipmentRef: {
            type: String,
            default: null
        },
        isFilterSelection: {
            type: Boolean,
            default: false
        },
        filterTitle: {
            type: String,
            default: 'Filter Shipments for'
        },
        teamRegionId: {
            type: Number,
            default: null
        },
        isBulk: {
            type: Boolean,
            default: false
        },
        anyShipmentsInPast: {
            type: Boolean,
            default: false
        }
    },
    components: { TeamRegionMemberControls },
    validations: {
        createShipment: {
            assignedUser: {
                required
            }
        }
    },
    data() {
        return {
            form: {
                assignedUser: 0,
                assignedCarrierTeamId: null
            },
            teamMemberOptions: []
        };
    },
    computed: {
        ...mapGetters({
            allTeamCarriers: 'team/teamCarriers'
        })
    },
    mounted() {
        this.updateMemberOptions();

        let assignee = null;
        if (this.assignedTo) {
            assignee = this.teamMembers.find((member) => member.publicUserId === this.assignedTo);
        }

        this.form = {
            assignedUser: assignee ? assignee.publicUserId : null,
            assignedCarrierTeamId: this.assignedCarrierTeamId || null
        };
    },
    methods: {
        assignShipmentMobile(publicUserId) {
            this.form.assignedUser = publicUserId;
            this.assignShipment();
        },
        async assignShipment() {
            if (this.isFilterSelection) {
                this.resolve(this.form.assignedUser);
                return;
            }

            this.$messageBox
                .showIf(this.anyShipmentsInPast, null, {
                    body: `One or more shipments you're trying to assign are for a past date. Do you want to assign these shipments, keeping the date in the <b>past</b>, or change the date to <b>today</b>?`,
                    buttons: ['Past', 'Today', 'Cancel']
                })
                .then(async (response) => {
                    if (response?.toLowerCase() === 'cancel') 
                        return;

                    this.$_handleLoaderState(true);
                    let api = `/api/shipments/${this.shipmentId}/assign-user`;
                    let data = {
                        assignToPublicUserId: this.form.assignedUser === 0 ? null : this.form.assignedUser,
                        carrierTeamId: !this.form.assignedCarrierTeamId ? null : this.form.assignedCarrierTeamId,
                        assignToPastTrip: response === null ? null : response.toLowerCase() === 'past'
                    };

                    if (this.isBulk) {
                        api = '/api/shipments/bulk/assign-user';
                        data = {
                            shipments: this.shipmentId,
                            request: {
                                assignToPublicUserId: this.form.assignedUser,
                                carrierTeamId: !this.form.assignedCarrierTeamId
                                    ? null
                                    : this.form.assignedCarrierTeamId,
                                assignToPastTrip: response === null ? null : response.toLowerCase() === 'past'
                            }
                        };
                    }
                    const payload = {
                        method: 'post',
                        data
                    };

                    try {
                        const data = await handleRequests(api, payload);
                        let msg = 'Shipment was un-assigned.';
                        let name = null;
                        if (this.isBulk) {
                            if (this.form.assignedUser != null) {
                                const { fullName } = this.teamMembers.find(
                                    (member) => member.publicUserId === this.form.assignedUser
                                );

                                name = fullName;
                            } else if (this.form.assignedCarrierTeamId != null) {
                                name = this.allTeamCarriers.find(
                                    (member) => member.carrierTeamId === this.form.assignedCarrierTeamId
                                ).carrierTeam.company;
                            }
                            msg = `Shipment was assigned to ${name}`;
                            this.resolve({ errors: data.data, name });
                        } else {
                            const { assignedTo, carrierTeam } = data.data;

                            if (assignedTo.publicUserId) {
                                name = assignedTo.fullName;
                            } else if (carrierTeam) {
                                name = carrierTeam.company;
                            }

                            if (name) {
                                msg = `Shipment was assigned to ${name}`;
                            } else {
                                msg = `Shipment was unassigned.`;
                            }

                            this.$notify({
                                message: msg,
                                type: 'success'
                            });
                            this.$v.$reset();
                            this.resolve(data.data);
                        }
                    } catch (e) {
                        let message = 'Cannot assign shipment to user.';
                        if (e && e.message) 
                            [{ message }] = e.data;
                        showErrorMessage(this, message, e);
                        this.resolve(false);
                    }
                });
        },
        updateMemberOptions() {
            let tempMembers = this.teamMembers.map((member) => ({
                publicUserId: member.publicUserId,
                fullName: `${member.firstName || ''} ${member.lastName || ''}`,
                email: member.email,
                teamRegionId: member.teamRegionId
            }));

            tempMembers = tempMembers.filter((x) => {
                return x.teamRegionId === this.teamRegionId;
            });

            const defaultOption = {
                publicUserId: null,
                fullName: 'Unassigned',
                email: null
            };
            this.teamMemberOptions = [defaultOption].concat(tempMembers);
        },
        handleTeamRegionMemberChanged(val) {
            this.form.assignedUser = val && val.publicUserId ? val.publicUserId : null;
            this.form.assignedCarrierTeamId = val && val.carrierTeamId ? val.carrierTeamId : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 500px;
}
</style>
