var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-container" },
    [
      _vm._m(0),
      _c("div", { staticClass: "modal-body" }, [
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c(
              "md-datepicker",
              {
                ref: "mdDatePicker",
                staticClass: "date-clone-filter",
                attrs: { "md-immediately": "", "md-debounce": 10 },
                model: {
                  value: _vm.tripDate,
                  callback: function ($$v) {
                    _vm.tripDate = $$v
                  },
                  expression: "tripDate",
                },
              },
              [_c("label", [_vm._v("Trip Date")])]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c("div", [_vm._v("\n                Driver\n            ")]),
            _c("team-region-member-filter-options", {
              attrs: {
                "team-region-id": _vm.selectedTeamRegionId,
                clearable: false,
                "show-team-member": true,
              },
              on: { changed: _vm.handleTeamRegionMemberChanged },
            }),
          ],
          1
        ),
      ]),
      _c(
        "md-button",
        {
          staticClass: "dialog-button md-default confirm-button",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$modal.hide($event)
            },
          },
        },
        [_vm._v("Cancel")]
      ),
      _c(
        "md-button",
        {
          staticClass: "dialog-button md-primary confirm-button",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.confirm($event)
            },
          },
        },
        [_vm._v("Confirm")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Duplicate Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }