<template>
    <div class="details-page">
        <div class="detail-container" v-if="shipmentDetails != null">
            <div class="booking-details">
                <div class="title">
                    <span v-if="shipmentDetails.shipmentRef != null">Shipment {{ shipmentDetails.shipmentRef }}</span>
                    <span v-else>Shipment {{ shipmentDetails.shipmentId }}</span>
                    <template v-if="shipmentDetails.sourceReference">
                        / {{ shipmentDetails.sourceReference }}
                    </template>
                </div>
                <div class="action-buttons pull-right">
                    <div>
                        <shipment-status-button :shipment="shipmentDetails" @statusUpdated="handleChangedStatus" />
                    </div>
                    <div v-if="!isCustomer && !isSupportStaff">
                        <update-shipment-button
                            :shipment="shipmentDetails.shipmentId"
                            :team-members="teamMembers"
                            @shipmentUpdated="reloadDetails"
                            class-name="header-button"
                            :use-icon="false"
                            :shipment-status="shipmentDetails.status"
                            :carrier-team-id="shipmentDetails.carrierTeamId"
                        />
                    </div>

                    <clone-job
                        v-if="canCloneJobs"
                        :shipment-id="shipmentDetails.shipmentId"
                        :shipment-details="shipmentDetails"
                    />

                    <generate-label-button
                        v-if="!isCarrierAssigned && !isSupportStaff"
                        :id="shipmentDetails.shipmentId"
                        :use-icon="false"
                        :type="'Shipment'"
                    />
                    <stop-pdf-download-button
                        v-if="!shipmentDetails.carrierTeamId"
                        :status="shipmentDetails.status.toLowerCase()"
                        :stop-id="shipmentDetails.dropStop.stopId"
                        :stop-ref="shipmentDetails.dropStop.stopRef"
                        :use-icon="false"
                    />
                </div>
            </div>
            <div class="md-layout md-alignment-top-left">
                <div class="md-layout-item md-layout md-large-size-66 md-xlarge-size-50">
                    <div class="md-layout-item md-large-size-100 md-small-size-100 md-xlarge-size-100">
                        <md-card>
                            <md-card-header class="md-card-header-icon md-card-header-blue">
                                <div class="card-icon">
                                    <md-icon>description</md-icon>
                                </div>
                            </md-card-header>

                            <md-card-content>
                                <div>
                                    <div class="details-box tracking-link-box">
                                        <div v-if="shipmentDetails.tripDate">
                                            <label>Trip date</label>
                                            <span
                                                v-if="
                                                    shipmentDetails.tripDate != null &&
                                                        (shipmentDetails.dropStop.tripDate == null ||
                                                            shipmentDetails.pickupStop.tripDate ===
                                                                shipmentDetails.dropStop.tripDate)
                                                "
                                            >
                                                <router-link
                                                    v-if="shipmentDetails.tripId && !isCarrierAssigned"
                                                    :to="{
                                                        name: 'Trip Details',
                                                        params: {
                                                            tripId: shipmentDetails.tripId
                                                        }
                                                    }"
                                                    target="_blank"
                                                >
                                                    {{ shipmentDetails.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                                </router-link>
                                                <span v-else>
                                                    {{ shipmentDetails.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                                </span>
                                            </span>
                                            <span v-else>
                                                <router-link
                                                    v-if="shipmentDetails.tripId && !isCarrierAssigned"
                                                    :to="{
                                                        name: 'Trip Details',
                                                        params: {
                                                            tripId: shipmentDetails.tripId
                                                        }
                                                    }"
                                                    target="_blank"
                                                >
                                                    {{
                                                        shipmentDetails.pickupStop.tripDate
                                                            | dateFormat(DATE_TYPES.standardDate)
                                                    }}
                                                    <strong>-></strong>
                                                    {{
                                                        shipmentDetails.dropStop.tripDate
                                                            | dateFormat(DATE_TYPES.standardDate)
                                                    }}
                                                </router-link>
                                                <span v-else>
                                                    {{
                                                        shipmentDetails.pickupStop.tripDate
                                                            | dateFormat(DATE_TYPES.standardDate)
                                                    }}
                                                    <strong>-></strong>
                                                    {{
                                                        shipmentDetails.dropStop.tripDate
                                                            | dateFormat(DATE_TYPES.standardDate)
                                                    }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="run-number" v-if="shipmentDetails.runNumber">
                                            <div class="details-box">
                                                <label>Run</label>
                                                <span>
                                                    {{ shipmentDetails.runNumber }}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <template v-if="isCarrierAssigned">
                                                <a v-if="carrierTrackingUrl" :href="carrierTrackingUrl" target="_blank">
                                                    <md-icon>place</md-icon>
                                                    Tracking Link
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a
                                                    v-if="shipmentDetails.trackingLinkUrl"
                                                    :href="setTrackingLinkUrl(shipmentDetails.trackingLinkUrl)"
                                                    target="_blank"
                                                >
                                                    <md-icon>place</md-icon>
                                                    Tracking Link
                                                </a>
                                                <a
                                                    v-else-if="
                                                        shipmentDetails.trackingLinkUrl == null && !isReadOnlyUser
                                                    "
                                                    @click="createTrackingLink"
                                                >
                                                    <md-icon>add_location_alt</md-icon>
                                                    Create tracking link
                                                </a>
                                            </template>
                                        </div>
                                        <div v-if="shipmentDetails.rateGroup">
                                            <label>Service Package</label>
                                            <span>
                                                {{ shipmentDetails.rateGroup.rateGroupName }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="shipmentDetails.brandId != null" class="shipment-time-details">
                                    <div>
                                        <div class="details-box">
                                            <label>Brand</label>
                                            <img
                                                v-if="shipmentDetails.brandLogoUrl"
                                                class="brand-logo"
                                                :src="
                                                    shipmentDetails.brandLogoUrl !== null
                                                        ? shipmentDetails.brandLogoUrl
                                                        : $root.defaultPhotoUrl
                                                "
                                                @error="$_setDefaultBrokenImage"
                                                :title="shipmentDetails.brandName"
                                                :alt="shipmentDetails.brandName"
                                            />
                                            <span v-if="!shipmentDetails.brandLogoUrl">
                                                {{ shipmentDetails.brandName }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="details-box">
                                    <div class="address-details">
                                        <label>Pickup Address</label>
                                        <div v-if="shipmentDetails.pickupStop.name" class="address-name">
                                            {{ shipmentDetails.pickupStop.name }}
                                        </div>
                                        <a :href="'/stops/details/' + shipmentDetails.pickupStop.stopId">
                                            {{ shipmentDetails.pickupStop.address }}
                                        </a>
                                    </div>
                                    <div class="shipment-time-details">
                                        <div v-if="shipmentDetails.pickupStop.durationMinutes > 0">
                                            <div class="details-box">
                                                <label>Duration</label>
                                                <span>
                                                    {{ `${shipmentDetails.pickupStop.durationMinutes} minutes` }}
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="shipmentDetails.pickupStop.appointmentTime">
                                            <div class="details-box">
                                                <label>Appointment time</label>
                                                <span>
                                                    {{
                                                        shipmentDetails.pickupStop.appointmentTime
                                                            | timeFormat(DATE_TYPES.standardTime)
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            v-else-if="
                                                shipmentDetails.pickupStop.timeWindowStart &&
                                                    shipmentDetails.pickupStop.timeWindowEnd
                                            "
                                        >
                                            <div class="details-box">
                                                <label>Time Window</label>
                                                <span>
                                                    {{
                                                        shipmentDetails.pickupStop.timeWindowStart
                                                            | timeFormat(DATE_TYPES.standardTime)
                                                    }}
                                                    -
                                                    {{
                                                        shipmentDetails.pickupStop.timeWindowEnd
                                                            | timeFormat(DATE_TYPES.standardTime)
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="shipmentDetails.pickupStop.originalEta">
                                            <div class="details-box">
                                                <label>Estimated Time of Arrival</label>
                                                <span>
                                                    {{
                                                        shipmentDetails.pickupStop.originalEta
                                                            | timeFormat(DATE_TYPES.standardDateTime)
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="details-box">
                                    <div class="address-details">
                                        <label>Drop Address</label>
                                        <div v-if="shipmentDetails.dropStop.name" class="address-name">
                                            {{ shipmentDetails.dropStop.name }}
                                        </div>
                                        <a :href="'/stops/details/' + shipmentDetails.dropStop.stopId">
                                            {{ shipmentDetails.dropStop.address }}
                                        </a>
                                    </div>
                                    <div class="shipment-time-details">
                                        <div v-if="shipmentDetails.dropStop.durationMinutes > 0">
                                            <div class="details-box">
                                                <label>Duration</label>
                                                <span>
                                                    {{ `${shipmentDetails.dropStop.durationMinutes} minutes` }}
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="shipmentDetails.dropStop.appointmentTime">
                                            <div class="details-box">
                                                <label>Appointment time</label>
                                                <span>
                                                    {{
                                                        shipmentDetails.dropStop.appointmentTime
                                                            | timeFormat(DATE_TYPES.standardTime)
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            v-else-if="
                                                shipmentDetails.dropStop.timeWindowStart &&
                                                    shipmentDetails.dropStop.timeWindowEnd
                                            "
                                        >
                                            <div class="details-box">
                                                <label>Time Window</label>
                                                <span>
                                                    {{
                                                        shipmentDetails.dropStop.timeWindowStart
                                                            | timeFormat(DATE_TYPES.standardTime)
                                                    }}
                                                    -
                                                    {{
                                                        shipmentDetails.dropStop.timeWindowEnd
                                                            | timeFormat(DATE_TYPES.standardTime)
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="shipmentDetails.dropStop.originalEta">
                                            <div class="details-box">
                                                <label>Estimated Time of Arrival</label>
                                                <span>
                                                    {{
                                                        shipmentDetails.dropStop.originalEta
                                                            | timeFormat(DATE_TYPES.standardDateTime)
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="details-box md-chips md-primary" v-if="shipmentDetails.skills.length > 0">
                                    <label>Required skills:</label>
                                    <div
                                        class="md-chip custom-chip"
                                        v-for="(skill, index) in shipmentDetails.skills"
                                        :key="index"
                                    >
                                        {{ skill }}
                                    </div>
                                </div>
                                <div class="custom-field-container" v-if="shipmentCustomFieldList !== null">
                                    <div
                                        class="details-box"
                                        v-for="(customField, index) in shipmentCustomFieldList"
                                        :key="index"
                                    >
                                        <label>{{ customField.label }}</label>
                                        <span
                                            v-if="
                                                customFieldValues !== null &&
                                                    customFieldValues[customField.name] !== null
                                            "
                                        >
                                            {{ customFieldValues[customField.name] }}
                                        </span>
                                    </div>
                                </div>
                                <div class="shipment-time-details" v-if="loadCapacityList !== null">
                                    <div
                                        class="details-box"
                                        v-for="(loadCapacity, index) in loadCapacityList"
                                        :key="index"
                                    >
                                        <div v-if="loadCapacity.type !== 'dimensions'">
                                            <label>{{ loadCapacity.label }}</label>
                                            <span
                                                v-if="loadCapacity !== null && loadValues[loadCapacity.type] !== null"
                                            >
                                                {{ loadValues[loadCapacity.type] }} {{ loadCapacity.symbol }}
                                            </span>
                                        </div>
                                        <div v-if="loadCapacity.type === 'dimensions'">
                                            <label>{{ loadCapacity.label }}</label>
                                            <span
                                                v-if="loadCapacity !== null && loadValues[loadCapacity.type] !== null"
                                            >
                                                L: {{ loadValues['length'] }} {{ loadCapacity.symbol }} W:
                                                {{ loadValues['width'] }} {{ loadCapacity.symbol }} H:
                                                {{ loadValues['height'] }} {{ loadCapacity.symbol }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="teamRegionName" class="details-box">
                                    <label>Team Region</label>
                                    <span>{{ teamRegionName }}</span>
                                </div>
                                <div class="details-box">
                                    <label>Notes</label>
                                    <span
                                        v-if="shipmentDetails.notes"
                                        class="shipment-notes"
                                        v-text="shipmentDetails.notes"
                                    ></span>
                                    <span v-else class="grayedout-text">No notes found</span>
                                </div>

                                <div v-if="shipmentDetails.teamMemberInvoiceId" class="details-box">
                                    <label class="label">Team Member Invoice:</label>

                                    <span class="value">
                                        <div
                                            :class="
                                                `custom-badge payment-status-${shipmentDetails.teamMemberInvoiceStatus
                                                    .toLowerCase()
                                                    .replace(/\s+/g, '')}`
                                            "
                                        >
                                            {{
                                                $t('inputs.paymentStatuses.' + shipmentDetails.teamMemberInvoiceStatus)
                                            }}
                                        </div>

                                        <router-link
                                            :to="{
                                                name: 'Team Member Invoice Details',
                                                params: { invoiceId: shipmentDetails.teamMemberInvoiceId }
                                            }"
                                            target="_blank"
                                        >
                                            {{ shipmentDetails.teamMemberInvoiceNumber }}
                                        </router-link>
                                    </span>
                                </div>

                                <div v-if="shipmentDetails.customerInvoiceId" class="details-box">
                                    <label class="label">Customer Invoice:</label>

                                    <span class="value">
                                        <div
                                            :class="
                                                `custom-badge payment-status-${shipmentDetails.customerInvoiceStatus
                                                    .toLowerCase()
                                                    .replace(/\s+/g, '')}`
                                            "
                                        >
                                            {{ $t('inputs.paymentStatuses.' + shipmentDetails.customerInvoiceStatus) }}
                                        </div>

                                        <router-link
                                            :to="{
                                                name: 'Customer Invoice Details',
                                                params: { invoiceId: shipmentDetails.customerInvoiceId }
                                            }"
                                            target="_blank"
                                        >
                                            {{ shipmentDetails.customerInvoiceNumber }}
                                        </router-link>
                                    </span>
                                </div>
                            </md-card-content>
                        </md-card>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100 map-box">
                        <map-box-overlay :details="shipmentDetails" :type="'shipment'"></map-box-overlay>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100">
                        <notes
                            :list="shipmentDetails.shipmentNotes"
                            :shipment-id="shipmentDetails.shipmentId"
                            @notesAdded="handleAddedShipmentNote"
                        />
                    </div>

                    <div
                        v-if="!isIndividualUser"
                        class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100"
                    >
                        <charge-rates-list
                            v-if="isTeamOwner || isAdmin || isReadOnlyUser"
                            :charge-lines="shipmentDetails.chargeLines"
                            :shipment-details="shipmentDetails"
                            title="Charges"
                            @updateShipmentDetails="getShipmentDetails"
                            :has-invoice="shipmentDetails.customerInvoiceId !== null"
                            :rates-have-changed="updateChargesInBackground"
                        />
                        <md-card>
                            <md-card-header class="md-card-header-icon md-card-header-green">
                                <div class="card-icon">
                                    <md-icon>receipt_long</md-icon>
                                </div>
                            </md-card-header>

                            <md-card-content>
                                <p
                                    v-if="
                                        !shipmentDetails.pickupStop.stopItems ||
                                            shipmentDetails.pickupStop.stopItems.length === 0
                                    "
                                    class="grayedout-text text-center"
                                >
                                    No items found
                                </p>
                                <div v-else>
                                    <md-table class="context-menu-support custom-paginated-table">
                                        <md-table-row>
                                            <md-table-head>Status</md-table-head>
                                            <md-table-head>Barcode</md-table-head>
                                            <md-table-head>Description</md-table-head>
                                            <md-table-head
                                                v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Length')"
                                            >
                                                L
                                            </md-table-head>
                                            <md-table-head
                                                v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Width')"
                                            >
                                                W
                                            </md-table-head>
                                            <md-table-head
                                                v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Height')"
                                            >
                                                H
                                            </md-table-head>
                                            <md-table-head
                                                v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Volume')"
                                            >
                                                V
                                            </md-table-head>
                                            <md-table-head
                                                v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Weight')"
                                            >
                                                Wt
                                            </md-table-head>
                                            <md-table-head v-if="$root.isDesktop && !isReadOnlyUser">
                                                Actions
                                            </md-table-head>
                                        </md-table-row>
                                        <md-table-row
                                            v-for="line in shipmentDetails.pickupStop.stopItems"
                                            :key="line.itemId"
                                            @click.stop="openInventoryItem(line.itemId)"
                                        >
                                            <md-table-cell>
                                                <div
                                                    :class="
                                                        `custom-badge inventory-status-${line.status
                                                            .toLowerCase()
                                                            .replace(/\s+/g, '')}`
                                                    "
                                                >
                                                    {{ line.status }}
                                                </div>
                                            </md-table-cell>
                                            <md-table-cell>{{ line.barcode }}</md-table-cell>
                                            <md-table-cell>{{ line.description }}</md-table-cell>
                                            <md-table-cell
                                                v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Length')"
                                            >
                                                {{ $_displayLoadValue(loadCapacityList, line.load, 'Length') }}
                                            </md-table-cell>
                                            <md-table-cell
                                                v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Width')"
                                            >
                                                {{ $_displayLoadValue(loadCapacityList, line.load, 'Width') }}
                                            </md-table-cell>
                                            <md-table-cell
                                                v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Height')"
                                            >
                                                {{ $_displayLoadValue(loadCapacityList, line.load, 'Height') }}
                                            </md-table-cell>
                                            <md-table-cell
                                                v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Volume')"
                                            >
                                                {{ $_displayLoadValue(loadCapacityList, line.load, 'Volume') }}
                                            </md-table-cell>
                                            <md-table-cell
                                                v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Weight')"
                                            >
                                                {{ $_displayLoadValue(loadCapacityList, line.load, 'Weight') }}
                                            </md-table-cell>
                                            <md-table-cell
                                                v-if="$root.isDesktop && !isReadOnlyUser"
                                                class="action-buttons"
                                            >
                                                <div class="action-button">
                                                    <update-item-button
                                                        v-if="!isReadOnlyUser"
                                                        :item="line"
                                                        @itemUpdated="handleUpdateItem"
                                                        :is-delivered="line.status === 'Delivered'"
                                                        :shipment-status="shipmentDetails.status"
                                                    />
                                                    <md-tooltip
                                                        v-if="line.status === 'Delivered'"
                                                        class="line-tooltip"
                                                        md-direction="top"
                                                    >
                                                        You cannot edit a delivered item.
                                                    </md-tooltip>
                                                </div>

                                                <div class="action-button">
                                                    <md-button
                                                        v-if="!isReadOnlyUser"
                                                        title="Unlink item"
                                                        class="md-danger md-just-icon md-round"
                                                        :disabled="
                                                            line.status === 'Delivered' ||
                                                                shipmentDetails.status.toLowerCase() === 'complete' ||
                                                                shipmentDetails.status.toLowerCase() === 'cancelled'
                                                        "
                                                        @click.stop="handleUnlinkItem(line.itemId)"
                                                    >
                                                        <md-icon>link_off</md-icon>
                                                    </md-button>
                                                    <md-tooltip
                                                        v-if="line.status === 'Delivered'"
                                                        class="line-tooltip"
                                                        md-direction="top"
                                                    >
                                                        You cannot unlink a delivered item.
                                                    </md-tooltip>
                                                    <md-tooltip
                                                        v-else-if="shipmentDetails.status.toLowerCase() === 'complete'"
                                                        class="line-tooltip"
                                                        md-direction="top"
                                                    >
                                                        You cannot edit an item when the shipment is marked as Complete
                                                    </md-tooltip>
                                                    <md-tooltip
                                                        v-else-if="shipmentDetails.status.toLowerCase() === 'cancelled'"
                                                        class="line-tooltip"
                                                        md-direction="top"
                                                    >
                                                        You cannot edit an item when the shipment is marked as Cancelled
                                                    </md-tooltip>
                                                </div>
                                                <div class="action-button">
                                                    <md-button
                                                        v-if="!isReadOnlyUser"
                                                        title="Delete item"
                                                        class="md-danger md-just-icon md-round"
                                                        :disabled="
                                                            line.status === 'Delivered' ||
                                                                shipmentDetails.status.toLowerCase() === 'complete' ||
                                                                shipmentDetails.status.toLowerCase() === 'cancelled'
                                                        "
                                                        @click.stop="handleDeleteItem(line.itemId)"
                                                    >
                                                        <md-icon>delete</md-icon>
                                                    </md-button>
                                                    <md-tooltip
                                                        v-if="line.status === 'Delivered'"
                                                        class="line-tooltip"
                                                        md-direction="top"
                                                    >
                                                        You cannot delete a delivered item.
                                                    </md-tooltip>
                                                    <md-tooltip
                                                        v-else-if="shipmentDetails.status.toLowerCase() === 'complete'"
                                                        class="line-tooltip"
                                                        md-direction="top"
                                                    >
                                                        You cannot edit an item when the shipment is marked as Complete
                                                    </md-tooltip>
                                                    <md-tooltip
                                                        v-else-if="shipmentDetails.status.toLowerCase() === 'cancelled'"
                                                        class="line-tooltip"
                                                        md-direction="top"
                                                    >
                                                        You cannot edit an item when the shipment is marked as Cancelled
                                                    </md-tooltip>
                                                </div>
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </div>
                            </md-card-content>

                            <md-card-actions md-alignment="right" v-if="!isSupportStaff">
                                <CarrierDownloadLabelButton
                                    v-if="isCarrierAssigned && carrierLabels.length"
                                    :use-icon="false"
                                    :labels="carrierLabels"
                                />
                                <generate-label-button
                                    v-if="!isCarrierAssigned && shipmentDetails.pickupStop.stopItems.length > 0"
                                    :id="shipmentDetails.shipmentId"
                                    :use-icon="false"
                                    :type="'Shipment'"
                                />
                                <md-button
                                    class="md-success"
                                    @click="handleAddItem()"
                                    :disabled="
                                        shipmentDetails.status.toLowerCase() === 'complete' ||
                                            shipmentDetails.status.toLowerCase() === 'cancelled'
                                    "
                                >
                                    Add Item
                                </md-button>

                                <md-tooltip
                                    v-if="shipmentDetails.status.toLowerCase() === 'complete'"
                                    class="line-tooltip"
                                    md-direction="left"
                                >
                                    Not allowed to add an item when the shipment is already marked as Complete.
                                </md-tooltip>
                                <md-tooltip
                                    v-else-if="shipmentDetails.status.toLowerCase() === 'cancelled'"
                                    class="line-tooltip"
                                    md-direction="left"
                                >
                                    Not allowed to add an item when the shipment is already marked as Cancelled.
                                </md-tooltip>
                            </md-card-actions>
                        </md-card>
                    </div>
                    <div
                        v-feature="[featureNames.Orders]"
                        class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100 stop-order-box"
                    >
                        <order-items :orders="orderDetails" />
                    </div>
                </div>
                <div class="md-layout-item md-layout md-large-size-33 md-xlarge-size-50">
                    <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50">
                        <md-card>
                            <md-card-header class="md-card-header-icon md-card-header-green">
                                <div class="card-icon" v-if="shipmentDetails.assignedTo.photoUrl === null">
                                    <md-icon>account_box</md-icon>
                                </div>
                                <div
                                    class="card-icon profile-image"
                                    v-if="shipmentDetails.assignedTo.photoUrl !== null"
                                >
                                    <img :src="shipmentDetails.assignedTo.photoUrl" alt="avatar" />
                                </div>
                            </md-card-header>

                            <md-card-content>
                                <div class="custom-md-field">
                                    <div class="custom-label">
                                        {{ shipmentDetails.carrierTeamId != null ? 'Carrier' : 'Driver' }}
                                    </div>
                                    <span v-if="isReadOnlyUser">{{ shipmentDetails.assignedTo.fullName }}</span>
                                    <router-link
                                        v-else-if="shipmentDetails.assignedTo.publicUserId"
                                        :to="{
                                            name: 'Team Member Profile',
                                            params: { publicUserId: shipmentDetails.assignedTo.publicUserId }
                                        }"
                                        target="_blank"
                                    >
                                        {{ shipmentDetails.assignedTo.fullName }}
                                    </router-link>
                                    <span v-else-if="shipmentDetails.carrierTeamId != null">
                                        <img
                                            v-if="shipmentDetails.carrierTeam && shipmentDetails.carrierTeam.logoUrl"
                                            class="profile-image carrier-image-logo"
                                            :src="
                                                shipmentDetails.carrierTeam.logoUrl !== null
                                                    ? shipmentDetails.carrierTeam.logoUrl
                                                    : $root.defaultPhotoUrl
                                            "
                                            alt="carrier logo"
                                            @error="$_setDefaultBrokenImage"
                                        />
                                        {{ shipmentDetails.carrierTeam.company }}
                                    </span>
                                    <reassign-shipment-button
                                        v-else
                                        type="text"
                                        :item="shipmentDetails"
                                        :team-members="teamMembers"
                                        @assignedUser="handleAssignedUser"
                                    />

                                    <div
                                        v-if="
                                            !shipmentDetails.rateGroupId &&
                                                shipmentDetails.assignedTo.rateGroupId &&
                                                showServicePackageDriver()
                                        "
                                    >
                                        <div class="custom-label">Service Package</div>
                                        <span>{{ shipmentDetails.assignedTo.rateGroupName }}</span>
                                    </div>
                                </div>
                                <div class="details-box" v-if="shipmentDetails.assignedTo.phone">
                                    <label>Phone</label>
                                    <div class="phone-sms">
                                        <a :href="`tel:${shipmentDetails.assignedTo.phone}`">
                                            {{ shipmentDetails.assignedTo.phone }}
                                        </a>
                                        <send-sms-button
                                            v-if="shipmentDetails.assignedTo.phone"
                                            :public-user-id="shipmentDetails.assignedTo.publicUserId"
                                            :name="shipmentDetails.assignedTo.fullName"
                                        />
                                    </div>
                                </div>
                                <div v-if="shipmentDetails.assignedCarrierBooking != null">
                                    <div class="details-box">
                                        <label>Reference #:</label>
                                        <span>
                                            {{ shipmentDetails.assignedCarrierBooking.supportReference }}
                                        </span>
                                    </div>
                                    <div class="details-box">
                                        <label>Status</label>
                                        <span>
                                            <div
                                                class="custom-badge"
                                                :class="
                                                    `status-${$_getQuotingStatusBadgeColor(
                                                        shipmentDetails.quotingStatus
                                                    )}`
                                                "
                                            >
                                                {{ $_getQuotingStatusText(shipmentDetails.quotingStatus) }}
                                            </div>
                                        </span>
                                    </div>
                                    <div class="details-box" v-if="shipmentDetails.assignedCarrierBooking.serviceLevel">
                                        <label>Service Type:</label>
                                        <span>
                                            <div
                                                class="custom-badge"
                                                :class="
                                                    `status-${$_getServiceLevelBadgeColor(
                                                        shipmentDetails.assignedCarrierBooking.timeWindowEnd
                                                    )}`
                                                "
                                            >
                                                {{ $t(shipmentDetails.assignedCarrierBooking.serviceLevel) }}
                                            </div>
                                        </span>
                                    </div>
                                    <div class="details-box">
                                        <label>Price:</label>
                                        <span>
                                            {{
                                                shipmentDetails.assignedCarrierBooking.feeAfterTax
                                                    | currency(shipmentDetails.assignedCarrierBooking.currency)
                                            }}
                                        </span>
                                    </div>
                                    <div class="details-box" v-if="shipmentDetails.assignedCarrierBooking.pickupEta">
                                        <label>Pickup ETA:</label>
                                        <span>
                                            {{
                                                shipmentDetails.assignedCarrierBooking.pickupEta
                                                    | dateTimeFormat(DATE_TYPES.standardDate)
                                            }}
                                        </span>
                                    </div>
                                    <div class="details-box" v-if="shipmentDetails.assignedCarrierBooking.dropOffEta">
                                        <label>Drop ETA:</label>
                                        <span>
                                            {{
                                                shipmentDetails.assignedCarrierBooking.dropOffEta
                                                    | dateTimeFormat(DATE_TYPES.standardDate)
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </md-card-content>
                        </md-card>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50">
                        <md-card>
                            <md-card-header class="md-card-header-icon md-card-header-green">
                                <div class="card-icon">
                                    <md-icon>account_box</md-icon>
                                </div>
                            </md-card-header>
                            <md-card-content>
                                <p
                                    v-if="
                                        !shipmentDetails.contact.name &&
                                            !shipmentDetails.contact.phone &&
                                            !shipmentDetails.contact.email
                                    "
                                    class="grayedout-text"
                                >
                                    No contact details provided.
                                </p>
                                <div v-else>
                                    <div class="details-box" v-if="shipmentDetails.contact.name">
                                        <label>Contact Name</label>
                                        <span>{{ shipmentDetails.contact.name }}</span>
                                    </div>
                                    <div class="details-box" v-if="shipmentDetails.contact.phone">
                                        <label>Phone</label>
                                        <a :href="`tel:${shipmentDetails.contact.phone}`">
                                            {{ shipmentDetails.contact.phone }}
                                        </a>
                                    </div>
                                    <div class="details-box" v-if="shipmentDetails.contact.email">
                                        <label>Email</label>
                                        <span>{{ shipmentDetails.contact.email }}</span>
                                    </div>
                                    <div
                                        class="details-box"
                                        v-if="
                                            shipmentDetails.customerRateGroupId &&
                                                !shipmentDetails.rateGroupId &&
                                                showServicePackageCustomer()
                                        "
                                    >
                                        <label>Service Package</label>
                                        <span>{{ shipmentDetails.customerRateGroupName }}</span>
                                    </div>
                                </div>
                            </md-card-content>
                        </md-card>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50">
                        <md-card>
                            <md-card-header class="md-card-header-icon md-card-header-warning">
                                <div class="card-icon">
                                    <md-icon>history</md-icon>
                                </div>
                            </md-card-header>

                            <md-card-content>
                                <time-line plain type="simple" v-if="shipmentStatusHistory.length > 0">
                                    <time-line-item
                                        inverted
                                        :badge-type="`status-${history.newStatus.replace(/\s+/g, '').toLowerCase()}`"
                                        badge-icon="place"
                                        v-for="(history, index) in shipmentStatusHistory"
                                        :key="index"
                                        :is-clickable="true"
                                        @clicked="showHistoryInMap(history)"
                                    >
                                        <span
                                            slot="header"
                                            class="badge"
                                            :class="`status-${history.newStatus.replace(/\s+/g, '').toLowerCase()}`"
                                        >
                                            {{ history.newStatus }}
                                        </span>

                                        <span slot="headerTime" class="time">
                                            {{ history.actionDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                                        </span>

                                        <h6 slot="footer">
                                            <i class="ti-time" />

                                            By {{ history.changedByUserFullName }}
                                        </h6>
                                    </time-line-item>
                                </time-line>
                                <p v-else class="grayedout-text">No Status History Available</p>
                            </md-card-content>
                        </md-card>
                    </div>
                    <div
                        v-if="hasMarketplaceAccess"
                        class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50"
                    >
                        <CarrierBookingHistory
                            :carrier-booking-history="shipmentDetails ? shipmentDetails.carrierBookingHistory : []"
                        />
                    </div>
                    <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50">
                        <md-card>
                            <md-card-header class="md-card-header-icon md-card-header-blue">
                                <div class="card-icon">
                                    <md-icon title="Assignment History">supervised_user_circle</md-icon>
                                </div>
                            </md-card-header>

                            <md-card-content>
                                <time-line plain type="simple" v-if="shipmentDetails.shipmentUserHistory.length > 0">
                                    <time-line-item
                                        inverted
                                        :is-image="true"
                                        :image-src="
                                            shipmentUser.assignedToPhotoUrl
                                                ? shipmentUser.assignedToPhotoUrl
                                                : shipmentUser.assignedToCarrierLogoUrl
                                        "
                                        v-for="shipmentUser in shipmentDetails.shipmentUserHistory"
                                        :key="shipmentUser.actionDate"
                                    >
                                        <span
                                            slot="header"
                                            class="badge status-complete"
                                            v-if="shipmentUser.assignedToPublicUserId"
                                        >
                                            {{ shipmentUser.assignedToFullName }}
                                        </span>
                                        <span
                                            slot="header"
                                            class="badge status-complete"
                                            v-else-if="shipmentUser.assignedToCarrierTeamId"
                                        >
                                            {{ shipmentUser.assignedToCarrierCompany }}
                                        </span>

                                        <span
                                            slot="header"
                                            class="badge status-pending"
                                            v-if="
                                                !shipmentUser.assignedToPublicUserId &&
                                                    !shipmentUser.assignedToCarrierTeamId
                                            "
                                        >
                                            Unassigned
                                        </span>

                                        <span slot="headerTime" class="time">
                                            {{ shipmentUser.actionDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                                        </span>

                                        <h6 slot="footer">
                                            <i class="ti-time" />

                                            By {{ shipmentUser.assignedByFullName }}
                                        </h6>
                                    </time-line-item>
                                </time-line>
                                <p v-else class="grayedout-text">No Assignment History Available</p>
                            </md-card-content>
                        </md-card>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50">
                        <md-card>
                            <md-card-header class="md-card-header-icon md-card-header-blue">
                                <div class="card-icon icon-style">
                                    <md-icon title="Shipment Contents">medication_liquid</md-icon>
                                    Contents
                                </div>
                            </md-card-header>
                            <md-card-content>
                                <ShipmentContentsDisplay :contents="shipmentDetails.contents" />
                            </md-card-content>
                        </md-card>
                    </div>
                    <div
                        v-if="!isIndividualUser"
                        class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100"
                    >
                        <cost-rates-list
                            v-if="isTeamOwner || isAdmin"
                            :cost-lines="shipmentDetails.costLines"
                            :shipment-details="shipmentDetails"
                            title="Costs"
                            @updateShipmentDetails="getShipmentDetails"
                            :has-invoice="shipmentDetails.teamMemberInvoiceId !== null"
                            :rates-have-changed="updateCostsInBackground"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { handleRequests, getTeamRegionName, showErrorMessage } from '@/helpers';
import { GeneralMixin, CarrierMixin, RatesEngineMixin } from '@/mixins';
import { OrderItems } from '@/pages/Orders/components';
import {
    TimeLine,
    TimeLineItem,
    Notes,
    SendSmsButton,
    GenerateLabelButton,
    CostRatesList,
    ChargeRatesList,
    MapBoxOverlay,
    CloneJob
} from '@/components';
import CarrierBookingHistory from '@/components/JobOffers/CarrierBookingHistory';
import CarrierDownloadLabelButton from '@/components/JobOffers/CarrierDownloadLabelButton';
import { mapGetters } from 'vuex';
import marked from 'marked';
import { NOTE_TYPE_CONSTANTS, FEATURE_NAMES } from '@/utils/constants';
import { UpdateItemButton } from '@/pages/Inventory/buttons';
import { ItemLineModal, ShipmentContentsDisplay } from './components';
import { UpdateShipmentButton, ShipmentStatusButton, ReassignShipmentButton } from './buttons';
import { StopPdfDownloadButton } from '../Stops/buttons';

const signalR = require('@aspnet/signalr');

export default {
    name: 'ShipmentDetails',
    components: {
        TimeLine,
        TimeLineItem,
        UpdateShipmentButton,
        Notes,
        ShipmentStatusButton,
        ReassignShipmentButton,
        SendSmsButton,
        UpdateItemButton,
        GenerateLabelButton,
        StopPdfDownloadButton,
        CarrierBookingHistory,
        CarrierDownloadLabelButton,
        CostRatesList,
        ChargeRatesList,
        ShipmentContentsDisplay,
        OrderItems,
        MapBoxOverlay,
        CloneJob
    },
    mixins: [GeneralMixin, CarrierMixin, RatesEngineMixin],
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam',
            isLoading: 'isLoading',
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser',
            isIndividualUser: 'user/isIndividualUser',
            hasMarketplaceAccess: 'user/hasMarketplaceAccess',
            teamMemberSelfAllocationAllowed: 'user/teamMemberSelfAllocationAllowed',
            teamRegions: 'team/teamRegions',
            isTeamOwner: 'user/isTeamOwner',
            isCustomer: 'user/isCustomer',
            isSupportStaff: 'user/isSupportStaff',
            canCloneJobs: 'user/canCloneJobs'
        }),
        teamRegionName() {
            return getTeamRegionName(this.shipmentDetails?.teamRegionId, this.teamRegions);
        },
        isCarrierAssigned() {
            return this.shipmentDetails && this.shipmentDetails.carrierTeamId;
        },
        carrierTrackingUrl() {
            if (!this.isCarrierAssigned) 
                return null;
            if (this.shipmentDetails.assignedCarrierBooking?.trackingUrl)
                return this.shipmentDetails.assignedCarrierBooking?.trackingUrl;

            return null;
        },
        carrierLabels() {
            if (!this.isCarrierAssigned) 
                return [];
            if (
                this.shipmentDetails.assignedCarrierBooking?.labels &&
                this.shipmentDetails.assignedCarrierBooking.labels.length
            ) {
                if (this.shipmentDetails.assignedCarrierBooking.labels.some((x) => x.blobDownloadUrl))
                    return [...this.shipmentDetails.assignedCarrierBooking.labels.filter((x) => x.blobDownloadUrl)];
            }

            return [];
        }
    },
    async mounted() {
        this.$_handleLoaderState(true);
        this.loadCapacityList = this.user.vehicleCapacityUnitsConfiguration || [];

        this.shipmentCustomFieldList = this.user.shipmentCustomFieldDefinitions;
        await this.getShipmentDetails();
        await this.getTeamMembers();
        this.getOrders();
        this.$_handleLoaderState(false);

        if (this.isTeamOwner || this.isAdmin) {
            this.setupSignalR();
        }
    },
    data() {
        return {
            shipmentDetails: null,
            shipmentStatusHistory: [],
            shipmentId: null,
            status: null,
            selectedTeamMemberId: '',
            teamMembers: [],
            shouldShowAddNotesModal: false,
            shipmentCustomFieldList: [],
            customFieldValues: [],
            loadCapacityList: [],
            loadValues: {},
            isCarrierType: false,
            clickedHistory: {},
            selectedCharge: null,
            selectedCost: null,
            isAdmin: Boolean(this.$route.query.isAdmin),
            updateCostsInBackground: false,
            updateChargesInBackground: false,
            orderDetails: [],
            featureNames: FEATURE_NAMES
        };
    },
    methods: {
        handleAddItem() {
            this.$modal
                .show(ItemLineModal, {
                    shipmentId: this.shipmentDetails.shipmentId
                })
                .then((response) => {
                    this.getShipmentDetails();
                    this.$modal.hide();
                });
        },
        async getShipmentDetails() {
            try {
                const api = `/api/shipments/${
                    this.$route.params.shipmentId
                }?includeStatusHistory=true&includeAssignmentHistory=true&includeNotes=true&includeStopNotes=true&includePickupItemLines=true&includeCarrierBookingHistory=true`;
                const data = await handleRequests(api);
                this.shipmentDetails = data.data;
                this.shipmentStatusHistory = this.shipmentDetails.shipmentStatusHistory;
                document.title = `Locate2u - Shipment ${this.shipmentDetails.shipmentRef}`;
                if (this.shipmentCustomFieldList != null && this.shipmentCustomFieldList.length) {
                    this.customFieldValues = this.shipmentDetails.customFields;
                }

                if (this.shipmentDetails.assignedCarrierBooking != null) 
                    this.isCarrierType = true;

                if (this.loadCapacityList != null && this.loadCapacityList.length) {
                    this.loadValues = this.shipmentDetails.load;
                }
            } catch (error) {
                const message = 'Error in loading the details.';
                showErrorMessage(this, message, error);
            }
        },
        async getTeamMembers() {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS');
        },
        showHistoryInMap(history) {
            const { actionDate, oldStatus, newStatus } = history;
            if (!oldStatus) 
                return;

            this.clickedHistory = { status: newStatus.toLowerCase(), actionDate };
        },
        async handleAssignedUser(data) {
            await this.getShipmentDetails();
        },
        async handleChangedStatus({ status, oldStatus }) {
            this.$set(this.shipmentDetails, 'status', status);
            this.handleStatusHistoryUpdated(status, oldStatus);

            await this.getShipmentDetails();
        },
        handleStatusHistoryUpdated(newStatus, oldStatus) {
            this.shipmentStatusHistory.push({
                actionDate: moment(),
                changedByUserFullName: this.user.fullName,
                location: null,
                oldStatus,
                newStatus
            });
        },
        compiledMarkdown(note) {
            return marked(note, { sanitize: true });
        },
        async handleAddedShipmentNote() {
            await this.refreshNoteList();
        },
        async reloadDetails() {
            this.$_handleLoaderState(true);
            await this.getShipmentDetails();
            this.getOrders();
            this.$_handleLoaderState(false);
        },
        async refreshNoteList() {
            const api = `/api/shipments/${this.$route.params.shipmentId}/notes`;
            const result = await handleRequests(api);
            this.shipmentDetails.shipmentNotes = result.data;
        },
        notePhotoLoading(event) {
            event.target.src = '/img/loading-ico-60px.gif';
        },
        camelCaseToSentenceCase(text) {
            const result = text.replace(/([A-Z])/g, ' $1');
            return result.charAt(0).toUpperCase() + result.slice(1);
        },
        getBadgeColor(type) {
            const noteTypes = [...NOTE_TYPE_CONSTANTS];

            const result = noteTypes.find((noteType) => noteType.name === type);

            if (result) 
                return result.color;

            return 'pending';
        },
        async createTrackingLink() {
            // TODO: implement a global component of creating tracking links so that shipments and stops could share the component.
            const data = {
                shipmentId: this.shipmentDetails.shipmentId,
                type: 'LR',
                recipient: {
                    name: this.shipmentDetails.contact.name,
                    phone: this.shipmentDetails.contact.phone,
                    email: this.shipmentDetails.contact.email
                },
                teamMemberId: this.shipmentDetails.assignedTo ? this.shipmentDetails.assignedTo.publicUserId : null
            };

            const api = '/api/links';
            const payload = {
                method: 'post',
                data
            };

            try {
                const response = await handleRequests(api, payload);
                this.shipmentDetails.trackingLinkUrl = response.data.url;
                this.$notify({
                    message: 'Successfully created a tracking link.',
                    type: 'success'
                });
            } catch (e) {
                const message = 'Error in creating the tracking link.';
                showErrorMessage(this, message, e);
            }
        },
        setTrackingLinkUrl(trackingLink) {
            // this is to make url shorteners behave like a link
            if (trackingLink.includes('https://')) 
                return `${trackingLink}?isAdmin=true&showPOD=true`;

            return `https://${trackingLink}?isAdmin=true&showPOD=true`;
        },
        openInventoryItem(itemId) {
            const routeData = this.$router.resolve({ name: 'Inventory Details', params: { itemId } });
            window.open(routeData.href, '_blank');
        },
        async handleUpdateItem(item) {
            await this.reloadDetails();
        },
        async handleConfirmUnlink(itemId, deleteItem = false) {
            this.$_handleLoaderState(true, 'UPDATING...');

            try {
                const payload = {
                    method: 'post',
                    data: {
                        itemId,
                        stopIds: [this.shipmentDetails.pickupStop.stopId, this.shipmentDetails.dropStop.stopId],
                        deleteItem
                    }
                };
                const api = `/api/items/unlink`;
                await handleRequests(api, payload);

                await this.reloadDetails();

                if (deleteItem) {
                    this.$notify({
                        message: `Item is deleted!`,
                        type: 'success'
                    });
                } else {
                    this.$notify({
                        message: `Item unlinked!`,
                        type: 'success'
                    });
                }
            } catch (e) {
                const message = 'Cannot add item.';
                showErrorMessage(this, message);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async handleUnlinkItem(itemId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Unlink Item',
                    body: 'Are you sure you want to unlink this item?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmUnlink(itemId);
                });
        },
        async handleDeleteItem(itemId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Item',
                    body: 'Are you sure you want to delete this item?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmUnlink(itemId, true);
                });
        },
        async setupSignalR() {
            const userCrendentials = await window.auth.getUser();

            this.connection = new signalR.HubConnectionBuilder()
                .withUrl('/api/RatesEngineUpdateHub', {
                    accessTokenFactory: () => {
                        return userCrendentials.access_token;
                    }
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            try {
                await this.connection.start().then((result) => {
                    this.connection.invoke('JoinToTeamChannel');
                });

                this.connection.onclose(async () => {
                    await this.setupSignalR();
                });

                this.connection.on('ShipmentRatesUpdated', async (shipmentId, updateCosts, updateCharges) => {
                    this.handleShipmentRatesUpdated(shipmentId, updateCosts, updateCharges);
                });

                this.connection.on('ProcessRecalculationShipmentComplete', async (shipmentId) => {
                    this.handleShipmentRatesUpdated(shipmentId, true, true);
                });
            } catch (err) {
                setTimeout(this.setupSignalR, 5000);
            }
        },
        async handleShipmentRatesUpdated(shipmentId, updateCosts, updateCharges) {
            if (Number(shipmentId) === this.shipmentDetails.shipmentId) {
                // fetch the new rates
                try {
                    if (
                        this.shipmentDetails.teamMemberInvoiceId === null ||
                        this.shipmentDetails.teamMemberInvoiceId === undefined
                    ) {
                        this.updateCostsInBackground = Boolean(updateCosts);
                    }

                    if (
                        this.shipmentDetails.customerInvoiceId === null ||
                        this.shipmentDetails.customerInvoiceId === undefined
                    ) {
                        this.updateChargesInBackground = Boolean(updateCharges);
                    }

                    const endpoint = `/api/shipments/get-rates/${shipmentId}`;
                    const result = await handleRequests(endpoint);

                    if (this.updateCostsInBackground) {
                        this.shipmentDetails.costLines = result.data.costLines;
                    }

                    if (this.updateChargesInBackground) {
                        this.shipmentDetails.chargeLines = result.data.chargeLines;
                    }
                } finally {
                    this.updateCostsInBackground = false;
                    this.updateChargesInBackground = false;
                }
            }
        },
        showServicePackageDriver() {
            // eslint-disable-next-line no-bitwise
            if (this.shipmentDetails.assignedTo.applyRatesTo & this.getKeyByLabelInApplyRatesTo('Shipment')) {
                return true;
            }

            return false;
        },
        showServicePackageCustomer() {
            // eslint-disable-next-line no-bitwise
            if (this.shipmentDetails.customerApplyRatesTo & this.getKeyByLabelInApplyRatesTo('Shipment')) {
                return true;
            }

            return false;
        },
        async getOrders() {
            try {
                const api = `/api/shipments/${this.$route.params.shipmentId}/orders`;
                const data = await handleRequests(api);
                this.orderDetails = data.data;
            } catch (error) {
                const message = 'Error in loading the order details.';
                showErrorMessage(this, message, error);
            }
        }
    },
    beforeDestroy() {
        if (this.connection) {
            this.connection.invoke('RemoveFromTeamChannel');
        }
    }
};
</script>

<style lang="scss" scoped>
$status-pending: #c2907f;
$status-enroute: #4ed2ff;
$status-arrived: #2b93ff;
$status-departed: #ffb42b;
$status-cancelled: #ff5245;
$status-delayed: #384553;
$status-complete: #0bda8e;

.details-page {
    padding: 0 20px;

    ::v-deep .md-table-row:hover {
        background-color: #fafafa;
        cursor: pointer;
    }

    .details-box {
        // display: table;
        width: 100%;
        margin-bottom: 10px;
        label {
            display: block;
            font-size: 0.6875rem;
            color: #aaaaaa !important;
            font-weight: 400;
        }
        span {
            display: block;
            font-size: 14px !important;
            color: #3c4858;
            cursor: text;
            font-weight: 400;
        }

        span::selection {
            background-color: #2b93ff !important;
            color: #fff !important;
        }
        // > div {
        //     display: table-cell;
        //     vertical-align: top;
        // }

        .address-details {
            width: 50%;
        }
    }

    .action-buttons > div {
        display: inline-block;

        ::v-deep .status {
            height: 36px;
            display: block;
            border-radius: 2px;
            font-size: 12px;
            line-height: 27px;
        }

        ::v-deep .dropdown-menu {
            left: 10px;
        }
    }

    .booking-details {
        display: table;
        width: 100%;

        div {
            display: table-cell;
            vertical-align: middle;
        }

        .title {
            font-size: 18px;
        }

        .status-pending {
            background-color: $status-pending !important;
            color: #fff !important;
        }

        .status-pending:hover,
        .status-pending:focus {
            color: #fff !important;
        }

        .status-enroute-to-pickup {
            background-color: $status-enroute !important;
        }

        .status-arrived-at-pickup {
            background-color: $status-arrived !important;
        }

        .status-enroute-to-drop {
            background-color: $status-enroute !important;
        }

        .status-arrived-at-drop {
            background-color: $status-arrived !important;
        }

        .status-departed {
            background-color: $status-departed !important;
        }

        .status-picked-up {
            background-color: $status-complete !important;
        }

        .status-complete {
            background-color: $status-complete !important;
        }

        .status-delayed {
            background-color: $status-delayed !important;
        }

        .status-cancelled {
            background-color: $status-cancelled !important;
        }
    }

    ::v-deep .badge {
        display: inline-block;
        border-radius: 12px;
        padding: 5px 12px;
        text-transform: uppercase;
        font-size: 10px;
        color: #fff;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        position: initial;
        width: initial;
        height: initial;
        top: initial;
        right: initial;
        margin-top: initial;
        margin-right: initial;
    }

    .shipment-time-details {
        > div {
            display: inline-block;
            width: 200px;
        }
    }
    .grayedout-text {
        color: #aaa !important;
        -webkit-text-fill-color: #aaa !important;
    }

    ::v-deep .md-field.md-disabled:after {
        border-bottom: none;
    }

    ::v-deep .md-field:after {
        height: 0;
    }

    ::v-deep .md-field:before {
        background-color: initial !important;
    }

    .custom-md-field {
        margin-bottom: 25px;

        a {
            color: #0076f0;
        }
    }

    .details-box {
        a {
            color: #0076f0;
        }
    }

    .custom-label {
        font-size: 0.6875rem;
        color: #aaa;
    }

    ::v-deep .timeline.timeline-simple {
        margin-top: 0;
    }

    ::v-deep .md-card-timeline {
        margin-top: 0;
        margin-bottom: 0;
    }

    ::v-deep .timeline > li > .timeline-panel {
        padding: 5px 20px;
        background-color: #fafafa;
    }

    ::v-deep .timeline-panel > h6 > h6 {
        text-transform: none;
        margin-top: 0;
    }

    ::v-deep .timeline-panel > h6 {
        margin-top: 0px;
    }

    ::v-deep .timeline-heading {
        margin-bottom: 5px;
    }

    ::v-deep .timeline > li {
        margin-bottom: 5px;
    }

    ::v-deep .timeline > li > .timeline-panel:after,
    ::v-deep .timeline > li > .timeline-panel:before {
        border-left-color: #fafafa;
        border-right-color: #fafafa;
    }

    ::v-deep .timeline.timeline-simple > li > .timeline-panel {
        width: 83%;
    }

    ::v-deep .status {
        text-transform: uppercase;
        padding: 5px 20px;
        border-radius: 8px;
        margin-left: 10px;
    }

    ::v-deep .timeline-heading .time {
        margin-left: 10px;
        color: #333333;
        font-weight: 400;
        font-size: 0.75rem;
    }

    .shipment-notes {
        white-space: pre-line;
    }
}

.profile-image {
    height: 63px;
    width: 63px;
    padding: 0px !important;
    -webkit-box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 0, 0, 0.2) !important;

    img {
        border-radius: 3px;
    }
}

::v-deep .md-card-header-icon {
    z-index: 9;
}

.map-box ::v-deep .md-card-content {
    padding-top: 0;
    padding-bottom: 20px;
    margin-top: -22px;
}

.custom-chip {
    margin-top: 0px;
}

.assignment-history-time {
    margin-left: 0 !important;
}

.tracking-link-box {
    > div {
        width: 50%;
        display: inline-block;
    }

    a {
        cursor: pointer;
    }
}

.phone-sms {
    display: flex;

    > span {
        margin-left: 5px;
    }
}

.action-button {
    float: left;
}

.brand-logo {
    max-height: 50px;
    width: initial;
}

.brand-details {
    // >div {
    //     display:inline-block;
    // }
    // >div:first-child {
    //     width: 40%;
    //     min-width: 200px;
    // }
    // >div:last-child {
    //     width: 60%;
    // }
}

.custom-field-container {
    .details-box {
        display: inline-block;
        width: initial;
        vertical-align: top;
        min-width: 150px;
    }
}

.not-available {
    color: #aaa !important;
    font-style: italic;
}

.carrier-image-logo {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

.material-symbols-outlined {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' -25, 'opsz' 20;
}

.icon-style {
    color: white;
}
</style>
