<template>
    <div>
        <div v-if="order > 1" class="operator-wrapper">
            <form-group name="mainOperator" label="And/Or" attribute="And/Or">
                <md-select v-model="subCondition.mainOperator">
                    <md-option v-for="o in mainOperatorOptions" :value="o.key" :key="`${order}-main-op-${o.text}`">
                        {{ o.text }}
                    </md-option>
                </md-select>
            </form-group>
        </div>
        <span v-else class="operator-wrapper">&nbsp;</span>

        <!-- shipment, stop addresses, load, custom fields, top level fields, etc -->
        <div class="lg-condition-wrapper">
            <form-group
                name="variable"
                label="Variable"
                attribute="Variable"
                :class="{
                    error: validator.variable.$error
                }"
            >
                <md-select v-model="selectedVariable" @md-selected="selectedVariableChanged">
                    <template v-for="ungrp in conditionVariablesWithoutGroup">
                        <md-option
                            v-for="o in ungrp.options"
                            :value="o.dropdownValue"
                            :key="`${order}-var-ungrp-${o.parentObject}-${o.text}`"
                        >
                            {{ o.text }}
                        </md-option>
                    </template>
                    <template v-if="conditionVariablesWithGroup.length">
                        <md-optgroup
                            v-for="g in conditionVariablesWithGroup"
                            :label="g.group"
                            :key="`${order}-var-grp-${g.group}`"
                        >
                            <md-option
                                v-for="o in g.options"
                                :value="o.dropdownValue"
                                :key="`${order}-var-${g.group}-${o.parentObject}-${o.text}`"
                            >
                                {{ o.text }}
                            </md-option>
                        </md-optgroup>
                    </template>
                </md-select>
            </form-group>
        </div>
        <div class="lg-condition-wrapper">
            <form-group
                name="operator"
                label="Operator"
                attribute="Operator"
                :class="{
                    error: validator.operator.$error
                }"
            >
                <md-select v-model="subCondition.operator" :disabled="!subCondition.variable">
                    <md-option v-for="o in variableOperatorOptions" :value="o.key" :key="`${order}-operator-${o.text}`">
                        {{ o.text }}
                    </md-option>
                </md-select>
            </form-group>
        </div>
        <div
            name="name"
            :class="{
                error: validator.value.$error,
                'field-text': subCondition.dataType == 'Text',
                'checkbox-field': subCondition.dataType == 'CheckBox',
                'dropdown-field': subCondition.dataType == 'Dropdown',
                'textarea-custom-field': subCondition.dataType == 'MultiLineText',
                'lg-condition-wrapper-wider': subCondition.dataType === 'MultiSelect',
                'lg-condition-wrapper': subCondition.dataType !== 'MultiSelect'
            }"
        >
            <CustomFieldInputs
                v-if="subCondition.dataType !== 'MultiSelect' && subConditionFieldInput"
                :custom-field-definition="subConditionFieldInput"
                :initial-value="subConditionFieldInputValue"
                @changed="handleValueChanged"
                showDropdownLabelAs="label"
            />

            <form-group v-if="subCondition.dataType == 'MultiSelect'" name="TeamRegion" label="Team Region">
                <multiselect
                    data-testid="teamRegion-option"
                    v-model="tempMultiSelected"
                    :options="getChoices(subCondition.variable)"
                    :multiple="true"
                    :close-on-select="true"
                    placeholder="Select"
                    track-by="key"
                    label="text"
                ></multiselect>
            </form-group>
        </div>
        <div
            v-if="showValue2"
            class="lg-condition-wrapper"
            name="name"
            :class="{
                error: validator.value2.$error,
                'field-text': subCondition.dataType == 'Text',
                'checkbox-field': subCondition.dataType == 'CheckBox',
                'dropdown-field': subCondition.dataType == 'Dropdown',
                'textarea-custom-field': subCondition.dataType == 'MultiLineText'
            }"
        >
            <CustomFieldInputs
                :custom-field-definition="subConditionFieldInput2"
                :initial-value="subConditionFieldInputValue2"
                @changed="handleValue2Changed"
            />
        </div>
        <span v-else class="lg-condition-wrapper">&nbsp;</span>

        <md-button
            title="Remove sub-condition"
            class="md-danger md-just-icon md-round remove-button"
            @click.prevent="removeSubCondition"
        >
            <md-icon>close</md-icon>
        </md-button>
    </div>
</template>

<script>
import {
    stringDataType,
    intDataType,
    dateDataType,
    booleanDataType,
    dropdownDataType,
    multiSelectDataType
} from '@/utils/CustomActionConstants';
import { mainOperatorOptions, booleanOperatorOptions } from '@/utils/PlaceBookingConstants';
import { mapGetters } from 'vuex';
import { CustomFieldInputs } from '@/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import moment from 'moment';

export default {
    name: 'CustomConditionBuilderItem',
    mixins: [GeneralMixin],
    components: { CustomFieldInputs },
    props: {
        order: { type: Number, required: true },
        subCondition: { type: Object, default: () => {} },
        validator: { type: Object, required: true },
        conditionVariables: { type: Array, default: () => [] }
    },
    data() {
        return {
            subConditionFieldInput: { type: 'Text' },
            subConditionFieldInput2: { type: 'Text' },
            subConditionFieldInputValue: null,
            subConditionFieldInputValue2: null,
            mainOperatorOptions,
            booleanOperatorOptions,
            variableOperatorOptions: [],
            selectedVariable: null,
            latestTempKey: null, // to keep track of variable change
            prevTempKey: null, // to keep track of variable change
            tempMultiSelected: [],
            isInitialized: false
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        }),
        conditionVariablesWithoutGroup() {
            // top-level variables
            if (!this.conditionVariables || !this.conditionVariables.length) return [];

            return this.conditionVariables.filter((x) => !x.group);
        },
        conditionVariablesWithGroup() {
            if (!this.conditionVariables || !this.conditionVariables.length) return [];

            return this.conditionVariables.filter((x) => x.group && x.group.length > 0);
        },
        showValue2() {
            return this.subCondition?.operator === 'Between';
        },
        allConditionVariableOptions() {
            if (this.conditionVariables && this.conditionVariables.length) {
                const allVariables = this.conditionVariables.reduce((result, x) => result.concat(x.options), []);
                return allVariables;
            }
            return [];
        }
    },
    watch: {
        subCondition(newVal, oldVal) {
            if (!this.isInitialized) return;

            this.latestTempKey = newVal?.tempKey;
            this.prevTempKey = oldVal?.tempKey;
            // when sub-condition is removed
            this.updateControls();
            this.updateCustomFieldInputDefinition({ ...this.subCondition });
        },

        'subCondition.variable': {
            immediate: true,
            handler(newVal, oldVal) {
                if (!this.isInitialized) return;

                // reset the Operator and Value in order to display correct options and input control
                if (this.subCondition.tempKey !== this.prevTempKey) {
                    this.updateCustomFieldInputDefinition({ ...this.subCondition });
                } else {
                    this.updateCustomFieldInputDefinition({
                        ...this.subCondition,
                        operator: null,
                        value: null,
                        value2: null
                    });
                    this.$emit('subConditionChanged', {
                        ...this.subCondition,
                        operator: null,
                        value: null,
                        value2: null
                    });
                }
            }
        },
        tempMultiSelected(newVal) {
            if (newVal && Array.isArray(newVal) && newVal.length) {
                const keysOnly = newVal.map((x) => x.key);
                this.subCondition.value = JSON.stringify(keysOnly);
            } else {
                this.subCondition.value = null;
            }
        }
    },
    methods: {
        removeSubCondition() {
            this.$emit('subConditionRemoved', this.order);
        },
        selectedVariableChanged(dropdownValue) {
            this.populateCondition(dropdownValue);
        },
        populateCondition(dropdownValue) {
            if (dropdownValue) {
                const allVariables = this.conditionVariables.reduce((result, x) => result.concat(x.options), []);
                const variable = allVariables.find((x) => x.dropdownValue === dropdownValue);
                this.variableOperatorOptions = [...variable.dataType.operators];
                this.subCondition.dataType = variable.dataType.type;
                this.subCondition.variable = variable.key;
                this.subCondition.parentObject = variable.parentObject;
                this.subCondition.trueType = variable.trueType;
            }
        },
        updateControls() {
            if (this.subCondition) {
                const dataType = this.mapCustomFieldDataType(this.subCondition.dataType);
                this.variableOperatorOptions = [...dataType.operators];

                this.selectedVariable = this.subCondition.parentObject
                    ? `${this.subCondition.parentObject}-${this.subCondition.variable}`
                    : this.subCondition.variable;
            }
        },
        updateCustomFieldInputDefinition(subCon) {
            if (subCon) {
                if (subCon.dataType === 'MultiSelect') {
                    const keys = JSON.parse(subCon.value);
                    const choices = this.getChoices(subCon.variable);

                    if (keys && choices) {
                        const selected = choices.filter((x) => keys.some((k) => k === x.key));
                        this.tempMultiSelected = selected || [];
                    } else {
                        this.tempMultiSelected = [];
                    }
                }

                this.subConditionFieldInput = this.getSubConditionFieldInput('Value', subCon);
                this.subConditionFieldInput2 = this.getSubConditionFieldInput('2nd Value', subCon);
                this.subConditionFieldInputValue = subCon.value;
                this.subConditionFieldInputValue2 = subCon.value2;

                if (this.variableOperatorOptions.length === 1) {
                    this.subCondition.operator = this.variableOperatorOptions[0].key;
                }
            }
        },
        mapCustomFieldDataType(customFieldType) {
            switch (customFieldType) {
                case 'Date':
                    return dateDataType;
                case 'CheckBox':
                    return booleanDataType;
                case 'Number':
                    return intDataType;
                case 'Dropdown':
                    return dropdownDataType;
                case 'MultiSelect':
                    return multiSelectDataType;
                case 'MultiLineText':
                case 'Text':
                default:
                    return stringDataType;
            }
        },
        getChoices(variableKey) {
            const variable = this.allConditionVariableOptions.find((x) => x.key === variableKey);
            return variable?.choices || [];
        },
        getSubConditionFieldInput(label, subCon) {
            const fieldInput = {
                ...subCon,
                type: subCon.dataType ?? 'Text',
                name: subCon.variable,
                label,
                options: []
            };

            if (subCon.dataType === 'MultiSelect') {
                const choices = this.getChoices(subCon.variable);

                fieldInput.type = 'MultiSelect';
                fieldInput.options = choices.map((x) => {
                    return {
                        value: x.key,
                        label: x.text
                    };
                });
            } else if (subCon.dataType === 'Dropdown') {
                const choices = this.getChoices(subCon.variable);
                fieldInput.type = 'Dropdown';
                fieldInput.options = choices.map((x) => {
                    return {
                        value: x.key,
                        label: x.text
                    };
                });
            } else if (subCon.dataType === 'CheckBox') {
                fieldInput.type = 'Dropdown'; // to make it dropdown
                fieldInput.options = this.booleanOperatorOptions.map((x) => {
                    return {
                        value: x.key,
                        label: x.text
                    };
                });
            } else if (subCon.dataType === 'Number') {
                fieldInput.type = 'Text'; // the component does not support Number
            }
            return { ...fieldInput };
        },
        handleValueChanged({ name, value, type }) {
            let parsedValue = value;
            if (type === 'Date') {
                parsedValue = !moment(parsedValue).isValid() ? null : moment(parsedValue).format('YYYY-MM-DD');
            }

            this.$emit('subConditionChanged', { ...this.subCondition, value: parsedValue });
        },
        handleValue2Changed({ name, value, type }) {
            let parsedValue = value;
            if (type === 'Date') {
                parsedValue = !moment(parsedValue).isValid() ? null : moment(parsedValue).format('YYYY-MM-DD');
            }

            this.$emit('subConditionChanged', { ...this.subCondition, value2: parsedValue });
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.latestTempKey = this.subCondition?.tempKey;
            this.prevTempKey = this.subCondition?.tempKey;
            this.updateControls();
            this.updateCustomFieldInputDefinition(this.subCondition);
            this.isInitialized = true;
        });
    }
};
</script>

<style lang="scss" scoped>
::v-deep .form-wrapper {
    width: 100%;
}
.operator-wrapper {
    margin: 5px;
    width: 90px;
    padding: 2px;
    display: inline-block;
}

.lg-condition-wrapper {
    margin: 5px;
    width: 170px;
    padding: 2px;
    display: inline-block;
}

.lg-condition-wrapper-wider {
    margin: 5px;
    width: 300px;
    padding: 2px;
    display: inline-block;
}

.error {
    border: solid 1px red;
}

.md-button.remove-button {
    min-width: 24px;
    width: 24px;
    height: 24px;
    font-size: 12px;

    .md-icon.md-icon-font.md-theme-default {
        color: red !important;
    }
}
</style>
