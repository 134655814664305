<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ isUpdate ? 'Update' : 'Create' }} Run Schedule</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body stop-form-modal">
            <form-wrapper :validator="$v.runDetails" class="form-wrapper">
                <div class="stop-form-container">
                    <div class="md-layout run-item-layout">
                        <div class="md-layout-item md-size-70">
                            <form-group name="runName" label="Name">
                                <md-input type="text" v-model="runDetails.runName" />
                            </form-group>
                        </div>
                        <div class="md-layout-item md-size-30">
                            <form-group name="runnumber" label="Run Number">
                                <md-input type="number" v-model.number="runDetails.runNumber" min="1" />
                            </form-group>
                        </div>
                    </div>
                    <charging-type-options
                        :update-stop="true"
                        :selected-charge-type-id="runDetails.rateGroupId"
                        @selectedOption="handleRateTypeChanged"
                    />
                    <team-region-member-controls
                        :team-region-id="runDetails.teamRegionId"
                        :public-user-id="runDetails.assignToPublicUserId"
                        @onChanged="handleTeamRegionMemberChanged"
                        :mode="isUpdate ? 'update' : 'create'"
                    />
                    <div class="contact-details">
                        <customer-autocomplete
                            label="Customer"
                            id="autocustomer"
                            placeholder=""
                            :should-focus="false"
                            v-model="runDetails"
                            :update-mode="isUpdate"
                            :auto-fill-address="true"
                            @input="onCustomerChanged"
                            @changeCustomerRateGroup="onCustomerRateGroupIdChange"
                        />
                    </div>
                    <div>
                        <google-autocomplete
                            label="Start Address"
                            ref="startlocation"
                            id="startlocationId"
                            classname="form-control autocomplete-input"
                            placeholder=""
                            :should-focus="false"
                            name="runStartLocation.address"
                            v-model="runDetails.runStartLocation"
                            :disable-gps-coordinates="false"
                            gps-coordinate-placeholder="Enter a name for the start address"
                            @handleCoordinates="handleStartAddressCoordinates"
                            @placechanged="onStartLocationChanged"
                        ></google-autocomplete>
                    </div>
                    <div>
                        <google-autocomplete
                            name="runEndLocation.address"
                            ref="endlocation"
                            id="endlocationId"
                            v-model="runDetails.runEndLocation"
                            classname="form-control autocomplete-input"
                            label="End Address"
                            placeholder=""
                            :disable-gps-coordinates="false"
                            gps-coordinate-placeholder="Enter a name for the end address"
                            @handleCoordinates="handleEndAddressCoordinates"
                            @placechanged="onEndLocationChanged"
                        ></google-autocomplete>
                    </div>
                    <div class="md-layout run-item-layout">
                        <div class="md-layout-item md-size-50">
                            <form-group name="starttime" label="Start Time">
                                <time-picker
                                    :time="runDetails.startTime"
                                    :all-time-options="false"
                                    @selectedTime="setStartTime"
                                />
                            </form-group>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <form-group name="endtime" label="End Time">
                                <time-picker
                                    :time="runDetails.endTime"
                                    :all-time-options="false"
                                    @selectedTime="setEndTime"
                                />
                            </form-group>
                        </div>
                    </div>
                    <div class="md-layout run-item-layout">
                        <div class="md-layout-item md-size-50">
                            <form-group
                                name="startlocationstopdurationminutes"
                                label="Start Location Stop Duration (mins)"
                            >
                                <md-input
                                    type="number"
                                    v-model.number="runDetails.startLocationStopDurationMinutes"
                                    min="1"
                                />
                            </form-group>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <form-group name="endlocationstopdurationminutes" label="End Location Stop Duration (mins)">
                                <md-input
                                    type="number"
                                    v-model.number="runDetails.endLocationStopDurationMinutes"
                                    min="1"
                                />
                            </form-group>
                        </div>
                    </div>
                    <form-group label="Schedule" name="schedule">
                        <md-select v-model="runDetails.scheduleType">
                            <md-option v-for="scheduleType in scheduleTypes" :key="scheduleType" :value="scheduleType">
                                {{ scheduleType }}
                            </md-option>
                        </md-select>
                    </form-group>
                    <div class="date-picker-div hide-clear-button" v-if="runDetails.scheduleType == 'Fortnightly'">
                        <md-datepicker
                            name="activefromdate"
                            ref="mdDatePicker"
                            v-model="runDetails.activeFromDate"
                            md-immediately
                        >
                            <label>Start Date</label>
                        </md-datepicker>
                        <span v-if="runDetails.activeFromDate === null">
                            Start date is required.
                        </span>
                    </div>
                    <div
                        class="md-layout weekly-box"
                        v-if="['Weekly', 'Fortnightly'].includes(runDetails.scheduleType)"
                    >
                        <label>Every:</label>
                        <md-checkbox
                            v-model="scheduleWeeklyValue"
                            v-for="(day, index) in weekDays"
                            :key="day"
                            :value="index"
                        >
                            {{ day }}
                        </md-checkbox>
                    </div>
                    <form-group name="autocreatetripdaysinadvance" label="Auto Create Trip Days In Advance">
                        <md-input type="number" v-model.number="runDetails.autoCreateTripDaysInAdvance" min="1" />
                    </form-group>
                    <div class="md-layout run-item-layout" v-if="showCreateEmptyTrip(runDetails)">
                        <div class="md-layout-item">
                            <md-checkbox v-model="runDetails.createEmptyTrip">
                                Create empty trip
                            </md-checkbox>
                        </div>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateRunSchedule">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { WEEK_DAYS_CONSTANTS, RUN_SCHEDULE_TYPE_CONSTANTS, DATE_TYPES } from '@/utils/constants';
import { required } from 'vuelidate/lib/validators';
import {
    GoogleAutocomplete,
    TimePicker,
    CustomerAutocomplete,
    TeamRegionMemberControls,
    ChargingTypeOptions
} from '@/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    name: 'CreateRunScheduleModal',
    components: { GoogleAutocomplete, TimePicker, CustomerAutocomplete, TeamRegionMemberControls, ChargingTypeOptions },
    mixins: [GeneralMixin],
    props: {
        runDetails: {
            type: Object,
            default: () => {}
        },
        members: {
            type: Array,
            default: () => []
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        })
    },
    data() {
        return {
            weekDays: WEEK_DAYS_CONSTANTS,
            scheduleTypes: RUN_SCHEDULE_TYPE_CONSTANTS,
            scheduleWeeklyValue: [],
            startLocationName: 'startLocation',
            endLocationName: 'endLocation',
            useStartAddressCoordinates: false,
            useEndAddressCoordinates: false
        };
    },
    validations: {
        runDetails: {
            // eslint-disable-next-line func-names
            runName: {
                required
            }
        }
    },
    created() {
        this.cronExpressionExplainer(this.runDetails.schedule);
    },
    async mounted() {
        if (this.isSingleUser || this.isSingleTeamMember) {
            this.runDetails.assignToPublicUserId = this.user.publicUserId;
        }

        if (this.runDetails.activeFromDate != null) {
            this.runDetails.activeFromDate = moment(this.runDetails.activeFromDate).format(
                DATE_TYPES.internationalDate
            );
        } else {
            this.runDetails.activeFromDate = moment().format(DATE_TYPES.internationalDate);
        }
    },
    watch: {
        'runDetails.scheduleType': function(type) {
            if (!this.canCreateEmptyTrip(type)) 
                this.runDetails.createEmptyTrip = false;
        }
    },
    methods: {
        validateRunSchedule() {
            this.$v.runDetails.$touch();

            if (!this.$v.runDetails.$invalid) {
                if (this.isUpdate) {
                    this.updateRunDetails();
                } else {
                    this.createRun();
                }
            }
        },
        async createRun() {
            await this.mapRunDetails(this.runDetails);
            this.$_handleLoaderState(true, 'SAVING...');

            const api = '/api/run-schedules';
            const payload = {
                method: 'post',
                data: this.runDetails
            };

            try {
                const response = await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully created a run schedule.',
                    type: 'success'
                });
                this.$v.$reset();
                this.resolve({ ...response, code: 'ok' });
            } catch (e) {
                const message = 'Could not create a new run schedule.';
                showErrorMessage(this, message, e);
                this.resolve(false);
            }
        },
        async updateRunDetails() {
            this.$_handleLoaderState(true, 'UPDATING...');
            await this.mapRunDetails(this.runDetails);
            const api = `/api/run-schedules/${this.runDetails.runScheduleId}`;
            const payload = {
                method: 'put',
                data: this.runDetails
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully updated run schedule.',
                    type: 'success'
                });
                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Error in updating the run schedule';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        async mapRunDetails(runDetails) {
            if (this.scheduleWeeklyValue.length && ['Weekly', 'Fortnightly'].includes(this.runDetails.scheduleType)) {
                this.runDetails.schedule = `0 0 * * ${this.scheduleWeeklyValue.join(',')}`;
            } else {
                this.runDetails.schedule = null;
            }
            this.runDetails.name = runDetails.runName;
            if (runDetails.assignToPublicUserId === 0) {
                this.runDetails.assignToPublicUserId = '';
            }
            if (
                !runDetails.startAddress ||
                (runDetails.startLocation &&
                    (!runDetails.startLocation.latitude || !runDetails.startLocation.longitude))
            ) {
                this.runDetails.startLocation = null;
                this.runDetails.startAddress = '';
            }
            if (
                !runDetails.endAddress ||
                (runDetails.endLocation && (!runDetails.endLocation.latitude || !runDetails.endLocation.longitude))
            ) {
                this.runDetails.endLocation = null;
                this.runDetails.endAddress = '';
            }
            this.runDetails.startAddressName = this.runDetails.runStartLocation.name;
            this.runDetails.endAddressName = this.runDetails.runEndLocation.name;
            if (this.runDetails.autoCreateTripDaysInAdvance === '') {
                this.runDetails.autoCreateTripDaysInAdvance = null;
            }

            if (typeof this.runDetails.runNumber === 'string' && this.runDetails.runNumber.trim().length === 0) {
                this.runDetails.runNumber = null;
            }
        },

        resetRunState() {
            Object.assign(this.runDetails, RUN_DETAILS_DEFAULTS());
            this.$v.$reset();
        },
        onCustomerRateGroupIdChange(rateGroupId) {
            this.runDetails.rateGroupId = rateGroupId;
        },
        onCustomerChanged(data) {
            if (data.customerId && data.address) {
                this.runDetails.runStartLocation.address = data.address;
                this.runDetails.runStartLocation.location = data.location;
                this.runDetails.runStartLocation.name = data.name;
                this.runDetails.startLocation = data.location;
                this.runDetails.startAddress = data.address;
                this.runDetails.startAddressName = data.name;
            }
        },
        handleStartAddressCoordinates(value) {
            this.useStartAddressCoordinates = value;
            this.$v.$reset();
        },
        handleEndAddressCoordinates(value) {
            this.useEndAddressCoordinates = value;
            this.$v.$reset();
        },
        onStartLocationChanged(startLocationData) {
            this.runDetails.runStartLocation.location = startLocationData.location;
            this.runDetails.startLocation = startLocationData.location;
            this.runDetails.startAddress = startLocationData.address;
        },
        onEndLocationChanged(endLocationData) {
            this.runDetails.runEndLocation.location = endLocationData.location;
            this.runDetails.endLocation = endLocationData.location;
            this.runDetails.endAddress = endLocationData.address;
        },
        setStartTime(selectedTime) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;
                this.runDetails.startTime = appointmentTime;
            } else {
                this.runDetails.startTime = null;
            }
        },
        setEndTime(selectedTime) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;
                this.runDetails.endTime = appointmentTime;
            } else {
                this.runDetails.endTime = null;
            }
        },
        cronExpressionExplainer(schedule) {
            if (schedule) {
                const scheduleVal = schedule.split(' ');
                this.scheduleWeeklyValue = scheduleVal[4].split(',');
                this.scheduleWeeklyValue.forEach((day, index) => {
                    if (day.match(/[a-z]/i)) {
                        this.scheduleWeeklyValue[index] = Object.keys(this.weekDays).find(
                            (key) => this.weekDays[key].toLocaleLowerCase() === day.toLocaleLowerCase()
                        );
                    }
                });
            }
        },
        handleTeamRegionMemberChanged(val) {
            this.runDetails.teamRegionId = val.teamRegionId;
            this.runDetails.assignToPublicUserId = val.publicUserId || 0;
        },
        handleRateTypeChanged(rateRule) {
            this.runDetails.rateGroupId = rateRule?.rateGroupId ?? null;
        },
        canCreateEmptyTrip(type) {
            return ['Weekly', 'Fortnightly'].includes(type);
        },
        showCreateEmptyTrip(runDetails) {
            let noRunStops = true;
            if (this.isUpdate) 
                noRunStops = !runDetails.hasRunStops;
            return this.canCreateEmptyTrip(runDetails.scheduleType) && noRunStops;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}
::v-deep .timer-field {
    .time-picker-container .vs__selected {
        padding-left: 25px;
    }
    .md-icon {
        position: absolute;
    }
    .vs__clear {
        display: none;
    }
}
.run-item-layout {
    ::v-deep .md-layout-item {
        padding-left: 0px;
    }
}
.weekly-box {
    label {
        padding-top: 14px;
        margin-right: 13px;
    }
    .md-checkbox {
        width: 50px;
    }
    ::v-deep .md-checkbox-label {
        padding-left: 3px !important;
    }
}
.team-member {
    ::v-deep .vs__selected,
    ::v-deep input.vs__search {
        padding-left: 0px;
        margin-left: -2px;
    }
}
</style>
