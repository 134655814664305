var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-container" }, [
    _c(
      "div",
      { staticClass: "close-button" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-default md-just-icon md-round pull-right",
            on: { click: _vm.closeModal },
          },
          [_c("md-icon", [_vm._v("close")])],
          1
        ),
      ],
      1
    ),
    !_vm.loading && Object.keys(_vm.inventoryDetails).length > 0
      ? _c("div", [
          _c("div", { staticClass: "detail-section text-center" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.inventoryDetails.barcode)),
            ]),
            _c(
              "div",
              { staticClass: "detail-group text-center action-detail-group" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "md-button md-success md-just-icon md-round",
                    attrs: {
                      title: "View item details",
                      to: {
                        name: "Inventory Details",
                        params: { itemId: _vm.itemId },
                      },
                      target: "_blank",
                    },
                  },
                  [
                    _c("div", { staticClass: "md-ripple" }, [
                      _c(
                        "div",
                        { staticClass: "md-button-content" },
                        [_c("md-icon", [_vm._v("visibility")])],
                        1
                      ),
                    ]),
                  ]
                ),
                !_vm.isReadOnlyUser
                  ? _c("update-item-button", {
                      attrs: {
                        item: _vm.inventoryDetails,
                        "is-delivered":
                          _vm.inventoryDetails.status === "Delivered",
                        "stop-status":
                          _vm.inventoryDetails.lines.length > 0
                            ? _vm.inventoryDetails.lines.at(-1).stopStatus
                            : null,
                        "shipment-status":
                          _vm.inventoryDetails.lines.length > 0
                            ? _vm.inventoryDetails.lines.at(-1).shipmentStatus
                            : null,
                      },
                      on: { itemUpdated: _vm.handleUpdateItem },
                    })
                  : _vm._e(),
                !_vm.isReadOnlyUser
                  ? _c("delete-item-button", {
                      attrs: {
                        "item-id": _vm.inventoryDetails.itemId,
                        "is-shipment-attached":
                          _vm.inventoryDetails.lines &&
                          _vm.inventoryDetails.lines.length > 0 &&
                          _vm.inventoryDetails.lines.at(-1).shipmentId !== null,
                        "is-stop-attached":
                          _vm.inventoryDetails.lines &&
                          _vm.inventoryDetails.lines.length > 0,
                        "is-order-attached":
                          _vm.inventoryDetails.orderId !== null,
                      },
                      on: { itemDeleted: _vm.handleDeletedItem },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "line-separator" }),
          _c("div", { staticClass: "detail-section" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("Inventory Information"),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Type:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.inventoryDetails.type)),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Description:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.inventoryDetails.description)),
              ]),
            ]),
            _vm.inventoryDetails.sku
              ? _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("SKU:")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.inventoryDetails.sku)),
                  ]),
                ])
              : _vm._e(),
            _vm.inventoryDetails.productReference
              ? _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Product Reference:"),
                  ]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.inventoryDetails.productReference)),
                  ]),
                ])
              : _vm._e(),
            _vm.inventoryDetails.installationTimeInMinutes
              ? _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Installation Time (minutes):"),
                  ]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm.inventoryDetails.installationTimeInMinutes)
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.inventoryDetails.order
              ? _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Order Number:"),
                  ]),
                  _c(
                    "span",
                    { staticClass: "value" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Orders",
                              params: {
                                orderId: _vm.inventoryDetails.order.orderId,
                              },
                            },
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.inventoryDetails.order.orderNumber) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Warehouse:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  _vm._s(_vm.getWarehouseName(_vm.inventoryDetails.warehouseId))
                ),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Status:")]),
              _c(
                "span",
                { staticClass: "value" },
                [
                  _c("inventory-status-button", {
                    attrs: {
                      "item-status": _vm.inventoryDetails.status,
                      "item-id": _vm.inventoryDetails.itemId,
                      "inventory-details": _vm.inventoryDetails,
                    },
                    on: {
                      statusUpdated: function ($event) {
                        return _vm.statusUpdated()
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [
                _vm._v("Current Location:"),
              ]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.inventoryDetails.currentLocationName)),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Possesed by:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  _vm._s(
                    _vm.getFullNameFromTeamMemberId(_vm.inventoryDetails.userId)
                  )
                ),
              ]),
            ]),
            _vm.inventoryDetails.lines && _vm.inventoryDetails.lines.length > 0
              ? _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Stop:")]),
                  _c(
                    "span",
                    { staticClass: "value" },
                    [
                      !_vm.inventoryDetails.lines[
                        _vm.inventoryDetails.lines.length - 1
                      ].shipmentId
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Stop Details",
                                  params: {
                                    stopId:
                                      _vm.inventoryDetails.lines[
                                        _vm.inventoryDetails.lines.length - 1
                                      ].stopId,
                                  },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.inventoryDetails.lines[
                                      _vm.inventoryDetails.lines.length - 1
                                    ].stopRef
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Shipment Details",
                                  params: {
                                    shipmentId:
                                      _vm.inventoryDetails.lines[
                                        _vm.inventoryDetails.lines.length - 1
                                      ].shipmentId,
                                  },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.inventoryDetails.lines[
                                      _vm.inventoryDetails.lines.length - 1
                                    ].shipmentRef
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.stopLinesHistory
            ? _c("div", { staticClass: "line-separator" })
            : _vm._e(),
          _vm.stopLinesHistory
            ? _c(
                "div",
                { staticClass: "detail-section" },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Previous Stops / Shipments"),
                  ]),
                  _vm._l(_vm.stopLinesHistory, function (item) {
                    return _c("div", { key: item.stopLineId }, [
                      _c("div", { staticClass: "detail-group" }, [
                        _c(
                          "span",
                          { staticClass: "prev-shipment" },
                          [
                            !item.shipmentId
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Stop Details",
                                        params: { stopId: item.stopId },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item.stopRef) +
                                          "\n                            "
                                      ),
                                    ]),
                                    item.tripDate
                                      ? _c("span", { staticClass: "time" }, [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  item.tripDate,
                                                  _vm.DATE_TYPES.standardDate
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.publicUserId
                                      ? _c("span", { staticClass: "user" }, [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.getFullNameFromTeamMemberId(
                                                  item.publicUserId
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Shipment Details",
                                        params: { shipmentId: item.shipmentId },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item.shipmentRef) +
                                          "\n                            "
                                      ),
                                    ]),
                                    item.tripDate
                                      ? _c("span", { staticClass: "time" }, [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  item.tripDate,
                                                  _vm.DATE_TYPES.standardDate
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.publicUserId
                                      ? _c("span", { staticClass: "user" }, [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.getFullNameFromTeamMemberId(
                                                  item.publicUserId
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.inventoryDetails.order
            ? _c("div", { staticClass: "line-separator" })
            : _vm._e(),
          _vm.inventoryDetails.order
            ? _c(
                "div",
                { staticClass: "detail-section" },
                [
                  _c("span", { staticClass: "title" }, [_vm._v("Products")]),
                  _vm._l(
                    _vm.inventoryDetails.order.orderLines,
                    function (item) {
                      return _c("div", { key: item.orderLineId }, [
                        _c("div", { staticClass: "detail-group" }, [
                          _c("div", { staticClass: "prev-shipment" }, [
                            _c("span", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.productName) +
                                  "\n                        "
                              ),
                            ]),
                            item.productVariantSize
                              ? _c("span", [
                                  _vm._v(
                                    "- " + _vm._s(item.productVariantSize)
                                  ),
                                ])
                              : _vm._e(),
                            item.productVariantColour
                              ? _c("span", [
                                  _vm._v(
                                    "- " + _vm._s(item.productVariantColour)
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ])
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticClass: "line-separator" }),
          _c(
            "div",
            { staticClass: "detail-section" },
            [
              _c("span", { staticClass: "title" }, [_vm._v("Status History")]),
              _vm._l(_vm.inventoryDetails.itemStatusHistory, function (item) {
                return _c("div", { key: item.itemStatusHistoryId }, [
                  _c("div", { staticClass: "detail-group" }, [
                    _c("div", { staticClass: "custom-badge-div" }, [
                      _c(
                        "div",
                        {
                          class:
                            "custom-badge inventory-status-" +
                            item.newStatus.toLowerCase().replace(/\s+/g, ""),
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t("inputs.itemStatuses." + item.newStatus)
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "time",
                        attrs: { slot: "headerTime" },
                        slot: "headerTime",
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("dateTimeFormat")(
                                item.actionDate,
                                _vm.DATE_TYPES.standardDate
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]),
                  item.notes
                    ? _c("div", { staticClass: "detail-group" }, [
                        _c("span", { staticClass: "notes" }, [
                          _vm._v(_vm._s(item.notes)),
                        ]),
                      ])
                    : _vm._e(),
                ])
              }),
            ],
            2
          ),
          _c("div", { staticClass: "line-separator" }),
          _c(
            "div",
            { staticClass: "detail-section" },
            [
              _c("span", { staticClass: "title" }, [
                _vm._v("Movement History"),
              ]),
              _vm._l(_vm.inventoryDetails.itemMovementHistory, function (item) {
                return _c("div", { key: item.itemMovementHistoryId }, [
                  _c("div", { staticClass: "detail-group" }, [
                    _c("div", { staticClass: "custom-badge-div" }, [
                      _c(
                        "div",
                        {
                          class:
                            "custom-badge inventory-movement-" +
                            item.newLocationName
                              .toLowerCase()
                              .replace(/\s+/g, ""),
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "inputs.itemLocations." + item.newLocationName
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "time",
                        attrs: { slot: "headerTime" },
                        slot: "headerTime",
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("dateTimeFormat")(
                                item.actionDate,
                                _vm.DATE_TYPES.standardDate
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]),
                  item.notes
                    ? _c("div", { staticClass: "detail-group" }, [
                        _c("span", { staticClass: "notes" }, [
                          _vm._v(_vm._s(item.notes)),
                        ]),
                      ])
                    : _vm._e(),
                ])
              }),
            ],
            2
          ),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "sidebar-loader" },
            [
              _c("fade-loader", {
                attrs: { loading: _vm.loading, color: "#333333" },
              }),
              _c("span", [_vm._v("LOADING")]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }