<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Create Waiting Time Rule</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>

        <form-wrapper class="form-wrapper" :validator="$v.rateDetail">
            <div class="md-layout-item md-size-100">
                <rule-valid-days
                    :current-days="rateDetail.validForWeekDays"
                    :from-date="rateDetail.validFromDate"
                    :to-date="rateDetail.validToDate"
                    @daysSelected="handleDaysSeleceted"
                    ref="ruleValidDays"
                />
                <form-group name="includedWaitingTime" label="Included Waiting Time (Minutes)">
                    <md-input v-if="isUpdate && updatingRule" v-model="rateDetail.includedWaitingTime" />
                    <md-input v-if="!isUpdate && !updatingRule" v-model.number="rateDetail.includedWaitingTime" />
                </form-group>
                <form-group name="waitTimeDescription" label="Description">
                    <md-input v-model.trim="rateDetail.waitTimeDescription" />
                </form-group>
                <form-group name="ratePerHour" label="Rate Per Hour">
                    <md-input v-model.number="rateDetail.waitingTimeRatePerHour" />
                </form-group>
                <form-group name="WaitTimeRatePerInterval" label="Rate Per Interval">
                    <md-input v-model.number="rateDetail.waitTimeRatePerInterval" />
                </form-group>
                <form-group name="waitTimeInterval" label="Wait Time Interval (Minutes)">
                    <md-input v-if="isUpdate && updatingRule" v-model="rateDetail.waitTimeInterval" />
                    <md-input v-if="!isUpdate && !updatingRule" v-model.number="rateDetail.waitTimeInterval" />
                </form-group>
            </div>
        </form-wrapper>

        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="saveForm()">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { decimal, minLength, maxLength } from 'vuelidate/lib/validators';
/* eslint-disable import/extensions */
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { handleRequests, showErrorMessage } from '@/helpers';
import { RATE_CHARGING_TYPES } from '@/utils/constants';
import { RuleValidDays } from '@/components';
/* eslint-enable import/extensions */

export default {
    name: 'CreateWaitTimeRuleContent',
    components: {
        RuleValidDays
    },
    mixins: [GeneralMixin, RatesEngineMixin],
    props: {
        rateDetail: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        listLength: {
            type: Number,
            default: 0
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        customerId: {
            type: Number,
            default: null
        },
        rateGroupId: {
            type: Number,
            default: null
        },
        driverUserId: {
            type: Number,
            default: null
        },
        driverRuleCreation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            ruleData: {
                type: null,
                rule: null
            },
            rateChargingTypes: RATE_CHARGING_TYPES,
            localMessages: {
                required: '{attribute} is required.'
            },
            updatingRule: false
        };
    },
    validations: {
        rateDetail: {
            waitingTimeRatePerHour: {
                decimal
            },
            includedWaitingTime: {
                decimal
            },
            waitTimeRatePerInterval: {
                decimal
            },
            waitTimeInterval: {
                decimal
            },
            waitTimeDescription: {
                minLength: minLength(1),
                maxLength: maxLength(100)
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    mounted() {
        if (this.isUpdate) {
            this.updatingRule = true;
            this.rateDetail.includedWaitingTime = this.formatTimeSpan(this.rateDetail.includedWaitingTime);
            this.rateDetail.waitTimeInterval = this.formatTimeSpan(this.rateDetail.waitTimeInterval);
        }
    },
    methods: {
        validateData() {
            if (this.$v.$invalid) {
                return false;
            }

            if (
                this.$v.rateDetail.waitingTimeRatePerHour.$model === null &&
                this.$v.rateDetail.includedWaitingTime.$model === null &&
                this.$v.rateDetail.waitTimeRatePerInterval.$model === null &&
                this.$v.rateDetail.waitTimeInterval.$model === null
            ) {
                return false;
            }

            return true;
        },
        formatTimeSpan(time) {
            let timeCopy = time;
            if (timeCopy === null || timeCopy === '00:00:00') 
                return null;

            let [day] = timeCopy.split('.').map(Number);

            if (day >= 1) {
                timeCopy = timeCopy.substring(timeCopy.indexOf('.') + 1);
            } else {
                day = 0;
            }

            const [hours, minutes] = timeCopy.split(':').map(Number);
            const totalMinutes = day * 24 * 60 + hours * 60 + minutes;

            if (totalMinutes === 0) {
                return null;
            }

            return totalMinutes;
        },
        async saveForm() {
            const ruleDaysValid = this.$refs.ruleValidDays.validate();
            if (!ruleDaysValid) {
                return;
            }

            if (this.isUpdate) {
                this.updateRuleDetails();
                return;
            }

            if (!this.validateData()) {
                showErrorMessage(this, 'Rule must have valid data', null);
                return;
            }

            this.updatingRule = false;

            this.rateDetail.enableIncludedWaitingTime =
                this.$v.rateDetail.waitingTimeRatePerHour.$model !== null ||
                this.$v.rateDetail.waitTimeRatePerInterval.$model !== null;

            this.$_handleLoaderState(true, 'PROCESSING...');

            const type = this.$route.params.type
                .toLowerCase()
                .split('-')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
                .toLowerCase();

            const chargeType = this.rateChargingTypes.find((x) => x.key.toLowerCase() === type);

            const obj = this.$_getRateRuleData(
                chargeType,
                this.user.publicUserId,
                this.rateDetail,
                this.customerId,
                this.listLength,
                this.rateGroupId,
                this.driverRuleCreation,
                this.driverUserId
            );

            const endpoint = '/api/rates/create';
            const payload = {
                method: 'post',
                data: obj
            };

            try {
                const response = await handleRequests(endpoint, payload);

                if (response.status === 200) {
                    this.$notify({
                        message: 'Successfully created rule.',
                        type: 'success'
                    });

                    this.resolve({
                        record: response.data
                    });
                } else {
                    showErrorMessage(this, 'Failed creating rule.', null);
                }
            } catch (error) {
                showErrorMessage(this, error.data, null);
            }

            this.$_handleLoaderState(false);
        },
        async updateRuleDetails() {
            if (!this.validateData()) {
                showErrorMessage(this, 'Rule must have valid data', null);
                return;
            }

            this.$_handleLoaderState(true, 'UPDATING...');

            this.rateDetail.includedWaitingTime = this.rateDetail.includedWaitingTime;
            this.rateDetail.waitTimeInterval = this.rateDetail.waitTimeInterval;

            const rateId = this.rateDetail.rateRulesId;
            this.rateDetail.condition = null;
            this.rateDetail.currency = null;
            this.rateDetail.priority = null;
            this.rateDetail.rateRulesId = null;

            const obj = {
                rateRulesId: rateId,
                rule: JSON.stringify(this.rateDetail)
            };

            const endpoint = '/api/rates/updateRate';
            const payload = {
                method: 'post',
                data: obj
            };

            try {
                const response = await handleRequests(endpoint, payload);

                if (response.status === 200) {
                    this.$notify({
                        message: 'Successfully updated rule.',
                        type: 'success'
                    });

                    this.resolve({
                        record: response.status
                    });
                } else {
                    showErrorMessage(this, 'Failed updating rule.', null);
                }
            } catch (error) {
                showErrorMessage(this, 'Failed updating rule.', null);
            }

            this.$_handleLoaderState(false);
        },
        handleDaysSeleceted(value) {
            this.rateDetail = Object.assign(this.rateDetail, value);
        }
    }
};
</script>

<style lang="scss" scoped>
.rate-form-container {
    padding-top: 20px;
    max-height: 80vh;
    padding-right: 10px;
}

::v-deep .form-wrapper {
    width: 100%;
}

.md-switch-container {
    .md-checked {
        ::v-deep .md-switch-container {
            background-color: #2b93ff !important;
        }

        ::v-deep .md-switch-thumb {
            border: 1px solid #2b93ff !important;
        }
    }
}
</style>
