<template>
    <div class="modal-container" :class="{ 'wide-container': isWideContainer(customActionModel.action) }">
        <div class="modal-header">
            <h4 id="createEmailActionModalTitle" class="modal-title">{{ isUpdate ? 'Update' : 'Create' }} Action</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.customActionModel" class="form-wrapper">
                <form-group name="action" label="Action Type">
                    <md-select id="action-type-select" v-model="customActionModel.action" disabled>
                        <md-option v-for="item in actionTypeOptions" :key="item.value" :value="item.value">
                            {{ item.text }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group name="eventTrigger" label="Event Trigger">
                    <md-select id="eventTrigger-select" v-model="customActionModel.eventTrigger" :disabled="isUpdate">
                        <md-option
                            v-for="item in filteredEventTriggers"
                            :key="item.displayText"
                            :value="item.eventTrigger"
                        >
                            {{ item.displayText }}
                        </md-option>
                    </md-select>
                </form-group>
                <keep-alive>
                    <component
                        v-if="isInitialized"
                        :is="actionComponent"
                        :validator="$v.customActionModel"
                        :custom-action-model="customActionModel"
                        :is-update="isUpdate"
                        :condition-variables="conditionVariables"
                    ></component>
                </keep-alive>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button id="btnUpdateCustomAction" class="dialog-button md-primary" @click.prevent="validate">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import {
    TEAM_CUSTOM_ACTION_EVENT_TRIGGERS,
    TEAM_CUSTOM_ACTION_TYPE_OPTIONS,
    stringDataType,
    intDataType,
    dateDataType,
    booleanDataType
} from '@/utils/CustomActionConstants';
import { teamRegionIdOption, customerIdOption } from '@/utils/PlaceBookingConstants';
import { showErrorMessage } from '@/helpers';
import { required, requiredIf, minLength, helpers } from 'vuelidate/lib/validators';
import { timeSpanMinValue, timeSpanMaxValue } from '@/utils/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

const ManageEmailAction = () => import('./Email/ManageEmailAction');
const ManageSmsAction = () => import('./Sms/ManageSmsAction');
const GetQuotesFromCarriers = () => import('./GetQuotesFromCarriers/GetQuotesFromCarriers');
const PlaceCarrierBooking = () => import('./PlaceCarrierBooking/PlaceCarrierBooking');

const numberIf = helpers.withParams({ type: 'numberIf' }, (value, siblings) => {
    return !helpers.req(value) || siblings.dataType !== 'Number' || !Number.isNaN(Number(value));
});

const dateIf = helpers.withParams({ type: 'dateIf' }, (value, siblings) => {
    return !helpers.req(value) || siblings.dataType !== 'Date' || new Date(value) > 0;
});

const minValueIf = helpers.withParams({ type: 'minValueIf' }, (value, siblings) => {
    let result = !helpers.req(value) || (siblings.dataType !== 'Date' && siblings.dataType !== 'Number');

    if (value) {
        if (siblings.dataType === 'Date') {
            result = result || moment(value) >= moment(siblings.value);
        } else if (siblings.dataType === 'Number') {
            result = result || +value >= +siblings.value;
        }
    }
    return result;
});

export default {
    name: 'CreateCustomAction',
    mixins: [GeneralMixin],
    props: {
        customActionModel: { type: Object, default: () => null },
        isUpdate: { type: Boolean, default: false },
        resolve: { type: Function, default: () => {} }
    },
    data() {
        return {
            eventTriggers: TEAM_CUSTOM_ACTION_EVENT_TRIGGERS,
            actionTypeOptions: TEAM_CUSTOM_ACTION_TYPE_OPTIONS,
            teamRegionIdOption,
            customerIdOption,
            conditionVariables: [],
            isInitialized: false
        };
    },
    validations() {
        if (this.customActionModel.action === 'Email') {
            return {
                customActionModel: {
                    action: { required },
                    eventTrigger: { required },
                    filter: {
                        status: {
                            required: requiredIf(() => {
                                return (
                                    this.currentEventTrigger?.smsConfig?.filters?.statuses &&
                                    Array.isArray(this.currentEventTrigger?.smsConfig?.filters?.statuses) &&
                                    this.currentEventTrigger?.smsConfig?.filters?.statuses.length
                                );
                            })
                        }
                    },
                    settings: {
                        timeDelay: {
                            delay: {
                                required: requiredIf(() => {
                                    return (
                                        this.customActionModel.settings.timeDelay.type === 'Delayed' &&
                                        (this.customActionModel.settings.timeDelay.delay === '0.00:00:00' ||
                                            !this.customActionModel.settings.timeDelay.delay)
                                    );
                                }),
                                timeSpanMinValue: timeSpanMinValue('0.00:1:00'),
                                timeSpanMaxValue: timeSpanMaxValue('30.23:59:59')
                            },
                            specificTime: {
                                required: requiredIf(() => {
                                    return this.customActionModel.settings.timeDelay.type === 'Specified';
                                })
                            }
                        },
                        emailTemplateId: {
                            required: requiredIf(() => {
                                return !this.customActionModel.settings.useSystemTemplate;
                            })
                        },
                        emailRecipientTypes: { required, minLength: minLength(1) },
                        recipientEmailAddresses: {
                            required: requiredIf(() => {
                                return (
                                    this.customActionModel.settings.emailRecipientTypes &&
                                    this.customActionModel.settings.emailRecipientTypes.includes('Specified')
                                );
                            })
                        },
                        conditions: {
                            $each: {
                                name: { required },
                                subConditions: {
                                    $each: {
                                        dataType: { required },
                                        variable: { required },
                                        operator: { required },
                                        value: {
                                            required,
                                            isNumber: numberIf,
                                            isDate: dateIf
                                        },
                                        value2: {
                                            required: requiredIf((o) => {
                                                return o.operator === 'Between';
                                            }),
                                            number: numberIf,
                                            date: dateIf,
                                            isHigherThanFirstValue: minValueIf
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            };
        }

        if (this.customActionModel.action === 'Sms') {
            return {
                customActionModel: {
                    action: { required },
                    eventTrigger: { required },
                    filter: {
                        status: {
                            required: requiredIf(() => {
                                return (
                                    this.currentEventTrigger?.smsConfig?.filters?.statuses &&
                                    Array.isArray(this.currentEventTrigger?.smsConfig?.filters?.statuses) &&
                                    this.currentEventTrigger?.smsConfig?.filters?.statuses.length
                                );
                            })
                        },
                        stopTypes: {
                            required: requiredIf(() => {
                                return this.showStopTypeFilter;
                            })
                        }
                    },
                    settings: {
                        timeDelay: {
                            delay: {
                                required: requiredIf(() => {
                                    return (
                                        this.customActionModel.settings.timeDelay.type === 'Delayed' &&
                                        (this.customActionModel.settings.timeDelay.delay === '0.00:00:00' ||
                                            !this.customActionModel.settings.timeDelay.delay)
                                    );
                                }),
                                timeSpanMinValue: timeSpanMinValue('0.00:1:00'),
                                timeSpanMaxValue: timeSpanMaxValue('30.23:59:59')
                            },
                            specificTime: {
                                required: requiredIf(() => {
                                    return this.customActionModel.settings.timeDelay.type === 'Specified';
                                })
                            }
                        },
                        smsRecipientTypes: { required, minLength: minLength(1) },
                        recipientSmsNumbers: {
                            required: requiredIf(() => {
                                return (
                                    this.customActionModel.settings.smsRecipientTypes &&
                                    this.customActionModel.settings.smsRecipientTypes.includes('Specified')
                                );
                            })
                        },
                        templateName: {
                            required: requiredIf(() => {
                                return this.customActionModel.eventTrigger === 'STOP-EVENT-MANUAL-TRIGGERED';
                            })
                        },
                        smsTemplateContent: { required },
                        conditions: {
                            $each: {
                                name: { required },
                                subConditions: {
                                    $each: {
                                        dataType: { required },
                                        variable: { required },
                                        operator: { required },
                                        value: {
                                            required,
                                            isNumber: numberIf,
                                            isDate: dateIf
                                        },
                                        value2: {
                                            required: requiredIf((o) => {
                                                return o.operator === 'Between';
                                            }),
                                            number: numberIf,
                                            date: dateIf,
                                            isHigherThanFirstValue: minValueIf
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            };
        }

        if (this.customActionModel.action === 'GetQuotesFromCarriers') {
            return {
                customActionModel: {
                    action: { required },
                    eventTrigger: { required },
                    settings: {
                        requestQuote: { required }
                    }
                }
            };
        }

        if (this.customActionModel.action === 'PlaceCarrierBooking') {
            return {
                customActionModel: {
                    action: { required },
                    eventTrigger: { required },
                    settings: {
                        preferredQuote: { required },
                        conditions: {
                            $each: {
                                name: { required },
                                assignTo: { required },
                                assignToOption: { required },
                                subConditions: {
                                    $each: {
                                        dataType: { required },
                                        variable: { required },
                                        operator: { required },
                                        value: {
                                            required,
                                            isNumber: numberIf,
                                            isDate: dateIf
                                        },
                                        value2: {
                                            required: requiredIf((o) => {
                                                return o.operator === 'Between';
                                            }),
                                            number: numberIf,
                                            date: dateIf,
                                            isHigherThanFirstValue: minValueIf
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            };
        }

        return {
            customActionModel: {}
        };
    },
    computed: {
        ...mapGetters('team', ['teamRegions', 'customers']),
        ...mapGetters({
            user: 'user/user'
        }),
        filteredEventTriggers() {
            return this.eventTriggers
                .filter((x) => x.supportedActions.includes(this.customActionModel.action))
                .sort((a, b) => (a.displayText > b.displayText ? 1 : -1));
        },
        currentEventTrigger() {
            if (!this.customActionModel.eventTrigger) return null;

            return this.eventTriggers.find((x) => x.eventTrigger === this.customActionModel.eventTrigger);
        },
        actionComponent() {
            if (this.customActionModel) {
                if (this.customActionModel.action === 'Email') return ManageEmailAction;

                if (this.customActionModel.action === 'Sms') return ManageSmsAction;

                if (this.customActionModel.action === 'GetQuotesFromCarriers') return GetQuotesFromCarriers;

                if (this.customActionModel.action === 'PlaceCarrierBooking') return PlaceCarrierBooking;
            }

            return null;
        },
        allowedConditions() {
            if (
                this.customActionModel.action === 'Email' &&
                this.currentEventTrigger?.emailConfig?.allowedConditions &&
                this.currentEventTrigger.emailConfig.allowedConditions.length
            ) {
                return [...this.currentEventTrigger.emailConfig.allowedConditions];
            }

            if (
                this.customActionModel.action === 'Sms' &&
                this.currentEventTrigger?.smsConfig?.allowedConditions &&
                this.currentEventTrigger.smsConfig.allowedConditions.length
            ) {
                return [...this.currentEventTrigger.smsConfig.allowedConditions];
            }

            return [];
        }
    },
    watch: {
        currentEventTrigger() {
            this.populateVariables();
        }
    },
    methods: {
        ...mapActions('custom-actions', ['ADD_EVENT_TRIGGER', 'UPDATE_EVENT_TRIGGER']),
        async validate() {
            this.$v.customActionModel.$touch();

            if (this.isUpdate) {
                await this.update();
            } else {
                await this.create();
            }
        },
        async create() {
            this.$v.customActionModel.$touch();
            if (this.$v.customActionModel.$invalid) return;

            this.$_handleLoaderState(true, 'CREATING EVENT TRIGGER...');

            try {
                await this.ADD_EVENT_TRIGGER({ data: this.customActionModel });

                this.$notify({
                    message: 'Successfully created an event trigger.',
                    type: 'success'
                });

                this.resolve({ result: 'ok' });
            } catch (e) {
                const message = 'Could not create event trigger.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        async update() {
            this.$v.customActionModel.$touch();
            if (this.$v.customActionModel.$invalid) return;

            this.$_handleLoaderState(true, 'UPDATING EVENT TRIGGER...');

            try {
                await this.UPDATE_EVENT_TRIGGER({ data: this.customActionModel });

                this.$notify({
                    message: 'Successfully updated an event trigger.',
                    type: 'success'
                });

                this.resolve({ result: 'ok' });
            } catch (e) {
                const message = 'Could not update event trigger.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        isWideContainer(action) {
            return action === 'Email' || action === 'Sms' || action === 'PlaceCarrierBooking';
        },
        isAllowedCondition(variableKey) {
            return this.allowedConditions.some((allowed) => allowed === 'ALL' || allowed === variableKey);
        },
        populateVariables() {
            // put them in 1 group (in future: double check the logic esp when adding variables with parentObject)

            this.conditionVariables = [];

            if (!this.allowedConditions.length) return;

            // check all variables/ options if supported
            let allOptions = [];
            if (this.isAllowedCondition('CustomerId')) {
                allOptions = this.addCustomerVariables();
            }

            if (this.isAllowedCondition('TeamRegionId')) {
                allOptions = allOptions.concat(this.addTeamRegionVariables());
            }

            // without groupings
            if (allOptions.length) {
                this.conditionVariables.push({
                    group: null,
                    options: allOptions
                });
            }

            if (this.isAllowedCondition('StopCustomFields')) {
                const shCustVars = this.addStopCustomFieldVariables();

                if (shCustVars.length) {
                    this.conditionVariables.push({
                        group: 'Stop Custom Fields',
                        options: shCustVars
                    });
                }
            }

            if (this.isAllowedCondition('ShipmentCustomFields')) {
                const shCustVars = this.addShipmentCustomFieldVariables();

                if (shCustVars.length) {
                    this.conditionVariables.push({
                        group: 'Shipment Custom Fields',
                        options: shCustVars
                    });
                }
            }
        },
        addTeamRegionVariables() {
            const choices = this.teamRegions.map((tr) => {
                return { key: tr.teamRegionId, text: tr.name };
            });

            const options = [
                {
                    ...teamRegionIdOption,
                    choices
                }
            ];

            return options;
        },
        addCustomerVariables() {
            const choices = this.customers.map((tr) => {
                return { key: parseInt(tr.key, 10), text: tr.value };
            });

            const options = [
                {
                    ...customerIdOption,
                    choices
                }
            ];

            return options;
        },
        addStopCustomFieldVariables() {
            const stopCustomFieldList = this.user.stopCustomFieldDefinitions;

            if (stopCustomFieldList && Array.isArray(stopCustomFieldList)) {
                const options = stopCustomFieldList.map((x) => {
                    return {
                        parentObject: 'CustomFields',
                        key: x.name,
                        text: x.label,
                        dataType: this.mapCustomFieldDataType(x.type),
                        dropdownValue: `CustomFields-${x.name}`
                    };
                });

                return options;
            }

            return [];
        },
        addShipmentCustomFieldVariables() {
            const shipmentCustomFieldList = this.user.shipmentCustomFieldDefinitions;

            if (shipmentCustomFieldList && Array.isArray(shipmentCustomFieldList)) {
                const options = shipmentCustomFieldList.map((x) => {
                    return {
                        parentObject: 'CustomFields',
                        key: x.name,
                        text: x.label,
                        dataType: this.mapCustomFieldDataType(x.type),
                        dropdownValue: `CustomFields-${x.name}`
                    };
                });

                return options;
            }

            return [];
        },
        mapCustomFieldDataType(customFieldType) {
            switch (customFieldType) {
                case 'Date':
                    return dateDataType;
                case 'CheckBox':
                    return booleanDataType;
                case 'Number':
                    return intDataType;
                case 'MultiLineText':
                case 'Text':
                default:
                    return stringDataType;
            }
        }
    },
    mounted() {
        if (!this.isUpdate && this.eventTriggers && this.eventTriggers.length === 1) {
            this.customActionModel.eventTrigger = this.eventTriggers[0].eventTrigger;
        }

        this.populateVariables();
        this.isInitialized = true;
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-checkbox-container {
    top: 10px;
}

::v-deep .md-checkbox-label {
    opacity: 1 !important;
    color: black !important;
}

::v-deep div.md-field.no-underline:after {
    height: 0px;
}

::v-deep .md-checkbox {
    margin-top: 0px;
}

.wide-container {
    max-width: 1080px !important;
    width: 100%;
}

::v-deep .modal-body {
    max-height: 600px;
    overflow: hidden;
}
</style>
