<template>
    <div class="area-fence map-panel" v-if="!loadShapesOnly">
        <div class="floating-service-area">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-black">
                    <div class="card-icon">
                        <md-icon>my_location</md-icon>
                    </div>
                    <md-button
                        title="Turn On/Off preview on the map screen"
                        class="md-primary md-just-icon md-round md-card-header-action-button"
                        @click="handleServiceAreaEntireMapPreview"
                        v-if="selectedTab === 'areaList'"
                    >
                        <md-icon>
                            {{ toggleMainPreviewList ? 'visibility_off' : 'visibility' }}
                        </md-icon>
                    </md-button>
                </md-card-header>
                <md-card-content>
                    <div v-if="selectedTab === 'areaList'">
                        <md-table class="area-list-table">
                            <md-table-row>
                                <md-table-head>Name</md-table-head>
                                <md-table-head>Actions</md-table-head>
                            </md-table-row>

                            <md-table-row
                                v-for="serviceArea in areaList"
                                :key="serviceArea[idName]"
                                :class="serviceArea[idName] == [idName] ? 'highlight-item' : ''"
                            >
                                <md-table-cell
                                    :class="
                                        (selectedArea == serviceArea[idName] ? 'selected-area-highlight ' : '') +
                                            ' service-area-table-name'
                                    "
                                >
                                    <div @click="zoomToFence(serviceArea[idName])">
                                        <span v-if="serviceArea.suburbLocalities.length == 0">
                                            <md-icon>polyline</md-icon>
                                            <md-tooltip>This {{ title }} was drawn using the polyline</md-tooltip>
                                        </span>
                                        <span v-else>
                                            <md-icon>playlist_add</md-icon>
                                            <md-tooltip>
                                                This {{ title }} was created from the list of suburbs
                                            </md-tooltip>
                                        </span>
                                        {{ serviceArea.name }}
                                    </div>
                                </md-table-cell>
                                <md-table-cell class="service-area-table-actions">
                                    <md-button
                                        title="Edit service area"
                                        class="md-warning md-just-icon md-round action-button"
                                        @click="handleEditServiceArea(serviceArea[idName])"
                                    >
                                        <md-icon>edit</md-icon>
                                    </md-button>
                                    <md-button
                                        title="Toggle service area visibility"
                                        class="md-primary md-just-icon md-round action-button"
                                        @click="handleServiceAreaPreview(serviceArea[idName])"
                                    >
                                        <md-icon>
                                            {{ serviceArea.areaVisible ? 'visibility' : 'visibility_off' }}
                                        </md-icon>
                                    </md-button>
                                    <md-button
                                        title="Delete service area"
                                        class="md-danger md-just-icon md-round action-button"
                                        @click="handleDeleteServiceArea(serviceArea[idName])"
                                    >
                                        <md-icon>delete</md-icon>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <md-button class="md-primary md-block service-area-add-button" @click="handleAddNewServiceArea">
                            ADD NEW SERVICE AREA
                        </md-button>
                    </div>
                    <div v-else-if="selectedTab === 'create' || selectedTab === 'search'" class="create-service-area">
                        <div class="md-layout">
                            <div class="md-layout-item md-size-100" v-if="selectedTab === 'search' && listLevel === 1">
                                <div class="select-state-title">
                                    Select a state
                                </div>
                            </div>

                            <div
                                :class="[
                                    {
                                        'md-layout-item': true,
                                        'md-size-100': selectedTab === 'create',
                                        'side-panel': selectedTab === 'search'
                                    }
                                ]"
                            >
                                <div class="md-layout">
                                    <div
                                        :class="[
                                            {
                                                'md-size-50': selectedTab === 'create',
                                                'md-layout-item side-panel-button-container': true
                                            }
                                        ]"
                                    >
                                        <button
                                            @click="handleDrawOnMap"
                                            :class="[
                                                {
                                                    'selected-side-panel-button': selectedTab === 'create',
                                                    'side-panel-left-button': selectedTab === 'create'
                                                }
                                            ]"
                                            :disabled="hasSelectedDrawMode"
                                        >
                                            <div>
                                                <md-icon>draw</md-icon>
                                            </div>
                                            <div>
                                                DRAW ON MAP
                                            </div>
                                        </button>
                                    </div>
                                    <div
                                        :class="[
                                            {
                                                'md-size-50': selectedTab === 'create',
                                                'md-layout-item side-panel-button-container': true
                                            }
                                        ]"
                                    >
                                        <button
                                            @click="handleShowStates"
                                            :class="[
                                                {
                                                    'selected-side-panel-button': selectedTab === 'search',
                                                    'side-panel-right-button': selectedTab === 'create'
                                                }
                                            ]"
                                            :disabled="hasSelectedDrawMode"
                                        >
                                            <div>
                                                <md-icon>checklist_rtl</md-icon>
                                            </div>
                                            <div>
                                                SELECT FROM LIST
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div v-if="selectedTab == 'create' && newArea" class="md-layout-item md-size-100">
                                <div v-if="newArea" class="area-list-wrapper">
                                    <div>
                                        <form-wrapper :validator="$v" class="form-wrapper">
                                            <form-group name="areaName" label="Name">
                                                <md-input v-model="areaName" aria-placeholder="Name" v-focus />
                                            </form-group>
                                        </form-wrapper>
                                        <div class="form-button">
                                            <md-button
                                                class="md-primary dialog-button md-theme-default"
                                                @click="handleAddNewArea()"
                                            >
                                                Save
                                            </md-button>
                                            <md-button
                                                class="md-default dialog-button md-theme-default"
                                                @click="cancelAddEditMode()"
                                            >
                                                Cancel
                                            </md-button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="selectedTab == 'search'"
                                :class="[{ 'md-layout-item': true, 'md-size-100': selectedTab === 'create' }]"
                            >
                                <div :class="listLevel == 1 ? 'list-container-state' : 'list-container'">
                                    <div v-if="listLevel == 1">
                                        <div v-if="listOfStates.length">
                                            <div
                                                v-for="state in listOfStates"
                                                :key="state.stateId"
                                                :class="[
                                                    {
                                                        'state-item': true,
                                                        'selected-state-item': state.stateId === currentStateId
                                                    }
                                                ]"
                                                @click="handleClickState(state.stateId)"
                                            >
                                                {{ state.province }}
                                            </div>
                                        </div>
                                        <div v-else class="no-result-container">
                                            We currently don't support your country please contact support or email us
                                            at
                                            <a href="mailto:support@locate2u.com">support@locate2u.com</a>
                                        </div>
                                    </div>
                                    <div v-if="listLevel == 2">
                                        <div @click="handleShowStates" class="back-btn">
                                            <md-icon>arrow_back</md-icon>
                                            Back to list of states
                                        </div>
                                        <div class="search-suburb-title">
                                            Search suburb/s:
                                        </div>
                                        <div>
                                            <textarea
                                                class="c-textarea"
                                                v-model="suburbNames"
                                                placeholder="Search a list of suburbs or post codes separated by comma or an individual suburb or post code"
                                            />
                                            <md-button
                                                class="validate-btn md-primary"
                                                @click="handleMultipleSuburbSearch"
                                            >
                                                Validate
                                            </md-button>
                                        </div>
                                        <div class="search-section">
                                            <div class="preview-title">
                                                Result/s: ({{ listOfSuburbSearchResult.length }})
                                                <span
                                                    class="preview-btn"
                                                    v-if="listOfSuburbSearchResult.length > 0"
                                                    @click="toggleSearchResultPreview"
                                                >
                                                    <md-icon>
                                                        {{ toggleSearchResultList ? 'visibility' : 'visibility_off' }}
                                                    </md-icon>
                                                    <md-tooltip>Turn On/Off preview on the map screen</md-tooltip>
                                                </span>
                                                <span
                                                    class="preview-btn"
                                                    v-if="listOfSuburbSearchResult.length > 0"
                                                    @click="addSearchResultToPreviewList"
                                                >
                                                    <md-icon>add</md-icon>
                                                    <md-tooltip>Add search result to preview list</md-tooltip>
                                                </span>
                                            </div>

                                            <div class="search-container">
                                                <div class="loader" v-if="isLoading">
                                                    <fade-loader :loading="isLoading" color="#808080" />
                                                    <span>Loading...</span>
                                                </div>
                                                <div v-else>
                                                    <div class="item-list" v-if="listOfSuburbSearchResult.length">
                                                        <div
                                                            v-for="suburb in listOfSuburbSearchResult"
                                                            :key="suburb.suburbLocalityId"
                                                            class="state-item"
                                                        >
                                                            <div>
                                                                <span @click="addToPreviewList(suburb)">
                                                                    <md-icon class="blue-icon">add</md-icon>
                                                                    <md-tooltip>Add to list</md-tooltip>
                                                                </span>
                                                                <span @click="handleSuburbPreview(suburb, true)">
                                                                    <md-icon class="info-icon">visibility</md-icon>
                                                                    <md-tooltip>Show map preview</md-tooltip>
                                                                </span>
                                                            </div>
                                                            <div>{{ suburb.namePostCode }}</div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="no-result-container"
                                                        v-else-if="
                                                            listOfSuburbSearchResult.length == 0 &&
                                                                currentSearchTextValue
                                                        "
                                                    >
                                                        <span>No results matching your search could be found.</span>
                                                    </div>
                                                    <div class="no-result-container" v-else>
                                                        Search suburb/s or post codes to seee results.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="listLevel == 2" class="list-container-search">
                                    <div class="selected-preview">
                                        <form-wrapper :validator="$v" class="form-wrapper">
                                            <form-group name="areaName" label="Name">
                                                <md-input v-model="areaName" aria-placeholder="Name" v-focus />
                                            </form-group>
                                        </form-wrapper>
                                        <div></div>
                                        <div class="preview-title">
                                            Your selection/s: ({{ previewSuburbList.length }})
                                            <span
                                                class="preview-btn"
                                                v-if="previewSuburbList.length > 0"
                                                @click="turnOffAllSelectedPreview"
                                            >
                                                <md-icon>
                                                    {{ togglePreviewList ? 'visibility' : 'visibility_off' }}
                                                </md-icon>
                                                <md-tooltip>Turn On/Off preview on the map screen</md-tooltip>
                                            </span>
                                        </div>
                                        <div class="preview-box" v-if="previewSuburbList.length > 0">
                                            <div
                                                class="preview-item"
                                                v-for="previewSuburb in previewSuburbList"
                                                :key="previewSuburb.suburbLocalityId"
                                            >
                                                <div>
                                                    <span @click="removeFromList(previewSuburb)">
                                                        <md-icon class="red-icon">close</md-icon>
                                                        <md-tooltip>Remove item from list</md-tooltip>
                                                    </span>
                                                    <span @click="handleSuburbPreview(previewSuburb)">
                                                        <md-icon class="info-icon">
                                                            visibility
                                                        </md-icon>
                                                        <md-tooltip>Show preview in the map screen</md-tooltip>
                                                    </span>
                                                </div>
                                                <div>{{ previewSuburb.namePostCode }}</div>
                                            </div>
                                        </div>
                                        <div class="preview-box" v-else>
                                            <div class="no-result-container">
                                                Select suburb/s from results
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-btn" v-if="listLevel == 2">
                                        <md-button @click="cancelAddEditMode()">
                                            Cancel
                                        </md-button>
                                        <md-button class="md-primary" @click="handleAddNewSuburbArea()">
                                            Save
                                        </md-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        SELECT LIST
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { handleRequests, showErrorMessage } from '@/helpers';
import { MapOverviewMixin, GeneralMixin } from '@/mixins';
import { MarkerColorCode } from '@/utils/MarkerColorCode';
import FadeLoader from 'vue-spinner/src/FadeLoader';

export default {
    components: {
        FadeLoader
    },
    props: {
        map: {
            type: Object,
            default: () => {}
        },
        drawingManager: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: ''
        },
        idName: {
            type: String,
            default: ''
        },
        endPointName: {
            type: String,
            default: ''
        },
        loadShapesOnly: {
            type: Boolean,
            default: false
        },
        serviceAreaListOptions: {
            type: Array,
            default: () => []
        }
    },
    mixins: [MapOverviewMixin, GeneralMixin],
    data() {
        return {
            areaList: [],
            areaDrawings: [],
            drawings: [],
            areaAction: [],
            newArea: false,
            areaName: null,
            currentShape: {},
            areaId: null,
            selectedTab: 'areaList',
            listOfStates: [],
            listOfSuburbSearchResult: [],
            currentSearchTextValue: null,
            listLevel: 1,
            isLoading: false,
            currentStateId: null,
            previewSuburbList: [],
            suburbNames: null,
            temporaryPreviewList: [],
            togglePreviewList: false,
            toggleMainPreviewList: true,
            isUpdateMultipleArea: false,
            colorBeforeEdit: null,
            toggleSearchResultList: false,
            selectedArea: null,
            hasSelectedDrawMode: false
        };
    },
    validations: {
        areaName: {
            required
        }
    },
    async mounted() {
        await this.getAreaData();
        this.initDrawing();
        this.getDefaultStates();
    },
    beforeDestroy() {
        this.clearArea();
    },
    watch: {
        serviceAreaListOptions() {
            this.areaList = this.serviceAreaListOptions;
            this.clearArea();
            this.displayAreaFence();
        }
    },
    methods: {
        showAllPreview(value) {
            this.areaList.forEach((suburbData) => {
                if (suburbData.suburbLocalities.length) {
                    suburbData.suburbLocalities.forEach((element) => {
                        this.handleActionToggleVisibility(
                            element.paintedShape,
                            suburbData[this.idName],
                            suburbData,
                            value
                        );
                    });
                } else {
                    this.handleActionToggleVisibility(
                        suburbData.paintedShape,
                        suburbData[this.idName],
                        suburbData,
                        value
                    );
                }
            });
        },
        zoomToFence(areaId) {
            const clickedFence = this.areaList.find((fence) => areaId === fence[this.idName]);
            this.showAllPreview(false);
            this.showPreviewArea(areaId, true);
            this.selectedArea = clickedFence[this.idName];
            const bounds = new google.maps.LatLngBounds();
            if (clickedFence.fullShape) {
                clickedFence.fullShape.getPath().forEach((element) => {
                    bounds.extend(element);
                });
            } else {
                clickedFence.paintedShape.getPath().forEach((element) => {
                    bounds.extend(element);
                });
            }

            this.map.fitBounds(bounds);
        },
        initDrawing() {
            const onPolygonComplete = (polygon) => {
                // For polygon, loop through vertices and push the lat and lng as object
                this.currentShape = {
                    boundary: {
                        vertices: []
                    }
                };
                polygon.getPath().forEach((vertex) => {
                    this.currentShape.boundary.vertices.push({
                        lat: vertex.lat(),
                        lng: vertex.lng()
                    });
                });

                this.drawings.push(polygon);
                this.drawingManager.setDrawingMode(null);
                this.newArea = true;
                this.selectedTab = 'create';
            };

            this.$_map_handleListener(this.drawingManager, 'polygoncomplete', onPolygonComplete);
            this.$_map_handleListener(this.drawingManager, 'drawingmode_changed', () => {
                this.drawingManager.clearDrawing = false;
                if (this.drawingManager.getDrawingMode() !== null) {
                    this.clearDrawing();
                }
            });
        },
        selectDrawing(mode) {
            this.drawingManager.setDrawingMode(mode);
            this.listLevel = 1;
            this.newArea = true;
            this.clearFields();
            this.selectedTab = 'create';
            this.clearSelectedSuburb();
            this.clearSuburbSearchResult();
        },
        clearDrawing() {
            this.drawings.forEach((draw) => {
                draw.setMap(null);
            });
            this.drawings = [];
        },
        async handleAddNewArea() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            if (this.areaId) {
                const area = this.areaList.find((fence) => this.areaId === fence[this.idName]);
                const { paintedShape } = area;
                Object.assign(this.currentShape, {
                    boundary: {
                        vertices: []
                    }
                });
                paintedShape.getPath().forEach((vertex) => {
                    this.currentShape.boundary.vertices.push({
                        lat: vertex.lat(),
                        lng: vertex.lng()
                    });
                });
                this.currentShape[this.idName] = this.areaId;
                this.currentShape.name = area.name;
                this.newArea = false;
            }

            if (!this.currentShape?.boundary) {
                this.$notify({
                    message: 'Please draw on map',
                    type: 'danger'
                });
                return;
            }

            this.$_handleLoaderState(true, 'SAVING...');
            try {
                await this.saveArea();
                this.$v.$reset();
                this.$_handleLoaderState(false);
            } catch (e) {
                const message = `Could not save ${this.title.toLowerCase()}.`;
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        async saveArea() {
            this.currentShape.name = this.areaName;
            this.newArea = false;

            const data = this.currentShape;
            const method = data[this.idName] ? 'PUT' : 'POST';
            const endPoint = data[this.idName] ? `${this.endPointName}/${data[this.idName]}` : `${this.endPointName}`;
            await handleRequests(endPoint, {
                method,
                data
            });

            this.$notify({
                message: this.areaId ? `${this.title} updated` : `${this.title} created`,
                type: 'success'
            });

            this.selectedArea = null;
            this.clearDrawing();
            this.clearFields();
            this.selectedTab = 'areaList';
            await this.getAreaData();
        },
        clearFields() {
            if (this.areaId) {
                const areaData = this.areaList.find((fence) => fence[this.idName] === this.areaId);
                if (areaData.paintedShape === undefined) {
                    this.areaId = null;
                    this.areaName = null;
                    return;
                }
                const { paintedShape } = areaData;
                paintedShape.setEditable(false);
                paintedShape.setDraggable(false);
            }
            this.areaId = null;
            this.areaName = null;
        },
        cancelAddEditMode() {
            this.currentShape = {};
            this.newArea = false;
            this.drawingManager.setDrawingMode(null);
            this.currentStateId = null;
            this.clearDrawing();
            this.clearFields();
            this.selectedTab = 'areaList';
            this.$v.$reset();
            this.hasSelectedDrawMode = false;
            this.isUpdateMultipleArea = false;
        },
        editArea(areaId) {
            const fence = this.areaList.find((fence) => fence[this.idName] === areaId);
            if (fence.suburbLocalities.length) {
                this.colorBeforeEdit = fence.color;
                const { stateId } = fence.suburbLocalities[0];
                this.currentStateId = stateId;
                this.isUpdateMultipleArea = true;
                this.clearSelectedSuburb();
                this.zoomToFence(areaId);
                this.selectedTab = 'search';
                this.handleClickState(stateId);
                this.areaId = areaId;
                this.areaName = fence.name;
                this.previewSuburbList = [...fence.suburbLocalities];
                this.drawMultipleSuburb();
            } else {
                this.colorBeforeEdit = fence.color;
                this.zoomToFence(areaId);
                this.clearFields();
                this.selectedTab = 'create';
                this.areaId = areaId;
                this.areaName = fence.name;
                this.newArea = true;
                fence.paintedShape.setEditable(true);
                fence.paintedShape.setDraggable(true);
            }
            this.hasSelectedDrawMode = true;
        },
        deleteArea(areaId) {
            this.zoomToFence(areaId);
            this.$messageBox
                .show({
                    title: `Delete ${this.title.toLowerCase()}`,
                    body: `Are you sure you want to delete this ${this.title.toLowerCase()}?`,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        await this.handleConfirmDelete(areaId);
                    }
                });
        },
        async handleConfirmDelete(areaId) {
            this.$_handleLoaderState(true, 'DELETING...');
            try {
                const endPoint = `${this.endPointName}/${areaId}`;
                const payload = {
                    method: 'delete'
                };
                await handleRequests(endPoint, payload);
                this.cancelAddEditMode();
                await this.getAreaData();

                this.$notify({
                    message: `${this.title} removed`,
                    type: 'success'
                });
                this.$_handleLoaderState(false);
            } catch (e) {
                const message = `Could not delete ${this.title.toLowerCase()}.`;
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        areaActions(paintedShape, shape, lat, lng, zoneName = null, displayShapeName = true) {
            let shapeName = shape.name;
            let isMultipleSuburb = false;
            // if zone name is not null it means this area is using a suburb boundary.
            if (zoneName) {
                shapeName = zoneName;
                isMultipleSuburb = true;
            }
            const action = this.$_map_createAreaActions({
                latlng: this.$_map_createLatLngPoint(lat, lng),
                map: this.map,
                html:
                    // eslint-disable-next-line no-nested-ternary
                    displayShapeName
                        ? this.loadShapesOnly || isMultipleSuburb
                            ? `<div style="background-color: #fff;padding: 5px;border-radius: 8px; text-align: center;">
                            <div style="color: #000; font-size: 14px; font-weight: 500;">${shapeName}
                            </div>
                      </div>`
                            : `<div style="background-color: #fff;padding: 5px;border-radius: 8px; text-align: center;">
                            <div style="color: #000; font-size: 14px; font-weight: 500;">${shapeName}
                            </div>
                            <div class="area-id" style="display: none; margin-top: 8px;">
                                <span class="edit-area" title="Edit">
                                    <i style="width: 14px; height: 14px; font-size: 14px !important;" class="md-icon md-icon-font md-theme-default">edit</i>
                                </span>
                                <span class="delete-area" title="Delete">
                                    <i style="width: 14px; height: 14px; font-size: 14px !important;" class="md-icon md-icon-font md-theme-default">delete</i>
                                </span>
                            </div>
                      </div>`
                        : null
            });

            if (!this.loadShapesOnly) {
                if (isMultipleSuburb) {
                    action.isDisplay = true;
                    return action;
                }

                const onEdit = () => {
                    this.editArea(shape[this.idName]);
                };
                const onDelete = () => {
                    this.deleteArea(shape[this.idName]);
                };

                google.maps.event.addListener(paintedShape, 'mouseover', (evt) => {
                    if (action.div) {
                        const dom = action.div.getElementsByClassName('area-id')[0].style;
                        dom.display = 'block';

                        const editDom = action.div.getElementsByClassName('edit-area')[0];
                        if (editDom.getAttribute('editlistener') !== 'true') {
                            editDom.setAttribute('editlistener', 'true');
                            editDom.addEventListener('click', () => {
                                onEdit();
                            });
                        }

                        const deleteDom = action.div.getElementsByClassName('delete-area')[0];
                        if (deleteDom.getAttribute('deletelistener') !== 'true') {
                            deleteDom.setAttribute('deletelistener', 'true');
                            deleteDom.addEventListener('click', () => {
                                onDelete();
                            });
                        }
                    }
                });
                google.maps.event.addListener(paintedShape, 'mouseout', (evt) => {
                    if (action.div) {
                        action.div.getElementsByClassName('area-id')[0].style.display = 'none';
                    }
                });
                const onRadiusChanged = () => {
                    const bounds = new google.maps.LatLngBounds();
                    shape.paintedShape.getPath().forEach((element) => {
                        bounds.extend(element);
                    });
                    action.setPosition(bounds.getCenter());
                };

                const polygonPath = paintedShape.getPath();
                this.$_map_handleListener(polygonPath, 'insert_at', onRadiusChanged);
                this.$_map_handleListener(polygonPath, 'set_at', onRadiusChanged);

                action.isDisplay = true;
            }

            return action;
        },
        paintFence(shape, areaId, color, isVisible) {
            shape.color = color;
            const paintedShape = new google.maps.Polygon({
                paths: shape.boundary.vertices,
                strokeColor: color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: color,
                draggable: false,
                editable: false,
                fillOpacity: 0.35,
                visible: isVisible
            });
            const bounds = new google.maps.LatLngBounds();
            paintedShape.getPath().forEach((element) => {
                bounds.extend(element);
            });
            const action = this.areaActions(paintedShape, shape, bounds.getCenter().lat(), bounds.getCenter().lng());

            action.isDisplay = isVisible;
            paintedShape.setMap(this.map);
            this.areaDrawings.push(paintedShape);

            const index = this.areaList.findIndex((x) => x[this.idName] === areaId);
            if (index === -1) {
                shape[this.idName] = areaId;
                this.areaList.push(Object.assign({ paintedShape }, { ...shape }, { areaId }));
            } else {
                this.areaList[index].paintedShape = paintedShape;
                Object.assign(this.areaList[index], shape);
            }

            this.areaAction.push({ areaId, action });
        },
        paintMultipleFence(fence, areaId, zoneName, color, isVisible) {
            const fullBounds = [];
            const { suburbLocalities } = fence;
            fence.color = color;
            suburbLocalities.forEach((suburb) => {
                suburb.boundary.vertices.forEach((vertex) => {
                    fullBounds.push({
                        lat: vertex.lat,
                        lng: vertex.lng
                    });
                });
                const paintedShape = new google.maps.Polygon({
                    paths: suburb.boundary.vertices,
                    strokeColor: color,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: color,
                    draggable: false,
                    editable: false,
                    fillOpacity: 0.35,
                    visible: isVisible
                });
                const bounds = new google.maps.LatLngBounds();
                paintedShape.getPath().forEach((element) => {
                    bounds.extend(element);
                });
                const action = this.areaActions(
                    paintedShape,
                    suburb,
                    bounds.getCenter().lat(),
                    bounds.getCenter().lng(),
                    zoneName,
                    false
                );
                paintedShape.setMap(this.map);
                this.areaDrawings.push(paintedShape);
                suburb.paintedShape = paintedShape;
                action.isDisplay = isVisible;

                this.areaAction.push({ areaId, action, suburbLocalityId: suburb.suburbLocalityId });
            });

            // this helps with the zooming of per zone area if using suburb localities
            const fullShape = new google.maps.Polygon({
                paths: fullBounds,
                visible: false
            });

            fence.fullShape = fullShape;
        },
        displayAreaFence(value) {
            this.areaAction.forEach((area) => {
                area.action.remove();
                area.action.isDisplay = false;
            });
            this.areaList.forEach((fence, i) => {
                if (!fence.paintedShape) {
                    if (fence.suburbLocalities.length) {
                        const { color } = MarkerColorCode[i % MarkerColorCode.length];
                        this.paintMultipleFence(fence, fence[this.idName], fence.name, color, value);
                    } else {
                        const { color } = MarkerColorCode[i % MarkerColorCode.length];
                        this.paintFence(fence, fence[this.idName], color, value);
                    }
                } else {
                    fence.paintedShape.setVisible(value);
                }
                const areaAction = this.areaAction.find((x) => x[this.idName] === fence[this.idName]);
                if (value) {
                    areaAction.action.isDisplay = false;
                    areaAction.action.draw();
                }
            });
        },
        clearArea() {
            this.clearDrawing();
            this.areaDrawings.forEach((draw) => {
                draw.setMap(null);
            });
            this.areaDrawings = [];

            this.areaAction.forEach((area) => {
                area.action.remove();
                area.action.isDisplay = false;
            });
            this.areaAction = [];
        },
        async getAreaData() {
            this.$_handleLoaderState(true);
            this.selectedArea = null;
            this.clearArea();
            let isVisible = false;
            if (this.serviceAreaListOptions && this.serviceAreaListOptions.length > 0) {
                this.areaList = this.serviceAreaListOptions;
                isVisible = true;
            } else {
                const endPoint = `${this.endPointName}`;
                const response = await handleRequests(endPoint);
                this.areaList = response.data.sort((x, y) => (x.name > y.name ? 1 : -1));
            }

            this.displayAreaFence(isVisible);

            this.$_handleLoaderState(false);
        },
        async handleShowStates() {
            this.hasSelectedDrawMode = true;
            this.listLevel = 1;
            this.selectedTab = 'search';
            this.suburbNames = null;
            await this.getDefaultStates();
        },
        async getDefaultStates() {
            if (this.listOfStates != null && this.listOfStates.length > 0) {
                return;
            }
            const qry = new URLSearchParams();
            if (this.currentSearchTextValue) {
                qry.append('searchText', this.currentSearchTextValue);
            }
            const api = `/api/teams/states?${qry}`;
            const { data } = await handleRequests(api);
            this.listOfStates = data;
        },
        // eslint-disable-next-line func-names
        handleFilterOrSearch: _.debounce(function(obj) {
            this.currentSearchTextValue = obj.searchText;
            switch (this.listLevel) {
                case 1:
                    this.getDefaultStates();
                    break;
                case 2:
                    this.handleClickState(this.currentStateId);
                    this.handleSearchSuburb();
                    break;
                default:
                    break;
            }
        }, 800),
        async handleClickState(stateId) {
            this.currentStateId = stateId;
            this.listLevel = 2;
            this.clearSuburbSearchResult();
        },
        async handleSearchSuburb() {
            if (!this.currentSearchTextValue) {
                this.listOfSuburbSearchResult = [];
                return;
            }
            this.isLoading = true;
            const qry = new URLSearchParams();
            if (this.currentSearchTextValue) {
                qry.append('searchText', this.currentSearchTextValue);
            }
            const api = `/api/teams/states/${this.currentStateId}/suburbs?${qry}`;
            const { data } = await handleRequests(api);
            this.listOfSuburbSearchResult = data;
            this.isLoading = false;
        },
        handleSuburbPreview(suburbData, forPreviewOnly = false) {
            let isPreviewOnly = forPreviewOnly;

            const suburb = this.previewSuburbList.find((x) => x.suburbLocalityId === suburbData.suburbLocalityId);

            if (suburb) {
                isPreviewOnly = false;
            }
            if (suburbData.paintedShape) {
                suburbData.paintedShape.setVisible(!suburbData.paintedShape.getVisible());
            } else {
                this.drawPreview(suburbData, isPreviewOnly);
            }
        },
        addToPreviewList(suburbData) {
            const suburbIndex = this.previewSuburbList.findIndex(
                (x) => x.suburbLocalityId === suburbData.suburbLocalityId
            );

            if (suburbIndex > -1) {
                this.$notify({
                    message: `${suburbData.name} has already been added to the list.`,
                    type: 'success'
                });

                return;
            }

            this.previewSuburbList.push(suburbData);

            if (suburbData.paintedShape) {
                suburbData.paintedShape.setOptions({
                    strokeColor: '#FF0000',
                    fillColor: '#FF0000'
                });
            } else {
                this.drawPreview(suburbData);
            }
        },
        removePreview(suburbData) {
            if (suburbData.paintedShape) {
                suburbData.paintedShape.setMap(null);
                suburbData.paintedShape = null;
            }

            const suburbAction = this.areaAction.find((x) => x.suburbLocalityId === suburbData.suburbLocalityId);
            if (suburbAction) {
                suburbAction.action.isDisplay = false;
                suburbAction.action.remove();
            }
        },
        removeFromList(suburbData) {
            const suburbIndex = this.previewSuburbList.findIndex(
                (x) => x.suburbLocalityId === suburbData.suburbLocalityId
            );
            this.removePreview(suburbData);
            this.previewSuburbList.splice(suburbIndex, 1);
        },
        turnOffAllSelectedPreview() {
            this.previewSuburbList.forEach((suburbData) => {
                suburbData.paintedShape.setVisible(this.togglePreviewList);
            });
            this.togglePreviewList = !this.togglePreviewList;
        },
        toggleSearchResultPreview() {
            this.listOfSuburbSearchResult.forEach((suburbData) => {
                if (suburbData.paintedShape) {
                    suburbData.paintedShape.setVisible(!this.toggleSearchResultList);
                } else {
                    this.drawPreview(suburbData, true);
                }
            });
            this.toggleSearchResultList = !this.toggleSearchResultList;
        },
        drawPreview(suburbData, forPreviewOnly = false, color = null) {
            let colorCode = forPreviewOnly ? '#7F00FF' : '#FF0000';

            if (color != null) {
                colorCode = color;
            }

            const paintedShape = new google.maps.Polygon({
                paths: suburbData.boundary.vertices,
                strokeColor: colorCode,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: colorCode,
                draggable: false,
                editable: false,
                fillOpacity: 0.35
            });
            const bounds = new google.maps.LatLngBounds();
            paintedShape.getPath().forEach((element) => {
                bounds.extend(element);
            });

            paintedShape.setMap(this.map);

            suburbData.paintedShape = paintedShape;
        },
        async handleAddNewSuburbArea() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$notify({
                    message: 'Service Area name is required',
                    type: 'danger'
                });
                return;
            }

            if (this.previewSuburbList.length === 0) {
                this.$notify({
                    message: 'Please add suburbs',
                    type: 'danger'
                });
                return;
            }

            this.$_handleLoaderState(true, 'SAVING...');
            try {
                const suburbListIds = this.previewSuburbList.map((x) => x.suburbLocalityId);

                const data = {
                    name: this.areaName,
                    suburbIds: suburbListIds
                };
                const method = this.isUpdateMultipleArea ? 'PUT' : 'POST';
                const endPoint = this.isUpdateMultipleArea
                    ? `${this.endPointName}/${this.areaId}/multiple`
                    : `${this.endPointName}/multiple`;
                await handleRequests(endPoint, {
                    method,
                    data
                });

                this.$notify({
                    message: this.areaId ? `${this.title} updated` : `${this.title} created`,
                    type: 'success'
                });

                this.$v.$reset();

                this.$_handleLoaderState(false);
                this.isUpdateMultipleArea = false;
                this.clearSelectedSuburb();
                this.clearDrawing();
                this.areaId = null;
                this.areaName = null;
                this.selectedTab = 'areaList';
                this.listLevel = 1;
                this.selectedArea = null;
                await this.getAreaData();
                this.$_handleLoaderState(false);
            } catch (e) {
                const message = `Could not save ${this.title.toLowerCase()}.`;
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        async handleMultipleSuburbSearch() {
            if (!this.suburbNames) {
                this.$notify({
                    message: "There's no data to validate.",
                    type: 'warning'
                });
                return;
            }

            this.isLoading = true;

            const listOfSuburbNames = this.suburbNames.trim().split(',');

            const data = {
                suburbNames: listOfSuburbNames
            };

            const api = `/api/teams/states/${this.currentStateId}/suburbs/validate`;
            const result = await handleRequests(api, { method: 'POST', data });
            this.clearSuburbSearchResult();
            this.listOfSuburbSearchResult = result.data;
            this.isLoading = false;
        },
        drawMultipleSuburb() {
            if (this.previewSuburbList.length === 0) {
                return;
            }

            this.previewSuburbList.forEach((element) => {
                this.removePreview(element);
                this.drawPreview(element);
            });
        },
        clearSelectedSuburb() {
            this.previewSuburbList.forEach((suburbData) => {
                this.removePreview(suburbData);
            });

            this.previewSuburbList = [];
        },
        async handleServiceAreaEntireMapPreview() {
            this.areaList.forEach((suburbData) => {
                if (suburbData.suburbLocalities.length) {
                    suburbData.suburbLocalities.forEach((element) => {
                        this.handleActionToggleVisibility(
                            element.paintedShape,
                            suburbData[this.idName],
                            suburbData,
                            this.toggleMainPreviewList
                        );
                    });
                } else {
                    this.handleActionToggleVisibility(
                        suburbData.paintedShape,
                        suburbData[this.idName],
                        suburbData,
                        this.toggleMainPreviewList
                    );
                }
            });

            this.toggleMainPreviewList = !this.toggleMainPreviewList;
        },
        showPreviewArea(areaId, isShow) {
            const fence = this.areaList.find((fence) => fence[this.idName] === areaId);
            if (fence.suburbLocalities.length) {
                fence.suburbLocalities.forEach((element) => {
                    this.handleActionToggleVisibility(element.paintedShape, areaId, fence, isShow);
                });
            } else {
                this.handleActionToggleVisibility(fence.paintedShape, areaId, fence, isShow);
            }
        },
        clearSuburbSearchResult() {
            this.listOfSuburbSearchResult.forEach((suburbData) => {
                const suburbIndex = this.previewSuburbList.findIndex(
                    (x) => x.suburbLocalityId === suburbData.suburbLocalityId
                );
                if (suburbIndex < 0) {
                    this.removePreview(suburbData);
                }
            });
            this.listOfSuburbSearchResult = [];
        },
        handleActionToggleVisibility(paintedShape, areaId, areaItem, visibilityValue = null) {
            if (paintedShape) {
                let isVisible = !paintedShape.getVisible();

                if (visibilityValue != null) {
                    isVisible = visibilityValue;
                }

                paintedShape.setVisible(isVisible);

                const areaActions = this.areaAction.filter((x) => x.areaId === areaId);
                this.$set(areaItem, 'areaVisible', isVisible);
                areaActions.forEach((element) => {
                    if (!isVisible) {
                        element.action.isDisplay = false;
                        element.action.remove();
                    } else {
                        element.action.isDisplay = true;
                        element.action.draw();
                    }
                });
            }
        },
        addSearchResultToPreviewList() {
            this.listOfSuburbSearchResult.forEach((suburbData) => {
                this.addToPreviewList(suburbData);
            });
        },
        async handleEditServiceArea(serviceArea) {
            this.editArea(serviceArea);
        },
        async handleServiceAreaPreview(serviceArea) {
            this.showPreviewArea(serviceArea);
        },
        async handleDeleteServiceArea(serviceArea) {
            this.deleteArea(serviceArea);
        },
        async handleAddNewServiceArea() {
            this.cancelAddEditMode();
            this.selectedTab = 'create';
        },
        async handleDrawOnMap() {
            this.hasSelectedDrawMode = true;
            this.selectDrawing('polygon');
        }
    }
};
</script>

<style lang="scss" scoped>
span.area-icons {
    transform: scale(0.7);
}

.drawing-tool {
    margin-left: 0px;
    margin-top: -10px;

    .small {
        line-height: 1.2;
        margin-bottom: 5px;
    }

    .area-print {
        display: flex;
        justify-content: center;
    }

    .area-print-child {
        float: left;
        line-height: 0;

        &.active button {
            border: 1px solid #2b93ff;
            width: 32px;
            height: 32px;
            min-width: 32px;
        }
    }

    button {
        background: none padding-box transparent;
        display: block;
        border: 0px;
        margin: 0px;
        padding: 4px;
        text-transform: none;
        appearance: none;
        position: relative;
        cursor: pointer;
        user-select: none;
        direction: ltr;
        overflow: hidden;
        text-align: left;
        font-family: Roboto, Arial, sans-serif;
        font-size: 11px;
        border: 1px solid #e8e8e8;

        span {
            display: inline-block;
        }

        div {
            width: 16px;
            height: 14px;
            overflow: hidden;
            position: relative;
        }

        img {
            position: absolute;
            left: 0px;
            user-select: none;
            border: 0px;
            padding: 0px;
            margin: 0px;
            max-width: none;
            width: 16px;
            height: 192px;
        }
    }

    .button-pressed {
        background: #e5e5e5;
        -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
        -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
        box-shadow: inset 0px 0px 5px #c1c1c1;
        outline: none;
    }

    .drawing-button {
        color: rgb(0, 0, 0);
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        font-weight: 500;

        img {
            top: -144px;
        }
    }

    .polygon-button {
        color: rgb(86, 86, 86);
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;

        img {
            top: -65px;
        }
    }
}

.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
    padding: 15px 0;
}

.md-field + .md-has-textarea:not(.md-autogrow) {
    margin-top: 0;
}

.area-list-wrapper {
    background: #fff;
    padding: 3px 5px;
    font-size: 0.8rem;
    max-height: 300px;
    overflow: auto;
}

.form-wrapper {
    margin-top: 25px;
}

.form-button {
    text-align: center;
}

::v-deep .md-card-content {
    padding: 0;
}

::v-deep .md-table-head {
    padding: 0;
}

::v-deep .md-table-head-label {
    font-size: 0.83rem;
    font-weight: 800;
}

map-overlay {
    ::v-deep .area-fence-name {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
    }
}

.state-item:hover,
.preview-item:hover {
    background-color: #eee;
    cursor: pointer;
}

.item-list {
    overflow: auto;
    max-height: 145px;

    .state-item {
        display: table;
        font-size: 13px;
        width: 100%;
        > div {
            display: table-cell;
            vertical-align: middle;

            .md-icon:hover {
                color: #2b93ff;
            }
        }

        > div:first-child {
            width: 55px;
        }
    }
}

.list-container {
    width: 100%;

    .back-btn {
        margin: 10px 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        i {
            font-size: 18px !important;
        }
    }

    .back-btn:hover {
        color: #2b93ff;
        i {
            color: #2b93ff;
        }
    }

    .search-suburb-title {
        margin: 10px 0;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
    }

    .loader {
        position: absolute;
        top: 35%;
        left: 62%;

        span {
            position: absolute;
            margin-top: 50px;
            width: 110px;
            left: calc((100% - 100px) / 2);
            text-align: center;
            font-weight: 600;
        }
    }

    ::v-deep .search-button--container {
        margin-right: 0;
    }

    .list-btn {
        display: inline-block;
    }
}

.c-textarea {
    width: 100%;
    min-height: 77px;
    resize: none;
    overflow: auto;
    border: 1px solid #ced6de;
    padding: 10px;
}

.list-container-search {
    .selected-preview {
        margin-top: 10px;
        margin-bottom: 10px;
        overflow: auto;
        max-height: 84vh;
        > .preview-item:hover {
            cursor: pointer;
            background-color: #eee;
        }
        > .preview-box {
            border: 1px solid #ced6de;
            overflow: auto;
            padding: 10px;
            max-height: 175px;
            height: 175px;
        }
        .preview-item {
            display: table;
            font-size: 13px;
            width: 100%;
            > div {
                display: table-cell;
                vertical-align: middle;
            }
            > div:first-child {
                width: 55px;
            }
        }
        > .preview-title {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 500;
        }

        .form-wrapper {
            margin-top: 0;
        }
    }

    .list-btn {
        width: 100%;

        button {
            margin: 0;
            display: inline-block;
            width: calc((100% - 10px) / 2);
        }

        button:first-child {
            margin-right: 10px;
        }
    }
}

.no-result-container {
    font-size: 13px;
    color: #465161;
    a {
        display: initial;
        color: #2b93ff;
    }
    a:hover {
        display: initial;
    }
}

.search-container {
    height: 165px;
    max-height: 165px;
    border: 1px solid #ced6de;
    padding: 10px;
}

.validate-btn {
    margin: 0;
    width: 100%;
    background-color: white !important;
    color: #2b93ff !important;
    border: 1px solid #2b93ff;
}

.search-section {
    margin-top: 10px;
    > .preview-title {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 500;
    }
}

.preview-btn {
    margin-left: 10px;
}

.preview-btn:hover {
    color: #2b93ff;
    cursor: pointer;
}

.list-container-state {
    margin-top: 30px;

    .state-item {
        border-radius: 3px;
        padding: 6px 8px 2px 6px;
        width: fit-content;
        margin-bottom: 5px;
    }

    .selected-state-item,
    .selected-state-item:hover {
        background-color: #2b93ff;
        color: #fff;
        box-shadow: 0 2px 2px #accded;
    }
}

.blue-icon {
    color: #2b93ff !important;
}

.red-icon {
    color: #f44336 !important;
}

.selected-area-highlight {
    color: #2b93ff;
    font-weight: 500;
}

.floating-service-area {
    position: absolute;
    left: 25px;
    width: 600px;
    bottom: 0;
    height: 88vh;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 0 16px 0 16px;

    .area-list-table {
        overflow-y: hidden;
        height: calc(80vh - 60px);
    }

    .area-list-table:hover {
        overflow-y: auto;
    }

    .md-card {
        margin-top: 0;
    }

    .md-card-header-icon .card-icon {
        background-color: #384553;
        padding: 10px;
        margin-top: -20px;
        margin-left: -15px;
        margin-bottom: 15px;
    }

    .md-table {
        width: 100%;
        border-collapse: collapse;
    }

    .md-table-row {
        border-bottom: 1px solid #ddd;

        highlight-item {
            font-weight: bold;

            ::v-deep .cta-list {
                display: unset;
            }
        }

        ::v-deep .md-table-head .md-table-head-container .md-table-head-label {
            color: #4caf50 !important;
            font-size: 1.0625rem;
            font-weight: 300;
        }

        .md-table-head:last-child {
            width: 215px;
        }

        .md-table-cell:first-child {
            ::v-deep .md-table-cell-container {
                margin-left: -15px !important;
            }

            .md-icon {
                width: 14px;
                height: 14px;
                font-size: 14px !important;
                margin-right: 0px;
                margin-left: 0px;

                &.user-pin {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .md-table-cell {
        padding: 10px;
        font-size: 14px;
    }

    .service-area-table-name {
        cursor: pointer;
    }

    .service-area-table-actions {
        width: 180px;

        ::v-deep .md-table-cell-container {
            margin-left: -15px !important;
        }
    }

    .action-button {
        margin-right: 5px;
        margin-left: 5px;
        min-width: 27px;
        height: 27px;
    }

    .md-card-header-action-button {
        position: absolute;
        right: 5px;
        top: 30px;
        padding: 0 !important;
        height: 34px;
        min-width: 34px;
        width: 34px;
    }

    .block-out-days-button {
        background-color: black !important;
    }

    .service-area-add-button {
        margin-left: 0;
        margin-top: 25px;
    }

    .create-service-area {
        .select-state-title {
            margin: 10px 0;
            font-size: 24px;
            font-weight: 500;
        }

        .side-panel {
            max-width: 155px;
            padding-left: 0;
            padding-right: 10px;
            border-right: 1px solid #ced6de;
            height: 75vh;
        }

        .side-panel-button-container {
            text-align: center;
            margin-bottom: 5px;

            button {
                height: 90px;
                width: 130px;
                border: 0;
                font-size: 10px;
                cursor: pointer;
                background-color: #fff;
                border-radius: 3px;

                div:nth-child(2) {
                    margin-top: 15px;
                }
            }

            button:hover {
                background-color: #eee;
            }

            .side-panel-left-button {
                float: right;
            }

            .side-panel-right-button {
                float: left;
            }

            .selected-side-panel-button,
            .selected-side-panel-button:hover {
                background-color: #2b93ff;
                color: #fff;
                box-shadow: 0 2px 2px #accded;

                i {
                    color: #fff;
                }
            }
        }
    }
}
</style>
