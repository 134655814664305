var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "modal-header" },
        [
          _c("h4", { staticClass: "modal-title" }, [_vm._v("Update Order")]),
          _c(
            "md-button",
            {
              staticClass:
                "md-simple md-just-icon md-round modal-default-button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$modal.hide($event)
                },
              },
            },
            [_c("md-icon", [_vm._v("clear")])],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          !_vm.isLoading
            ? _c(
                "form-wrapper",
                {
                  staticClass: "form-wrapper",
                  attrs: { validator: _vm.$v.orderDetails },
                },
                [
                  _c(
                    "form-group",
                    { attrs: { name: "name", label: "Contact Name" } },
                    [
                      _c("md-input", {
                        model: {
                          value: _vm.orderDetails.contact.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderDetails.contact, "name", $$v)
                          },
                          expression: "orderDetails.contact.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "form-group",
                    { attrs: { name: "phone", label: "Phone" } },
                    [
                      _c("md-input", {
                        model: {
                          value: _vm.orderDetails.contact.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderDetails.contact, "phone", $$v)
                          },
                          expression: "orderDetails.contact.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "form-group",
                    { attrs: { name: "email", label: "Email" } },
                    [
                      _c("md-input", {
                        model: {
                          value: _vm.orderDetails.contact.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderDetails.contact, "email", $$v)
                          },
                          expression: "orderDetails.contact.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "loader-container" },
                  [
                    _c("fade-loader", {
                      attrs: { loading: true, color: "#333333" },
                    }),
                    _c("span", [_vm._v("LOADING")]),
                  ],
                  1
                ),
              ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          !_vm.isLoading
            ? _c(
                "md-button",
                {
                  staticClass: "dialog-button md-primary",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.updateOrder($event)
                    },
                  },
                },
                [_vm._v("\n                Update\n            ")]
              )
            : _vm._e(),
          _c(
            "md-button",
            {
              staticClass: "dialog-button md-default",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$modal.hide($event)
                },
              },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }