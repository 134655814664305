var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          { staticClass: "form-wrapper", attrs: { validator: _vm.$v } },
          [
            _c(
              "div",
              { staticClass: "item-barcode" },
              [
                _c(
                  "form-group",
                  { attrs: { name: "barcode", label: "Barcode" } },
                  [
                    _c("md-input", {
                      directives: [{ name: "focus", rawName: "v-focus" }],
                      model: {
                        value: _vm.barcode,
                        callback: function ($$v) {
                          _vm.barcode = $$v
                        },
                        expression: "barcode",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item-description" },
              [
                _c(
                  "form-group",
                  { attrs: { name: "description", label: "Description" } },
                  [
                    _c("md-input", {
                      model: {
                        value: _vm.description,
                        callback: function ($$v) {
                          _vm.description = $$v
                        },
                        expression: "description",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item-description" },
              [
                _c(
                  "form-group",
                  { attrs: { name: "sku", label: "SKU" } },
                  [
                    _c("md-input", {
                      model: {
                        value: _vm.sku,
                        callback: function ($$v) {
                          _vm.sku = $$v
                        },
                        expression: "sku",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item-description" },
              [
                _c(
                  "form-group",
                  {
                    attrs: {
                      name: "productReference",
                      label: "Product Reference",
                    },
                  },
                  [
                    _c("md-input", {
                      model: {
                        value: _vm.productReference,
                        callback: function ($$v) {
                          _vm.productReference = $$v
                        },
                        expression: "productReference",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item-description" },
              [
                _c(
                  "form-group",
                  {
                    attrs: {
                      name: "installationTime",
                      label: "Installation Time In Minutes",
                    },
                  },
                  [
                    _c("md-input", {
                      model: {
                        value: _vm.installationTimeInMinutes,
                        callback: function ($$v) {
                          _vm.installationTimeInMinutes = $$v
                        },
                        expression: "installationTimeInMinutes",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.loadFieldList !== null
              ? _c(
                  "div",
                  _vm._l(_vm.loadFieldList, function (load, index) {
                    return _c("load-inputs", {
                      key: index,
                      attrs: {
                        "load-definition": load,
                        "initial-value": _vm.loadValues,
                      },
                      on: { changed: _vm.handleLoadChanged },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            on: { click: _vm.addItem },
          },
          [_vm._v("\n            Save\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Add Item")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }