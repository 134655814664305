<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Duplicate Details</h4>
        </div>
        <div class="modal-body">
            <div class="md-layout-item">
                <md-datepicker
                    class="date-clone-filter"
                    ref="mdDatePicker"
                    v-model="tripDate"
                    md-immediately
                    :md-debounce="10"
                >
                    <label>Trip Date</label>
                </md-datepicker>
            </div>
            <div class="md-layout-item">
                <div>
                    Driver
                </div>
                <team-region-member-filter-options
                    :team-region-id="selectedTeamRegionId"
                    @changed="handleTeamRegionMemberChanged"
                    :clearable="false"
                    :show-team-member="true"
                />
            </div>
        </div>
        <md-button class="dialog-button md-default confirm-button" @click.stop="$modal.hide">Cancel</md-button>
        <md-button class="dialog-button md-primary confirm-button" @click.stop="confirm">Confirm</md-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { GeneralMixin } from '@/mixins';
// eslint-disable-next-line import/no-cycle
import { TeamRegionMemberFilterOptions } from '@/components';

export default {
    name: 'CloneJobEditModal',
    mixins: [GeneralMixin],
    components: { TeamRegionMemberFilterOptions },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    async mounted() {
        if (this.stopDetails && this.stopDetails.tripDate) 
            this.tripDate = moment(this.stopDetails.tripDate).toDate();

        if (this.shipmentDetails && this.shipmentDetails.tripDate)
            this.tripDate = moment(this.shipmentDetails.tripDate).toDate();

        this.selectedTeamRegionId = this.user.teamRegionId;
    },
    data() {
        return {
            tripDate: null,
            selectedTeamRegionId: null,
            updateClonedDetails: null,
            userId: null
        };
    },
    props: {
        stopDetails: {
            type: Object,
            default: () => {}
        },
        shipmentDetails: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    methods: {
        confirm() {
            let dateTime;
            if (this.tripDate) 
                dateTime = moment(this.tripDate).format('YYYY-MM-DD');

            this.updateClonedDetails = {
                tripDate: dateTime ?? null,
                userId: this.userId ?? null
            };

            const data = this.updateClonedDetails;
            this.resolve({ result: 'confirm', data });
        },
        handleTeamRegionMemberChanged(member) {
            this.userId = member?.teamMember?.userId;
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    margin: 0 auto;
    background-color: #fff;
    transition: all 0.3s ease;
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
    border-radius: 6px;
    border: none;
    position: fixed;
    top: 100px !important;
    justify-self: anchor-center;
    width: 400px !important;
}

.confirm-button {
    float: right;
}

.date-clone-filter {
    width: 200px !important;
}
</style>
