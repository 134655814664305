<template>
    <div class="date-filter-option">
        <md-field class="text-field">Where</md-field>
        <md-field>
            <vue-select
                v-model="filterType"
                :options="filterTypeList"
                :reduce="(x) => x.value"
                label="name"
                :clearable="false"
                placeholder="Date Type"
            ></vue-select>
        </md-field>
        <md-field class="text-field">is</md-field>
        <md-field>
            <vue-select
                v-model="dateRangeType"
                :options="dateRangeList"
                :reduce="(x) => x.value"
                label="name"
                :clearable="false"
                placeholder="Date Type"
            ></vue-select>
        </md-field>
        <md-field class="filter-steps--choices filter-step-date" v-if="dateRangeType === 'dateRange'">
            <md-datepicker class="start-date" v-model="fromDate" md-immediately :md-debounce="100"></md-datepicker>
        </md-field>
        <md-field class="filter-steps--choices filter-step-date" v-if="dateRangeType === 'dateRange'">
            <md-datepicker class="end-date" v-model="toDate" md-immediately :md-debounce="100"></md-datepicker>
        </md-field>
    </div>
</template>

<script>
import moment from 'moment';
import { DATE_TYPES } from '@/utils/constants';

export default {
    name: 'OrderDateFilterOptions',
    data() {
        return {
            dateRangeType: 'today',
            filterType: 'orderDate',
            filterTypeList: [
                { name: 'Created Date', value: 'createdDate' },
                { name: 'Order Date', value: 'orderDate' },
                { name: 'Expected Delivery Date', value: 'expectedDeliveryDate' },
                { name: 'Ready Delivery Date', value: 'deliveryDate' }, // based on stop that is attached to the order
                { name: 'Ready Pickup Date', value: 'readyPickupDate' }
            ],
            dateRangeList: [
                { name: 'Today', value: 'today' },
                { name: 'Tomorrow', value: 'tomorrow' },
                { name: 'Yesterday', value: 'yesterday' },
                { name: 'Date Range', value: 'dateRange' }
            ],
            fromDate: moment().format(DATE_TYPES.internationalDate),
            toDate: moment().format(DATE_TYPES.internationalDate)
        };
    },
    watch: {
        filterType() {
            this.handleFilter();
        },
        dateRangeType(value) {
            switch (value) {
                case 'today':
                    this.fromDate = moment().format(DATE_TYPES.internationalDate);
                    this.toDate = moment().format(DATE_TYPES.internationalDate);
                    break;
                case 'yesterday': {
                    const todayDate = moment();
                    const yesterdayDate = moment(todayDate.startOf('day')).set('date', todayDate.get('date') - 1);
                    this.fromDate = yesterdayDate.format(DATE_TYPES.internationalDate);
                    this.toDate = yesterdayDate.format(DATE_TYPES.internationalDate);
                    break;
                }
                case 'tomorrow': {
                    const todayDate = moment();
                    const yesterdayDate = moment(todayDate.startOf('day')).set('date', todayDate.get('date') + 1);
                    this.fromDate = yesterdayDate.format(DATE_TYPES.internationalDate);
                    this.toDate = yesterdayDate.format(DATE_TYPES.internationalDate);
                    break;
                }
                default: {
                    const todayDate = moment();
                    const oneWeekFromToday = moment(todayDate.startOf('day')).set('date', todayDate.get('date') + 7);
                    this.fromDate = todayDate.format(DATE_TYPES.internationalDate);
                    this.toDate = oneWeekFromToday.format(DATE_TYPES.internationalDate);
                    break;
                }
            }

            this.handleFilter();
        },
        fromDate() {
            this.handleFilter();
        },
        toDate() {
            this.handleFilter();
        }
    },
    methods: {
        // eslint-disable-next-line func-names
        handleFilter: _.debounce(function() {
            this.handleFilterchange();
        }, 700),
        handleFilterchange() {
            this.$emit('onDateFilterChanged', {
                filterType: this.filterType,
                dateRangeType: this.dateRangeType,
                fromDate: this.fromDate,
                toDate: this.toDate
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.date-filter-option {
    display: inline-block;

    ::v-deep .md-field {
        display: inline-block;
        width: 160px;
    }
    .md-field.text-field {
        font-size: 12px;
        font-weight: 600;
        vertical-align: bottom !important;
        width: initial !important;
        min-height: 0 !important;
    }

    .text-field:after {
        height: 0;
    }

    ::v-deep .md-datepicker {
        padding-bottom: 0;
        min-height: 40px !important;
        display: flex;
    }

    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }

    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        bottom: 30px;
        color: #aaaaaa;
        font-weight: 400;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }

    .start-date:before,
    .start-date:after {
        content: 'Start Date';
    }
    .end-date:before,
    .end-date:after {
        content: 'End Date';
    }

    ::v-deep .md-has-value:before,
    ::v-deep .md-has-value:after {
        content: '';
    }
}

::v-deep .vs__selected-options {
    max-width: 140px;
    flex-wrap: nowrap;
    span {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        width: 100%;
        margin-top: 6px;
    }
}

.filter-step-date {
    width: 150px;
}
</style>
