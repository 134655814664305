/* eslint-disable import/no-cycle */
import { FETCH_USER, UPDATE_USER, RESET_STATE, FETCH_RATE_RULES, SET_RATE_RULES } from '@/store/types';
import { handleRequests, imageCacheBuster } from '@/helpers/index';
import moment from 'moment';

// import { RetryMode } from '@azure/core-http';

const INIT_STATE = () => ({
    firstName: null,
    lastName: null,
    fullName: null,
    email: null,
    phone: null,
    bio: null,
    industry: null,
    company: null,
    logoFileName: null,
    timeZone: null,
    publicUserId: null,
    photoFileName: null,
    regionCode: null,
    vehicleType: null,
    photoUrl: null,
    logoUrl: null,
    role: null,
    hasTeam: false,
    userPermissions: [],
    startLocation: null,
    startAddress: null,
    endLocation: null,
    endAddress: null,
    gpsDataCollectionRule: null,
    defaultTripStartTime: null,
    defaultTripEndTime: null,
    stopCustomFieldDefinitions: null,
    shipmentCustomFieldDefinitions: null,
    assetCustomFieldDefinitions: null,
    skillOptions: null,
    skills: null,
    serviceAreasOptions: null,
    serviceAreas: null,
    websiteUrl: null,
    speedUnits: null,
    geofenceConfiguration: 'OFF',
    canOverrideLocation: false,
    teamMemberSelfAllocationAllowed: false,
    defaultOfferMethod: null,
    teamType: null,
    subscriptionDetails: null,
    teamId: null,
    hasRateRules: false,
    isTeamOwner: false,
    applyRatesTo: 7
});

const getters = {
    user: (state) => state,
    hasTeam: (state) => state.hasTeam,
    hasTeamAccess: (state) => state.role === 'Team Owner' || state.role === 'Team Manager',
    hasAssetAccess: (state) =>
        state.role === 'Team Owner' ||
        state.role === 'Team Manager' ||
        state.role === 'Office Staff' ||
        state.role === 'Support Staff',
    hasTeamSettingsAccess: (state) => state.role === 'Team Owner',
    hasCustomerListAccess: (state) =>
        state.role === 'Team Owner' ||
        state.role === 'Team Manager' ||
        state.role === 'Office Staff' ||
        state.role === 'Support Staff',
    canEditOwnTeamRegion: (state) => state.role === 'Team Owner',
    hasElevatedTeamRegionFilter: (state) =>
        state.role === 'Team Manager' || state.role === 'Office Staff' || state.role === 'Support Staff',
    canViewTeamRegion: (state) =>
        state.role === 'Team Owner' ||
        state.role === 'Team Manager' ||
        state.role === 'Office Staff' ||
        state.role === 'Support Staff',
    hasInventoryListAccess: (state) =>
        state.role === 'Team Owner' ||
        state.role === 'Team Manager' ||
        state.role === 'Office Staff' ||
        state.role === 'Warehouse Manager' ||
        state.role === 'Support Staff',
    hasInvoicesAccess: (state) =>
        state.role === 'Team Owner' || state.role === 'Team Manager' || state.role === 'Office Staff',
    hasReportsAccess: (state) => !(state.role === 'Customer' || state.role === 'Customer Admin'),
    hasMarketplaceAccess: (state) => state.userPermissions && state.userPermissions.includes('Marketplace'),
    publicUserId: ({ publicUserId }) => publicUserId,
    isIndividualUser: (state) => !state.hasTeam,
    isSingleTeamMember: (state) => state.isSingleTeamMember,
    isTrialExpired: (state) => {
        const today = moment().startOf('day');
        if (!state.subscriptionDetails) 
            return false;
        return (
            moment(state.subscriptionDetails.trialExpiryDate).isBefore(today) && !state.subscriptionDetails.isPaidUser
        );

        // hardcoded to hide the Trial banner until the issue in billing account is fully fixed
        // return false;
    },
    isSupportUser: (state) => state.isSupportUser,
    isSupportAdminUser: (state) => state.isSupportAdminUser,
    supportingTeam: (state) => state.supportingTeam,
    isAccountOwner: (state) => state.isAccountOwner,
    geofenceConfiguration: (state) => state.geofenceConfiguration || 'OFF',
    isReadOnlyUser: (state) =>
        state.role === 'Customer' || state.role === 'Customer Admin' || state.role === 'Support Staff',
    isSupportStaff: (state) => state.role === 'Support Staff',
    isCustomer: (state) => state.role === 'Customer',
    isCustomerAdmin: (state) => state.role === 'Customer Admin',
    isOfficeStaff: (state) =>
        state.hasTeam &&
        (state.role === 'Team Owner' || state.role === 'Team Manager' || state.role === 'Office Staff'),
    hasOptimiseAccess: (state, getters) => getters.isOfficeStaff,
    canReassignTrip: (state, getters) => getters.isOfficeStaff || state.role === 'Customer Admin',
    canOverrideLocation: (state) => state.canOverrideLocation,
    hasGeofenceAccess: (state) =>
        state.role === 'Team Owner' || state.role === 'Team Manager' || state.role === 'Office Staff',
    subscriptionDetails: (state, getters) => state.subscriptionDetails,
    teamMemberSelfAllocationAllowed: (state) => state.teamMemberSelfAllocationAllowed,
    speedUnits: (state) => state.speedUnits,
    defaultOfferMethod: (state) => state.defaultOfferMethod,
    oldJobOffersEnabled: (state) => state.oldJobOffersEnabled,
    teamType: (state) => state.teamType,
    hasRateRules: (state) => {
        return state.hasRateRules;
    },
    isTeamOwner: (state) => state.role === 'Team Owner',
    applyRatesTo: (state) => state.applyRatesTo,
    canCloneJobs: (state) => (state) =>
        state.role === 'Team Owner' ||
        state.role === 'Team Manager' ||
        state.role === 'Office Staff' ||
        state.role === 'Support Staff'
};

const mutations = {
    [UPDATE_USER]: (state, payload) => {
        // eslint-disable-next-line no-param-reassign
        state = Object.assign(state, payload);
        if (payload.firstName || payload.lastName) {
            state.fullName = `${payload.firstName || state.firstName} ${payload.lastName || ''}`.trim();
        }
    },
    [RESET_STATE]: (state) => Object.assign({}, state, INIT_STATE()),
    [SET_RATE_RULES]: (state, payload) => {
        state.hasRateRules = payload;
    },
    UPDATE_USER_CUSTOM_ACTION_CONFIGURATION: (state, { config }) => {
        state.customActionConfiguration = config;
    }
};

const actions = {
    async [FETCH_USER]({ commit }) {
        const url = '/api/users/me/profile';
        const { data } = await handleRequests(url);
        data.photoUrl = imageCacheBuster(data.photoUrl);
        data.logoUrl = imageCacheBuster(data.logoUrl);

        commit(UPDATE_USER, data);
        return data;
    },
    async [FETCH_RATE_RULES]({ commit }) {
        const url = '/api/rates/hasRateRules';
        const { data } = await handleRequests(url);

        commit(SET_RATE_RULES, data ?? false);
        return data;
    }
};

const state = () => ({ ...INIT_STATE() });

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
