var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serviceAreaList.length > 0
    ? _c(
        "md-field",
        [
          _c("vue-select", {
            attrs: {
              "data-testid": "service-area-filter",
              options: _vm.serviceAreaList,
              reduce: function (x) {
                return x.serviceAreaId
              },
              label: "name",
              placeholder: "Service Area",
              searchable: _vm.$root.isDesktop,
            },
            model: {
              value: _vm.serviceAreaFilterValue,
              callback: function ($$v) {
                _vm.serviceAreaFilterValue = $$v
              },
              expression: "serviceAreaFilterValue",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }