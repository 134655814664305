<template>
    <div>
        <CustomConditionBuilder
            v-for="con in validator.settings.conditions.$each.$iter"
            :key="con.$model.tempKey"
            :condition="con.$model"
            :validator="con"
            @conditionRemoved="conditionRemovedHandler"
            :condition-variables="conditionVariables"
        />
        <div v-if="!hasCondition" class="condition-button-wrapper">
            <md-button @click.prevent="addEmptyCondition">Add Condition Block</md-button>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { CUSTOM_SUB_CONDITION_DEFAULTS } from '@/utils/defaults';
import CustomConditionBuilder from './CustomConditionBuilder';

export default {
    name: 'CustomConditionBuilderList',
    mixins: [GeneralMixin],
    props: {
        customActionModel: { type: Object, default: () => null },
        validator: { type: Object, required: true },
        conditionVariables: { type: Array, default: () => [] }
    },
    components: { CustomConditionBuilder },
    data() {
        return {
            conditionsModel: []
        };
    },
    computed: {
        hasCondition() {
            // right now, we are only allowing 1 condition block (unlike in Placement Booking - Custom)
            // so, we are hiding the 'Add Condition' button when there's already one
            return this.customActionModel.settings.conditions && this.customActionModel.settings.conditions.length;
        }
    },
    methods: {
        addEmptyCondition() {
            if (!this.customActionModel.settings.conditions) 
                this.customActionModel.settings.conditions = [];

            this.customActionModel.settings.conditions.splice(
                this.customActionModel.settings.conditions.length - 1,
                0,
                {
                    tempKey: _.random(-1, -10000),
                    order: this.customActionModel.settings.conditions.length + 1,
                    subConditions: [
                        {
                            ...CUSTOM_SUB_CONDITION_DEFAULTS(),
                            order: 1,
                            tempKey: _.random(-1, -10000)
                        }
                    ],
                    name: 'Custom Condition'
                }
            );

            this.updateConditionOrder();
        },
        updateConditionOrder() {
            const current = [...this.customActionModel.settings.conditions];

            for (let i = 0; i < current.length; i++) {
                current[i].order = i + 1;
            }

            this.$set(this.customActionModel.settings, 'conditions', [...current]);
        },
        conditionRemovedHandler(order) {
            const current = cloneDeep(this.customActionModel.settings.conditions);
            const orderIndex = current.findIndex((x) => x.order === order);
            current.splice(orderIndex, 1);
            if (current.length) {
                this.$set(this.customActionModel.settings, 'conditions', [...current]);
            } else {
                this.$set(this.customActionModel.settings, 'conditions', []);
            }

            this.updateConditionOrder();
        }
    }
};
</script>
