<template>
    <div class="map-overlay1">
        <div class="create-run-layout">
            <div class="optimise-header">
                <div v-for="(header, index) in headerTabs" :key="index">
                    <div :class="step == index ? 'header-cell-highlighted' : 'header-cell'">
                        {{ header }}
                    </div>
                </div>
            </div>
            <div class="wizard-step">
                <div class="wizard-header">
                    <button
                        type="button"
                        class="header-button next-button"
                        :disabled="!isValidated"
                        @click="next()"
                        v-show="step != headerTabs.length - 1"
                    >
                        <span class="button-text">Next</span>
                        <md-icon class="icon-chevron-right">chevron_right</md-icon>
                    </button>

                    <div class="button-separator"></div>

                    <button type="button" class="header-button back-button" @click="prev()" v-show="step != 0">
                        <md-icon class="icon-chevron-left">chevron_left</md-icon>
                        <span class="button-text">Back</span>
                    </button>

                    <span class="header-text">
                        {{ headerTabs[step] }}
                        <span class="header-sub-text">{{ labelHeaderSubText }}</span>
                    </span>
                </div>
                <start-location-details-step
                    v-show="step === 0"
                    :data="optimiseConfiguration"
                    :warehouses="warehouses"
                    @validated="validated"
                    @setStartEndLocationPin="setStartEndLocationPin"
                    @handleStartTeamRegionChanged="handleStartTeamRegionChanged"
                    @setExcludeStopsWithNoDate="onSetExcludeStopsWithNoDate"
                    @handleCustomerChanged="handleCustomerChanged"
                />
                <select-stops-step
                    :stop-list="stopList"
                    :map="map"
                    :stops-markers="stopsMarkers"
                    :stop-list-sorting="stopListSorting"
                    :time-window-filter-options="timeWindowFilterOptions"
                    :skill-options="skillOptions"
                    :start-location-mode="optimiseConfiguration.startLocationMode"
                    :selected-drawn-shape-stops="drawnShapeStops"
                    :drawing-manager="drawingManager"
                    :active-validation-filter="activeValidationFilter"
                    :active-selection-filter="activeSelectionFilter"
                    :optimise-configuration="optimiseConfiguration"
                    :team-regions="teamRegions"
                    :unassigned-stops-without-team-region="unassignedStopsWithoutTeamRegion"
                    :total-stops="totalStopCount"
                    :is-loading="isLoading"
                    :get-capacity-info="getCapacityInfo"
                    :has-capacity-settings="hasCapacitySettings"
                    v-show="step === 1"
                    @selectedAllUnassignedStops="selectedAllUnassignedStops"
                    @selectedUnassignedStops="selectedUnassignedStops"
                    @onStopMouseOverOut="onStopMouseOverOut"
                    @calculateStopsDistance="calculateStopsDistance"
                    @applyTimeWindowFilter="applyTimeWindowFilter"
                    @applyStopsBySkillsFilter="applyStopsBySkillsFilter"
                    @clearTimeWindowFilter="clearTimeWindowFilter"
                    @selectDrawing="selectDrawing"
                    @handleFilterStopsClick="handleFilterStopsClick"
                    @handleFilterValidationClick="handleFilterValidationClick"
                    @handleFilterTeamRegionClick="handleFilterTeamRegionClick"
                    @handleFilterServiceAreaClick="handleFilterServiceAreaClick"
                />
                <team-member-step
                    :team-members="filteredTeamMembers"
                    v-show="step === 2"
                    @selectedTeamMembers="selectedTeamMembers"
                    :start-location-mode="optimiseConfiguration.startLocationMode"
                    :optimise-configuration="optimiseConfiguration"
                    :is-loading="isLoading"
                    :team-regions="teamRegions"
                />
                <trip-summary
                    v-show="step === 3"
                    :trip-list="allocateStops"
                    :unallocated-stop-list="unallocatedStops"
                    :has-displayed="hasDisplayed"
                    :start-location-mode="optimiseConfiguration.startLocationMode"
                    :is-start-routing="isStartRouting"
                    :loaded-save-model="loadedSaveModel"
                    :start-trip-time="optimiseConfiguration.startTime"
                    :end-trip-time="optimiseConfiguration.endTime"
                    @onExportTrips="onExportTrips"
                    @onCreateTrips="onCreateTrips"
                    @handleAssignedStopList="handleAssignedStopList"
                    @handleAssignedTrip="handleAssignedTrip"
                    @handleChangeTeamMemberSettings="handleChangeTeamMemberSettings"
                    @handleUnassignedStopList="handleUnassignedStopList"
                    @allocateStopMarkers="allocateStopMarkers"
                    @onSaveOptimise="onSaveOptimise"
                />
            </div>
        </div>
        <div
            id="map_canvas"
            style="width: 100%; height: 100vh;"
            :class="isTrialExpired ? 'not-paid-map-overlay' : ''"
        />
        <info-window-component
            :content="infoWindowContent"
            :styles="infoWindowStyles"
            :show-info-window="showInfoWindow"
            :loaded-save-model="loadedSaveModel"
            :get-capacity-info="getCapacityInfo"
            :has-capacity-settings="hasCapacitySettings"
            @handleDeleteStop="handleDeleteStop"
            @handleReassignedStop="handleReassignedStop"
            @handleShowStreetView="handleShowStreetView"
            @closeInfoWindow="
                () => {
                    this.showInfoWindow = false;
                }
            "
        />
        <trip-stop-window
            :content="tripStopWindowContent"
            :show-trip-stop-window="showTripStopWindow"
            :loaded-save-model="loadedSaveModel"
            @handleDeleteStop="handleDeleteStop"
            @handleReassignedStop="handleReassignedStop"
            @closeTripStopWindow="closeTripStopWindow"
        />
        <routing-settings-component
            v-if="!loadedSaveModel"
            :start-location-mode="optimiseConfiguration.startLocationMode"
            :is-start-routing="isStartRouting"
            :is-route-loading="isRouteLoading"
            :route-last-changed-time="routeLastChangedTime"
            :total-routed-stops="totalRoutedStops"
            :total-stops="totalStops"
            :is-next-routing="isNextRouting"
            :count-route-changed="countRouteChanged"
            :is-distribute-workload="optimiseConfiguration.isDistributeWorkload"
            @handleRoutingSettings="handleRoutingSettings"
            @onStopRouting="onStopRouting"
            :trip-date="optimiseConfiguration.tripDate"
            :step="step"
            @handleLoadModel="handleLoadModel"
        />
        <AreasLayer
            v-if="optimiseConfiguration.showServiceArea"
            :map="map"
            :drawing-manager="drawingManager"
            :id-name="'rateZoneId'"
            :end-point-name="'/api/teams/service-areas'"
            :load-shapes-only="true"
            :use-mapbox="true"
            :service-area-list-options="serviceAreaOptions"
        />
    </div>
</template>
<script>
import { handleRequests, getRegionCoordinates, isAValidCoordinate } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
// eslint-disable-next-line import/extensions
import InfoWindowComponent from '@/pages/Map/InfoWindow.vue';
import { MapBoxMixin } from '@/mixins/MapBoxMixin';
import { MapOverviewMixin } from '@/mixins/MapOverviewMixin';

import { MarkerColorCode } from '@/utils/MarkerColorCode';
import RoutingSettingsComponent from '@/pages/OptimiseStops/Settings/RoutingSettings';
import SelectStopsStep from '@/pages/OptimiseStops/StepComponents/SelectStopsStep';
import StartLocationDetailsStep from '@/pages/BatchStop/StepComponents/StartLocationDetailsStep';
import TeamMemberStep from '@/pages/BatchStop/StepComponents/TeamMemberStep';
import TripSummary from '@/pages/BatchStop/StepComponents/TripSummary';
import TripStopListModal from '@/pages/BatchStop/TripStopListModal';
import TripStopWindow from '@/pages/BatchStop/TripStopWindow';
import ChangeTripTeamMemberModal from '@/pages/BatchStop/ChangeTripTeamMemberModal';
import moment from 'moment';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { DATE_TYPES } from '@/utils/constants';
import AreasLayer from '@/components/AreasLayer';

function roundNumber(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
}

export default {
    name: 'CreateRun',
    mixins: [MapOverviewMixin, MapBoxMixin, GeneralMixin],
    components: {
        StartLocationDetailsStep,
        SelectStopsStep,
        TeamMemberStep,
        InfoWindowComponent,
        TripSummary,
        TripStopWindow,
        RoutingSettingsComponent,
        AreasLayer
    },
    data() {
        return {
            bounds: null,
            map: null,
            panorama: null,
            startEndLocationMarkers: [],
            headerTabs: ['Trip Details', 'Select Stops', 'Select Team Members', 'Trip Summary'],
            labelHeaderSubText: null,
            step: 0,
            stopList: [],
            stopListSorting: true,
            baseStopList: [],
            shipmentList: [],
            timeWindowFilterOptions: [],
            teamMembers: [],
            warehouses: [],
            selectedUnassignedStopsData: [],
            selectedTeamMembersData: [],
            markerPins: {},
            stopsMarkers: [],
            drawingManager: null,
            activeValidationFilter: {},
            activeSelectionFilter: {},
            drawnShapeStops: [],
            currentDrawingShape: null,
            drawings: [],
            drawingPolygons: [],
            showTripStopWindow: false,
            tripStopWindowContent: {},
            // info window
            showInfoWindow: false,
            infoWindowContent: {},
            infoWindowStyles: {},
            isValidated: true,
            isLoading: false,
            optimiseConfiguration: {
                startLocation: {
                    address: null,
                    location: {
                        latitude: null,
                        longitude: null
                    }
                },
                startLocationMode: null,
                endLocation: {
                    address: null,
                    location: {
                        latitude: null,
                        longitude: null
                    }
                },
                endLocationMode: 'none',
                tripDate: null,
                startTime: null,
                endTime: null,
                teamMemberTimingOverride: false,
                ignoreTimeWindows: false,
                contiguityConstraint: false,
                skillMatching: true,
                addReturnStartLocationStop: false,
                isDistributeWorkload: false,
                includeAssignedStops: false,
                runNumber: null,
                teamRegionId: null,
                warehouseStartLocation: null,
                warehouseEndLocation: null,
                excludeStopsWithNoDate: false
            },
            hasDisplayed: false,
            allocateStops: [],
            unallocatedStops: [],
            allocateStopsResponse: [],
            routingInterval: null,
            timerInterval: null,
            isStartRouting: false,
            isRouteLoading: false,
            isRoutingChanged: false,
            countRouteChanged: 0,
            totalRoutedStops: 0,
            totalStops: 0,
            isNextRouting: false,
            routeLastChangedTime: null,
            optimiseInputData: [],
            routeNotMatchCount: 0,
            modelId: null,
            vehiclesRecords: [],
            unassignedStops: [],
            optimiseStopsMarkers: [],
            totalOptimiseStops: 0,
            isReassigned: false,
            skillOptions: [],
            iterationCount: 0,
            pinnedTrips: [],
            loadedSaveModel: false,
            savedModelName: null,
            unassignedQueryFilters: {},
            membersQueryFilters: {},
            selectedTeamRegionId: null,
            teamRegions: [],
            unassignedStopsWithoutTeamRegion: null,
            hasUnassignedStopFilterChanges: true,
            stopListCount: 0,
            markerGeojson: {
                type: 'FeatureCollection',
                features: []
            },
            shapeUnselectedGeojson: {
                type: 'FeatureCollection',
                features: []
            },
            serviceAreaOptions: [],
            baseServiceAreaOptions: [],
            mapboxEventListeners: []
        };
    },
    watch: {
        'optimiseConfiguration.tripDate': function() {
            if (!this.loadedSaveModel) {
                this.hasUnassignedStopFilterChanges = true;
                this.getTeamRegions();
            }
        },
        'optimiseConfiguration.teamRegionId': function(newValue, oldValue) {
            if (oldValue !== undefined) {
                if (!this.loadedSaveModel) {
                    this.hasUnassignedStopFilterChanges = true;
                }
            }
        },
        'optimiseConfiguration.startLocation.address': function() {
            if (!this.loadedSaveModel) {
                this.calculateStopsDistance(true, true);
            }
        },
        'optimiseConfiguration.startLocationMode': function() {
            if (!this.loadedSaveModel) {
                this.calculateStopsDistance(true, true);
            }
        },
        step() {
            this.setHeaderSubText();
        },
        selectedUnassignedStopsData() {
            this.setHeaderSubText();
        },
        selectedTeamMembersData() {
            this.setHeaderSubText();
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            maxRadiusValue: 'routingSetting/maxRadiusValue',
            maxRadiusUnits: 'routingSetting/maxRadiusUnits',
            getDistanceCost: 'routingSetting/getDistanceCost',
            getTimeCost: 'routingSetting/getTimeCost',
            getFixedCost: 'routingSetting/getFixedCost',
            getRouteCenteringPenalty: 'routingSetting/getRouteCenteringPenalty',
            getDistributeWorkloadCost: 'routingSetting/getDistributeWorkloadCost',
            getWarehouseStartLocation: 'optimiseSetting/getWarehouseStartLocation',
            getWarehouseEndLocation: 'optimiseSetting/getWarehouseEndLocation',
            getExcludeStopsWithNoDate: 'optimiseSetting/getExcludeStopsWithNoDate',
            isTrialExpired: 'team/isTrialExpired'
        }),
        baseStopListCount() {
            return this.baseStopList ? this.baseStopList.length : 0;
        },
        filteredTeamMembers() {
            if (!this.teamMembers) 
                return [];
            const teamRegionId = this.selectedTeamRegionId;
            const res = this.teamMembers.filter((x) => {
                return !teamRegionId || x.teamRegionId === teamRegionId || x.parentTeamRegionId === teamRegionId;
            });
            return res;
        },
        totalStopCount() {
            return this.stopList ? this.stopList.length : 0;
        }
    },
    methods: {
        ...mapActions({
            FETCH_ROUTE_SETTINGS: 'routingSetting/FETCH_ROUTE_SETTINGS'
        }),
        ...mapMutations({
            saveStartTime: 'optimiseSetting/UPDATE_START_TIME',
            saveEndTime: 'optimiseSetting/UPDATE_END_TIME'
        }),
        setHeaderSubText() {
            switch (this.step) {
                case 1:
                    this.labelHeaderSubText = `(${this.selectedUnassignedStopsData.length})`;
                    break;
                case 2:
                    this.labelHeaderSubText = `(${this.selectedTeamMembersData.length})`;
                    break;
                default:
                    this.labelHeaderSubText = null;
            }
        },
        handleRoutingSettings(response) {
            const { isChange } = response;
            if (isChange && this.step === 3) {
                this.$messageBox
                    .show({
                        class: 'sm-modal-container',
                        title: 'Routes Setting',
                        body: 'Would you like to recalculate the routes?',
                        buttons: ['Yes', 'No']
                    })
                    .then(async (response) => {
                        if (response.toLowerCase() === 'yes') {
                            this.clearAllocateStops();
                            await this.onCreateMyRuns();
                        }
                    });
            }
        },
        handleAssignedTrip(vehicleId) {
            this.$modal
                .show(ChangeTripTeamMemberModal, {
                    teamMembers: this.teamMembers,
                    assignedTo: vehicleId
                })
                .then((response) => {
                    this.handleAssignedUser(response);
                });
        },
        handleAssignedUser(data) {
            const teamMember = this.teamMembers.find((val) => {
                return val.publicUserId === data.assignedUser;
            });
            const allocateStopsResponseIndex = this.allocateStopsResponse.vehicles.findIndex((val) => {
                return val.vehicleId === data.assignedTo;
            });
            const allocateStopsIndex = this.allocateStops.findIndex((val) => {
                return val.vehicleId === data.assignedTo;
            });
            this.allocateStopsResponse.vehicles[allocateStopsResponseIndex].vehicleId = data.assignedUser;
            this.allocateStops[allocateStopsIndex].vehicleId = data.assignedUser;
            this.allocateStops[allocateStopsIndex].teamMember = teamMember;
        },
        // Function is created for calculating current load and available capacity.
        async calculateVehicleLoadAndCapacity(tripDetails) {
            if (
                !tripDetails.vehicle.vehicleLoad ||
                !this.user.vehicleCapacityUnitsConfiguration ||
                this.user.vehicleCapacityUnitsConfiguration.length <= 0
            )
                return;

            const vehicleLoadTypes = Object.keys(tripDetails.vehicle.vehicleLoad);
            vehicleLoadTypes.forEach((type) => {
                const { defaultLoad, defaultMaxCapacity } = this.user.vehicleCapacityUnitsConfiguration.find(
                    (x) => x.type === type
                );
                const teamMember = this.teamMembers.find((t) => t.publicUserId === tripDetails.vehicleId);
                let vehicleCapacity = teamMember.vehicleCapacity[type] ?? defaultMaxCapacity;
                let currentVehicleLoad = 0;

                // Calculate the starting load
                tripDetails.stops
                    .filter(
                        (stop) =>
                            stop.type !== 'SHIPMENT_PICKUP' &&
                            stop.type !== 'SHIPMENT_DELIVERY' &&
                            stop.locationType !== 'Start Location' &&
                            stop.locationType !== 'End Location'
                    )
                    .forEach((stop) => {
                        if (stop.load) {
                            const stopLoadValue = !stop.load[type] ? defaultLoad : Number(stop.load[type]); // Need to use Number() since some loads have the string type
                            currentVehicleLoad += stopLoadValue;
                        }
                    });
                if (!vehicleCapacity) {
                    vehicleCapacity = currentVehicleLoad; // if no set maximum vehicle capacity then set a total stop load a maximum capacity
                }
                // Now calculate the running totals
                tripDetails.stops.forEach((stop) => {
                    if (!stop.vehicleLoad) {
                        stop.vehicleLoad = {};
                    }

                    if (!stop.availableCapacity) {
                        stop.availableCapacity = {};
                    }

                    if (stop.locationType === 'Start Location') {
                        stop.vehicleLoad[type] = roundNumber(currentVehicleLoad);
                        stop.availableCapacity[type] = roundNumber(vehicleCapacity - currentVehicleLoad);
                    } else if (stop.locationType === 'End Location') {
                        stop.vehicleLoad[type] = 0;
                        stop.availableCapacity[type] = roundNumber(vehicleCapacity);
                    } else if (stop.load) {
                        const stopLoadValue = !stop.load[type] ? defaultLoad : Number(stop.load[type]); // Need to use Number() since some loads have the string type
                        stop.load[type] = stopLoadValue;

                        if (stop.type !== 'SHIPMENT_PICKUP') {
                            currentVehicleLoad -= stopLoadValue;
                        } else {
                            currentVehicleLoad += stopLoadValue;
                        }

                        stop.vehicleLoad[type] = roundNumber(currentVehicleLoad);
                        stop.availableCapacity[type] = roundNumber(vehicleCapacity - currentVehicleLoad);
                    }
                });
            });
        },

        async handleAssignedStopList(vehicleId, selectedCapacityType) {
            const tripDetails = this.allocateStops.find((s) => s.vehicleId === vehicleId);
            if (selectedCapacityType) {
                await this.calculateVehicleLoadAndCapacity(tripDetails);
            }
            this.tripStopWindowContent = {
                stopList: tripDetails.stops,
                teamMember: tripDetails.teamMember,
                allTrips: this.allocateStops,
                tripCustomerId: tripDetails.teamMember?.tripDetails?.customerId,
                vehicleId,
                selectedCapacityType
            };
            if (!this.showTripStopWindow) {
                this.showTripStopWindow = true;
            }
        },
        handleUnassignedStopList(stops) {
            this.$modal
                .show(TripStopListModal, {
                    stopList: stops,
                    teamMemberName: 'Unallocated',
                    hasCapacitySettings: this.hasCapacitySettings,
                    getCapacityInfo: this.getCapacityInfo
                })
                .then(async (response) => {
                    await this.onCreateMyRuns();
                });
        },
        setStopsRoutePathMarkers(trip, fitBound) {
            trip.routePath.forEach((s) => {
                const pins = this.markerPins[s];
                this.$_mapBox_showRoute(s, this.map, pins.color);
            });

            if (trip.isPinned) {
                if (trip.stopMarkers.length === 0) {
                    const pins = this.markerPins[trip.vehicleId];
                    // Check the Start and End Location is same
                    const isSameLocation = trip.vehicle.startAddress === trip.vehicle.endAddress;

                    this.setStopStartEndMarkers(trip, pins, 'startLocation', isSameLocation);
                    /* eslint-disable no-unused-vars */
                    const sameMarkerIndexes = [];
                    trip.stops.forEach((stop, stopIndex) => {
                        if (stop.stopId || stop.shipmentId) {
                            // get all markers with the same coordinates
                            const sameMarkers = trip.stops.filter(
                                (m) => m.latitude === stop.latitude && m.longitude === stop.longitude
                            );

                            // if there is more than one marker with the same coordinates
                            if (sameMarkers.length > 1) {
                                let offset = 0;
                                /* eslint-disable no-unused-vars */
                                const grpIndex = sameMarkerIndexes.find(
                                    (x) => x.lat === stop.latitude && x.lng === stop.longitude
                                );
                                if (grpIndex) {
                                    // calculate the offset for the current marker
                                    offset = 13 * (grpIndex.index - (sameMarkers.length - 1) / 2);
                                    grpIndex.index += 1;
                                } else {
                                    sameMarkerIndexes.push({ lat: stop.latitude, lng: stop.longitude, index: 0 });
                                }
                                // set the offset
                                this.setRouteMarkers(stopIndex, stop, pins, trip, offset);
                            } else {
                                this.setRouteMarkers(stopIndex, stop, pins, trip);
                            }
                        } else if (stop.driverBreakId) {
                            this.setDriverBreakMarkers(stopIndex, stop, pins, trip);
                        }
                    });
                    if (!isSameLocation && trip.vehicle.endLocation) {
                        this.setStopStartEndMarkers(trip, pins, 'endLocation');
                    }
                } else {
                    trip.stopMarkers.forEach((s) => {
                        this.$_mapBox_addMapElement(s, this.map);
                    });
                }

                if (fitBound) {
                    const routeCoordinates = trip.stops
                        .filter((stop) => stop.longitude !== undefined && stop.latitude !== undefined)
                        .map((stop) => [stop.longitude, stop.latitude]);

                    routeCoordinates.push([trip.vehicle.startLocation.longitude, trip.vehicle.startLocation.latitude]);

                    this.pinnedTrips.forEach((x) => {
                        const stopCoordinates = x.stops
                            .filter((stop) => stop.longitude !== undefined && stop.latitude !== undefined)
                            .map((stop) => [stop.longitude, stop.latitude]);
                        stopCoordinates.forEach((x) => routeCoordinates.push(x));
                    });

                    const coordinates = this.$_mapBox_getMaxBounds(routeCoordinates);
                    this.$_mapBox_callMapFunction(this.map, 'fitBounds', coordinates);
                }
            }
        },
        allocateStopMarkers() {
            const isActive = this.allocateStops.find((s) => s.isPinned === true);
            const oldPinnedTrips = this.pinnedTrips;
            this.pinnedTrips = [];
            this.allocateStops.forEach((trip) => {
                const oldPinnedIndex = oldPinnedTrips.findIndex((x) => x.vehicleId === trip.vehicleId);
                if (!trip.isPinned) {
                    // Clear all route path and markers
                    trip.stopMarkers.forEach((s) => this.$_mapBox_removeElement(s));
                    trip.routePath.forEach((s) => {
                        this.$_mapBox_hideRoute(s, this.map);
                    });
                } else {
                    const pins = this.markerPins[trip.vehicleId];
                    this.$_mapBox_showRoute(trip.vehicleId, this.map, pins.color);
                    this.pinnedTrips.push(trip);
                }
                if (trip.isPinned || !isActive) {
                    const fitBound = oldPinnedIndex === -1 || !this.isStartRouting;
                    this.setStopsRoutePathMarkers(trip, fitBound);
                }
            });
        },
        removeAllocateStopMarkers(vehicleId) {
            const allocateStopIndex = this.allocateStops.findIndex((val) => {
                return val ? val.vehicleId === vehicleId : -1;
            });
            if (allocateStopIndex !== -1) {
                const allocateStopsData = this.allocateStops[allocateStopIndex];

                // Clear all route path and markers
                allocateStopsData.stopMarkers.forEach((s) => this.$_mapBox_removeElement(s));
                allocateStopsData.routePath.forEach((s) => this.$_mapBox_clearRoute(s, this.map));

                this.allocateStops.splice(allocateStopIndex, 1);
            }
        },
        validated(value) {
            this.isValidated = value && !!this.optimiseConfiguration.startLocationMode;
        },
        setStopsMarkers(stop, isDropStop) {
            if (!stop.isOutside && !this.drawnShapeStops.length && !isDropStop) {
                this.selectedUnassignedStopsData.push(stop.shipmentId || stop.stopId);
            }
            this.stopsMarkers.push(stop);
        },
        showStopsMarkers(stops, pins) {
            const { data } = this.shipmentList;
            let allStops = stops;
            if (data.length) {
                allStops = this.baseStopList.concat(data);
                this.baseStopList = allStops;
                this.stopList = allStops;
                if (!this.loadedSaveModel) {
                    this.calculateStopsDistance(true);
                    this.setTimeWindowFilter(this.stopList);
                }
            }
            if (this.drawnShapeStops.length) {
                this.selectedUnassignedStopsData = this.drawnShapeStops;
            }
            allStops.forEach((s) => {
                if (s.shipmentId) {
                    s.pickupStop.isOutside = s.isOutside;
                    s.pickupStop.shipmentId = s.shipmentId;
                    s.pickupStop.skills = s.skills;
                    s.pickupStop.isSelected = true;
                    this.setStopsMarkers(s.pickupStop);
                    this.$_mapBox_add_geojson_marker(s.pickupStop, this.markerGeojson);

                    s.dropStop.shipmentId = s.shipmentId;
                    s.dropStop.isOutside = s.isOutside;
                    s.dropStop.skills = s.skills;
                    s.dropStop.isSelected = true;
                    this.setStopsMarkers(s.dropStop, true);
                    this.$_mapBox_add_geojson_marker(s.dropStop, this.markerGeojson);
                } else if (s.stopId) {
                    s.isSelected = true;
                    this.$_mapBox_add_geojson_marker(s, this.markerGeojson);
                    this.setStopsMarkers(s);
                }
            });
            this.$_mapBox_setData(this.map, 'stopPoints', this.markerGeojson);
            this.$_handleLoaderState(false);
        },
        handleClickedRouteLine(vehicleId) {
            const trip = this.allocateStops.find((x) => x.vehicleId === vehicleId);
            this.allocateStops.find((x) => x.vehicleId === vehicleId).isPinned = !trip.isPinned;
            this.allocateStopMarkers();
        },
        getMarkerCoordinates(marker, options) {
            const offset = this.step !== 3 ? 120 : 0;
            const markerCoordinates = this.$_mapbox_calculateMarkerCoordinates(marker, this.map, options);
            markerCoordinates.top = `${parseInt(markerCoordinates.top, 10) - offset}px`;

            return markerCoordinates;
        },
        handleClickedMarker(data, marker, type, reallocateStop) {
            const {
                markerType,
                status,
                address,
                fullName,
                phone,
                stopId,
                trackingLinkUrl,
                stopRef,
                vehicleId,
                contact,
                arrivalDate,
                shipmentId,
                sourceReference,
                load,
                skills,
                serviceLines
            } = data;

            let location = null;
            if (data.stops) {
                const stopData = data.stops.find((s) => s.stopId === data.stopId);
                if (stopData) {
                    location = { latitude: stopData.latitude, longitude: stopData.longitude };
                }
            }

            const teamMember = this.teamMembers.find((t) => t.publicUserId === vehicleId);

            this.infoWindowStyles = {
                '--infowindow-width': type === 'stop' || type === 'optimisedStop' ? '290px' : '240px',
                '--tooltip-placement': '120px',
                '--text-max-width': '200px'
            };

            const obj = {
                address,
                teamMemberName: fullName,
                photoUrl: teamMember && teamMember.photoUrl,
                contactName: contact && contact.name,
                contactPhone: phone ?? contact?.phone,
                coordinates: location,
                stopId,
                shipmentId,
                status,
                trackingLinkUrl,
                originalEta: arrivalDate,
                type,
                reallocateStop,
                stopRef,
                vehicleId,
                teamMembers: this.teamMembers,
                allocateStops: this.allocateStops,
                sourceReference,
                load,
                skills,
                serviceLines
            };

            this.infoWindowContent = obj;
            const options = {
                height: type === 'optimisedStop' ? 100 : 280,
                width: 128
            };
            if (markerType === 'location') {
                options.height = 210;
            }

            const markerCoordinates = this.getMarkerCoordinates(marker, options);

            // for setting the location of the tooltip (1/2 the width of tooltip + 1/2 width of marker icon)
            this.infoWindowStyles = {
                ...this.infoWindowStyles,
                ...markerCoordinates
            };

            if (!this.showInfoWindow) {
                this.showInfoWindow = true;
            }
        },
        prev() {
            if (this.loadedSaveModel) {
                this.step = 0;
                this.loadedSaveModel = false;
                this.selectedUnassignedStopsData = [];
                this.clearAllocateStops();
                this.calculateStopsDistance(true);
                this.filterStopMapPins(this.stopList);
            } else {
                this.step -= 1;
            }
            this.isValidated = true;
            this.pinnedTrips = [];
            if (this.step !== 0) {
                this.onStopRouting();
                this.clearAllocateStops();
                this.pinAllStopsMarkers(this.selectedUnassignedStopsData);
            }
            if (this.step === 1) {
                this.showHideUnselectStopsMarkers(false);
            }
        },
        next() {
            this.step += 1;
            if (this.step === 1 && this.hasUnassignedStopFilterChanges) {
                this.getUnassignedStopList();
                this.hasUnassignedStopFilterChanges = false;
            }
            if (this.step === 2) {
                this.clearUnselectedShapeStopMarkers();
                this.clearDrawing();
            }
            if (this.step === 3) {
                this.onCreateMyRuns();
            }
            this.checkTabValidation();
        },
        getGroupDetails(userId, group, id) {
            return this[group].find((d) => d[id] === userId);
        },
        showHideUnselectStopsMarkers(isClear) {
            if (this.stopsMarkers.some((marker) => marker.isSelected)) {
                this.stopsMarkers.forEach((marker) => {
                    if (!marker.isSelected && isClear) {
                        this.$_mapBox_remove_geojson_marker(marker, this.markerGeojson);
                    } else if (!marker.isSelected && !isClear) {
                        this.$_mapBox_add_geojson_marker(marker, this.markerGeojson);
                    }
                });
                this.$_mapBox_setData(this.map, 'stopPoints', this.markerGeojson);
            }
        },
        setMarkersInArea(marker, shape, drawingType, hasExistingShapes) {
            let stopIsInsideShape = false;
            if (drawingType === 'circle') {
                const radiusInMeters = shape.features[0].properties.radiusInKm * 1000;
                const { center } = shape.features[0].properties;
                stopIsInsideShape =
                    this.$_mapbox_computeDistance(marker.location, { longitude: center[0], latitude: center[1] }) <=
                    radiusInMeters;
            } else if (drawingType === 'polygon') {
                const data = this.drawingManager.getAll();
                stopIsInsideShape = this.$_mapbox_containsLocation(marker, data);
            }

            // This will indicate if the stop is part of the shape before the shape was updated.
            const drawnShapeStopIndex = this.drawnShapeStops.findIndex((x) => x === marker.stopId);
            if (stopIsInsideShape) {
                marker.isSelected = true;
                this.$_mapBox_add_geojson_marker(marker, this.markerGeojson);
                this.$_mapBox_remove_geojson_marker(marker, this.shapeUnselectedGeojson);
                if (!hasExistingShapes || this.selectedUnassignedStopsData.findIndex((x) => x === marker.stopId) < 0) {
                    this.drawnShapeStops.push(marker.shipmentId || marker.stopId);
                }
            } else {
                /* eslint-disable no-lonely-if */
                if (
                    !hasExistingShapes ||
                    this.selectedUnassignedStopsData.findIndex((x) => x === marker.stopId) < 0 ||
                    drawnShapeStopIndex > -1
                ) {
                    marker.isSelected = false;
                    this.$_mapBox_add_geojson_marker(marker, this.shapeUnselectedGeojson);
                    this.$_mapBox_remove_geojson_marker(marker, this.markerGeojson);
                    // Remove it from the list if the shape is updated and it's not part of the new shape.
                    if (drawnShapeStopIndex > -1) {
                        this.drawnShapeStops.splice(drawnShapeStopIndex, 1);
                    }
                }
            }
        },
        selectDrawing(mode) {
            this.clearDrawing();
            if (mode === 'polygon') {
                this.currentDrawingShape = 'polygon';
                this.drawingManager.changeMode(this.drawingManager.modes.DRAW_POLYGON);
            } else if (mode === 'circle') {
                this.currentDrawingShape = 'circle';
                this.drawingManager.changeMode('draw_circle', { initialRadiusInKm: 10 });
            } else {
                this.currentDrawingShape = null;
            }
        },
        findMarkersInArea(shape, drawingType) {
            const hasExistingShapes = this.shapeUnselectedGeojson.features.length > 0;
            // find markers in area
            this.stopsMarkers.forEach((marker) => {
                this.setMarkersInArea(marker, shape, drawingType, hasExistingShapes);
            });
            if (hasExistingShapes) {
                if (this.drawnShapeStops) {
                    if (!this.selectedUnassignedStopsData) {
                        this.selectedUnassignedStopsData = this.drawnShapeStops;
                    } else {
                        this.drawnShapeStops.forEach((s) => {
                            const unassignedIndex = this.selectedUnassignedStopsData.findIndex((u) => u === s);
                            if (unassignedIndex < 0) {
                                this.selectedUnassignedStopsData.push(s);
                            }
                        });
                    }
                }
            } else {
                this.selectedUnassignedStopsData = this.drawnShapeStops;
            }

            this.$_mapBox_setData(this.map, 'stopPoints', this.markerGeojson);
            this.$_mapBox_setData(this.map, 'unSelectedStopPoints', this.shapeUnselectedGeojson);
        },
        clearUnselectedShapeStopMarkers() {
            this.shapeUnselectedGeojson = {
                type: 'FeatureCollection',
                features: []
            };
            this.$_mapBox_setData(this.map, 'unSelectedStopPoints', this.shapeUnselectedGeojson);
        },
        clearDrawing() {
            this.drawingManager.deleteAll();
            this.drawnShapeStops = [];
        },
        initializeMap() {
            // get starting location of user. if there is none, default to Australia
            const { latitude, longitude } = this.user.startLocation
                ? this.user.startLocation
                : getRegionCoordinates('AU');

            this.map = this.$_mapBox_createMap('map_canvas', latitude, longitude, null, this.callRoutingInterval);
            this.drawingManager = this.$_map_drawingManager();
            this.map.addControl(this.drawingManager);

            const clear = () => {
                this.drawingManager.clearDrawing = true;
                if (this.step === 2) {
                    this.clearDrawing();
                }
            };

            const onDrawShapeEventComplete = (polygon) => {
                this.findMarkersInArea(polygon, this.currentDrawingShape);
                this.drawingPolygons.push(polygon);
            };

            this.drawnShapeStops = [];
            this.skillOptions = this.user.skillOptions;
            this.$_mapBox_handleListener(this.map, 'draw.create', onDrawShapeEventComplete);
            this.$_mapBox_handleListener(this.map, 'draw.update', onDrawShapeEventComplete);
            this.$_mapBox_handleListener(this.map, 'click', clear);
            this.$_mapBox_handleListener(this.map, 'marker-click', (event) => this.displayCard(event));
        },
        clearStopsMarkers() {
            if (this.map.getSource('stopPoints')) {
                this.markerGeojson = {
                    type: 'FeatureCollection',
                    features: []
                };
                this.$_mapBox_setData(this.map, 'stopPoints', this.markerGeojson);

                if (this.stopsMarkers) {
                    this.stopsMarkers.forEach((s) => {
                        s.isSelected = false;
                    });
                }
                // this.clearUnselectedShapeStopMarkers();
                // this.clearDrawing();
            }
            if (this.allocateStops) {
                this.allocateStops.forEach((trip) => {
                    if (trip.stopsMarkers) {
                        // Clear all route path and markers
                        trip.stopsMarkers.forEach((s) => {
                            this.$_mapBox_removeElement(s);
                        });
                        trip.routePath.forEach((s) => {
                            this.$_mapBox_clearRoute(s);
                        });
                    }
                });
            }
        },
        pinAllStopsMarkers(stops) {
            this.$_handleLoaderState(true);
            this.stopsMarkers.forEach((s) => {
                const selectedStop = stops.find((stopId) => {
                    return stopId === s.stopId || stopId === s.shipmentId;
                });
                if (selectedStop) {
                    this.$_mapBox_add_geojson_marker(s, this.markerGeojson);
                }
            });
            this.$_mapBox_setData(this.map, 'stopPoints', this.markerGeojson);
            this.$_handleLoaderState(false);
        },
        pinStopsMarkers(stopId, shipmentId, isSelected) {
            const markers = this.stopsMarkers.filter((stop) => {
                return stop.stopId === stopId || (shipmentId && stop.shipmentId === shipmentId);
            });
            markers.forEach((marker) => {
                if (marker && isSelected) {
                    this.$_mapBox_add_geojson_marker(marker, this.markerGeojson);
                } else {
                    this.$_mapBox_remove_geojson_marker(marker, this.markerGeojson);
                }
            });
            this.$_mapBox_setData(this.map, 'stopPoints', this.markerGeojson);
        },
        hasQueryChanged(newQuery, prevQuery) {
            let shouldSearch = false;
            Object.getOwnPropertyNames(newQuery).forEach((q) => {
                shouldSearch = shouldSearch || (newQuery[q] || null) !== (prevQuery[q] || null);
            });

            return shouldSearch;
        },

        async getUnassignedShipmentList(inputTripDate) {
            let { tripDate } = this.optimiseConfiguration;
            if (inputTripDate) {
                tripDate = inputTripDate;
            }

            const qry = new URLSearchParams();
            qry.append('tripDate', this.$options.filters.dateFormat(tripDate, this.DATE_TYPES.internationalDate));
            qry.append('teamRegionId', this.optimiseConfiguration.teamRegionId);
            qry.append('excludeStopsWithNoDate', this.getExcludeStopsWithNoDate);
            qry.append('customerId', this.optimiseConfiguration.customerId);

            const api = `/api/shipments/list/unassigned/?${qry}`;
            this.shipmentList = await handleRequests(api);
        },
        onSetExcludeStopsWithNoDate(excludeStopsWithNoDate) {
            this.getUnassignedStopList();
        },
        async getUnassignedStopList(inputTripDate) {
            this.isLoading = true;
            this.selectedUnassignedStopsData = [];
            await this.getUnassignedShipmentList();
            let { tripDate } = this.optimiseConfiguration;
            if (inputTripDate) {
                tripDate = inputTripDate;
            }

            const newQry = {
                tripDate: this.$options.filters.dateFormat(tripDate, this.DATE_TYPES.internationalDate),
                teamRegionId: this.optimiseConfiguration.teamRegionId,
                excludeStopsWithNoDate: this.getExcludeStopsWithNoDate,
                customerId: this.optimiseConfiguration.customerId
            };
            const shouldSearch = this.hasQueryChanged(newQry, this.unassignedQueryFilters);

            const api = `/api/stops/unassigned/?tripDate=${this.$options.filters.dateFormat(
                tripDate,
                this.DATE_TYPES.internationalDate
            )}&teamRegionId=${this.optimiseConfiguration.teamRegionId}&excludeStopsWithNoDate=${
                this.getExcludeStopsWithNoDate
            }&customerId=${this.optimiseConfiguration.customerId}`;

            if (!shouldSearch) {
                this.isLoading = false;
                return Promise.resolve();
            }
            this.unassignedQueryFilters = newQry;
            return handleRequests(api)
                .then((response) => {
                    this.$_handleLoaderState(true, 'PROCESSING...');
                    const { data } = response;
                    this.setStartEndLocationPin('start', false);
                    this.setStartEndLocationPin('end', false);
                    this.clearStopsMarkers();
                    this.stopsMarkers = [];
                    this.baseStopList = data;
                    this.stopList = data;

                    if (!this.loadedSaveModel) {
                        this.calculateStopsDistance(true);
                        this.setTimeWindowFilter(this.stopList);
                        this.showStopsMarkers(this.stopList);
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$_handleLoaderState(false);
                });
        },
        async getTeamMembers() {
            const { teamRegionId, runNumber } = this.optimiseConfiguration;

            const newQry = {
                date: moment(this.optimiseConfiguration.tripDate).format(DATE_TYPES.internationalDate),
                includeLocationDetails: true,
                includeTripDetails: true,
                teamRegionId,
                runNumber,
                strictTeamRegionFilter: false,
                elevatedTeamRegionFilter: true
            };
            const shouldSearch = this.hasQueryChanged(newQry, this.membersQueryFilters);

            if (!shouldSearch) 
                return;

            this.membersQueryFilters = newQry;
            this.isLoading = true;

            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS', newQry);

            this.teamMembers.forEach((d, i) => {
                this.markerPins = Object.assign(this.markerPins, {
                    [d.publicUserId]: MarkerColorCode[i % MarkerColorCode.length]
                });
                d.color = this.markerPins[d.publicUserId].color;
            });
            this.isLoading = false;
        },
        async getTeamRegions() {
            const api = `api/teams/team-regions/list?countUnassignedStops=true&tripDate=${
                this.optimiseConfiguration.tripDate
            }&excludeStopsWithNoDate=${this.getExcludeStopsWithNoDate}`;

            this.$_handleLoaderState(true);
            await handleRequests(api)
                .then((response) => {
                    const { data } = response;
                    this.teamRegions = [...data.teamRegions];
                    this.unassignedStopsWithoutTeamRegion = data.unassignedStopsWithoutTeamRegion || 0;

                    this.$_handleLoaderState(false);
                })
                .catch(() => {
                    this.$_handleLoaderState(false);
                });
        },
        getWarehouses() {
            const api = `/api/warehouses?itemsPerPage=500`;
            return handleRequests(api).then((response) => {
                const { warehouses } = response.data;
                this.warehouses = warehouses;
                this.optimiseConfiguration.warehouseStartLocation = this.getWarehouseStartLocation;
                this.optimiseConfiguration.warehouseEndLocation = this.getWarehouseEndLocation;
            });
        },
        setUnassignedStops(stops) {
            this.selectedUnassignedStopsData = [];
            stops.forEach((s) => {
                if (!s.isOutside && s.stopId) {
                    this.selectedUnassignedStopsData.push(s.stopId);
                }
            });
        },
        setSelectedUnassignedStops(stops) {
            this.selectedUnassignedStopsData = [];
            stops.forEach((s) => {
                const stop = this.stopList.find((stop) => stop.stopId === s || stop.shipmentId === s);
                if (stop && !stop.isOutside) {
                    this.selectedUnassignedStopsData.push(s);
                }
            });
        },
        calculateStopsDistance(isSort, isChangeMode) {
            this.stopListSorting = isSort;
            const maxRadiusMetres = this.getMaxRadiusMetres();
            if (this.optimiseConfiguration.startLocationMode === 'manual') {
                const startLocation = this.optimiseConfiguration.startLocation.location;
                this.stopList.forEach((stop) => {
                    if (stop.location) {
                        stop.locationData = {
                            address: stop.address,
                            location: stop.location
                        };
                        stop.startLocation = startLocation;
                        const distanceMetres = this.$_mapbox_computeDistance(startLocation, stop.location);
                        const isOutside = distanceMetres >= maxRadiusMetres;
                        if (isAValidCoordinate(stop.address) && isOutside) {
                            stop.locationData.address = null;
                        }
                        stop.isOutside = isOutside;
                        stop.distance = distanceMetres && (distanceMetres / 1000).toFixed(2);
                    } else if (stop.shipmentId) {
                        stop.locationData = {
                            address: stop.pickupStop.address,
                            location: stop.pickupStop.location
                        };
                        stop.startLocation = startLocation;
                        const distanceMetres = this.$_mapbox_computeDistance(startLocation, stop.pickupStop.location);
                        const isOutside = distanceMetres >= maxRadiusMetres;
                        if (isAValidCoordinate(stop.pickupStop.address) && isOutside) {
                            stop.locationData.address = null;
                        }
                        stop.isOutside = isOutside;
                        stop.distance = distanceMetres && (distanceMetres / 1000).toFixed(2);
                    } else {
                        stop.isOutside = true;
                    }
                });
            } else {
                this.stopList.forEach((stop) => {
                    stop.isOutside = false;
                    stop.locationData = {
                        address: stop.address,
                        location: stop.location
                    };
                });
            }
            if (isChangeMode) {
                this.setUnassignedStops(this.stopList);
                if (this.stopList.length) {
                    this.stopList.push({}); // pushing an empty object to refresh stopList on select stops step
                }
            }
            this.checkTabValidation();
        },
        getMaxRadiusMetres() {
            let distanceValue = 300000; // Default distance is less than 300km
            if (!this.maxRadiusUnits) {
                return distanceValue;
            }
            if (this.maxRadiusUnits === 'MILES') {
                distanceValue = this.maxRadiusValue * 1610;
            } else {
                distanceValue = this.maxRadiusValue * 1000;
            }

            return distanceValue;
        },
        selectedAllUnassignedStops(stops, hasShapeSelected) {
            if (!hasShapeSelected) {
                this.setSelectedUnassignedStops(stops);
            }

            if (stops.length > 0) {
                this.clearUnselectedShapeStopMarkers();
                this.pinAllStopsMarkers(stops);
            } else {
                /* eslint-disable no-lonely-if */
                if (this.currentDrawingShape === null) {
                    this.clearStopsMarkers();
                }
            }

            if (this.headerTabs[this.step] === 'Select Stops' || hasShapeSelected) {
                this.isValidated = !!stops.length || !!this.drawnShapeStops.length;
            }
        },
        selectedUnassignedStops(stopId, shipmentId, isSelected, stops) {
            this.setSelectedUnassignedStops(stops);
            if (stopId || shipmentId) {
                this.pinStopsMarkers(stopId, shipmentId, isSelected);
                this.isValidated = stops.length;
            }
            if (this.headerTabs[this.step] === 'Select Stops') {
                this.isValidated = !!stops.length;
            }
        },
        onStopMouseOverOut(stopId, shipmentId, isOver) {},
        selectedTeamMembers(teamMembers) {
            this.selectedTeamMembersData = teamMembers;
            if (this.step === 2) {
                this.isValidated = teamMembers.length > 0 && !!this.optimiseConfiguration.startLocationMode;
            }
        },
        async onCreateTrips(isFixedStopOrder) {
            this.onStopRouting();
            this.$_handleLoaderState(true, 'PROCESSING...');
            const mapVehicleIds = this.allocateStopsResponse.vehicles.map((trip) => trip.vehicleId);
            const isDuplicate = mapVehicleIds.some((trip, vehicleId) => {
                return mapVehicleIds.indexOf(trip) !== vehicleId;
            });
            if (!isDuplicate) {
                const unassignedStops = [];
                this.allocateStopsResponse.vehicles.forEach((trip) => {
                    if (trip) {
                        const vehicle = this.selectedTeamMembersData.find((x) => x.publicUserId === trip.vehicleId);
                        unassignedStops.push({
                            vehicleId: trip.vehicleId,
                            vehicleStartTime: trip.vehicleStartTime,
                            vehicleEndTime: trip.vehicleEndTime,
                            stops: trip.stops,
                            startAddress: trip.startAddress,
                            startLocation: trip.startLocation,
                            endAddress: trip.endAddress,
                            endLocation: trip.endLocation,
                            tripStartTime: trip.tripStartTime,
                            tripEndTime: trip.tripEndTime,
                            distanceCost: trip.distanceCost,
                            timeCost: trip.timeCost,
                            vehicleCapacityString: vehicle ? vehicle.vehicleCapacityString : null
                        });
                    }
                });
                const { tripDate } = this.optimiseConfiguration;
                const api = `api/stops/optimise/create-trips`;
                const payload = {
                    method: 'post',
                    data: {
                        vehicles: unassignedStops,
                        routeModelId: this.allocateStopsResponse.routeModelId,
                        transactionId: this.allocateStopsResponse.transactionId,
                        tripDate: moment(this.optimiseConfiguration.tripDate).format(DATE_TYPES.internationalDate),
                        addReturnStartLocationStop: this.optimiseConfiguration.addReturnStartLocationStop,
                        runNumber: this.optimiseConfiguration.runNumber || null,
                        isFixedStopOrder,
                        teamRegionId: this.optimiseConfiguration.teamRegionId,
                        warehouseStartLocationId: this.optimiseConfiguration.warehouseStartLocation,
                        warehouseEndLocationId: this.optimiseConfiguration.warehouseEndLocation,
                        UseBackgroundProcess: true
                    }
                };
                await handleRequests(api, payload)
                    .then((response) => {
                        this.$_handleLoaderState(false);
                        this.$router.push({
                            path: `/trips/list/${this.$options.filters.dateFormat(
                                tripDate,
                                this.DATE_TYPES.internationalDate
                            )}`
                        });
                    })
                    .catch(() => {
                        this.$_handleLoaderState(false);
                    });
            } else {
                this.$_handleLoaderState(false);
                this.$notify({
                    message: 'Please select different team members for each trip',
                    horizontalAlign: 'right',
                    verticalAlign: 'bottom',
                    type: 'danger',
                    duration: 10000,
                    width: 35
                });
            }
        },
        async onCreateMyRuns(runRouteModelId) {
            if (
                this.optimiseConfiguration.startLocationMode !== 'teammembers' &&
                (!this.optimiseConfiguration.startLocation ||
                    !this.optimiseConfiguration.startLocation.location ||
                    (!this.optimiseConfiguration.startLocation.location.latitude &&
                        !this.optimiseConfiguration.startLocation.location.latitude))
            ) {
                this.$notify({
                    message: 'Please set a start location before moving onto the next step..',
                    type: 'danger'
                });
                return;
            }
            this.unassignedStops = [];
            this.selectedUnassignedStopsData.forEach((stopId) => {
                if (stopId) {
                    const stop = this.stopList.filter((stop) => stop.stopId === stopId || stop.shipmentId === stopId);
                    if (stop && stop.length) {
                        this.unassignedStops.push(stop[0]);
                    }
                }
            });

            if (!this.routingInterval) {
                this.$_handleLoaderState(true, 'Processing...');
                this.allocateStops = [];
                this.allocateStopsResponse = [];
                this.hasDisplayed = false;
                this.isRouteLoading = true;
                this.isNextRouting = false;
                this.iterationCount += 1;
                this.showInfoWindow = false;
            }
            this.totalOptimiseStops = this.unassignedStops.length;

            const api = `api/stops/optimise`;
            const mappedTeamMemberData = this.selectedTeamMembersData.map((s) => {
                return {
                    ...s,
                    tripCustomerId: s.tripDetails?.customerId
                };
            });

            this.optimiseInputData = {
                startLocation:
                    this.optimiseConfiguration.startLocation && this.optimiseConfiguration.startLocation.address
                        ? {
                            address: this.optimiseConfiguration.startLocation.address,
                            latitude: this.optimiseConfiguration.startLocation.location.latitude,
                            longitude: this.optimiseConfiguration.startLocation.location.longitude
                        }
                        : null,
                stops: this.unassignedStops,
                teamMembers: mappedTeamMemberData,
                tripDate: moment(this.optimiseConfiguration.tripDate).format(DATE_TYPES.internationalDate),
                includeExistingStopsInTrips: this.optimiseConfiguration.includeAssignedStops,
                routeModelId: runRouteModelId || `RUN_${moment().format('YYYYMMDDHHmmss')}`,
                tripType: 'Delivery',
                startTime: this.optimiseConfiguration.startTime, // != null ? this.optimiseConfiguration.startTime : '09:00',
                endTime: this.optimiseConfiguration.endTime, // != null ? this.optimiseConfiguration.endTime : '18:00',
                startLocationMode: this.optimiseConfiguration.startLocationMode,
                teamMemberTimingOverride: this.optimiseConfiguration.teamMemberTimingOverride,
                ignoreTimeWindows: this.optimiseConfiguration.ignoreTimeWindows,
                contiguityConstraint: this.optimiseConfiguration.contiguityConstraint,
                skillMatching: this.optimiseConfiguration.skillMatching,
                waitForOptimisedPlan: true,
                endLocation: this.optimiseConfiguration.endLocation.address
                    ? {
                        address: this.optimiseConfiguration.endLocation.address,
                        latitude: this.optimiseConfiguration.endLocation.location.latitude,
                        longitude: this.optimiseConfiguration.endLocation.location.longitude
                    }
                    : null,
                endLocationMode: this.optimiseConfiguration.endLocationMode,
                distanceCost: this.getDistanceCost || null,
                timeCost: this.getTimeCost || null,
                fixedCost: this.getFixedCost || null,
                distributeWorkloadCost: this.getDistributeWorkloadCost || null,
                isDistributeWorkload: this.optimiseConfiguration.isDistributeWorkload,
                runNumber: this.optimiseConfiguration.runNumber || null,
                routeCenteringPenalty: this.getRouteCenteringPenalty,
                warehouseStartLocationId: this.optimiseConfiguration.warehouseStartLocation,
                warehouseEndLocationId: this.optimiseConfiguration.warehouseEndLocation
            };
            const payload = {
                method: 'post',
                data: this.optimiseInputData
            };
            const response = await handleRequests(api, payload).catch(() => {
                this.$_handleLoaderState(false);
                this.$notify({
                    message: 'Error in getting optimise stops',
                    type: 'danger'
                });
            });
            const responseData = response ? response.data : null;
            if (responseData && responseData.modelId) {
                const { modelId } = responseData;
                this.modelId = modelId;
                this.$_mapBox_triggerData(this.map);
            }
        },
        routingMatched(vehicle) {
            let isStopsRoutingMatched = false;
            if (this.allocateStopsResponse.vehicles) {
                const oldOptimiseStops = this.allocateStopsResponse.vehicles.find((val) => {
                    return val ? val.vehicleId === vehicle.vehicleId : [];
                });
                if (oldOptimiseStops) {
                    const oldStops = oldOptimiseStops.stops.map((stop) => stop.stopId);
                    const newStops = vehicle.stops.map((stop) => stop.stopId);
                    const matchedStops = newStops.filter((e) => oldStops.indexOf(e) === -1);
                    if (matchedStops.length === 0) {
                        isStopsRoutingMatched = true;
                    } else {
                        const oldStopsString = JSON.stringify(oldOptimiseStops.stops);
                        const newStopsString = JSON.stringify(vehicle.stops);

                        isStopsRoutingMatched = oldStopsString === newStopsString;
                    }
                }
            }
            if (!isStopsRoutingMatched) {
                this.countRouteChanged += 1;
            }
            return isStopsRoutingMatched;
        },
        callRoutingInterval() {
            this.isRouteLoading = true;
            this.isNextRouting = false;
            this.isStartRouting = true;
            this.iterationCount += 1;
            this.optimiseStopsMarkers = [];
            this.optimiseInputData.currentIterationCount = this.iterationCount;
            const api = `api/stops/optimise/${this.modelId}/route`;
            const payload = {
                method: 'post',
                data: this.optimiseInputData
            };
            handleRequests(api, payload)
                .then((response) => {
                    this.$_handleLoaderState(false);
                    if (this.step === 3 && this.isStartRouting === true) {
                        if (response.data) {
                            this.$_handleLoaderState(false);
                            this.setOptimiseRoutes(response.data);
                        }
                    }
                }, this)
                .catch((error) => {
                    this.$_handleLoaderState(false);
                    this.onStopRouting();
                    if (error?.data) {
                        error.data.forEach((data) => {
                            this.$notify({
                                message: data.message,
                                type: 'warning'
                            });
                        });
                    }
                });
        },
        setOptimiseRoutes(responseData, setMapRouteBounds) {
            let isFirstCall = true;
            const oldResponseData = this.allocateStopsResponse;
            // Checking the old and new optimise response
            if (oldResponseData.vehicles) {
                isFirstCall = false;
                const newVehicleIds = responseData.vehicles.map((vehicle) => vehicle.vehicleId);
                const oldVehicleIds = oldResponseData.vehicles.map((vehicle) => vehicle.vehicleId);
                const notMatchVehicleIds = oldVehicleIds.filter((e) => newVehicleIds.indexOf(e) === -1);
                notMatchVehicleIds.forEach((vehicleId) => {
                    this.removeAllocateStopMarkers(vehicleId);
                });
            }
            this.isRoutingChanged = false;
            this.countRouteChanged = 0;
            this.totalRoutedStops = 0;

            this.totalStops = responseData.unassigned?.length || 0;

            responseData.vehicles.forEach((vehicle) => {
                const { stops } = vehicle;
                this.totalRoutedStops += stops.length;
                this.totalStops += stops.length;

                stops.forEach((stop) => {
                    const stopMarker = this.stopsMarkers.find((marker) => marker.stopId === stop.stopId);
                    if (!stopMarker) {
                        const marker = this.setStopsMarkers(stop, true);
                        this.optimiseStopsMarkers.push(marker);
                    }
                });

                if (isFirstCall) {
                    this.populateRouteModels(vehicle, setMapRouteBounds);
                    this.$_mapBox_setData(this.map, 'stopPoints', this.markerGeojson);
                } else if (!this.routingMatched(vehicle)) {
                    this.isRoutingChanged = true;
                    this.removeAllocateStopMarkers(vehicle.vehicleId);
                    this.populateRouteModels(vehicle, setMapRouteBounds);
                    this.$_mapBox_setData(this.map, 'stopPoints', this.markerGeojson);
                }
            }, this);
            if (!this.isRoutingChanged) {
                this.routeNotMatchCount += 1;
                const seconds = this.routeNotMatchCount * 15;
                const minutes = Math.floor((seconds % 3600) / 60);
                this.routeLastChangedTime = minutes > 0 ? `${minutes} minutes` : null;
            } else {
                this.routeLastChangedTime = null;
                this.routeNotMatchCount = 0;
            }
            this.unallocatedStops = [];
            if (responseData.unassigned) {
                responseData.unassigned.forEach((unassignedStop) => {
                    const stop = this.stopList.find(
                        (stop) => stop.stopId === unassignedStop.stopId || stop.shipmentId === unassignedStop.stopId
                    );
                    if (stop) {
                        if (isAValidCoordinate(stop.address)) {
                            stop.locationData.address = null;
                        }
                        stop.routingErrors = unassignedStop.routingErrors;
                        this.unallocatedStops.push(stop);
                    }
                }, this);
            }
            if (this.isStartRouting) {
                this.isNextRouting = true;
                this.routingInterval = setTimeout(this.$_mapBox_triggerData, 15000, this.map);
            }
            this.allocateStopsResponse = responseData;
            this.isRouteLoading = false;
        },
        onStopRouting() {
            this.isStartRouting = false;
            this.routeNotMatchCount = 0;
            clearTimeout(this.routingInterval);
            this.routingInterval = null;
        },
        displayCard(rawData) {
            const { stopId, shipmentId } = rawData;

            let allStops = this.stopList;
            const { data } = this.shipmentList;
            if (data.length) {
                allStops = this.baseStopList.concat(data);
            }

            allStops.forEach((s) => {
                if (s.shipmentId) {
                    s.pickupStop.isOutside = s.isOutside;
                    s.pickupStop.shipmentId = s.shipmentId;
                    s.pickupStop.skills = s.skills;
                    s.pickupStop.isSelected = true;

                    s.dropStop.shipmentId = s.shipmentId;
                    s.dropStop.isOutside = s.isOutside;
                    s.dropStop.skills = s.skills;
                    s.dropStop.isSelected = true;
                } else if (s.stopId) {
                    s.isSelected = true;
                }
            });

            let stopData = allStops.find((x) => x.stopId === stopId);
            if (!stopData && shipmentId) {
                const shipment = allStops.find((x) => x.shipmentId === shipmentId);
                if (shipment) {
                    stopData = shipment.pickupStopId === stopId ? shipment.pickupStop : shipment.dropStop;

                    stopData = { ...stopData, sourceReference: shipment.sourceReference, load: shipment.load };
                }
            }

            if (!stopData) 
                return;

            const marker = this.$_mapBox_setMarkers(
                this.map,
                stopData.location?.latitude,
                stopData.location?.longitude,
                'unassignedstop'
            );

            this.handleClickedMarker(stopData, marker, 'optimisedStop');
        },
        setRouteMarkers(stopIndex, stop, pins, trip, offset) {
            // this.bounds = this.$_map_createBounds(this.map);
            const { location } = stop;
            const marker = this.$_mapBox_setMarkers(
                this.map,
                location.latitude,
                location.longitude,
                'stop',
                pins,
                stopIndex
            );

            this.$_mapBox_addMapElement(marker, this.map);
            // this.$_map_callMapFunction(this.bounds, 'extend', [location.longitude, location.latitude]);
            marker.getElement().addEventListener('click', () => {
                this.showInfoWindow = false;
                let vehicleDetails = this.getGroupDetails(trip.vehicle.vehicleId, 'teamMembers', 'publicUserId');

                vehicleDetails = Object.assign({}, vehicleDetails, trip.vehicle);
                vehicleDetails.stopId = stop.stopId;
                vehicleDetails.shipmentId = stop.shipmentId;
                vehicleDetails.address = stop.address;
                vehicleDetails.stopRef = stop.stopRef;
                vehicleDetails.contact = stop.contact;
                vehicleDetails.status = stop.status;
                vehicleDetails.arrivalDate = stop.arrivalDate;
                vehicleDetails.sourceReference = stop.sourceReference;
                vehicleDetails.load = stop.load;
                vehicleDetails.serviceLines = stop.serviceLines;

                this.handleClickedMarker(vehicleDetails, marker, 'stop', true);
            });
            if (offset) {
                marker.setOffset([offset, 0]);
            }

            trip.stopMarkers.push(marker);
        },
        setDriverBreakMarkers(stopIndex, driverBreak, pins, trip, offset) {
            // this.bounds = this.$_map_createBounds(this.map);
            const { location } = driverBreak;
            const marker = this.$_mapBox_setMarkers(
                this.map,
                location.latitude,
                location.longitude,
                'driverBreak',
                pins,
                stopIndex
            );

            this.$_mapBox_addMapElement(marker, this.map);

            trip.stopMarkers.push(marker);
        },
        setMapRouteBounds() {
            // this.bounds = this.$_mapbox_createBounds(this.map);
            // this.optimiseStopsMarkers.forEach((marker) => {
            //     this.$_map_callMapFunction(this.bounds, 'extend', [marker.getLngLat()]);
            // });
            // this.startEndLocationMarkers.forEach((marker) => {
            //     this.$_map_callMapFunction(this.map, 'setCenter', [marker.getLngLat()]);
            // });
            // this.$_map_callMapFunction(this.map, 'fitBounds', [this.bounds]);
        },
        populateRouteModels(vehicle, setMapRouteBounds, jobLockCount) {
            const pins = this.markerPins[vehicle.vehicleId];
            const trip = this.pinnedTrips.find((x) => x.vehicleId === vehicle.vehicleId);
            const routeLineColor = this.pinnedTrips.length ? '#aaa' : pins.color;
            vehicle.routePath = [];
            vehicle.stopMarkers = [];
            const teamMember = this.teamMembers.find((val) => {
                return val.publicUserId === vehicle.vehicleId;
            });
            let stopsCount = vehicle.stops.length;
            if (this.loadedSaveModel) {
                const vehicleStops = vehicle.stops.map((stop) => stop.shipmentId || stop.stopId);
                const stopsList = this.stopList.map((stop) => stop.shipmentId || stop.stopId);
                const matchedStops = vehicleStops.filter((e) => stopsList.indexOf(e) > -1);
                stopsCount = matchedStops.length + (jobLockCount ?? 0);
            }
            const selectedStopList = [];

            selectedStopList.push({
                address: vehicle.startAddress,
                arrivalTime: vehicle.tripStartTime,
                locationType: 'Start Location'
            });

            const options = {
                strokeColor: routeLineColor,
                strokeWeight: 3,
                strokeOpacity: 1,
                zIndex: 3
            };
            /* eslint-disable prefer-const */
            let routeData = [];
            vehicle.stops.forEach((stop) => {
                const stopPin = this.stopsMarkers.find((s) => s.stopId === stop.stopId);
                if (stopPin) {
                    this.$_mapBox_remove_geojson_marker(stopPin, this.markerGeojson);
                    if (stop.route.length > 0) {
                        const decodedPath = this.$_mapbox_createPath(stop.route);
                        const routeLine = this.$_mapBox_createRouteData(decodedPath);

                        routeData.push(routeLine);
                    }
                    stop.display = 'show';
                    selectedStopList.push(stop);
                }
            }, this);
            this.$_mapBox_createRouteLine(routeData, this.map, vehicle.vehicleId, options);
            vehicle.routePath.push(vehicle.vehicleId);

            let vehicleEventListener = null;

            if (this.mapboxEventListeners.length > 0) {
                vehicleEventListener = this.mapboxEventListeners.find((x) => x === vehicle.vehicleId);
            }

            if (!vehicleEventListener) {
                this.map.on('click', vehicle.vehicleId, (e) => {
                    this.handleClickedRouteLine(vehicle.vehicleId);
                });

                this.mapboxEventListeners.push(vehicle.vehicleId);
            }

            if (vehicle.endLocation) {
                selectedStopList.push({
                    address: vehicle.endAddress,
                    arrivalTime: vehicle.tripEndTime,
                    locationType: 'End Location'
                });
            }
            const time = moment(vehicle.tripEndTime).format('h:mm A');
            const allocateStopsData = {
                vehicle,
                vehicleId: vehicle.vehicleId,
                teamMember,
                stops: selectedStopList,
                stopsCount,
                arrivalTime: time,
                stopMarkers: vehicle.stopMarkers,
                routePath: vehicle.routePath,
                color: pins.color,
                isPinned: trip ? trip.isPinned : false
            };
            const allocateStopIndex = this.allocateStops.findIndex((val) => {
                return val ? val.vehicleId === vehicle.vehicleId : -1;
            });
            if (allocateStopIndex !== -1) {
                this.allocateStops[allocateStopIndex] = allocateStopsData;
            } else {
                this.allocateStops.push(allocateStopsData);
            }
            this.hasDisplayed = true;
            if (setMapRouteBounds) {
                this.setMapRouteBounds();
            }
            if (trip && trip.isPinned) {
                this.setStopsRoutePathMarkers(allocateStopsData, false);
            }
        },

        setStopStartEndMarkers(trip, pin, locationType, isSameLocation) {
            this.bounds = this.$_mapbox_createBounds(this.map);
            let location;
            /* eslint-disable no-unused-vars */
            let locationAddress;
            /* eslint-disable no-unused-vars */
            let type = 'stop';
            if (locationType === 'startLocation' || isSameLocation) {
                type = 'optimiseStop';
                pin.optimiseStop = '/img/map/optimise-stop/start.png';
                location = trip.vehicle.startLocation;
                locationAddress = trip.vehicle.startAddress;
            } else if (locationType === 'endLocation') {
                type = 'optimiseStop';
                pin.optimiseStop = '/img/map/optimise-stop/end.png';
                location = trip.vehicle.endLocation;
                locationAddress = trip.vehicle.endAddress;
            }

            const marker = this.$_mapBox_setMarkers(
                this.map,
                location.latitude,
                location.longitude,
                locationType === 'startLocation' ? 'start' : 'end',
                pin
            );
            // this.$_map_callMapFunction(this.bounds, 'extend', [marker.getLngLat()]);

            trip.stopMarkers.push(marker);
        },

        formatAMPM(value) {
            if (!value) {
                return '';
            }
            return moment(value, 'hh:mm').format('h:mm A');
        },
        handleDeleteStop(deleteTripStops) {
            if (this.isStartRouting) {
                this.$notify({
                    message: 'Routing is in progress - please stop the routing process before deleting stops',
                    type: 'danger'
                });
            } else {
                const { vehicleId } = deleteTripStops;
                const allocateStops = this.allocateStopsResponse.vehicles.find((s) => {
                    return s ? s.vehicleId === vehicleId : [];
                });

                deleteTripStops.stops.forEach((stopId) => {
                    const allocateStopsIndex = allocateStops.stops.findIndex((val) => {
                        return val.stopId === stopId;
                    });
                    allocateStops.stops.splice(allocateStopsIndex, 1);
                });
                if (!this.isReassigned) {
                    this.reallocateRemoveVehicles(allocateStops);
                    this.recalculateRouteAPICall();
                }
            }
        },
        handleShowStreetView(coordinates) {},
        handleReassignedStop(reassignedTripStops) {
            if (this.isStartRouting) {
                this.$notify({
                    message: 'Routing is in progress - please stop the routing process before reassigning stops',
                    type: 'danger'
                });
            } else {
                this.isReassigned = true;
                const { vehicleId } = reassignedTripStops;
                const allocateOldStops = this.allocateStopsResponse.vehicles.find((s) => s.vehicleId === vehicleId);
                const deleteTripStops = {
                    stops: reassignedTripStops.stops,
                    vehicleId
                };
                let oldVehicleId;
                const vehicleIds = [];
                reassignedTripStops.stops.forEach((stopId, index) => {
                    const newVehicleId =
                        reassignedTripStops.records[index].newVehicleId || reassignedTripStops.records[index].vehicleId;
                    if (newVehicleId !== oldVehicleId) {
                        vehicleIds.push(newVehicleId);
                        oldVehicleId = newVehicleId;
                    }
                    const allocateNewStops = this.allocateStopsResponse.vehicles.find(
                        (s) => s.vehicleId === newVehicleId
                    );
                    const stop = allocateOldStops.stops.find((val) => {
                        return val.stopId === stopId;
                    });
                    if (!allocateNewStops) {
                        const teamMember = this.teamMembers.find((member) => {
                            return member.publicUserId === newVehicleId;
                        });
                        let tripStartTime = moment(
                            `${this.optimiseConfiguration.tripDate} ${this.optimiseConfiguration.startTime}`
                        );
                        let tripEndTime = moment(
                            `${this.optimiseConfiguration.tripDate} ${this.optimiseConfiguration.endTime}`
                        );
                        let optimiseStartAddress = '';
                        let optimiseStartLocation = null;
                        let optimiseEndLocation = null;
                        let optimiseEndAddress = '';
                        if (this.optimiseConfiguration.startLocationMode === 'manual') {
                            optimiseStartAddress = this.optimiseConfiguration.startLocation.address;
                            if (
                                this.optimiseConfiguration.startLocation.location.latitude &&
                                this.optimiseConfiguration.startLocation.location.longitude
                            ) {
                                optimiseStartLocation = this.optimiseConfiguration.startLocation.location;
                            }
                        } else {
                            optimiseStartAddress = teamMember.startAddress;
                            optimiseStartLocation = teamMember.startLocation;
                        }
                        if (this.optimiseConfiguration.endLocationMode === 'manual') {
                            optimiseEndAddress = this.optimiseConfiguration.endLocation.address;
                            optimiseEndLocation = this.optimiseConfiguration.endLocation.location;
                        } else if (this.optimiseConfiguration.endLocationMode === 'teammembers') {
                            optimiseEndAddress = teamMember.endAddress;
                            optimiseEndLocation = teamMember.endLocation;
                        } else if (this.optimiseConfiguration.endLocationMode === 'startlocation') {
                            if (this.optimiseConfiguration.startLocationMode === 'manual') {
                                optimiseEndAddress = startAddress;
                                optimiseEndLocation = startLocation;
                            } else {
                                optimiseEndAddress = teamMember.startAddress;
                                optimiseEndLocation = teamMember.startLocation;
                            }
                        }

                        if (this.optimiseConfiguration.teamMemberTimingOverride) {
                            tripStartTime = moment(
                                `${this.optimiseConfiguration.tripDate} ${teamMember.defaultTripStartTime}`
                            );
                            tripEndTime = moment(
                                `${this.optimiseConfiguration.tripDate} ${teamMember.defaultTripEndTime}`
                            );
                        }

                        this.allocateStopsResponse.vehicles.push({
                            stops: [stop],
                            vehicleId: newVehicleId,
                            endAddress: optimiseEndAddress,
                            endLocation: optimiseEndLocation,
                            startAddress: optimiseStartAddress,
                            startLocation: optimiseStartLocation,
                            tripEndTime: moment(tripEndTime).format('YYYY-MM-DDTHH:mm:ss'),
                            tripStartTime: moment(tripStartTime).format('YYYY-MM-DDTHH:mm:ss')
                        });
                    } else {
                        allocateNewStops.stops.push(stop);
                    }
                });

                this.handleDeleteStop(deleteTripStops);
                const allocateData = this.allocateStopsResponse.vehicles.find((s) => s.vehicleId === vehicleId);
                this.reallocateRemoveVehicles(allocateData);
                vehicleIds.forEach((vehicleId) => {
                    const allocateData = this.allocateStopsResponse.vehicles.find((s) => s.vehicleId === vehicleId);
                    this.reallocateRemoveVehicles(allocateData);
                });
                this.recalculateRouteAPICall();
            }
        },
        reallocateRemoveVehicles(allocateData) {
            this.vehiclesRecords.push({
                vehicleId: allocateData.vehicleId,
                stops: allocateData.stops,
                endAddress: allocateData.endAddress,
                endLocation: allocateData.endLocation,
                startAddress: allocateData.startAddress,
                startLocation: allocateData.startLocation,
                vehicleStartTime: allocateData.vehicleStartTime,
                vehicleEndTime: allocateData.vehicleEndTime,
                tripEndTime: allocateData.tripEndTime,
                tripStartTime: allocateData.tripStartTime
            });
        },
        recalculateRouteAPICall() {
            this.showInfoWindow = false;
            this.clearStopsMarkers();
            const api = `api/stops/optimise/recalculate-route`;
            const payload = {
                method: 'post',
                data: this.vehiclesRecords
            };
            this.$_handleLoaderState(true, 'Processing...');
            handleRequests(api, payload)
                .then((response) => {
                    this.vehiclesRecords = [];
                    this.optimiseStopsMarkers = [];
                    response.data.forEach((vehicle) => {
                        const allocateStopsResponseIndex = this.allocateStopsResponse.vehicles.findIndex((val) => {
                            return val ? val.vehicleId === vehicle.vehicleId : [];
                        });
                        if (allocateStopsResponseIndex !== -1) {
                            this.allocateStopsResponse.vehicles[allocateStopsResponseIndex] = vehicle;
                        }
                    }, this);
                    this.allocateStopsResponse.vehicles.forEach((vehicle) => {
                        const { stops } = vehicle;
                        stops.forEach((stop) => {
                            const stopMarker = this.stopsMarkers.find((marker) => marker.stopId === stop.stopId);
                            this.optimiseStopsMarkers.push(stopMarker);
                        });
                        this.removeAllocateStopMarkers(vehicle.vehicleId);
                        this.populateRouteModels(vehicle, true);
                    });
                    this.isReassigned = false;
                    this.$_handleLoaderState(false);
                })
                .catch(() => {
                    this.vehiclesRecords = [];
                    this.isReassigned = false;
                    this.$_handleLoaderState(false);
                    this.$notify({
                        message: 'Error in getting optimise stops',
                        type: 'danger'
                    });
                });
        },
        closeTripStopWindow(reassignedTripStops, deleteTripStops) {
            this.showTripStopWindow = false;
            this.isReassigned = false;
            if (!!reassignedTripStops && reassignedTripStops.stops.length) {
                this.handleReassignedStop(reassignedTripStops);
            }
            if (!!deleteTripStops && deleteTripStops.stops.length) {
                this.handleDeleteStop(deleteTripStops);
            }
        },
        async onExportTrips() {
            const { tripDate } = this.optimiseConfiguration;
            const mapVehicleIds = this.allocateStopsResponse.vehicles.map((trip) => trip.vehicleId);
            const isDuplicate = mapVehicleIds.some((trip, vehicleId) => {
                return mapVehicleIds.indexOf(trip) !== vehicleId;
            });

            if (!isDuplicate) {
                const inputExportData = [];

                this.allocateStopsResponse.vehicles.forEach((trip) => {
                    if (trip) {
                        const teamMember = this.teamMembers.find((val) => {
                            return val.publicUserId === trip.vehicleId;
                        });

                        const teamMemberFullname = teamMember?.fullName ?? '';

                        let stopTeamRegionIdToUse = null;
                        const stopsExportData = [];

                        trip.stops.forEach((s) => {
                            let stop;
                            const originalEta = moment(s.arrivalTime).format('HH:mm');
                            if (s.type === 'SERVICE') {
                                stop = this.stopList.find((x) => x.stopId === s.stopId);
                            } else {
                                stop = this.stopList.find((x) => x.shipmentId === s.shipmentId);
                            }

                            if (stop) {
                                const stopData = {};

                                if (s.type === 'SHIPMENT_PICKUP' && s.shipmentId != null) {
                                    Object.assign(stopData, {
                                        address: stop.pickupStop.address,
                                        stopRef: stop.pickupStop.stopRef,
                                        teamMemberName: teamMemberFullname,
                                        contact: stop.pickupStop.contact ? stop.pickupStop.contact.name : null,
                                        tripDate,
                                        originalEta,
                                        travelDistance: s.distance,
                                        travelTime: s.time
                                    });
                                } else if (s.type === 'SHIPMENT_DELIVERY' && s.shipmentId != null) {
                                    Object.assign(stopData, {
                                        address: stop.dropStop.address,
                                        stopRef: stop.dropStop.stopRef,
                                        teamMemberName: teamMemberFullname,
                                        contact: stop.dropStop.contact ? stop.dropStop.contact.name : null,
                                        tripDate,
                                        originalEta,
                                        travelDistance: s.distance,
                                        travelTime: s.time
                                    });
                                } else {
                                    Object.assign(stopData, {
                                        address: stop.address,
                                        stopRef: stop.stopRef,
                                        teamMemberName: teamMemberFullname,
                                        contact: stop.contact ? stop.contact.name : null,
                                        tripDate,
                                        originalEta,
                                        travelDistance: s.distance,
                                        travelTime: s.time
                                    });
                                }

                                const stopTeamRegion = this.teamRegions.find(
                                    (tr) => tr.teamRegionId === stop.teamRegionId
                                );

                                if (stopTeamRegion) {
                                    stopData.teamRegionName = stopTeamRegion.name;
                                }

                                stopsExportData.push(stopData);

                                if (!stopTeamRegionIdToUse) {
                                    stopTeamRegionIdToUse = stop.teamRegionId;
                                }
                            }
                        });

                        let travelTime = '';
                        let travelDistance = '';

                        if (stopsExportData.length > 0) {
                            travelTime = moment.utc(stopsExportData[0].travelTime * 1000).format('H.mm'); // Conversions of seconds to hours and minutes

                            travelDistance =
                                this.maxRadiusUnits === 'MILES'
                                    ? (stopsExportData[0].travelDistance / 1610).toFixed(2)
                                    : (stopsExportData[0].travelDistance / 1000).toFixed(2); // Conversions of Meter to Kilometer
                        }

                        const assignedMemberFilter = inputExportData.filter(
                            (x) => x.teamMembersFullName === teamMemberFullname
                        );

                        const tripTeamRegionToUse = this.findTripTeamRegionToUse(teamMember, stopTeamRegionIdToUse);

                        inputExportData.push({
                            vehicleId: trip.vehicleId,
                            userId: trip.userId,
                            stops: stopsExportData,
                            startAddress: trip.startAddress,
                            startLocation: trip.startLocation,
                            endAddress: trip.endAddress,
                            endLocation: trip.endLocation,
                            startTime: moment(trip.tripStartTime).format('HH:mm'),
                            endTime: moment(trip.vehicleEndTime).format('HH:mm'),
                            teamMembersFullName: teamMemberFullname,
                            assignedMember:
                                assignedMemberFilter.length > 0
                                    ? `${teamMemberFullname} ${assignedMemberFilter.length}` // Handle scenario where more than one team member has the same name, to avoid an error when generating the spreadsheet
                                    : teamMemberFullname,
                            travelDistance: parseFloat(travelDistance),
                            travelTime: parseFloat(travelTime),
                            estimatedEndTime: trip.tripEndTime,
                            teamRegionName: tripTeamRegionToUse ? tripTeamRegionToUse.name : null
                        });
                    }
                });

                const formttedDate = `${this.$options.filters.dateFormat(tripDate, 'dddd MMMM D YYYY')}`;
                const fileName = `Locate2u Trips - ${formttedDate}.xlsx`;
                const api = `api/stops/optimise/export`;
                const payload = {
                    method: 'post',
                    data: {
                        trips: inputExportData,
                        speedUnit: this.maxRadiusUnits === 'MILES' ? 'Miles' : 'KM',
                        tripDate: moment(tripDate).format(DATE_TYPES.internationalDate)
                    },
                    responseType: 'blob'
                };
                const response = await handleRequests(api, payload);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            } else {
                this.$_handleLoaderState(false);
                this.$notify({
                    message: 'Please select different team members for each trip',
                    type: 'danger'
                });
            }
        },
        findTripTeamRegionToUse(assignedMember, stopTeamRegionIdToUse) {
            // find trip team region id to use
            let teamRegionToUse = null;
            let tripTeamRegionIdToUse = null;
            // 1. assigned member
            if (assignedMember) {
                tripTeamRegionIdToUse = assignedMember.teamRegionId;
            }

            // 2. filter
            if (!tripTeamRegionIdToUse) {
                tripTeamRegionIdToUse = this.optimiseConfiguration.teamRegionId;
            }

            // 3. first stop with team region
            if (!tripTeamRegionIdToUse) {
                tripTeamRegionIdToUse = stopTeamRegionIdToUse;
            }

            if (this.teamRegions) {
                const tr = this.teamRegions.find((x) => x.teamRegionId === tripTeamRegionIdToUse);
                if (tr) {
                    teamRegionToUse = { ...tr };
                }
            }
            return teamRegionToUse;
        },

        clearAllocateStops() {
            this.allocateStops.forEach((trip) => {
                // Clear all route path and markers
                trip.stopMarkers.forEach((s) => this.$_mapBox_removeElement(s));
                trip.routePath.forEach((s) => this.$_mapBox_clearRoute(s, this.map));
                if (trip.markerClusters) {
                    trip.markerClusters.clearMarkers();
                }
            });
        },

        checkTabValidation() {
            switch (this.step) {
                case 1:
                    this.isValidated = this.selectedUnassignedStopsData.length !== 0;
                    this.filterByRunNumber();
                    if (!_.isEmpty(this.activeValidationFilter)) {
                        this.handleFilterValidationClick(this.activeValidationFilter);
                    } else if (!_.isEmpty(this.activeSelectionFilter)) {
                        this.handleFilterStopsClick(this.activeSelectionFilter);
                    } else {
                        this.filterStopMapPins(this.stopList);
                    }
                    break;
                case 2:
                    this.getTeamMembers();
                    this.showHideUnselectStopsMarkers(true);
                    this.clearUnselectedShapeStopMarkers();
                    this.isValidated = this.selectedTeamMembersData.length !== 0;
                    break;
                default:
                    this.isValidated = true;
            }
        },
        getTime(value) {
            return moment.parseZone(value).format(DATE_TYPES.standardTime);
        },
        setTimeWindowFilter(stops) {
            const timeWindowStartStops = stops.filter((item) => item.timeWindowStart);
            this.timeWindowFilterOptions = [];
            timeWindowStartStops.forEach((stop) => {
                const val = `${this.getTime(stop.timeWindowStart)} - ${this.getTime(stop.timeWindowEnd)}`;
                const index = this.timeWindowFilterOptions.findIndex((v) => v.value === val);
                if (index === -1) {
                    this.timeWindowFilterOptions.push({ value: val });
                }
            });
        },
        filterByTimeWindow(timeWindowStart, timeWindowEnd, startValue, endValue) {
            if (timeWindowStart != null && timeWindowEnd != null)
                return this.getTime(timeWindowStart) === startValue && this.getTime(timeWindowEnd) === endValue;
            return null;
        },
        applyTimeWindowFilter(filterValues) {
            const resultfilteredStops = [];
            filterValues.forEach((values) => {
                const value = values.split(' - ');
                const result = this.baseStopList.filter((item) =>
                    this.filterByTimeWindow(item.timeWindowStart, item.timeWindowEnd, value[0], value[1])
                );

                result.forEach((data) => {
                    resultfilteredStops.push(data);
                });
            });

            this.stopList = resultfilteredStops;
            if (this.stopList.length > 0) {
                this.filterStopMapPins(this.stopList);
            }
        },
        applyStopsBySkillsFilter(filterValues) {
            // Find the stops which have at least one skill that matches the selected skills
            this.stopList = this.baseStopList.filter(
                (stop) => stop.skills && stop.skills.filter((x) => filterValues.includes(x)).length
            );
            if (this.stopList.length > 0) {
                this.filterStopMapPins(this.stopList);
            }
        },
        clearTimeWindowFilter() {
            this.stopList = this.baseStopList;
            this.filterStopMapPins(this.stopList);
        },
        filterStopMapPins(stops) {
            this.clearStopsMarkers();
            const stopIds = stops.map((x) => x.stopId || x.shipmentId);
            this.pinAllStopsMarkers(stopIds);
        },
        setStartEndLocationPin(type, isClear) {
            const { latitude, longitude } = this.optimiseConfiguration[`${type}Location`].location;
            if (isClear) {
                this.clearStartEndLocationPin(type);
            } else if (this.map && latitude != null && longitude != null) {
                let locationMarker;
                const marker = this.startEndLocationMarkers.find((marker) => {
                    return marker.type === type;
                });
                if (marker) {
                    locationMarker = marker;
                    this.$_mapBox_addMapElement(locationMarker, this.map);
                } else {
                    const pin = {
                        optimiseStop: `/img/map/optimise-stop/${type}.png`
                    };
                    locationMarker = this.$_mapBox_setMarkers(this.map, latitude, longitude, 'optimiseStop', pin);
                    locationMarker.type = type;
                    this.startEndLocationMarkers.push(locationMarker);
                }

                this.$_mapBox_callMapFunction(this.map, 'panTo', [longitude, latitude]);
            }
        },
        clearStartEndLocationPin(type) {
            this.startEndLocationMarkers.forEach((marker) => {
                if (marker.type === type) {
                    this.$_mapBox_removeElement(marker);
                }
            });
        },
        onSaveOptimise(name) {
            this.savedModelName = name;
            this.$_handleLoaderState(true);
            const api = `api/stops/optimise/routes/${moment(this.optimiseConfiguration.tripDate).format(
                DATE_TYPES.internationalDate
            )}/${encodeURIComponent(name)}`;
            const vehicles = [];
            this.allocateStopsResponse.vehicles.forEach((trip) => {
                if (trip) {
                    const vehicle = this.selectedTeamMembersData.find((x) => x.publicUserId === trip.vehicleId);
                    vehicles.push({
                        vehicleId: trip.vehicleId,
                        vehicleStartTime: trip.vehicleStartTime,
                        vehicleEndTime: trip.vehicleEndTime,
                        stops: trip.stops,
                        coordinate: trip.coordinate,
                        startAddress: trip.startAddress,
                        startLocation: trip.startLocation,
                        endAddress: trip.endAddress,
                        endLocation: trip.endLocation,
                        tripStartTime: trip.tripStartTime,
                        tripEndTime: trip.tripEndTime,
                        distanceCost: trip.distanceCost,
                        timeCost: trip.timeCost,
                        vehicleLoad: trip.vehicleLoad,
                        vehicleCapacityString: vehicle ? vehicle.vehicleCapacityString : null
                    });
                }
            });
            const modelData = {
                addReturnStartLocationStop: this.allocateStopsResponse.addReturnStartLocationStop,
                routeModelId: this.allocateStopsResponse.routeModelId,
                transactionId: this.allocateStopsResponse.transactionId,
                tripDate: this.allocateStopsResponse.tripDate,
                unassigned: this.allocateStopsResponse.unassigned,
                vehicles
            };
            const payload = {
                method: 'put',
                data: {
                    modelId: this.modelId,
                    data: modelData,
                    inputs: this.optimiseInputData
                }
            };
            handleRequests(api, payload)
                .then(() => {
                    this.$_handleLoaderState(false);
                    this.$notify({
                        message: 'Model details successfully saved.',
                        type: 'success'
                    });
                })
                .catch(() => {
                    this.$_handleLoaderState(false);
                    this.$notify({
                        message: 'Error in saving the model details.',
                        type: 'danger'
                    });
                });
        },
        handleLoadModel(response) {
            this.loadedSaveModel = true;
            const { inputs } = response.data;
            const settingTripDate = moment(this.optimiseConfiguration.tripDate, DATE_TYPES.internationalDate);
            const inputTripDate = moment(inputs.tripDate, DATE_TYPES.internationalDate);

            this.optimiseConfiguration.tripDate = moment(inputs.tripDate).format(DATE_TYPES.internationalDate);
            if (this.hasUnassignedStopFilterChanges) {
                this.getUnassignedStopList(inputTripDate).then(() => {
                    this.setLoadModelRoutes(response.data);
                });
            }

            this.getTeamRegions();
        },
        async setLoadModelRoutes(savedModel) {
            this.$_handleLoaderState(true);

            if (!this.teamMembers.length) {
                await this.getTeamMembers();
            }
            const { data, inputs } = savedModel;
            this.unassignedStops = this.stopList.filter((stop) =>
                inputs.stopIds.find((inputStop) => stop.stopId === inputStop)
            );
            if (this.unassignedStops.length) {
                this.optimiseConfiguration = {
                    startLocation: inputs.startLocation
                        ? {
                            address: inputs.startLocation.address,
                            location: {
                                latitude: inputs.startLocation.latitude,
                                longitude: inputs.startLocation.longitude
                            }
                        }
                        : null,
                    startLocationMode: inputs.startLocationMode,
                    endLocation: {
                        address: inputs.endLocation ? inputs.endLocation.address : null,
                        location: {
                            latitude: inputs.endLocation ? inputs.endLocation.latitude : null,
                            longitude: inputs.endLocation ? inputs.endLocation.longitude : null
                        }
                    },
                    endLocationMode: inputs.endLocationMode,
                    tripDate: moment(inputs.tripDate).format(DATE_TYPES.internationalDate),
                    startTime: inputs.startTime,
                    endTime: inputs.endTime,
                    teamMemberTimingOverride: inputs.teamMemberTimingOverride,
                    ignoreTimeWindows: inputs.ignoreTimeWindows,
                    contiguityConstraint: inputs.contiguityConstraint,
                    skillMatching: inputs.skillMatching,
                    addReturnStartLocationStop: inputs.addReturnStartLocationStop,
                    isDistributeWorkload: inputs.isDistributeWorkload,
                    runNumber: inputs.runNumber || null
                };
                // save optimise start end time
                this.saveStartTime(inputs.startTime);
                this.saveEndTime(inputs.endTime);

                this.optimiseStopsMarkers = [];
                this.allocateStops = [];
                this.unallocatedStops = [];
                this.selectedUnassignedStopsData = inputs.stopIds;
                this.clearStartEndLocationPin('start');
                this.clearStartEndLocationPin('end');
                this.showHideUnselectStopsMarkers(true);
                this.stopsMarkers.forEach((marker) => {
                    this.$_mapBox_removeElement(marker);
                });
                this.step = 3;
                this.allocateStopsResponse = data;
                data.vehicles.forEach((vehicle) => {
                    const { stops } = vehicle;
                    const jobLockCount = 0; // routeModel.data.vehicles.find((x) => x.id === vehicle.vehicleId)?.lock?.jobLocks?.length; // We no longer save this part of the model, so can't do this anymore. Not sure as to the full implications, but I think it's only when adding to an existing trip, which probably is not an issue for us.
                    const pins = this.markerPins[vehicle.vehicleId];
                    stops.forEach((stop) => {
                        const stopMarker = this.stopsMarkers.find((marker) => marker.stopId === stop.stopId);
                        if (!stopMarker) {
                            const marker = this.$_mapBox_setMarkers(
                                this.map,
                                stop.latitude,
                                stop.longitude,
                                'unassignedstop',
                                pins
                            );
                            marker.stopId = stop.stopId;
                            this.setStopsMarkers(stop, true);
                            this.optimiseStopsMarkers.push(marker);
                        } else {
                            this.optimiseStopsMarkers.push(stopMarker);
                        }
                    });
                    this.populateRouteModels(vehicle, true, jobLockCount);
                });
            } else {
                this.loadedSaveModel = false;
                this.$notify({
                    message: `Model has already been consumed and all the stops are used to generate the trip for the ${moment(
                        inputs.tripDate
                    ).format(DATE_TYPES.internationalDate)}.`,
                    type: 'warning'
                });
            }

            this.$_handleLoaderState(false);
        },
        handleFilterStopsClick(option) {
            switch (option.id) {
                case 2:
                    this.stopList = this.baseStopList.filter((x) =>
                        this.selectedUnassignedStopsData.includes(x.stopId)
                    );
                    break;
                case 3:
                    this.stopList = this.baseStopList.filter(
                        (x) => !this.selectedUnassignedStopsData.includes(x.stopId)
                    );
                    break;
                default:
                    this.stopList = this.baseStopList;
            }

            if (this.stopList.length > 0) {
                this.filterStopMapPins(this.stopList);
            }
        },
        handleFilterValidationClick(option) {
            switch (option.id) {
                case 2:
                    this.stopList = this.baseStopList.filter((x) => !x.isOutside);
                    break;
                case 3:
                    this.stopList = this.baseStopList.filter((x) => x.isOutside);
                    break;
                default:
                    this.stopList = this.baseStopList;
            }

            if (this.stopList.length > 0) {
                this.filterStopMapPins(this.stopList);
            }
        },
        handleStartTeamRegionChanged(teamRegionId) {
            this.optimiseConfiguration.teamRegionId = teamRegionId;
            this.handleFilterTeamRegionClick(this.optimiseConfiguration);
        },
        handleFilterTeamRegionClick(option) {
            /**
             * All option should always be displayed, regardless of whether the user has a team region assigned.
             * However, when this is selected, it should only show stops from their assigned team region, and stops/shipments
             * which have no team region assigned at all.
             */
            if (option) {
                if (option.teamRegionId > 0) {
                    this.stopList = this.baseStopList.filter(
                        (x) => x.teamRegionId === option.teamRegionId || x.parentTeamRegionId === option.teamRegionId
                    );
                } else if (option.teamRegionId === -1) {
                    this.stopList = this.baseStopList.filter((x) => x.teamRegionId === null || x.teamRegionId === -1);
                } else if (!this.user.teamRegionId || this.user.teamRegionId === -1) {
                    // user has no team region
                    this.stopList = [...this.baseStopList];
                } else {
                    this.stopList = this.baseStopList.filter(
                        (x) =>
                            x.teamRegionId === null ||
                            x.teamRegionId === -1 ||
                            x.teamRegionId === this.user.teamRegionId ||
                            x.parentTeamRegionId === this.user.teamRegionId
                    );
                }
            }
            if (this.stopList.length > 0) {
                this.filterStopMapPins(this.stopList);
            }
        },
        handleFilterServiceAreaClick(options) {
            this.serviceAreaOptions = [];
            this.stopList = [];
            if (options.some((op) => op.checked)) {
                options.forEach((option) => {
                    let baseServiceAreas = JSON.parse(JSON.stringify(this.baseServiceAreaOptions));
                    const areas = baseServiceAreas.filter((sa) => sa.name === option.name);

                    if (areas && areas.length > 0) {
                        if (option.checked) {
                            const saIndex = this.serviceAreaOptions.findIndex((s) => s.name === option.name);
                            if (saIndex < 0) {
                                let selectedStops = [];
                                areas.forEach((area) => {
                                    if (area.suburbLocalities.length > 0) {
                                        area.suburbLocalities.forEach((element) => {
                                            const geometryList = [element.boundary.vertices.map((v) => [v.lng, v.lat])];
                                            const listOfStops = this.baseStopList.filter((stop) => {
                                                return geometryList.some((g) =>
                                                    this.$_map_polygon_containsLocation(stop.location, {
                                                        coordinates: [g]
                                                    })
                                                );
                                            });
                                            selectedStops = selectedStops.concat(listOfStops);
                                        });
                                    } else {
                                        const geometryList = [area.boundary.vertices.map((v) => [v.lng, v.lat])];
                                        const listOfStops = this.baseStopList.filter((stop) => {
                                            return geometryList.some((g) =>
                                                this.$_map_polygon_containsLocation(stop.location, { coordinates: [g] })
                                            );
                                        });
                                        selectedStops = selectedStops.concat(listOfStops);
                                    }
                                    selectedStops.forEach((ss) => this.stopList.push(ss));
                                    this.serviceAreaOptions.push(areas[0]);
                                });
                            }
                        }
                        this.optimiseConfiguration.showServiceArea = true;
                    }
                });
            } else {
                this.stopList = this.baseStopList;
                this.serviceAreaOptions = [];
                this.optimiseConfiguration.showServiceArea = false;
            }

            if (this.stopList.length > 0) {
                this.filterStopMapPins(this.stopList);
            }
        },
        filterByRunNumber() {
            const { teamRegionId, runNumber } = this.optimiseConfiguration;
            if (runNumber && teamRegionId > 0) {
                this.stopList = this.baseStopList.filter(
                    (x) =>
                        (x.runNumber === runNumber || x.runNumber === null) &&
                        (!teamRegionId || x.teamRegionId === teamRegionId || x.parentTeamRegionId === teamRegionId)
                );
            } else if (runNumber) {
                this.stopList = this.baseStopList.filter((x) => x.runNumber === runNumber || x.runNumber === null);
            } else if (teamRegionId > 0) {
                this.stopList = this.baseStopList.filter(
                    (x) => !teamRegionId || x.teamRegionId === teamRegionId || x.parentTeamRegionId === teamRegionId
                );
            } else {
                this.stopList = this.baseStopList;
            }

            this.isValidated = this.stopList.length !== 0;
        },
        filterByTeamRegionId(stops, teamRegionId) {
            if (!stops) 
                return [];

            const res = stops.filter((x) => {
                return !teamRegionId || x.teamRegionId === teamRegionId || x.parentTeamRegionId === teamRegionId;
            });

            return res;
        },
        handleChangeTeamMemberSettings(data) {
            this.selectedTeamMembersData.forEach((vehicleItem) => {
                if (vehicleItem.publicUserId === data.vehicleId) {
                    vehicleItem.customStartTime = data.startTime;
                    vehicleItem.customEndTime = data.endTime;

                    if (data.capacityValues) {
                        vehicleItem.vehicleCapacity = data.capacityValues;
                        vehicleItem.vehicleCapacityString = JSON.stringify(data.capacityValues);
                    }
                }
            });
            this.onCreateMyRuns(this.optimiseInputData.routeModelId);
        },
        getAreaData() {
            const api = `api/teams/service-areas`;
            return handleRequests(api).then((response) => {
                this.serviceAreaOptions = response.data.sort((x, y) => (x.name > y.name ? 1 : -1));
                this.baseServiceAreaOptions = JSON.parse(JSON.stringify(this.serviceAreaOptions));
            });
        },
        handleCustomerChanged(customerId) {
            this.optimiseConfiguration.customerId = customerId;
            this.hasUnassignedStopFilterChanges = true;
        },
        hasCapacitySettings(...capacityTypes) {
            const { vehicleCapacityUnitsConfiguration } = this.user;
            return (
                Array.isArray(vehicleCapacityUnitsConfiguration) &&
                vehicleCapacityUnitsConfiguration.some((x) => capacityTypes.some((y) => x.type === y))
            );
        },
        getCapacityInfo(item, itemName, measurementLabel) {
            const load = item?.load;
            return `${load?.[itemName] ?? '-'} ${measurementLabel}`;
        }
    },
    async mounted() {
        await this.FETCH_ROUTE_SETTINGS();
        this.optimiseConfiguration.teamRegionId = null; // default to All (null) !important
        this.selectedTeamRegionId = this.user.teamRegionId;
        this.initializeMap();
        this.getWarehouses();
        this.getAreaData();
    }
};
</script>

<style lang="scss" scoped>
.create-run-layout {
    width: 650px;
    position: absolute;
    z-index: 1;
}

.not-paid-run-layout {
    top: 60px;
}

.map-overlay {
    position: relative;
    .google-map {
        width: 100%;
        height: 100vh;
    }

    .not-paid-map-overlay {
        width: 100%;
        height: calc(100vh - 60px);
        > div {
            bottom: 0;
        }
    }

    .gmnoprint-tool {
        z-index: 6;
        position: absolute;
        left: 120px;
        top: 110px;
    }
}

.marker-stop {
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.optimise-header {
    font-size: 14px;
    overflow: auto;
    width: 100%;
}

.header-cell-highlighted {
    width: auto;
    height: 45px;
    padding: 15px 34px 15px 34px;
    color: #e5f9d7;
    text-align: center;
    background: #2b93ff;
    vertical-align: middle;
    float: left;
    font-weight: 400;
}

.header-cell {
    width: auto;
    height: 45px;
    padding: 15px 34px 15px 34px;
    color: #f3f7fb;
    text-align: center;
    background: #335977;
    border-left: 1px solid #335977;
    vertical-align: middle;
    float: left;
    font-weight: 400;
}

.wizard-step {
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 12px;
    width: 650px;
    padding: 20px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.wizard-header {
    border-bottom: 1px solid #e8e8e8;
    height: 45px;
    margin-bottom: 20px;
}

.header-text {
    color: #42474a;
    font-size: 22px;
}

.header-sub-text {
    color: #c8c5c5;
}

.header-button {
    background: none;
    border: none;
    float: right;
    font-size: 18px;
    margin-top: 3px;
    cursor: pointer;
}

.button-text {
    padding: 0 10px;
}

.next-button {
    color: #2b93ff;
    cursor: pointer;
}

.next-button:disabled {
    color: #d3d5d7;
    cursor: default;
}

.back-button {
    color: #c8c5c5;
}

.icon-chevron-right {
    color: #2b93ff !important;
    border: 1px solid #2b93ff;
    border-radius: 12px;
}

.next-button:disabled i {
    color: #d3d5d7 !important;
    border: 1px solid #d3d5d7;
}

.icon-chevron-left {
    color: #c8c5c5 !important;
    border: 1px solid #c8c5c5;
    border-radius: 12px;
}

@-moz-keyframes pulsate {
    from {
        -moz-transform: scale(0.25);
        opacity: 1;
    }

    95% {
        -moz-transform: scale(1.3);
        opacity: 0;
    }

    to {
        -moz-transform: scale(0.3);
        opacity: 0;
    }
}

@-webkit-keyframes pulsate {
    from {
        -webkit-transform: scale(0.25);
        opacity: 1;
    }

    95% {
        -webkit-transform: scale(1.3);
        opacity: 0;
    }

    to {
        -webkit-transform: scale(0.3);
        opacity: 0;
    }
}

#map_canvas ::v-deep div[title='pulse'] {
    -moz-animation: pulsate 1s ease-in-out infinite;
    -webkit-animation: pulsate 1s ease-in-out infinite;
    border: 1pt solid #fff;
    -moz-border-radius: 51px;
    -webkit-border-radius: 51px;
    border-radius: 51px;
    // orange ED9900 blue 06f -moz-box-shadow: inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f;
    -webkit-box-shadow: inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f,
        0 0 5px #06f;
    box-shadow: inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f;
    height: 46px !important;
    margin: -18px 0 0 -18px;
    width: 46px !important;
}

::v-deep .marker-stop {
    width: 0;
    height: 0;
}

::v-deep .marker-stop span {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    color: white;
    border: solid 2px;
    border-radius: 0 70% 70%;
    box-shadow: 0 0 2px #000;
    cursor: pointer;
    transform-origin: 0 0;
    transform: rotateZ(-135deg);

    img {
        transform: rotateZ(135deg);
        border-radius: 50%;
    }
}

::v-deep .marker-stop b {
    transform: rotateZ(135deg);
    color: #fff;
}
</style>
