var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "md-card",
        [
          _c(
            "md-card-header",
            { staticClass: "md-card-header-icon md-card-header-warning" },
            [
              _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("map")])],
                1
              ),
              _vm.isTrip
                ? _c(
                    "div",
                    { staticClass: "map-router-link" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Maps Overview Active User Panel",
                              params: {
                                date: _vm.selectedDate,
                                userId: _vm.details.assignedTo.publicUserId,
                              },
                              query: { from: "trip" },
                            },
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    View Route Playback\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("md-card-content", [
            _c("div", {
              staticStyle: { width: "100%", height: "480px" },
              attrs: { id: "map_canvas" },
            }),
          ]),
          _c("div", { staticClass: "mapbox-footer" }, [
            _vm._v(
              "\n            Use CTRL + Shift + Scroll to zoom the map\n        "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }