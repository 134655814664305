<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.generalSettings') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100 member-container">
            <md-card>
                <md-card-content>
                    <tabs
                        :tab-name="tabNames"
                        color-button="success"
                        class="custom-tab-list"
                        v-if="hasTeam"
                        :hide-empty-tabs="true"
                    >
                        <template slot="tab-pane-1">
                            <form-wrapper :validator="$v.settings">
                                <div class="default-tab">
                                    <div>Default Stop Duration (in Minutes):</div>
                                    <div>
                                        <form-group name="defaultStopDurationMinutes">
                                            <md-input
                                                type="number"
                                                v-model.number="settings.defaultStopDurationMinutes"
                                            />
                                        </form-group>
                                    </div>
                                </div>
                                <div class="default-tab">
                                    <div>Stop Duration Behaviour</div>
                                    <div>
                                        <form-group name="defaultSpeedUnit" class="speed-unit-field">
                                            <div class="md-inline-boxes">
                                                <md-radio v-model="settings.stopDurationBehaviour" value="Additive">
                                                    Additive Stop Durations
                                                </md-radio>
                                                <md-radio v-model="settings.stopDurationBehaviour" value="Combined">
                                                    Combined Stop Durations
                                                </md-radio>
                                                <md-avatar>
                                                    <md-icon class="icon-info">info</md-icon>
                                                    <md-tooltip class="tooltip-width" md-direction="right">
                                                        When you have multiple stops at the same address, this setting
                                                        will determine the combined stop duration. You may want the stop
                                                        durations to be added together, or combined into a single stop
                                                        duration. For example, with 3 stops with a 5 minute duration
                                                        each, you can choose to add these together (total: 15 minutes),
                                                        or they can be combined (a total of 5 minutes).
                                                    </md-tooltip>
                                                </md-avatar>
                                            </div>
                                        </form-group>
                                    </div>
                                </div>
                                <div class="default-tab">
                                    <div>Default speed unit:</div>
                                    <div>
                                        <form-group name="defaultSpeedUnit" class="speed-unit-field">
                                            <div class="md-inline-boxes">
                                                <md-radio v-model="settings.defaultSpeedUnits" value="kph">km</md-radio>
                                                <md-radio v-model="settings.defaultSpeedUnits" value="mph">
                                                    miles
                                                </md-radio>
                                            </div>
                                        </form-group>
                                    </div>
                                </div>
                                <div class="default-tab" v-if="isAdmin">
                                    <div>Enable Two Factor Authentication:</div>
                                    <div>
                                        <div class="md-inline-boxes">
                                            <md-switch v-model="settings.isTwoFAEnabled"></md-switch>
                                        </div>
                                    </div>
                                </div>
                            </form-wrapper>
                        </template>
                        <template slot="tab-pane-2">
                            <div v-if="oldJobOffersEnabled">
                                <div class="md-layout custom-switch">
                                    <label class="md-layout-item md-size-15 md-form-label">
                                        Enable Offer Notifications:
                                    </label>
                                    <div class="md-layout-item md-inline-boxes">
                                        <md-switch v-model="settings.offerNotification"></md-switch>
                                    </div>
                                </div>
                                <div class="md-layout">
                                    <label class="md-layout-item md-size-15 md-form-label">Alert Type:</label>
                                    <div class="md-layout-item md-inline-boxes">
                                        <md-radio v-model="settings.alertTypeScheme" value="Default">
                                            Default Communication
                                        </md-radio>
                                        <md-radio v-model="settings.alertTypeScheme" value="PushNotification">
                                            Push Notification
                                        </md-radio>
                                    </div>
                                </div>
                                <div class="md-layout">
                                    <label class="md-layout-item md-size-15 md-form-label">Offer Order:</label>
                                    <div class="md-layout-item md-inline-boxes">
                                        <md-radio v-model="settings.prioritizationScheme" value="Nearest">
                                            Nearest
                                        </md-radio>
                                        <md-radio v-model="settings.prioritizationScheme" value="Random">
                                            Random
                                        </md-radio>
                                    </div>
                                </div>
                                <div class="md-layout">
                                    <label class="md-layout-item md-size-15 md-form-label">Offer Within Radius:</label>
                                    <div class="md-layout-item md-size-10 units-input">
                                        <md-field>
                                            <md-input
                                                class="input-width"
                                                type="number"
                                                v-model.number="settings.offerWithinDistance"
                                            />
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-size-10">
                                        <md-field>
                                            <md-select v-model="settings.preferredDistanceUnits">
                                                <md-option value="kph">km</md-option>
                                                <md-option value="mph">miles</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </tabs>
                </md-card-content>
                <md-card-actions md-alignment="left">
                    <button class="custom-btn" @click="validateForm">Save</button>
                </md-card-actions>
            </md-card>
        </div>
    </div>
</template>

<script>
import { Tabs } from '@/components';
import { handleRequests, showErrorMessage, minStopDuration, maxStopDuration, isStopDurationNull } from '@/helpers';
import { mapGetters, mapMutations } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'GeneralSettings',
    mixins: [GeneralMixin],
    components: { Tabs },
    data() {
        return {
            settings: {},
            user: null,
            tabNames: ['Team'],
            isAdmin: Boolean(this.$route.query.isAdmin)
        };
    },
    validations: {
        settings: {
            defaultStopDurationMinutes: {
                minValue_customerStopDuration: minStopDuration,
                maxValue_customerStopDuration: maxStopDuration, // max duration value of 24 hours,
                numeric_duration: isStopDurationNull
            }
        }
    },
    computed: {
        ...mapGetters({
            getUser: 'user/user',
            hasTeam: 'user/hasTeam',
            oldJobOffersEnabled: 'user/oldJobOffersEnabled'
        })
    },
    async mounted() {
        if (this.oldJobOffersEnabled) {
            this.tabNames.push('Stop/Shipment Offers');
        }

        this.user = Object.assign({}, this.getUser);
        await this.getTeamData();
    },
    methods: {
        ...mapMutations({
            updateGeneralSettings: 'generalSetting/SET_TEAM_GENERAL_SETTINGS'
        }),
        async getTeamData() {
            const api = '/api/teams/settings/general';
            const { data } = await handleRequests(api);
            this.settings = data;
            if (!this.settings.preferredDistanceUnits) {
                this.settings.preferredDistanceUnits = this.user.speedUnits;
            }
        },
        validateForm() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.saveConfiguration();
            }
        },
        async saveConfiguration() {
            this.$_handleLoaderState(true, 'SAVING...');

            // When user enters a blank value for defaultStopDurationMinute, it's passed to the API as an empty string. This
            // results in the API not being able to deserialise the data, resulting in a null reference exception on the server.
            // So we need to convert a blank string to null for this field.
            if (this.settings.defaultStopDurationMinutes === '') 
                this.settings.defaultStopDurationMinutes = null;

            const payload = {
                method: 'post',
                data: this.settings
            };

            const api = '/api/teams/settings/general';
            try {
                await handleRequests(api, payload);

                this.updateGeneralSettings(this.settings);

                this.$notify({
                    message: 'Settings saved!',
                    type: 'success'
                });
                this.$_handleLoaderState(false);
            } catch (e) {
                this.$_handleLoaderState(false);
                const message = 'Cannot save settings.';
                showErrorMessage(this, message, e);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.member-container {
    margin-top: 15px;
    ::v-deep .md-field {
        margin: 0;
    }
    .custom-switch {
        .md-checked {
            ::v-deep .md-switch-container {
                background-color: #2b93ff !important;
            }
            ::v-deep .md-switch-thumb {
                border: 1px solid #2b93ff !important;
            }
        }
    }
    ::v-deep .custom-tab-list {
        .tab-content {
            padding-top: 25px !important;
        }
    }
    .units-input {
        ::v-deep input {
            width: 100px !important;
        }
    }
    .md-form-label {
        margin-top: -5px;
        text-align: right !important;
    }
    .md-inline-boxes {
        display: inline-flex;
        .md-radio {
            margin-top: 15px !important;
        }
    }
    .currency-field {
        margin-top: 7px;
    }

    .default-tab {
        > div {
            display: inline-block;
            vertical-align: middle;
        }
        > div:first-child {
            min-width: 250px;
            text-align: right;
            margin-right: 10px;
            color: #aaa;
            font-weight: 400;
            font-size: inherit;
            line-height: 1.5;
        }

        .speed-unit-field {
            ::v-deep label {
                top: auto;
            }
        }

        .speed-unit-field:after {
            height: 0;
        }

        .md-inline-boxes ::v-deep .md-radio {
            margin: 8px 20px 8px 0px;
        }
        .icon-info {
            color: #ffa21a !important;
            cursor: pointer;
        }
    }
}
.tooltip-width {
    max-width: 500px;
    text-align: justify;
}
.card-icon {
    background: #4ed2ff !important;
}
</style>
