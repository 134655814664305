<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
            <div class="card-icon">
                <md-icon>post_add</md-icon>
            </div>
            <div class="note-type-filter" v-if="list.length > 0">
                <vue-select
                    :reduce="(x) => x.name"
                    label="name"
                    :options="noteTypes"
                    placeholder="Status"
                    v-model="selectedNoteType"
                    :searchable="$root.isDesktop"
                    :clearable="false"
                ></vue-select>
            </div>
        </md-card-header>

        <md-card-content>
            <div v-if="filteredList.length > 0">
                <div class="stop-notes" v-for="(item, index) in filteredList" :key="index">
                    <div>
                        <div v-if="item.type" class="note-type">
                            <span class="custom-badge" :class="`status-${$_getBadgeColor(item.type)}`">
                                {{ getItemType(item) }}
                            </span>
                            <span v-if="item.type === 'Outgoing SMS' || item.type === 'Outgoing Email'">
                                {{ item.createdDate | dateFormat(DATE_TYPES.standardDateTime) }}
                            </span>
                            <span v-if="item.stopRef" class="note-reference">
                                <router-link
                                    :to="{
                                        name: 'Stop Details',
                                        params: { stopId: item.stopId }
                                    }"
                                    target="_blank"
                                >
                                    {{ item.stopRef }}
                                </router-link>
                            </span>
                            <span v-if="item.shipmentRef" class="note-reference">
                                <router-link
                                    :to="{
                                        name: 'Shipment Details',
                                        params: { shipmentId: item.shipmentId }
                                    }"
                                    target="_blank"
                                >
                                    {{ item.shipmentRef }}
                                </router-link>
                            </span>
                        </div>
                        <div class="rating" v-if="item.rating">
                            <md-icon
                                v-for="indexRating in 5"
                                :key="indexRating"
                                :style="{ color: indexRating <= item.rating ? 'orange' : 'rgba(0,0,0,0.54' }"
                            >
                                star_rate
                            </md-icon>
                        </div>
                        <div class="rater-details">
                            <span v-if="item.raterName">({{ item.stopContactName }}) -</span>
                            <span>{{ item.stopAddress }}</span>
                        </div>
                        <div class="note-document">
                            <div v-if="item.documentUrls != null && item.documentUrls.length > 0">
                                <div v-for="(documentUrl, documentIndex) in item.documentUrls" :key="documentIndex">
                                    <a :href="documentUrl.photoUrl" download target="_blank">
                                        <img
                                            v-if="$_getFileIcon(documentUrl.photoName)"
                                            class="file-icon"
                                            :src="`/img/icons/${$_getFileIcon(documentUrl.photoName)}.png`"
                                            alt=""
                                        />
                                        {{ documentUrl.photoName }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="note-photos" v-if="item.photoUrls != null && item.photoUrls.length > 0">
                            <div class="note-image-container">
                                <div v-for="(photoUrl, photoIndex) in item.photoUrls" :key="photoIndex">
                                    <a @click="viewPhoto(item.photoUrls, photoUrl)">
                                        <img
                                            class="note-image"
                                            :src="photoUrl.thumbnailUrl"
                                            @error="$_notePhotoLoading($event, photoUrl.thumbnailUrl)"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="note-preview" v-html="$_compiledMarkdown(item.note)">
                            {{ item.note }}
                        </div>
                        <div class="note-metadata" v-if="item.metadata != null">
                            <div v-for="(metadata, metaindex) in item.metadata" :key="metaindex">
                                <div v-if="metaindex === 'questions'">
                                    <span v-for="(x, j) in metadata" :key="j">
                                        <template v-if="typeof x === 'string' || x instanceof String">
                                            <span class="questions">
                                                <span class="label">{{ getQuestionLabel(j, item.type) }}:</span>
                                                <span class="answers-multiline" v-if="isMultilineAnswer(x)">
                                                    {{ x }}
                                                </span>
                                                <span class="answers" v-else>{{ x }}</span>
                                            </span>
                                            <br />
                                        </template>
                                    </span>
                                </div>
                                <div v-else-if="metaindex !== 'runNumber'">
                                    <div class="metadata-label" v-if="checkMetadataValue(metadata)">
                                        {{ $_camelCaseToSentenceCase(metaindex) }}:
                                    </div>
                                    <div v-if="typeof metadata === 'object'">
                                        <span v-for="(x, j) in metadata" :key="j">
                                            {{ x.barcode }}
                                            <span class="barcode-notes">
                                                {{ x.note ? `, ${x.note}` : '' }}
                                            </span>
                                            <br />
                                        </span>
                                    </div>
                                    <div v-else>{{ metadata }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="note-author">
                            <md-avatar>
                                <img
                                    :src="
                                        item.createdByPhotoUrl == null ? $root.defaultPhotoUrl : item.createdByPhotoUrl
                                    "
                                    class="profile-image"
                                    @error="$_setDefaultBrokenImage"
                                    alt=""
                                />
                                <md-tooltip md-direction="top">
                                    <i>
                                        {{ item.createdDate | dateFormat(DATE_TYPES.standardDateTime) }}
                                    </i>
                                    <br />
                                    by: {{ item.createdByUserFullName }}
                                </md-tooltip>
                            </md-avatar>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="text-center">
                <p class="grayedout-text">No notes found</p>
            </div>
        </md-card-content>

        <md-card-actions md-alignment="right" v-if="isAllowedToAddNotes">
            <md-button class="md-rose" @click="handleAddNotes()">Add note</md-button>
        </md-card-actions>
    </md-card>
</template>
<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import PhotoPreviewModal from '@/components/PhotoPreviewModal';
import NotesModal from '@/pages/NotesModal';
import { NOTE_TYPE_CONSTANTS, STOP_NOTE_TYPE_CONSTANTS } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
    name: 'Notes',
    mixins: [GeneralMixin],
    props: {
        list: {
            type: Array,
            default: () => []
        },
        shipmentId: {
            type: Number,
            default: null
        },
        stopId: {
            type: Number,
            default: null
        },
        tripId: {
            type: Number,
            default: null
        },
        orderId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            isAllowedToAddNotes: false,
            selectedNoteType: 'All',
            noteTypes: [...NOTE_TYPE_CONSTANTS],
            filteredList: []
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isCustomerAdmin: 'user/isCustomerAdmin',
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {
        getItemType(item) {
            if (item.metadata?.runNumber) {
                const type = item.type === 'Trip Started' ? 'Started' : 'Complete';
                return `Run ${item.metadata.runNumber} - ${type}`;
            }
            return item.type;
        },
        checkMetadataValue(metadata) {
            let result = true;
            switch (typeof metadata) {
                case 'object': {
                    result = metadata.length > 0;
                    break;
                }
                default: {
                    result = metadata !== '';
                }
            }
            return result;
        },
        viewPhoto(photos, selected) {
            this.$modal
                .show(PhotoPreviewModal, {
                    photos,
                    selectedPhoto: selected
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.$modal.hide();
                    }
                });
        },
        handleAddNotes() {
            this.$modal
                .show(NotesModal, {
                    stopId: this.stopId,
                    shipmentId: this.shipmentId,
                    tripId: this.tripId,
                    orderId: this.orderId
                })
                .then((response) => {
                    this.$emit('notesAdded');
                });
        },
        getFilteredType() {
            let { list } = this;
            if (this.selectedNoteType !== 'All') {
                list = this.list.filter((note) => note.type === this.selectedNoteType);
            }

            this.filteredList = list;
        },
        isMultilineAnswer(value) {
            return value.search('\n') !== -1;
        },
        getQuestionLabel(fieldName, type) {
            let questionType;
            switch (type.toLowerCase()) {
                case 'trip started':
                    questionType = 'tripstart';
                    break;
                case 'trip complete':
                    questionType = 'tripend';
                    break;
                case 'attempt failed':
                    questionType = 'faileddelivery';
                    break;
                default:
                    questionType = type;
                    break;
            }
            const questionsConfiguration = this.user.questionsConfiguration[
                questionType?.toLowerCase().replace(/[^\w]/gi, '')
            ];
            const question = questionsConfiguration?.find((x) => x.name === fieldName);
            return question?.label ?? fieldName;
        }
    },
    watch: {
        selectedNoteType(newValue) {
            this.getFilteredType();
        },
        list(newValue) {
            // this.filteredList = newValue;
            this.getFilteredType();
        }
    },
    mounted() {
        if (
            (this.shipmentId != null || this.stopId != null || this.orderId != null) &&
            (!this.isReadOnlyUser || this.isCustomerAdmin)
        ) {
            this.isAllowedToAddNotes = true;
        }
        if (this.stopId != null || this.tripId != null) {
            this.noteTypes = this.noteTypes.concat([...STOP_NOTE_TYPE_CONSTANTS]);
        }

        this.noteTypes.unshift({
            name: 'All',
            color: null
        });

        this.filteredList = this.list;
    }
};
</script>

<style lang="scss" scoped>
.stop-notes {
    border-bottom: 1px solid #eee;
    display: table;
    width: 100%;
    padding: 10px 0;

    > div {
        display: table-cell;
        vertical-align: top;
    }
    > div:last-child {
        width: 50px;
    }
    > div:first-child {
        width: 100%;
    }

    div {
        font-weight: 400;
    }
    .note-preview {
        ::v-deep p {
            margin: 0;
            white-space: pre-line;
        }
    }
    .profile-image {
        border-radius: 50%;
        height: 32px;
        width: 32px;
        box-shadow: none !important;
        border: 1px solid #eee;
    }
    ::v-deep .profile-image {
        border-radius: 50%;
        height: 32px;
        width: 32px;
        box-shadow: none !important;
        border: 1px solid #eee;
    }
    .note-author {
        text-align: right;
        margin-top: 0;
    }
    .note-photos {
        margin-top: 5px;
    }
    .note-type {
        .custom-badge {
            margin-top: -2px;
            // vertical-align: middle;
        }
    }
}
.stop-notes:last-child {
    border-bottom: none;
}
.stop-time-details {
    > div {
        display: inline-block;
        width: 200px;
    }
    a {
        cursor: pointer;
    }
}

.note-photos {
    display: inline-block;
    vertical-align: middle;
}

.note-photos-container {
    display: inline-block;
}

.note-image-container {
    display: flex;
    flex-wrap: wrap;
    > div {
        height: 60px;
        width: 60px;
        border: 1px solid #d6d6d6;
        margin-right: 5px;
        a {
            display: flex;
            height: 100%;
        }
        img {
            vertical-align: middle;
            max-height: 100%;
            object-fit: contain;
            cursor: pointer;
        }
    }
}

.note-reference {
    margin-left: 10px;
    font-weight: 600;
    color: #2b93ff;
    a {
        color: #2b93ff !important;
    }
}

.note-document {
    .file-icon {
        width: 15px;
        height: 15px;
        margin-right: 2px;
    }
}

.stop-note-loading-photos {
    font-style: italic;
}

.note-metadata {
    > div > div {
        display: table;
        > div {
            display: table-cell;
            // vertical-align: middle;
        }
        > div:last-child {
            padding-left: 5px;
        }
        .metadata-label {
            font-weight: 600;
        }
    }
    .barcode-notes {
        font-style: italic;
    }
    .questions {
        .label {
            font-weight: 600;
            min-width: 65px;
        }
        .answers {
            padding-left: 5px;
        }
        .answers-multiline {
            white-space: pre-wrap;
            padding-left: 5px;
            display: table-cell;
        }
    }
}

.rater-details span {
    color: #aaa;
}

.note-type-filter {
    display: inline-block;
    width: 200px;
    position: absolute;
    right: 20px;
    top: 7px;

    ::v-deep .vs__dropdown-toggle {
        border-radius: 0;
    }
}
</style>
