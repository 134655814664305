<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ isUpdate ? 'Update' : 'Create' }} Run Stop</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <!-- <div v-if="!runStopDetails.shipmentId">
            <md-button class="md-success chargeButton-toggle" @click="openCharges()">
                Charges ({{ chargeList.length }})
            </md-button>
            <charge-list-modal
                :rates-list="chargeList"
                :rates-is-loading="ratesIsLoading"
                :rate-list-data="rateListData"
                :currency="runStopDetails.currency"
            />
        </div>
        <br /> -->

        <div class="modal-body stop-form-modal">
            <form-wrapper :validator="$v.runStopDetails" class="form-wrapper">
                <div class="stop-form-container">
                    <charging-type-options
                        v-if="!runStopDetails.shipmentId"
                        :selected-charge-type-id="runStopDetails.rateGroupId"
                        @selectedOption="handleRateTypeChanged"
                    />
                    <div v-if="!runStopDetails.shipmentId">
                        <form-group name="type" label="Type">
                            <md-input v-model="runStopDetails.type" type="hidden" />
                            <vue-select
                                :options="stopTypes"
                                :reduce="(x) => x.type"
                                label="key"
                                v-model="runStopDetails.type"
                            ></vue-select>
                        </form-group>
                    </div>
                    <!-- <div>
                        <form-group name="sourceReference" label="Source Reference">
                            <md-input
                                v-model="runStopDetails.sourceReference"
                                :readonly="runStopDetails.shipmentId !== null"
                            />
                        </form-group>
                    </div> -->
                    <div class="contact-details">
                        <span class="cd-title">Customer Details</span>
                        <customer-autocomplete
                            :label="runStopDetails.customerId == null ? 'Name' : 'Customer'"
                            id="autocustomer"
                            placeholder=""
                            :should-focus="false"
                            v-model="runStopDetails"
                            :update-mode="isUpdate"
                            :auto-fill-address="true"
                            :update-customer="!isUpdate"
                            :disabled="customerNameIsDisabled()"
                            @changeCustomerRateGroup="onCustomerRateGroupIdChange"
                        />

                        <div>
                            <google-autocomplete
                                label="Destination"
                                ref="address"
                                id="autoaddress"
                                classname="form-control autocomplete-input"
                                placeholder=""
                                :should-focus="false"
                                v-model="runStopDetails"
                                :disable-gps-coordinates="false"
                                @handleCoordinates="handleCoordinates"
                            ></google-autocomplete>
                        </div>
                        <form-group name="name" label="Contact Name" v-if="runStopDetails.customerId != null">
                            <md-input v-model="runStopDetails.contact.name" />
                        </form-group>
                        <form-group name="phone" label="Phone">
                            <md-input v-model="runStopDetails.contact.phone" />
                        </form-group>
                        <form-group name="email" label="Email">
                            <md-input v-model="runStopDetails.contact.email" />
                        </form-group>
                        <div v-if="loadFieldList !== null">
                            <load-inputs
                                v-for="(load, index) in loadFieldList"
                                :key="index"
                                :load-definition="load"
                                :initial-value="loadValues"
                                @changed="handleLoadChanged"
                            />
                        </div>
                        <form-group v-if="skillOptions.length" name="skills" label="Skills">
                            <multiselect
                                v-model="runStopDetails.skills"
                                :options="skillOptions"
                                :multiple="true"
                                :close-on-select="true"
                                placeholder="Pick required skills"
                            ></multiselect>
                        </form-group>
                        <form-group name="notes" label="Notes">
                            <md-textarea v-model="runStopDetails.notes" />
                        </form-group>
                        <div v-if="stopCustomFieldList !== null">
                            <custom-field-inputs
                                v-for="(customField, index) in stopCustomFieldList"
                                :key="index"
                                :custom-field-definition="customField"
                                :initial-value="customFieldValues[customField.name]"
                                @changed="handleCustomFieldChanged"
                            />
                        </div>
                        <div class="stop-datepicker">
                            <div>
                                <form-group name="time" label="Time" class="time-picker">
                                    <time-picker
                                        :time="time"
                                        :additional-time-options="additionalTimeOption"
                                        @selectedTime="getTime"
                                        :enable-auto-scroll="true"
                                    />
                                </form-group>
                            </div>
                            <div class="duration-container">
                                <form-group name="durationMinutes" label="Duration (mins)">
                                    <md-input type="number" v-model="runStopDetails.durationMinutes" />
                                </form-group>
                            </div>
                        </div>
                        <!-- <div>
                            <form-group name="order" label="Order">
                                <md-input type="number" v-model="runStopDetails.order" />
                            </form-group>
                        </div> -->
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateRunStop">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="handleHideModal">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage, isAValidCoordinate } from '@/helpers';
import { required, numeric, requiredIf } from 'vuelidate/lib/validators';
import {
    TimePicker,
    GoogleAutocomplete,
    CustomFieldInputs,
    LoadInputs,
    CustomerAutocomplete,
    ChargingTypeOptions
} from '@/components';
import { GeneralMixin } from '@/mixins';
import { mapGetters } from 'vuex';
import moment from 'moment';
import {
    DATE_TYPES,
    STOP_TYPES,
    RUN_SCHEDULE_RUN_STOP_UPDATE_OPERATION,
    TIME_WINDOW_CONSTANTS
} from '@/utils/constants';

export default {
    name: 'CreateRunStopModal',
    components: {
        TimePicker,
        GoogleAutocomplete,
        CustomFieldInputs,
        LoadInputs,
        CustomerAutocomplete,
        ChargingTypeOptions
    },
    mixins: [GeneralMixin],
    props: {
        runStopDetails: {
            type: Object,
            default: () => {}
        },
        members: {
            type: Array,
            default: () => []
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        verifyPendingTripExistsOnRunSchedule: {
            type: Function,
            default: () => {}
        },
        runScheduleCustomerId: {
            type: [String, Number],
            default: () => 0
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    created() {
        if (this.isUpdate) {
            let stopTime = null;
            if (this.runStopDetails.timeWindowStart != null && this.runStopDetails.timeWindowEnd != null) {
                const timeWindowStart = moment(this.runStopDetails.timeWindowStart).format(DATE_TYPES.militaryTime);
                const timeWindowEnd = moment(this.runStopDetails.timeWindowEnd).format(DATE_TYPES.militaryTime);

                const timeWindowStartStandard = moment(this.runStopDetails.timeWindowStart).format(
                    DATE_TYPES.standardTime
                );
                const timeWindowEndStandard = moment(this.runStopDetails.timeWindowEnd).format(DATE_TYPES.standardTime);

                let hasMatch = false;

                TIME_WINDOW_CONSTANTS.some((x) => {
                    if (timeWindowStart === x.timeWindowStart && timeWindowEnd === x.timeWindowEnd) {
                        stopTime = x.name;
                        hasMatch = true;
                    }
                    return timeWindowStart === x.timeWindowStart && timeWindowEnd === x.timeWindowEnd;
                });

                if (!hasMatch) {
                    stopTime = `${timeWindowStartStandard} - ${timeWindowEndStandard}`;
                    this.additionalTimeOption.push({
                        name: stopTime,
                        timeWindowStart,
                        timeWindowEnd
                    });
                }
            } else if (this.runStopDetails.appointmentTime != null) {
                stopTime = moment(this.runStopDetails.appointmentTime).format(DATE_TYPES.standardTime);
            } else {
                stopTime = 'None';
            }
            this.time = stopTime;
        }
    },
    async mounted() {
        this.stopCustomFieldList = this.user.stopCustomFieldDefinitions;
        if (this.user.skillOptions) {
            this.skillOptions = this.user.skillOptions.map((x) => x.name);
        }
        this.loadFieldList = this.user.vehicleCapacityUnitsConfiguration || [];

        if (
            this.stopCustomFieldList != null &&
            this.stopCustomFieldList.length &&
            this.runStopDetails.customFields != null
        ) {
            this.customFields =
                typeof this.runStopDetails.customFields === 'object'
                    ? this.runStopDetails.customFields
                    : JSON.parse(this.runStopDetails.customFields);
        }

        if (this.loadFieldList != null && this.loadFieldList.length && this.runStopDetails.load != null) {
            this.loadValues = this.runStopDetails.load;
        }

        await this.preLoadCustomerDetails();
    },
    methods: {
        async validateRunStop() {
            this.$v.runStopDetails.$touch();

            if (!this.$v.runStopDetails.$invalid) {
                await this.verifyPendingTripExistsOnRunSchedule(
                    this.getRunOperation(),
                    this.runStopDetails.runScheduleId,
                    this.getRunScheduleOnSuccessFunction()
                );
            }
        },
        async createRunStop() {
            this.$_handleLoaderState(true, 'SAVING...');
            await this.mapRunDetails();
            const api = `/api/run-schedules/${this.runStopDetails.runScheduleId}/create-stop`;
            const payload = {
                method: 'post',
                data: this.runStopDetails
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully created a stop.',
                    type: 'success'
                });
                this.$v.$reset();
                this.resolve('ok');
            } catch (e) {
                const message = 'Could not create a new stop.';
                showErrorMessage(this, message, e);
                this.resolve(false);
            }
        },
        async updateRunStopDetails() {
            this.$_handleLoaderState(true, 'UPDATING...');
            await this.mapRunDetails();
            const api = `/api/run-schedules/update-stop/${this.runStopDetails.runScheduleStopId}`;

            const payload = {
                method: 'put',
                data: this.runStopDetails
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully updated stop.',
                    type: 'success'
                });
                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Error in updating the stop';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        async mapRunDetails() {
            // eslint-disable-next-line radix
            this.runStopDetails.durationMinutes = parseInt(this.runStopDetails.durationMinutes);
            this.runStopDetails.customFields = JSON.stringify(this.customFieldValues);
            this.runStopDetails.load = this.loadValues;
            this.runStopDetails.skills =
                this.runStopDetails.skills === 'null' || this.runStopDetails.skills === []
                    ? null
                    : this.runStopDetails.skills;
        },
        handleCustomFieldChanged({ name, value }) {
            this.customFieldValues[name] = value;
        },
        handleLoadChanged({ name, value }) {
            this.loadValues[name] = value;
        },
        handleCoordinates(value) {
            this.useCoordinates = value;
            this.$v.$reset();
        },
        handleHideModal() {
            if (this.isUpdate) {
                this.resolve('ok');
            } else {
                this.$modal.hide();
            }
        },
        getTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime, timeWindowStart, timeWindowEnd } = selectedTime;
                this.runStopDetails.appointmentTime = appointmentTime;
                this.runStopDetails.timeWindowStart = timeWindowStart;
                this.runStopDetails.timeWindowEnd = timeWindowEnd;
            } else {
                this.runStopDetails.appointmentTime = null;
                this.runStopDetails.timeWindowStart = null;
                this.runStopDetails.timeWindowEnd = null;
            }
            this.isTimeValid = isTimeValid;
        },
        openCharges() {
            document.querySelector('.charge-wrapper').classList.toggle('side-panel-open');
            document.querySelector('.charge-wrapper').classList.toggle('side-panel');
        },
        handleRateTypeChanged(rateRule) {
            this.runStopDetails.rateGroupId = rateRule?.rateGroupId ?? null;
            this.ruleGenerationDebounce();
        },
        validateCanGenerateRates(addressChanged, rateGroupChanged) {
            if (this.runStopDetails.address !== null && this.ratesIsLoading === false) {
                this.generateRates(addressChanged, rateGroupChanged);
            }
        },
        async generateRates(addressChanged, rateGroupChanged) {
            if (!this.hasRateRules) 
                return;

            if (this.runStopDetails.shipmentId) {
                return;
            }

            this.ratesIsLoading = true;

            let response = {
                data: {
                    rateList: null
                }
            };

            // eslint-disable-next-line no-unused-expressions
            this.chargeList?.forEach((rate, index) => {
                rate.stopId = this.runStopDetails.stopId;
                rate.isChargingRule = true;
                rate.customerId = this.runStopDetails.customerId;
            });

            // eslint-disable-next-line no-unused-expressions
            this.costList?.forEach((cost, index) => {
                cost.stopId = this.runStopDetails.stopId;
                cost.isCostingRule = true;
                cost.customerId = this.runStopDetails.customerId;
            });

            try {
                const data = this.$_getStopData(
                    this.runStopDetails,
                    this.user,
                    this.loadValues,
                    this.chargeList,
                    this.costList,
                    true,
                    true,
                    false,
                    false,
                    rateGroupChanged,
                    addressChanged
                );

                const payload = {
                    method: 'post',
                    data
                };

                response = await handleRequests('/api/rates/generate-rates', payload);
                if (response.data) {
                    this.rateListData = response.data;
                    this.chargeList = response.data.charge ?? [];
                    this.costList = response.data.cost ?? [];

                    this.updatedCharges = response.data.charge ?? [];
                    this.updatedCosts = response.data.cost ?? [];
                }
                this.ratesIsLoading = false;
            } catch (error) {
                const message = `Error generating rates, if issue persists please contact support`;
                showErrorMessage(this, message, null);
                this.ratesIsLoading = false;
            }
        },
        // eslint-disable-next-line func-names
        ruleGenerationDebounce: _.debounce(function() {
            this.validateCanGenerateRates(false, true);
        }, 500),
        // eslint-disable-next-line func-names
        ruleGenerationLoadDetails: _.debounce(function() {
            this.validateCanGenerateRates(false, false);
        }, 1000),
        // eslint-disable-next-line func-names
        handlerStopDetailsChanged: _.debounce(function() {
            this.validateCanGenerateRates(true, false);
        }, 500),
        handleCustomerFieldChange() {
            this.ruleGenerationLoadDetails();
        },
        handleCustomerChangeRateGroup(customerRateGroupId) {
            if (this.runStopDetails.rateGroupId === null || this.runStopDetails.rateGroupId === undefined) {
                this.runStopDetails.rateGroupId = customerRateGroupId;
            }
        },
        onCustomerRateGroupIdChange(rateGroupId) {
            this.runStopDetails.rateGroupId = rateGroupId;
        },
        async getCustomerDetails(customerId) {
            let customerData;
            try {
                const api = `/api/customers/${customerId}`;
                const { data } = await handleRequests(api);
                customerData = data;
            } catch (error) {
                const message = 'Error in getting the customer details';
                showErrorMessage(this, message, error);
            }
            return customerData;
        },
        async preLoadCustomerDetails() {
            if (this.runScheduleCustomerId && this.runScheduleCustomerId > 0 && !this.isUpdate) {
                const customerDetails = await this.getCustomerDetails(this.runScheduleCustomerId);

                if (customerDetails) {
                    this.runStopDetails.rateGroupId = customerDetails.defaultRateGroupId;
                    this.runStopDetails.customerId = customerDetails.customerId;
                }
            }
        },
        customerNameIsDisabled() {
            if (
                !this.isUpdate ||
                !this.runStopDetails ||
                !this.runScheduleCustomerId ||
                this.runScheduleCustomerId === 0
            )
                return true;

            // We will let user to update stop's customer ONLY IF this is different from run schedule customer
            return this.runStopDetails.customerId === this.runScheduleCustomerId;
        },
        getRunOperation() {
            return this.isUpdate ? this.runOperation.Edit : this.runOperation.Add;
        },
        getRunScheduleOnSuccessFunction() {
            return this.isUpdate ? this.updateRunStopDetails : this.createRunStop;
        }
    },
    data() {
        return {
            stopCustomFieldList: [],
            customFieldValues: {},
            loadFieldList: [],
            loadValues: {},
            skillOptions: [],
            useCoordinates: false,
            stopTypes: STOP_TYPES,
            runOperation: RUN_SCHEDULE_RUN_STOP_UPDATE_OPERATION,
            time: null,
            additionalTimeOption: []
        };
    },
    validations: {
        runStopDetails: {
            // eslint-disable-next-line func-names
            address: {
                required:
                    // eslint-disable-next-line func-names
                    requiredIf(function() {
                        return !this.useCoordinates;
                    }),
                coordinatesValid(data) {
                    if (this.useCoordinates) {
                        return isAValidCoordinate(data);
                    }
                    return true;
                }
            },
            durationMinutes: { required, numeric }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}
.cd-title {
    margin-top: 10px;
}

.stop-datepicker {
    vertical-align: middle;
    .duration-container {
        ::v-deep .md-field {
            padding-top: 4px;
        }
    }
}
</style>
