<template>
    <div class="cf-container">
        <div class="cf-action-container" v-if="settings.length > 0">
            <md-button
                title="Save Proof of delivery configurations"
                class="md-primary md-just-icon md-round"
                @click.stop="saveConfiguration()"
            >
                <md-icon>save</md-icon>
            </md-button>
        </div>

        <div class="alert-section">
            <div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="showAlert">
                <strong>ATTENTION!</strong>
                Please click the save button on the upper right to save the changes made.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="closeAlert">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>

        <md-table class="custom-paginated-table">
            <draggable v-model="settings" draggable=".drag-item" class="custom-draggable">
                <md-table-row slot="header">
                    <md-table-head></md-table-head>
                    <md-table-head>Type</md-table-head>
                    <md-table-head>Stops</md-table-head>
                    <md-table-head>Shipment Pickup</md-table-head>
                    <md-table-head>Shipment Drop Off</md-table-head>
                    <md-table-head v-if="!isReadOnlyUser && !isSingleUser">Include in PDF</md-table-head>
                </md-table-row>

                <md-table-row v-for="(setting, index) in settings" :key="index" class="drag-item">
                    <md-table-cell>
                        <md-icon>drag_indicator</md-icon>
                    </md-table-cell>
                    <md-table-cell>
                        {{ $t('podTypes.' + setting.type.toLowerCase()) }}
                    </md-table-cell>
                    <md-table-cell>
                        <form-group class="pod-dropdown">
                            <md-select v-model="setting.standardStops" @md-selected="updateSettings">
                                <md-option value="Always">Always</md-option>
                                <md-option value="Optional">Optional</md-option>
                                <md-option value="Never">Never</md-option>
                            </md-select>
                        </form-group>
                    </md-table-cell>

                    <md-table-cell>
                        <form-group class="pod-dropdown">
                            <md-select v-model="setting.shipmentPickupStops" @md-selected="updateSettings">
                                <md-option value="Always">Always</md-option>
                                <md-option value="Optional">Optional</md-option>
                                <md-option value="Never">Never</md-option>
                            </md-select>
                        </form-group>
                    </md-table-cell>

                    <md-table-cell>
                        <form-group class="pod-dropdown">
                            <md-select v-model="setting.shipmentDropStops" @md-selected="updateSettings">
                                <md-option value="Always">Always</md-option>
                                <md-option value="Optional">Optional</md-option>
                                <md-option value="Never">Never</md-option>
                            </md-select>
                        </form-group>
                    </md-table-cell>

                    <md-table-cell v-if="!isReadOnlyUser && !isSingleUser">
                        <form-group class="pod-checkbox">
                            <md-checkbox v-model="setting.includeInPodPdf"></md-checkbox>
                        </form-group>
                    </md-table-cell>
                </md-table-row>
            </draggable>
        </md-table>
        <div class="text-center" v-if="settings.length == 0">
            <p class="no-result-message">This setting is currently not yet available.</p>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';

export default {
    name: 'ProofOfDeliveryConfiguration',
    mixins: [GeneralMixin],
    props: {
        level: {
            type: String,
            default: 'users'
        }
    },
    data() {
        return {
            settings: [],
            showAlert: false
        };
    },
    computed: {
        includeInPodPdfValues() {
            return this.settings.map((setting) => setting.includeInPodPdf);
        },
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            isSingleUser: 'user/isIndividualUser'
        })
    },
    async mounted() {
        this.$_handleLoaderState(true);

        const api = `/api/${this.level}/proof-of-delivery`;
        const response = await handleRequests(api);
        await this.setDefaultConfiguration(response.data);
        this.$watch('includeInPodPdfValues', (newVal, oldVal) => {
            this.updateSettings();
        });

        this.$_handleLoaderState(false);
    },
    methods: {
        async saveConfiguration() {
            this.$_handleLoaderState(true, 'SAVING...');
            const payload = {
                method: 'post',
                data: this.settings
            };
            const api = `/api/${this.level}/proof-of-delivery`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Proof of Delivery settings saved!',
                    type: 'success'
                });
                this.showAlert = false;
                await this.$store.dispatch('user/FETCH_USER');
            } catch (e) {
                const message = 'Cannot save proof of delivery settings.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        },
        async setDefaultConfiguration(podConfiguration) {
            let types = ['Signature', 'Photos', 'Barcodes', 'Notes', 'OneTimePin'];

            if (podConfiguration) {
                types = [];
                podConfiguration.forEach((type) => {
                    types.push(type.type);
                });
            }

            this.settings = [];

            types.forEach((type) => {
                const existingConfiguration =
                    podConfiguration !== '' ? podConfiguration.find((x) => x.type === type) : null;
                if (existingConfiguration) {
                    this.settings.push(existingConfiguration);
                } else {
                    this.settings.push({
                        type,
                        standardStops: 'Never',
                        shipmentPickupStops: 'Never',
                        shipmentDropStops: 'Never',
                        includeInPodPdf: false
                    });
                }
            });
        },
        updateSettings() {
            this.showAlert = true;
        },
        closeAlert() {
            this.showAlert = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.actions {
    width: 6%;
}
.pod-dropdown {
    width: 100px;
    margin-top: 0;
    border: 1px solid #ddd;
    padding: 0;
    padding-left: 10px;
}

.pod-checkbox {
    width: 100px;
    margin-top: 0;
    padding: 0;
    padding-left: 10px;
}
.pod-checkbox:after,
.pod-checkbox:before {
    height: 0;
}

.pod-dropdown:after,
.pod-dropdown:before {
    height: 0;
}

.alert-section {
    padding-top: 10px;
}

.custom-draggable {
    display: table;
    width: 100%;
    tr > td:first-child {
        width: 45px;
    }
    tr {
        cursor: move;
    }
    tr:first-child:hover {
        cursor: context-menu;
        background-color: #fff;
    }
}
</style>
