<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>coffee</md-icon>
                        </div>
                        <div class="custom-toolbar">
                            <div class="custom-toolbar-start">
                                <h3 class="title">Break Management</h3>
                            </div>
                            <div class="custom-toolbar-end">
                                <div class="header-button-container">
                                    <md-button
                                        class="md-round md-just-icon md-primary"
                                        title="Add Break Schedule"
                                        @click="setAddBreakState(true)"
                                    >
                                        <md-icon>add</md-icon>
                                    </md-button>
                                </div>
                            </div>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div>
                            <div v-if="breaksList.length != 0 || addBreakState">
                                <md-table class="context-menu-support custom-paginated-table">
                                    <draggable
                                        v-model="breaksList"
                                        draggable=".drag-item"
                                        class="custom-draggable"
                                        @change="breakScheduleOrderChanged"
                                    >
                                        <md-table-row>
                                            <md-table-head class="order-column"></md-table-head>
                                            <md-table-head>Break Schedule</md-table-head>
                                            <md-table-head>Action</md-table-head>
                                        </md-table-row>
                                        <team-break-row-component
                                            v-for="item in breaksList"
                                            :key="item.breakScheduleId"
                                            :team-break-details="item"
                                            :break-count="breaksList.length"
                                            @editBreakScheduleClick="editBreakSchedule"
                                            @deleteBreakScheduleClick="deleteBreakSchedule"
                                        ></team-break-row-component>

                                        <md-table-row v-if="addBreakState">
                                            <md-table-cell class="order-align">
                                                {{ breaksList.length + 1 }}
                                            </md-table-cell>
                                            <md-table-cell>
                                                Before completing
                                                <input
                                                    type="text"
                                                    class="minutes-textbox"
                                                    v-model="newEntry.drivingTimeMinutes"
                                                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                                                />
                                                minutes of work take a
                                                <input
                                                    type="text"
                                                    class="minutes-textbox"
                                                    v-model="newEntry.breakTimeMinutes"
                                                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                                                />
                                                minute break.
                                            </md-table-cell>
                                            <md-table-cell>
                                                <md-button
                                                    class="md-round md-just-icon md-primary break-btn-icon"
                                                    title="Add Break Schedule"
                                                    @click="createBreakSchedule"
                                                >
                                                    <md-icon>check</md-icon>
                                                </md-button>
                                                <md-button
                                                    class="md-round md-just-icon md-danger break-btn-icon"
                                                    title="Cancel"
                                                    @click="setAddBreakState(false)"
                                                >
                                                    <md-icon>cancel</md-icon>
                                                </md-button>
                                            </md-table-cell>
                                        </md-table-row>
                                    </draggable>
                                </md-table>
                            </div>
                            <div v-if="breaksList.length == 0 && !addBreakState">
                                <p class="no-result-message">No breaks configured; breaks disabled for team</p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import { TeamBreakRowComponent } from './components';

export default {
    name: 'BreakManagementOverview',
    components: {
        TeamBreakRowComponent
    },
    mixins: [GeneralMixin],
    data() {
        return {
            breaksList: [],
            topScrollPosition: 0,
            newEntry: {},
            addBreakState: false
        };
    },
    async mounted() {
        this.$_handleLoaderState(true);
        await this.retrieveBreakList();
        this.$_handleLoaderState(false);
    },
    watch: {},
    computed: {
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {
        async fetchbreaksList(teamId) {
            this.setAddBreakState(false);
            const endpoint = `/api/breaks/list`;
            const response = await handleRequests(endpoint, {
                params: {
                    teamId
                }
            });
            return response;
        },
        assignBreaks(breaks) {
            this.breaksList = breaks;
        },
        setAddBreakState(state) {
            this.addBreakState = state;
            if (this.addBreakState) {
                this.newEntry = {};
            }
        },
        async createBreakSchedule() {
            if (this.addBreakState) {
                if (this.validateBreakSchedule()) {
                    this.$_handleLoaderState(true);
                    this.newEntry.teamId = this.user.teamId;
                    const api = `/api/breaks/create-break-schedule`;
                    const payload = {
                        method: 'post',
                        data: this.newEntry
                    };
                    try {
                        await handleRequests(api, payload);
                        await this.retrieveBreakList();
                        this.$notify({
                            message: 'Successfully created break schedule.',
                            type: 'success'
                        });
                        this.newEntry = {};
                        this.addBreakState = false;
                    } catch (e) {
                        this.$notify({
                            message: 'Could not create break schedule.',
                            type: 'danger'
                        });
                    }
                    this.$_handleLoaderState(false);
                } else {
                    this.$notify({
                        message: 'Minutes should not be empty or zero.',
                        type: 'danger'
                    });
                }
            } else {
                this.addBreakState = true;
            }
        },
        async editBreakSchedule(obj) {
            this.$_handleLoaderState(true);
            const api = `/api/breaks/update-break-schedule`;
            const payload = {
                method: 'post',
                data: obj
            };
            try {
                await handleRequests(api, payload);
                await this.retrieveBreakList();
                this.$notify({
                    message: 'Successfully updated break schedule.',
                    type: 'success'
                });
            } catch (e) {
                this.$notify({
                    message: 'Could not update break schedule.',
                    type: 'danger'
                });
            }
            this.$_handleLoaderState(false);
        },
        async deleteBreakSchedule(id) {
            this.$_handleLoaderState(true);
            const api = `/api/breaks/delete-break-schedule`;
            const payload = {
                method: 'post',
                params: {
                    breakScheduleId: id
                }
            };
            try {
                await handleRequests(api, payload);
                await this.retrieveBreakList();
                this.$notify({
                    message: 'Successfully deleted break schedule.',
                    type: 'success'
                });
            } catch (e) {
                this.$notify({
                    message: 'Could not delete break schedule.',
                    type: 'danger'
                });
            }
            this.$_handleLoaderState(false);
        },
        async retrieveBreakList() {
            const {
                data: { breaks }
            } = await this.fetchbreaksList(this.user.teamId);
            this.assignBreaks(breaks);
        },
        async changeOrderBreakSchedule(id, newIndex) {
            this.$_handleLoaderState(true);
            const api = `/api/breaks/change-order-break-schedule`;
            const payload = {
                method: 'post',
                params: {
                    breakScheduleId: id,
                    newIndex
                }
            };
            try {
                await handleRequests(api, payload);
                await this.retrieveBreakList();
                this.$notify({
                    message: 'Successfully updated break schedule.',
                    type: 'success'
                });
            } catch (e) {
                this.$notify({
                    message: 'Could not update break schedule.',
                    type: 'danger'
                });
            }
            this.$_handleLoaderState(false);
        },
        validateBreakSchedule() {
            if (this.newEntry === null || this.newEntry === undefined) {
                return false;
            }

            return (
                this.isPropertyValid(this.newEntry.drivingTimeMinutes) &&
                this.isPropertyValid(this.newEntry.breakTimeMinutes)
            );
        },
        breakScheduleOrderChanged(event) {
            this.changeOrderBreakSchedule(this.breaksList[event.moved.oldIndex].breakScheduleId, event.moved.newIndex);
        },
        isPropertyValid(property) {
            if (property === null || property === undefined || property === '' || property <= 0) 
                return false;
            return true;
        }
    }
};
</script>

<style lang="scss" scoped>
.order-column {
    width: 100px;
}
.custom-draggable {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.drag-item {
    cursor: grab;
}

.order-align {
    text-align: center;
}

.break-btn-icon {
    margin-left: 3px;
    margin-right: 3px;
}

.body-list {
    padding-top: 0px;
}

.custom-toolbar-end {
    padding-top: 10px;
    padding-right: 10px;
}

.custom-toolbar {
    margin-top: 0px;
}

.minutes-textbox {
    width: 30px;
}

.content {
    margin-top: -15px;

    ::v-deep .md-card.md-theme-default {
        margin-bottom: 0;
    }
}

.body-list {
    position: relative;
}
</style>
