var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-steps--container" },
    [
      (_vm.canViewTeamRegion &&
        _vm.teamRegions &&
        _vm.teamRegions.length > 0) ||
      _vm.isCustomerAdmin
        ? _c("team-region-member-filter-options", {
            staticClass: "team-region-container",
            attrs: {
              "team-region-id": _vm.teamRegionId,
              "team-member-public-userid": _vm.teamMemberFilterValue,
              "show-team-member": true,
              clearable: false,
              "use-in-map": true,
            },
            on: { changed: _vm.handleTeamRegionChanged },
          })
        : _vm._e(),
      _c(
        "md-field",
        { staticClass: "datepicker-container" },
        [
          _c("md-datepicker", {
            ref: "mdDatePicker",
            staticClass: "filter-steps--choices filter-step-date",
            attrs: {
              "data-testid": "trip-date",
              "md-immediately": "",
              "md-debounce": 100,
              clearable: false,
            },
            model: {
              value: _vm.tripDateFilterValue,
              callback: function ($$v) {
                _vm.tripDateFilterValue = $$v
              },
              expression: "tripDateFilterValue",
            },
          }),
        ],
        1
      ),
      _c(
        "md-field",
        [
          _vm.statuses
            ? _c("vue-select", {
                attrs: {
                  "data-testid": "trip-status",
                  options: _vm.statuses,
                  clearable: false,
                  placeholder: "Status",
                },
                model: {
                  value: _vm.statusFilterValue,
                  callback: function ($$v) {
                    _vm.statusFilterValue = $$v
                  },
                  expression: "statusFilterValue",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.hasCustomerFilterAccess
        ? _c("CustomerOptions", {
            attrs: {
              selected: _vm.customerId,
              "team-region-id": _vm.teamRegionId,
            },
            on: { selectedOption: _vm.handleCustomerChanged },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }