<template>
    <div class="modal-container">
        <div>
            <div class="modal-header">
                <h4 class="modal-title">Update Order</h4>
                <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                    <md-icon>clear</md-icon>
                </md-button>
            </div>
            <div class="modal-body">
                <form-wrapper v-if="!isLoading" :validator="$v.orderDetails" class="form-wrapper">
                    <form-group name="name" label="Contact Name">
                        <md-input v-model="orderDetails.contact.name" />
                    </form-group>
                    <form-group name="phone" label="Phone">
                        <md-input v-model="orderDetails.contact.phone" />
                    </form-group>
                    <form-group name="email" label="Email">
                        <md-input v-model="orderDetails.contact.email" />
                    </form-group>
                </form-wrapper>
                <div v-else>
                    <div class="loader-container">
                        <fade-loader :loading="true" color="#333333" />
                        <span>LOADING</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <md-button v-if="!isLoading" class="dialog-button md-primary" @click.stop="updateOrder">
                    Update
                </md-button>
                <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
            </div>
        </div>
    </div>
</template>

<script>
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'UpdateOrderModal',
    mixins: [GeneralMixin],
    components: {
        FadeLoader
    },
    props: {
        orderId: {
            type: Number,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            orderDetails: {
                contact: {
                    name: null,
                    phone: null,
                    email: null
                }
            },
            isFormTouched: false,
            isLoading: true
        };
    },
    async mounted() {
        await this.getOrderDetails(this.orderId);
    },
    methods: {
        /**
         *
         * @param {import("@/jsDocTypes/RateRule").RateRule} rateRule
         */
        handleRateTypeChanged(rateRule) {
            this.rateGroupId = rateRule?.rateGroupId ?? null;
        },
        handleCustomerChanged(customerId) {
            this.customerId = !customerId ? 0 : customerId;
        },
        async getOrderDetails(orderId) {
            const api = `/api/orders/${orderId}`;

            try {
                const data = await handleRequests(api);
                this.isLoading = false;

                const { contact } = data.data;

                this.orderDetails = {
                    contact: {
                        name: contact?.name ?? null,
                        phone: contact?.phone ?? null,
                        email: contact?.email ?? null
                    }
                };
            } catch (e) {
                const message = 'Could not retrieve order details. Please try again later.';
                showErrorMessage(this, message, e);
                this.resolve(false);
            }
        },
        async updateOrder() {
            const data = {
                ...this.orderDetails
            };

            this.$_handleLoaderState(true, 'UPDATING...');
            const api = `/api/orders/${this.orderId}`;
            const payload = {
                method: 'put',
                data
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully updated the trip.',
                    type: 'success'
                });
                this.$v.$reset();
                this.resolve({ code: 'ok', data: this.orderDetails });
            } catch (e) {
                const message = 'Could not update order.';
                showErrorMessage(this, message, e);
                this.resolve('error');
            }
            this.$_handleLoaderState(false);
        }
    },
    validations: {
        orderDetails: {}
    }
};
</script>

<style lang="scss" scoped>
.modal-body {
    min-height: 200px;
}
</style>
