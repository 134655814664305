var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-overlay" },
    [
      _c(
        "div",
        { staticClass: "trip-container", attrs: { id: "toggleRoute" } },
        [
          _c(
            "div",
            [
              _c(
                "md-button",
                {
                  staticClass:
                    "md-round md-just-icon md-transparent toggle-icon",
                  on: {
                    click: function ($event) {
                      return _vm.toggleRouteWindow()
                    },
                  },
                },
                [
                  _c(
                    "md-icon",
                    {
                      staticClass: "show-icon",
                      attrs: { id: "routeOpenButton" },
                    },
                    [
                      _vm._v(
                        "\n                    more_vert\n                "
                      ),
                    ]
                  ),
                  _c(
                    "md-icon",
                    {
                      staticClass: "hide-icon",
                      attrs: { id: "routeCloseButton" },
                    },
                    [
                      _vm._v(
                        "\n                    view_list\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "route-container" }, [
            _c("h3", { staticClass: "summary-title" }, [_vm._v("Summary")]),
            _c(
              "div",
              {
                staticClass: "table-wrapper",
                attrs: { id: "routeTableContainer" },
              },
              [
                _c(
                  "md-table",
                  { staticClass: "trip-table table-container" },
                  [
                    _c(
                      "md-table-row",
                      [
                        _c("md-table-head", [_vm._v("Driver")]),
                        _c("md-table-head", [_vm._v("Stops")]),
                        _c("md-table-head", { staticClass: "pin-all" }, [
                          _c(
                            "div",
                            {
                              class: { active: _vm.allStopsPinned },
                              on: { click: _vm.handlePinAll },
                            },
                            [
                              _c("md-icon", { staticClass: "route-pin" }, [
                                _vm._v(
                                  "\n                                    push_pin\n                                "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._l(_vm.trips, function (trip, index) {
                      return _c(
                        "md-table-row",
                        { key: index },
                        [
                          _c("md-table-cell", { staticClass: "allocatestop" }, [
                            _c("span", { style: { background: trip.color } }, [
                              _vm._v(" "),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(trip.driverName) +
                                "\n                        "
                            ),
                          ]),
                          _c(
                            "md-table-cell",
                            { staticClass: "allocatestop-number" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(trip.stops.length) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _c(
                            "md-table-cell",
                            { staticClass: "allocatestop-pin" },
                            [
                              trip.stops.length > 0
                                ? _c(
                                    "a",
                                    {
                                      class: { active: trip.isPinned },
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickedRouteLine(
                                            trip
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "md-icon",
                                        { staticClass: "route-pin" },
                                        [
                                          _vm._v(
                                            "\n                                    push_pin\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "trip-footer" }),
        ]
      ),
      _c("div", {
        class: _vm.isTrialExpired ? "not-paid-map-overlay" : "trip-map",
        attrs: { id: "map_canvas" },
      }),
      _c("info-window-component", {
        attrs: {
          content: _vm.infoWindowContent,
          styles: _vm.infoWindowStyles,
          "show-info-window": _vm.showInfoWindow,
        },
        on: {
          closeInfoWindow: function () {
            this$1.showInfoWindow = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }