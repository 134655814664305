var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("div", { staticClass: "modal-container" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("h4", { staticClass: "modal-title" }, [
              _vm._v("Update Shipment"),
            ]),
            _c(
              "md-button",
              {
                staticClass:
                  "md-simple md-just-icon md-round modal-default-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$modal.hide($event)
                  },
                },
              },
              [_c("md-icon", [_vm._v("clear")])],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "md-button",
              {
                staticClass: "md-success chargeButton-toggle",
                on: {
                  click: function ($event) {
                    return _vm.openCharges()
                  },
                },
              },
              [
                _vm._v(
                  "\n            Charges (" +
                    _vm._s(_vm.chargeList.length) +
                    ")\n        "
                ),
              ]
            ),
            _c("charge-list-modal", {
              attrs: {
                "rates-list": _vm.chargeList,
                "rates-is-loading": _vm.ratesIsLoading,
                "rate-list-data": _vm.rateListData,
                currency: _vm.shipmentDetails.currency,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "modal-body shipment-form-modal" },
          [
            _c(
              "form-wrapper",
              {
                staticClass: "form-wrapper",
                attrs: { validator: _vm.$v.shipmentDetails },
              },
              [
                _c(
                  "tabs",
                  {
                    staticClass: "custom-tab-list",
                    attrs: {
                      "tab-name": [
                        "Shipment",
                        "Pickup Options",
                        "Drop off Options",
                      ],
                      "color-button": "warning",
                    },
                  },
                  [
                    _c("template", { slot: "tab-pane-1" }, [
                      _c(
                        "div",
                        { staticClass: "shipment-form-container" },
                        [
                          _c("charging-type-options", {
                            attrs: {
                              "selected-charge-type-id":
                                _vm.shipmentDetails.rateGroupId,
                            },
                            on: { selectedOption: _vm.handleRateTypeChanged },
                          }),
                          _c("team-region-member-controls", {
                            attrs: {
                              "team-region-id":
                                _vm.shipmentDetails.teamRegionId,
                              "public-user-id":
                                _vm.shipmentDetails.assignedTo.publicUserId,
                              "carrier-team-id":
                                _vm.shipmentDetails.carrierTeamId,
                              "show-label-if-member-not-in-team-region": true,
                              mode: "update",
                              "strict-team-region-filter": false,
                            },
                            on: {
                              onChanged: _vm.handleTeamRegionMemberChanged,
                            },
                          }),
                          _c(
                            "div",
                            [
                              _c(
                                "form-group",
                                {
                                  attrs: {
                                    name: "sourceReference",
                                    label: "Source Reference",
                                  },
                                },
                                [
                                  _c("md-input", {
                                    model: {
                                      value:
                                        _vm.shipmentDetails.sourceReference,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.shipmentDetails,
                                          "sourceReference",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "shipmentDetails.sourceReference",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("brand-filter", {
                                model: {
                                  value: _vm.shipmentDetails,
                                  callback: function ($$v) {
                                    _vm.shipmentDetails = $$v
                                  },
                                  expression: "shipmentDetails",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "stop-container" }, [
                            _c(
                              "div",
                              [
                                _c("google-autocomplete", {
                                  attrs: {
                                    label: "Pickup",
                                    id: "pickupAutoaddress",
                                    classname:
                                      "form-control autocomplete-input",
                                    placeholder: "",
                                    "should-focus": false,
                                    "disable-gps-coordinates": false,
                                    name: "pickupStop.address",
                                  },
                                  on: {
                                    handleCoordinates:
                                      _vm.handlePickupCoordinates,
                                    change: _vm.handleShipmentDetailsChanged,
                                  },
                                  model: {
                                    value: _vm.shipmentDetails.pickupStop,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.shipmentDetails,
                                        "pickupStop",
                                        $$v
                                      )
                                    },
                                    expression: "shipmentDetails.pickupStop",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "stop-container" }, [
                            _c(
                              "div",
                              [
                                _c("google-autocomplete", {
                                  attrs: {
                                    label: "Drop off",
                                    id: "dropStopAutoaddress",
                                    classname:
                                      "form-control autocomplete-input",
                                    placeholder: "",
                                    "should-focus": false,
                                    "disable-gps-coordinates": false,
                                    name: "dropStop.address",
                                  },
                                  on: {
                                    handleCoordinates:
                                      _vm.handleDropCoordinates,
                                    change: _vm.handleShipmentDetailsChanged,
                                  },
                                  model: {
                                    value: _vm.shipmentDetails.dropStop,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.shipmentDetails,
                                        "dropStop",
                                        $$v
                                      )
                                    },
                                    expression: "shipmentDetails.dropStop",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "contact-details" }, [
                            _c("span", [_vm._v("Contact Details")]),
                            _c(
                              "div",
                              { staticClass: "contact-details" },
                              [
                                _c("customer-autocomplete", {
                                  attrs: {
                                    label:
                                      _vm.shipmentDetails.customerId == null
                                        ? "Name"
                                        : "Customer",
                                    id: "autocustomerShipment",
                                    placeholder: "",
                                    "should-focus": false,
                                    "update-mode": true,
                                  },
                                  on: {
                                    change: _vm.handleCustomerFieldChange,
                                    changeCustomerRateGroup:
                                      _vm.handleCustomerChangeRateGroup,
                                  },
                                  model: {
                                    value: _vm.shipmentDetails,
                                    callback: function ($$v) {
                                      _vm.shipmentDetails = $$v
                                    },
                                    expression: "shipmentDetails",
                                  },
                                }),
                                _vm.shipmentDetails.customerId != null
                                  ? _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "name",
                                          label: "Contact Name",
                                        },
                                      },
                                      [
                                        _c("md-input", {
                                          model: {
                                            value:
                                              _vm.shipmentDetails.contact.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.shipmentDetails.contact,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "shipmentDetails.contact.name",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "form-group",
                                  { attrs: { name: "phone", label: "Phone" } },
                                  [
                                    _c("md-input", {
                                      staticClass: "shipment-contact",
                                      model: {
                                        value:
                                          _vm.shipmentDetails.contact.phone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.shipmentDetails.contact,
                                            "phone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "shipmentDetails.contact.phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "form-group",
                                  { attrs: { name: "email", label: "Email" } },
                                  [
                                    _c("md-input", {
                                      staticClass: "shipment-contact",
                                      model: {
                                        value:
                                          _vm.shipmentDetails.contact.email,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.shipmentDetails.contact,
                                            "email",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "shipmentDetails.contact.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm.skillOptions.length
                            ? _c(
                                "form-group",
                                { attrs: { name: "skills", label: "Skills" } },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      "data-testid": "skills-option",
                                      options: _vm.skillOptions,
                                      multiple: true,
                                      "close-on-select": true,
                                      placeholder: "Pick skills",
                                    },
                                    model: {
                                      value: _vm.shipmentDetails.skills,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.shipmentDetails,
                                          "skills",
                                          $$v
                                        )
                                      },
                                      expression: "shipmentDetails.skills",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "form-group",
                            { attrs: { name: "notes", label: "Notes" } },
                            [
                              _c("md-textarea", {
                                model: {
                                  value: _vm.shipmentDetails.notes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.shipmentDetails, "notes", $$v)
                                  },
                                  expression: "shipmentDetails.notes",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.shipmentCustomFieldList !== null
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.shipmentCustomFieldList,
                                  function (customField, index) {
                                    return _c("custom-field-inputs", {
                                      key: index,
                                      attrs: {
                                        "custom-field-definition": customField,
                                        "initial-value":
                                          _vm.customFieldValues[
                                            customField.name
                                          ],
                                      },
                                      on: {
                                        changed: _vm.handleCustomFieldChanged,
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _vm.loadFieldList !== null
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.loadFieldList,
                                  function (load, index) {
                                    return _c("load-inputs", {
                                      key: index,
                                      attrs: {
                                        "load-definition": load,
                                        "initial-value": _vm.loadValues,
                                      },
                                      on: { changed: _vm.handleLoadChanged },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            [
                              _c(
                                "form-group",
                                {
                                  attrs: {
                                    name: "runnumber",
                                    label: "Run (Optional)",
                                  },
                                },
                                [
                                  _c("md-input", {
                                    attrs: { type: "number", min: "1" },
                                    model: {
                                      value: _vm.shipmentDetails.runNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.shipmentDetails,
                                          "runNumber",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "shipmentDetails.runNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "tripdate" },
                            [
                              _c(
                                "md-checkbox",
                                {
                                  attrs: {
                                    "data-testid": "trip-date-checkbox",
                                    disabled: _vm.tripDateRequired,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleIsSetTripDateChange(
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.isSetTripDate,
                                    callback: function ($$v) {
                                      _vm.isSetTripDate = $$v
                                    },
                                    expression: "isSetTripDate",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Set trip date for this shipment?\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.isSetTripDate
                            ? _c("div", { ref: "bottomElement" }, [
                                _c(
                                  "div",
                                  { staticClass: "stop-container" },
                                  [
                                    _c(
                                      "md-datepicker",
                                      {
                                        attrs: {
                                          "md-immediately": "",
                                          "md-disabled-dates":
                                            _vm.disabledDates,
                                          "md-debounce": 10,
                                        },
                                        model: {
                                          value:
                                            _vm.shipmentDetails.pickupStop
                                              .tripDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.shipmentDetails.pickupStop,
                                              "tripDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "shipmentDetails.pickupStop.tripDate",
                                        },
                                      },
                                      [_c("label", [_vm._v("Trip Date")])]
                                    ),
                                    _vm.isSetTripDate &&
                                    _vm.tripDateRequired &&
                                    _vm.shipmentDetails.pickupStop.tripDate ==
                                      null
                                      ? _c(
                                          "span",
                                          { staticClass: "md-error" },
                                          [
                                            _vm._v(
                                              "\n                                    Trip date is required.\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("template", { slot: "tab-pane-2" }, [
                      _c(
                        "div",
                        { staticClass: "contact-details" },
                        [
                          _c("span", [_vm._v("Pickup Details")]),
                          _c("customer-autocomplete", {
                            attrs: {
                              label:
                                _vm.shipmentDetails.pickupStop.customerId ==
                                null
                                  ? "Name"
                                  : "Customer",
                              id: "autocustomerShipmentPickup",
                              placeholder: "",
                              "should-focus": false,
                              "update-mode": true,
                            },
                            model: {
                              value: _vm.shipmentDetails.pickupStop,
                              callback: function ($$v) {
                                _vm.$set(_vm.shipmentDetails, "pickupStop", $$v)
                              },
                              expression: "shipmentDetails.pickupStop",
                            },
                          }),
                          _vm.shipmentDetails.pickupStop.customerId != null
                            ? _c(
                                "form-group",
                                {
                                  attrs: {
                                    name: "name",
                                    label: "Contact Name",
                                  },
                                },
                                [
                                  _c("md-input", {
                                    model: {
                                      value:
                                        _vm.shipmentDetails.pickupStop.contact
                                          .name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.shipmentDetails.pickupStop
                                            .contact,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "shipmentDetails.pickupStop.contact.name",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "form-group",
                            { attrs: { name: "phone", label: "Phone" } },
                            [
                              _c("md-input", {
                                model: {
                                  value:
                                    _vm.shipmentDetails.pickupStop.contact
                                      .phone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.shipmentDetails.pickupStop.contact,
                                      "phone",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "shipmentDetails.pickupStop.contact.phone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "form-group",
                            { attrs: { name: "email", label: "Email" } },
                            [
                              _c("md-input", {
                                model: {
                                  value:
                                    _vm.shipmentDetails.pickupStop.contact
                                      .email,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.shipmentDetails.pickupStop.contact,
                                      "email",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "shipmentDetails.pickupStop.contact.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "form-group",
                            { attrs: { name: "notes", label: "Notes" } },
                            [
                              _c("md-textarea", {
                                model: {
                                  value: _vm.shipmentDetails.pickupStop.notes,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.shipmentDetails.pickupStop,
                                      "notes",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "shipmentDetails.pickupStop.notes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isSetTripDate
                        ? _c("div", [
                            _c("div", { staticClass: "stop-container" }, [
                              _c(
                                "div",
                                { staticClass: "trip-settings" },
                                [
                                  _c(
                                    "form-group",
                                    {
                                      staticClass: "time-picker",
                                      attrs: { name: "time", label: "Time" },
                                    },
                                    [
                                      _c("time-picker", {
                                        attrs: {
                                          time: _vm.pickupTime,
                                          "additional-time-options":
                                            _vm.additionalPickupTimeOption,
                                        },
                                        on: { selectedTime: _vm.getPickupTime },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "trip-settings duration-setting",
                                },
                                [
                                  _c(
                                    "form-group",
                                    {
                                      attrs: {
                                        name: "pickupStop.durationMinutes",
                                        label: "Duration (mins)",
                                      },
                                    },
                                    [
                                      _c("md-input", {
                                        attrs: { type: "number" },
                                        model: {
                                          value:
                                            _vm.shipmentDetails.pickupStop
                                              .durationMinutes,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.shipmentDetails.pickupStop,
                                              "durationMinutes",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "shipmentDetails.pickupStop.durationMinutes",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _c("template", { slot: "tab-pane-3" }, [
                      _c(
                        "div",
                        { staticClass: "contact-details" },
                        [
                          _c("span", [_vm._v("Drop off Details")]),
                          _c("customer-autocomplete", {
                            attrs: {
                              label:
                                _vm.shipmentDetails.dropStop.customerId == null
                                  ? "Name"
                                  : "Customer",
                              id: "autocustomerShipmentDrop",
                              placeholder: "",
                              "should-focus": false,
                              "update-mode": true,
                            },
                            model: {
                              value: _vm.shipmentDetails.dropStop,
                              callback: function ($$v) {
                                _vm.$set(_vm.shipmentDetails, "dropStop", $$v)
                              },
                              expression: "shipmentDetails.dropStop",
                            },
                          }),
                          _vm.shipmentDetails.dropStop.customerId != null
                            ? _c(
                                "form-group",
                                {
                                  attrs: {
                                    name: "name",
                                    label: "Contact Name",
                                  },
                                },
                                [
                                  _c("md-input", {
                                    model: {
                                      value:
                                        _vm.shipmentDetails.dropStop.contact
                                          .name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.shipmentDetails.dropStop.contact,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "shipmentDetails.dropStop.contact.name",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "form-group",
                            { attrs: { name: "phone", label: "Phone" } },
                            [
                              _c("md-input", {
                                model: {
                                  value:
                                    _vm.shipmentDetails.dropStop.contact.phone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.shipmentDetails.dropStop.contact,
                                      "phone",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "shipmentDetails.dropStop.contact.phone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "form-group",
                            { attrs: { name: "email", label: "Email" } },
                            [
                              _c("md-input", {
                                model: {
                                  value:
                                    _vm.shipmentDetails.dropStop.contact.email,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.shipmentDetails.dropStop.contact,
                                      "email",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "shipmentDetails.dropStop.contact.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "form-group",
                            { attrs: { name: "notes", label: "Notes" } },
                            [
                              _c("md-textarea", {
                                model: {
                                  value: _vm.shipmentDetails.dropStop.notes,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.shipmentDetails.dropStop,
                                      "notes",
                                      $$v
                                    )
                                  },
                                  expression: "shipmentDetails.dropStop.notes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isSetTripDate
                        ? _c("div", { staticClass: "shipment-datepicker" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "md-datepicker",
                                  {
                                    attrs: {
                                      "md-immediately": "",
                                      "md-disabled-dates":
                                        _vm.dropOffDisabledDates,
                                      "md-debounce": 10,
                                    },
                                    model: {
                                      value:
                                        _vm.shipmentDetails.dropStop.tripDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.shipmentDetails.dropStop,
                                          "tripDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "shipmentDetails.dropStop.tripDate",
                                    },
                                  },
                                  [_c("label", [_vm._v("Trip Date")])]
                                ),
                                _vm.isSetTripDate &&
                                _vm.tripDateRequired &&
                                (_vm.shipmentDetails.dropStop.tripDate ==
                                  null ||
                                  _vm.shipmentDetails.dropStop.tripDate == "")
                                  ? _c("span", { staticClass: "error" }, [
                                      _vm._v(
                                        "\n                                Trip date is required.\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "form-group",
                                  {
                                    staticClass: "time-picker",
                                    attrs: { name: "time", label: "Time" },
                                  },
                                  [
                                    _c("time-picker", {
                                      attrs: {
                                        time: _vm.dropTime,
                                        "additional-time-options":
                                          _vm.additionalDropTimeOption,
                                      },
                                      on: { selectedTime: _vm.getDropTime },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "dropStop.durationMinutes",
                                      label: "Duration (mins)",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      attrs: { type: "number" },
                                      model: {
                                        value:
                                          _vm.shipmentDetails.dropStop
                                            .durationMinutes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.shipmentDetails.dropStop,
                                            "durationMinutes",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "shipmentDetails.dropStop.durationMinutes",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                attrs: { disabled: _vm.ratesIsLoading },
                on: { click: _vm.updateShipment },
              },
              [_vm._v("\n            Update\n        ")]
            ),
            _c(
              "md-button",
              {
                staticClass: "dialog-button md-default",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$modal.hide($event)
                  },
                },
              },
              [_vm._v("Cancel")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }