<template>
    <div class="filter-steps--container">
        <CustomerFilterOptionsNew
            class="filter-steps--choices filter-status"
            @onCustomerSelected="handleCustomerFilterChange"
            :filter-customer-id="customerIdFilterValue"
        />
        <ServiceAreaFilterOptions
            class="filter-steps--choices filter-status"
            @onSelectedServiceArea="handleServiceAreaFilterChange"
            :filter-service-area-id="serviceAreaFilterValue"
        />
        <OrderDateFilterOptions @onDateFilterChanged="handleDateFilterChange" />
    </div>
</template>

<script>
import { CustomerFilterOptionsNew, ServiceAreaFilterOptions } from '@/components';
import { mapGetters } from 'vuex';
import OrderDateFilterOptions from '@/pages/Orders/components/OrderDateFilterOptions';

export default {
    name: 'OrderFilterMenu',
    components: { CustomerFilterOptionsNew, ServiceAreaFilterOptions, OrderDateFilterOptions },
    props: {
        customerIdFilterValue: {
            type: Number,
            default: null
        },
        serviceAreaFilterValue: {
            type: Number,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            hasCustomerFilterAccess: 'user/hasInventoryListAccess'
        })
    },
    data() {
        return {
            filter: {
                customerId: null,
                serviceAreaId: null,
                filterType: 'createdDate',
                dateRangeType: 'today',
                fromDate: null,
                toDate: null
            }
        };
    },
    methods: {
        handleCustomerFilterChange(customerId) {
            this.filter.customerId = customerId;
            this.$emit('handleFilterChange', this.filter);
        },
        handleServiceAreaFilterChange(serviceAreaId) {
            this.filter.serviceAreaId = serviceAreaId;
            this.$emit('handleFilterChange', this.filter);
        },
        handleDateFilterChange({ filterType, dateRangeType, fromDate, toDate }) {
            this.filter.filterType = filterType;
            this.filter.dateRangeType = dateRangeType;
            this.filter.fromDate = fromDate;
            this.filter.toDate = toDate;
            this.$emit('handleFilterChange', this.filter);
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-steps--container {
    flex-grow: 2;
    ::v-deep .md-field {
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }

    ::v-deep > .md-field {
        display: inline-block;
        width: 180px;
    }

    ::v-deep .vs__selected {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 116px;
        display: block;
    }
}
</style>
