<template>
    <md-field v-if="serviceAreaList.length > 0">
        <vue-select
            data-testid="service-area-filter"
            :options="serviceAreaList"
            :reduce="(x) => x.serviceAreaId"
            label="name"
            placeholder="Service Area"
            v-model="serviceAreaFilterValue"
            :searchable="$root.isDesktop"
        ></vue-select>
    </md-field>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';

export default {
    name: 'ServiceAreaFilterOptions',
    props: {
        filterServiceAreaId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            serviceAreaList: [],
            serviceAreaId: this.filterServiceAreaId,
            name: null,
            serviceAreaFilterValue: null,
            isLoading: false
        };
    },
    computed: {
        ...mapGetters({
            hasCustomerFilterAccess: 'user/hasInventoryListAccess'
        })
    },
    watch: {
        serviceAreaFilterValue(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.serviceAreaId = newValue;
                this.handleFilterValue();
            }
        },
        filterServiceAreaId() {
            this.serviceAreaFilterValue = this.filterServiceAreaId;
        }
    },
    methods: {
        handleFilterValue() {
            this.$emit('onSelectedServiceArea', this.serviceAreaId);

            if (this.serviceAreaList.length > 0) {
                const serviceArea = this.serviceAreaList.find(
                    (serviceArea) => serviceArea.serviceAreaId === this.serviceAreaId
                );

                this.$emit('onServiceAreaSelectedName', serviceArea?.name);
            }
        },
        async getServiceAreaList() {
            let response = {
                data: []
            };

            try {
                this.isLoading = true;
                const endpoint = '/api/teams/service-areas';

                response = await handleRequests(endpoint);
            } catch (error) {
                const message = 'Error in getting the service area list';
                showErrorMessage(this, message, error);
            }

            this.isLoading = false;

            return response.data.map((x) => {
                return {
                    serviceAreaId: x.serviceAreaId,
                    name: x.name
                };
            });
        }
    },
    async mounted() {
        this.serviceAreaList = [...(await this.getServiceAreaList())];

        if (this.filterServiceAreaId) {
            this.serviceAreaFilterValue = this.filterServiceAreaId;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}

::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}

::v-deep .vs__search {
    cursor: pointer;
}

.status-filter {
    width: 180px !important;
}

.state-filter {
    margin-right: 20px;
}

::v-deep .filter-customer .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    display: block;
}
</style>
