<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <filter-component
                            :class="$root.isTablet ? 'tablet-filter-container' : ''"
                            @onFilterStops="handleFilterOrSearch"
                            :team-members="() => this.teamMembers"
                            :time-window-filter-options="timeWindowFilterOptions"
                            :team-region-id="filters.teamRegionId"
                            :filter-value="filters"
                        />
                    </div>
                    <div class="custom-toolbar-end">
                        <search-component
                            v-if="$root.isDesktop"
                            :search-filter="currentSearchTextValue"
                            @onSearch="handleFilterOrSearch"
                        />
                        <div>
                            <md-button
                                title="Refresh List"
                                class="md-primary md-just-icon md-round pull-right header-button"
                                @click="handleRefresh"
                            >
                                <md-icon>refresh</md-icon>
                            </md-button>
                        </div>
                        <create-stop
                            v-if="!isCustomer && !isSupportStaff"
                            class="tablet-button-margin"
                            :team-members="teamMembers"
                            @stopCreated="handleStopCreated"
                        />
                        <batch-upload
                            :title="'Import Stops'"
                            :template-type="'stops'"
                            v-if="!isReadOnlyUser"
                            :team-members="teamMembers"
                            @batchImported="handleStopCreated"
                        />
                        <div v-if="!isReadOnlyUser">
                            <md-button
                                title="Export Stops"
                                class="md-primary md-just-icon md-round pull-right header-button"
                                @click="exportStops"
                            >
                                <md-icon>get_app</md-icon>
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <div
                    v-if="bulkListError.length > 0"
                    class="alert alert-danger alert-dismissible fade show bulk-error-message"
                >
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="closeAlert">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>
                        <div v-for="e in bulkListError" :key="e.stopRef" class="bulk-single-container">
                            <div>
                                {{ e.stopRef }}
                            </div>
                            <div>
                                <div v-for="(error, i) in e.errors" :key="i">{{ error.message }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
                :class="total < pagination.perPage ? 'margin-space-bot' : ''"
            >
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <CardHeaderDropdown
                            :dropdown-options="headerDropdown"
                            @selectedOption="handleSelectedView"
                            :selected-option="headerDropdown[0]"
                        />
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div
                            :class="['bulk-section', topScrollPosition > 150 ? 'sticky' : '']"
                            v-if="selectedStops.length"
                        >
                            <div>
                                {{ selectedStops.length }} stop{{ selectedStops.length > 1 ? 's' : '' }} selected.
                            </div>
                            <!-- <div>
                                <span
                                    @click="onSelectAll((isSelectAll = false))"
                                    style="cursor:pointer;"
                                    title="Un-select stops"
                                >
                                    CANCEL
                                </span>
                            </div> -->
                            <div>
                                <!-- <md-button class="md-warning" title="Bulk change status" @click="bulkAssign">
                                   Change Status
                                </md-button> -->
                                <drop-down :should-close="shouldCloseDropdown">
                                    <span class="status md-warning" slot="title" data-toggle="dropdown">
                                        Change Status
                                    </span>
                                    <ul class="dropdown-menu">
                                        <li v-for="(item, index) in statuses" :key="index">
                                            <a @click.stop="handleChangeStopStatus(item)">
                                                {{ item }}
                                            </a>
                                        </li>
                                    </ul>
                                </drop-down>
                                <md-button
                                    v-if="!isSingleTeamMember && !isSingleTeamMember && !isCustomer && !isSupportStaff"
                                    class="md-round md-just-icon md-info"
                                    title="Re-assign driver"
                                    @click="bulkAssign"
                                >
                                    <md-icon>person_pin</md-icon>
                                </md-button>
                                <md-button
                                    class="md-round md-just-icon md-danger"
                                    title="Delete Stops"
                                    @click="bulkDelete"
                                >
                                    <md-icon>delete</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div v-if="!isListLoading">
                            <md-table class="context-menu-support">
                                <md-table-row>
                                    <md-table-head v-if="!isCustomer && !isSupportStaff">
                                        <md-checkbox
                                            class="stop-checkbox checkbox-head"
                                            v-model="isSelectAll"
                                            @change="onSelectAll(isSelectAll)"
                                        ></md-checkbox>
                                    </md-table-head>
                                    <TableHeaderDropdown
                                        column-icon="keyboard_arrow_down"
                                        :default-text="tableColumnOptions.columns[columnKeys.referenceSource].name"
                                        :dropdown-options="tableColumnKeyOptions.referenceSource"
                                        :selected-option="tableColumnOptions.columns[columnKeys.referenceSource]"
                                        @selectedOption="
                                            $_handleColumnSwitch(
                                                $event,
                                                columnKeys.referenceSource,
                                                'stops.defaults.stop-table-options'
                                            )
                                        "
                                    />
                                    <md-table-head>Assigned To</md-table-head>
                                    <md-table-head v-if="filters.status === 'Complete' || filters.status === 'All'">
                                        Rating
                                    </md-table-head>
                                    <md-table-head v-if="$root.isDesktop">Type</md-table-head>
                                    <md-table-head>Status</md-table-head>
                                    <md-table-head>When</md-table-head>
                                    <md-table-head v-if="$root.isDesktop">Contact</md-table-head>
                                    <md-table-head class="address-container">Address</md-table-head>
                                    <md-table-head v-if="$root.isDesktop && !isCustomer && !isSupportStaff">
                                        Actions
                                    </md-table-head>
                                </md-table-row>

                                <md-table-row
                                    v-for="item in stopsList"
                                    :key="item.stopId"
                                    @click.stop="showStopDetailsSidebar(item)"
                                >
                                    <md-table-cell v-if="!isCustomer && !isSupportStaff">
                                        <md-checkbox
                                            class="stop-checkbox"
                                            :value="item.stopId"
                                            v-model="selectedStops"
                                        ></md-checkbox>
                                    </md-table-cell>
                                    <md-table-cell class="stop-reference">
                                        <md-tooltip
                                            md-direction="top"
                                            v-if="item.importErrors && item.importErrors.length > 0"
                                        >
                                            <ul>
                                                <li
                                                    v-for="(importError, importErrorIndex) in item.importErrors"
                                                    :key="importErrorIndex"
                                                >
                                                    {{ importError }}
                                                </li>
                                            </ul>
                                        </md-tooltip>
                                        <md-icon
                                            v-if="item.importErrors && item.importErrors.length > 0"
                                            class="icon-warning"
                                        >
                                            warning
                                        </md-icon>
                                        <router-link
                                            :to="{
                                                name: 'Shipment Details',
                                                params: { shipmentId: item.shipmentId }
                                            }"
                                            target="_blank"
                                            v-if="item.shipmentId != null"
                                        >
                                            <md-icon>directions_car</md-icon>
                                        </router-link>
                                        <router-link
                                            :to="{
                                                name: 'Stop Details',
                                                params: { stopId: item.stopId }
                                            }"
                                            target="_blank"
                                        >
                                            {{
                                                tableColumnOptions.columns[columnKeys.referenceSource].key ===
                                                    'reference-number' ||
                                                item.sourceReference === null ||
                                                item.sourceReference === ''
                                                    ? item.stopRef
                                                    : item.sourceReference
                                            }}
                                        </router-link>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <div
                                            v-if="item.assignedTo.publicUserId || item.carrierTeamId"
                                            class="assigned-column"
                                        >
                                            <div>
                                                <reassign-stop-button
                                                    type="text"
                                                    :item="item"
                                                    :team-members="teamMembers"
                                                    @assignedUser="onAssignedUser"
                                                />
                                            </div>
                                        </div>

                                        <reassign-stop-button
                                            v-else
                                            type="button"
                                            :item="item"
                                            :team-members="teamMembers"
                                            @assignedUser="onAssignedUser"
                                        />
                                    </md-table-cell>
                                    <md-table-cell
                                        class="rating-container"
                                        v-if="filters.status === 'Complete' || filters.status === 'All'"
                                    >
                                        <div v-if="item.rating">
                                            <div class="ratings" v-if="$root.isDesktop">
                                                <md-icon
                                                    v-for="index in 5"
                                                    :key="index"
                                                    :style="{
                                                        color: index <= item.rating ? 'orange' : 'rgba(0,0,0,0.54)'
                                                    }"
                                                >
                                                    star_rate
                                                </md-icon>
                                            </div>
                                            <div class="ratings" v-else>
                                                <span>{{ item.rating }}</span>
                                                &nbsp;
                                                <md-icon>star_rate</md-icon>
                                            </div>
                                        </div>
                                    </md-table-cell>
                                    <md-table-cell v-if="$root.isDesktop">
                                        {{ item.type }}
                                    </md-table-cell>
                                    <md-table-cell class="status-container">
                                        <stop-status-button :stop="item" @statusUpdated="onChangedStopStatus" />
                                    </md-table-cell>
                                    <md-table-cell class="trip-date">
                                        <router-link
                                            v-if="item.tripId && item.carrierTeamid == null"
                                            :to="{
                                                name: 'Trip Details',
                                                params: {
                                                    tripId: item.tripId
                                                }
                                            }"
                                            target="_blank"
                                        >
                                            {{ item.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                        </router-link>
                                        <div v-else>
                                            {{ item.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                        </div>
                                    </md-table-cell>
                                    <md-table-cell v-if="$root.isDesktop">
                                        {{ item.contact.name }}
                                    </md-table-cell>
                                    <md-table-cell class="address-container">
                                        <div class="custom-ellipsis">
                                            <GeocodingIndicator :address-components="item.addressComponents" />
                                            {{ $_displayAddressName(item) }}
                                        </div>
                                    </md-table-cell>
                                    <md-table-cell v-if="$root.isDesktop" class="action-buttons">
                                        <update-stop-button
                                            v-if="!isCustomer && !isSupportStaff"
                                            :stop="item.stopId"
                                            :team-members="teamMembers"
                                            @stopUpdated="handleUpdateStop"
                                            :is-failed="item.status.toLowerCase() === 'failed'"
                                            :carrier-team-id="item.carrierTeamId"
                                        />
                                        <reassign-stop-button
                                            v-if="
                                                !isSingleUser && !isSingleTeamMember && !isCustomer && !isSupportStaff
                                            "
                                            type="icon"
                                            :item="item"
                                            :team-members="teamMembers"
                                            @assignedUser="onAssignedUser"
                                        />
                                        <clone-job
                                            v-if="!item.shipmentId && canCloneJobs"
                                            :stop-id="item.stopId"
                                            :stop-details="item"
                                            :use-icon="true"
                                        />
                                        <delete-stop-button
                                            v-if="!isReadOnlyUser"
                                            :stop-id="item.stopId"
                                            :team-members="teamMembers"
                                            @stopDeleted="handleDeleteStop"
                                            :is-shipment-stop="item.shipmentId != null"
                                        />
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <div v-if="stopsList.length == 0 && !isLoading">
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="stopbar-loader">
                                <fade-loader :loading="loading" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <md-card-actions class="page-footer" md-alignment="space-between">
            <div>
                <p v-if="total === pagination.perPage" class="card-category">
                    Page {{ pagination.currentPage }} of many
                </p>
                <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
            </div>
            <pagination
                v-model="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :enable-first-page-link="true"
                :enable-last-page-link="true"
                @change-page="handleChangePage($event, pagination.perPage)"
            />
        </md-card-actions>
        <transition name="slide">
            <stop-sidebar
                v-if="showStopDetails"
                :stop-id="stopId"
                :data="stopDetails"
                :team-members="teamMembers"
                v-click-outside="toggleStopDetailsWindow"
                @assignStop="onAssignedUser"
                @deleteStop="handleDeleteStop"
                @updateStop="handleUpdateStop"
                @changeStopStatus="onChangedStopStatus"
                @showAddNotes="handleAddStopNotes"
                @closeModal="toggleStopDetailsWindow"
            />
        </transition>
    </div>
</template>

<script>
import { PAGINATION_DEFAULTS, STOP_TABLE_HEADER_DISPLAY_OPTION } from '@/utils/defaults';
import {
    Pagination,
    SearchComponent,
    BatchUpload,
    GeocodingIndicator,
    CardHeaderDropdown,
    TableHeaderDropdown,
    CloneJob
} from '@/components';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { mapGetters } from 'vuex';
import {
    STATUS_CONSTANTS,
    DATE_TYPES,
    STOP_HEADER_DROPDOWN_CONSTANTS,
    STOP_HEADER_DROPDOWN_READONLY_CONSTANTS,
    REFERENCE_SOURCE_KEY_OPTIONS
} from '@/utils/constants';
import moment from 'moment';
import StopSidebar from './StopSidebar';

import { FilterComponent, CreateStop, AssignStopModal } from './components';

import { UpdateStopButton, DeleteStopButton, ReassignStopButton, StopStatusButton } from './buttons';

const signalR = require('@aspnet/signalr');

export default {
    name: 'StopList',
    components: {
        StopSidebar,
        Pagination,
        FilterComponent,
        SearchComponent,
        CreateStop,
        FadeLoader,
        BatchUpload,
        UpdateStopButton,
        DeleteStopButton,
        ReassignStopButton,
        StopStatusButton,
        GeocodingIndicator,
        CardHeaderDropdown,
        TableHeaderDropdown,
        CloneJob
    },
    mixins: [GeneralMixin],
    async created() {
        if (Object.keys(this.$route.query).length > 0) {
            this.filters = this.handleQueryParameter(this.$route.query);

            if (this.filters.searchText) {
                this.currentSearchTextValue = this.filters.searchText;
            }

            if (this.filters.teamRegionId) {
                this.filters.teamRegionId = Number(this.filters.teamRegionId);
            }
        }

        this.$_applyColumnSwitchDefaults('stops.defaults.stop-table-options', {
            [this.columnKeys.referenceSource]: REFERENCE_SOURCE_KEY_OPTIONS
        });
    },
    async mounted() {
        this.$_handleLoaderState(true);
        const { stops, totalStops } = await this.handleFetchingOfStopsOnLoad();
        this.setTimeWindowFilter(stops);
        this.assignStops(stops, totalStops || stops.length);
        this.getTeamMembers();
        this.$_handleLoaderState(false);
        this.createHandleScroll();

        if (this.stopsList.length > 0 && !this.isReadOnlyUser) {
            this.setupSignalR();
        }

        this.headerDropdown = this.isSupportStaff
            ? STOP_HEADER_DROPDOWN_READONLY_CONSTANTS
            : STOP_HEADER_DROPDOWN_CONSTANTS;
    },
    beforeDestroy() {
        if (this.stopHubConnection) {
            this.stopHubConnection.invoke('RemoveFromTeamChannel');
        }
        window.removeEventListener('resize', this.onResize);
        document.querySelector('.main-panel').removeEventListener('scroll', this.handleScroll);
    },
    data() {
        return {
            isGeocodingView: false,
            shouldShowAddStopNotesModal: false,
            stopsList: [],
            showStopDetails: false,
            stopDetails: {},
            pagination: PAGINATION_DEFAULTS,
            teamMembers: [],
            selectedTeamMemberId: '',
            stopId: 0,
            status: null,
            maxPage: 1,
            loading: true,
            isListLoading: false,
            filters: {
                status: 'Active',
                teamMemberPublicUserId: '',
                carrierTeamId: null,
                teamRegionId: null,
                timeWindowStart: null,
                timeWindowEnd: null,
                tripDate: '',
                confidenceScore: 'All',
                driverRating: null,
                fromDate: moment()
                    .subtract(7, 'd')
                    .format('YYYY-MM-DD'),
                toDate: moment()
                    .add(7, 'd')
                    .format('YYYY-MM-DD')
            },
            selectedStops: [],
            isSelectAll: false,
            statuses: STATUS_CONSTANTS,
            shouldCloseDropdown: false,
            bulkListError: [],
            topScrollPosition: 0,
            timeWindowFilterOptions: [],
            brandList: [],
            selectedView: null,
            headerDropdown: STOP_HEADER_DROPDOWN_CONSTANTS,
            ...STOP_TABLE_HEADER_DISPLAY_OPTION(),
            currentSearchTextValue: null,
            stopHubConnection: ''
        };
    },
    watch: {
        selectedStops() {
            if (this.selectedStops.length === 0) {
                this.bulkListError = [];
            }
        },
        selectedView(value) {
            this.$emit('selectedView', value);
        }
    },
    methods: {
        handleSelectedView(value) {
            this.$emit('selectedView', value.key);
        },
        handleView(value) {
            this.selectedView = value;
        },
        async handleFetchingOfStopsOnLoad() {
            const currentPage = Number(this.$route.query.currentPage) || 1;

            const {
                data: { stops, totalStops }
            } = await this.fetchStopsList(currentPage, this.pagination.perPage);

            if (currentPage) {
                this.pagination.currentPage = currentPage;
            }

            return { stops, totalStops };
        },
        getMaxPage(page) {
            this.maxPage = page;
        },
        toggleModal(shouldToggle, modalToToggle) {
            this[modalToToggle] = shouldToggle;
        },
        async getTeamMembers(tripDate = null) {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS', {
                date: tripDate
            });
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },

        async fetchStopsList(pageNumber = 1, itemsPerPage = 50) {
            const endpoint = `/api/stops/list`;
            const response = await handleRequests(endpoint, {
                params: {
                    pageNumber,
                    itemsPerPage,
                    ...this.cleanFilters()
                }
            });

            return response;
        },
        showStopDetailsSidebar(item) {
            this.showStopDetails = true;
            this.stopId = item.stopId;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.$_handleLoaderState(true);
            this.showStopDetails = false;
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            const {
                data: { stops, totalStops }
            } = await this.fetchStopsList(currentPage, perPage);
            const stopsToAssign = totalStops || stops.length;
            this.assignStops(stops, stopsToAssign);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            this.$_handleLoaderState(false);
        },
        async handleFilterOrSearch(val) {
            let shouldSearch = false;
            Object.getOwnPropertyNames(val).forEach((q) => {
                shouldSearch = shouldSearch || (val[q] || null) !== (this.filters[q] || null);
            });

            if (!shouldSearch) 
                return; // exit function

            this.pagination.currentPage = 1;
            this.isListLoading = true;
            const filters = { ...this.filters };
            this.filters = Object.assign(this.filters, val);
            try {
                const {
                    data: { stops, totalStops }
                } = await this.fetchStopsList(1, this.pagination.perPage);
                const stopsToAssign = totalStops || stops.length;
                this.assignStops(stops, stopsToAssign);

                const filterQuery = this.handleQueryParameter(this.filters);
                this.$router.replace({ path: this.$route.path, query: filterQuery });
            } catch (e) {
                let message = 'Failed to fetch stops';
                const { data } = e || {};
                const [error] = data;
                if (error.message) {
                    const { message: errorMessage } = error;
                    message = errorMessage;
                }

                showErrorMessage(this, message);
                // reset state to original state
                this.filters = filters;
            }
            this.isListLoading = false;
        },
        handleQueryParameter(obj) {
            const filterKeys = Object.keys(obj);
            const filterQuery = {};
            filterKeys.forEach((filterKey) => {
                if (filterKey !== 'confidenceScore') {
                    if (obj[filterKey]) {
                        if (typeof obj[filterKey] === 'string') {
                            filterQuery[filterKey] = obj[filterKey].toLowerCase();
                        } else {
                            filterQuery[filterKey] = obj[filterKey];
                        }
                    }
                }
            });

            return filterQuery;
        },
        toggleStopDetailsWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
                this.showStopDetails = !this.showStopDetails;
        },
        assignStops(stops, totalStops) {
            this.stopsList = stops;
            this.pagination.total = totalStops;
        },
        handleAddStopNotes(stopId) {
            this.stopId = stopId;
            this.toggleModal(true, 'shouldShowAddStopNotesModal');
        },
        handleDeleteStop(response) {
            this.showStopDetails = false;
            this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        onAssignedUser(response) {
            response.forEach((element) => {
                const { stopId, assignedTo, tripDate, status, teamRegionId, carrierTeamId, carrierTeam } = element;
                const stop = this.stopsList.find((item) => item.stopId === stopId);
                this.$set(stop, 'assignedTo', assignedTo);
                this.$set(this.stopDetails, 'assignedTo', assignedTo);
                this.$set(stop, 'tripDate', tripDate);
                this.$set(this.stopDetails, 'tripDate', tripDate);
                this.$set(stop, 'status', status);
                this.$set(this.stopDetails, 'status', status);
                this.$set(stop, 'teamRegionId', teamRegionId);
                this.$set(this.stopDetails, 'teamRegionId', teamRegionId);
                this.$set(stop, 'carrierTeamId', carrierTeamId);
                this.$set(this.stopDetails, 'carrierTeamId', carrierTeamId);
                this.$set(stop, 'carrierTeam', carrierTeam);
                this.$set(this.stopDetails, 'carrierTeam', carrierTeam);
            });
        },
        onChangedStopStatus(response) {
            const { stopId, status } = response;
            const stop = this.stopsList.find((item) => item.stopId === stopId);
            this.$set(stop, 'status', status);
            this.$set(this.stopDetails, 'status', status);
        },
        handleAddedStopNote() {},
        handleStopCreated() {
            // after creating a new stop, fetch the latest list of stops
            this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        async handleUpdateStop(response) {
            if (response.toLowerCase() === 'ok')
                this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        onSelectAll(value) {
            if (value) {
                this.stopsList.forEach((stop) => {
                    this.selectedStops.push(stop.stopId);
                });
            } else {
                this.selectedStops = [];
            }
        },
        async setupSignalR() {
            const userCrendentials = await window.auth.getUser();

            this.stopHubConnection = new signalR.HubConnectionBuilder()
                .withUrl('/api/StopHub', {
                    accessTokenFactory: () => {
                        return userCrendentials.access_token;
                    }
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            try {
                await this.stopHubConnection.start().then((result) => {
                    this.stopHubConnection.invoke('JoinToTeamChannel');
                });

                this.stopHubConnection.onclose(async () => {
                    await this.setupSignalR();
                });

                this.stopHubConnection.on('AssignmentStatus', async (message) => {
                    if (message && typeof message === 'string' && message.trim().length !== 0) {
                        this.$notifyWarning(message, 7000);
                    }
                });
            } catch (err) {
                setTimeout(this.setupSignalR, 5000);
            }
        },
        bulkDelete() {
            if (!this.validateBulkAction()) {
                return;
            }

            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Stops',
                    body: 'Are you sure you want to delete these stops?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'DELETING...');
                        const payload = {
                            method: 'delete',
                            data: this.selectedStops
                        };
                        const api = `/api/stops/bulk/delete`;
                        try {
                            const response = await handleRequests(api, payload);

                            if (response != null && response.data.errors.length) {
                                this.bulkListError = [];
                                this.bulkListError = response.data.errors;

                                if (this.bulkListError.length) {
                                    this.$notify({
                                        message: `There were errors while deleting stops.`,
                                        type: 'danger'
                                    });
                                }

                                if (response.data.successCount !== 0) {
                                    this.$notify({
                                        message: `${response.data.successCount} stops have been deleted!`,
                                        type: 'success'
                                    });
                                }
                            } else {
                                this.$notify({
                                    message: `${this.selectedStops.length} stops have been deleted!`,
                                    type: 'success'
                                });

                                if (this.isSelectAll) {
                                    this.isSelectAll = false;
                                }

                                this.selectedStops = [];
                                this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
                            }
                        } catch (e) {
                            const message = 'Cannot delete stops.';
                            showErrorMessage(this, message, e);
                        }
                    }
                    this.$_handleLoaderState(false);
                    // create an endpoint to accept list of stop ids
                });
        },
        bulkAssign() {
            if (!this.validateBulkAction()) {
                return;
            }

            const firstTeamRegionId = this.stopsList.find((x) => x.stopId === this.selectedStops[0]).teamRegionId;
            let counter = 0;
            let hasShipmentStops = false;
            this.selectedStops.forEach((x) => {
                const found = this.stopsList.find((y) => y.stopId === x && y.teamRegionId === firstTeamRegionId);
                if (found) {
                    counter += 1;
                    hasShipmentStops = hasShipmentStops || !!found.shipmentId;
                }
            });

            if (this.selectedStops.length !== counter) {
                this.$notify({
                    message:
                        'Cannot perform bulk reassignment. Selected Stops do not belong in the same region or contain Stops that are part of Shipment.',
                    type: 'error',
                    duration: 10 * 1000
                });
                return;
            }

            const inPast = this.stopsInPast(this.selectedStops);

            // show the reassign modal then get the publicuserid of the selected user
            this.$modal
                .show(AssignStopModal, {
                    stopId: this.selectedStops,
                    teamMembers: this.teamMembers,
                    assignedTo: '',
                    stopRef: null,
                    isBulk: true,
                    teamRegionId: firstTeamRegionId,
                    anyStopsInPast: inPast
                })
                .then((response) => {
                    const { errors, name } = response;

                    if (errors.length) {
                        this.bulkListError = [];
                        this.bulkListError = errors;
                    } else {
                        let msg = `${this.selectedStops.length} stops have been assigned to ${name}!`;
                        if (!name) {
                            msg = `${this.selectedStops.length} stops have been un-assigned`;
                        }
                        this.$notify({
                            message: msg,
                            type: 'success'
                        });

                        if (this.isSelectAll) {
                            this.isSelectAll = false;
                        }

                        this.selectedStops = [];
                        this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
                    }
                });
        },
        handleChangeStopStatus(status) {
            if (!this.validateBulkAction()) {
                return;
            }

            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Bulk Change Status',
                    body: `Are you sure you want to change the status of the stops to ${status}?`,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'UPDATING...');

                        const payload = {
                            method: 'post',
                            data: {
                                stops: this.selectedStops,
                                statusChangeInfo: {
                                    newStatus: status
                                }
                            }
                        };
                        try {
                            const api = `/api/stops/bulk/change-status`;
                            const response = await handleRequests(api, payload);
                            if (response.data.length) {
                                this.bulkListError = [];
                                this.bulkListError = response.data;
                            } else {
                                this.$notify({
                                    message: `${
                                        this.selectedStops.length
                                    } stops had their status changed to ${status}!`,
                                    type: 'success'
                                });

                                if (this.isSelectAll) {
                                    this.isSelectAll = false;
                                }

                                this.selectedStops.forEach((x) => {
                                    const stop = this.stopsList.find((item) => item.stopId === x);
                                    this.$set(stop, 'status', status);
                                    if (this.stopDetails) {
                                        this.$set(this.stopDetails, 'status', status);
                                    }
                                });
                                this.selectedStops = [];
                            }
                        } catch (e) {
                            const message = 'Cannot change stop status.';
                            showErrorMessage(this, message, e);
                            this.resolve(false);
                        }
                    }
                    this.shouldCloseDropdown = true;
                    this.$_handleLoaderState(false);
                });

            // to toggle the context menu
            this.$nextTick(() => {
                this.shouldCloseDropdown = false;
            });
        },
        closeAlert() {
            this.bulkListError = [];
        },
        async exportStops() {
            this.$_handleLoaderState(true, 'EXPORTING...');
            try {
                const endpoint = `/api/stops/export`;
                const response = await handleRequests(endpoint, {
                    params: {
                        pageNumber: 1,
                        itemsPerPage: 2000,
                        ...this.cleanFilters()
                    },
                    responseType: 'blob'
                });

                const resData = response.data;
                if (resData.size === 0) {
                    this.$notify({
                        message: 'There are no stops to export',
                        type: 'danger'
                    });
                } else {
                    const url = window.URL.createObjectURL(new Blob([resData]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'stoplist.xlsx');
                    document.body.appendChild(link);
                    link.click();
                }
            } catch (error) {
                const message = 'Error in exporting stops.';
                showErrorMessage(this, message, error);
            }

            this.$_handleLoaderState(false);
        },
        createHandleScroll() {
            document.querySelector('.main-panel').addEventListener('scroll', this.handleScroll, true);
        },
        handleScroll(e) {
            this.topScrollPosition = e.target.scrollTop;
        },
        getTime(value) {
            return moment.parseZone(value).format(DATE_TYPES.standardTime);
        },
        setTimeWindowFilter(stops) {
            const timeWindowStartStops = stops.filter((item) => item.timeWindowStart);
            this.timeWindowFilterOptions = [];
            timeWindowStartStops.forEach((stop) => {
                const val = `${this.getTime(stop.timeWindowStart)} - ${this.getTime(stop.timeWindowEnd)}`;
                const index = this.timeWindowFilterOptions.findIndex((v) => v.value === val);
                if (index === -1) {
                    this.timeWindowFilterOptions.push({
                        name: val,
                        timeWindowStart: moment(stop.timeWindowStart).format(DATE_TYPES.militaryTime),
                        timeWindowEnd: moment(stop.timeWindowEnd).format(DATE_TYPES.militaryTime)
                    });
                }
            });
        },
        handleRefresh() {
            this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        validateBulkAction() {
            let isValid = true;
            for (let i = 0; i < this.selectedStops.length; i++) {
                const stop = this.stopsList.find((x) => x.stopId === this.selectedStops[i]);
                isValid = stop && !stop.carrierTeamId;

                if (!isValid) 
                    break;
            }

            if (!isValid) {
                const message = 'Cannot perform bulk action. One or more selected stops are assigned to a carrier.';
                showErrorMessage(this, message, null);
            }
            return isValid;
        },
        stopsInPast(stops) {
            let inPast = false;

            stops.some((stopId) => {
                const stop = this.stopsList.find((s) => s.stopId === stopId);
                if (stop && stop.tripDate && !inPast) {
                    inPast = moment(stop.tripDate ?? moment(), 'YYYY-MM-DD').isBefore(moment().startOf('day'));
                }

                return false;
            });

            return inPast;
        }
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser',
            isCustomer: 'user/isCustomer',
            isLoading: 'isLoading',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isSupportStaff: 'user/isSupportStaff',
            canCloneJobs: 'user/canCloneJobs'
        })
    }
};
</script>

<style lang="scss" scoped>
.no-result-message {
    text-align: center;
    margin-top: 45px;
    font-size: 16px;
}

::v-deep .status-container {
    .md-table-cell-container {
        overflow: visible;
    }
}

::v-deep .rating-container {
    .ratings {
        display: flex;
        align-items: flex-end;
        > {
            display: inline-block;
        }
        .md-icon {
            margin: 0;
        }
    }
}

.slide-leave-active,
.slide-enter-active {
    transition: 0.3s;
}
.slide-enter {
    transform: translateX(100%);
}
.slide-leave-to {
    transform: translateX(100%);
}

.content {
    margin-top: -15px;
    ::v-deep .md-card.md-theme-default {
        margin-bottom: 0;
    }
}

::v-deep .filter-steps--container {
    // width: 880px;
    margin-top: 6px;
    // float: right;

    .filter-steps--choices {
        margin-top: 0;
        padding-bottom: 0;
        > .md-input {
            display: initial;
        }
    }
}

::v-deep .filter-steps--container.tablet-filter-container {
    width: initial;
    float: left;
    margin-left: 20px;
}

::v-deep .search--container {
    width: 250px;
    float: right;
    margin-top: 6px;
}

::v-deep .md-table-cell-container {
    max-width: 205px;

    // .custom-btn {
    //     margin: 0;
    //     max-width: initial;
    //     width: 90px;
    //     line-height: 29px;
    // }

    .md-button.md-round.md-just-icon {
        box-shadow: none;
    }
}

.custom-hidden-tablet {
    display: none;
}

.address-container {
    display: none;
}

@media (min-width: 1400px) {
    .address-container {
        display: table-cell;
        line-height: 30px;
        ::v-deep .md-table-cell-container {
            max-width: 300px;
        }
    }
}

::v-deep .md-table-cell {
    padding: 10px 8px;
    &:last-child {
        .md-table-cell-container {
            text-align: left !important;
            justify-content: flex-start;
        }
    }
}

::v-deep .md-table-row {
    &.zero-results {
        padding: 16px;
        text-align: center;
    }

    .custom-button-container .custom-a-blue a {
        color: rgba(0, 0, 0, 0.87);
    }
}

::v-deep .md-table-row:hover {
    background-color: #fafafa;
    cursor: pointer;
}

::v-deep .md-toolbar {
    .md-field {
        &:first-child,
        &:last-child {
            margin: 8px;
        }
    }

    .stops-search {
        padding-bottom: 10px;
        &::before,
        &::after {
            bottom: 10px;
        }
    }
}

::v-deep .md-card-actions {
    padding: 16px;
    &.page-footer {
        padding-top: 0px;
    }
}

.stop-reference ::v-deep a,
.trip-date ::v-deep a {
    color: rgba(0, 0, 0, 0.87);
}

::v-deep .header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.gray-text {
    color: rgba(0, 0, 0, 0.5);
}

.profile-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

.stopbar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

.action-buttons {
    button,
    ::v-deep button {
        margin: 0 2px;
    }

    button:last-child {
        margin-right: 0;
    }
}

.assigned-column {
    display: inline-block;
    vertical-align: middle;
    ::v-deep .custom-ellipsis {
        max-width: 150px;
    }
}

.stop-checkbox {
    margin: 0;
}
.checkbox-head {
    margin-top: 4px;
}

.body-list {
    position: relative;
    .sticky {
        position: fixed;
        top: 0;
        z-index: 9;
        left: 75px;
    }
}
.bulk-section {
    position: absolute;
    top: -35px;
    width: 545px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #cfeefa;
    color: #2b93ff;
    font-weight: 400;
    padding: 5px 10px 5px 20px;
    > div {
        width: 60%;
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }
    > div:last-child {
        width: 40%;
        text-align: right;
        button {
            height: 30px;
            width: 30px;
            min-width: 30px;
        }
        .dropdown {
            width: 120px;
            background-color: #ff9800 !important;
            display: inline-block;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
            height: 30px;
            line-height: 30px;
            margin-top: 6px;
            cursor: pointer;
        }
    }
}

.bulk-error-message {
    z-index: 1 !important;
    > div {
        max-height: 160px;
        overflow: auto;
    }

    .bulk-single-container {
        display: table;
        > div {
            display: table-cell;
        }
        > div:first-child {
            width: 200px;
        }
    }
}

.custom-toolbar {
    align-items: start;
    margin-top: 1px;
    .custom-toolbar-start {
        flex: 3;
    }
}

.icon-warning {
    color: #ff9800 !important;
    cursor: pointer;
    left: 14px;
}

@media (max-width: 992px) {
    .tablet-button-margin {
        margin-right: 10px;
    }
}
</style>
