var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.order > 1
        ? _c(
            "div",
            { staticClass: "operator-wrapper" },
            [
              _c(
                "form-group",
                {
                  attrs: {
                    name: "mainOperator",
                    label: "And/Or",
                    attribute: "And/Or",
                  },
                },
                [
                  _c(
                    "md-select",
                    {
                      model: {
                        value: _vm.subCondition.mainOperator,
                        callback: function ($$v) {
                          _vm.$set(_vm.subCondition, "mainOperator", $$v)
                        },
                        expression: "subCondition.mainOperator",
                      },
                    },
                    _vm._l(_vm.mainOperatorOptions, function (o) {
                      return _c(
                        "md-option",
                        {
                          key: _vm.order + "-main-op-" + o.text,
                          attrs: { value: o.key },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(o.text) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("span", { staticClass: "operator-wrapper" }, [_vm._v(" ")]),
      _c(
        "div",
        { staticClass: "lg-condition-wrapper" },
        [
          _c(
            "form-group",
            {
              class: {
                error: _vm.validator.variable.$error,
              },
              attrs: {
                name: "variable",
                label: "Variable",
                attribute: "Variable",
              },
            },
            [
              _c(
                "md-select",
                {
                  on: { "md-selected": _vm.selectedVariableChanged },
                  model: {
                    value: _vm.selectedVariable,
                    callback: function ($$v) {
                      _vm.selectedVariable = $$v
                    },
                    expression: "selectedVariable",
                  },
                },
                [
                  _vm._l(_vm.conditionVariablesWithoutGroup, function (ungrp) {
                    return _vm._l(ungrp.options, function (o) {
                      return _c(
                        "md-option",
                        {
                          key:
                            _vm.order +
                            "-var-ungrp-" +
                            o.parentObject +
                            "-" +
                            o.text,
                          attrs: { value: o.dropdownValue },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(o.text) +
                              "\n                    "
                          ),
                        ]
                      )
                    })
                  }),
                  _vm.conditionVariablesWithGroup.length
                    ? _vm._l(_vm.conditionVariablesWithGroup, function (g) {
                        return _c(
                          "md-optgroup",
                          {
                            key: _vm.order + "-var-grp-" + g.group,
                            attrs: { label: g.group },
                          },
                          _vm._l(g.options, function (o) {
                            return _c(
                              "md-option",
                              {
                                key:
                                  _vm.order +
                                  "-var-" +
                                  g.group +
                                  "-" +
                                  o.parentObject +
                                  "-" +
                                  o.text,
                                attrs: { value: o.dropdownValue },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(o.text) +
                                    "\n                        "
                                ),
                              ]
                            )
                          }),
                          1
                        )
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "lg-condition-wrapper" },
        [
          _c(
            "form-group",
            {
              class: {
                error: _vm.validator.operator.$error,
              },
              attrs: {
                name: "operator",
                label: "Operator",
                attribute: "Operator",
              },
            },
            [
              _c(
                "md-select",
                {
                  attrs: { disabled: !_vm.subCondition.variable },
                  model: {
                    value: _vm.subCondition.operator,
                    callback: function ($$v) {
                      _vm.$set(_vm.subCondition, "operator", $$v)
                    },
                    expression: "subCondition.operator",
                  },
                },
                _vm._l(_vm.variableOperatorOptions, function (o) {
                  return _c(
                    "md-option",
                    {
                      key: _vm.order + "-operator-" + o.text,
                      attrs: { value: o.key },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(o.text) +
                          "\n                "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          class: {
            error: _vm.validator.value.$error,
            "field-text": _vm.subCondition.dataType == "Text",
            "checkbox-field": _vm.subCondition.dataType == "CheckBox",
            "dropdown-field": _vm.subCondition.dataType == "Dropdown",
            "textarea-custom-field":
              _vm.subCondition.dataType == "MultiLineText",
            "lg-condition-wrapper-wider":
              _vm.subCondition.dataType === "MultiSelect",
            "lg-condition-wrapper": _vm.subCondition.dataType !== "MultiSelect",
          },
          attrs: { name: "name" },
        },
        [
          _vm.subCondition.dataType !== "MultiSelect" &&
          _vm.subConditionFieldInput
            ? _c("CustomFieldInputs", {
                attrs: {
                  "custom-field-definition": _vm.subConditionFieldInput,
                  "initial-value": _vm.subConditionFieldInputValue,
                  showDropdownLabelAs: "label",
                },
                on: { changed: _vm.handleValueChanged },
              })
            : _vm._e(),
          _vm.subCondition.dataType == "MultiSelect"
            ? _c(
                "form-group",
                { attrs: { name: "TeamRegion", label: "Team Region" } },
                [
                  _c("multiselect", {
                    attrs: {
                      "data-testid": "teamRegion-option",
                      options: _vm.getChoices(_vm.subCondition.variable),
                      multiple: true,
                      "close-on-select": true,
                      placeholder: "Select",
                      "track-by": "key",
                      label: "text",
                    },
                    model: {
                      value: _vm.tempMultiSelected,
                      callback: function ($$v) {
                        _vm.tempMultiSelected = $$v
                      },
                      expression: "tempMultiSelected",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showValue2
        ? _c(
            "div",
            {
              staticClass: "lg-condition-wrapper",
              class: {
                error: _vm.validator.value2.$error,
                "field-text": _vm.subCondition.dataType == "Text",
                "checkbox-field": _vm.subCondition.dataType == "CheckBox",
                "dropdown-field": _vm.subCondition.dataType == "Dropdown",
                "textarea-custom-field":
                  _vm.subCondition.dataType == "MultiLineText",
              },
              attrs: { name: "name" },
            },
            [
              _c("CustomFieldInputs", {
                attrs: {
                  "custom-field-definition": _vm.subConditionFieldInput2,
                  "initial-value": _vm.subConditionFieldInputValue2,
                },
                on: { changed: _vm.handleValue2Changed },
              }),
            ],
            1
          )
        : _c("span", { staticClass: "lg-condition-wrapper" }, [_vm._v(" ")]),
      _c(
        "md-button",
        {
          staticClass: "md-danger md-just-icon md-round remove-button",
          attrs: { title: "Remove sub-condition" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.removeSubCondition($event)
            },
          },
        },
        [_c("md-icon", [_vm._v("close")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }