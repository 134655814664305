<template>
    <div v-if="isActive">
        <span
            @click.stop="
                handleAssignStop({
                    stopId: item.stopId,
                    assignedTo: item.driverPublicUserId,
                    assignedCarrierTeamId: item.carrierTeamId,
                    stopRef: item.stopRef,
                    teamRegionId: item.teamRegionId
                })
            "
        >
            <a v-if="item && item.driverPublicUserId" title="reassign stop">
                {{ item.driverName }}
            </a>
            <a v-else-if="item && item.carrierTeamId" title="reassign stop">
                {{ item.carrierTeam.company }}
            </a>
            <a v-else title="reassign stop">Unassigned</a>
        </span>
    </div>
    <div v-else>
        <span v-if="item && item.driverPublicUserId">{{ item.driverName }}</span>
        <span v-else-if="item && item.carrierTeamId">{{ item.carrierTeam.company }}</span>
        <span v-else>Unassigned</span>
    </div>
</template>

<script>
import { AssignStopModal } from '@/pages/Stops/components';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { ASSIGNABLE_STATUS_CONSTANTS } from '@/utils/constants';

export default {
    name: 'AssignDriverCellRenderer',
    mixins: [GeneralMixin],
    data() {
        return {
            teamMembers: [],
            item: {},
            isActive: false
        };
    },
    beforeMount() {
        this.teamMembers = this.params.teamMembers;
        this.item = this.params.data;
        this.isActive = ASSIGNABLE_STATUS_CONSTANTS.includes(this.params.data.status);
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isCustomer: 'user/isCustomer',
            isSupportStaff: 'user/isSupportStaff'
        })
    },
    methods: {
        handleAssignStop({ stopId, assignedTo, assignedCarrierTeamId, stopRef, teamRegionId }) {
            if (this.isCustomer || this.isSupportStaff) 
                return;

            if (this.teamMembers.length > 0) {
                this.$modal
                    .show(AssignStopModal, {
                        stopId,
                        teamMembers: this.teamMembers,
                        assignedTo,
                        assignedCarrierTeamId,
                        stopRef,
                        teamRegionId
                    })
                    .then((response) => {
                        response.forEach((element) => {
                            let updatedData = {
                                driverName: null,
                                driverEmail: null,
                                driverPublicUserId: null
                            };

                            if (element.assignedTo && element.assignedTo.publicUserId) {
                                updatedData = {
                                    driverName: element.assignedTo.fullName,
                                    driverEmail: element.assignedTo.email,
                                    driverPublicUserId: element.assignedTo.publicUserId
                                };
                            }

                            const rowNode = this.params.api.getRowNode(this.params.node.id);

                            if (rowNode) {
                                Object.assign(rowNode.data, updatedData);

                                // Check if the current row is part of a shipment

                                if (rowNode.data.shipmentId) {
                                    // Get all row nodes in the grid
                                    const allRowNodes = [];
                                    this.params.api.forEachNode((node) => allRowNodes.push(node));

                                    // Find other rows with the same shipmentId
                                    const relatedRowNodes = allRowNodes.filter(
                                        (node) => node.data.shipmentId === rowNode.data.shipmentId
                                    );

                                    // Update each related row
                                    relatedRowNodes.forEach((node) => {
                                        Object.assign(node.data, updatedData);
                                    });

                                    // Refresh cells for all updated rows
                                    this.params.api.refreshCells({ rowNodes: relatedRowNodes });
                                } else {
                                    // Refresh only the current row if not part of a shipment
                                    this.params.api.refreshCells({ rowNodes: [rowNode] });
                                }
                            }
                        });
                    });
            } else {
                this.$notify({
                    message: 'Reassign function is still loading. Please try again later.',
                    type: 'warning'
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
a {
    color: #0076f0 !important;
}

a:hover {
    color: #0056b3 !important;
    cursor: pointer;
    text-decoration: underline !important;
}
</style>
