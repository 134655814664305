var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isReadOnlyUser
        ? _c(
            "md-button",
            {
              class: _vm.useIcon
                ? "md-primary md-just-icon md-round pull-right header-button"
                : "" + _vm.className,
              attrs: { title: "Add item details" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleAddItem($event)
                },
              },
            },
            [
              _vm.useIcon
                ? _c("md-icon", [_vm._v("add")])
                : _c("span", [_vm._v("Add")]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }