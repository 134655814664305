<template>
    <div>
        <md-button
            v-if="!isReadOnlyUser"
            title="Add item details"
            :class="useIcon ? 'md-primary md-just-icon md-round pull-right header-button' : `${className}`"
            @click.stop="handleAddItem"
        >
            <md-icon v-if="useIcon">add</md-icon>
            <span v-else>Add</span>
        </md-button>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { AddItemModal } from '@/pages/Inventory/components';
import { mapGetters } from 'vuex';

export default {
    name: 'AddItemButton',
    props: {
        className: {
            type: String,
            default: () => ''
        },
        useIcon: {
            type: Boolean,
            default: () => true
        }
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {
        handleAddItem() {
            this.$modal
                .show(AddItemModal, {
                    item: this.item
                })
                .then((response) => {
                    if (response) {
                        Object.assign(response, { itemId: this.item.itemId });

                        this.$emit('itemAdded', response);
                        this.$modal.hide();
                    }
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.add-item {
    display: inline;
}
</style>
