import { render, staticRenderFns } from "./FrequentAddressDetails.vue?vue&type=template&id=055ae35b&scoped=true&"
import script from "./FrequentAddressDetails.vue?vue&type=script&lang=js&"
export * from "./FrequentAddressDetails.vue?vue&type=script&lang=js&"
import style0 from "./FrequentAddressDetails.vue?vue&type=style&index=0&id=055ae35b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "055ae35b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\a\\1\\s\\Locate2u.AppPortal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('055ae35b')) {
      api.createRecord('055ae35b', component.options)
    } else {
      api.reload('055ae35b', component.options)
    }
    module.hot.accept("./FrequentAddressDetails.vue?vue&type=template&id=055ae35b&scoped=true&", function () {
      api.rerender('055ae35b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/FrequentAddresses/FrequentAddressDetails.vue"
export default component.exports