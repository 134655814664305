<template>
    <div class="calendar-parent">
        <div class="filter-steps-container" v-if="requestOptions.type === 'timeline'">
            <team-region-member-filter-options
                v-if="canViewTeamRegion"
                :team-region-id="teamRegionId"
                @changed="handleTeamRegionMemberChanged"
                :strict-team-region-filter="false"
                :show-team-member="false"
                :clearable="false"
            ></team-region-member-filter-options>
        </div>
        <div class="custom-filter-container">
            <div class="filter-select" v-if="hasMemberSelected && !isSingleUser && !isSingleTeamMember">
                <vue-select
                    label="fullName"
                    v-model="selectedTeamMemberId"
                    :reduce="(member) => member.publicUserId"
                    :options="members"
                    :searchable="$root.isDesktop"
                ></vue-select>
            </div>
            <div>
                <batch-upload
                    :title="'Import Stops'"
                    :template-type="'stops'"
                    :team-members="members"
                    @batchImported="handleBatchImported"
                    v-if="!isReadOnlyUser"
                />
                <create-stop
                    :team-members="members"
                    :selected-member-id="selectedTeamMemberId"
                    @stopCreated="handleStopCreated"
                />
            </div>
        </div>
        <div>
            <div
                class="calendar-section"
                :class="[{ calendarOverlay: isStopDetailsLoading }, isShowSidebar ? 'show-sidebar' : '']"
            >
                <calendar-widget
                    :members="members"
                    :events="events"
                    :has-member-selected="hasMemberSelected"
                    @getStopInfo="showStopDetailsSidebar"
                    @eventAdded="handleEventDrop"
                    @dateChange="handleDateChange"
                    @eventCalendarDrop="handleCalendarDrop"
                    @eventCalendarResize="handleCalendarResize"
                    @eventMouseHover="handleMouseHover"
                />
            </div>
            <slide-x-right-transition :duration="500">
                <div
                    class="calendar-sidebar-container"
                    :class="isShowSidebar ? 'show-sidebar' : ''"
                    v-if="isShowSidebar"
                >
                    <stop-calendar-sidebar
                        :remove-stop-id="removeStopId"
                        :unassigned-stops="unassignedStopsData"
                        :is-loading="isSidebarLoading"
                    ></stop-calendar-sidebar>
                </div>
            </slide-x-right-transition>
        </div>

        <stop-sidebar
            v-if="showStopDetails"
            :data="stopDetails"
            :stop-id="stopId"
            :team-members="members"
            v-click-outside="toggleStopDetailsWindow"
            @closeModal="toggleStopDetailsWindow"
            @changeStopStatus="stopStatusUpdated"
            @assignStop="onAssignedUser"
            @updateStop="stopUpdated"
            @deleteStop="stopDeleted"
        />
    </div>
</template>

<script>
/* eslint-disable no-useless-concat */
import CalendarWidget from '@/plugins/CalendarWidget';
import moment from 'moment';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins';
import { mapGetters } from 'vuex';
import { TeamRegionMemberFilterOptions, BatchUpload } from '@/components';
import { SlideXRightTransition } from 'vue2-transitions';
import { DATE_TYPES, CALENDAR_ACTIVE_STATUS_CONSTANTS } from '@/utils/constants';
import CreateStop from '@/pages/Stops/components/CreateStop';
import UpdateStopModal from '@/pages/Stops/components/UpdateStopModal';
import StopSidebar from '@/pages/Stops/StopSidebar';
import SelectTeamMemberModal from './SelectTeamMemberModal';
import StopCalendarSidebar from './StopCalendarSideBar';

export default {
    name: 'StopsScheduler',
    components: {
        TeamRegionMemberFilterOptions,
        CalendarWidget,
        StopCalendarSidebar,
        SlideXRightTransition,
        CreateStop,
        BatchUpload,
        StopSidebar
    },
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            isLoading: 'isLoading',
            user: 'user/user',
            canViewTeamRegion: 'user/canViewTeamRegion',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    watch: {
        async selectedTeamMemberId(val) {
            this.hasMemberSelected = val !== null;

            if (!this.hasMemberSelected) 
                return;
            const { type, dateStart, dateEnd } = this.requestOptions;
            if (type && type !== 'timeline') {
                const data = await this.getStopsList(dateStart, dateEnd, type, val);
                this.mapStopsToEvents(data);
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.stillHasCallsWaitingToFinish) {
            this.$messageBox
                .show({
                    title: 'Warning!',
                    body:
                        'A background process has not yet finished doing the changes, By clicking "YES some data will not be saved properly. Would you like to exit this page?',
                    buttons: ['Yes', 'No']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'yes') 
                        next();
                });
        } else {
            next();
        }
    },
    data() {
        const today = moment();
        return {
            events: [],
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            baseMembers: [],
            members: [],
            teamRegionId: null,
            selectedTeamMemberId: null,
            tripDate: new Date(),
            startDate: today.startOf('week').toISOString(),
            endDate: today.endOf('week').toISOString(),
            hasMemberSelected: false,
            isStopDetailsLoading: false,
            selectedFile: null,
            hasError: false,
            errorMessages: [],
            isShowSidebar: false,
            selectedMembers: [],
            removeStopId: null,
            requestOptions: {},
            currentCalendarData: [],
            stillHasCallsWaitingToFinish: false,
            unassignedStopsData: [],
            isSidebarLoading: true,
            showStopDetails: false,
            stopId: 0,
            stopDetails: {}
        };
    },
    async mounted() {
        await this.getMemberList();

        if (!this.isSingleUser && !this.isSingleTeamMember) {
            this.isShowSidebar = true;
            this.getUnassignedStops();
        }

        await this.loadAndMapStopList();
    },
    methods: {
        async getMemberList(val) {
            this.$_handleLoaderState(true);
            this.members = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS', {
                teamRegionId: val ? val.teamRegionId : null
            });
            // this.baseMembers = this.members;
            this.$_handleLoaderState(false);

            this.selectedTeamMemberId = null;

            if (this.isSingleTeamMember) {
                this.selectedTeamMemberId = this.user.publicUserId;
            }
        },
        async getStopsList(date, endDate = '', type = 'timeline', publicUserId = '') {
            let api;
            if (type === 'timeline') 
                api = `/api/stops/scheduler/list?&tripDate=${date}`;
            else 
                api = `/api/stops?fromDate=${date}&toDate=${endDate}&userId=${publicUserId}`;

            const { data } = await handleRequests(api);
            return data.data || data.stops;
        },
        async handleDateChange({ type, dateStart, dateEnd }) {
            localStorage.setItem('stops.defaults.calendar-view', type);
            this.$router.replace({ path: `/stops/calendar/${type}` });
            if (type !== 'timeline' && !this.selectedTeamMemberId) {
                await this.$modal
                    .show(SelectTeamMemberModal, { teamMembers: this.members })
                    .then(({ publicUserId }) => {
                        this.selectedTeamMemberId = publicUserId;
                        this.hasMemberSelected = true;
                    });
            }

            if (type === 'timeline' && !this.isSingleUser && !this.isSingleTeamMember) {
                this.hasMemberSelected = false;
                this.selectedTeamMemberId = null;
            }
            if (
                this.requestOptions?.type !== type ||
                this.requestOptions?.dateStart !== dateStart ||
                this.requestOptions?.dateEnd !== dateEnd
            ) {
                this.requestOptions = Object.assign(this.requestOptions, { type, dateStart, dateEnd });
                const data = await this.getStopsList(dateStart, dateEnd, type, this.selectedTeamMemberId);
                this.mapStopsToEvents(data, type);
            }
        },
        async handleEventDrop(event) {
            let publicUserId = this.selectedTeamMemberId;

            if (this.requestOptions.type === 'timeline') 
                publicUserId = event.resource.id;
            if (event.draggedEl.dataset.stop) {
                const stop = JSON.parse(event.draggedEl.dataset.stop);

                const { timeWindowEnd, timeWindowStart, appointmentTime, durationMinutes } = stop;

                const eventTime = {
                    assignToPublicUserId: publicUserId
                };

                let isTimeWindow = false;
                let stopOriginalTimeStart = null;

                if (timeWindowStart != null && timeWindowEnd != null) {
                    stopOriginalTimeStart = moment(timeWindowStart);
                    isTimeWindow = true;
                } else if (appointmentTime != null) {
                    stopOriginalTimeStart = moment(appointmentTime);
                } else {
                    stopOriginalTimeStart = moment(event.dateStr);
                }

                const calendarTime = moment(event.dateStr);
                const stopTime = moment(stopOriginalTimeStart);
                // This is to check if the Date is either a past or a future date and if the calendarTime corresponds to the stopTime
                if (!calendarTime.isSame(stopTime)) {
                    this.$notify({
                        message: 'Warning: Date and Time is not the same as the stop.',
                        type: 'warning'
                    });
                }

                // This warning will warn if the calendarTime is not between the timeWindowStart and timeWindowEnd
                if (isTimeWindow) {
                    const calendarTime = this.$options.filters.timeFormat(event.dateStr, DATE_TYPES.militaryTime);
                    const stopTimeStart = this.$options.filters.timeFormat(timeWindowStart, DATE_TYPES.militaryTime);
                    const stopTimeEnd = this.$options.filters.timeFormat(timeWindowEnd, DATE_TYPES.militaryTime);

                    const chosenTime = moment(calendarTime, DATE_TYPES.militaryTime);
                    const beforeTime = moment(stopTimeStart, DATE_TYPES.militaryTime);
                    const afterTime = moment(stopTimeEnd, DATE_TYPES.militaryTime);

                    if (!chosenTime.isBetween(beforeTime, afterTime)) {
                        this.$notify({
                            message: 'Warning: Time is not between the time window set.',
                            type: 'warning'
                        });
                    }
                }

                const calendarDate = moment(event.date);
                const isAllDay = false;
                let timeStart = moment(calendarDate);
                let timeEnd = moment(calendarDate);
                let startTime = null;
                let endTime = null;

                startTime = moment(event.dateStr);
                endTime = moment(event.dateStr).add(durationMinutes, 'minutes');

                timeStart = this.setTime(timeStart, startTime);
                timeEnd = this.setTime(timeEnd, endTime);

                eventTime.appointmentTime = this.$options.filters.timeFormat(timeStart, DATE_TYPES.militaryTime);
                eventTime.durationMinutes = timeEnd.diff(timeStart, 'minutes');

                timeStart = moment(timeStart).format();
                timeEnd = moment(timeEnd).format();

                eventTime.tripDate = this.$options.filters.timeFormat(timeStart, DATE_TYPES.internationalDate);

                this.events.push({
                    id: stop.stopId,
                    resourceId: publicUserId,
                    title: stop.stopId,
                    customHtml: `<div class="heavy-font">${
                        stop.contact.name == null ? '' : this.sanitizeContent(stop.contact.name)
                    }</div><div>${this.sanitizeContent(stop.address)}</div>`,
                    start: timeStart,
                    end: timeEnd,
                    className: this.statusClassName(stop.status),
                    allDay: isAllDay
                });

                this.removeStopId = stop.stopId;
                this.sendToApi(stop.stopId, eventTime);
            }
        },
        async sendToApi(stopId, data) {
            const api = `/api/stops/${stopId}/assign-user`;
            const payload = {
                method: 'post',
                data
            };

            this.stillHasCallsWaitingToFinish = true;
            try {
                await handleRequests(api, payload);
            } catch (e) {
                const message = 'Cannot assign stops.';
                showErrorMessage(this, message, e);
                this.refreshCalendar();
            }
            this.stillHasCallsWaitingToFinish = false;
        },
        async refreshCalendar() {
            const { type, dateStart, dateEnd } = this.requestOptions;
            const data = await this.getStopsList(dateStart, dateEnd, type, this.selectedTeamMemberId);
            this.mapStopsToEvents(data, type);
        },
        async apiStatusUpdate(stopId, data) {
            const api = `/api/stops/${stopId}/change-status`;
            const payload = {
                method: 'post',
                data
            };

            this.stillHasCallsWaitingToFinish = true;
            await handleRequests(api, payload);
            this.stillHasCallsWaitingToFinish = false;
        },
        getMemberWeeklyCalendarStops(publicUserId) {
            this.teamMemberSelected = true;
            this.getCalendarInfo(this.calendar);
        },
        mapStopsToEvents(stops, type = 'timeline') {
            this.currentCalendarData = Object.assign(this.currentCalendarData, stops);
            const filteredList = stops.filter((stop) => stop.assignedTo.publicUserId != null);
            this.events = filteredList.map((event) => {
                const {
                    stopId,
                    assignedTo: { publicUserId },
                    contact: { name },
                    address,
                    status,
                    tripDate,
                    appointmentTime,
                    timeWindowStart,
                    timeWindowEnd,
                    originalEta,
                    notes
                } = event;
                const durationInHours = Math.round(
                    moment.duration(moment(timeWindowEnd).diff(moment(timeWindowStart))).asHours()
                );
                let isAllDay = false;
                let timeStart = null;
                let timeEnd = null;

                if (originalEta && (this.requestOptions.type === 'day' || this.requestOptions.type === 'week')) {
                    timeStart = moment(originalEta).format();
                    timeEnd = moment(originalEta)
                        .add(event.durationMinutes, 'minutes')
                        .format();
                } else if (timeWindowStart != null && timeWindowEnd != null) {
                    timeStart = moment(timeWindowStart).format();
                    timeEnd = moment(timeWindowEnd).format();
                    isAllDay = durationInHours === 24;
                } else if (appointmentTime) {
                    timeStart = moment(appointmentTime).format();
                    timeEnd = moment(appointmentTime)
                        .add(event.durationMinutes, 'minutes')
                        .format();
                } else {
                    // no appointment time and time window, treat as all day event
                    isAllDay = true;
                    timeStart = moment(tripDate)
                        .startOf('day')
                        .format();
                    timeEnd = moment(tripDate)
                        .endOf('day')
                        .format();
                }

                return {
                    id: stopId,
                    resourceId: publicUserId || null,
                    title: stopId,
                    customHtml: `<div class="heavy-font type-${type}">${
                        name == null ? '' : this.sanitizeContent(name)
                    }</div><div class="type-${type}">${this.sanitizeContent(address)}</div>`,
                    notes,
                    start: timeStart,
                    end: timeEnd,
                    className: this.statusClassName(status),
                    allDay: isAllDay,
                    status,
                    tripDate
                };
            });
        },
        setTime(time, eventTime) {
            time.set({
                hour: eventTime.get('hour'),
                minute: eventTime.get('minute'),
                second: eventTime.get('second')
            });
            return time;
        },
        onFileSelected(event) {
            // eslint-disable-next-line prefer-destructuring
            this.selectedFile = event.target.files[0];
            event.target.value = '';
            if (this.selectedFile) 
                this.onUpload();
        },
        onUpload() {
            this.isLoading = true;
            const fd = new FormData();
            fd.append('file', this.selectedFile);
            const api = `/api/stops/import/?userId=${this.selectedTeamMemberId}`;
            const payload = {
                method: 'post',
                data: fd
            };
            handleRequests(api, payload)
                .then(
                    (response) => {
                        if (response.status === 200) {
                            this.hasError = false;
                            this.errorMessages = [];
                            this.selectedFile = '';
                            this.getMemberWeeklyCalendarStops(this.selectedTeamMemberId);
                            return true;
                        }
                        return false;
                    },
                    (error) => {
                        this.hasError = true;
                        this.isLoading = false;
                        this.errorMessages = error.data;
                    }
                )
                .then((response) => {
                    if (response) {
                        this.$notify({
                            message: 'Stops has been successfully uploaded',
                            type: 'success'
                        });
                    }
                });
        },
        async handleCalendarDrop(event) {
            let publicUserId = this.selectedTeamMemberId;
            if (this.requestOptions.type === 'timeline') {
                const { _def } = event.event;
                // eslint-disable-next-line prefer-destructuring
                publicUserId = _def.resourceIds[0];
            }
            try {
                await this.formatEventAndSendToApi(event.event, publicUserId);
                await this.updateEventStatus(event.event);
            } catch (e) {
                this.$_handleLoaderState(false);
            }
        },
        handleCalendarResize(event) {
            let publicUserId = this.selectedTeamMemberId;
            if (this.requestOptions.type === 'timeline') {
                const { _def } = event.event;
                // eslint-disable-next-line prefer-destructuring
                publicUserId = _def.resourceIds[0];
            }

            this.formatEventAndSendToApi(event.event, publicUserId);
        },
        handleMouseHover(event) {
            event.el.title = event.event.extendedProps.notes ? `Notes: ${event.event.extendedProps.notes}` : '';
        },
        async formatEventAndSendToApi(event, publicUserId) {
            const timeStart = moment(event.startStr);
            const timeEnd = moment(event.endStr);

            const duration = timeEnd.diff(timeStart, 'minutes');
            const eventTime = {
                assignToPublicUserId: publicUserId,
                appointmentTime: this.$options.filters.timeFormat(event.startStr, DATE_TYPES.militaryTime),
                durationMinutes: duration,
                tripDate: this.$options.filters.timeFormat(timeStart, DATE_TYPES.internationalDate)
            };

            await this.sendToApi(event.id, eventTime);
        },
        async updateEventStatus(event) {
            // eslint-disable-next-line eqeqeq
            const stop = this.events.find((item) => item.id == event.id);

            const eventStartDate = moment(event.start, DATE_TYPES.internationalDate);
            const stopStopDate = moment(stop.start, DATE_TYPES.internationalDate);
            const isSameDate = eventStartDate.isSame(stopStopDate, 'day');

            const isActiveStatus = CALENDAR_ACTIVE_STATUS_CONSTANTS.find((item) => item === stop.status);
            if (isActiveStatus && !isSameDate) {
                await this.apiStatusUpdate(event.id, {
                    oldStatus: stop.status,
                    newStatus: 'Pending'
                });
                this.refreshCalendar();
            }
        },
        showSidebar(value) {
            this.isShowSidebar = !value;
        },
        async handleBatchImported() {
            this.$_handleLoaderState(true);
            if (!this.isSingleUser && !this.isSingleTeamMember) {
                this.getUnassignedStops();
            }
            this.refreshCalendar();
            this.$_handleLoaderState(false);
        },
        async handleStopCreated() {
            this.$_handleLoaderState(true);
            if (!this.isSingleUser && !this.isSingleTeamMember) {
                this.getUnassignedStops();
            }
            const { type, dateStart, dateEnd } = this.requestOptions;
            this.handleDateChange({ type, dateStart, dateEnd });
            this.$_handleLoaderState(false);
        },
        async getUnassignedStops(val) {
            this.isSidebarLoading = true;
            const api = `/api/stops/unassigned?teamRegionId=${val ? val.teamRegionId : null}`;
            const { data } = await handleRequests(api);
            this.isSidebarLoading = false;

            if (data) {
                this.unassignedStopsData = data;
            }
        },
        async handleUpdateStop(stop) {
            this.$modal
                .show(UpdateStopModal, {
                    members: this.members,
                    stop: stop.event.id
                })
                .then((response) => {
                    if (response.toLowerCase() === 'ok') 
                        this.loadAndMapStopList();
                    this.$modal.hide();
                });
        },
        async loadAndMapStopList() {
            this.$_handleLoaderState(true);
            const today = moment().format('YYYY-MM-DD');
            const stops = await this.getStopsList(today);
            this.mapStopsToEvents(stops);
            this.$_handleLoaderState(false);
        },
        toggleStopDetailsWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
                this.showStopDetails = !this.showStopDetails;
        },
        showStopDetailsSidebar(stop) {
            this.showStopDetails = true;
            this.stopId = stop.event.id;
        },
        stopStatusUpdated(response) {
            const { stopId, status } = response;
            // eslint-disable-next-line eqeqeq
            const stop = this.events.find((item) => item.id == stopId);
            this.$set(stop, 'status', status);
            this.$set(stop, 'className', this.statusClassName(status));
            this.$set(this.stopDetails, 'status', status);
        },
        stopUpdated(response) {
            if (response.toLowerCase() === 'ok') {
                const { type, dateStart, dateEnd } = this.requestOptions;
                this.handleDateChange({ type, dateStart, dateEnd });
            }

            this.showStopDetails = false;
        },
        stopDeleted() {
            const { type, dateStart, dateEnd } = this.requestOptions;
            this.showStopDetails = false;
            this.handleDateChange({ type, dateStart, dateEnd });
            this.$_handleLoaderState(false);
        },
        onAssignedUser(response) {
            const { stopId, assignedTo } = response;
            // eslint-disable-next-line eqeqeq
            const stop = this.events.find((item) => item.id == stopId);
            this.$set(stop, 'resourceId', assignedTo.publicUserId);
            this.$set(this.stopDetails, 'assignedTo', assignedTo);
        },
        sanitizeContent(content) {
            return content.replace(/<[^>]*>?/gm, '');
        },
        statusClassName(status) {
            return `status-${status.toLowerCase().replace(/\s+/g, '')}`;
        },
        handleTeamRegionMemberChanged(val) {
            this.getMemberList(val);
            this.getUnassignedStops(val);
        }
    }
};
</script>

<style lang="scss" scoped>
.stops-details {
    text-align: left;
    font-size: 15px;
    label {
        font-weight: 700;
    }
}
.calendar-section {
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    .stop-loading {
        position: absolute;
        top: 0%;
        z-index: 999;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        .v-spinner {
            top: 45%;
            left: 50%;
        }
    }
}

.calendar-section.show-sidebar {
    width: 80%;
    // height: 75vh;
}

.calendar-sidebar-container {
    // width: 20%;
    margin-top: 29px;
    display: inline-block;
    vertical-align: top;
    // border-left: 1px solid black;
    // height: 80vh;
    padding-left: 5px;
    padding-right: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    background: white;
    border-radius: 4px;
    // overflow: scroll;
}

.calendar-sidebar-container.show-sidebar {
    width: 20%;
    display: inline-block;
    vertical-align: top;
}

.fc-day-grid-event .fc-content {
    white-space: normal !important;
}

.calendar-parent {
    margin-top: -15px;
    .filter-steps-container {
        width: 500px;
        float: left;
        margin-left: 20px;
    }
    .custom-filter-container {
        text-align: right;
        // border-bottom: 1px solid #ddd;
        // padding-bottom: 12px;
        .custom-btn {
            margin: 0;
            margin-left: 10px;
            max-width: 180px;
        }
        > div {
            width: 50%;
            display: inline-block;
            vertical-align: middle;
        }
        .filter-select {
            // text-align: left;
            min-width: 300px;
            // max-width: 300px;
            max-height: 50px;
            .vs__search::placeholder {
                color: #aaaaaa;
                font-size: 14px;
            }
            .v-select {
                max-width: 300px;
            }
        }
        .btn-container {
            text-align: right;
        }

        ::v-deep .header-button {
            width: 32px;
            height: 32px;
            min-width: 32px;
        }
    }
}
</style>
