<template>
    <div v-if="isFetchingUserDetails" class="loader-content-container">
        <LoginRedirect />
    </div>
    <div class="wrapper" :class="[{ 'nav-open': $sidebar.showSidebar }]" v-else>
        <notifications />
        <side-bar
            :active-color="sidebarBackground"
            :background-image="sidebarBackgroundImage"
            :background-color="sidebarBackgroundColor"
            :logo="isCustomer && getTeamGeneralSettings.companyLogo ? getTeamGeneralSettings.companyLogo : null"
            :is-customer-type="isCustomer"
            :app-version="user.appPortalVersion"
            :app-version-link="user.appPortalVersionLink"
        >
            <user-menu />

            <template slot="links">
                <div v-for="(menuItem, menuIndex) in menuData" :key="menuIndex">
                    <div v-feature="getRouteFeatureRestrictions(menuItem)">
                        <div v-if="canShowItem(menuItem)">
                            <sidebar-item :link="menuItem" class="sidebar-spacing">
                                <template v-for="(childItem, index) in menuItem.children">
                                    <sidebar-item
                                        v-feature="getRouteFeatureRestrictions(childItem)"
                                        v-if="canShowItem(childItem)"
                                        :badge="childItem.badge"
                                        :link="childItem"
                                        :key="index"
                                        class="sidebar-spacing-reset"
                                    />
                                </template>
                            </sidebar-item>
                        </div>
                    </div>
                </div>

                <support-menu v-if="!isReadOnlyUser" />
            </template>
        </side-bar>
        <div
            class="main-panel custom-lp"
            :class="[
                $root.isMobileOnly ? 'main-panel-whitebg' : '',
                isSupportUser ? 'with-header' : 'no-header',
                isReadOnlyUser || !$route.meta.hideFooter ? 'show-footer' : 'no-footer'
            ]"
        >
            <support-user-nav-bar />

            <div v-if="!isFetchingUserDetails && showTrialBanner" class="trial-alert-container">
                <TrialBanner />
            </div>
            <top-navbar class="mobile-navigation" :class="$root.isMobileOnly ? 'mobileonly-navigation' : ''" />
            <div
                :class="{
                    content: !$route.meta.hideContent,
                    'map-overview': $route.meta.isMap,
                    'optimise-stops-overview': $route.meta.isOptimise
                }"
                @click="toggleSidebar"
            >
                <zoom-center-transition :duration="200" mode="out-in">
                    <!-- your content here -->
                    <div class="layout-parent" v-if="getTeamGeneralSettings.isTwoFAEnabled && !user.hasTwoFAEnabled">
                        <div class="two-fa-container">
                            <h5 class="two-fa-title">Two Factor Authentication Setup</h5>
                            <p>
                                Your team has enabled two factor authentication. In order to use the app properly you
                                must logout and sign in again to setup two factor authentication on your account.
                            </p>

                            <div>
                                <a class="logout-link" href="#" @click="logout">Click here to logout</a>
                            </div>
                        </div>
                    </div>
                    <div class="layout-parent" v-else>
                        <router-view :key="$router.path" />
                        <div v-show="isLoading" class="loading">
                            <fade-loader :loading="isLoading" color="#333333" />
                            <span>{{ loadingText }}</span>
                        </div>
                    </div>
                </zoom-center-transition>
            </div>
            <content-footer v-if="isReadOnlyUser || !$route.meta.hideFooter" />
        </div>
    </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { ZoomCenterTransition } from 'vue2-transitions';
import { mapGetters, mapActions } from 'vuex';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { TrialBanner } from '@/components';
// eslint-disable-next-line no-unused-vars
import { FEATURE_NAMES } from '@/utils/constants';
import TopNavbar from './TopNavbar';
import ContentFooter from './ContentFooter';
import UserMenu from './Extra/UserMenu';
import SupportUserNavBar from './SupportUserNavBar';
/* eslint-disable vue/no-unused-components */
import ReportMenu from './Extra/ReportMenu';
import TeamMenu from './Extra/TeamMenu';
import SupportMenu from './Extra/SupportMenu';
import InventoryMenu from './Extra/InventoryMenu';
import InvoicesMenu from './Extra/InvoicesMenu';
import AssetManagementMenu from './Extra/AssetManagementMenu';
import LoginRedirect from '../../../components/LoginRedirect';
// eslint-disable-next-line
import { sideBarMenuBuilder, canRouteAndShow } from '@/helpers';

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

export default {
    components: {
        TopNavbar,
        ContentFooter,
        UserMenu,
        SupportUserNavBar,
        ReportMenu,
        TeamMenu,
        SupportMenu,
        InventoryMenu,
        InvoicesMenu,
        ZoomCenterTransition,
        FadeLoader,
        AssetManagementMenu,
        TrialBanner,
        LoginRedirect
    },
    data() {
        return {
            sidebarBackgroundColor: 'darkblue',
            sidebarBackground: 'green',
            sidebarBackgroundImage: '/img/sidebar-2.jpg',
            sidebarMini: true,
            sidebarImg: true,
            isFetchingUserDetails: true,
            isAdmin: Boolean(this.$route.query.isAdmin),
            menuData: [],
            featureName: FEATURE_NAMES
        };
    },
    computed: {
        ...mapGetters({
            isTrialExpired: 'user/isTrialExpired',
            isIndividualUser: 'user/isIndividualUser',
            hasTeamAccess: 'user/hasTeamAccess',
            hasAssetAccess: 'user/hasAssetAccess',
            hasCustomerListAccess: 'user/hasCustomerListAccess',
            hasInventoryListAccess: 'user/hasInventoryListAccess',
            hasInvoicesAccess: 'user/hasInvoicesAccess',
            isLoading: 'isLoading',
            loadingText: 'loadingText',
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser',
            hasOptimiseAccess: 'user/hasOptimiseAccess',
            getTeamGeneralSettings: 'generalSetting/getGeneralSettings',
            hasReportsAccess: 'user/hasReportsAccess',
            isAccountOwner: 'user/isAccountOwner',
            teamStatus: 'team/teamStatus',
            hasRateRules: 'user/hasRateRules',
            isTeamOwner: 'user/isTeamOwner',
            isSupportUser: 'user/isSupportUser',
            useNewOptimiseStops: 'optimiseSetting/getUseNewOptimiseStops',
            isCustomer: 'user/isCustomer'
        }),
        showTrialBanner() {
            return !(this.teamStatus === 'Unknown' || this.teamStatus === 'Active' || this.teamStatus === 'Disabled?');
        }
    },
    watch: {
        sidebarMini() {
            this.minimizeSidebar();
        }
    },
    async mounted() {
        window.messageBox = this.$messageBox; // a workaround to make MessageBox accessible in helper/index
        this.isFetchingUserDetails = true;
        await this.$store.dispatch('generalSetting/FETCH_GENERAL_SETTINGS');

        // avoid 403
        if (this.isIndividualUser) {
            await Promise.all([
                this.$store.dispatch('user/FETCH_USER'),
                this.$store.dispatch('user/FETCH_RATE_RULES')
            ]).catch((err) => {
                console.error(err);
            });
        } else {
            await Promise.all([
                this.$store.dispatch('user/FETCH_USER'),
                this.FETCH_TEAM_REGIONS(),
                this.FETCH_TEAM_HAS_CUSTOMERS(),
                this.FETCH_WAREHOUSES(),
                this.FETCH_TEAM_CARRIERS(),
                this.FETCH_EVENT_TRIGGERS({ actionType: null }),
                this.$store.dispatch('user/FETCH_RATE_RULES')
            ]).catch((err) => {
                console.error(err);
            });
        }

        this.isFetchingUserDetails = false;
        const docClasses = document.body.classList;
        const isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
            // if we are on windows OS we activate the perfectScrollbar function
            initScrollbar('sidebar');
            initScrollbar('sidebar-wrapper');
            initScrollbar('main-panel');

            docClasses.add('perfect-scrollbar-on');
        } else {
            docClasses.add('perfect-scrollbar-off');
        }

        if (this.isCustomer) {
            this.sidebarBackgroundColor = 'customwhite';
        }

        this.menuData = sideBarMenuBuilder(this.$t('menus'), this.useNewOptimiseStops);
    },
    methods: {
        ...mapActions('team', [
            'FETCH_TEAM_REGIONS',
            'FETCH_WAREHOUSES',
            'FETCH_TEAM_CARRIERS',
            'FETCH_TEAM_HAS_CUSTOMERS'
        ]),
        ...mapActions('custom-actions', ['FETCH_EVENT_TRIGGERS']),
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
        async logout() {
            this.$store.commit('user/RESET_STATE');
            const response = await auth.logout();
            // clear local storage
            window.localStorage.clear();
            return response;
        },
        getRoute(routeId) {
            // eslint-disable-next-line prefer-destructuring
            const routes = this.$router.options.routes;
            let route = routes.find((route) => route.meta?.security?.id === routeId);

            if (route === undefined || route === null) {
                routes.forEach((child) => {
                    if (child.children) {
                        // eslint-disable-next-line
                        child.children.some((item) => {
                            if (item.meta?.security?.id === routeId) {
                                route = item;
                            }
                        });
                    }
                });
            }

            return route;
        },
        canShowItem(menuItem) {
            let route = null;

            if (menuItem.id) 
                route = this.getRoute(menuItem.id);

            if (!route) 
                return true;

            return canRouteAndShow(route, this.user);
        },
        getRouteFeatureRestrictions(menuItem) {
            let route = null;

            if (menuItem.id) {
                route = this.getRoute(menuItem.id);
            }

            if (!route) 
                return null;

            if (route.meta?.security?.featureRestrictions) {
                return route.meta?.security?.featureRestrictions;
            }

            return null;
        }
    }
};
</script>
<style lang="scss" scoped>
$scaleSize: 0.95;
@keyframes zoomIn95 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    to {
        opacity: 1;
    }
}
.main-panel .zoomIn {
    animation-name: zoomIn95;
}
@keyframes zoomOut95 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
}
.main-panel .zoomOut {
    animation-name: zoomOut95;
}
.main-panel .map-overview.content {
    padding: 0;
    min-height: 100vh;
}
.main-panel .optimise-stops-overview.content {
    padding: 0;
}

.show-footer > .content {
    min-height: calc(100vh - 122px);
}

.no-header.show-footer > .content {
    min-height: calc(100vh - 62px);
}

.no-header.show-footer > .map-overview {
    min-height: calc(100vh - 122px);
}

.layout-parent {
    position: relative;
    height: 100%;
    // height: calc(100vh - 120px);

    .loading {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 99999;

        .v-spinner {
            position: absolute;
            top: 40%;
            left: 50%;

            .v-fade {
                background-color: #fff !important;
            }
            .v-fade1,
            .v-fade2,
            .v-fade3,
            .v-fade4,
            .v-fade5,
            .v-fade6,
            .v-fade7 {
                background-color: #fff;
            }
        }

        span {
            position: absolute;
            top: 47%;
            left: calc((100% - 140px) / 2);
            text-align: center;
            font-weight: 600;
            color: #dee5ee !important;
            text-transform: uppercase;
            letter-spacing: 10px;
        }
    }
}

.main-panel-whitebg {
    background-color: #fff;
    > .content {
        padding-top: 0;
    }

    .trial-upgrade-btn {
        margin-left: 20px;
    }
}

.mobile-navigation {
    border-radius: 0;
}

.mobileonly-navigation {
    position: sticky;
    top: 0;
}

.custom-lp {
    position: relative;
    .trial-alert-container {
        position: sticky;
        top: 0;
        z-index: 999;
        .trial-alert-expiry {
            width: 100%;
            text-align: center;
            margin-bottom: 0;
            span {
                max-width: 100%;
                display: inline-block;
            }
        }
        .trial-upgrade-btn {
            color: #fff;
            border: 2px solid #fff;
            padding: 5px 10px;
            margin-left: 20px;
        }

        .trial-upgrade-btn:hover,
        .trial-upgrade-btn:focus,
        .trial-upgrade-btn:active {
            color: #fff !important;
        }
    }
}

.main-panel-whitebg {
    .trial-upgrade-btn {
        margin-left: 0;
        display: block;
    }
}

@media (min-width: 992px) {
    .mobile-navigation {
        display: none;
    }
}

.loader-content-container {
    ::v-deep .loader--text {
        color: #fff !important;
    }
}

.two-fa-container {
    max-width: 800px;
}

.two-fa-title {
    font-weight: 600;
}

.logout-link {
    padding: 15px 20px;
    background-color: #2b93ff !important;
    color: #fff !important;
    text-decoration: none;
    margin-top: 20px;
    display: block;
    width: 250px;
    text-align: center;
}

.version-number {
    z-index: 9;
    width: 150px;
    a {
        text-align: center;
        color: #aaa !important;
        text-decoration: none;
        font-weight: 500;
        font-size: 12px;
    }
}

.sidebar-spacing-reset {
    padding-top: 0px;
}

.sidebar-spacing {
    padding-top: 5px;
}

.icon-disabled {
    padding-top: 10px;
}

#sidebar-item {
    padding-top: 10px;
}

:empty {
    padding-top: 0px;
}

:blank {
    padding-top: 0px;
}

.version-number {
    z-index: 9;
    width: 150px;
    a {
        text-align: center;
        color: #aaa !important;
        text-decoration: none;
        font-weight: 500;
        font-size: 12px;
    }
}
</style>
