<template>
    <div class="modal-container">
        <div class="modal-header">
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <simple-wizard plain :show-header="false" :title="title" :sub-title="subtitle" @stepChange="stepChange">
                <wizard-tab :before-change="validateDetails" :id="'step1'">
                    <template slot="label">
                        Create SMS Template
                    </template>
                    <div>
                        <form-wrapper :validator="$v.form" class="form-wrapper" v-if="!isStepLoading">
                            <!-- TODO: Create a reusable template to for template dropdown -->
                            <div v-if="orderSmsTemplates.length" class="sms-template-dropdown-container">
                                <form-group name="eventTrigger" label="SMS Template">
                                    <md-select data-testid="eventTrigger-select" v-model="selectedTemplate">
                                        <md-option value="none">No Selected Template</md-option>
                                        <md-option
                                            v-for="item in orderSmsTemplates"
                                            :key="item.teamCustomActionId"
                                            :value="item.teamCustomActionId"
                                        >
                                            {{
                                                item.settings != null
                                                    ? item.settings.templateName
                                                    : item.eventTriggerName
                                            }}
                                        </md-option>
                                    </md-select>
                                </form-group>
                            </div>
                            <form-group class="custom-merge-field">
                                <drop-down>
                                    <md-button
                                        @click.prevent
                                        slot="title"
                                        class="dropdown-toggle md-info"
                                        data-toggle="dropdown"
                                    >
                                        Insert Merge Field
                                    </md-button>
                                    <ul class="dropdown-menu dropdown-menu-right">
                                        <li v-for="event in mergeFields" :key="event.key">
                                            <span v-if="event.key === 'stopcustomfields'" class="custom-field-header">
                                                {{ event.name }}
                                            </span>
                                            <a v-else @click="getSelectedMergeField(event.key)">{{ event.name }}</a>
                                        </li>
                                    </ul>
                                </drop-down>
                            </form-group>
                            <form-group class="custom-textarea" name="template">
                                <md-textarea v-model="form.template" ref="template" />
                            </form-group>
                        </form-wrapper>
                        <div v-else>
                            <div class="table-loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </div>
                </wizard-tab>
                <wizard-tab :before-change="validateDetails" :id="'step2'">
                    <template slot="label">
                        Selected Orders
                    </template>
                    <div class="stop-table-container" v-if="!isStepLoading">
                        <md-table class="context-menu-support">
                            <md-table-row>
                                <md-table-head></md-table-head>
                                <md-table-head>
                                    <md-checkbox
                                        class="stop-checkbox checkbox-head"
                                        v-model="isSelectAll"
                                        @change="onSelectAll(isSelectAll)"
                                    ></md-checkbox>
                                </md-table-head>
                                <TableHeaderDropdown
                                    column-icon="keyboard_arrow_down"
                                    :default-text="selectedColumn.name"
                                    :dropdown-options="columnDropdownOptions"
                                    :selected-option="selectedColumn"
                                    @selectedOption="handleSelectedColumn"
                                />
                                <md-table-head>Contact</md-table-head>
                                <md-table-head>Phone</md-table-head>
                                <md-table-head>Ready Delivery Date</md-table-head>
                            </md-table-row>

                            <md-table-row v-for="item in selectedOrders" :key="item.orderId">
                                <md-table-cell>
                                    <div class="error-icon" v-if="!checkValidPhone(item.contact)">
                                        <md-icon>error</md-icon>
                                        <md-tooltip md-direction="right">Invalid Phone Number</md-tooltip>
                                    </div>
                                </md-table-cell>
                                <md-table-cell>
                                    <md-checkbox
                                        class="stop-checkbox"
                                        :value="item"
                                        v-model="orderList"
                                        @change="singleCheckbox(item)"
                                    ></md-checkbox>
                                </md-table-cell>
                                <md-table-cell class="stop-reference">
                                    <router-link
                                        v-if="selectedColumn.key == 'order-number'"
                                        class="ref-link"
                                        :to="{ name: 'Order Details', params: { orderId: item.orderId } }"
                                        target="_blank"
                                    >
                                        {{ item.orderNumber }}
                                    </router-link>
                                    <router-link
                                        v-else
                                        class="ref-link"
                                        :to="{ name: 'Order Details', params: { orderId: item.orderId } }"
                                        target="_blank"
                                    >
                                        {{ item.sourceReference != null ? item.sourceReference : item.orderNumber }}
                                    </router-link>
                                </md-table-cell>
                                <md-table-cell>
                                    {{ item.contact ? item.contact.name : '' }}
                                </md-table-cell>
                                <md-table-cell>
                                    <form-group>
                                        <md-input v-model="item.contact.phone" />
                                    </form-group>
                                </md-table-cell>
                                <md-table-cell>
                                    {{ item.orderDate | dateFormat(DATE_TYPES.standardDate) }}
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                    <div v-else>
                        <div class="table-loader">
                            <fade-loader :loading="true" color="#333333" />
                            <span>LOADING</span>
                        </div>
                    </div>
                </wizard-tab>

                <wizard-tab :before-change="validateDetails" :id="'step3'">
                    <template slot="label">
                        Confirmation
                    </template>
                    <div class="confirmation-message">
                        Are you sure you want to send SMS to {{ this.orderList.length }} orders?
                        <br />
                        Click the "Finish" button to send.
                    </div>
                </wizard-tab>
            </simple-wizard>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';
import { SimpleWizard, WizardTab, TableHeaderDropdown } from '@/components';
import { INSERT_ORDER_MERGE_FIELDS_CONSTANTS } from '@/utils/constants';
import { handleRequests, showErrorMessage, isPhoneValid } from '@/helpers';
import { mapGetters } from 'vuex';
import FadeLoader from 'vue-spinner/src/FadeLoader';

export default {
    name: 'SendBulkSms',
    mixins: [GeneralMixin],
    components: {
        SimpleWizard,
        WizardTab,
        FadeLoader,
        TableHeaderDropdown
    },
    props: {
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        selectedOrders: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({ customActions: 'custom-actions/smsCustomActions' }),
        orderSmsTemplates() {
            return this.customActions.filter((ca) => ca.eventTrigger === 'ORDER-EVENT-MANUAL-TRIGGERED');
        }
    },
    data() {
        return {
            form: {
                key: '',
                template: '',
                level: '',
                eventTrigger: '',
                stopType: null,
                id: 0
            },
            insertMergeFieldOptions: [...INSERT_ORDER_MERGE_FIELDS_CONSTANTS],
            mergeFields: [],
            step: 'step1',
            orderList: [],
            isSelectAll: true,
            smsTemplates: [],
            isStepLoading: false,
            columnDropdownOptions: [
                { key: 'order-number', name: 'Order #' },
                { key: 'source-reference', name: 'Source Ref' }
            ],
            selectedColumn: { key: 'order-number', name: 'Order #' },
            selectedTemplate: 'none'
        };
    },
    watch: {
        selectedTemplate(templateId) {
            const template = this.orderSmsTemplates.find((template) => template.teamCustomActionId === templateId);
            if (template) {
                this.form.template = template.settings.smsTemplateContent;
            } else {
                this.form.template = null;
            }
        }
    },
    mounted() {
        this.getMergeFields();
        if (localStorage.getItem('orders.defaults.bulksms.templateId') != null) {
            this.selectedTemplate = localStorage.getItem('orders.defaults.bulksms.templateId');
            this.selectedTemplate = Number(this.selectedTemplate);
        }
        this.orderList = [...this.selectedOrders];
    },
    methods: {
        getMergeFields() {
            this.mergeFields = this.insertMergeFieldOptions;
        },
        stepChange(name) {
            this.step = name;
        },
        async validateDetails() {
            switch (this.step) {
                case 'step1':
                    if (this.form.template === '' || this.form.template === null) {
                        this.$notify({
                            message: 'Template cannot be empty',
                            type: 'danger'
                        });
                        return false;
                    }

                    // this is to prevent server side call in getting the order, when we change the step from step 1 to 2.
                    if (this.orderList.length === 0) {
                        this.isStepLoading = true;
                        this.onSelectAll(true);
                    }

                    break;
                case 'step2':
                    if (this.orderList.length <= 0) {
                        this.$notify({
                            message: 'Please select at least one order to send an SMS to',
                            type: 'danger'
                        });
                        return false;
                    }
                    break;
                case 'step3':
                    this.sendBulkSms();
                    break;
                default:
                    break;
            }

            return true;
        },
        onSelectAll(value) {
            this.orderList = [];
            if (value) {
                this.selectedOrders.forEach((order) => {
                    if (order.contact != null && order.contact.phone && order.status !== 'Cancelled') {
                        this.orderList.push(order);
                    }
                });
            }
        },
        singleCheckbox(order) {
            const isRemoved = !this.orderList.some((selectedOrder) => selectedOrder.orderId === order.orderId);
            if (isRemoved) {
                this.isSelectAll = false;
            }
        },
        getSelectedMergeField(value) {
            const textarea = this.$refs.template.$el;

            if (value !== 'default') {
                if (!this.form.template) {
                    this.form.template = '';
                }
                const temp = this.form.template;
                const currentCursorPosition = textarea.selectionStart;
                this.form.template = `${temp.substring(0, textarea.selectionStart)}{{${value}}}${temp.substring(
                    textarea.selectionEnd,
                    temp.length
                )}`;

                this.$nextTick(() => {
                    textarea.focus();
                    // The additional + 4 is because we are adding {{}} to the merge field value upon insert.
                    const cursorfocusPos = currentCursorPosition + value.length + 4;
                    textarea.setSelectionRange(cursorfocusPos, cursorfocusPos);
                });
            }
        },
        checkValidPhone(contact) {
            if (contact !== null && contact.phone) {
                return isPhoneValid(contact.phone);
            }

            return false;
        },
        async sendBulkSms() {
            try {
                const data = [];
                this.orderList.forEach((order) => {
                    data.push({
                        orderId: order.orderId,
                        phoneNumber: order.contact != null ? order.contact.phone : ''
                    });
                });

                const api = '/api/orders/bulk/send-sms';
                const payload = {
                    method: 'post',
                    data: {
                        template: this.form.template,
                        bulkSmsData: data
                    }
                };
                await handleRequests(api, payload);
                // send to bulk sms endpoint
                this.$notify({
                    message: 'Your SMS are now being sent.',
                    type: 'success'
                });
                localStorage.setItem('orders.defaults.bulksms.templateId', this.selectedTemplate);
                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Unable to send bulk sms.';
                showErrorMessage(this, message, error);
            }
        },
        handleSelectedColumn(item) {
            this.selectedColumn = item;
        }
    },
    validations: {
        form: {
            key: {
                required
            },
            template: {
                required
            }
        },
        step1: {},
        step2: {},
        step3: {}
    }
};
</script>

<style lang="scss" scoped>
.overflow-container {
    overflow: auto;
    max-height: 50vh;
    margin-bottom: 10px;

    > div {
        margin-bottom: 20px;
    }
}

.modal-container {
    max-width: 800px;

    @media (min-width: 1440px) {
        max-width: 1000px;
    }

    .modal-header {
        padding: 0;

        button {
            z-index: 99;
        }
    }

    .modal-body {
        padding-top: 0;

        h5 {
            font-weight: 400;
            text-transform: uppercase;
            font-size: 15px;
        }

        ::v-deep .md-card {
            margin: 0 !important;
            background-color: #fff;
            box-shadow: none;

            .md-card-content {
                max-height: 60vh;
            }
        }

        ::v-deep .tab-content {
            padding: 20px 15px 0 15px !important;
        }

        .md-icon-button,
        ::v-deep .md-icon-button {
            background-color: #2b93ff !important;
        }

        .custom-merge-field {
            ::v-deep label {
                top: -10px;
            }

            ::v-deep .dropdown .md-button {
                background-color: #00bcd4 !important;
            }

            .dropdown-menu-right {
                max-height: 300px;
                overflow: auto;

                a {
                    cursor: pointer;
                }
            }

            .custom-field-header {
                padding-left: 10px;
                font-weight: bold;
            }
        }

        .event-trigger {
            float: left;
            width: 180px;
            margin-top: 29px;
            margin-right: 10px;

            ::v-deep label {
                top: -1.2rem !important;
            }
        }

        .stop-table-container {
            ::v-deep .md-table {
                overflow-x: initial;
            }

            min-height: 50vh;
            max-height: 50vh;
            max-width: 100%;
            overflow: auto;

            .stop-checkbox {
                margin: 0;
                margin-top: 6px;
            }

            .error-icon {
                display: inline-block;

                i {
                    color: #ffcc00;
                }
            }

            ::v-deep .md-table-cell,
            ::v-deep .md-table-head {
                padding: 0;

                a {
                    color: #1286ff;
                }
            }

            ::v-deep .md-table-cell {
                height: initial;
            }

            ::v-deep .md-table-head {
                padding-bottom: 5px;

                .md-table-head-label {
                    color: #4caf50 !important;
                }
            }

            .assigned-column {
                max-width: 200px;
            }
        }

        .confirmation-message {
            text-align: center;
            margin-top: 10%;
            font-size: 20px;

            .alert {
                font-size: 16px;
            }
        }
    }
}

::v-deep .running-load-head-table-header .running-load-menu .dropdown {
    top: -6px;
}

.md-tooltip {
    z-index: 9999;
}

.sms-template-dropdown-container {
    width: 300px;
    float: left;
    margin-top: 10px;
}
</style>
