var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item" },
        [
          _c("div", { staticClass: "custom-toolbar" }, [
            _c(
              "div",
              { staticClass: "custom-toolbar-start" },
              [
                _c("OrderFilterMenu", {
                  on: { handleFilterChange: _vm.handleFilterChange },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "custom-toolbar-end" },
              [
                _vm.$root.isDesktop
                  ? _c("search-component", {
                      attrs: {
                        "search-filter": _vm.currentSearchTextValue,
                        "handle-search-on-type": true,
                      },
                      on: { onSearch: _vm.handleFilterOrSearch },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "md-card",
            { staticClass: "custom-margin-bottom" },
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-warning" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("receipt_long")])],
                    1
                  ),
                ]
              ),
              _c("md-card-content", { staticClass: "body-list" }, [
                _vm.selectedOrders.length
                  ? _c(
                      "div",
                      {
                        class: [
                          "table-bulk-action-container",
                          _vm.topScrollPosition > 150 ? "sticky" : "",
                        ],
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.selectedOrders.length) +
                              " order" +
                              _vm._s(_vm.selectedOrders.length > 1 ? "s" : "") +
                              " selected.\n                        "
                          ),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-warning bulk-action-btn",
                                attrs: { title: "Send SMS" },
                                on: { click: _vm.sendSchedulingLink },
                              },
                              [
                                _c("md-icon", [_vm._v("schedule_send")]),
                                _vm._v(
                                  "\n                                Send SMS\n                            "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.isListLoading
                  ? _c(
                      "div",
                      [
                        _c(
                          "md-table",
                          {
                            staticClass:
                              "context-menu-support custom-paginated-table",
                          },
                          [
                            _c(
                              "md-table-row",
                              [
                                _c(
                                  "md-table-head",
                                  [
                                    _c("md-checkbox", {
                                      staticClass:
                                        "table-checkbox table-head-checkbox",
                                      on: {
                                        change: function ($event) {
                                          return _vm.onSelectAll(
                                            _vm.isSelectAll
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.isSelectAll,
                                        callback: function ($$v) {
                                          _vm.isSelectAll = $$v
                                        },
                                        expression: "isSelectAll",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("TableHeaderDropdown", {
                                  attrs: {
                                    "column-icon": "keyboard_arrow_down",
                                    "default-text": _vm.selectedColumn.name,
                                    "dropdown-options":
                                      _vm.columnDropdownOptions,
                                    "selected-option": _vm.selectedColumn,
                                  },
                                  on: {
                                    selectedOption: _vm.handleSelectedColumn,
                                  },
                                }),
                                _c("md-table-head", [_vm._v("Order Date")]),
                                _c("md-table-head", [_vm._v("Items")]),
                                _c("md-table-head", [
                                  _vm._v("Shipping Address"),
                                ]),
                                _c("md-table-head", [_vm._v("Customer Name")]),
                                _c("md-table-head", [
                                  _vm._v("Shipping Contact"),
                                ]),
                                _c("md-table-head", [
                                  _vm._v("Fulfillment Status"),
                                ]),
                                _vm.$root.isDesktop && !_vm.isReadOnlyUser
                                  ? _c("md-table-head", [_vm._v("Actions")])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._l(_vm.orderList, function (order) {
                              return _c(
                                "md-table-row",
                                { key: order.orderId },
                                [
                                  _c(
                                    "md-table-cell",
                                    [
                                      _c("md-checkbox", {
                                        staticClass: "table-checkbox",
                                        attrs: { value: order },
                                        on: {
                                          change: function ($event) {
                                            return _vm.singleCheckbox(order)
                                          },
                                        },
                                        model: {
                                          value: _vm.selectedOrders,
                                          callback: function ($$v) {
                                            _vm.selectedOrders = $$v
                                          },
                                          expression: "selectedOrders",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "md-table-cell",
                                    [
                                      _vm.selectedColumn.key == "order-number"
                                        ? _c(
                                            "router-link",
                                            {
                                              staticClass: "ref-link",
                                              attrs: {
                                                to: {
                                                  name: "Order Details",
                                                  params: {
                                                    orderId: order.orderId,
                                                  },
                                                },
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(order.orderNumber) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "router-link",
                                            {
                                              staticClass: "ref-link",
                                              attrs: {
                                                to: {
                                                  name: "Order Details",
                                                  params: {
                                                    orderId: order.orderId,
                                                  },
                                                },
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    order.sourceReference !=
                                                      null
                                                      ? order.sourceReference
                                                      : order.orderNumber
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                  _c("md-table-cell", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            order.orderDate,
                                            _vm.DATE_TYPES.standardDate
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _c("md-table-cell", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(order.itemCount) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _c("md-table-cell", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          order.shippingAddress != null
                                            ? order.shippingAddress.city
                                            : ""
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _c("md-table-cell", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          order.customerId != null
                                            ? order.customer.name
                                            : ""
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _c("md-table-cell", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(order.contact.name) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _c("md-table-cell", [
                                    _c(
                                      "span",
                                      { staticClass: "custom-badge" },
                                      [_vm._v(_vm._s(order.status))]
                                    ),
                                  ]),
                                  _vm.$root.isDesktop && !_vm.isReadOnlyUser
                                    ? _c(
                                        "md-table-cell",
                                        { staticClass: "action-buttons" },
                                        [
                                          !_vm.isReadOnlyUser
                                            ? _c(
                                                "md-button",
                                                {
                                                  class: [
                                                    "md-warning md-just-icon md-round",
                                                    _vm.isDisabled
                                                      ? "button-disabled"
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    title: "Update order",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.updateOrder(
                                                        order
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("md-icon", [
                                                    _vm._v("edit"),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        ),
                        _vm.orderList.length == 0
                          ? _c("div", [
                              _c("p", { staticClass: "no-result-message" }, [
                                _vm._v(
                                  "No results matching your search/filter could be found."
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "loader" },
                        [
                          _c("fade-loader", {
                            attrs: { loading: true, color: "#333333" },
                          }),
                          _c("span", [_vm._v("LOADING")]),
                        ],
                        1
                      ),
                    ]),
              ]),
            ],
            1
          ),
          _c(
            "md-card-actions",
            {
              staticClass: "page-footer",
              attrs: { "md-alignment": "space-between" },
            },
            [
              _c("div", [
                _vm.total === _vm.pagination.perPage
                  ? _c("p", { staticClass: "card-category" }, [
                      _vm._v(
                        "\n                        Page " +
                          _vm._s(_vm.pagination.currentPage) +
                          " of many\n                    "
                      ),
                    ])
                  : _c("p", { staticClass: "card-category" }, [
                      _vm._v(
                        "Page " +
                          _vm._s(_vm.pagination.currentPage) +
                          " of " +
                          _vm._s(_vm.totalPages)
                      ),
                    ]),
              ]),
              _c("pagination", {
                staticClass: "pagination-no-border pagination-success",
                attrs: { "per-page": _vm.pagination.perPage, total: _vm.total },
                on: {
                  "change-page": function ($event) {
                    return _vm.handleChangePage($event, _vm.pagination.perPage)
                  },
                },
                model: {
                  value: _vm.pagination.currentPage,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "currentPage", $$v)
                  },
                  expression: "pagination.currentPage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }