<template>
    <div class="customer-autocomplete">
        <form-group name="name" :label="label">
            <md-input
                ref="customerAutocomplete"
                type="text"
                v-model="autocompleteText"
                v-click-outside="handleClickOutside"
                @focus="onFocus($event)"
                @blur="blur"
                @change="onChange"
                @keyup.down="onArrowDown"
                @keyup.up="onArrowUp"
                @keyup.enter="onEnter"
                :disabled="isCustomerAdmin || disabled"
            />
        </form-group>
        <div class="autocomplete">
            <ul
                id="autocomplete-results"
                v-show="isOpen && autocompleteList.length"
                class="autocomplete-results"
                ref="autocompleteContainer"
            >
                <li class="loading" v-if="isAutoCompleteLoading">
                    Loading results...
                </li>
                <li
                    ref="autocompleteOptions"
                    v-else
                    v-for="(result, i) in autocompleteList"
                    :key="result.customerId"
                    @click="setAutocompleteResult(result)"
                    class="autocomplete-result"
                    :class="{ 'is-active': i === arrowCounter }"
                >
                    {{ result.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'CustomerAutocomplete',
    props: {
        id: {
            type: String,
            required: true
        },
        classname: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: 'Name'
        },
        icon: {
            type: String,
            default: ''
        },
        shouldFocus: {
            type: Boolean,
            default: false
        },
        stopId: {
            type: Number,
            default: 0
        },
        value: {
            type: Object,
            default: null
        },
        autoFillAddress: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        teamRegionId: {
            type: Number,
            default: null
        },
        updateCustomer: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        updateMode: {
            // set to true to enable logics only for 'update' scenarios
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            autocompleteText: null,
            autocompleteList: [],
            isOpen: false,
            isAutoCompleteLoading: false,
            arrowCounter: 0,
            selectedAutocomplete: {
                address: null,
                customerName: null,
                contact: {
                    name: null,
                    email: null,
                    phone: null
                },
                notes: null,
                durationMinutes: null
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isCustomerAdmin: 'user/isCustomerAdmin',
            hasCustomerFilterAccess: 'user/hasInventoryListAccess'
        })
    },
    watch: {
        'value.customerId': async function(newVal, oldVal) {
            if (newVal !== oldVal && this.updateCustomer) {
                await this.initializeComponent();
            }
        },
        'value.address': function(newVal, oldVal) {
            this.$emit('autoFillAddress', this.value);
        },
        // eslint-disable-next-line func-names
        autocompleteText: _.debounce(function() {
            if (this.autocompleteText !== this.selectedAutocomplete.customerName) {
                if (this.value.customerId != null) {
                    // If user decides to change the contact name after selecting from the autocomplete list.
                    // We want to check for values that were manually inputted and leave those in place, only removing the details automatically entered from the customer record.
                    // We need to compare if the address, email, or phone are actually still part of that selected customer.
                    // If it matches then we need to remove that detail that was linked to the customer.
                    if (this.selectedAutocomplete.contact.email === this.value.contact.email) {
                        this.value.contact.email = null;
                    }

                    if (this.selectedAutocomplete.contact.phone === this.value.contact.phone) {
                        this.value.contact.phone = null;
                    }

                    if (
                        this.selectedAutocomplete.contact.name === this.value.contact.name ||
                        this.autocompleteText === null ||
                        this.autocompleteText === ''
                    ) {
                        this.value.contact.name = null;
                    }

                    if (this.selectedAutocomplete.notes === this.value.notes) {
                        this.value.notes = null;
                    }

                    if (this.selectedAutocomplete.durationMinutes === this.value.durationMinutes) {
                        this.value.durationMinutes = 15; // set back to default value;
                    }

                    this.value.customerId = null;
                    this.value.brandId = null;

                    if (this.autoFillAddress) {
                        if (this.selectedAutocomplete.address === this.value.address) {
                            this.value.address = null;
                            this.value.location = {
                                latitude: null,
                                longitude: null
                            };
                        }
                    }
                }
                this.value.contact.name = this.autocompleteText;
                this.getCustomerList();
            }
        }, 500)
    },
    async mounted() {
        await this.initializeComponent();
    },
    methods: {
        async initializeComponent() {
            if (this.value.customerId != null) {
                await this.getCustomerDetails(this.value.customerId);
                this.update(this.selectedAutocomplete.customerName);
            } else {
                this.update(this.value.contact.name);
            }
        },
        onFocus(e) {
            // for selecting the whole input field
            setTimeout(() => e.target.select(), 0);
            this.$emit('focus');
        },
        onChange() {
            this.$emit('change', this.autocompleteText);
        },
        focus() {
            this.$refs.customerAutocomplete.$el.focus();
        },
        blur() {
            this.$refs.customerAutocomplete.$el.blur();
            // a timeout is needed since when an autocomplete option is selected it is synchronous with the setAutoCompleteResult function
            // we need to make sure that setAutocompleteResult runs first before we close the autocomplete selection.
            // This is also used when users use the tab key to go to the next input. We need to close the autocomplete selection.
            setTimeout(() => {
                this.isOpen = false;
            }, 300);
        },
        update(value) {
            this.autocompleteText = value;
        },
        setAutocompleteResult(data) {
            const {
                customerId,
                brandId,
                name,
                firstName,
                lastName,
                email,
                phone,
                address,
                locationName,
                location,
                defaultStopNotes,
                defaultStopDurationMinutes,
                defaultRateGroupId
            } = data;

            this.$emit('changeCustomerRateGroup', defaultRateGroupId);
            this.value.customerId = customerId;
            this.value.brandId = brandId;
            const contact = { name: `${firstName || ''} ${lastName || ''}`.trim(), email, phone };
            this.value.contact = Object.assign({}, contact);

            this.selectedAutocomplete.contact = Object.assign({}, contact);
            this.selectedAutocomplete.notes = defaultStopNotes;
            this.selectedAutocomplete.durationMinutes = defaultStopDurationMinutes;
            this.selectedAutocomplete.brandId = brandId;
            this.value.notes = defaultStopNotes;
            this.value.durationMinutes = defaultStopDurationMinutes || 0;

            if (this.autoFillAddress) {
                if (data.address) {
                    this.selectedAutocomplete.address = address;
                    this.value.address = address;
                    this.value.location = location;
                    this.value.name = locationName;
                }
            }

            this.$emit('input', this.value);
            this.autocompleteText = name;
            this.selectedAutocomplete.customerName = name;
            this.isOpen = false;
        },
        onArrowDown(evt) {
            if (this.arrowCounter < this.autocompleteList.length - 1) {
                this.arrowCounter = this.arrowCounter + 1;
                this.fixScrolling();
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
                this.fixScrolling();
            }
        },
        onEnter() {
            if (this.arrowCounter === -1) {
                this.setAutocompleteResult({
                    address: null,
                    customerName: null,
                    contact: {
                        name: null,
                        email: null,
                        phone: null
                    },
                    notes: null,
                    durationMinutes: null
                });
                return;
            }
            this.setAutocompleteResult(this.autocompleteList[this.arrowCounter]);
            this.arrowCounter = -1;
        },
        fixScrolling() {
            const optionHeight = this.$refs.autocompleteOptions[this.arrowCounter].clientHeight;
            this.$refs.autocompleteContainer.scrollTop = optionHeight * this.arrowCounter;
        },
        // eslint-disable-next-line func-names
        async getCustomerList() {
            if (!this.hasCustomerFilterAccess) 
                return;

            this.isAutoCompleteLoading = true;
            const endpoint = `/api/customers`;
            const {
                data: { customers }
            } = await handleRequests(endpoint, {
                params: {
                    pageNumber: 1,
                    itemsPerPage: 50,
                    searchText: this.autocompleteText,
                    teamRegionId: this.teamRegionId
                }
            });

            if (this.autocompleteText !== null && this.autocompleteText !== '') 
                this.isOpen = true;
            this.autocompleteList = customers;
            this.isAutoCompleteLoading = false;
        },
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.isOpen = false;
                this.arrowCounter = -1;
            }
        },
        async getCustomerDetails(customerId) {
            try {
                const api = `/api/customers/${customerId}`;
                const { data } = await handleRequests(api);
                this.selectedAutocomplete.address = data.address;
                this.selectedAutocomplete.contact.name = `${data.firstName} ${data.lastName}`.trim();
                this.selectedAutocomplete.contact.email = data.email;
                this.selectedAutocomplete.contact.phone = data.phone;
                this.selectedAutocomplete.notes = data.defaultStopNotes;
                this.selectedAutocomplete.customerName = data.name;
                this.selectedAutocomplete.name = data.locationName;
                this.selectedAutocomplete.durationMinutes = data.defaultStopDurationMinutes;
                this.selectedAutocomplete.brandId = data.brandId;
                this.selectedAutocomplete.rateGroupId = data.defaultRateGroupId;

                if (this.isCustomerAdmin && !this.updateMode) {
                    this.setAutocompleteResult(data);
                }
            } catch (error) {
                const message = 'Error in getting the customer details';
                showErrorMessage(this, message, error);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.customer-autocomplete {
    position: relative;
    width: 100%;
}

.autocomplete {
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    top: 40px;
}

.autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    overflow: auto;
    width: 100%;
}

.autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
    background-color: #4aae9b;
    color: white;
}
</style>
