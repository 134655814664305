var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-toolbar" }, [
    _c("span", { staticClass: "filter-title" }, [_vm._v("Current Filter:")]),
    _c(
      "div",
      {
        staticClass: "filter-selection",
        class: _vm.currentFilter ? "present" : "empty",
      },
      [
        _c(
          "div",
          {
            staticClass: "col1 layout-column",
            on: {
              click: function ($event) {
                return _vm.showFilterSelection()
              },
            },
          },
          [_c("md-icon", { staticClass: "icon edit" }, [_vm._v("tune")])],
          1
        ),
        _c("div", { staticClass: "col2 layout-column selected-filter-name" }, [
          _c(
            "span",
            { class: _vm.currentFilter ? "has-filter" : "no-filter" },
            [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.currentFilter ? _vm.currentFilter.name : "No filter"
                  ) +
                  "\n            "
              ),
            ]
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "col3 layout-column",
            on: {
              click: function ($event) {
                return _vm.clearCurrentFilter()
              },
            },
          },
          [
            _vm.currentFilter
              ? _c("md-icon", { staticClass: "icon delete" }, [
                  _vm._v("cancel"),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "filter-options" }, [
      _vm.currentFilter &&
      _vm.currentFilter.isSaving === false &&
      _vm.filterModified
        ? _c(
            "a",
            {
              staticClass: "active",
              on: {
                click: function ($event) {
                  return _vm.saveCurrentFilter()
                },
              },
            },
            [_vm._v("\n            Save\n        ")]
          )
        : _vm.currentFilter && _vm.currentFilter.isSaving === true
        ? _c("span", { staticClass: "saving" }, [_vm._v("Saving...")])
        : _c("span", { staticClass: "inactive" }, [_vm._v("Save")]),
      _vm._v("\n        |\n        "),
      _c(
        "a",
        {
          class: _vm.filterModified ? "active" : "inactive",
          on: {
            click: function ($event) {
              return _vm.revertToFilterConfig()
            },
          },
        },
        [_vm._v("\n            Revert\n        ")]
      ),
      _vm._v("\n        |\n        "),
      _c(
        "a",
        {
          class: _vm.hasGridFilter ? "active" : "inactive",
          on: {
            click: function ($event) {
              return _vm.saveNewFilter()
            },
          },
        },
        [_vm._v("\n            Save As New\n        ")]
      ),
      _vm.newFilterDetailsVisible
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.exitModals,
                  expression: "exitModals",
                },
              ],
              staticClass: "save-new-filter-details",
            },
            [
              _c("div", { staticClass: "col1 layout-column" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newFilterName,
                      expression: "newFilterName",
                    },
                  ],
                  staticClass: "filter-name-textbox",
                  attrs: {
                    type: "text",
                    placeholder: "Enter a name for your filter",
                  },
                  domProps: { value: _vm.newFilterName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.newFilterName = $event.target.value
                    },
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "col2 layout-column",
                  on: {
                    click: function ($event) {
                      return _vm.newFilterSaved()
                    },
                  },
                },
                [_c("md-icon", { staticClass: "saveicon" }, [_vm._v("save")])],
                1
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm.filterSelectionVisible
      ? _c(
          "div",
          {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.exitModals,
                expression: "exitModals",
              },
            ],
            staticClass: "filter-selection-popup",
          },
          [
            _c(
              "div",
              { staticClass: "exit-icon", on: { click: _vm.exitModals } },
              [_c("md-icon", [_vm._v("close")])],
              1
            ),
            _vm._v("\n        Your Saved Filters\n\n        "),
            _c("table", { staticClass: "filter-selection-table" }, [
              _vm._m(0),
              _c(
                "tbody",
                _vm._l(_vm.userSavedJobFilters, function (filter) {
                  return _c(
                    "tr",
                    {
                      key: filter.id,
                      class: { "saving-row": filter.isSaving },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticClass: "icon-column",
                          on: {
                            click: function ($event) {
                              return _vm.setDefaultFilter(filter)
                            },
                            mouseover: function ($event) {
                              return _vm.defaultColumnMouseOver(filter)
                            },
                            mouseleave: function ($event) {
                              return _vm.defaultColumnMouseLeave(filter)
                            },
                          },
                        },
                        [
                          filter.shouldApplyByDefault
                            ? _c(
                                "md-icon",
                                {
                                  staticClass: "icon default",
                                  attrs: {
                                    "aria-label":
                                      "Default filter will load automatically when you visit this page",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            star\n                        "
                                  ),
                                ]
                              )
                            : filter.showDefaultIcon
                            ? _c(
                                "md-icon",
                                {
                                  staticClass: "icon default",
                                  attrs: {
                                    "aria-label":
                                      "Select new default filter to load",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            star_border\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        {
                          on: {
                            mouseover: function ($event) {
                              return _vm.selectColumnMouseOver(filter)
                            },
                            mouseleave: function ($event) {
                              return _vm.selectColumnMouseLeave(filter)
                            },
                          },
                        },
                        [
                          !filter.isEditing
                            ? _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeSelectedFilter(filter)
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "filter-name" }, [
                                    _vm._v(_vm._s(filter.name)),
                                  ]),
                                  Object.is(_vm.currentFilter, filter)
                                    ? _c(
                                        "md-icon",
                                        {
                                          staticClass: "icon selected",
                                          attrs: {
                                            title: "Selected filter",
                                            "aria-label":
                                              "Currently selected filter",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                check_circle\n                            "
                                          ),
                                        ]
                                      )
                                    : filter.showSelectIcon
                                    ? _c(
                                        "md-icon",
                                        {
                                          staticClass: "icon selected",
                                          attrs: {
                                            title: "Select this filter",
                                            "aria-label": "Select this filter",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                check\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c("div", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: filter.name,
                                      expression: "filter.name",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  domProps: { value: filter.name },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.saveFilterName(filter)
                                    },
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.saveFilterName(filter)
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        filter,
                                        "name",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "icon-column",
                          on: {
                            click: function ($event) {
                              return _vm.editFilterName(filter)
                            },
                          },
                        },
                        [
                          _c(
                            "md-icon",
                            {
                              staticClass: "icon edit",
                              attrs: {
                                title: "Rename filter",
                                "aria-label": "Change name of filter",
                              },
                            },
                            [
                              _vm._v(
                                "\n                            edit\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "icon-column",
                          on: {
                            click: function ($event) {
                              return _vm.deleteFilter(filter)
                            },
                          },
                        },
                        [
                          _c(
                            "md-icon",
                            {
                              staticClass: "icon delete",
                              attrs: {
                                title: "Delete this filter",
                                "aria-label": "Delete the filter permanently",
                              },
                            },
                            [
                              _vm._v(
                                "\n                            delete\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      filter.isSaving
                        ? _c(
                            "td",
                            {
                              staticClass: "saving-overlay",
                              attrs: { colspan: "4" },
                            },
                            [_vm._v("Saving...")]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "details-text" }, [
              _vm._v(
                "\n            Your saved filters belong to you. You may optionally select a default filter which will load\n            automatically when you visit this page.\n        "
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Default")]),
        _c("th", [_vm._v("Name")]),
        _c("th", [_vm._v("Edit")]),
        _c("th", [_vm._v("Delete")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }