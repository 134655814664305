<template>
    <div>
        <form-group v-if="showTemplateName(customActionModel.eventTrigger)" label="Name" name="settings.templateName">
            <md-input v-model="customActionModel.settings.templateName" />
        </form-group>
        <form-group v-if="filters && filters.length > 0" name="filter.status" label="Filter">
            <md-select id="filter-status-select" v-model="nonWebhookStatusFilter">
                <md-option
                    v-for="item in filters"
                    :key="item"
                    :value="item"
                    :data-test-id="`create-sms-action-filter-${item}`"
                >
                    {{ item }}
                </md-option>
            </md-select>
        </form-group>
        <form-group v-if="showStopTypeFilter" name="filter.stopTypes" label="Stop Type(s)">
            <md-select id="filter-stop-type-select" multiple v-model="customActionModel.filter.stopTypes">
                <md-option
                    v-for="item in stopTypeFilters"
                    :key="item.value"
                    :value="item.value"
                    :data-test-id="`create-sms-action-stop-type-filter-${item.value}`"
                >
                    {{ item.text }}
                </md-option>
            </md-select>
        </form-group>
        <form-group name="settings.smsRecipientTypes" label="Recipient(s)">
            <md-select id="smsRecipientTypes" multiple v-model="customActionModel.settings.smsRecipientTypes">
                <md-option
                    v-for="item in smsRecipientOptions"
                    :key="item.value"
                    :value="item.value"
                    :data-test-id="`create-sms-action-recipient-types-${item.value}`"
                >
                    {{ item.text }}
                </md-option>
            </md-select>
        </form-group>
        <form-group
            v-if="
                customActionModel.settings.smsRecipientTypes &&
                    customActionModel.settings.smsRecipientTypes.includes('Specified')
            "
            name="settings.recipientSmsNumbers"
            label="Sms number(s)"
        >
            <md-input id="recipient-numbers-input" v-model="customActionModel.settings.recipientSmsNumbers" />
        </form-group>
        <form-group name="settings.timeDelay.type" label="Time Delay">
            <TimeDelayType :value="customActionModel.settings.timeDelay.type" @changed="handleTimeDelayTypeChanged" />
        </form-group>
        <form-group
            class="form-group-no-underline"
            v-if="customActionModel.settings.timeDelay.type === 'Delayed'"
            name="settings.timeDelay.delay"
        >
            <TimeSpanInput :value="customActionModel.settings.timeDelay.delay" @changed="handleTimeDelayChanged" />
        </form-group>
        <form-group
            v-if="customActionModel.settings.timeDelay.type === 'Specified'"
            name="settings.timeDelay.specificTime"
            label="Time"
        >
            <TimePicker
                :time="customActionModel.settings.timeDelay.specificTime"
                :all-time-options="false"
                :clear-none="true"
                @selectedTime="getStartTime"
            />
        </form-group>
        <form-group name="settings.skipWhenRecipientHasEmail" class="no-underline">
            <md-checkbox v-model="customActionModel.settings.skipWhenRecipientHasEmail">
                Skip Sms when recipient has an email.
            </md-checkbox>
        </form-group>
        <CustomConditionBuilderList
            v-if="conditionVariables.length"
            :custom-action-model="customActionModel"
            :validator="validator"
            :condition-variables="conditionVariables"
        />
        <form-group class="custom-merge-field">
            <drop-down v-show="showMergeFields">
                <md-button @click.prevent slot="title" class="dropdown-toggle md-info" data-toggle="dropdown">
                    Insert Merge Field
                </md-button>
                <ul class="dropdown-menu dropdown-menu-right dropdown-menu-height">
                    <li v-for="event in mergeFieldsOptions" :key="event.key">
                        <span
                            v-if="event.key === 'stopcustomfields' || event.key === 'shipmentcustomfields'"
                            class="custom-field-header"
                        >
                            {{ event.name }}
                        </span>
                        <a v-else @click="insertMergeField(event.key)">
                            {{ event.name }}
                        </a>
                    </li>
                </ul>
            </drop-down>
        </form-group>
        <form-group name="settings.smsTemplateContent" class="custom-textarea">
            <span class="custom-label">Template</span>
            <md-textarea v-model="customActionModel.settings.smsTemplateContent" ref="template" md-counter="160" />
        </form-group>
        <md-icon class="custom-sms-over-limit-warning-icon">
            warning
            <md-tooltip>{{ customSmsOverLimitTooltip }}</md-tooltip>
        </md-icon>
        <div class="custom-sms-over-limit-text" v-if="isCustomSmsOverLimit">
            Your SMS will be sent across multiple segments and could impact your SMS usage costs. Please reduce your
            character count to 160 or less.
        </div>
        <br v-if="!isCustomSmsOverLimit" />
    </div>
</template>

<script>
import {
    TEAM_CUSTOM_ACTION_EVENT_TRIGGERS,
    TEAM_CUSTOM_ACTION_SMS_RECIPIENT_OPTIONS,
    TEAM_CUSTOM_ACTION_TYPE_OPTIONS,
    TEAM_CUSTOM_ACTION_STOP_TYPE_OPTIONS
} from '@/utils/CustomActionConstants';
import {
    INSERT_MERGE_FIELDS_CONSTANTS,
    INSERT_SHIPMENT_MERGE_FIELDS_CONSTANTS,
    INSERT_ORDER_MERGE_FIELDS_CONSTANTS
} from '@/utils/constants';
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { TimePicker } from '@/components';
import TimeSpanInput from '@/components/TimeSpanInput';
import TimeDelayType from '../components/TimeDelayType';
import CustomConditionBuilderList from '../components/CustomConditionBuilderList';

export default {
    name: 'ManageSmsAction',
    mixins: [GeneralMixin],
    props: {
        customActionModel: { type: Object, default: () => null },
        validator: { type: Object, required: true },
        isUpdate: { type: Boolean, default: false },
        conditionVariables: { type: Array, default: () => [] }
    },
    components: { TimePicker, TimeSpanInput, TimeDelayType, CustomConditionBuilderList },
    data() {
        return {
            action: 'Sms',
            eventTriggers: TEAM_CUSTOM_ACTION_EVENT_TRIGGERS,
            selectedEventTrigger: null,
            actionTypeOptions: TEAM_CUSTOM_ACTION_TYPE_OPTIONS,
            stopTypeOptions: TEAM_CUSTOM_ACTION_STOP_TYPE_OPTIONS,
            nonWebhookStatusFilter: null,
            initialUseSmsSystemTemplate: true,
            mergeFields: [],
            stopMergeFieldsConstants: [...INSERT_MERGE_FIELDS_CONSTANTS],
            orderMergeFieldConstants: [...INSERT_ORDER_MERGE_FIELDS_CONSTANTS],
            stopCustomMergeFields: [],
            shipmentMergeFieldsContants: [...INSERT_SHIPMENT_MERGE_FIELDS_CONSTANTS],
            shipmentCustomMergeFields: [],
            timeDelayValue: null,
            customSmsOverLimitTooltip:
                'Note: Using merge fields will affect the SMS character count (e.g. If you use the {{Address}} merge field and a stop/shipment address has 60 characters, then your SMS character count will be down to 100)'
        };
    },
    computed: {
        filteredEventTriggers() {
            return this.eventTriggers
                .filter((x) => x.supportedActions.includes(this.action))
                .sort((a, b) => (a.displayText > b.displayText ? 1 : -1));
        },
        currentEventTrigger() {
            if (!this.customActionModel.eventTrigger) return null;

            return this.eventTriggers.find((x) => x.eventTrigger === this.customActionModel.eventTrigger);
        },
        filters() {
            if (
                this.currentEventTrigger &&
                this.currentEventTrigger.smsConfig &&
                this.currentEventTrigger.smsConfig.filters &&
                this.currentEventTrigger.smsConfig.filters.statuses
            ) {
                return [...this.currentEventTrigger.smsConfig.filters.statuses];
            }

            return [];
        },
        stopTypeFilters() {
            if (this.currentEventTrigger && this.currentEventTrigger.smsConfig?.hasStopType) {
                return this.stopTypeOptions;
            }

            return [];
        },
        showStopTypeFilter() {
            return (
                this.stopTypeFilters.length &&
                this.currentEventTrigger &&
                this.currentEventTrigger.smsConfig &&
                !this.currentEventTrigger.smsConfig.hideStopTypeFilter
            );
        },
        smsRecipientOptions() {
            if (!this.currentEventTrigger) return [];

            if (this.currentEventTrigger.smsConfig?.limitRecipients) {
                return TEAM_CUSTOM_ACTION_SMS_RECIPIENT_OPTIONS.filter((x) =>
                    this.currentEventTrigger.smsConfig.limitRecipients.includes(x.value)
                );
            }

            // if no recipient defined, means all is available
            return TEAM_CUSTOM_ACTION_SMS_RECIPIENT_OPTIONS;
        },
        mergeFieldsOptions() {
            // Note: The logic to show/hide the OTP merge field was not migrated from the original Sms implementation into Custom Action (Sms)
            // because the latest PoD/OTP settings is now on a per-customer basis
            // but Sms is still applicable to all customers

            if (this.currentEventTrigger && this.currentEventTrigger.smsConfig?.hasStopType) {
                // special handling - Stop Type filter is hidden but need to show all merge fields
                if (
                    this.currentEventTrigger.eventTrigger === 'TRIP-STATUS-CHANGED' ||
                    this.currentEventTrigger.eventTrigger === 'SHIPMENT-LINK-CREATED'
                ) {
                    return this.stopMergeFieldsConstants
                        .concat(this.shipmentMergeFieldsContants)
                        .concat(this.stopCustomMergeFields)
                        .concat(this.shipmentCustomMergeFields);
                }
                // show merge fields based on selected Stop Type
                if (this.customActionModel.filter.stopTypes && this.customActionModel.filter.stopTypes.length) {
                    if (!this.customActionModel.filter.stopTypes.includes('No Stop Type')) {
                        return this.stopMergeFieldsConstants
                            .concat(this.shipmentMergeFieldsContants)
                            .concat(this.stopCustomMergeFields)
                            .concat(this.shipmentCustomMergeFields);
                    }

                    return this.stopMergeFieldsConstants.concat(this.stopCustomMergeFields);
                }
            }

            if (this.currentEventTrigger && this.currentEventTrigger.eventTrigger === 'ORDER-EVENT-MANUAL-TRIGGERED') {
                return this.orderMergeFieldConstants;
            }

            return [];
        },
        showMergeFields() {
            return this.mergeFieldsOptions.length;
        },
        isCustomSmsOverLimit() {
            if (!this.customActionModel?.settings?.smsTemplateContent) return false;
            return this.customActionModel.settings.smsTemplateContent.length > 160;
        }
    },
    watch: {
        'customActionModel.eventTrigger': function(newVal, oldVal) {
            if (oldVal) {
                this.nonWebhookStatusFilter = null;
                this.customActionModel.settings.smsRecipientTypes = [];
            }
            if (!newVal || !this.showStopTypeFilter) {
                this.customActionModel.filter = {
                    ...this.customActionModel.filter,
                    stopTypes: []
                };
            }
        },
        nonWebhookStatusFilter(newVal) {
            this.customActionModel.filter.status = newVal ? [newVal] : [];
        },
        'customActionModel.settings.smsRecipientTypes': function(newVal) {
            if (!newVal || !newVal.includes('Specified')) {
                this.customActionModel.settings.recipientSmsNumbers = null;
            }
        },
        'customActionModel.settings.timeDelay.type': function(newVal, oldVal) {
            if (!newVal || newVal === 'Immediate') {
                this.customActionModel.settings.timeDelay.delay = null;
                this.customActionModel.settings.timeDelay.specificTime = null;
            }

            if (newVal) {
                if (newVal === 'Delayed') {
                    this.customActionModel.settings.timeDelay.specificTime = null;
                }

                if (newVal === 'Specified') {
                    this.customActionModel.settings.timeDelay.delay = null;
                }
            }
        }
    },
    methods: {
        insertMergeField(value) {
            const textarea = this.$refs.template.$el;

            if (value !== 'default') {
                if (!this.customActionModel.settings.smsTemplateContent) {
                    this.customActionModel.settings.smsTemplateContent = '';
                }
                const temp = this.customActionModel.settings.smsTemplateContent || '';
                const currentCursorPosition = textarea.selectionStart;
                this.customActionModel.settings.smsTemplateContent = `${temp.substring(
                    0,
                    textarea.selectionStart
                )}{{${value}}}${temp.substring(textarea.selectionEnd, temp.length)}`;

                this.$nextTick(() => {
                    textarea.focus();
                    // The additional + 4 is because we are adding {{}} to the merge field value upon insert.
                    const cursorfocusPos = currentCursorPosition + value.length + 4;
                    textarea.setSelectionRange(cursorfocusPos, cursorfocusPos);
                });
            }
        },
        async getStopCustomFields() {
            const api = '/api/teams/custom-fields/stops';
            await handleRequests(api).then((response) => {
                if (response.data.length > 0) {
                    this.stopCustomMergeFields.push({
                        name: 'Stop Custom Fields',
                        key: 'stopcustomfields'
                    });
                    response.data.forEach((field) => {
                        this.stopCustomMergeFields.push({
                            name: field.label,
                            key: field.name
                        });
                    });
                }
            });
        },
        async getShipmentCustomFields() {
            const api = '/api/teams/custom-fields/shipments';
            await handleRequests(api).then((response) => {
                if (response.data.length > 0) {
                    this.shipmentCustomMergeFields.push({
                        name: 'Shipment Custom Fields',
                        key: 'shipmentcustomfields'
                    });
                    response.data.forEach((field) => {
                        this.shipmentCustomMergeFields.push({
                            name: field.label,
                            key: `Shipment.${field.name}`
                        });
                    });
                }
            });
        },
        getStartTime(selectedTime) {
            if (selectedTime) {
                const { appointmentTime } = selectedTime;
                this.customActionModel.settings.timeDelay.specificTime = appointmentTime;
            } else {
                this.customActionModel.settings.timeDelay.specificTime = null;
            }
        },
        async handleTimeDelayChanged(args) {
            this.customActionModel.settings.timeDelay.delay = args.formatted;
            this.timeDelayValue = args;
        },
        async handleTimeDelayTypeChanged(delayType) {
            this.customActionModel.settings.timeDelay.type = delayType;
        },
        showTemplateName(eventTrigger) {
            return (
                eventTrigger &&
                (eventTrigger === 'STOP-EVENT-MANUAL-TRIGGERED' || eventTrigger === 'ORDER-EVENT-MANUAL-TRIGGERED')
            );
        }
    },
    mounted() {
        // if there's a custom template id from backend, provide an option to use it or use the system template
        // but if there's no template id, force user to use the system template
        this.initialUseSmsSystemTemplate =
            !this.customActionModel.settings.smsTemplateId || !this.customActionModel.settings.smsTemplateId.length;

        /* eslint-disable */
        if (this.isUpdate) {
            const { status } = this.customActionModel.filter;
            if (status.length) {
                this.nonWebhookStatusFilter = status[0];
            }
        }
        /* eslint-enable */
        this.getStopCustomFields();
        this.getShipmentCustomFields();
    }
};
</script>

<style scoped lang="scss">
.modal-container {
    max-width: 620px;
}

.custom-merge-field {
    z-index: 99;
    justify-content: flex-end;
    margin: 15px 0 0 auto;
    width: 188px;
    float: right;

    ::v-deep label {
        top: -5px !important;
        font-size: 0.6875rem;
    }

    .dropdown {
        margin-left: 0;
        margin-top: 12px;

        .md-button {
            background-color: #00bcd4 !important;
            margin: 0;

            ::v-deep .md-button-content {
                color: #fff !important;
            }

            ::v-deep .md-ripple:after {
                color: #fff !important;
            }
        }
    }

    .dropdown-menu-height {
        max-height: 400px;
        overflow-y: scroll;
    }

    .custom-field-header {
        padding-left: 10px;
        font-weight: bold;
    }
}

.custom-merge-field:after,
.custom-merge-field:before {
    height: 0;
}

.form-group-no-underline:before {
    height: 0;
}

::v-deep .md-checkbox-container {
    top: 10px;
}

::v-deep .md-checkbox-label {
    opacity: 1 !important;
    color: black !important;
}

::v-deep div.md-field.no-underline:after {
    height: 0px;
}

::v-deep .md-checkbox {
    margin-top: 0px;
}

.form-group-no-underline:after {
    height: 0;
}

.form-group-no-underline {
    margin-top: 0;

    label {
        display: none;
    }
}

.custom-textarea {
    margin-top: 5px !important;
    display: block;

    textarea {
        width: 100%;
        min-height: 250px !important;
    }
}

.custom-label {
    display: block;
}

.custom-sms-over-limit-text {
    margin-top: 13px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.54);
}

::v-deep .md-count {
    right: 36px !important;
}

.custom-sms-over-limit-warning-icon {
    top: -24px;
    right: 8px;
    float: right;
    position: relative;
}
</style>
