var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("inventory-filter-component", {
        class: _vm.$root.isTablet ? "tablet-filter-container" : "",
        attrs: {
          "team-members": _vm.teamMembers,
          warehouses: _vm.warehouses,
          "item-locations": _vm.itemLocations,
        },
        on: {
          onFilterStops: _vm.handleFilterOrSearch,
          onExportClicked: _vm.handleExportClick,
          onItemAdded: _vm.handleItemAdded,
        },
      }),
      _c(
        "div",
        { staticClass: "md-layout" },
        [
          _c(
            "div",
            { staticClass: "md-layout-item" },
            [
              _c(
                "div",
                { staticClass: "error-message-container" },
                [
                  _c("batch-upload-error-message", {
                    attrs: {
                      content: _vm.errorMessages,
                      "has-error-message": _vm.hasError,
                    },
                  }),
                ],
                1
              ),
              _c(
                "md-card",
                [
                  _c(
                    "md-card-header",
                    {
                      staticClass: "md-card-header-icon md-card-header-warning",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-icon" },
                        [_c("md-icon", [_vm._v("inventory")])],
                        1
                      ),
                    ]
                  ),
                  _c("md-card-content", { staticClass: "body-list" }, [
                    !_vm.isListLoading
                      ? _c(
                          "div",
                          { key: _vm.updateList },
                          [
                            _c(
                              "md-table",
                              {
                                staticClass:
                                  "context-menu-support custom-paginated-table",
                              },
                              [
                                _c(
                                  "md-table-row",
                                  [
                                    _c("md-table-head", [_vm._v("Barcode")]),
                                    _c("md-table-head", [
                                      _vm._v("Description"),
                                    ]),
                                    _c("md-table-head", [
                                      _vm._v("Order Number"),
                                    ]),
                                    _c("md-table-head", [
                                      _vm._v("Current Location"),
                                    ]),
                                    _c("md-table-head", [_vm._v("Status")]),
                                    _vm.$root.isDesktop && !_vm.isReadOnlyUser
                                      ? _c("md-table-head", [_vm._v("Actions")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._l(_vm.inventoryList, function (item) {
                                  return _c(
                                    "md-table-row",
                                    {
                                      key: item.itemId,
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.showSidebar(item.itemId)
                                        },
                                      },
                                    },
                                    [
                                      _c("md-table-cell", [
                                        _vm._v(_vm._s(item.barcode)),
                                      ]),
                                      _c("md-table-cell", [
                                        _vm._v(_vm._s(item.description)),
                                      ]),
                                      _c(
                                        "md-table-cell",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "Orders",
                                                  params: {
                                                    orderId: item.orderId,
                                                  },
                                                },
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(item.orderNumber) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getLocationStatus(
                                              item.currentLocation
                                            )
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "md-table-cell",
                                        { staticClass: "status-container" },
                                        [
                                          _c("inventory-status-button", {
                                            attrs: {
                                              "item-status": item.itemStatus,
                                              "item-id": item.itemId,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.$root.isDesktop && !_vm.isReadOnlyUser
                                        ? _c(
                                            "md-table-cell",
                                            { staticClass: "action-buttons" },
                                            [
                                              !_vm.isReadOnlyUser
                                                ? _c("update-item-button", {
                                                    attrs: {
                                                      item: item,
                                                      "is-delivered":
                                                        item.itemStatus ===
                                                        "Delivered",
                                                      "stop-status":
                                                        item.stopStatus,
                                                      "shipment-status":
                                                        item.shipmentStatus,
                                                    },
                                                    on: {
                                                      itemUpdated:
                                                        _vm.handleUpdateItem,
                                                    },
                                                  })
                                                : _vm._e(),
                                              !_vm.isReadOnlyUser
                                                ? _c("delete-item-button", {
                                                    attrs: {
                                                      "item-id": item.itemId,
                                                      "is-shipment-attached":
                                                        item.shipmentId !==
                                                        null,
                                                      "is-stop-attached":
                                                        item.stopId !== null,
                                                      "is-order-attached":
                                                        item.orderId !== null,
                                                    },
                                                    on: {
                                                      itemDeleted:
                                                        _vm.handleDeletedItem,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                            _vm.inventoryList.length == 0
                              ? _c("div", [
                                  _c(
                                    "p",
                                    { staticClass: "no-result-message" },
                                    [
                                      _vm._v(
                                        "No results matching your search/filter could be found."
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c("div", [
                          _c(
                            "div",
                            { staticClass: "loader" },
                            [
                              _c("fade-loader", {
                                attrs: { loading: true, color: "#333333" },
                              }),
                              _c("span", [_vm._v("LOADING")]),
                            ],
                            1
                          ),
                        ]),
                  ]),
                ],
                1
              ),
              _c(
                "md-card-actions",
                {
                  staticClass: "page-footer",
                  attrs: { "md-alignment": "space-between" },
                },
                [
                  _c("div", [
                    _vm.total === _vm.pagination.perPage
                      ? _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            "\n                        Page " +
                              _vm._s(_vm.pagination.currentPage) +
                              " of many\n                    "
                          ),
                        ])
                      : _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            "Page " +
                              _vm._s(_vm.pagination.currentPage) +
                              " of " +
                              _vm._s(_vm.totalPages)
                          ),
                        ]),
                  ]),
                  _c("pagination", {
                    staticClass: "pagination-no-border pagination-success",
                    attrs: {
                      "per-page": _vm.pagination.perPage,
                      total: _vm.total,
                    },
                    on: {
                      "change-page": function ($event) {
                        return _vm.handleChangePage(
                          $event,
                          _vm.pagination.perPage
                        )
                      },
                    },
                    model: {
                      value: _vm.pagination.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.pagination, "currentPage", $$v)
                      },
                      expression: "pagination.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "slide" } },
            [
              _vm.isShowSidebar
                ? _c("inventory-sidebar", {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.toggleSidebarWindow,
                        expression: "toggleSidebarWindow",
                      },
                    ],
                    attrs: {
                      "item-id": _vm.itemId,
                      warehouses: _vm.warehouses,
                      "team-members": _vm.teamMembers,
                    },
                    on: {
                      closeModal: _vm.toggleSidebarWindow,
                      itemUpdated: function (p) {
                        return _vm.itemUpdated(p)
                      },
                      itemDeleted: _vm.handleDeletedItem,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }