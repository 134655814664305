var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-field",
    [
      _vm.customerList.length > 0
        ? _c("vue-select", {
            attrs: {
              "data-testid": "customer-filter",
              options: _vm.customerList,
              reduce: function (cl) {
                return cl.customerId
              },
              label: "name",
              placeholder: "Customer",
              searchable: _vm.$root.isDesktop,
            },
            model: {
              value: _vm.customerFilterValue,
              callback: function ($$v) {
                _vm.customerFilterValue = $$v
              },
              expression: "customerFilterValue",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }