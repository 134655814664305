<template>
    <div class="map-overlay">
        <div class="custom-toolbar">
            <router-link
                :to="{
                    name: 'Team Settings'
                }"
            >
                <h3 class="title">Team Settings</h3>
            </router-link>
            <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.serviceAreas') }}</h3>
        </div>
        <div class="service-area-main">
            <div class="service-area-panel">
                <ServiceAreaLayer
                    :map="map"
                    :drawing-manager="drawingManager"
                    :title="'Service area'"
                    :id-name="'serviceAreaId'"
                    :end-point-name="'/api/teams/service-areas'"
                />
            </div>
            <div
                id="map_canvas"
                ref="googleMap"
                :class="[isTrialExpired ? 'not-paid-map-overlay' : '', 'google-map']"
            />
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import { MapOverviewMixin } from '@/mixins/MapOverviewMixin';

import ServiceAreaLayer from '@/components/ServiceAreaLayer';

export default {
    name: 'ServiceAreasOverview',
    mixins: [GeneralMixin, MapOverviewMixin],
    components: {
        ServiceAreaLayer
    },
    data() {
        return {
            map: null,
            drawingManager: null,
            longerPanelWidth: false
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isTrialExpired: 'user/isTrialExpired'
        })
    },
    methods: {
        initializeMap() {
            // get starting location of user. if there is none, default to Australia
            const { latitude, longitude } = this.user.startLocation
                ? this.user.startLocation
                : getRegionCoordinates('AU');

            const element = this.$refs.googleMap;

            this.map = this.$_map_createMap(element, latitude, longitude);
            this.drawingManager = this.$_map_drawingManager();
            this.drawingManager.setMap(this.map);
            this.drawingManager.setOptions({
                drawingControl: false
            });
        }
    },
    async mounted() {
        await this.$_map_loadGoogleMap();
        this.initializeMap();
    }
};
</script>

<style lang="scss" scoped>
// remove padding from content because it's already overridden in the DashboardLayout if the component rendered is a map (via $route.meta.isMap)
.map-overlay {
    position: relative;
    padding: 0;
    .service-area-main {
        width: 100%;
        position: relative;
    }
    .google-map {
        height: 100vh !important;
    }
    .service-area-panel {
        height: 100vh;
        background: #fff;
        float: left;
    }

    .custom-toolbar {
        position: absolute;
        z-index: 1000;
        top: 20px;
        left: 15px;
        background: #fff;

        > div {
            padding: 5px;
        }

        .title {
            font-size: 14px;
            font-weight: 500;
            margin-top: 10px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    .not-paid-map-overlay {
        width: 100%;
        height: calc(100vh - 60px);

        > div {
            bottom: 0;
        }
    }

    .gmnoprint-tool {
        z-index: 6;
        position: absolute;
        left: 120px;
        top: 110px;
    }
}
</style>
