var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile-container" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c(
            "div",
            { staticClass: "custom-toolbar-start" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "Frequent Addresses",
                    },
                  },
                },
                [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("Frequent Addresses"),
                  ]),
                ]
              ),
              _c("h3", { staticClass: "title" }, [
                _vm._v("   >    " + _vm._s(_vm.frequentAddress.contactName)),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-green" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("library_books")])],
                    1
                  ),
                ]
              ),
              _c("md-card-content", [
                _c("div", { staticClass: "cf-container" }, [
                  _c(
                    "div",
                    { staticClass: "cf-action-container" },
                    [
                      !_vm.editMode &&
                      _vm.user.role.toLowerCase() !== "customer"
                        ? _c(
                            "md-button",
                            {
                              staticClass: "md-primary md-just-icon md-round",
                              attrs: { title: "Edit Frequent Address" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.toggleEdit()
                                },
                              },
                            },
                            [_c("md-icon", [_vm._v("edit")])],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "frequentAddress-information field-spacing-container",
                    },
                    [
                      _c(
                        "form-wrapper",
                        {
                          staticClass: "form-wrapper",
                          attrs: { validator: _vm.$v.frequentAddress },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "information-box" },
                            [
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "addressCode",
                                          label: "Address Code",
                                        },
                                      },
                                      [
                                        _c("md-input", {
                                          attrs: { disabled: !_vm.editMode },
                                          model: {
                                            value:
                                              _vm.frequentAddress.addressCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.frequentAddress,
                                                "addressCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "frequentAddress.addressCode",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    !_vm.editMode
                                      ? _c(
                                          "form-group",
                                          {
                                            attrs: {
                                              name: "customer",
                                              label: "Customer",
                                            },
                                          },
                                          [
                                            _c("md-input", {
                                              attrs: {
                                                disabled:
                                                  !_vm.editMode ||
                                                  _vm.readOnlyCustomer,
                                              },
                                              model: {
                                                value: _vm.savedCustomer.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.savedCustomer,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "savedCustomer.name",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.editMode
                                      ? _c("customer-autocomplete", {
                                          attrs: {
                                            label: "Customer",
                                            id: "autocustomer",
                                            placeholder: "",
                                            "should-focus": true,
                                            "update-customer": true,
                                          },
                                          on: {
                                            change:
                                              _vm.handleCustomerFieldChange,
                                          },
                                          model: {
                                            value: _vm.customer,
                                            callback: function ($$v) {
                                              _vm.customer = $$v
                                            },
                                            expression: "customer",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "contactName",
                                          label: "Contact Name",
                                          messages: {
                                            required:
                                              "Contact Name is required.",
                                          },
                                        },
                                      },
                                      [
                                        _c("md-input", {
                                          attrs: { disabled: !_vm.editMode },
                                          model: {
                                            value:
                                              _vm.frequentAddress.contactName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.frequentAddress,
                                                "contactName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "frequentAddress.contactName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "phone",
                                          label: "Contact Number",
                                          messages: {
                                            required:
                                              "Contact Number is required.",
                                          },
                                        },
                                      },
                                      [
                                        _c("md-input", {
                                          attrs: { disabled: !_vm.editMode },
                                          model: {
                                            value: _vm.frequentAddress.phone,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.frequentAddress,
                                                "phone",
                                                $$v
                                              )
                                            },
                                            expression: "frequentAddress.phone",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "email",
                                          label: "Email",
                                          messages: {
                                            required: "Email is required.",
                                          },
                                        },
                                      },
                                      [
                                        _c("md-input", {
                                          attrs: { disabled: !_vm.editMode },
                                          model: {
                                            value: _vm.frequentAddress.email,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.frequentAddress,
                                                "email",
                                                $$v
                                              )
                                            },
                                            expression: "frequentAddress.email",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c("google-autocomplete", {
                                      ref: "address",
                                      attrs: {
                                        label: "Destination",
                                        id: "Address",
                                        classname:
                                          "form-control autocomplete-input",
                                        placeholder: "",
                                        "should-focus": false,
                                        "disable-gps-coordinates": true,
                                        disabled: !_vm.editMode,
                                      },
                                      on: { change: _vm.handleAddressChanged },
                                      model: {
                                        value: _vm.autocompleteAddress,
                                        callback: function ($$v) {
                                          _vm.autocompleteAddress = $$v
                                        },
                                        expression: "autocompleteAddress",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "notes",
                                          label: "Notes",
                                        },
                                      },
                                      [
                                        _c("md-input", {
                                          attrs: { disabled: !_vm.editMode },
                                          model: {
                                            value: _vm.frequentAddress.notes,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.frequentAddress,
                                                "notes",
                                                $$v
                                              )
                                            },
                                            expression: "frequentAddress.notes",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm.customFieldList !== null
                                ? _c("div", { staticClass: "md-layout" }, [
                                    _c(
                                      "div",
                                      { staticClass: "md-layout-item" },
                                      [
                                        _c(
                                          "form-group",
                                          {
                                            staticClass: "time-picker",
                                            attrs: { name: "time", label: "" },
                                          },
                                          [
                                            _c("time-picker", {
                                              attrs: {
                                                disabled: !_vm.editMode,
                                                time: _vm.time,
                                                "time-window-only": true,
                                                "enable-auto-scroll": true,
                                                "additional-time-options":
                                                  _vm.additionalTimeOption,
                                              },
                                              on: { selectedTime: _vm.getTime },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "md-layout-item" },
                                      [
                                        _c(
                                          "form-group",
                                          {
                                            attrs: {
                                              name: "durationMinutes",
                                              label: "Duration (mins)",
                                            },
                                          },
                                          [
                                            _c("md-input", {
                                              attrs: {
                                                type: "number",
                                                disabled: !_vm.editMode,
                                              },
                                              model: {
                                                value:
                                                  _vm.frequentAddress
                                                    .durationMinutes,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.frequentAddress,
                                                    "durationMinutes",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "frequentAddress.durationMinutes",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.customFieldList !== null
                                ? _c("div", { staticClass: "md-layout" }, [
                                    _c(
                                      "div",
                                      { staticClass: "md-layout-item" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "fa-header" },
                                          [_vm._v("Stop Custom Fields")]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._l(
                                _vm.customFieldList,
                                function (customFields, index) {
                                  return _vm.customFieldList !== null
                                    ? _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "md-layout",
                                        },
                                        _vm._l(
                                          customFields,
                                          function (customField, index2) {
                                            return _c(
                                              "div",
                                              {
                                                key: index2,
                                                staticClass: "md-layout-item",
                                              },
                                              [
                                                _c("custom-field-inputs", {
                                                  attrs: {
                                                    "custom-field-definition":
                                                      customField,
                                                    "initial-value":
                                                      _vm.customFieldValues[
                                                        customField.name
                                                      ],
                                                    disabled: !_vm.editMode,
                                                    "show-label": true,
                                                  },
                                                  on: {
                                                    changed:
                                                      _vm.handleCustomFieldChanged,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e()
                                }
                              ),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "form-actions" },
                            [
                              _vm.editMode
                                ? _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-secondary dialog-button pull-right",
                                      on: { click: _vm.cancelUpdate },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Cancel\n                                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.editMode
                                ? _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-primary dialog-button pull-right",
                                      on: {
                                        click: _vm.actionButtonFrequentAddress,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.isUpdate ? "Update" : "Add"
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }