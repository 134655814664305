var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showTemplateName(_vm.customActionModel.eventTrigger)
        ? _c(
            "form-group",
            { attrs: { label: "Name", name: "settings.templateName" } },
            [
              _c("md-input", {
                model: {
                  value: _vm.customActionModel.settings.templateName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.customActionModel.settings,
                      "templateName",
                      $$v
                    )
                  },
                  expression: "customActionModel.settings.templateName",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.filters && _vm.filters.length > 0
        ? _c(
            "form-group",
            { attrs: { name: "filter.status", label: "Filter" } },
            [
              _c(
                "md-select",
                {
                  attrs: { id: "filter-status-select" },
                  model: {
                    value: _vm.nonWebhookStatusFilter,
                    callback: function ($$v) {
                      _vm.nonWebhookStatusFilter = $$v
                    },
                    expression: "nonWebhookStatusFilter",
                  },
                },
                _vm._l(_vm.filters, function (item) {
                  return _c(
                    "md-option",
                    {
                      key: item,
                      attrs: {
                        value: item,
                        "data-test-id": "create-sms-action-filter-" + item,
                      },
                    },
                    [
                      _vm._v(
                        "\n                " + _vm._s(item) + "\n            "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showStopTypeFilter
        ? _c(
            "form-group",
            { attrs: { name: "filter.stopTypes", label: "Stop Type(s)" } },
            [
              _c(
                "md-select",
                {
                  attrs: { id: "filter-stop-type-select", multiple: "" },
                  model: {
                    value: _vm.customActionModel.filter.stopTypes,
                    callback: function ($$v) {
                      _vm.$set(_vm.customActionModel.filter, "stopTypes", $$v)
                    },
                    expression: "customActionModel.filter.stopTypes",
                  },
                },
                _vm._l(_vm.stopTypeFilters, function (item) {
                  return _c(
                    "md-option",
                    {
                      key: item.value,
                      attrs: {
                        value: item.value,
                        "data-test-id":
                          "create-sms-action-stop-type-filter-" + item.value,
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.text) +
                          "\n            "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "form-group",
        {
          attrs: { name: "settings.smsRecipientTypes", label: "Recipient(s)" },
        },
        [
          _c(
            "md-select",
            {
              attrs: { id: "smsRecipientTypes", multiple: "" },
              model: {
                value: _vm.customActionModel.settings.smsRecipientTypes,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.customActionModel.settings,
                    "smsRecipientTypes",
                    $$v
                  )
                },
                expression: "customActionModel.settings.smsRecipientTypes",
              },
            },
            _vm._l(_vm.smsRecipientOptions, function (item) {
              return _c(
                "md-option",
                {
                  key: item.value,
                  attrs: {
                    value: item.value,
                    "data-test-id":
                      "create-sms-action-recipient-types-" + item.value,
                  },
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(item.text) + "\n            "
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm.customActionModel.settings.smsRecipientTypes &&
      _vm.customActionModel.settings.smsRecipientTypes.includes("Specified")
        ? _c(
            "form-group",
            {
              attrs: {
                name: "settings.recipientSmsNumbers",
                label: "Sms number(s)",
              },
            },
            [
              _c("md-input", {
                attrs: { id: "recipient-numbers-input" },
                model: {
                  value: _vm.customActionModel.settings.recipientSmsNumbers,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.customActionModel.settings,
                      "recipientSmsNumbers",
                      $$v
                    )
                  },
                  expression: "customActionModel.settings.recipientSmsNumbers",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "form-group",
        { attrs: { name: "settings.timeDelay.type", label: "Time Delay" } },
        [
          _c("TimeDelayType", {
            attrs: { value: _vm.customActionModel.settings.timeDelay.type },
            on: { changed: _vm.handleTimeDelayTypeChanged },
          }),
        ],
        1
      ),
      _vm.customActionModel.settings.timeDelay.type === "Delayed"
        ? _c(
            "form-group",
            {
              staticClass: "form-group-no-underline",
              attrs: { name: "settings.timeDelay.delay" },
            },
            [
              _c("TimeSpanInput", {
                attrs: {
                  value: _vm.customActionModel.settings.timeDelay.delay,
                },
                on: { changed: _vm.handleTimeDelayChanged },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.customActionModel.settings.timeDelay.type === "Specified"
        ? _c(
            "form-group",
            {
              attrs: { name: "settings.timeDelay.specificTime", label: "Time" },
            },
            [
              _c("TimePicker", {
                attrs: {
                  time: _vm.customActionModel.settings.timeDelay.specificTime,
                  "all-time-options": false,
                  "clear-none": true,
                },
                on: { selectedTime: _vm.getStartTime },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "form-group",
        {
          staticClass: "no-underline",
          attrs: { name: "settings.skipWhenRecipientHasEmail" },
        },
        [
          _c(
            "md-checkbox",
            {
              model: {
                value: _vm.customActionModel.settings.skipWhenRecipientHasEmail,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.customActionModel.settings,
                    "skipWhenRecipientHasEmail",
                    $$v
                  )
                },
                expression:
                  "customActionModel.settings.skipWhenRecipientHasEmail",
              },
            },
            [
              _vm._v(
                "\n            Skip Sms when recipient has an email.\n        "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.conditionVariables.length
        ? _c("CustomConditionBuilderList", {
            attrs: {
              "custom-action-model": _vm.customActionModel,
              validator: _vm.validator,
              "condition-variables": _vm.conditionVariables,
            },
          })
        : _vm._e(),
      _c(
        "form-group",
        { staticClass: "custom-merge-field" },
        [
          _c(
            "drop-down",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMergeFields,
                  expression: "showMergeFields",
                },
              ],
            },
            [
              _c(
                "md-button",
                {
                  staticClass: "dropdown-toggle md-info",
                  attrs: { slot: "title", "data-toggle": "dropdown" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                    },
                  },
                  slot: "title",
                },
                [_vm._v("\n                Insert Merge Field\n            ")]
              ),
              _c(
                "ul",
                {
                  staticClass:
                    "dropdown-menu dropdown-menu-right dropdown-menu-height",
                },
                _vm._l(_vm.mergeFieldsOptions, function (event) {
                  return _c("li", { key: event.key }, [
                    event.key === "stopcustomfields" ||
                    event.key === "shipmentcustomfields"
                      ? _c("span", { staticClass: "custom-field-header" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(event.name) +
                              "\n                    "
                          ),
                        ])
                      : _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insertMergeField(event.key)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(event.name) +
                                "\n                    "
                            ),
                          ]
                        ),
                  ])
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "form-group",
        {
          staticClass: "custom-textarea",
          attrs: { name: "settings.smsTemplateContent" },
        },
        [
          _c("span", { staticClass: "custom-label" }, [_vm._v("Template")]),
          _c("md-textarea", {
            ref: "template",
            attrs: { "md-counter": "160" },
            model: {
              value: _vm.customActionModel.settings.smsTemplateContent,
              callback: function ($$v) {
                _vm.$set(
                  _vm.customActionModel.settings,
                  "smsTemplateContent",
                  $$v
                )
              },
              expression: "customActionModel.settings.smsTemplateContent",
            },
          }),
        ],
        1
      ),
      _c(
        "md-icon",
        { staticClass: "custom-sms-over-limit-warning-icon" },
        [
          _vm._v("\n        warning\n        "),
          _c("md-tooltip", [_vm._v(_vm._s(_vm.customSmsOverLimitTooltip))]),
        ],
        1
      ),
      _vm.isCustomSmsOverLimit
        ? _c("div", { staticClass: "custom-sms-over-limit-text" }, [
            _vm._v(
              "\n        Your SMS will be sent across multiple segments and could impact your SMS usage costs. Please reduce your\n        character count to 160 or less.\n    "
            ),
          ])
        : _vm._e(),
      !_vm.isCustomSmsOverLimit ? _c("br") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }