<template>
    <md-table class="custom-paginated-table">
        <md-table-row>
            <md-table-head>Address Code</md-table-head>
            <md-table-head>Address</md-table-head>
            <md-table-head>Contact Name</md-table-head>
            <md-table-head>Email</md-table-head>
            <md-table-head>Phone</md-table-head>
            <md-table-head>Customer</md-table-head>
            <md-table-head></md-table-head>
        </md-table-row>
        <md-table-row v-for="frequentAddress in filteredlistOfFrequentAddresses" :key="frequentAddress.Id">
            <md-table-cell>
                {{ frequentAddress.addressCode }}
            </md-table-cell>
            <md-table-cell class="frequent-address-col">
                <router-link
                    :to="{
                        name: 'Frequent Address Details',
                        params: {
                            frequentAddressId: frequentAddress.frequentAddressId,
                            editMode: false
                        }
                    }"
                >
                    {{ frequentAddress.address }}
                </router-link>
            </md-table-cell>
            <md-table-cell>
                {{ frequentAddress.contactName }}
            </md-table-cell>
            <md-table-cell>
                {{ frequentAddress.email }}
            </md-table-cell>
            <md-table-cell class="phone-number-cell">
                {{ frequentAddress.phone }}
            </md-table-cell>
            <md-table-cell>
                <span v-if="frequentAddress.customerId > 0">
                    {{ frequentAddress.customer.name }}
                </span>
            </md-table-cell>
            <md-table-cell>
                <md-button
                    class="md-round md-just-icon md-warning action-btn-icon"
                    title="Edit Frequent"
                    @click="editFrequentAddress(frequentAddress.frequentAddressId)"
                >
                    <md-icon>edit</md-icon>
                </md-button>
                <md-button
                    class="md-round md-just-icon md-danger action-btn-icon"
                    title="Delete Frequent Address"
                    @click="deleteFrequentAddress(frequentAddress.frequentAddressId)"
                >
                    <md-icon>delete</md-icon>
                </md-button>
            </md-table-cell>
        </md-table-row>
    </md-table>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests } from '@/helpers';

export default {
    name: 'FrequentAddressesList',
    props: {
        listOfFrequentAddresses: {
            type: Array,
            default: null
        },
        customerIdFilter: {
            type: Number,
            default: null
        },
        searchFilter: {
            type: String,
            default: ''
        },
        isCustomer: {
            type: Boolean,
            default: false
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            frequentAddresses: []
        };
    },
    async mounted() {
        this.$_handleLoaderState(true);
        if (this.listOfFrequentAddresses == null) {
            await this.fetchFrequentAddressesList();
        } else {
            this.frequentAddresses = this.listOfFrequentAddresses;
        }
        this.filterList();
        this.$_handleLoaderState(false);
    },
    methods: {
        async fetchFrequentAddressesList() {
            let customerIdPayload = null;
            if (this.isCustomer || this.isCustomerAdmin) {
                customerIdPayload = this.customerIdFilter;
            }
            const api = `/api/frequent-addresses/list`;
            const payload = {
                method: 'get',
                params: { customerId: customerIdPayload }
            };
            await handleRequests(api, payload).then(
                (response) => {
                    this.frequentAddressesList = response.data.frequentAddresses;
                },
                (error) => {
                    const message = 'Error in adding frequent address';
                    showErrorMessage(this, message, error);
                    this.$_handleLoaderState(false);
                }
            );
        },
        editFrequentAddress(val) {
            this.$router.push({
                name: 'Frequent Address Details',
                params: {
                    frequentAddressId: val,
                    editMode: true
                }
            });
        },
        async deleteFrequentAddress(val) {
            this.$_handleLoaderState(true);
            const api = `/api/frequent-addresses/${val}`;
            const payload = {
                method: 'delete'
            };
            await handleRequests(api, payload).then(
                (response) => {
                    const message = 'Successfully deleted';
                    this.$notify({
                        message,
                        type: 'success'
                    });
                    this.getFrequentAddresses();
                    this.$_handleLoaderState(false);
                },
                (error) => {
                    const message = 'Error in retrieving frequent address details';
                    showErrorMessage(this, message, error);
                    this.$_handleLoaderState(false);
                }
            );
            this.$_handleLoaderState(false);
        },
        filterList() {
            let filteredList = this.listOfFrequentAddresses;
            if (this.searchFilter !== null) {
                filteredList = filteredList.filter((obj) =>
                    Object.keys(obj).some((k) =>
                        JSON.stringify(obj[k])
                            .toLowerCase()
                            .includes(this.searchFilter.toLowerCase())
                    )
                );
            }
            if (this.computedCustomerIdFilter !== null) {
                filteredList = filteredList.filter((obj) => {
                    if (this.computedCustomerIdFilter === null || this.computedCustomerIdFilter <= 0) 
                        return true;
                    return obj.customerId === this.computedCustomerIdFilter;
                });
            }
            this.frequentAddresses = filteredList;
        }
    },
    computed: {
        filteredlistOfFrequentAddresses() {
            return this.frequentAddresses;
        },
        computedCustomerIdFilter() {
            return this.customerIdFilter;
        }
    },
    watch: {
        computedCustomerIdFilter() {
            this.filterList();
        },
        searchFilter() {
            this.filterList();
        }
    }
};
</script>
<style lang="scss" scoped>
.phone-number-cell {
    width: 200px;
}

.card-icon-text {
    color: white;
}

.md-theme-default a:not(.md-button) {
    color: black;
}

.action-btn-icon {
    margin-left: 5px;
}

.frequent-address-col {
    width: 25%;
}
</style>
